.rbc-btn {
	color: inherit;
	font: inherit;
	margin: 0;
}

button.rbc-btn {
	-webkit-appearance: button;
	cursor: pointer;
	overflow: visible;
	text-transform: none;
}

button[disabled].rbc-btn {
	cursor: not-allowed;
}

button.rbc-input::-moz-focus-inner {
	border: 0;
	padding: 0;
}

.rbc-calendar {
	align-items: stretch;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	height: 100%;
}

.rbc-calendar *,
.rbc-calendar :after,
.rbc-calendar :before {
	box-sizing: inherit;
}

.rbc-abs-full,
.rbc-row-bg {
	bottom: 0;
	left: 0;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 0;
}

.rbc-ellipsis,
.rbc-event-label,
.rbc-row-segment .rbc-event-content,
.rbc-show-more {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.rbc-rtl {
	direction: rtl;
}

.rbc-off-range {
	color: #999;
}

.rbc-off-range-bg {
	background: #e6e6e6;
}

.rbc-header {
	border-bottom: 1px solid #ddd;
	flex: 1 0;
	font-size: 90%;
	font-weight: 700;
	min-height: 0;
	overflow: hidden;
	padding: 0 3px;
	text-align: center;
	text-overflow: ellipsis;
	vertical-align: middle;
	white-space: nowrap;
}

.rbc-header+.rbc-header {
	border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-header+.rbc-header {
	border-left-width: 0;
	border-right: 1px solid #ddd;
}

.rbc-header>a,
.rbc-header>a:active,
.rbc-header>a:visited {
	color: inherit;
	text-decoration: none;
}

.rbc-row-content {
	position: relative;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-select: none;
	z-index: 4;
}

.rbc-row-content-scrollable {
	display: flex;
	flex-direction: column;
	height: 100%;
}

.rbc-row-content-scrollable .rbc-row-content-scroll-container {
	-ms-overflow-style: none;
	height: 100%;
	overflow-y: scroll;
	scrollbar-width: none;
}

.rbc-row-content-scrollable .rbc-row-content-scroll-container::-webkit-scrollbar {
	display: none;
}

.rbc-today {
	background-color: #eaf6ff;
}

.rbc-toolbar {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	font-size: 16px;
	justify-content: center;
	margin-bottom: 10px;
}

.rbc-toolbar .rbc-toolbar-label {
	flex-grow: 1;
	padding: 0 10px;
	text-align: center;
}

.rbc-toolbar button {
	background: none;
	background-image: none;
	border: 1px solid #ccc;
	border-radius: 4px;
	color: #373a3c;
	display: inline-block;
	line-height: normal;
	margin: 0;
	padding: 0.375rem 1rem;
	text-align: center;
	vertical-align: middle;
	white-space: nowrap;
}

.rbc-toolbar button.rbc-active,
.rbc-toolbar button:active {
	background-color: #e6e6e6;
	border-color: #adadad;
	box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.rbc-toolbar button.rbc-active:focus,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button:active:hover {
	background-color: #d4d4d4;
	border-color: #8c8c8c;
	color: #373a3c;
}

.rbc-toolbar button:focus,
.rbc-toolbar button:hover {
	background-color: #e6e6e6;
	border-color: #adadad;
	color: #373a3c;
}

.rbc-btn-group {
	display: inline-block;
	white-space: nowrap;
}

.rbc-btn-group>button:first-child:not(:last-child) {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.rbc-btn-group>button:last-child:not(:first-child) {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.rbc-rtl .rbc-btn-group>button:first-child:not(:last-child) {
	border-radius: 4px;
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.rbc-rtl .rbc-btn-group>button:last-child:not(:first-child) {
	border-radius: 4px;
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.rbc-btn-group>button:not(:first-child):not(:last-child) {
	border-radius: 0;
}

.rbc-btn-group button+button {
	margin-left: -1px;
}

.rbc-rtl .rbc-btn-group button+button {
	margin-left: 0;
	margin-right: -1px;
}

.rbc-btn-group+.rbc-btn-group,
.rbc-btn-group+button {
	margin-left: 10px;
}

.rbc-day-slot .rbc-background-event,
.rbc-event {
	border: none;
	border-radius: 5px;
	box-shadow: none;
	box-sizing: border-box;
	color: #fff;
	cursor: pointer;
	margin: 0;
	text-align: left;
	width: 100%;
}

.rbc-day-slot .rbc-slot-selecting .rbc-background-event,
.rbc-slot-selecting .rbc-day-slot .rbc-background-event,
.rbc-slot-selecting .rbc-event {
	cursor: inherit;
	pointer-events: none;
}

.rbc-day-slot .rbc-selected.rbc-background-event,
.rbc-event.rbc-selected {
	background-color: #265985;
}

.rbc-event-label {
	font-size: 80%;
}

.rbc-event-overlaps {
	box-shadow: -1px 1px 5px 0 rgba(51, 51, 51, 0.5);
}

.rbc-event-continues-prior {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.rbc-event-continues-after {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.rbc-event-continues-earlier {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.rbc-event-continues-later {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.rbc-row {
	display: flex;
	flex-direction: row;
}

.rbc-row-segment {
	padding: 0 1px 1px;
}

.rbc-selected-cell {
	background-color: rgba(0, 0, 0, 0.1);
}

.rbc-show-more {
	background-color: hsla(0, 0%, 100%, 0.3);
	font-size: 85%;
	font-weight: 700;
	height: auto;
	line-height: normal;
	text-align: center;
	z-index: 4;
}

.rbc-month-view {
	border: 1px solid #ddd;
	display: flex;
	flex: 1 0;
	flex-direction: column;
	height: 100%;
	position: relative;
	-ms-user-select: none;
	user-select: none;
	-webkit-user-select: none;
	width: 100%;
}

.rbc-month-header {
	flex-direction: row;
}

.rbc-month-header,
.rbc-month-row {
	display: flex;
}

.rbc-month-row {
	flex: 1 0;
	flex-basis: 0px;
	flex-direction: column;
	height: 100%;
	overflow: hidden;
	position: relative;
}

.rbc-month-row+.rbc-month-row {
	border-top: 1px solid #ddd;
}

.rbc-date-cell {
	flex: 1 1;
	min-width: 0;
	padding-right: 5px;
	text-align: right;
}

.rbc-date-cell.rbc-now {
	font-weight: 700;
}

.rbc-date-cell>a,
.rbc-date-cell>a:active,
.rbc-date-cell>a:visited {
	color: inherit;
	text-decoration: none;
}

.rbc-row-bg {
	display: flex;
	flex-direction: row;
	overflow: hidden;
}

.rbc-day-bg,
.rbc-row-bg {
	flex: 1 0;
}

.rbc-day-bg+.rbc-day-bg {
	border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-day-bg+.rbc-day-bg {
	border-left-width: 0;
	border-right: 1px solid #ddd;
}

.rbc-overlay {
	background-color: #fff;
	border: 1px solid #e5e5e5;
	box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
	padding: 10px;
	position: absolute;
	z-index: 5;
}

.rbc-overlay>*+* {
	margin-top: 1px;
}

.rbc-overlay-header {
	border-bottom: 1px solid #e5e5e5;
	margin: -10px -10px 5px;
	padding: 2px 10px;
}

.rbc-agenda-view {
	display: flex;
	flex: 1 0;
	flex-direction: column;
	overflow: auto;
}

.rbc-agenda-view table.rbc-agenda-table {
	border: 1px solid #ddd;
	border-collapse: collapse;
	border-spacing: 0;
	width: 100%;
}

.rbc-agenda-view table.rbc-agenda-table tbody>tr>td {
	padding: 5px 10px;
	vertical-align: top;
}

.rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
	padding-left: 15px;
	padding-right: 15px;
	text-transform: lowercase;
}

.rbc-agenda-view table.rbc-agenda-table tbody>tr>td+td {
	border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody>tr>td+td {
	border-left-width: 0;
	border-right: 1px solid #ddd;
}

.rbc-agenda-view table.rbc-agenda-table tbody>tr+tr {
	border-top: 1px solid #ddd;
}

.rbc-agenda-view table.rbc-agenda-table thead>tr>th {
	border-bottom: 1px solid #ddd;
	padding: 3px 5px;
	text-align: left;
}

.rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead>tr>th {
	text-align: right;
}

.rbc-agenda-time-cell {
	text-transform: lowercase;
}

.rbc-agenda-time-cell .rbc-continues-after:after {
	content: ' Â»';
}

.rbc-agenda-time-cell .rbc-continues-prior:before {
	content: 'Â« ';
}

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
	white-space: nowrap;
}

.rbc-agenda-event-cell {
	width: 100%;
}

.rbc-time-column {
	display: flex;
	flex-direction: column;
	min-height: 100%;
}

.rbc-time-column .rbc-timeslot-group {
	flex: 1 1;
}

.rbc-timeslot-group {
	border-bottom: 1px solid #ddd;
	display: flex;
	flex-flow: column nowrap;
	min-height: 40px;
}

.rbc-header-gutter,
.rbc-time-gutter {
	flex: none;
}

.rbc-label {
	padding: 0 5px;
}

.rbc-day-slot {
	position: relative;
}

.rbc-day-slot .rbc-events-container {
	bottom: 0;
	left: 0;
	margin-right: 10px;
	position: absolute;
	right: 0;
	top: 0;
}

.rbc-day-slot .rbc-events-container.rbc-rtl {
	left: 10px;
	right: 0;
}

.rbc-day-slot .rbc-background-event,
.rbc-day-slot .rbc-event {
	align-items: flex-start;
	display: flex;
	flex-flow: column wrap;
	max-height: 100%;
	min-height: 20px;
	overflow: hidden;
	position: absolute;
}

.rbc-day-slot .rbc-background-event {
	opacity: 0.75;
}

.rbc-day-slot .rbc-event-label {
	flex: none;
	padding-right: 5px;
	width: auto;
}

.rbc-day-slot .rbc-event-content {
	word-wrap: break-word;
	flex: 1 1;
	height: 100%;
	line-height: 1;
	min-height: 1em;
	width: 100%;
}

.rbc-day-slot .rbc-time-slot {
	border-top: 1px solid #f7f7f7;
}

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
	background-color: #fff;
	border-right: 1px solid #ddd;
	left: 0;
	margin-right: -1px;
	position: -webkit-sticky;
	position: sticky;
	z-index: 10;
}

.rbc-time-view-resources .rbc-time-header {
	overflow: hidden;
}

.rbc-time-view-resources .rbc-time-header-content {
	flex: 1 0;
	flex-basis: 0px;
	min-width: auto;
}

.rbc-time-view-resources .rbc-time-header-cell-single-day {
	display: none;
}

.rbc-time-view-resources .rbc-day-slot {
	min-width: 140px;
}

.rbc-time-view-resources .rbc-day-bg,
.rbc-time-view-resources .rbc-header {
	flex: 1 1;
	flex-basis: 0 px;
	width: 140px;
}

.rbc-time-header-content+.rbc-time-header-content {
	margin-left: -1px;
}

.rbc-time-slot {
	flex: 1 0;
}

.rbc-time-slot.rbc-now {
	font-weight: 700;
}

.rbc-day-header {
	text-align: center;
}

.rbc-slot-selection {
	background-color: rgba(0, 0, 0, 0.5);
	color: #fff;
	font-size: 75%;
	padding: 3px;
	position: absolute;
	width: 100%;
	z-index: 10;
}

.rbc-slot-selecting {
	cursor: move;
}

.rbc-time-view {
	border: 1px solid #ddd;
	display: flex;
	flex: 1 1;
	flex-direction: column;
	min-height: 0;
	width: 100%;
}

.rbc-time-view .rbc-time-gutter {
	white-space: nowrap;
}

.rbc-time-view .rbc-allday-cell {
	box-sizing: content-box;
	height: 100%;
	position: relative;
	width: 100%;
}

.rbc-time-view .rbc-allday-cell+.rbc-allday-cell {
	border-left: 1px solid #ddd;
}

.rbc-time-view .rbc-allday-events {
	position: relative;
	z-index: 4;
}

.rbc-time-view .rbc-row {
	box-sizing: border-box;
	min-height: 20px;
}

.rbc-time-header {
	display: flex;
	flex: 0 0 auto;
	flex-direction: row;
}

.rbc-time-header.rbc-overflowing {
	border-right: 1px solid #ddd;
}

.rbc-rtl .rbc-time-header.rbc-overflowing {
	border-left: 1px solid #ddd;
	border-right-width: 0;
}

.rbc-time-header>.rbc-row.rbc-row-resource,
.rbc-time-header>.rbc-row:first-child {
	border-bottom: 1px solid #ddd;
}

.rbc-time-header-cell-single-day {
	display: none;
}

.rbc-time-header-content {
	border-left: 1px solid #ddd;
	display: flex;
	flex: 1 1;
	flex-direction: column;
	min-width: 0;
}

.rbc-rtl .rbc-time-header-content {
	border-left-width: 0;
	border-right: 1px solid #ddd;
}

.rbc-time-header-content>.rbc-row.rbc-row-resource {
	border-bottom: 1px solid #ddd;
	flex-shrink: 0;
}

.rbc-time-content {
	align-items: flex-start;
	border-top: 2px solid #ddd;
	display: flex;
	flex: 1 0;
	overflow-y: auto;
	position: relative;
	width: 100%;
}

.rbc-time-content>.rbc-time-gutter {
	flex: none;
}

.rbc-time-content>*+*>* {
	border-left: 1px solid #ddd;
}

.rbc-rtl .rbc-time-content>*+*>* {
	border-left-width: 0;
	border-right: 1px solid #ddd;
}

.rbc-time-content>.rbc-day-slot {
	-ms-user-select: none;
	user-select: none;
	-webkit-user-select: none;
	width: 100%;
}

.rbc-current-time-indicator {
	background-color: #74ad31;
	height: 1px;
	left: 0;
	pointer-events: none;
	position: absolute;
	right: 0;
	z-index: 3;
}

@-webkit-keyframes expand {
	0% {
		max-height: 0;
		opacity: 0;
	}

	to {
		max-height: 1000px;
	}
}

@keyframes expand {
	0% {
		max-height: 0;
		opacity: 0;
	}

	to {
		max-height: 1000px;
	}
}

.ant-table-expanded-row {
	padding: 0;
}

.ant-table-expanded-row>td>div {
	-webkit-animation: expand 0.3s ease-in normal forwards;
	animation: expand 0.3s ease-in normal forwards;
	-webkit-animation-iteration-count: 1;
	animation-iteration-count: 1;
	overflow: hidden;
}

[class*='ant-'] input::-ms-clear,
[class*='ant-'] input::-ms-reveal,
[class*='ant-']::-ms-clear,
[class^='ant-'] input::-ms-clear,
[class^='ant-'] input::-ms-reveal,
[class^='ant-']::-ms-clear {
	display: none;
}

[class*='ant-'],
[class*='ant-'] *,
[class*='ant-'] :after,
[class*='ant-'] :before,
[class^='ant-'],
[class^='ant-'] *,
[class^='ant-'] :after,
[class^='ant-'] :before {
	box-sizing: border-box;
}

input::-ms-clear,
input::-ms-reveal {
	display: none;
}

[tabindex='-1']:focus {
	outline: none !important;
}

/* hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #535353;
  font-weight: 500;
  margin-bottom: 0.5em;
  margin-top: 0;
}
p {
  margin-bottom: 1em;
  margin-top: 0;
}
abbr[data-original-title],
abbr[title] {
  border-bottom: 0;
  cursor: help;
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}
address {
  font-style: normal;
  line-height: inherit;
  margin-bottom: 1em;
}
input[type='number'],
input[type='password'],
input[type='text'],
textarea {
  -webkit-appearance: none;
}
dt {
  font-weight: 500;
}
dd {
  margin-bottom: 0.5em;
  margin-left: 0;
}
blockquote {
  margin: 0 0 1em;
}
dfn {
  font-style: italic;
}
b,
strong {
  font-weight: bolder;
}
small {
  font-size: 80%;
}
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}
sub {
  bottom: -0.25em;
}
sup {
  top: -0.5em;
}
a {
  -webkit-text-decoration-skip: objects;
  background-color: transparent;
  color: #038fde;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  transition: color 0.3s;
}
a:hover {
  color: #28aaeb;
}
a:active {
  color: #006eb8;
}
a:active,
a:hover {
  outline: 0;
  text-decoration: none;
}
a:focus {
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
  pointer-events: none;
}
code,
kbd,
pre,
samp {
  font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier,
    monospace;
  font-size: 1em;
}
pre {
  margin-bottom: 1em;
  margin-top: 0;
  overflow: auto;
}
figure {
  margin: 0 0 1em;
}
img {
  border-style: none;
  vertical-align: middle;
}
svg:not(:root) {
  overflow: hidden;
}
[role='button'],
a,
area,
button,
input:not([type='range']),
label,
select,
summary,
textarea {
  touch-action: manipulation;
}
table {
  border-collapse: collapse;
}
caption {
  caption-side: bottom;
  color: #595959;
  padding-bottom: 0.3em;
  padding-top: 0.75em;
  text-align: left;
}
th {
  text-align: inherit;
}
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0;
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
[type='reset'],
[type='submit'],
button,
html [type='button'] {
  -webkit-appearance: button;
}
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}
input[type='checkbox'],
input[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}
input[type='date'],
input[type='datetime-local'],
input[type='month'],
input[type='time'] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}
fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}
legend {
  color: inherit;
  display: block;
  font-size: 1.5em;
  line-height: inherit;
  margin-bottom: 0.5em;
  max-width: 100%;
  padding: 0;
  white-space: normal;
  width: 100%;
}
progress {
  vertical-align: baseline;
}
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}
[type='search'] {
  -webkit-appearance: none;
  outline-offset: -2px;
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
output {
  display: inline-block;
}
summary {
  display: list-item;
}
template {
  display: none;
}
[hidden] {
  display: none !important;
}
mark {
  background-color: #feffe6;
  padding: 0.2em;
} */
::selection {
	background: #038fde;
	color: #fff;
}

.clearfix:after,
.clearfix:before {
	content: '';
	display: table;
}

.clearfix:after {
	clear: both;
}

.anticon {
	text-rendering: optimizeLegibility;

	-moz-osx-font-smoothing: grayscale;
	color: inherit;
	display: inline-block;
	font-style: normal;
	line-height: 0;
	text-align: center;
	text-transform: none;
	vertical-align: -0.125em;
}

.anticon>* {
	line-height: 1;
}

.anticon svg {
	display: inline-block;
}

.anticon:before {
	display: none;
}

.anticon .anticon-icon {
	display: block;
}

.anticon[tabindex] {
	cursor: pointer;
}

.anticon-spin,
.anticon-spin:before {
	-webkit-animation: loadingCircle 1s linear infinite;
	animation: loadingCircle 1s linear infinite;
	display: inline-block;
}

.ant-fade-appear,
.ant-fade-enter,
.ant-fade-leave {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}

.ant-fade-appear.ant-fade-appear-active,
.ant-fade-enter.ant-fade-enter-active {
	-webkit-animation-name: antFadeIn;
	animation-name: antFadeIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}

.ant-fade-leave.ant-fade-leave-active {
	-webkit-animation-name: antFadeOut;
	animation-name: antFadeOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}

.ant-fade-appear,
.ant-fade-enter {
	opacity: 0;
}

.ant-fade-appear,
.ant-fade-enter,
.ant-fade-leave {
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
}

@-webkit-keyframes antFadeIn {
	0% {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes antFadeIn {
	0% {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@-webkit-keyframes antFadeOut {
	0% {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@keyframes antFadeOut {
	0% {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

.ant-move-up-appear,
.ant-move-up-enter,
.ant-move-up-leave {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}

.ant-move-up-appear.ant-move-up-appear-active,
.ant-move-up-enter.ant-move-up-enter-active {
	-webkit-animation-name: antMoveUpIn;
	animation-name: antMoveUpIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}

.ant-move-up-leave.ant-move-up-leave-active {
	-webkit-animation-name: antMoveUpOut;
	animation-name: antMoveUpOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}

.ant-move-up-appear,
.ant-move-up-enter {
	-webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	opacity: 0;
}

.ant-move-up-leave {
	-webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
	animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

.ant-move-down-appear,
.ant-move-down-enter,
.ant-move-down-leave {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}

.ant-move-down-appear.ant-move-down-appear-active,
.ant-move-down-enter.ant-move-down-enter-active {
	-webkit-animation-name: antMoveDownIn;
	animation-name: antMoveDownIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}

.ant-move-down-leave.ant-move-down-leave-active {
	-webkit-animation-name: antMoveDownOut;
	animation-name: antMoveDownOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}

.ant-move-down-appear,
.ant-move-down-enter {
	-webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	opacity: 0;
}

.ant-move-down-leave {
	-webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
	animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

.ant-move-left-appear,
.ant-move-left-enter,
.ant-move-left-leave {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}

.ant-move-left-appear.ant-move-left-appear-active,
.ant-move-left-enter.ant-move-left-enter-active {
	-webkit-animation-name: antMoveLeftIn;
	animation-name: antMoveLeftIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}

.ant-move-left-leave.ant-move-left-leave-active {
	-webkit-animation-name: antMoveLeftOut;
	animation-name: antMoveLeftOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}

.ant-move-left-appear,
.ant-move-left-enter {
	-webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	opacity: 0;
}

.ant-move-left-leave {
	-webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
	animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

.ant-move-right-appear,
.ant-move-right-enter,
.ant-move-right-leave {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}

.ant-move-right-appear.ant-move-right-appear-active,
.ant-move-right-enter.ant-move-right-enter-active {
	-webkit-animation-name: antMoveRightIn;
	animation-name: antMoveRightIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}

.ant-move-right-leave.ant-move-right-leave-active {
	-webkit-animation-name: antMoveRightOut;
	animation-name: antMoveRightOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}

.ant-move-right-appear,
.ant-move-right-enter {
	-webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	opacity: 0;
}

.ant-move-right-leave {
	-webkit-animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
	animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
}

@-webkit-keyframes antMoveDownIn {
	0% {
		opacity: 0;
		-webkit-transform: translateY(100%);
		transform: translateY(100%);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	to {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

@keyframes antMoveDownIn {
	0% {
		opacity: 0;
		-webkit-transform: translateY(100%);
		transform: translateY(100%);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	to {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

@-webkit-keyframes antMoveDownOut {
	0% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	to {
		opacity: 0;
		-webkit-transform: translateY(100%);
		transform: translateY(100%);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

@keyframes antMoveDownOut {
	0% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	to {
		opacity: 0;
		-webkit-transform: translateY(100%);
		transform: translateY(100%);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

@-webkit-keyframes antMoveLeftIn {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	to {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

@keyframes antMoveLeftIn {
	0% {
		opacity: 0;
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	to {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

@-webkit-keyframes antMoveLeftOut {
	0% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	to {
		opacity: 0;
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

@keyframes antMoveLeftOut {
	0% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	to {
		opacity: 0;
		-webkit-transform: translateX(-100%);
		transform: translateX(-100%);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

@-webkit-keyframes antMoveRightIn {
	0% {
		opacity: 0;
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	to {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

@keyframes antMoveRightIn {
	0% {
		opacity: 0;
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	to {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

@-webkit-keyframes antMoveRightOut {
	0% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	to {
		opacity: 0;
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

@keyframes antMoveRightOut {
	0% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	to {
		opacity: 0;
		-webkit-transform: translateX(100%);
		transform: translateX(100%);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

@-webkit-keyframes antMoveUpIn {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	to {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

@keyframes antMoveUpIn {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	to {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

@-webkit-keyframes antMoveUpOut {
	0% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	to {
		opacity: 0;
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

@keyframes antMoveUpOut {
	0% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	to {
		opacity: 0;
		-webkit-transform: translateY(-100%);
		transform: translateY(-100%);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

@-webkit-keyframes loadingCircle {
	to {
		-webkit-transform: rotate(1turn);
		transform: rotate(1turn);
	}
}

@keyframes loadingCircle {
	to {
		-webkit-transform: rotate(1turn);
		transform: rotate(1turn);
	}
}

[ant-click-animating-without-extra-node='true'],
[ant-click-animating='true'] {
	position: relative;
}

html {
	--antd-wave-shadow-color: #038fde;
	--scroll-bar: 0;
}

.ant-click-animating-node,
[ant-click-animating-without-extra-node='true']:after {
	-webkit-animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1),
		waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
	animation: fadeEffect 2s cubic-bezier(0.08, 0.82, 0.17, 1),
		waveEffect 0.4s cubic-bezier(0.08, 0.82, 0.17, 1);
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
	border-radius: inherit;
	bottom: 0;
	box-shadow: 0 0 0 0 #038fde;
	box-shadow: 0 0 0 0 var(--antd-wave-shadow-color);
	content: '';
	display: block;
	left: 0;
	opacity: 0.2;
	pointer-events: none;
	position: absolute;
	right: 0;
	top: 0;
}

@-webkit-keyframes waveEffect {
	to {
		box-shadow: 0 0 0 #038fde;
		box-shadow: 0 0 0 6px #038fde;
		box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
	}
}

@keyframes waveEffect {
	to {
		box-shadow: 0 0 0 #038fde;
		box-shadow: 0 0 0 6px #038fde;
		box-shadow: 0 0 0 6px var(--antd-wave-shadow-color);
	}
}

@-webkit-keyframes fadeEffect {
	to {
		opacity: 0;
	}
}

@keyframes fadeEffect {
	to {
		opacity: 0;
	}
}

.ant-slide-up-appear,
.ant-slide-up-enter,
.ant-slide-up-leave {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}

.ant-slide-up-appear.ant-slide-up-appear-active,
.ant-slide-up-enter.ant-slide-up-enter-active {
	-webkit-animation-name: antSlideUpIn;
	animation-name: antSlideUpIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}

.ant-slide-up-leave.ant-slide-up-leave-active {
	-webkit-animation-name: antSlideUpOut;
	animation-name: antSlideUpOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}

.ant-slide-up-appear,
.ant-slide-up-enter {
	-webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
	animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
	opacity: 0;
}

.ant-slide-up-leave {
	-webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
	animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.ant-slide-down-appear,
.ant-slide-down-enter,
.ant-slide-down-leave {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}

.ant-slide-down-appear.ant-slide-down-appear-active,
.ant-slide-down-enter.ant-slide-down-enter-active {
	-webkit-animation-name: antSlideDownIn;
	animation-name: antSlideDownIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}

.ant-slide-down-leave.ant-slide-down-leave-active {
	-webkit-animation-name: antSlideDownOut;
	animation-name: antSlideDownOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}

.ant-slide-down-appear,
.ant-slide-down-enter {
	-webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
	animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
	opacity: 0;
}

.ant-slide-down-leave {
	-webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
	animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.ant-slide-left-appear,
.ant-slide-left-enter,
.ant-slide-left-leave {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}

.ant-slide-left-appear.ant-slide-left-appear-active,
.ant-slide-left-enter.ant-slide-left-enter-active {
	-webkit-animation-name: antSlideLeftIn;
	animation-name: antSlideLeftIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}

.ant-slide-left-leave.ant-slide-left-leave-active {
	-webkit-animation-name: antSlideLeftOut;
	animation-name: antSlideLeftOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}

.ant-slide-left-appear,
.ant-slide-left-enter {
	-webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
	animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
	opacity: 0;
}

.ant-slide-left-leave {
	-webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
	animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

.ant-slide-right-appear,
.ant-slide-right-enter,
.ant-slide-right-leave {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}

.ant-slide-right-appear.ant-slide-right-appear-active,
.ant-slide-right-enter.ant-slide-right-enter-active {
	-webkit-animation-name: antSlideRightIn;
	animation-name: antSlideRightIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}

.ant-slide-right-leave.ant-slide-right-leave-active {
	-webkit-animation-name: antSlideRightOut;
	animation-name: antSlideRightOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}

.ant-slide-right-appear,
.ant-slide-right-enter {
	-webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
	animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
	opacity: 0;
}

.ant-slide-right-leave {
	-webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
	animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
}

@-webkit-keyframes antSlideUpIn {
	0% {
		opacity: 0;
		-webkit-transform: scaleY(0.8);
		transform: scaleY(0.8);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	to {
		opacity: 1;
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

@keyframes antSlideUpIn {
	0% {
		opacity: 0;
		-webkit-transform: scaleY(0.8);
		transform: scaleY(0.8);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	to {
		opacity: 1;
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

@-webkit-keyframes antSlideUpOut {
	0% {
		opacity: 1;
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	to {
		opacity: 0;
		-webkit-transform: scaleY(0.8);
		transform: scaleY(0.8);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

@keyframes antSlideUpOut {
	0% {
		opacity: 1;
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	to {
		opacity: 0;
		-webkit-transform: scaleY(0.8);
		transform: scaleY(0.8);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

@-webkit-keyframes antSlideDownIn {
	0% {
		opacity: 0;
		-webkit-transform: scaleY(0.8);
		transform: scaleY(0.8);
		-webkit-transform-origin: 100% 100%;
		transform-origin: 100% 100%;
	}

	to {
		opacity: 1;
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		-webkit-transform-origin: 100% 100%;
		transform-origin: 100% 100%;
	}
}

@keyframes antSlideDownIn {
	0% {
		opacity: 0;
		-webkit-transform: scaleY(0.8);
		transform: scaleY(0.8);
		-webkit-transform-origin: 100% 100%;
		transform-origin: 100% 100%;
	}

	to {
		opacity: 1;
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		-webkit-transform-origin: 100% 100%;
		transform-origin: 100% 100%;
	}
}

@-webkit-keyframes antSlideDownOut {
	0% {
		opacity: 1;
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		-webkit-transform-origin: 100% 100%;
		transform-origin: 100% 100%;
	}

	to {
		opacity: 0;
		-webkit-transform: scaleY(0.8);
		transform: scaleY(0.8);
		-webkit-transform-origin: 100% 100%;
		transform-origin: 100% 100%;
	}
}

@keyframes antSlideDownOut {
	0% {
		opacity: 1;
		-webkit-transform: scaleY(1);
		transform: scaleY(1);
		-webkit-transform-origin: 100% 100%;
		transform-origin: 100% 100%;
	}

	to {
		opacity: 0;
		-webkit-transform: scaleY(0.8);
		transform: scaleY(0.8);
		-webkit-transform-origin: 100% 100%;
		transform-origin: 100% 100%;
	}
}

@-webkit-keyframes antSlideLeftIn {
	0% {
		opacity: 0;
		-webkit-transform: scaleX(0.8);
		transform: scaleX(0.8);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	to {
		opacity: 1;
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

@keyframes antSlideLeftIn {
	0% {
		opacity: 0;
		-webkit-transform: scaleX(0.8);
		transform: scaleX(0.8);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	to {
		opacity: 1;
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

@-webkit-keyframes antSlideLeftOut {
	0% {
		opacity: 1;
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	to {
		opacity: 0;
		-webkit-transform: scaleX(0.8);
		transform: scaleX(0.8);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

@keyframes antSlideLeftOut {
	0% {
		opacity: 1;
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}

	to {
		opacity: 0;
		-webkit-transform: scaleX(0.8);
		transform: scaleX(0.8);
		-webkit-transform-origin: 0 0;
		transform-origin: 0 0;
	}
}

@-webkit-keyframes antSlideRightIn {
	0% {
		opacity: 0;
		-webkit-transform: scaleX(0.8);
		transform: scaleX(0.8);
		-webkit-transform-origin: 100% 0;
		transform-origin: 100% 0;
	}

	to {
		opacity: 1;
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
		-webkit-transform-origin: 100% 0;
		transform-origin: 100% 0;
	}
}

@keyframes antSlideRightIn {
	0% {
		opacity: 0;
		-webkit-transform: scaleX(0.8);
		transform: scaleX(0.8);
		-webkit-transform-origin: 100% 0;
		transform-origin: 100% 0;
	}

	to {
		opacity: 1;
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
		-webkit-transform-origin: 100% 0;
		transform-origin: 100% 0;
	}
}

@-webkit-keyframes antSlideRightOut {
	0% {
		opacity: 1;
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
		-webkit-transform-origin: 100% 0;
		transform-origin: 100% 0;
	}

	to {
		opacity: 0;
		-webkit-transform: scaleX(0.8);
		transform: scaleX(0.8);
		-webkit-transform-origin: 100% 0;
		transform-origin: 100% 0;
	}
}

@keyframes antSlideRightOut {
	0% {
		opacity: 1;
		-webkit-transform: scaleX(1);
		transform: scaleX(1);
		-webkit-transform-origin: 100% 0;
		transform-origin: 100% 0;
	}

	to {
		opacity: 0;
		-webkit-transform: scaleX(0.8);
		transform: scaleX(0.8);
		-webkit-transform-origin: 100% 0;
		transform-origin: 100% 0;
	}
}

.ant-zoom-appear,
.ant-zoom-enter,
.ant-zoom-leave {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}

.ant-zoom-appear.ant-zoom-appear-active,
.ant-zoom-enter.ant-zoom-enter-active {
	-webkit-animation-name: antZoomIn;
	animation-name: antZoomIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}

.ant-zoom-leave.ant-zoom-leave-active {
	-webkit-animation-name: antZoomOut;
	animation-name: antZoomOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}

.ant-zoom-appear,
.ant-zoom-enter {
	-webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}

.ant-zoom-appear-prepare,
.ant-zoom-enter-prepare {
	-webkit-transform: none;
	transform: none;
}

.ant-zoom-leave {
	-webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-big-appear,
.ant-zoom-big-enter,
.ant-zoom-big-leave {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}

.ant-zoom-big-appear.ant-zoom-big-appear-active,
.ant-zoom-big-enter.ant-zoom-big-enter-active {
	-webkit-animation-name: antZoomBigIn;
	animation-name: antZoomBigIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}

.ant-zoom-big-leave.ant-zoom-big-leave-active {
	-webkit-animation-name: antZoomBigOut;
	animation-name: antZoomBigOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}

.ant-zoom-big-appear,
.ant-zoom-big-enter {
	-webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}

.ant-zoom-big-appear-prepare,
.ant-zoom-big-enter-prepare {
	-webkit-transform: none;
	transform: none;
}

.ant-zoom-big-leave {
	-webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-big-fast-appear,
.ant-zoom-big-fast-enter,
.ant-zoom-big-fast-leave {
	-webkit-animation-duration: 0.1s;
	animation-duration: 0.1s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}

.ant-zoom-big-fast-appear.ant-zoom-big-fast-appear-active,
.ant-zoom-big-fast-enter.ant-zoom-big-fast-enter-active {
	-webkit-animation-name: antZoomBigIn;
	animation-name: antZoomBigIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}

.ant-zoom-big-fast-leave.ant-zoom-big-fast-leave-active {
	-webkit-animation-name: antZoomBigOut;
	animation-name: antZoomBigOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}

.ant-zoom-big-fast-appear,
.ant-zoom-big-fast-enter {
	-webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}

.ant-zoom-big-fast-appear-prepare,
.ant-zoom-big-fast-enter-prepare {
	-webkit-transform: none;
	transform: none;
}

.ant-zoom-big-fast-leave {
	-webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-up-appear,
.ant-zoom-up-enter,
.ant-zoom-up-leave {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}

.ant-zoom-up-appear.ant-zoom-up-appear-active,
.ant-zoom-up-enter.ant-zoom-up-enter-active {
	-webkit-animation-name: antZoomUpIn;
	animation-name: antZoomUpIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}

.ant-zoom-up-leave.ant-zoom-up-leave-active {
	-webkit-animation-name: antZoomUpOut;
	animation-name: antZoomUpOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}

.ant-zoom-up-appear,
.ant-zoom-up-enter {
	-webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}

.ant-zoom-up-appear-prepare,
.ant-zoom-up-enter-prepare {
	-webkit-transform: none;
	transform: none;
}

.ant-zoom-up-leave {
	-webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-down-appear,
.ant-zoom-down-enter,
.ant-zoom-down-leave {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}

.ant-zoom-down-appear.ant-zoom-down-appear-active,
.ant-zoom-down-enter.ant-zoom-down-enter-active {
	-webkit-animation-name: antZoomDownIn;
	animation-name: antZoomDownIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}

.ant-zoom-down-leave.ant-zoom-down-leave-active {
	-webkit-animation-name: antZoomDownOut;
	animation-name: antZoomDownOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}

.ant-zoom-down-appear,
.ant-zoom-down-enter {
	-webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}

.ant-zoom-down-appear-prepare,
.ant-zoom-down-enter-prepare {
	-webkit-transform: none;
	transform: none;
}

.ant-zoom-down-leave {
	-webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-left-appear,
.ant-zoom-left-enter,
.ant-zoom-left-leave {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}

.ant-zoom-left-appear.ant-zoom-left-appear-active,
.ant-zoom-left-enter.ant-zoom-left-enter-active {
	-webkit-animation-name: antZoomLeftIn;
	animation-name: antZoomLeftIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}

.ant-zoom-left-leave.ant-zoom-left-leave-active {
	-webkit-animation-name: antZoomLeftOut;
	animation-name: antZoomLeftOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}

.ant-zoom-left-appear,
.ant-zoom-left-enter {
	-webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}

.ant-zoom-left-appear-prepare,
.ant-zoom-left-enter-prepare {
	-webkit-transform: none;
	transform: none;
}

.ant-zoom-left-leave {
	-webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-zoom-right-appear,
.ant-zoom-right-enter,
.ant-zoom-right-leave {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}

.ant-zoom-right-appear.ant-zoom-right-appear-active,
.ant-zoom-right-enter.ant-zoom-right-enter-active {
	-webkit-animation-name: antZoomRightIn;
	animation-name: antZoomRightIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}

.ant-zoom-right-leave.ant-zoom-right-leave-active {
	-webkit-animation-name: antZoomRightOut;
	animation-name: antZoomRightOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}

.ant-zoom-right-appear,
.ant-zoom-right-enter {
	-webkit-animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
	opacity: 0;
	-webkit-transform: scale(0);
	transform: scale(0);
}

.ant-zoom-right-appear-prepare,
.ant-zoom-right-enter-prepare {
	-webkit-transform: none;
	transform: none;
}

.ant-zoom-right-leave {
	-webkit-animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
	animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

@-webkit-keyframes antZoomIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.2);
		transform: scale(0.2);
	}

	to {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes antZoomIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.2);
		transform: scale(0.2);
	}

	to {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@-webkit-keyframes antZoomOut {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	to {
		opacity: 0;
		-webkit-transform: scale(0.2);
		transform: scale(0.2);
	}
}

@keyframes antZoomOut {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	to {
		opacity: 0;
		-webkit-transform: scale(0.2);
		transform: scale(0.2);
	}
}

@-webkit-keyframes antZoomBigIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
	}

	to {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes antZoomBigIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
	}

	to {
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@-webkit-keyframes antZoomBigOut {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	to {
		opacity: 0;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
	}
}

@keyframes antZoomBigOut {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	to {
		opacity: 0;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
	}
}

@-webkit-keyframes antZoomUpIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
		-webkit-transform-origin: 50% 0;
		transform-origin: 50% 0;
	}

	to {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 50% 0;
		transform-origin: 50% 0;
	}
}

@keyframes antZoomUpIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
		-webkit-transform-origin: 50% 0;
		transform-origin: 50% 0;
	}

	to {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 50% 0;
		transform-origin: 50% 0;
	}
}

@-webkit-keyframes antZoomUpOut {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 50% 0;
		transform-origin: 50% 0;
	}

	to {
		opacity: 0;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
		-webkit-transform-origin: 50% 0;
		transform-origin: 50% 0;
	}
}

@keyframes antZoomUpOut {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 50% 0;
		transform-origin: 50% 0;
	}

	to {
		opacity: 0;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
		-webkit-transform-origin: 50% 0;
		transform-origin: 50% 0;
	}
}

@-webkit-keyframes antZoomLeftIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
		-webkit-transform-origin: 0 50%;
		transform-origin: 0 50%;
	}

	to {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 0 50%;
		transform-origin: 0 50%;
	}
}

@keyframes antZoomLeftIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
		-webkit-transform-origin: 0 50%;
		transform-origin: 0 50%;
	}

	to {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 0 50%;
		transform-origin: 0 50%;
	}
}

@-webkit-keyframes antZoomLeftOut {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 0 50%;
		transform-origin: 0 50%;
	}

	to {
		opacity: 0;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
		-webkit-transform-origin: 0 50%;
		transform-origin: 0 50%;
	}
}

@keyframes antZoomLeftOut {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 0 50%;
		transform-origin: 0 50%;
	}

	to {
		opacity: 0;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
		-webkit-transform-origin: 0 50%;
		transform-origin: 0 50%;
	}
}

@-webkit-keyframes antZoomRightIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
		-webkit-transform-origin: 100% 50%;
		transform-origin: 100% 50%;
	}

	to {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 100% 50%;
		transform-origin: 100% 50%;
	}
}

@keyframes antZoomRightIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
		-webkit-transform-origin: 100% 50%;
		transform-origin: 100% 50%;
	}

	to {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 100% 50%;
		transform-origin: 100% 50%;
	}
}

@-webkit-keyframes antZoomRightOut {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 100% 50%;
		transform-origin: 100% 50%;
	}

	to {
		opacity: 0;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
		-webkit-transform-origin: 100% 50%;
		transform-origin: 100% 50%;
	}
}

@keyframes antZoomRightOut {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 100% 50%;
		transform-origin: 100% 50%;
	}

	to {
		opacity: 0;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
		-webkit-transform-origin: 100% 50%;
		transform-origin: 100% 50%;
	}
}

@-webkit-keyframes antZoomDownIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
		-webkit-transform-origin: 50% 100%;
		transform-origin: 50% 100%;
	}

	to {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 50% 100%;
		transform-origin: 50% 100%;
	}
}

@keyframes antZoomDownIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
		-webkit-transform-origin: 50% 100%;
		transform-origin: 50% 100%;
	}

	to {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 50% 100%;
		transform-origin: 50% 100%;
	}
}

@-webkit-keyframes antZoomDownOut {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 50% 100%;
		transform-origin: 50% 100%;
	}

	to {
		opacity: 0;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
		-webkit-transform-origin: 50% 100%;
		transform-origin: 50% 100%;
	}
}

@keyframes antZoomDownOut {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transform-origin: 50% 100%;
		transform-origin: 50% 100%;
	}

	to {
		opacity: 0;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
		-webkit-transform-origin: 50% 100%;
		transform-origin: 50% 100%;
	}
}

.ant-motion-collapse-legacy {
	overflow: hidden;
}

.ant-motion-collapse,
.ant-motion-collapse-legacy-active {
	transition: height 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
		opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
}

.ant-motion-collapse {
	overflow: hidden;
}

.ant-affix {
	position: fixed;
	z-index: 10;
}

.ant-alert {
	word-wrap: break-word;
	align-items: center;
	border-radius: 6px;
	box-sizing: border-box;
	color: #545454;
	display: flex;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 8px 15px;
	position: relative;
}

.ant-alert-content {
	flex: 1 1;
	min-width: 0;
}

.ant-alert-icon {
	margin-right: 8px;
}

.ant-alert-description {
	display: none;
	font-size: 14px;
	line-height: 22px;
}

.ant-alert-success {
	background-color: #f6ffed;
	border: 1px solid #b7eb8f;
}

.ant-alert-success .ant-alert-icon {
	color: #52c41a;
}

.ant-alert-info {
	background-color: #e6f7ff;
	border: 1px solid #91d5ff;
}

.ant-alert-info .ant-alert-icon {
	color: #1890ff;
}

.ant-alert-warning {
	background-color: #fff7e6;
	border: 1px solid #ffd591;
}

.ant-alert-warning .ant-alert-icon {
	color: #fa8c16;
}

.ant-alert-error {
	background-color: #fff1f0;
	border: 1px solid #ffa39e;
}

.ant-alert-error .ant-alert-icon {
	color: #f5222d;
}

.ant-alert-error .ant-alert-description>pre {
	margin: 0;
	padding: 0;
}

.ant-alert-action {
	margin-left: 8px;
}

.ant-alert-close-icon {
	background-color: transparent;
	border: none;
	cursor: pointer;
	font-size: 12px;
	line-height: 12px;
	margin-left: 8px;
	outline: none;
	overflow: hidden;
	padding: 0;
}

.ant-alert-close-icon .anticon-close {
	color: #595959;
	transition: color 0.3s;
}

.ant-alert-close-icon .anticon-close:hover {
	color: rgba(0, 0, 0, 0.75);
}

.ant-alert-close-text {
	color: #595959;
	transition: color 0.3s;
}

.ant-alert-close-text:hover {
	color: rgba(0, 0, 0, 0.75);
}

.ant-alert-with-description {
	align-items: flex-start;
	padding: 15px 15px 15px 24px;
}

.ant-alert-with-description.ant-alert-no-icon {
	padding: 15px;
}

.ant-alert-with-description .ant-alert-icon {
	font-size: 24px;
	margin-right: 15px;
}

.ant-alert-with-description .ant-alert-message {
	color: #535353;
	display: block;
	font-size: 16px;
	margin-bottom: 4px;
}

.ant-alert-message {
	color: #535353;
}

.ant-alert-with-description .ant-alert-description {
	display: block;
}

.ant-alert.ant-alert-motion-leave {
	opacity: 1;
	overflow: hidden;
	transition: max-height 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
		opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
		padding-top 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
		padding-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
		margin-bottom 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-alert.ant-alert-motion-leave-active {
	margin-bottom: 0 !important;
	max-height: 0;
	opacity: 0;
	padding-bottom: 0;
	padding-top: 0;
}

.ant-alert-banner {
	border: 0;
	border-radius: 0;
	margin-bottom: 0;
}

.ant-alert.ant-alert-rtl {
	direction: rtl;
}

.ant-alert-rtl.ant-alert.ant-alert-no-icon {
	padding: 8px 15px;
}

.ant-alert-rtl .ant-alert-icon {
	margin-left: 8px;
	margin-right: auto;
}

.ant-alert-rtl .ant-alert-action,
.ant-alert-rtl .ant-alert-close-icon {
	margin-left: auto;
	margin-right: 8px;
}

.ant-alert-rtl.ant-alert-with-description .ant-alert-icon {
	margin-left: 15px;
	margin-right: auto;
}

.ant-anchor {
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0 0 0 2px;
	position: relative;
}

.ant-anchor-wrapper {
	background-color: #fff;
	margin-left: -4px;
	overflow: auto;
	padding-left: 4px;
}

.ant-anchor-ink {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
}

.ant-anchor-ink:before {
	background-color: #e8e8e8;
	content: ' ';
	display: block;
	height: 100%;
	margin: 0 auto;
	position: relative;
	width: 2px;
}

.ant-anchor-ink-ball {
	background-color: #fff;
	border: 2px solid #038fde;
	border-radius: 8px;
	display: none;
	height: 8px;
	left: 50%;
	position: absolute;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
	transition: top 0.3s ease-in-out;
	width: 8px;
}

.ant-anchor-ink-ball.visible {
	display: inline-block;
}

.ant-anchor.fixed .ant-anchor-ink .ant-anchor-ink-ball {
	display: none;
}

.ant-anchor-link {
	line-height: 1.143;
	padding: 7px 0 7px 16px;
}

.ant-anchor-link-title {
	color: #545454;
	display: block;
	margin-bottom: 6px;
	overflow: hidden;
	position: relative;
	text-overflow: ellipsis;
	transition: all 0.3s;
	white-space: nowrap;
}

.ant-anchor-link-title:only-child {
	margin-bottom: 0;
}

.ant-anchor-link-active>.ant-anchor-link-title {
	color: #038fde;
}

.ant-anchor-link .ant-anchor-link {
	padding-bottom: 5px;
	padding-top: 5px;
}

.ant-anchor-rtl {
	direction: rtl;
}

.ant-anchor-rtl.ant-anchor-wrapper {
	margin-left: 0;
	margin-right: -4px;
	padding-left: 0;
	padding-right: 4px;
}

.ant-anchor-rtl .ant-anchor-ink {
	left: auto;
	right: 0;
}

.ant-anchor-rtl .ant-anchor-ink-ball {
	left: 0;
	right: 50%;
	-webkit-transform: translateX(50%);
	transform: translateX(50%);
}

.ant-anchor-rtl .ant-anchor-link {
	padding: 7px 16px 7px 0;
}

.ant-select-auto-complete {
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
}

.ant-select-auto-complete .ant-select-clear {
	right: 13px;
}

.ant-avatar {
	background: #ccc;
	border-radius: 50%;
	box-sizing: border-box;
	color: #545454;
	color: #fff;
	display: inline-block;
	font-size: 14px;
	font-variant: tabular-nums;
	height: 32px;
	line-height: 1.3;
	line-height: 32px;
	list-style: none;
	margin: 0;
	overflow: hidden;
	padding: 0;
	text-align: center;
	vertical-align: middle;
	white-space: nowrap;
	width: 32px;
}

.ant-avatar-image {
	background: transparent;
}

.ant-avatar .ant-image-img {
	display: block;
}

.ant-avatar-string {
	left: 50%;
	position: absolute;
	-webkit-transform-origin: 0 center;
	transform-origin: 0 center;
}

.ant-avatar.ant-avatar-icon {
	font-size: 18px;
}

.ant-avatar.ant-avatar-icon>.anticon {
	margin: 0;
}

.ant-avatar-lg {
	border-radius: 50%;
	height: 40px;
	line-height: 40px;
	width: 40px;
}

.ant-avatar-lg-string {
	left: 50%;
	position: absolute;
	-webkit-transform-origin: 0 center;
	transform-origin: 0 center;
}

.ant-avatar-lg.ant-avatar-icon {
	font-size: 24px;
}

.ant-avatar-lg.ant-avatar-icon>.anticon {
	margin: 0;
}

.ant-avatar-sm {
	border-radius: 50%;
	height: 24px;
	line-height: 24px;
	width: 24px;
}

.ant-avatar-sm-string {
	left: 50%;
	position: absolute;
	-webkit-transform-origin: 0 center;
	transform-origin: 0 center;
}

.ant-avatar-sm.ant-avatar-icon {
	font-size: 14px;
}

.ant-avatar-sm.ant-avatar-icon>.anticon {
	margin: 0;
}

.ant-avatar-square {
	border-radius: 6px;
}

.ant-avatar>img {
	display: block;
	height: 100%;
	object-fit: cover;
	width: 100%;
}

.ant-avatar-group {
	display: inline-flex;
}

.ant-avatar-group .ant-avatar {
	border: 1px solid #fff;
}

.ant-avatar-group .ant-avatar:not(:first-child) {
	margin-left: -8px;
}

.ant-avatar-group-popover .ant-avatar+.ant-avatar {
	margin-left: 3px;
}

.ant-avatar-group-rtl .ant-avatar:not(:first-child) {
	margin-left: 0;
	margin-right: -8px;
}

.ant-avatar-group-popover.ant-popover-rtl .ant-avatar+.ant-avatar {
	margin-left: 0;
	margin-right: 3px;
}

.ant-back-top {
	bottom: 50px;
	box-sizing: border-box;
	color: #545454;
	cursor: pointer;
	font-size: 14px;
	font-variant: tabular-nums;
	height: 40px;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
	position: fixed;
	right: 100px;
	width: 40px;
	z-index: 10;
}

.ant-back-top:empty {
	display: none;
}

.ant-back-top-rtl {
	direction: rtl;
	left: 100px;
	right: auto;
}

.ant-back-top-content {
	background-color: #595959;
	border-radius: 20px;
	color: #fff;
	height: 40px;
	overflow: hidden;
	text-align: center;
	transition: all 0.3s;
	width: 40px;
}

.ant-back-top-content:hover {
	background-color: #545454;
	transition: all 0.3s;
}

.ant-back-top-icon {
	font-size: 24px;
	line-height: 40px;
}

@media screen and (max-width: 768px) {
	.ant-back-top {
		right: 60px;
	}
}

@media screen and (max-width: 480px) {
	.ant-back-top {
		right: 20px;
	}
}

.ant-badge {
	box-sizing: border-box;
	color: #545454;
	display: inline-block;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	line-height: 1;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}

.ant-badge-count {
	background: #f5222d;
	border-radius: 10px;
	box-shadow: 0 0 0 1px #fff;
	color: #fff;
	font-size: 12px;
	font-weight: 400;
	height: 20px;
	line-height: 20px;
	min-width: 20px;
	padding: 0 6px;
	text-align: center;
	white-space: nowrap;
	z-index: auto;
}

.ant-badge-count a,
.ant-badge-count a:hover {
	color: #fff;
}

.ant-badge-count-sm {
	border-radius: 7px;
	font-size: 12px;
	height: 14px;
	line-height: 14px;
	min-width: 14px;
	padding: 0;
}

.ant-badge-multiple-words {
	padding: 0 8px;
}

.ant-badge-dot {
	background: #f5222d;
	border-radius: 100%;
	box-shadow: 0 0 0 1px #fff;
	height: 6px;
	min-width: 6px;
	width: 6px;
	z-index: auto;
}

.ant-badge .ant-scroll-number-custom-component,
.ant-badge-count,
.ant-badge-dot {
	display: block;
	position: absolute;
	right: 0;
	top: 0;
	-webkit-transform: translate(50%, -50%);
	transform: translate(50%, -50%);
	-webkit-transform-origin: 100% 0;
	transform-origin: 100% 0;
}

.badge-animate .ant-badge-count {
	-webkit-animation: away 2s infinite;
	animation: away 2s infinite;
	background-color: #fa8c16;
	box-shadow: 0 0 0 rgb(250 140 22/40%);
}

.ant-badge-status {
	line-height: inherit;
	vertical-align: baseline;
}

.ant-badge-status-dot {
	border-radius: 50%;
	display: inline-block;
	height: 6px;
	position: relative;
	top: -1px;
	vertical-align: middle;
	width: 6px;
}

.ant-badge-status-success {
	background-color: #52c41a;
}

.ant-badge-status-processing {
	background-color: #038fde;
	position: relative;
}

.ant-badge-status-processing:after {
	-webkit-animation: antStatusProcessing 1.2s ease-in-out infinite;
	animation: antStatusProcessing 1.2s ease-in-out infinite;
	border: 1px solid #038fde;
	border-radius: 50%;
	content: '';
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.ant-badge-status-default {
	background-color: #d9d9d9;
}

.ant-badge-status-error {
	background-color: #f5222d;
}

.ant-badge-status-warning {
	background-color: #fa8c16;
}

.ant-badge-status-magenta,
.ant-badge-status-pink {
	background: #eb2f96;
}

.ant-badge-status-red {
	background: #f5222d;
}

.ant-badge-status-volcano {
	background: #fa541c;
}

.ant-badge-status-orange {
	background: #fa8c16;
}

.ant-badge-status-yellow {
	background: #fadb14;
}

.ant-badge-status-gold {
	background: #faad14;
}

.ant-badge-status-cyan {
	background: #13c2c2;
}

.ant-badge-status-lime {
	background: #a0d911;
}

.ant-badge-status-green {
	background: #52c41a;
}

.ant-badge-status-blue {
	background: #1890ff;
}

.ant-badge-status-geekblue {
	background: #2f54eb;
}

.ant-badge-status-purple {
	background: #722ed1;
}

.ant-badge-status-text {
	color: #545454;
	font-size: 14px;
	margin-left: 8px;
}

.ant-badge-zoom-appear,
.ant-badge-zoom-enter {
	-webkit-animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
	animation: antZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.ant-badge-zoom-leave {
	-webkit-animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
	animation: antZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
}

.ant-badge-not-a-wrapper .ant-badge-zoom-appear,
.ant-badge-not-a-wrapper .ant-badge-zoom-enter {
	-webkit-animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
	animation: antNoWrapperZoomBadgeIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
}

.ant-badge-not-a-wrapper .ant-badge-zoom-leave {
	-webkit-animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
	animation: antNoWrapperZoomBadgeOut 0.3s cubic-bezier(0.71, -0.46, 0.88, 0.6);
}

.ant-badge-not-a-wrapper:not(.ant-badge-status) {
	margin: 0;
	vertical-align: middle;
}

.ant-badge-not-a-wrapper .ant-scroll-number-custom-component {
	-webkit-transform: none;
	transform: none;
}

.ant-badge-not-a-wrapper .ant-scroll-number,
.ant-badge-not-a-wrapper .ant-scroll-number-custom-component {
	display: block;
	position: relative;
	top: auto;
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
}

@-webkit-keyframes antStatusProcessing {
	0% {
		opacity: 0.5;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
	}

	to {
		opacity: 0;
		-webkit-transform: scale(2.4);
		transform: scale(2.4);
	}
}

@keyframes antStatusProcessing {
	0% {
		opacity: 0.5;
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
	}

	to {
		opacity: 0;
		-webkit-transform: scale(2.4);
		transform: scale(2.4);
	}
}

.ant-scroll-number {
	overflow: hidden;
}

.ant-scroll-number-only {
	display: inline-block;
	position: relative;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-scroll-number-only,
.ant-scroll-number-only>p.ant-scroll-number-only-unit {
	height: 20px;
	-webkit-transform-style: preserve-3d;
	transform-style: preserve-3d;
}

.ant-scroll-number-only>p.ant-scroll-number-only-unit {
	margin: 0;
}

.ant-scroll-number-symbol {
	vertical-align: top;
}

@-webkit-keyframes antZoomBadgeIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(0) translate(50%, -50%);
		transform: scale(0) translate(50%, -50%);
	}

	to {
		-webkit-transform: scale(1) translate(50%, -50%);
		transform: scale(1) translate(50%, -50%);
	}
}

@keyframes antZoomBadgeIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(0) translate(50%, -50%);
		transform: scale(0) translate(50%, -50%);
	}

	to {
		-webkit-transform: scale(1) translate(50%, -50%);
		transform: scale(1) translate(50%, -50%);
	}
}

@-webkit-keyframes antZoomBadgeOut {
	0% {
		-webkit-transform: scale(1) translate(50%, -50%);
		transform: scale(1) translate(50%, -50%);
	}

	to {
		opacity: 0;
		-webkit-transform: scale(0) translate(50%, -50%);
		transform: scale(0) translate(50%, -50%);
	}
}

@keyframes antZoomBadgeOut {
	0% {
		-webkit-transform: scale(1) translate(50%, -50%);
		transform: scale(1) translate(50%, -50%);
	}

	to {
		opacity: 0;
		-webkit-transform: scale(0) translate(50%, -50%);
		transform: scale(0) translate(50%, -50%);
	}
}

@-webkit-keyframes antNoWrapperZoomBadgeIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	to {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes antNoWrapperZoomBadgeIn {
	0% {
		opacity: 0;
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	to {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@-webkit-keyframes antNoWrapperZoomBadgeOut {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	to {
		opacity: 0;
		-webkit-transform: scale(0);
		transform: scale(0);
	}
}

@keyframes antNoWrapperZoomBadgeOut {
	0% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	to {
		opacity: 0;
		-webkit-transform: scale(0);
		transform: scale(0);
	}
}

.ant-ribbon-wrapper {
	position: relative;
}

.ant-ribbon {
	background-color: #038fde;
	border-radius: 4px;
	box-sizing: border-box;
	color: #545454;
	color: #fff;
	font-size: 14px;
	font-variant: tabular-nums;
	height: 22px;
	line-height: 1.3;
	line-height: 22px;
	list-style: none;
	margin: 0;
	padding: 0 8px;
	position: absolute;
	top: 8px;
	white-space: nowrap;
}

.ant-ribbon-text {
	color: #fff;
}

.ant-ribbon-corner {
	border: 4px solid;
	color: currentColor;
	height: 8px;
	position: absolute;
	top: 100%;
	-webkit-transform: scaleY(0.75);
	transform: scaleY(0.75);
	-webkit-transform-origin: top;
	transform-origin: top;
	width: 8px;
}

.ant-ribbon-corner:after {
	border: inherit;
	color: rgba(0, 0, 0, 0.25);
	content: '';
	height: inherit;
	left: -4px;
	position: absolute;
	top: -4px;
	width: inherit;
}

.ant-ribbon-color-magenta,
.ant-ribbon-color-pink {
	background: #eb2f96;
	color: #eb2f96;
}

.ant-ribbon-color-red {
	background: #f5222d;
	color: #f5222d;
}

.ant-ribbon-color-volcano {
	background: #fa541c;
	color: #fa541c;
}

.ant-ribbon-color-orange {
	background: #fa8c16;
	color: #fa8c16;
}

.ant-ribbon-color-yellow {
	background: #fadb14;
	color: #fadb14;
}

.ant-ribbon-color-gold {
	background: #faad14;
	color: #faad14;
}

.ant-ribbon-color-cyan {
	background: #13c2c2;
	color: #13c2c2;
}

.ant-ribbon-color-lime {
	background: #a0d911;
	color: #a0d911;
}

.ant-ribbon-color-green {
	background: #52c41a;
	color: #52c41a;
}

.ant-ribbon-color-blue {
	background: #1890ff;
	color: #1890ff;
}

.ant-ribbon-color-geekblue {
	background: #2f54eb;
	color: #2f54eb;
}

.ant-ribbon-color-purple {
	background: #722ed1;
	color: #722ed1;
}

.ant-ribbon.ant-ribbon-placement-end {
	border-bottom-right-radius: 0;
	right: -8px;
}

.ant-ribbon.ant-ribbon-placement-end .ant-ribbon-corner {
	border-color: currentColor transparent transparent currentColor;
	right: 0;
}

.ant-ribbon.ant-ribbon-placement-start {
	border-bottom-left-radius: 0;
	left: -8px;
}

.ant-ribbon.ant-ribbon-placement-start .ant-ribbon-corner {
	border-color: currentColor currentColor transparent transparent;
	left: 0;
}

.ant-badge-rtl {
	direction: rtl;
}

.ant-badge-rtl .ant-badge .ant-scroll-number-custom-component,
.ant-badge-rtl .ant-badge-count,
.ant-badge-rtl .ant-badge-dot {
	direction: ltr;
}

.ant-badge-rtl .ant-badge .ant-scroll-number-custom-component,
.ant-badge-rtl .ant-badge-count,
.ant-badge-rtl .ant-badge-dot,
.ant-badge-rtl.ant-badge .ant-scroll-number-custom-component {
	left: 0;
	right: auto;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
}

.ant-badge-rtl .ant-badge-status-text {
	margin-left: 0;
	margin-right: 8px;
}

.ant-badge-rtl .ant-badge-zoom-appear,
.ant-badge-rtl .ant-badge-zoom-enter {
	-webkit-animation-name: antZoomBadgeInRtl;
	animation-name: antZoomBadgeInRtl;
}

.ant-badge-rtl .ant-badge-zoom-leave {
	-webkit-animation-name: antZoomBadgeOutRtl;
	animation-name: antZoomBadgeOutRtl;
}

.ant-badge-not-a-wrapper .ant-badge-count {
	-webkit-transform: none;
	transform: none;
}

.ant-ribbon-rtl {
	direction: rtl;
}

.ant-ribbon-rtl.ant-ribbon-placement-end {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 4px;
	left: -8px;
	right: unset;
}

.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner {
	left: 0;
	right: unset;
}

.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner,
.ant-ribbon-rtl.ant-ribbon-placement-end .ant-ribbon-corner:after {
	border-color: currentColor currentColor transparent transparent;
}

.ant-ribbon-rtl.ant-ribbon-placement-start {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 0;
	left: unset;
	right: -8px;
}

.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner {
	left: unset;
	right: 0;
}

.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner,
.ant-ribbon-rtl.ant-ribbon-placement-start .ant-ribbon-corner:after {
	border-color: currentColor transparent transparent currentColor;
}

@-webkit-keyframes antZoomBadgeInRtl {
	0% {
		opacity: 0;
		-webkit-transform: scale(0) translate(-50%, -50%);
		transform: scale(0) translate(-50%, -50%);
	}

	to {
		-webkit-transform: scale(1) translate(-50%, -50%);
		transform: scale(1) translate(-50%, -50%);
	}
}

@keyframes antZoomBadgeInRtl {
	0% {
		opacity: 0;
		-webkit-transform: scale(0) translate(-50%, -50%);
		transform: scale(0) translate(-50%, -50%);
	}

	to {
		-webkit-transform: scale(1) translate(-50%, -50%);
		transform: scale(1) translate(-50%, -50%);
	}
}

@-webkit-keyframes antZoomBadgeOutRtl {
	0% {
		-webkit-transform: scale(1) translate(-50%, -50%);
		transform: scale(1) translate(-50%, -50%);
	}

	to {
		opacity: 0;
		-webkit-transform: scale(0) translate(-50%, -50%);
		transform: scale(0) translate(-50%, -50%);
	}
}

@keyframes antZoomBadgeOutRtl {
	0% {
		-webkit-transform: scale(1) translate(-50%, -50%);
		transform: scale(1) translate(-50%, -50%);
	}

	to {
		opacity: 0;
		-webkit-transform: scale(0) translate(-50%, -50%);
		transform: scale(0) translate(-50%, -50%);
	}
}

.ant-breadcrumb {
	box-sizing: border-box;
	color: #545454;
	color: #595959;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
}

.ant-breadcrumb .anticon {
	font-size: 14px;
}

.ant-breadcrumb a {
	color: #595959;
	transition: color 0.3s;
}

.ant-breadcrumb a:hover {
	color: #28aaeb;
}

.ant-breadcrumb>span:last-child,
.ant-breadcrumb>span:last-child a {
	color: #545454;
}

.ant-breadcrumb>span:last-child .ant-breadcrumb-separator {
	display: none;
}

.ant-breadcrumb-separator {
	color: #595959;
	margin: 0 8px;
}

.ant-breadcrumb-link>.anticon+a,
.ant-breadcrumb-link>.anticon+span,
.ant-breadcrumb-overlay-link>.anticon {
	margin-left: 4px;
}

.ant-breadcrumb-rtl {
	direction: rtl;
}

.ant-breadcrumb-rtl:before {
	content: '';
	display: table;
}

.ant-breadcrumb-rtl:after {
	clear: both;
	content: '';
	display: table;
}

.ant-breadcrumb-rtl>span {
	float: right;
}

.ant-breadcrumb-rtl .ant-breadcrumb-link>.anticon+a,
.ant-breadcrumb-rtl .ant-breadcrumb-link>.anticon+span,
.ant-breadcrumb-rtl .ant-breadcrumb-overlay-link>.anticon {
	margin-left: 0;
	margin-right: 4px;
}

.ant-btn {
	background-image: none;
	background: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.015);
	color: #545454;
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
	height: 36px;
	line-height: 1.3;
	margin-bottom: 15px;
	padding: 0 15px;
	position: relative;
	text-align: center;
	touch-action: manipulation;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	white-space: nowrap;
}

.ant-btn>.anticon {
	line-height: 1;
}

.ant-btn,
.ant-btn:active,
.ant-btn:focus {
	outline: 0;
}

.ant-btn:not([disabled]):hover {
	text-decoration: none;
}

.ant-btn:not([disabled]):active {
	box-shadow: none;
	outline: 0;
}

.ant-btn[disabled] {
	cursor: not-allowed;
}

.ant-btn[disabled]>* {
	pointer-events: none;
}

.ant-btn-lg {
	border-radius: 4px;
	font-size: 16px;
	height: 40px;
	padding: 8.6px 15px;
}

.ant-btn-sm {
	border-radius: 4px;
	font-size: 12px;
	height: 24px;
	padding: 3.2px 7px;
}

.ant-btn:focus,
.ant-btn:hover {
	text-decoration: none;
}

.ant-btn.focus,
.ant-btn:focus {
	outline: 0;
}

.ant-btn.disabled,
.ant-btn:disabled {
	opacity: 0.65;
}

.ant-btn:not([disabled]):not(.disabled) {
	cursor: pointer;
}

.oitis-btn-list .ant-btn {
	margin-bottom: 6px;
}

.oitis-sub-popover .ant-btn {
	border: 0;
	margin-bottom: 5px;
}

.oitis-sub-popover .ant-btn:focus,
.oitis-sub-popover .ant-btn:hover {
	background-color: #f5f5f5;
}

.oitis-sub-popover .ant-btn:not(:last-child) {
	margin-right: 5px;
}

.ant-btn>a:only-child {
	color: currentColor;
}

.ant-btn>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn:focus,
.ant-btn:hover {
	background: #fff;
	border-color: #28aaeb;
	color: #28aaeb;
}

.ant-btn:focus>a:only-child,
.ant-btn:hover>a:only-child {
	color: currentColor;
}

.ant-btn:focus>a:only-child:after,
.ant-btn:hover>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn:active {
	background: #fff;
	border-color: #006eb8;
	color: #006eb8;
}

.ant-btn:active>a:only-child {
	color: currentColor;
}

.ant-btn:active>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn[disabled],
.ant-btn[disabled]:active,
.ant-btn[disabled]:focus,
.ant-btn[disabled]:hover {
	background: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	color: rgba(0, 0, 0, 0.25);
	text-shadow: none;
}

.ant-btn[disabled]:active>a:only-child,
.ant-btn[disabled]:focus>a:only-child,
.ant-btn[disabled]:hover>a:only-child,
.ant-btn[disabled]>a:only-child {
	color: currentColor;
}

.ant-btn[disabled]:active>a:only-child:after,
.ant-btn[disabled]:focus>a:only-child:after,
.ant-btn[disabled]:hover>a:only-child:after,
.ant-btn[disabled]>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn:active,
.ant-btn:focus,
.ant-btn:hover {
	background: #fff;
	text-decoration: none;
}

.ant-btn>span {
	display: inline-block;
}

.ant-btn-primary {
	background: #038fde;
	background-color: #038fde;
	border-color: #038fde;
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
	color: #fff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
}

.ant-btn-primary>a:only-child {
	color: currentColor;
}

.ant-btn-primary>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-primary:focus,
.ant-btn-primary:hover {
	background: #28aaeb;
}

.ant-btn-primary:focus>a:only-child,
.ant-btn-primary:hover>a:only-child {
	color: currentColor;
}

.ant-btn-primary:focus>a:only-child:after,
.ant-btn-primary:hover>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-primary:active {
	background: #006eb8;
	border-color: #006eb8;
	color: #fff;
}

.ant-btn-primary:active>a:only-child {
	color: currentColor;
}

.ant-btn-primary:active>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:active,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
	background: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	color: rgba(0, 0, 0, 0.25);
	text-shadow: none;
}

.ant-btn-primary[disabled]:active>a:only-child,
.ant-btn-primary[disabled]:focus>a:only-child,
.ant-btn-primary[disabled]:hover>a:only-child,
.ant-btn-primary[disabled]>a:only-child {
	color: currentColor;
}

.ant-btn-primary[disabled]:active>a:only-child:after,
.ant-btn-primary[disabled]:focus>a:only-child:after,
.ant-btn-primary[disabled]:hover>a:only-child:after,
.ant-btn-primary[disabled]>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-primary:focus,
.ant-btn-primary:hover {
	background-color: #28aaeb;
	border-color: #28aaeb;
	color: #fff;
}

.ant-btn-primary:not([disabled]):not(.disabled).active,
.ant-btn-primary:not([disabled]):not(.disabled):active {
	background-color: #038fde;
	border-color: #038fde;
	color: #fff;
}

.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child) {
	border-left-color: #28aaeb;
	border-right-color: #28aaeb;
}

.ant-btn-group .ant-btn-primary:not(:first-child):not(:last-child):disabled {
	border-color: #d9d9d9;
}

.ant-btn-group .ant-btn-primary:first-child:not(:last-child) {
	border-right-color: #28aaeb;
}

.ant-btn-group .ant-btn-primary:first-child:not(:last-child)[disabled] {
	border-right-color: #d9d9d9;
}

.ant-btn-group .ant-btn-primary+.ant-btn-primary,
.ant-btn-group .ant-btn-primary:last-child:not(:first-child) {
	border-left-color: #28aaeb;
}

.ant-btn-group .ant-btn-primary+.ant-btn-primary[disabled],
.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled] {
	border-left-color: #d9d9d9;
}

.ant-btn-ghost {
	background: transparent;
	border-color: #d9d9d9;
	color: #545454;
}

.ant-btn-ghost>a:only-child {
	color: currentColor;
}

.ant-btn-ghost>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-ghost:focus,
.ant-btn-ghost:hover {
	background: transparent;
	border-color: #28aaeb;
	color: #28aaeb;
}

.ant-btn-ghost:focus>a:only-child,
.ant-btn-ghost:hover>a:only-child {
	color: currentColor;
}

.ant-btn-ghost:focus>a:only-child:after,
.ant-btn-ghost:hover>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-ghost:active {
	background: transparent;
	border-color: #006eb8;
	color: #006eb8;
}

.ant-btn-ghost:active>a:only-child {
	color: currentColor;
}

.ant-btn-ghost:active>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-ghost[disabled],
.ant-btn-ghost[disabled]:active,
.ant-btn-ghost[disabled]:focus,
.ant-btn-ghost[disabled]:hover {
	background: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	color: rgba(0, 0, 0, 0.25);
	text-shadow: none;
}

.ant-btn-ghost[disabled]:active>a:only-child,
.ant-btn-ghost[disabled]:focus>a:only-child,
.ant-btn-ghost[disabled]:hover>a:only-child,
.ant-btn-ghost[disabled]>a:only-child {
	color: currentColor;
}

.ant-btn-ghost[disabled]:active>a:only-child:after,
.ant-btn-ghost[disabled]:focus>a:only-child:after,
.ant-btn-ghost[disabled]:hover>a:only-child:after,
.ant-btn-ghost[disabled]>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-dashed {
	background: #fff;
	border-color: #d9d9d9;
	border-style: dashed;
	color: #545454;
}

.ant-btn-dashed>a:only-child {
	color: currentColor;
}

.ant-btn-dashed>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-dashed:focus,
.ant-btn-dashed:hover {
	background: #fff;
	border-color: #28aaeb;
	color: #28aaeb;
}

.ant-btn-dashed:focus>a:only-child,
.ant-btn-dashed:hover>a:only-child {
	color: currentColor;
}

.ant-btn-dashed:focus>a:only-child:after,
.ant-btn-dashed:hover>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-dashed:active {
	background: #fff;
	border-color: #006eb8;
	color: #006eb8;
}

.ant-btn-dashed:active>a:only-child {
	color: currentColor;
}

.ant-btn-dashed:active>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-dashed[disabled],
.ant-btn-dashed[disabled]:active,
.ant-btn-dashed[disabled]:focus,
.ant-btn-dashed[disabled]:hover {
	background: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	color: rgba(0, 0, 0, 0.25);
	text-shadow: none;
}

.ant-btn-dashed[disabled]:active>a:only-child,
.ant-btn-dashed[disabled]:focus>a:only-child,
.ant-btn-dashed[disabled]:hover>a:only-child,
.ant-btn-dashed[disabled]>a:only-child {
	color: currentColor;
}

.ant-btn-dashed[disabled]:active>a:only-child:after,
.ant-btn-dashed[disabled]:focus>a:only-child:after,
.ant-btn-dashed[disabled]:hover>a:only-child:after,
.ant-btn-dashed[disabled]>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-danger {
	background: #f5222d;
	border-color: #f5222d;
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
	color: #fff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
}

.ant-btn-danger>a:only-child {
	color: currentColor;
}

.ant-btn-danger>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-danger:focus,
.ant-btn-danger:hover {
	background: #ff4d4f;
	border-color: #ff4d4f;
	color: #fff;
}

.ant-btn-danger:focus>a:only-child,
.ant-btn-danger:hover>a:only-child {
	color: currentColor;
}

.ant-btn-danger:focus>a:only-child:after,
.ant-btn-danger:hover>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-danger:active {
	background: #cf1322;
	border-color: #cf1322;
	color: #fff;
}

.ant-btn-danger:active>a:only-child {
	color: currentColor;
}

.ant-btn-danger:active>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-danger[disabled],
.ant-btn-danger[disabled]:active,
.ant-btn-danger[disabled]:focus,
.ant-btn-danger[disabled]:hover {
	background: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	color: rgba(0, 0, 0, 0.25);
	text-shadow: none;
}

.ant-btn-danger[disabled]:active>a:only-child,
.ant-btn-danger[disabled]:focus>a:only-child,
.ant-btn-danger[disabled]:hover>a:only-child,
.ant-btn-danger[disabled]>a:only-child {
	color: currentColor;
}

.ant-btn-danger[disabled]:active>a:only-child:after,
.ant-btn-danger[disabled]:focus>a:only-child:after,
.ant-btn-danger[disabled]:hover>a:only-child:after,
.ant-btn-danger[disabled]>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-link {
	background: transparent;
	border-color: transparent;
	box-shadow: none;
	color: #038fde;
}

.ant-btn-link>a:only-child {
	color: currentColor;
}

.ant-btn-link>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-link:focus,
.ant-btn-link:hover {
	background: transparent;
	border-color: #28aaeb;
	color: #28aaeb;
}

.ant-btn-link:focus>a:only-child,
.ant-btn-link:hover>a:only-child {
	color: currentColor;
}

.ant-btn-link:focus>a:only-child:after,
.ant-btn-link:hover>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-link:active {
	background: transparent;
	border-color: #006eb8;
	color: #006eb8;
}

.ant-btn-link:active>a:only-child {
	color: currentColor;
}

.ant-btn-link:active>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-link[disabled],
.ant-btn-link[disabled]:active,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:hover {
	background: #f5f5f5;
	border-color: #d9d9d9;
}

.ant-btn-link:hover {
	background: transparent;
}

.ant-btn-link:active,
.ant-btn-link:focus,
.ant-btn-link:hover {
	border-color: transparent;
}

.ant-btn-link[disabled],
.ant-btn-link[disabled]:active,
.ant-btn-link[disabled]:focus,
.ant-btn-link[disabled]:hover {
	background: transparent;
	border-color: transparent;
	box-shadow: none;
	color: rgba(0, 0, 0, 0.25);
	text-shadow: none;
}

.ant-btn-link[disabled]:active>a:only-child,
.ant-btn-link[disabled]:focus>a:only-child,
.ant-btn-link[disabled]:hover>a:only-child,
.ant-btn-link[disabled]>a:only-child {
	color: currentColor;
}

.ant-btn-link[disabled]:active>a:only-child:after,
.ant-btn-link[disabled]:focus>a:only-child:after,
.ant-btn-link[disabled]:hover>a:only-child:after,
.ant-btn-link[disabled]>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-text {
	background: transparent;
	border-color: transparent;
	box-shadow: none;
	color: #545454;
}

.ant-btn-text>a:only-child {
	color: currentColor;
}

.ant-btn-text>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-text:focus,
.ant-btn-text:hover {
	background: transparent;
	border-color: #28aaeb;
	color: #28aaeb;
}

.ant-btn-text:focus>a:only-child,
.ant-btn-text:hover>a:only-child {
	color: currentColor;
}

.ant-btn-text:focus>a:only-child:after,
.ant-btn-text:hover>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-text:active {
	background: transparent;
	border-color: #006eb8;
	color: #006eb8;
}

.ant-btn-text:active>a:only-child {
	color: currentColor;
}

.ant-btn-text:active>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-text[disabled],
.ant-btn-text[disabled]:active,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:hover {
	background: #f5f5f5;
	border-color: #d9d9d9;
}

.ant-btn-text:focus,
.ant-btn-text:hover {
	background: rgba(0, 0, 0, 0.018);
	border-color: transparent;
	color: #545454;
}

.ant-btn-text:active {
	background: rgba(0, 0, 0, 0.028);
	border-color: transparent;
	color: #545454;
}

.ant-btn-text[disabled],
.ant-btn-text[disabled]:active,
.ant-btn-text[disabled]:focus,
.ant-btn-text[disabled]:hover {
	background: transparent;
	border-color: transparent;
	box-shadow: none;
	color: rgba(0, 0, 0, 0.25);
	text-shadow: none;
}

.ant-btn-text[disabled]:active>a:only-child,
.ant-btn-text[disabled]:focus>a:only-child,
.ant-btn-text[disabled]:hover>a:only-child,
.ant-btn-text[disabled]>a:only-child {
	color: currentColor;
}

.ant-btn-text[disabled]:active>a:only-child:after,
.ant-btn-text[disabled]:focus>a:only-child:after,
.ant-btn-text[disabled]:hover>a:only-child:after,
.ant-btn-text[disabled]>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-dangerous {
	background: #fff;
	border-color: #f5222d;
	color: #f5222d;
}

.ant-btn-dangerous>a:only-child {
	color: currentColor;
}

.ant-btn-dangerous>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-dangerous:focus,
.ant-btn-dangerous:hover {
	background: #fff;
	border-color: #ff4d4f;
	color: #ff4d4f;
}

.ant-btn-dangerous:focus>a:only-child,
.ant-btn-dangerous:hover>a:only-child {
	color: currentColor;
}

.ant-btn-dangerous:focus>a:only-child:after,
.ant-btn-dangerous:hover>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-dangerous:active {
	background: #fff;
	border-color: #cf1322;
	color: #cf1322;
}

.ant-btn-dangerous:active>a:only-child {
	color: currentColor;
}

.ant-btn-dangerous:active>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-dangerous[disabled],
.ant-btn-dangerous[disabled]:active,
.ant-btn-dangerous[disabled]:focus,
.ant-btn-dangerous[disabled]:hover {
	background: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	color: rgba(0, 0, 0, 0.25);
	text-shadow: none;
}

.ant-btn-dangerous[disabled]:active>a:only-child,
.ant-btn-dangerous[disabled]:focus>a:only-child,
.ant-btn-dangerous[disabled]:hover>a:only-child,
.ant-btn-dangerous[disabled]>a:only-child {
	color: currentColor;
}

.ant-btn-dangerous[disabled]:active>a:only-child:after,
.ant-btn-dangerous[disabled]:focus>a:only-child:after,
.ant-btn-dangerous[disabled]:hover>a:only-child:after,
.ant-btn-dangerous[disabled]>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-dangerous.ant-btn-primary {
	background: #f5222d;
	border-color: #f5222d;
	box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
	color: #fff;
	text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
}

.ant-btn-dangerous.ant-btn-primary>a:only-child {
	color: currentColor;
}

.ant-btn-dangerous.ant-btn-primary>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-dangerous.ant-btn-primary:focus,
.ant-btn-dangerous.ant-btn-primary:hover {
	background: #ff4d4f;
	border-color: #ff4d4f;
	color: #fff;
}

.ant-btn-dangerous.ant-btn-primary:focus>a:only-child,
.ant-btn-dangerous.ant-btn-primary:hover>a:only-child {
	color: currentColor;
}

.ant-btn-dangerous.ant-btn-primary:focus>a:only-child:after,
.ant-btn-dangerous.ant-btn-primary:hover>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-dangerous.ant-btn-primary:active {
	background: #cf1322;
	border-color: #cf1322;
	color: #fff;
}

.ant-btn-dangerous.ant-btn-primary:active>a:only-child {
	color: currentColor;
}

.ant-btn-dangerous.ant-btn-primary:active>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-dangerous.ant-btn-primary[disabled],
.ant-btn-dangerous.ant-btn-primary[disabled]:active,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover {
	background: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	color: rgba(0, 0, 0, 0.25);
	text-shadow: none;
}

.ant-btn-dangerous.ant-btn-primary[disabled]:active>a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus>a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover>a:only-child,
.ant-btn-dangerous.ant-btn-primary[disabled]>a:only-child {
	color: currentColor;
}

.ant-btn-dangerous.ant-btn-primary[disabled]:active>a:only-child:after,
.ant-btn-dangerous.ant-btn-primary[disabled]:focus>a:only-child:after,
.ant-btn-dangerous.ant-btn-primary[disabled]:hover>a:only-child:after,
.ant-btn-dangerous.ant-btn-primary[disabled]>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-dangerous.ant-btn-link {
	background: transparent;
	border-color: transparent;
	box-shadow: none;
	color: #f5222d;
}

.ant-btn-dangerous.ant-btn-link>a:only-child {
	color: currentColor;
}

.ant-btn-dangerous.ant-btn-link>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-dangerous.ant-btn-link:focus,
.ant-btn-dangerous.ant-btn-link:hover {
	border-color: #28aaeb;
	color: #28aaeb;
}

.ant-btn-dangerous.ant-btn-link:active {
	border-color: #006eb8;
	color: #006eb8;
}

.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:active,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:hover {
	background: #f5f5f5;
	border-color: #d9d9d9;
}

.ant-btn-dangerous.ant-btn-link:focus,
.ant-btn-dangerous.ant-btn-link:hover {
	background: transparent;
	border-color: transparent;
	color: #ff4d4f;
}

.ant-btn-dangerous.ant-btn-link:focus>a:only-child,
.ant-btn-dangerous.ant-btn-link:hover>a:only-child {
	color: currentColor;
}

.ant-btn-dangerous.ant-btn-link:focus>a:only-child:after,
.ant-btn-dangerous.ant-btn-link:hover>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-dangerous.ant-btn-link:active {
	background: transparent;
	border-color: transparent;
	color: #cf1322;
}

.ant-btn-dangerous.ant-btn-link:active>a:only-child {
	color: currentColor;
}

.ant-btn-dangerous.ant-btn-link:active>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-dangerous.ant-btn-link[disabled]:active,
.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-dangerous.ant-btn-link[disabled]:hover {
	background: transparent;
	border-color: transparent;
	box-shadow: none;
	color: rgba(0, 0, 0, 0.25);
	text-shadow: none;
}

.ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child,
.ant-btn-dangerous.ant-btn-link[disabled]>a:only-child {
	color: currentColor;
}

.ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child:after,
.ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child:after,
.ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child:after,
.ant-btn-dangerous.ant-btn-link[disabled]>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-dangerous.ant-btn-text {
	background: transparent;
	border-color: transparent;
	box-shadow: none;
	color: #f5222d;
}

.ant-btn-dangerous.ant-btn-text>a:only-child {
	color: currentColor;
}

.ant-btn-dangerous.ant-btn-text>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-dangerous.ant-btn-text:focus,
.ant-btn-dangerous.ant-btn-text:hover {
	background: transparent;
	border-color: #28aaeb;
	color: #28aaeb;
}

.ant-btn-dangerous.ant-btn-text:active {
	background: transparent;
	border-color: #006eb8;
	color: #006eb8;
}

.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:active,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:hover {
	background: #f5f5f5;
	border-color: #d9d9d9;
}

.ant-btn-dangerous.ant-btn-text:focus,
.ant-btn-dangerous.ant-btn-text:hover {
	background: rgba(0, 0, 0, 0.018);
	border-color: transparent;
	color: #ff4d4f;
}

.ant-btn-dangerous.ant-btn-text:focus>a:only-child,
.ant-btn-dangerous.ant-btn-text:hover>a:only-child {
	color: currentColor;
}

.ant-btn-dangerous.ant-btn-text:focus>a:only-child:after,
.ant-btn-dangerous.ant-btn-text:hover>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-dangerous.ant-btn-text:active {
	background: rgba(0, 0, 0, 0.028);
	border-color: transparent;
	color: #cf1322;
}

.ant-btn-dangerous.ant-btn-text:active>a:only-child {
	color: currentColor;
}

.ant-btn-dangerous.ant-btn-text:active>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-dangerous.ant-btn-text[disabled],
.ant-btn-dangerous.ant-btn-text[disabled]:active,
.ant-btn-dangerous.ant-btn-text[disabled]:focus,
.ant-btn-dangerous.ant-btn-text[disabled]:hover {
	background: transparent;
	border-color: transparent;
	box-shadow: none;
	color: rgba(0, 0, 0, 0.25);
	text-shadow: none;
}

.ant-btn-dangerous.ant-btn-text[disabled]:active>a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:focus>a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]:hover>a:only-child,
.ant-btn-dangerous.ant-btn-text[disabled]>a:only-child {
	color: currentColor;
}

.ant-btn-dangerous.ant-btn-text[disabled]:active>a:only-child:after,
.ant-btn-dangerous.ant-btn-text[disabled]:focus>a:only-child:after,
.ant-btn-dangerous.ant-btn-text[disabled]:hover>a:only-child:after,
.ant-btn-dangerous.ant-btn-text[disabled]>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-icon-only {
	border-radius: 4px;
	font-size: 16px;
	height: 36px;
	padding: 6.6px 0;
	vertical-align: -1px;
	width: 36px;
}

.ant-btn-icon-only>* {
	font-size: 16px;
}

.ant-btn-icon-only.ant-btn-lg {
	border-radius: 4px;
	font-size: 18px;
	height: 40px;
	padding: 7.3px 0;
	width: 40px;
}

.ant-btn-icon-only.ant-btn-lg>* {
	font-size: 18px;
}

.ant-btn-icon-only.ant-btn-sm {
	border-radius: 4px;
	font-size: 14px;
	height: 24px;
	padding: 1.9px 0;
	width: 24px;
}

.ant-btn-icon-only.ant-btn-sm>* {
	font-size: 14px;
}

.ant-btn-round {
	border-radius: 36px;
	font-size: 14px;
	height: 36px;
	padding: 7.9px 18px;
}

.ant-btn-round.ant-btn-lg {
	border-radius: 40px;
	font-size: 16px;
	height: 40px;
	padding: 8.6px 20px;
}

.ant-btn-round.ant-btn-sm {
	border-radius: 24px;
	font-size: 14px;
	height: 24px;
	padding: 1.9px 12px;
}

.ant-btn-round.ant-btn-icon-only {
	width: auto;
}

.ant-btn-circle {
	border-radius: 50%;
	min-width: 36px;
	padding-left: 0;
	padding-right: 0;
	text-align: center;
}

.ant-btn-circle.ant-btn-lg {
	border-radius: 50%;
	min-width: 40px;
}

.ant-btn-circle.ant-btn-sm {
	border-radius: 50%;
	min-width: 24px;
}

.ant-btn:before {
	background: #fff;
	border-radius: inherit;
	bottom: -1px;
	content: '';
	display: none;
	left: -1px;
	opacity: 0.35;
	pointer-events: none;
	position: absolute;
	right: -1px;
	top: -1px;
	transition: opacity 0.2s;
	z-index: 1;
}

.ant-btn .anticon {
	transition: margin-left 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-btn .anticon.anticon-minus>svg,
.ant-btn .anticon.anticon-plus>svg {
	shape-rendering: optimizeSpeed;
}

.ant-btn.ant-btn-loading {
	position: relative;
}

.ant-btn.ant-btn-loading:not([disabled]) {
	pointer-events: none;
}

.ant-btn.ant-btn-loading:before {
	display: block;
}

.ant-btn>.ant-btn-loading-icon {
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-btn>.ant-btn-loading-icon .anticon {
	-webkit-animation: none;
	animation: none;
	padding-right: 8px;
}

.ant-btn>.ant-btn-loading-icon .anticon svg {
	-webkit-animation: loadingCircle 1s linear infinite;
	animation: loadingCircle 1s linear infinite;
}

.ant-btn-group {
	display: inline-flex;
}

.ant-btn-group,
.ant-btn-group>.ant-btn,
.ant-btn-group>span>.ant-btn {
	position: relative;
}

.ant-btn-group>.ant-btn:active,
.ant-btn-group>.ant-btn:focus,
.ant-btn-group>.ant-btn:hover,
.ant-btn-group>span>.ant-btn:active,
.ant-btn-group>span>.ant-btn:focus,
.ant-btn-group>span>.ant-btn:hover {
	z-index: 2;
}

.ant-btn-group>.ant-btn[disabled],
.ant-btn-group>span>.ant-btn[disabled] {
	z-index: 0;
}

.ant-btn-group .ant-btn-icon-only {
	font-size: 14px;
}

.ant-btn-group-lg>.ant-btn,
.ant-btn-group-lg>span>.ant-btn {
	border-radius: 0;
	font-size: 16px;
	height: 40px;
	padding: 8.6px 15px;
}

.ant-btn-group-lg .ant-btn.ant-btn-icon-only {
	height: 40px;
	padding-left: 0;
	padding-right: 0;
	width: 40px;
}

.ant-btn-group-sm>.ant-btn,
.ant-btn-group-sm>span>.ant-btn {
	border-radius: 0;
	font-size: 14px;
	height: 24px;
	padding: 1.9px 7px;
}

.ant-btn-group-sm>.ant-btn>.anticon,
.ant-btn-group-sm>span>.ant-btn>.anticon {
	font-size: 14px;
}

.ant-btn-group-sm .ant-btn.ant-btn-icon-only {
	height: 24px;
	padding-left: 0;
	padding-right: 0;
	width: 24px;
}

.ant-btn+.ant-btn-group,
.ant-btn-group .ant-btn+.ant-btn,
.ant-btn-group .ant-btn+span,
.ant-btn-group span+.ant-btn,
.ant-btn-group+.ant-btn,
.ant-btn-group+.ant-btn-group,
.ant-btn-group>span+span {
	margin-left: -1px;
}

.ant-btn-group .ant-btn-primary+.ant-btn:not(.ant-btn-primary):not([disabled]) {
	border-left-color: transparent;
}

.ant-btn-group .ant-btn {
	border-radius: 0;
}

.ant-btn-group>.ant-btn:first-child,
.ant-btn-group>span:first-child>.ant-btn {
	margin-left: 0;
}

.ant-btn-group>.ant-btn:only-child,
.ant-btn-group>span:only-child>.ant-btn {
	border-radius: 4px;
}

.ant-btn-group>.ant-btn:first-child:not(:last-child),
.ant-btn-group>span:first-child:not(:last-child)>.ant-btn {
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px;
}

.ant-btn-group>.ant-btn:last-child:not(:first-child),
.ant-btn-group>span:last-child:not(:first-child)>.ant-btn {
	border-bottom-right-radius: 4px;
	border-top-right-radius: 4px;
}

.ant-btn-group-sm>.ant-btn:only-child,
.ant-btn-group-sm>span:only-child>.ant-btn {
	border-radius: 4px;
}

.ant-btn-group-sm>.ant-btn:first-child:not(:last-child),
.ant-btn-group-sm>span:first-child:not(:last-child)>.ant-btn {
	border-bottom-left-radius: 4px;
	border-top-left-radius: 4px;
}

.ant-btn-group-sm>.ant-btn:last-child:not(:first-child),
.ant-btn-group-sm>span:last-child:not(:first-child)>.ant-btn {
	border-bottom-right-radius: 4px;
	border-top-right-radius: 4px;
}

.ant-btn-group>.ant-btn-group {
	float: left;
}

.ant-btn-group>.ant-btn-group:not(:first-child):not(:last-child)>.ant-btn {
	border-radius: 0;
}

.ant-btn-group>.ant-btn-group:first-child:not(:last-child)>.ant-btn:last-child {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
	padding-right: 8px;
}

.ant-btn-group>.ant-btn-group:last-child:not(:first-child)>.ant-btn:first-child {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
	padding-left: 8px;
}

.ant-btn-group-rtl.ant-btn+.ant-btn-group,
.ant-btn-group-rtl.ant-btn-group .ant-btn+.ant-btn,
.ant-btn-group-rtl.ant-btn-group .ant-btn+span,
.ant-btn-group-rtl.ant-btn-group span+.ant-btn,
.ant-btn-group-rtl.ant-btn-group+.ant-btn,
.ant-btn-group-rtl.ant-btn-group+.ant-btn-group,
.ant-btn-group-rtl.ant-btn-group>span+span,
.ant-btn-rtl.ant-btn+.ant-btn-group,
.ant-btn-rtl.ant-btn-group .ant-btn+.ant-btn,
.ant-btn-rtl.ant-btn-group .ant-btn+span,
.ant-btn-rtl.ant-btn-group span+.ant-btn,
.ant-btn-rtl.ant-btn-group+.ant-btn,
.ant-btn-rtl.ant-btn-group+.ant-btn-group,
.ant-btn-rtl.ant-btn-group>span+span {
	margin-left: auto;
	margin-right: -1px;
}

.ant-btn-group.ant-btn-group-rtl {
	direction: rtl;
}

.ant-btn-group-rtl.ant-btn-group>.ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group>span:first-child:not(:last-child)>.ant-btn {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 4px;
	border-top-left-radius: 0;
	border-top-right-radius: 4px;
}

.ant-btn-group-rtl.ant-btn-group>.ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group>span:last-child:not(:first-child)>.ant-btn {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 0;
	border-top-left-radius: 4px;
	border-top-right-radius: 0;
}

.ant-btn-group-rtl.ant-btn-group-sm>.ant-btn:first-child:not(:last-child),
.ant-btn-group-rtl.ant-btn-group-sm>span:first-child:not(:last-child)>.ant-btn {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 4px;
	border-top-left-radius: 0;
	border-top-right-radius: 4px;
}

.ant-btn-group-rtl.ant-btn-group-sm>.ant-btn:last-child:not(:first-child),
.ant-btn-group-rtl.ant-btn-group-sm>span:last-child:not(:first-child)>.ant-btn {
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 0;
	border-top-left-radius: 4px;
	border-top-right-radius: 0;
}

.ant-btn:active>span,
.ant-btn:focus>span {
	position: relative;
}

.ant-btn>.anticon+span,
.ant-btn>span+.anticon {
	margin-left: 8px;
}

.ant-btn-background-ghost {
	background: transparent !important;
	border-color: #fff;
	color: #fff;
}

.ant-btn-background-ghost.ant-btn-primary {
	background: transparent;
	border-color: #038fde;
	color: #038fde;
	text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-primary>a:only-child {
	color: currentColor;
}

.ant-btn-background-ghost.ant-btn-primary>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-background-ghost.ant-btn-primary:focus,
.ant-btn-background-ghost.ant-btn-primary:hover {
	background: transparent;
	border-color: #28aaeb;
	color: #28aaeb;
}

.ant-btn-background-ghost.ant-btn-primary:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-primary:hover>a:only-child {
	color: currentColor;
}

.ant-btn-background-ghost.ant-btn-primary:focus>a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary:hover>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-background-ghost.ant-btn-primary:active {
	background: transparent;
	border-color: #006eb8;
	color: #006eb8;
}

.ant-btn-background-ghost.ant-btn-primary:active>a:only-child {
	color: currentColor;
}

.ant-btn-background-ghost.ant-btn-primary:active>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-background-ghost.ant-btn-primary[disabled],
.ant-btn-background-ghost.ant-btn-primary[disabled]:active,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover {
	background: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	color: rgba(0, 0, 0, 0.25);
	text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-primary[disabled]:active>a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-primary[disabled]>a:only-child {
	color: currentColor;
}

.ant-btn-background-ghost.ant-btn-primary[disabled]:active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:focus>a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary[disabled]:hover>a:only-child:after,
.ant-btn-background-ghost.ant-btn-primary[disabled]>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-background-ghost.ant-btn-danger {
	background: transparent;
	border-color: #f5222d;
	color: #f5222d;
	text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-danger>a:only-child {
	color: currentColor;
}

.ant-btn-background-ghost.ant-btn-danger>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-background-ghost.ant-btn-danger:focus,
.ant-btn-background-ghost.ant-btn-danger:hover {
	background: transparent;
	border-color: #ff4d4f;
	color: #ff4d4f;
}

.ant-btn-background-ghost.ant-btn-danger:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-danger:hover>a:only-child {
	color: currentColor;
}

.ant-btn-background-ghost.ant-btn-danger:focus>a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger:hover>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-background-ghost.ant-btn-danger:active {
	background: transparent;
	border-color: #cf1322;
	color: #cf1322;
}

.ant-btn-background-ghost.ant-btn-danger:active>a:only-child {
	color: currentColor;
}

.ant-btn-background-ghost.ant-btn-danger:active>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-background-ghost.ant-btn-danger[disabled],
.ant-btn-background-ghost.ant-btn-danger[disabled]:active,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover {
	background: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	color: rgba(0, 0, 0, 0.25);
	text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-danger[disabled]:active>a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-danger[disabled]>a:only-child {
	color: currentColor;
}

.ant-btn-background-ghost.ant-btn-danger[disabled]:active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:focus>a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger[disabled]:hover>a:only-child:after,
.ant-btn-background-ghost.ant-btn-danger[disabled]>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-background-ghost.ant-btn-dangerous {
	background: transparent;
	border-color: #f5222d;
	color: #f5222d;
	text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-dangerous>a:only-child {
	color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-background-ghost.ant-btn-dangerous:focus,
.ant-btn-background-ghost.ant-btn-dangerous:hover {
	background: transparent;
	border-color: #ff4d4f;
	color: #ff4d4f;
}

.ant-btn-background-ghost.ant-btn-dangerous:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous:hover>a:only-child {
	color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous:focus>a:only-child:after,
.ant-btn-background-ghost.ant-btn-dangerous:hover>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-background-ghost.ant-btn-dangerous:active {
	background: transparent;
	border-color: #cf1322;
	color: #cf1322;
}

.ant-btn-background-ghost.ant-btn-dangerous:active>a:only-child {
	color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous:active>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-background-ghost.ant-btn-dangerous[disabled],
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover {
	background: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	color: rgba(0, 0, 0, 0.25);
	text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]>a:only-child {
	color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous[disabled]:active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:focus>a:only-child:after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]:hover>a:only-child:after,
.ant-btn-background-ghost.ant-btn-dangerous[disabled]>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link {
	background: transparent;
	border-color: transparent;
	color: #f5222d;
	text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link>a:only-child {
	color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover {
	background: transparent;
	border-color: transparent;
	color: #ff4d4f;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover>a:only-child {
	color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:focus>a:only-child:after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:hover>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active {
	background: transparent;
	border-color: transparent;
	color: #cf1322;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active>a:only-child {
	color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link:active>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled],
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover {
	background: #f5f5f5;
	border-color: #d9d9d9;
	box-shadow: none;
	color: rgba(0, 0, 0, 0.25);
	text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]>a:only-child {
	color: currentColor;
}

.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:active>a:only-child:after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:focus>a:only-child:after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]:hover>a:only-child:after,
.ant-btn-background-ghost.ant-btn-dangerous.ant-btn-link[disabled]>a:only-child:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-btn-two-chinese-chars:first-letter {
	letter-spacing: 0.34em;
}

.ant-btn-two-chinese-chars> :not(.anticon) {
	letter-spacing: 0.34em;
	margin-right: -0.34em;
}

.ant-btn-block {
	width: 100%;
}

.ant-btn:empty {
	content: '\a0';
	display: inline-block;
	visibility: hidden;
	width: 0;
}

a.ant-btn {
	line-height: 34px;
	padding-top: 0.01px !important;
}

a.ant-btn-lg {
	line-height: 38px;
}

a.ant-btn-sm {
	line-height: 22px;
}

.ant-btn-rtl {
	direction: rtl;
}

.ant-btn-group-rtl.ant-btn-group .ant-btn-primary+.ant-btn-primary,
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child) {
	border-left-color: #d9d9d9;
	border-right-color: #28aaeb;
}

.ant-btn-group-rtl.ant-btn-group .ant-btn-primary+.ant-btn-primary[disabled],
.ant-btn-group-rtl.ant-btn-group .ant-btn-primary:last-child:not(:first-child)[disabled] {
	border-left-color: #28aaeb;
	border-right-color: #d9d9d9;
}

.ant-btn-rtl.ant-btn>.ant-btn-loading-icon .anticon {
	padding-left: 8px;
	padding-right: 0;
}

.ant-btn>.ant-btn-loading-icon:only-child .anticon {
	padding-left: 0;
	padding-right: 0;
}

.ant-btn-rtl.ant-btn>.anticon+span,
.ant-btn-rtl.ant-btn>span+.anticon {
	margin-left: 0;
	margin-right: 8px;
}

.ant-picker-calendar {
	background: #fff;
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
}

.ant-picker-calendar-header {
	display: flex;
	justify-content: flex-end;
	padding: 12px 0;
}

.ant-picker-calendar-header .ant-picker-calendar-year-select {
	min-width: 80px;
}

.ant-picker-calendar-header .ant-picker-calendar-month-select {
	margin-left: 8px;
	min-width: 70px;
}

.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
	margin-left: 8px;
}

.ant-picker-calendar .ant-picker-panel {
	background: #fff;
	border: 0;
	border-radius: 0;
	border-top: 1px solid #e8e8e8;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-date-panel,
.ant-picker-calendar .ant-picker-panel .ant-picker-month-panel {
	width: auto;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-body {
	padding: 8px 0;
}

.ant-picker-calendar .ant-picker-panel .ant-picker-content {
	width: 100%;
}

.ant-picker-calendar-mini {
	border-radius: 6px;
}

.ant-picker-calendar-mini .ant-picker-calendar-header {
	padding-left: 8px;
	padding-right: 8px;
}

.ant-picker-calendar-mini .ant-picker-panel {
	border-radius: 0 0 6px 6px;
}

.ant-picker-calendar-mini .ant-picker-content {
	height: 256px;
}

.ant-picker-calendar-mini .ant-picker-content th {
	height: auto;
	line-height: 18px;
	padding: 0;
}

.ant-picker-calendar-full .ant-picker-panel {
	background: #fff;
	border: 0;
	display: block;
	text-align: right;
	width: 100%;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body td,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
	padding: 0;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
	height: auto;
	line-height: 18px;
	padding: 0 12px 5px 0;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:before {
	display: none;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell:hover .ant-picker-calendar-date {
	background: #f5f5f5;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell .ant-picker-calendar-date-today:before {
	display: none;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today {
	background: #e6faff;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected .ant-picker-calendar-date-today .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date .ant-picker-calendar-date-value,
.ant-picker-calendar-full .ant-picker-panel .ant-picker-cell-selected:hover .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
	color: #038fde;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date {
	border: 0;
	border-radius: 0;
	border-top: 2px solid #e8e8e8;
	display: block;
	height: auto;
	margin: 0 4px;
	padding: 4px 8px 0;
	transition: background 0.3s;
	width: auto;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-value {
	line-height: 24px;
	transition: color 0.3s;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
	color: #545454;
	height: 86px;
	line-height: 1.3;
	overflow-y: auto;
	position: static;
	text-align: left;
	width: auto;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today {
	border-color: #038fde;
}

.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-today .ant-picker-calendar-date-value {
	color: #545454;
}

@media only screen and (max-width: 480px) {
	.ant-picker-calendar-header {
		display: block;
	}

	.ant-picker-calendar-header .ant-picker-calendar-year-select {
		width: 50%;
	}

	.ant-picker-calendar-header .ant-picker-calendar-month-select {
		width: calc(50% - 8px);
	}

	.ant-picker-calendar-header .ant-picker-calendar-mode-switch {
		margin-left: 0;
		margin-top: 8px;
		width: 100%;
	}

	.ant-picker-calendar-header .ant-picker-calendar-mode-switch>label {
		text-align: center;
		width: 50%;
	}
}

.ant-picker-calendar-rtl {
	direction: rtl;
}

.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-mode-switch,
.ant-picker-calendar-rtl .ant-picker-calendar-header .ant-picker-calendar-month-select {
	margin-left: 0;
	margin-right: 8px;
}

.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel {
	text-align: left;
}

.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
	padding: 0 0 5px 12px;
}

.ant-picker-calendar-rtl.ant-picker-calendar-full .ant-picker-panel .ant-picker-calendar-date-content {
	text-align: right;
}

.ant-card {
	background: #fff;
	border-radius: 6px;
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}

.ant-card-rtl {
	direction: rtl;
}

.ant-card-hoverable {
	cursor: pointer;
	transition: box-shadow 0.3s, border-color 0.3s;
}

.ant-card-hoverable:hover {
	border-color: transparent;
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
}

.ant-card-bordered {
	border: 1px solid #e8e8e8;
}

.ant-card-head {
	background: #fff;
	border-bottom: 1px solid #e8e8e8;
	border-radius: 6px 6px 0 0;
	color: #535353;
	font-size: 16px;
	font-weight: 500;
	margin-bottom: -1px;
	min-height: 48px;
	padding: 0 24px;
}

.ant-card-head:after,
.ant-card-head:before {
	content: '';
	display: table;
}

.ant-card-head:after {
	clear: both;
}

.ant-card-head-wrapper {
	align-items: center;
	display: flex;
}

.ant-card-head-title {
	display: inline-block;
	flex: 1 1;
	overflow: hidden;
	padding: 16px 0;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ant-card-head-title>.ant-typography,
.ant-card-head-title>.ant-typography-edit-content {
	left: 0;
	margin-bottom: 0;
	margin-top: 0;
}

.ant-card-head .ant-tabs {
	clear: both;
	color: #545454;
	font-size: 14px;
	font-weight: 400;
	margin-bottom: -17px;
}

.ant-card-head .ant-tabs-bar {
	border-bottom: 1px solid #e8e8e8;
}

.ant-card-extra {
	color: #545454;
	float: right;
	font-size: 14px;
	font-weight: 400;
	margin-left: auto;
	padding: 16px 0;
}

.ant-card-rtl .ant-card-extra {
	margin-left: 0;
	margin-right: auto;
}

.ant-card-body {
	padding: 24px;
}

.ant-card-body:after,
.ant-card-body:before {
	content: '';
	display: table;
}

.ant-card-body:after {
	clear: both;
}

.ant-card-contain-grid:not(.ant-card-loading) .ant-card-body {
	margin: -1px 0 0 -1px;
	padding: 0;
}

.ant-card-grid {
	border: 0;
	border-radius: 0;
	box-shadow: 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8,
		inset 1px 0 0 0 #e8e8e8, inset 0 1px 0 0 #e8e8e8;
	float: left;
	padding: 24px;
	transition: all 0.3s;
	width: 33.33%;
}

.ant-card-rtl .ant-card-grid {
	float: right;
}

.ant-card-grid-hoverable:hover {
	box-shadow: 0 2px 8px rgba(0, 0, 0, 0.09);
	position: relative;
	z-index: 1;
}

.ant-card-contain-tabs>.ant-card-head .ant-card-head-title {
	min-height: 32px;
	padding-bottom: 0;
}

.ant-card-contain-tabs>.ant-card-head .ant-card-extra {
	padding-bottom: 0;
}

.ant-card-bordered .ant-card-cover {
	margin-left: -1px;
	margin-right: -1px;
	margin-top: -1px;
}

.ant-card-cover>* {
	display: block;
	width: 100%;
}

.ant-card-cover img {
	border-radius: 6px 6px 0 0;
}

.ant-card-actions {
	background: #fafafa;
	border-top: 1px solid #e8e8e8;
	list-style: none;
	margin: 0;
	padding: 0;
}

.ant-card-actions:after,
.ant-card-actions:before {
	content: '';
	display: table;
}

.ant-card-actions:after {
	clear: both;
}

.ant-card-actions>li {
	color: #595959;
	float: left;
	margin: 12px 0;
	text-align: center;
}

.ant-card-rtl .ant-card-actions>li {
	float: right;
}

.ant-card-actions>li>span {
	cursor: pointer;
	display: block;
	font-size: 14px;
	line-height: 1.3;
	min-width: 32px;
	position: relative;
}

.ant-card-actions>li>span:hover {
	color: #038fde;
	transition: color 0.3s;
}

.ant-card-actions>li>span a:not(.ant-btn),
.ant-card-actions>li>span>.anticon {
	color: #595959;
	display: inline-block;
	line-height: 22px;
	transition: color 0.3s;
	width: 100%;
}

.ant-card-actions>li>span a:not(.ant-btn):hover,
.ant-card-actions>li>span>.anticon:hover {
	color: #038fde;
}

.ant-card-actions>li>span>.anticon {
	font-size: 16px;
	line-height: 22px;
}

.ant-card-actions>li:not(:last-child) {
	border-right: 1px solid #e8e8e8;
}

.ant-card-rtl .ant-card-actions>li:not(:last-child) {
	border-left: 1px solid #e8e8e8;
	border-right: none;
}

.ant-card-type-inner .ant-card-head {
	background: #fafafa;
	padding: 0 24px;
}

.ant-card-type-inner .ant-card-head-title {
	font-size: 14px;
	padding: 12px 0;
}

.ant-card-type-inner .ant-card-body {
	padding: 16px 24px;
}

.ant-card-type-inner .ant-card-extra {
	padding: 13.5px 0;
}

.ant-card-meta {
	margin: -4px 0;
}

.ant-card-meta:after,
.ant-card-meta:before {
	content: '';
	display: table;
}

.ant-card-meta:after {
	clear: both;
}

.ant-card-meta-avatar {
	float: left;
	padding-right: 16px;
}

.ant-card-rtl .ant-card-meta-avatar {
	float: right;
	padding-left: 16px;
	padding-right: 0;
}

.ant-card-meta-detail {
	overflow: hidden;
}

.ant-card-meta-detail>div:not(:last-child) {
	margin-bottom: 8px;
}

.ant-card-meta-title {
	color: #535353;
	font-size: 16px;
	font-weight: 500;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ant-card-meta-description {
	color: #595959;
}

.ant-card-loading {
	overflow: hidden;
}

.ant-card-loading .ant-card-body {
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ant-card-loading-content p {
	margin: 0;
}

.ant-card-loading-block {
	-webkit-animation: card-loading 1.4s ease infinite;
	animation: card-loading 1.4s ease infinite;
	background: linear-gradient(90deg,
			rgba(207, 216, 220, 0.2),
			rgba(207, 216, 220, 0.4),
			rgba(207, 216, 220, 0.2));
	background-size: 600% 600%;
	border-radius: 6px;
	height: 14px;
	margin: 4px 0;
}

@-webkit-keyframes card-loading {

	0%,
	to {
		background-position: 0 50%;
	}

	50% {
		background-position: 100% 50%;
	}
}

@keyframes card-loading {

	0%,
	to {
		background-position: 0 50%;
	}

	50% {
		background-position: 100% 50%;
	}
}

.ant-card-small>.ant-card-head {
	font-size: 14px;
	min-height: 36px;
	padding: 0 12px;
}

.ant-card-small>.ant-card-head>.ant-card-head-wrapper>.ant-card-head-title {
	padding: 8px 0;
}

.ant-card-small>.ant-card-head>.ant-card-head-wrapper>.ant-card-extra {
	font-size: 14px;
	padding: 8px 0;
}

.ant-card-small>.ant-card-body {
	padding: 12px;
}

.ant-carousel {
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
}

.ant-carousel .slick-slider {
	-webkit-touch-callout: none;
	-webkit-tap-highlight-color: transparent;
	box-sizing: border-box;
	display: block;
	position: relative;
	touch-action: pan-y;
}

.ant-carousel .slick-list {
	display: block;
	margin: 0;
	overflow: hidden;
	padding: 0;
	position: relative;
}

.ant-carousel .slick-list:focus {
	outline: none;
}

.ant-carousel .slick-list.dragging {
	cursor: pointer;
}

.ant-carousel .slick-list .slick-slide {
	pointer-events: none;
}

.ant-carousel .slick-list .slick-slide input.ant-checkbox-input,
.ant-carousel .slick-list .slick-slide input.ant-radio-input {
	visibility: hidden;
}

.ant-carousel .slick-list .slick-slide.slick-active {
	pointer-events: auto;
}

.ant-carousel .slick-list .slick-slide.slick-active input.ant-checkbox-input,
.ant-carousel .slick-list .slick-slide.slick-active input.ant-radio-input {
	visibility: visible;
}

.ant-carousel .slick-list .slick-slide>div>div {
	vertical-align: bottom;
}

.ant-carousel .slick-slider .slick-list,
.ant-carousel .slick-slider .slick-track {
	touch-action: pan-y;
	-webkit-transform: translateZ(0);
	transform: translateZ(0);
}

.ant-carousel .slick-track {
	display: block;
	left: 0;
	position: relative;
	top: 0;
}

.ant-carousel .slick-track:after,
.ant-carousel .slick-track:before {
	content: '';
	display: table;
}

.ant-carousel .slick-track:after {
	clear: both;
}

.slick-loading .ant-carousel .slick-track {
	visibility: hidden;
}

.ant-carousel .slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px;
}

.ant-carousel .slick-slide img {
	display: block;
}

.ant-carousel .slick-slide.slick-loading img {
	display: none;
}

.ant-carousel .slick-slide.dragging img {
	pointer-events: none;
}

.ant-carousel .slick-initialized .slick-slide {
	display: block;
}

.ant-carousel .slick-loading .slick-slide {
	visibility: hidden;
}

.ant-carousel .slick-vertical .slick-slide {
	display: block;
	height: auto;
}

.ant-carousel .slick-arrow.slick-hidden {
	display: none;
}

.ant-carousel .slick-next,
.ant-carousel .slick-prev {
	border: 0;
	cursor: pointer;
	display: block;
	font-size: 0;
	height: 20px;
	line-height: 0;
	margin-top: -10px;
	padding: 0;
	position: absolute;
	top: 50%;
	width: 20px;
}

.ant-carousel .slick-next,
.ant-carousel .slick-next:focus,
.ant-carousel .slick-next:hover,
.ant-carousel .slick-prev,
.ant-carousel .slick-prev:focus,
.ant-carousel .slick-prev:hover {
	background: transparent;
	color: transparent;
	outline: none;
}

.ant-carousel .slick-next:focus:before,
.ant-carousel .slick-next:hover:before,
.ant-carousel .slick-prev:focus:before,
.ant-carousel .slick-prev:hover:before {
	opacity: 1;
}

.ant-carousel .slick-next.slick-disabled:before,
.ant-carousel .slick-prev.slick-disabled:before {
	opacity: 0.25;
}

.ant-carousel .slick-prev {
	left: -25px;
}

.ant-carousel .slick-prev:before {
	content: 'Ã¢â€ Â';
}

.ant-carousel .slick-next {
	right: -25px;
}

.ant-carousel .slick-next:before {
	content: 'Ã¢â€ â€™';
}

.ant-carousel .slick-dots {
	bottom: 0;
	display: flex !important;
	justify-content: center;
	left: 0;
	list-style: none;
	margin-left: 15%;
	margin-right: 15%;
	padding-left: 0;
	position: absolute;
	right: 0;
	z-index: 15;
}

.ant-carousel .slick-dots-bottom {
	bottom: 12px;
}

.ant-carousel .slick-dots-top {
	bottom: auto;
	top: 12px;
}

.ant-carousel .slick-dots li {
	box-sizing: content-box;
	display: inline-block;
	flex: 0 1 auto;
	height: 3px;
	margin: 0 3px;
	padding: 0;
	position: relative;
	text-align: center;
	text-indent: -999px;
	transition: all 0.5s;
	vertical-align: top;
	width: 16px;
}

.ant-carousel .slick-dots li button {
	background: #fff;
	border: 0;
	border-radius: 1px;
	color: transparent;
	cursor: pointer;
	display: block;
	font-size: 0;
	height: 3px;
	opacity: 0.3;
	outline: none;
	padding: 0;
	transition: all 0.5s;
	width: 100%;
}

.ant-carousel .slick-dots li button:focus,
.ant-carousel .slick-dots li button:hover {
	opacity: 0.75;
}

.ant-carousel .slick-dots li.slick-active {
	width: 24px;
}

.ant-carousel .slick-dots li.slick-active button {
	background: #fff;
	opacity: 1;
}

.ant-carousel .slick-dots li.slick-active:focus,
.ant-carousel .slick-dots li.slick-active:hover {
	opacity: 1;
}

.ant-carousel-vertical .slick-dots {
	bottom: auto;
	flex-direction: column;
	height: auto;
	margin: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 3px;
}

.ant-carousel-vertical .slick-dots-left {
	left: 12px;
	right: auto;
}

.ant-carousel-vertical .slick-dots-right {
	left: auto;
	right: 12px;
}

.ant-carousel-vertical .slick-dots li {
	height: 16px;
	margin: 4px 2px;
	vertical-align: baseline;
	width: 3px;
}

.ant-carousel-vertical .slick-dots li button {
	height: 16px;
	width: 3px;
}

.ant-carousel-vertical .slick-dots li.slick-active,
.ant-carousel-vertical .slick-dots li.slick-active button {
	height: 24px;
	width: 3px;
}

.ant-carousel-rtl {
	direction: rtl;
}

.ant-carousel-rtl .ant-carousel .slick-track {
	left: auto;
	right: 0;
}

.ant-carousel-rtl .ant-carousel .slick-prev {
	left: auto;
	right: -25px;
}

.ant-carousel-rtl .ant-carousel .slick-prev:before {
	content: 'Ã¢â€ â€™';
}

.ant-carousel-rtl .ant-carousel .slick-next {
	left: -25px;
	right: auto;
}

.ant-carousel-rtl .ant-carousel .slick-next:before {
	content: 'Ã¢â€ Â';
}

.ant-carousel-rtl.ant-carousel .slick-dots {
	flex-direction: row-reverse;
}

.ant-carousel-rtl.ant-carousel-vertical .slick-dots {
	flex-direction: column;
}

.ant-cascader {
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
}

.ant-cascader-input.ant-input {
	background-color: transparent !important;
	cursor: pointer;
	padding-right: 24px;
	position: static;
	width: 100%;
}

.ant-cascader-picker-show-search .ant-cascader-input.ant-input {
	position: relative;
}

.ant-cascader-picker {
	background-color: #fff;
	border-radius: 6px;
	box-sizing: border-box;
	color: #545454;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	outline: 0;
	padding: 0;
	position: relative;
	transition: color 0.3s;
}

.ant-cascader-picker-with-value .ant-cascader-picker-label {
	color: transparent;
}

.ant-cascader-picker-disabled {
	background: #f5f5f5;
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-cascader-picker-disabled .ant-cascader-input {
	cursor: not-allowed;
}

.ant-cascader-picker:focus .ant-cascader-input {
	border-color: #28aaeb;
	border-right-width: 1px !important;
	box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
	outline: 0;
}

.ant-input-rtl .ant-cascader-picker:focus .ant-cascader-input {
	border-left-width: 1px !important;
	border-right-width: 0;
}

.ant-cascader-picker-borderless .ant-cascader-input {
	border-color: transparent !important;
	box-shadow: none !important;
}

.ant-cascader-picker-show-search.ant-cascader-picker-focused {
	color: rgba(0, 0, 0, 0.25);
}

.ant-cascader-picker-label {
	height: 20px;
	left: 0;
	line-height: 20px;
	margin-top: -10px;
	overflow: hidden;
	padding: 0 20px 0 12px;
	position: absolute;
	text-overflow: ellipsis;
	top: 50%;
	white-space: nowrap;
	width: 100%;
}

.ant-cascader-picker-clear {
	background: #fff;
	color: rgba(0, 0, 0, 0.25);
	cursor: pointer;
	font-size: 12px;
	height: 12px;
	line-height: 12px;
	margin-top: -6px;
	opacity: 0;
	position: absolute;
	right: 12px;
	top: 50%;
	transition: color 0.3s ease, opacity 0.15s ease;
	width: 12px;
	z-index: 2;
}

.ant-cascader-picker-clear:hover {
	color: #595959;
}

.ant-cascader-picker:hover .ant-cascader-picker-clear {
	opacity: 1;
}

.ant-cascader-picker-arrow {
	color: rgba(0, 0, 0, 0.25);
	font-size: 12px;
	height: 12px;
	line-height: 12px;
	margin-top: -6px;
	position: absolute;
	right: 12px;
	top: 50%;
	width: 12px;
	z-index: 1;
}

.ant-cascader-picker-label:hover+.ant-cascader-input:not(.ant-cascader-picker-disabled .ant-cascader-picker-label:hover + .ant-cascader-input) {
	border-color: #28aaeb;
	border-right-width: 1px !important;
}

.ant-input-rtl .ant-cascader-picker-label:hover+.ant-cascader-input:not(.ant-cascader-picker-disabled .ant-cascader-picker-label:hover + .ant-cascader-input) {
	border-left-width: 1px !important;
	border-right-width: 0;
}

.ant-cascader-picker-small .ant-cascader-picker-arrow,
.ant-cascader-picker-small .ant-cascader-picker-clear {
	right: 8px;
}

.ant-cascader-menus {
	background: #fff;
	border-radius: 6px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	font-size: 14px;
	position: absolute;
	white-space: nowrap;
	z-index: 1050;
}

.ant-cascader-menus ol,
.ant-cascader-menus ul {
	list-style: none;
	margin: 0;
}

.ant-cascader-menus-empty,
.ant-cascader-menus-hidden {
	display: none;
}

.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-bottomLeft,
.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-bottomLeft {
	-webkit-animation-name: antSlideUpIn;
	animation-name: antSlideUpIn;
}

.ant-cascader-menus.slide-up-appear.slide-up-appear-active.ant-cascader-menus-placement-topLeft,
.ant-cascader-menus.slide-up-enter.slide-up-enter-active.ant-cascader-menus-placement-topLeft {
	-webkit-animation-name: antSlideDownIn;
	animation-name: antSlideDownIn;
}

.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-bottomLeft {
	-webkit-animation-name: antSlideUpOut;
	animation-name: antSlideUpOut;
}

.ant-cascader-menus.slide-up-leave.slide-up-leave-active.ant-cascader-menus-placement-topLeft {
	-webkit-animation-name: antSlideDownOut;
	animation-name: antSlideDownOut;
}

.ant-cascader-menu {
	-ms-overflow-style: -ms-autohiding-scrollbar;
	border-right: 1px solid #e8e8e8;
	display: inline-block;
	height: 180px;
	list-style: none;
	margin: 0;
	min-width: 111px;
	overflow: auto;
	padding: 4px 0;
	vertical-align: top;
}

.ant-cascader-menu:first-child {
	border-radius: 6px 0 0 6px;
}

.ant-cascader-menu:last-child {
	border-radius: 0 6px 6px 0;
	border-right-color: transparent;
	margin-right: -1px;
}

.ant-cascader-menu:only-child {
	border-radius: 6px;
}

.ant-cascader-menu-item {
	cursor: pointer;
	line-height: 22px;
	overflow: hidden;
	padding: 5px 12px;
	text-overflow: ellipsis;
	transition: all 0.3s;
	white-space: nowrap;
}

.ant-cascader-menu-item:hover {
	background: #f5f5f5;
}

.ant-cascader-menu-item-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-cascader-menu-item-disabled:hover {
	background: transparent;
}

.ant-cascader-menu-empty .ant-cascader-menu-item {
	color: rgba(0, 0, 0, 0.25);
	cursor: default;
	pointer-events: none;
}

.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled),
.ant-cascader-menu-item-active:not(.ant-cascader-menu-item-disabled):hover {
	background-color: #e6faff;
	font-weight: 600;
}

.ant-cascader-menu-item-expand {
	padding-right: 24px;
	position: relative;
}

.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-loading-icon {
	color: #595959;
	font-size: 10px;
	position: absolute;
	right: 12px;
}

.ant-cascader-menu-item-disabled.ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-item-disabled.ant-cascader-menu-item-loading-icon {
	color: rgba(0, 0, 0, 0.25);
}

.ant-cascader-menu-item .ant-cascader-menu-item-keyword {
	color: #f5222d;
}

.ant-cascader-picker-rtl .ant-cascader-input.ant-input {
	padding-left: 24px;
	padding-right: 11px;
	text-align: right;
}

.ant-cascader-picker-rtl {
	direction: rtl;
}

.ant-cascader-picker-rtl .ant-cascader-picker-label {
	padding: 0 12px 0 20px;
	text-align: right;
}

.ant-cascader-picker-rtl .ant-cascader-picker-arrow,
.ant-cascader-picker-rtl .ant-cascader-picker-clear {
	left: 12px;
	right: auto;
}

.ant-cascader-picker-rtl.ant-cascader-picker-small .ant-cascader-picker-arrow,
.ant-cascader-picker-rtl.ant-cascader-picker-small .ant-cascader-picker-clear {
	left: 8px;
	right: auto;
}

.ant-cascader-menu-rtl .ant-cascader-menu {
	border-left: 1px solid #e8e8e8;
	border-right: none;
	direction: rtl;
}

.ant-cascader-menu-rtl .ant-cascader-menu:first-child {
	border-radius: 0 6px 6px 0;
}

.ant-cascader-menu-rtl .ant-cascader-menu:last-child {
	border-left-color: transparent;
	border-radius: 6px 0 0 6px;
	margin-left: -1px;
	margin-right: 0;
}

.ant-cascader-menu-rtl .ant-cascader-menu:only-child {
	border-radius: 6px;
}

.ant-cascader-menu-rtl .ant-cascader-menu-item-expand {
	padding-left: 24px;
	padding-right: 12px;
}

.ant-cascader-menu-rtl .ant-cascader-menu-item-expand .ant-cascader-menu-item-expand-icon,
.ant-cascader-menu-rtl .ant-cascader-menu-item-loading-icon {
	left: 12px;
	right: auto;
}

.ant-cascader-menu-rtl .ant-cascader-menu-item-loading-icon {
	-webkit-transform: scaleY(-1);
	transform: scaleY(-1);
}

@-webkit-keyframes antCheckboxEffect {
	0% {
		opacity: 0.5;
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	to {
		opacity: 0;
		-webkit-transform: scale(1.6);
		transform: scale(1.6);
	}
}

@keyframes antCheckboxEffect {
	0% {
		opacity: 0.5;
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	to {
		opacity: 0;
		-webkit-transform: scale(1.6);
		transform: scale(1.6);
	}
}

.ant-checkbox {
	box-sizing: border-box;
	color: #545454;
	cursor: pointer;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	line-height: 1;
	list-style: none;
	margin: 0;
	outline: none;
	padding: 0;
	position: relative;
	top: 0.2em;
	white-space: nowrap;
}

.ant-checkbox-input:focus+.ant-checkbox-inner,
.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner {
	border-color: #038fde;
}

.ant-checkbox-checked:after {
	-webkit-animation: antCheckboxEffect 0.36s ease-in-out;
	animation: antCheckboxEffect 0.36s ease-in-out;
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	border: 1px solid #038fde;
	border-radius: 6px;
	content: '';
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	visibility: hidden;
	width: 100%;
}

.ant-checkbox-wrapper:hover .ant-checkbox:after,
.ant-checkbox:hover:after {
	visibility: visible;
}

.ant-checkbox-inner {
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-collapse: separate;
	border-radius: 6px;
	direction: ltr;
	display: block;
	height: 16px;
	left: 0;
	position: relative;
	top: 0;
	transition: all 0.3s;
	width: 16px;
}

.ant-checkbox-inner:after {
	border: 2px solid #fff;
	border-left: 0;
	border-top: 0;
	content: ' ';
	display: table;
	height: 9.14285714px;
	left: 22%;
	opacity: 0;
	position: absolute;
	top: 50%;
	-webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
	transform: rotate(45deg) scale(0) translate(-50%, -50%);
	transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
	width: 5.71428571px;
}

.ant-checkbox-input {
	bottom: 0;
	cursor: pointer;
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 1;
}

.ant-checkbox-checked .ant-checkbox-inner:after {
	border: 2px solid #fff;
	border-left: 0;
	border-top: 0;
	content: ' ';
	display: table;
	opacity: 1;
	position: absolute;
	-webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
	transform: rotate(45deg) scale(1) translate(-50%, -50%);
	transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.ant-checkbox-checked .ant-checkbox-inner {
	background-color: #038fde;
	border-color: #038fde;
}

.ant-checkbox-disabled {
	cursor: not-allowed;
}

.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
	-webkit-animation-name: none;
	animation-name: none;
	border-color: rgba(0, 0, 0, 0.25);
}

.ant-checkbox-disabled .ant-checkbox-input {
	cursor: not-allowed;
}

.ant-checkbox-disabled .ant-checkbox-inner {
	background-color: #f5f5f5;
	border-color: #d9d9d9 !important;
}

.ant-checkbox-disabled .ant-checkbox-inner:after {
	-webkit-animation-name: none;
	animation-name: none;
	border-collapse: separate;
	border-color: #f5f5f5;
}

.ant-checkbox-disabled+span {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-checkbox-disabled:hover:after,
.ant-checkbox-wrapper:hover .ant-checkbox-disabled:after {
	visibility: hidden;
}

.ant-checkbox-wrapper {
	align-items: baseline;
	box-sizing: border-box;
	color: #545454;
	cursor: pointer;
	display: inline-flex;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	line-height: unset;
	list-style: none;
	margin: 0;
	padding: 0;
}

.ant-checkbox-wrapper.ant-checkbox-wrapper-disabled {
	cursor: not-allowed;
}

.ant-checkbox-wrapper+.ant-checkbox-wrapper {
	margin-left: 8px;
}

.ant-checkbox+span {
	padding-left: 8px;
	padding-right: 8px;
}

.ant-checkbox-group {
	box-sizing: border-box;
	color: #545454;
	display: inline-block;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
}

.ant-checkbox-group-item {
	margin-right: 8px;
}

.ant-checkbox-group-item:last-child {
	margin-right: 0;
}

.ant-checkbox-group-item+.ant-checkbox-group-item {
	margin-left: 0;
}

.ant-checkbox-indeterminate .ant-checkbox-inner {
	background-color: #fff;
	border-color: #d9d9d9;
}

.ant-checkbox-indeterminate .ant-checkbox-inner:after {
	background-color: #038fde;
	border: 0;
	content: ' ';
	height: 8px;
	left: 50%;
	opacity: 1;
	top: 50%;
	-webkit-transform: translate(-50%, -50%) scale(1);
	transform: translate(-50%, -50%) scale(1);
	width: 8px;
}

.ant-checkbox-indeterminate.ant-checkbox-disabled .ant-checkbox-inner:after {
	background-color: rgba(0, 0, 0, 0.25);
	border-color: rgba(0, 0, 0, 0.25);
}

.ant-checkbox-rtl {
	direction: rtl;
}

.ant-checkbox-group-rtl .ant-checkbox-group-item {
	margin-left: 8px;
	margin-right: 0;
}

.ant-checkbox-group-rtl .ant-checkbox-group-item:last-child {
	margin-left: 0 !important;
}

.ant-checkbox-group-rtl .ant-checkbox-group-item+.ant-checkbox-group-item {
	margin-left: 8px;
}

.ant-collapse {
	background-color: #fafafa;
	border: 1px solid #d9d9d9;
	border-bottom: 0;
	border-radius: 6px;
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
}

.ant-collapse>.ant-collapse-item {
	border-bottom: 1px solid #d9d9d9;
}

.ant-collapse>.ant-collapse-item:last-child,
.ant-collapse>.ant-collapse-item:last-child>.ant-collapse-header {
	border-radius: 0 0 6px 6px;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
	color: #535353;
	cursor: pointer;
	line-height: 1.3;
	padding: 12px 16px 12px 40px;
	position: relative;
	transition: all 0.3s, visibility 0s;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header:before {
	content: '';
	display: table;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header:after {
	clear: both;
	content: '';
	display: table;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
	text-rendering: optimizeLegibility;

	-moz-osx-font-smoothing: grayscale;
	color: inherit;
	display: inline-block;
	font-size: 12px;
	font-style: normal;
	left: 16px;
	line-height: 0;
	padding: 12px 0 0;
	position: absolute;
	text-align: center;
	text-transform: none;
	top: 3.1px;
	vertical-align: -0.125em;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow>* {
	line-height: 1;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow svg {
	display: inline-block;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow:before {
	display: none;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow .ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow-icon {
	display: block;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow svg {
	transition: -webkit-transform 0.24s;
	transition: transform 0.24s;
	transition: transform 0.24s, -webkit-transform 0.24s;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-extra {
	float: right;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header:focus {
	outline: none;
}

.ant-collapse>.ant-collapse-item .ant-collapse-header-collapsible-only {
	cursor: default;
}

.ant-collapse>.ant-collapse-item .ant-collapse-header-collapsible-only .ant-collapse-header-text {
	cursor: pointer;
}

.ant-collapse>.ant-collapse-item.ant-collapse-no-arrow>.ant-collapse-header {
	padding-left: 12px;
}

.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header {
	padding: 12px 40px 12px 16px;
}

.ant-collapse-icon-position-right>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow {
	left: auto;
	right: 16px;
}

.ant-collapse-content {
	background-color: #fff;
	border-top: 1px solid #d9d9d9;
	color: #545454;
}

.ant-collapse-content>.ant-collapse-content-box {
	padding: 16px;
}

.ant-collapse-content-hidden {
	display: none;
}

.ant-collapse-item:last-child>.ant-collapse-content {
	border-radius: 0 0 6px 6px;
}

.ant-collapse-borderless {
	background-color: #fafafa;
	border: 0;
}

.ant-collapse-borderless>.ant-collapse-item {
	border-bottom: 1px solid #d9d9d9;
}

.ant-collapse-borderless>.ant-collapse-item:last-child,
.ant-collapse-borderless>.ant-collapse-item:last-child .ant-collapse-header {
	border-radius: 0;
}

.ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content {
	background-color: transparent;
	border-top: 0;
}

.ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
	padding-top: 4px;
}

.ant-collapse-ghost {
	background-color: transparent;
	border: 0;
}

.ant-collapse-ghost>.ant-collapse-item {
	border-bottom: 0;
}

.ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content {
	background-color: transparent;
	border-top: 0;
}

.ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
	padding-bottom: 12px;
	padding-top: 12px;
}

.ant-collapse .ant-collapse-item-disabled>.ant-collapse-header,
.ant-collapse .ant-collapse-item-disabled>.ant-collapse-header>.arrow {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-collapse-rtl {
	direction: rtl;
}

.ant-collapse-rtl .ant-collapse>.ant-collapse-item>.ant-collapse-header {
	padding: 12px 40px 12px 16px;
}

.ant-collapse-rtl.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-arrow svg {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.ant-collapse-rtl.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-extra {
	float: left;
}

.ant-collapse-rtl.ant-collapse>.ant-collapse-item.ant-collapse-no-arrow>.ant-collapse-header {
	padding-left: 0;
	padding-right: 12px;
}

.ant-comment {
	background-color: inherit;
	position: relative;
}

.ant-comment-inner {
	display: flex;
	padding: 16px 0;
}

.ant-comment-avatar {
	cursor: pointer;
	flex-shrink: 0;
	margin-right: 12px;
	position: relative;
}

.ant-comment-avatar img {
	border-radius: 50%;
	height: 32px;
	width: 32px;
}

.ant-comment-content {
	word-wrap: break-word;
	flex: 1 1 auto;
	font-size: 14px;
	min-width: 1px;
	position: relative;
}

.ant-comment-content-author {
	display: flex;
	flex-wrap: wrap;
	font-size: 14px;
	justify-content: flex-start;
	margin-bottom: 4px;
}

.ant-comment-content-author>a,
.ant-comment-content-author>span {
	font-size: 12px;
	line-height: 18px;
	padding-right: 8px;
}

.ant-comment-content-author-name {
	color: #595959;
	font-size: 14px;
	transition: color 0.3s;
}

.ant-comment-content-author-name>*,
.ant-comment-content-author-name> :hover {
	color: #595959;
}

.ant-comment-content-author-time {
	color: #ccc;
	cursor: auto;
	white-space: nowrap;
}

.ant-comment-content-detail p {
	margin-bottom: inherit;
	white-space: pre-wrap;
}

.ant-comment-actions {
	margin-bottom: inherit;
	margin-top: 12px;
	padding-left: 0;
}

.ant-comment-actions>li {
	color: #595959;
	display: inline-block;
}

.ant-comment-actions>li>span {
	color: #595959;
	cursor: pointer;
	font-size: 12px;
	margin-right: 10px;
	transition: color 0.3s;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ant-comment-actions>li>span:hover {
	color: #595959;
}

.ant-comment-nested {
	margin-left: 44px;
}

.ant-comment-rtl {
	direction: rtl;
}

.ant-comment-rtl .ant-comment-avatar {
	margin-left: 12px;
	margin-right: 0;
}

.ant-comment-rtl .ant-comment-content-author>a,
.ant-comment-rtl .ant-comment-content-author>span {
	padding-left: 8px;
	padding-right: 0;
}

.ant-comment-rtl .ant-comment-actions {
	padding-right: 0;
}

.ant-comment-rtl .ant-comment-actions>li>span {
	margin-left: 10px;
	margin-right: 0;
}

.ant-comment-rtl .ant-comment-nested {
	margin-left: 0;
	margin-right: 44px;
}

.ant-picker {
	align-items: center;
	background: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 6px;
	box-sizing: border-box;
	color: #545454;
	display: inline-flex;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 8px 11px;
	position: relative;
	transition: border 0.3s, box-shadow 0.3s;
}

.ant-picker-focused,
.ant-picker:hover {
	border-color: #28aaeb;
	border-right-width: 1px !important;
}

.ant-input-rtl .ant-picker-focused,
.ant-input-rtl .ant-picker:hover {
	border-left-width: 1px !important;
	border-right-width: 0;
}

.ant-picker-focused {
	border-color: #28aaeb;
	border-right-width: 1px !important;
	box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
	outline: 0;
}

.ant-input-rtl .ant-picker-focused {
	border-left-width: 1px !important;
	border-right-width: 0;
}

.ant-picker.ant-picker-disabled {
	background: #f5f5f5;
	border-color: #d9d9d9;
	cursor: not-allowed;
}

.ant-picker.ant-picker-disabled .ant-picker-suffix {
	color: rgba(0, 0, 0, 0.25);
}

.ant-picker.ant-picker-borderless {
	background-color: transparent !important;
	border-color: transparent !important;
	box-shadow: none !important;
}

.ant-picker-input {
	align-items: center;
	display: inline-flex;
	position: relative;
	width: 100%;
}

.ant-picker-input>input {
	background-color: #fff;
	background-image: none;
	background: transparent;
	border: 0;
	border-radius: 6px;
	color: #545454;
	display: inline-block;
	flex: auto;
	font-size: 14px;
	height: auto;
	line-height: 1.3;
	min-width: 0;
	min-width: 1px;
	padding: 0;
	position: relative;
	transition: all 0.3s;
	width: 100%;
}

.ant-picker-input>input::-webkit-input-placeholder {
	color: #bfbfbf;
}

.ant-picker-input>input:-ms-input-placeholder {
	color: #bfbfbf;
}

.ant-picker-input>input::placeholder {
	color: #bfbfbf;
}

.ant-picker-input>input:-ms-input-placeholder {
	text-overflow: ellipsis;
}

.ant-picker-input>input:placeholder-shown {
	text-overflow: ellipsis;
}

.ant-picker-input>input:hover {
	border-color: #28aaeb;
	border-right-width: 1px !important;
}

.ant-input-rtl .ant-picker-input>input:hover {
	border-left-width: 1px !important;
	border-right-width: 0;
}

.ant-picker-input>input-focused,
.ant-picker-input>input:focus {
	border-color: #28aaeb;
	border-right-width: 1px !important;
	box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
	outline: 0;
}

.ant-input-rtl .ant-picker-input>input-focused,
.ant-input-rtl .ant-picker-input>input:focus {
	border-left-width: 1px !important;
	border-right-width: 0;
}

.ant-picker-input>input-disabled {
	background-color: #f5f5f5;
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
	opacity: 1;
}

.ant-picker-input>input-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}

.ant-picker-input>input[disabled] {
	background-color: #f5f5f5;
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
	opacity: 1;
}

.ant-picker-input>input[disabled]:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}

.ant-picker-input>input-borderless,
.ant-picker-input>input-borderless-disabled,
.ant-picker-input>input-borderless-focused,
.ant-picker-input>input-borderless:focus,
.ant-picker-input>input-borderless:hover,
.ant-picker-input>input-borderless[disabled] {
	background-color: transparent;
	border: none;
	box-shadow: none;
}

textarea.ant-picker-input>input {
	height: auto;
	line-height: 1.3;
	max-width: 100%;
	min-height: 36px;
	transition: all 0.3s, height 0s;
	vertical-align: bottom;
}

.ant-picker-input>input-lg {
	font-size: 16px;
	padding: 8.6px 11px;
}

.ant-picker-input>input-sm {
	padding: 1.9px 7px;
}

.ant-picker-input>input-rtl {
	direction: rtl;
}

.ant-picker-input>input:focus {
	box-shadow: none;
}

.ant-picker-input>input[disabled] {
	background: transparent;
}

.ant-picker-input:hover .ant-picker-clear {
	opacity: 1;
}

.ant-picker-input-placeholder>input {
	color: #bfbfbf;
}

.ant-picker-large {
	padding: 9px 11px;
}

.ant-picker-large .ant-picker-input>input {
	font-size: 16px;
}

.ant-picker-small {
	padding: 2px 7px;
}

.ant-picker-suffix {
	align-self: center;
	color: rgba(0, 0, 0, 0.25);
	line-height: 1;
	margin-left: 4px;
	pointer-events: none;
}

.ant-picker-suffix>* {
	vertical-align: top;
}

.ant-picker-clear {
	background: #fff;
	color: rgba(0, 0, 0, 0.25);
	cursor: pointer;
	line-height: 1;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	transition: opacity 0.3s, color 0.3s;
}

.ant-picker-clear>* {
	vertical-align: top;
}

.ant-picker-clear:hover {
	color: #595959;
}

.ant-picker-separator {
	color: rgba(0, 0, 0, 0.25);
	cursor: default;
	display: inline-block;
	font-size: 16px;
	height: 16px;
	position: relative;
	vertical-align: top;
	width: 1em;
}

.ant-picker-focused .ant-picker-separator {
	color: #595959;
}

.ant-picker-disabled .ant-picker-range-separator .ant-picker-separator {
	cursor: not-allowed;
}

.ant-picker-range {
	display: inline-flex;
	position: relative;
}

.ant-picker-range .ant-picker-clear {
	right: 11px;
}

.ant-picker-range:hover .ant-picker-clear {
	opacity: 1;
}

.ant-picker-range .ant-picker-active-bar {
	background: #038fde;
	bottom: -1px;
	height: 2px;
	margin-left: 11px;
	opacity: 0;
	pointer-events: none;
	transition: all 0.3s ease-out;
}

.ant-picker-range.ant-picker-focused .ant-picker-active-bar {
	opacity: 1;
}

.ant-picker-range-separator {
	align-items: center;
	line-height: 1;
	padding: 0 8px;
}

.ant-picker-range.ant-picker-small .ant-picker-clear {
	right: 7px;
}

.ant-picker-range.ant-picker-small .ant-picker-active-bar {
	margin-left: 7px;
}

.ant-picker-dropdown {
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
	position: absolute;
	z-index: 1050;
}

.ant-picker-dropdown-hidden {
	display: none;
}

.ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
	display: block;
	top: 1.66666667px;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.ant-picker-dropdown-placement-topLeft .ant-picker-range-arrow {
	bottom: 1.66666667px;
	display: block;
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg);
}

.ant-picker-dropdown.slide-up-appear.slide-up-appear-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.slide-up-appear.slide-up-appear-active.ant-picker-dropdown-placement-topRight,
.ant-picker-dropdown.slide-up-enter.slide-up-enter-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.slide-up-enter.slide-up-enter-active.ant-picker-dropdown-placement-topRight {
	-webkit-animation-name: antSlideDownIn;
	animation-name: antSlideDownIn;
}

.ant-picker-dropdown.slide-up-appear.slide-up-appear-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.slide-up-appear.slide-up-appear-active.ant-picker-dropdown-placement-bottomRight,
.ant-picker-dropdown.slide-up-enter.slide-up-enter-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.slide-up-enter.slide-up-enter-active.ant-picker-dropdown-placement-bottomRight {
	-webkit-animation-name: antSlideUpIn;
	animation-name: antSlideUpIn;
}

.ant-picker-dropdown.slide-up-leave.slide-up-leave-active.ant-picker-dropdown-placement-topLeft,
.ant-picker-dropdown.slide-up-leave.slide-up-leave-active.ant-picker-dropdown-placement-topRight {
	-webkit-animation-name: antSlideDownOut;
	animation-name: antSlideDownOut;
}

.ant-picker-dropdown.slide-up-leave.slide-up-leave-active.ant-picker-dropdown-placement-bottomLeft,
.ant-picker-dropdown.slide-up-leave.slide-up-leave-active.ant-picker-dropdown-placement-bottomRight {
	-webkit-animation-name: antSlideUpOut;
	animation-name: antSlideUpOut;
}

.ant-picker-dropdown-range {
	padding: 6.66666667px 0;
}

.ant-picker-dropdown-range-hidden {
	display: none;
}

.ant-picker-dropdown .ant-picker-panel>.ant-picker-time-panel {
	padding-top: 4px;
}

.ant-picker-ranges {
	line-height: 34px;
	list-style: none;
	margin-bottom: 0;
	overflow: hidden;
	padding: 4px 12px;
	text-align: left;
}

.ant-picker-ranges>li {
	display: inline-block;
}

.ant-picker-ranges .ant-picker-preset>.ant-tag-blue {
	background: #e6faff;
	border-color: #7ddcff;
	color: #038fde;
	cursor: pointer;
}

.ant-picker-ranges .ant-picker-ok {
	float: right;
	margin-left: 8px;
}

.ant-picker-range-wrapper {
	display: flex;
}

.ant-picker-range-arrow {
	box-shadow: 2px -2px 6px rgba(0, 0, 0, 0.06);
	display: none;
	height: 10px;
	margin-left: 16.5px;
	position: absolute;
	transition: left 0.3s ease-out;
	width: 10px;
	z-index: 1;
}

.ant-picker-range-arrow:after {
	border-color: #fff #fff transparent transparent;
	border-style: solid;
	border-width: 5px;
	content: '';
	height: 10px;
	position: absolute;
	right: 1px;
	top: 1px;
	width: 10px;
}

.ant-picker-panel-container {
	background: #fff;
	border-radius: 6px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	overflow: hidden;
	transition: margin 0.3s;
	vertical-align: top;
}

.ant-picker-panel-container .ant-picker-panels {
	direction: ltr;
	display: inline-flex;
	flex-wrap: nowrap;
}

.ant-picker-panel-container .ant-picker-panel {
	background: transparent;
	border-radius: 0;
	border-width: 0 0 1px;
	vertical-align: top;
}

.ant-picker-panel-container .ant-picker-panel-focused {
	border-color: #e8e8e8;
}

.ant-picker-panel {
	background: #fff;
	border: 1px solid #e8e8e8;
	border-radius: 6px;
	display: inline-flex;
	flex-direction: column;
	outline: none;
	text-align: center;
}

.ant-picker-panel-focused {
	border-color: #038fde;
}

.ant-picker-date-panel,
.ant-picker-decade-panel,
.ant-picker-month-panel,
.ant-picker-quarter-panel,
.ant-picker-time-panel,
.ant-picker-week-panel,
.ant-picker-year-panel {
	display: flex;
	flex-direction: column;
	width: 280px;
}

.ant-picker-header {
	border-bottom: 1px solid #e8e8e8;
	color: #535353;
	display: flex;
	padding: 0 8px;
}

.ant-picker-header>* {
	flex: none;
}

.ant-picker-header button {
	background: transparent;
	border: 0;
	color: rgba(0, 0, 0, 0.25);
	cursor: pointer;
	line-height: 40px;
	padding: 0;
	transition: color 0.3s;
}

.ant-picker-header>button {
	font-size: 14px;
	min-width: 1.6em;
}

.ant-picker-header>button:hover {
	color: #545454;
}

.ant-picker-header-view {
	flex: auto;
	font-weight: 500;
	line-height: 40px;
}

.ant-picker-header-view button {
	color: inherit;
	font-weight: inherit;
}

.ant-picker-header-view button:not(:first-child) {
	margin-left: 8px;
}

.ant-picker-header-view button:hover {
	color: #038fde;
}

.ant-picker-next-icon,
.ant-picker-prev-icon,
.ant-picker-super-next-icon,
.ant-picker-super-prev-icon {
	display: inline-block;
	height: 7px;
	position: relative;
	width: 7px;
}

.ant-picker-next-icon:before,
.ant-picker-prev-icon:before,
.ant-picker-super-next-icon:before,
.ant-picker-super-prev-icon:before {
	border: 0 solid;
	border-width: 1.5px 0 0 1.5px;
	content: '';
	display: inline-block;
	height: 7px;
	left: 0;
	position: absolute;
	top: 0;
	width: 7px;
}

.ant-picker-super-next-icon:after,
.ant-picker-super-prev-icon:after {
	border: 0 solid;
	border-width: 1.5px 0 0 1.5px;
	content: '';
	display: inline-block;
	height: 7px;
	left: 4px;
	position: absolute;
	top: 4px;
	width: 7px;
}

.ant-picker-prev-icon,
.ant-picker-super-prev-icon {
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.ant-picker-next-icon,
.ant-picker-super-next-icon {
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg);
}

.ant-picker-content {
	border-collapse: collapse;
	table-layout: fixed;
	width: 100%;
}

.ant-picker-content td,
.ant-picker-content th {
	font-weight: 400;
	min-width: 24px;
	position: relative;
}

.ant-picker-content th {
	color: #545454;
	height: 30px;
	line-height: 30px;
}

.ant-picker-cell {
	color: rgba(0, 0, 0, 0.25);
	cursor: pointer;
	padding: 3px 0;
}

.ant-picker-cell-in-view {
	color: #545454;
}

.ant-picker-cell-in-view > * {
	color: #545454;
}

.ant-picker-cell-disabled {
	cursor: not-allowed;
}

.ant-picker-cell:before {
	content: '';
	height: 24px;
	left: 0;
	position: absolute;
	right: 0;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	z-index: 1;
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
	background: #f5f5f5;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner:before {
	border: 1px solid #038fde;
	border-radius: 6px;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range {
	position: relative;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range:before {
	background: #e6faff;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
	background: #038fde;
	color: #fff;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):before {
	background: #e6faff;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:before {
	left: 50%;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:before {
	right: 50%;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single:after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover:after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single:after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover:after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range):after {
	border-bottom: 1px dashed #4abcfd;
	border-top: 1px dashed #4abcfd;
	content: '';
	height: 24px;
	position: absolute;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	z-index: 0;
}

.ant-picker-cell-range-hover-end:after,
.ant-picker-cell-range-hover-start:after,
.ant-picker-cell-range-hover:after {
	left: 2px;
	right: 0;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover:before,
.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-range-hover:before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single).ant-picker-cell-range-hover-end:before,
.ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-hover:before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single).ant-picker-cell-range-hover-start:before,
.ant-picker-panel> :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end:before,
.ant-picker-panel> :not(.ant-picker-date-panel) .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start:before {
	background: #96d8fe;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
	border-radius: 6px 0 0 6px;
}

.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
	border-radius: 0 6px 6px 0;
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner:after,
.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner:after {
	background: #96d8fe;
	bottom: 0;
	content: '';
	position: absolute;
	top: 0;
	z-index: -1;
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner:after {
	left: 0;
	right: -6px;
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner:after {
	left: -6px;
	right: 0;
}

.ant-picker-cell-range-hover.ant-picker-cell-range-start:after {
	right: 50%;
}

.ant-picker-cell-range-hover.ant-picker-cell-range-end:after {
	left: 50%;
}

.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range):after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:after,
.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range:after,
tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child:after,
tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover:first-child:after {
	border-bottom-left-radius: 6px;
	border-left: 1px dashed #4abcfd;
	border-top-left-radius: 6px;
	left: 6px;
}

.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range:after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range):after,
.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:after,
tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child:after,
tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover:last-child:after {
	border-bottom-right-radius: 6px;
	border-right: 1px dashed #4abcfd;
	border-top-right-radius: 6px;
	right: 6px;
}

.ant-picker-cell-disabled {
	pointer-events: none;
}

.ant-picker-cell-disabled .ant-picker-cell-inner {
	background: transparent;
	color: rgba(0, 0, 0, 0.25);
}

.ant-picker-cell-disabled:before {
	background: #f5f5f5;
}

.ant-picker-cell-disabled.ant-picker-cell-today .ant-picker-cell-inner:before {
	border-color: rgba(0, 0, 0, 0.25);
}

.ant-picker-decade-panel .ant-picker-content,
.ant-picker-month-panel .ant-picker-content,
.ant-picker-quarter-panel .ant-picker-content,
.ant-picker-year-panel .ant-picker-content {
	height: 264px;
}

.ant-picker-decade-panel .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-inner {
	padding: 0 8px;
}

.ant-picker-decade-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-month-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-disabled .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-disabled .ant-picker-cell-inner {
	background: #f5f5f5;
}

.ant-picker-quarter-panel .ant-picker-content {
	height: 56px;
}

.ant-picker-footer {
	border-bottom: 1px solid transparent;
	line-height: 38px;
	min-width: 100%;
	text-align: center;
	width: -webkit-min-content;
	width: min-content;
}

.ant-picker-panel .ant-picker-footer {
	border-top: 1px solid #e8e8e8;
}

.ant-picker-footer-extra {
	line-height: 38px;
	padding: 0 12px;
	text-align: left;
}

.ant-picker-footer-extra:not(:last-child) {
	border-bottom: 1px solid #e8e8e8;
}

.ant-picker-now {
	text-align: left;
}

.ant-picker-today-btn {
	color: #038fde;
}

.ant-picker-today-btn:hover {
	color: #28aaeb;
}

.ant-picker-today-btn:active {
	color: #006eb8;
}

.ant-picker-today-btn.ant-picker-today-btn-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-picker-decade-panel .ant-picker-cell-inner {
	padding: 0 4px;
}

.ant-picker-decade-panel .ant-picker-cell:before {
	display: none;
}

.ant-picker-month-panel .ant-picker-body,
.ant-picker-quarter-panel .ant-picker-body,
.ant-picker-year-panel .ant-picker-body {
	padding: 0 8px;
}

.ant-picker-month-panel .ant-picker-cell-inner,
.ant-picker-quarter-panel .ant-picker-cell-inner,
.ant-picker-year-panel .ant-picker-cell-inner {
	width: 60px;
}

.ant-picker-month-panel .ant-picker-cell-range-hover-start:after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-start:after,
.ant-picker-year-panel .ant-picker-cell-range-hover-start:after {
	border-left: 1px dashed #4abcfd;
	border-radius: 6px 0 0 6px;
	left: 14px;
}

.ant-picker-month-panel .ant-picker-cell-range-hover-end:after,
.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-start:after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-start:after,
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-start:after,
.ant-picker-quarter-panel .ant-picker-cell-range-hover-end:after,
.ant-picker-year-panel .ant-picker-cell-range-hover-end:after {
	border-radius: 0 6px 6px 0;
	border-right: 1px dashed #4abcfd;
	right: 14px;
}

.ant-picker-panel-rtl .ant-picker-month-panel .ant-picker-cell-range-hover-end:after,
.ant-picker-panel-rtl .ant-picker-quarter-panel .ant-picker-cell-range-hover-end:after,
.ant-picker-panel-rtl .ant-picker-year-panel .ant-picker-cell-range-hover-end:after {
	border-left: 1px dashed #4abcfd;
	border-radius: 6px 0 0 6px;
	left: 14px;
}

.ant-picker-week-panel .ant-picker-body {
	padding: 8px 12px;
}

.ant-picker-week-panel .ant-picker-cell .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-week-panel .ant-picker-cell:hover .ant-picker-cell-inner {
	background: transparent !important;
}

.ant-picker-week-panel-row td {
	transition: background 0.3s;
}

.ant-picker-week-panel-row:hover td {
	background: #f5f5f5;
}

.ant-picker-week-panel-row-selected td,
.ant-picker-week-panel-row-selected:hover td {
	background: #038fde;
}

.ant-picker-week-panel-row-selected td.ant-picker-cell-week,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-week {
	color: hsla(0, 0%, 100%, 0.5);
}

.ant-picker-week-panel-row-selected td.ant-picker-cell-today .ant-picker-cell-inner:before,
.ant-picker-week-panel-row-selected:hover td.ant-picker-cell-today .ant-picker-cell-inner:before {
	border-color: #fff;
}

.ant-picker-week-panel-row-selected td .ant-picker-cell-inner,
.ant-picker-week-panel-row-selected:hover td .ant-picker-cell-inner {
	color: #fff;
}

.ant-picker-date-panel .ant-picker-body {
	padding: 8px 12px;
}

.ant-picker-date-panel .ant-picker-content {
	width: 252px;
}

.ant-picker-date-panel .ant-picker-content th {
	width: 36px;
}

.ant-picker-datetime-panel {
	display: flex;
}

.ant-picker-datetime-panel .ant-picker-time-panel {
	border-left: 1px solid #e8e8e8;
}

.ant-picker-datetime-panel .ant-picker-date-panel,
.ant-picker-datetime-panel .ant-picker-time-panel {
	transition: opacity 0.3s;
}

.ant-picker-datetime-panel-active .ant-picker-date-panel,
.ant-picker-datetime-panel-active .ant-picker-time-panel {
	opacity: 0.3;
}

.ant-picker-datetime-panel-active .ant-picker-date-panel-active,
.ant-picker-datetime-panel-active .ant-picker-time-panel-active {
	opacity: 1;
}

.ant-picker-time-panel {
	min-width: auto;
	width: auto;
}

.ant-picker-time-panel .ant-picker-content {
	display: flex;
	flex: auto;
	height: 224px;
}

.ant-picker-time-panel-column {
	flex: 1 0 auto;
	list-style: none;
	margin: 0;
	overflow-y: hidden;
	padding: 0;
	text-align: left;
	transition: background 0.3s;
	width: 56px;
}

.ant-picker-time-panel-column:after {
	content: '';
	display: block;
	height: 196px;
}

.ant-picker-datetime-panel .ant-picker-time-panel-column:after {
	height: 198px;
}

.ant-picker-time-panel-column:not(:first-child) {
	border-left: 1px solid #e8e8e8;
}

.ant-picker-time-panel-column-active {
	background: rgba(230, 250, 255, 0.2);
}

.ant-picker-time-panel-column:hover {
	overflow-y: auto;
}

.ant-picker-time-panel-column>li {
	margin: 0;
	padding: 0;
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner {
	border-radius: 0;
	color: #545454;
	cursor: pointer;
	display: block;
	height: 28px;
	line-height: 28px;
	margin: 0;
	padding: 0 0 0 14px;
	transition: background 0.3s;
	width: 100%;
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell .ant-picker-time-panel-cell-inner:hover {
	background: #f5f5f5;
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner {
	background: #e6faff;
}

.ant-picker-time-panel-column>li.ant-picker-time-panel-cell-disabled .ant-picker-time-panel-cell-inner {
	background: transparent;
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

:root .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
:root .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell,
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-month-panel .ant-picker-cell,
_:-ms-fullscreen .ant-picker-range-wrapper .ant-picker-year-panel .ant-picker-cell {
	padding: 21px 0;
}

.ant-picker-rtl {
	direction: rtl;
}

.ant-picker-rtl .ant-picker-suffix {
	margin-left: 0;
	margin-right: 4px;
}

.ant-picker-rtl .ant-picker-clear {
	left: 0;
	right: auto;
}

.ant-picker-rtl .ant-picker-separator {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.ant-picker-panel-rtl .ant-picker-header-view button:not(:first-child) {
	margin-left: 0;
	margin-right: 8px;
}

.ant-picker-rtl.ant-picker-range .ant-picker-clear {
	left: 11px;
	right: auto;
}

.ant-picker-rtl.ant-picker-range .ant-picker-active-bar {
	margin-left: 0;
	margin-right: 11px;
}

.ant-picker-rtl.ant-picker-range.ant-picker-small .ant-picker-active-bar {
	margin-right: 7px;
}

.ant-picker-dropdown-rtl .ant-picker-ranges {
	text-align: right;
}

.ant-picker-dropdown-rtl .ant-picker-ranges .ant-picker-ok {
	float: left;
	margin-left: 0;
	margin-right: 8px;
}

.ant-picker-panel-rtl {
	direction: rtl;
}

.ant-picker-panel-rtl .ant-picker-prev-icon,
.ant-picker-panel-rtl .ant-picker-super-prev-icon {
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg);
}

.ant-picker-panel-rtl .ant-picker-next-icon,
.ant-picker-panel-rtl .ant-picker-super-next-icon {
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

.ant-picker-cell .ant-picker-cell-inner {
	border-radius: 6px;
	display: inline-block;
	height: 24px;
	line-height: 24px;
	min-width: 24px;
	position: relative;
	transition: background 0.3s, border 0.3s;
	z-index: 2;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start:before {
	left: 0;
	right: 50%;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end:before {
	left: 50%;
	right: 0;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start.ant-picker-cell-range-end:before {
	left: 50%;
	right: 50%;
}

.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner:after {
	left: -6px;
	right: 0;
}

.ant-picker-panel-rtl .ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner:after {
	left: 0;
	right: -6px;
}

.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-start:after {
	left: 50%;
	right: 0;
}

.ant-picker-panel-rtl .ant-picker-cell-range-hover.ant-picker-cell-range-end:after {
	left: 0;
	right: 50%;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner {
	border-radius: 0 6px 6px 0;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
	border-radius: 6px 0 0 6px;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover-edge-start-near-range):after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start.ant-picker-cell-range-hover-edge-start-near-range:after,
.ant-picker-panel-rtl tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):first-child:after {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 6px;
	border-left: none;
	border-right: 1px dashed #4abcfd;
	border-top-left-radius: 0;
	border-top-right-radius: 6px;
	left: 0;
	right: 6px;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-edge-end.ant-picker-cell-range-hover-edge-end-near-range:after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover-edge-end-near-range):after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:after,
.ant-picker-panel-rtl tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-selected):last-child:after {
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 0;
	border-left: 1px dashed #4abcfd;
	border-right: none;
	border-top-left-radius: 6px;
	border-top-right-radius: 0;
	left: 6px;
	right: 0;
}

.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover-start.ant-picker-cell-range-hover-edge-end:not(.ant-picker-cell-range-hover):after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover):after,
.ant-picker-panel-rtl .ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover-end.ant-picker-cell-range-hover-edge-start:not(.ant-picker-cell-range-hover):after,
.ant-picker-panel-rtl tr>.ant-picker-cell-in-view.ant-picker-cell-end.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-end:first-child:after,
.ant-picker-panel-rtl tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover-end:first-child:after,
.ant-picker-panel-rtl tr>.ant-picker-cell-in-view.ant-picker-cell-range-hover-start:last-child:after,
.ant-picker-panel-rtl tr>.ant-picker-cell-in-view.ant-picker-cell-start.ant-picker-cell-range-hover.ant-picker-cell-range-hover-edge-start:last-child:after {
	border-left: 1px dashed #4abcfd;
	border-radius: 6px;
	border-right: 1px dashed #4abcfd;
	left: 6px;
	right: 6px;
}

.ant-picker-dropdown-rtl .ant-picker-footer-extra {
	direction: rtl;
	text-align: right;
}

.ant-picker-panel-rtl .ant-picker-time-panel {
	direction: ltr;
}

.ant-descriptions-header {
	align-items: center;
	display: flex;
	margin-bottom: 20px;
}

.ant-descriptions-title {
	color: #535353;
	flex: auto;
	font-size: 16px;
	font-weight: 700;
	line-height: 1.3;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ant-descriptions-extra {
	color: #545454;
	font-size: 14px;
	margin-left: auto;
}

.ant-descriptions-view {
	border-radius: 6px;
	overflow: hidden;
	width: 100%;
}

.ant-descriptions-view table {
	table-layout: fixed;
	width: 100%;
}

.ant-descriptions-row>td,
.ant-descriptions-row>th {
	padding-bottom: 16px;
}

.ant-descriptions-row:last-child {
	border-bottom: none;
}

.ant-descriptions-item-label {
	color: #535353;
	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
	text-align: start;
}

.ant-descriptions-item-label:after {
	content: ':';
	margin: 0 8px 0 2px;
	position: relative;
	top: -0.5px;
}

.ant-descriptions-item-label.ant-descriptions-item-no-colon:after {
	content: ' ';
}

.ant-descriptions-item-no-label:after {
	content: '';
	margin: 0;
}

.ant-descriptions-item-content {
	color: #545454;
	display: table-cell;
	flex: 1 1;
	font-size: 14px;
	line-height: 1.3;
	overflow-wrap: break-word;
	word-break: break-word;
}

.ant-descriptions-item {
	padding-bottom: 0;
	vertical-align: top;
}

.ant-descriptions-item-container {
	display: flex;
}

.ant-descriptions-item-container .ant-descriptions-item-content,
.ant-descriptions-item-container .ant-descriptions-item-label {
	align-items: baseline;
	display: inline-flex;
}

.ant-descriptions-middle .ant-descriptions-row>td,
.ant-descriptions-middle .ant-descriptions-row>th {
	padding-bottom: 12px;
}

.ant-descriptions-small .ant-descriptions-row>td,
.ant-descriptions-small .ant-descriptions-row>th {
	padding-bottom: 8px;
}

.ant-descriptions-bordered .ant-descriptions-view {
	border: 1px solid #e8e8e8;
}

.ant-descriptions-bordered .ant-descriptions-view>table {
	table-layout: auto;
}

.ant-descriptions-bordered .ant-descriptions-item-content,
.ant-descriptions-bordered .ant-descriptions-item-label {
	border-right: 1px solid #e8e8e8;
	padding: 16px 24px;
}

.ant-descriptions-bordered .ant-descriptions-item-content:last-child,
.ant-descriptions-bordered .ant-descriptions-item-label:last-child {
	border-right: none;
}

.ant-descriptions-bordered .ant-descriptions-item-label {
	background-color: #fafafa;
}

.ant-descriptions-bordered .ant-descriptions-item-label:after {
	display: none;
}

.ant-descriptions-bordered .ant-descriptions-row {
	border-bottom: 1px solid #e8e8e8;
}

.ant-descriptions-bordered .ant-descriptions-row:last-child {
	border-bottom: none;
}

.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-content,
.ant-descriptions-bordered.ant-descriptions-middle .ant-descriptions-item-label {
	padding: 12px 24px;
}

.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-content,
.ant-descriptions-bordered.ant-descriptions-small .ant-descriptions-item-label {
	padding: 8px 16px;
}

.ant-descriptions-rtl {
	direction: rtl;
}

.ant-descriptions-rtl .ant-descriptions-item-label:after {
	margin: 0 2px 0 8px;
}

.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label {
	border-left: 1px solid #e8e8e8;
	border-right: none;
}

.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-content:last-child,
.ant-descriptions-rtl.ant-descriptions-bordered .ant-descriptions-item-label:last-child {
	border-left: none;
}

.ant-divider {
	border-top: 1px solid rgba(0, 0, 0, 0.06);
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
}

.ant-divider-vertical {
	border-left: 1px solid rgba(0, 0, 0, 0.06);
	border-top: 0;
	display: inline-block;
	height: 0.9em;
	margin: 0 8px;
	position: relative;
	top: -0.06em;
	vertical-align: middle;
}

.ant-divider-horizontal {
	clear: both;
	display: flex;
	margin: 24px 0;
	min-width: 100%;
	width: 100%;
}

.ant-divider-horizontal.ant-divider-with-text {
	border-top: 0;
	border-top-color: rgba(0, 0, 0, 0.06);
	color: #535353;
	display: flex;
	font-size: 16px;
	font-weight: 500;
	margin: 16px 0;
	text-align: center;
	white-space: nowrap;
}

.ant-divider-horizontal.ant-divider-with-text:after,
.ant-divider-horizontal.ant-divider-with-text:before {
	border-bottom: 0;
	border-top: 1px solid transparent;
	border-top-color: inherit;
	content: '';
	position: relative;
	top: 50%;
	-webkit-transform: translateY(50%);
	transform: translateY(50%);
	width: 50%;
}

.ant-divider-horizontal.ant-divider-with-text-left:before {
	top: 50%;
	width: 5%;
}

.ant-divider-horizontal.ant-divider-with-text-left:after,
.ant-divider-horizontal.ant-divider-with-text-right:before {
	top: 50%;
	width: 95%;
}

.ant-divider-horizontal.ant-divider-with-text-right:after {
	top: 50%;
	width: 5%;
}

.ant-divider-inner-text {
	display: inline-block;
	padding: 0 1em;
}

.ant-divider-dashed {
	background: none;
	border: dashed rgba(0, 0, 0, 0.06);
	border-width: 1px 0 0;
}

.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed {
	border-top: 0;
}

.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed:after,
.ant-divider-horizontal.ant-divider-with-text.ant-divider-dashed:before {
	border-style: dashed none none;
}

.ant-divider-vertical.ant-divider-dashed {
	border-width: 0 0 0 1px;
}

.ant-divider-plain.ant-divider-with-text {
	color: #545454;
	font-size: 14px;
	font-weight: 400;
}

.ant-divider-rtl {
	direction: rtl;
}

.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left:before {
	width: 95%;
}

.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-left:after,
.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right:before {
	width: 5%;
}

.ant-divider-rtl.ant-divider-horizontal.ant-divider-with-text-right:after {
	width: 95%;
}

.ant-drawer {
	height: 100%;
	position: fixed;
	transition: height 0s ease 0.3s, width 0s ease 0.3s,
		-webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
	transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
		height 0s ease 0.3s, width 0s ease 0.3s;
	transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
		height 0s ease 0.3s, width 0s ease 0.3s,
		-webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
	width: 0;
	z-index: 1000;
}

.ant-drawer>* {
	transition: box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
		-webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
	transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
		box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
	transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
		box-shadow 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
		-webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}

.ant-drawer-content-wrapper {
	height: 100%;
	position: absolute;
	width: 100%;
}

.ant-drawer .ant-drawer-content {
	height: 100%;
	width: 100%;
}

.ant-drawer-left,
.ant-drawer-right {
	height: 100%;
	top: 0;
	width: 0;
}

.ant-drawer-left .ant-drawer-content-wrapper,
.ant-drawer-right .ant-drawer-content-wrapper {
	height: 100%;
}

.ant-drawer-left.ant-drawer-open,
.ant-drawer-right.ant-drawer-open {
	transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
	transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
	transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
		-webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
	width: 100%;
}

.ant-drawer-left,
.ant-drawer-left .ant-drawer-content-wrapper {
	left: 0;
}

.ant-drawer-left.ant-drawer-open .ant-drawer-content-wrapper {
	box-shadow: 6px 0 16px -8px rgba(0, 0, 0, 0.08),
		9px 0 28px 0 rgba(0, 0, 0, 0.05), 12px 0 48px 16px rgba(0, 0, 0, 0.03);
}

.ant-drawer-right,
.ant-drawer-right .ant-drawer-content-wrapper {
	right: 0;
}

.ant-drawer-right.ant-drawer-open .ant-drawer-content-wrapper {
	box-shadow: -6px 0 16px -8px rgba(0, 0, 0, 0.08),
		-9px 0 28px 0 rgba(0, 0, 0, 0.05), -12px 0 48px 16px rgba(0, 0, 0, 0.03);
}

.ant-drawer-right.ant-drawer-open.no-mask {
	right: 1px;
	-webkit-transform: translateX(1px);
	transform: translateX(1px);
}

.ant-drawer-bottom,
.ant-drawer-top {
	height: 0%;
	left: 0;
	width: 100%;
}

.ant-drawer-bottom .ant-drawer-content-wrapper,
.ant-drawer-top .ant-drawer-content-wrapper {
	width: 100%;
}

.ant-drawer-bottom.ant-drawer-open,
.ant-drawer-top.ant-drawer-open {
	height: 100%;
	transition: -webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
	transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
	transition: transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1),
		-webkit-transform 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}

.ant-drawer-top {
	top: 0;
}

.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
	box-shadow: 0 6px 16px -8px rgba(0, 0, 0, 0.08),
		0 9px 28px 0 rgba(0, 0, 0, 0.05), 0 12px 48px 16px rgba(0, 0, 0, 0.03);
}

.ant-drawer-bottom,
.ant-drawer-bottom .ant-drawer-content-wrapper {
	bottom: 0;
}

.ant-drawer-bottom.ant-drawer-open .ant-drawer-content-wrapper {
	box-shadow: 0 -6px 16px -8px rgba(0, 0, 0, 0.08),
		0 -9px 28px 0 rgba(0, 0, 0, 0.05), 0 -12px 48px 16px rgba(0, 0, 0, 0.03);
}

.ant-drawer-bottom.ant-drawer-open.no-mask {
	bottom: 1px;
	-webkit-transform: translateY(1px);
	transform: translateY(1px);
}

.ant-drawer.ant-drawer-open .ant-drawer-mask {
	-webkit-animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
	animation: antdDrawerFadeIn 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
	height: 100%;
	opacity: 1;
	pointer-events: auto;
	transition: none;
}

.ant-drawer-title {
	color: #535353;
	font-size: 16px;
	font-weight: 500;
	line-height: 22px;
	margin: 0;
}

.ant-drawer-content {
	background-clip: padding-box;
	background-color: #fff;
	border: 0;
	overflow: auto;
	position: relative;
	z-index: 1;
}

.ant-drawer-close {
	text-rendering: auto;
	background: transparent;
	border: 0;
	color: #595959;
	cursor: pointer;
	display: block;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: 1;
	outline: 0;
	padding: 20px;
	position: absolute;
	right: 0;
	text-align: center;
	text-decoration: none;
	text-transform: none;
	top: 0;
	transition: color 0.3s;
	z-index: 10;
}

.ant-drawer-close:focus,
.ant-drawer-close:hover {
	color: rgba(0, 0, 0, 0.75);
	text-decoration: none;
}

.ant-drawer-header-no-title .ant-drawer-close {
	margin-right: 0;
	margin-right: var(--scroll-bar);
	padding-right: 20px;
	padding-right: calc(20px - var(--scroll-bar));
}

.ant-drawer-header {
	border-bottom: 1px solid #e8e8e8;
	border-radius: 6px 6px 0 0;
	padding: 16px 24px;
	position: relative;
}

.ant-drawer-header,
.ant-drawer-header-no-title {
	background: #fff;
	color: #545454;
}

.ant-drawer-wrapper-body {
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	height: 100%;
	width: 100%;
}

.ant-drawer-body {
	word-wrap: break-word;
	flex-grow: 1;
	font-size: 14px;
	line-height: 1.3;
	overflow: auto;
	padding: 24px;
}

.ant-drawer-footer {
	border-top: 1px solid #e8e8e8;
	flex-shrink: 0;
	padding: 10px 16px;
}

.ant-drawer-mask {
	background-color: rgba(0, 0, 0, 0.45);
	filter: alpha(opacity=45);
	height: 0;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	transition: opacity 0.3s linear, height 0s ease 0.3s;
	width: 100%;
}

.ant-drawer-open-content {
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
}

.ant-drawer .ant-picker-clear {
	background: #fff;
}

@-webkit-keyframes antdDrawerFadeIn {
	0% {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes antdDrawerFadeIn {
	0% {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.ant-drawer-rtl {
	direction: rtl;
}

.ant-drawer-rtl .ant-drawer-close {
	left: 0;
	right: auto;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger {
	color: #f5222d;
}

.ant-dropdown-menu-item.ant-dropdown-menu-item-danger:hover {
	background-color: #f5222d;
	color: #fff;
}

.ant-dropdown {
	box-sizing: border-box;
	color: #545454;
	display: block;
	font-size: 14px;
	font-variant: tabular-nums;
	left: -9999px;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
	position: absolute;
	top: -9999px;
	z-index: 1050;
}

.ant-dropdown:before {
	bottom: -4px;
	content: ' ';
	left: -7px;
	opacity: 0.0001;
	position: absolute;
	right: 0;
	top: -4px;
	z-index: -9999;
}

.ant-dropdown-wrap {
	position: relative;
}

.ant-dropdown-wrap .ant-btn>.anticon-down {
	font-size: 10px;
}

.ant-dropdown-wrap .anticon-down:before {
	transition: -webkit-transform 0.2s;
	transition: transform 0.2s;
	transition: transform 0.2s, -webkit-transform 0.2s;
}

.ant-dropdown-wrap-open .anticon-down:before {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.ant-dropdown-hidden,
.ant-dropdown-menu-hidden {
	display: none;
}

.ant-dropdown-show-arrow.ant-dropdown-placement-topCenter,
.ant-dropdown-show-arrow.ant-dropdown-placement-topLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-topRight {
	padding-bottom: 10px;
}

.ant-dropdown-show-arrow.ant-dropdown-placement-bottomCenter,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomLeft,
.ant-dropdown-show-arrow.ant-dropdown-placement-bottomRight {
	padding-top: 10px;
}

.ant-dropdown-arrow {
	background: transparent;
	border-style: solid;
	border-width: 4.24264069px;
	display: block;
	height: 8.48528137px;
	position: absolute;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	width: 8.48528137px;
	z-index: 1;
}

.ant-dropdown-placement-topCenter>.ant-dropdown-arrow,
.ant-dropdown-placement-topLeft>.ant-dropdown-arrow,
.ant-dropdown-placement-topRight>.ant-dropdown-arrow {
	border-color: transparent #fff #fff transparent;
	bottom: 6.2px;
	box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}

.ant-dropdown-placement-topCenter>.ant-dropdown-arrow {
	left: 50%;
	-webkit-transform: translateX(-50%) rotate(45deg);
	transform: translateX(-50%) rotate(45deg);
}

.ant-dropdown-placement-topLeft>.ant-dropdown-arrow {
	left: 16px;
}

.ant-dropdown-placement-topRight>.ant-dropdown-arrow {
	right: 16px;
}

.ant-dropdown-placement-bottomCenter>.ant-dropdown-arrow,
.ant-dropdown-placement-bottomLeft>.ant-dropdown-arrow,
.ant-dropdown-placement-bottomRight>.ant-dropdown-arrow {
	border-color: #fff transparent transparent #fff;
	box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
	top: 6px;
}

.ant-dropdown-placement-bottomCenter>.ant-dropdown-arrow {
	left: 50%;
	-webkit-transform: translateX(-50%) rotate(45deg);
	transform: translateX(-50%) rotate(45deg);
}

.ant-dropdown-placement-bottomLeft>.ant-dropdown-arrow {
	left: 16px;
}

.ant-dropdown-placement-bottomRight>.ant-dropdown-arrow {
	right: 16px;
}

.ant-dropdown-menu {
	background-clip: padding-box;
	background-color: #fff;
	border-radius: 6px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	list-style-type: none;
	margin: 0;
	outline: none;
	padding: 4px 0;
	position: relative;
	text-align: left;
}

.ant-dropdown-menu-item-group-title {
	color: #595959;
	padding: 5px 12px;
	transition: all 0.3s;
}

.ant-dropdown-menu-submenu-popup {
	background: transparent;
	box-shadow: none;
	position: absolute;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
	z-index: 1050;
}

.ant-dropdown-menu-submenu-popup li,
.ant-dropdown-menu-submenu-popup ul {
	list-style: none;
}

.ant-dropdown-menu-submenu-popup ul {
	margin-left: 0.3em;
	margin-right: 0.3em;
}

.ant-dropdown-menu-item,
.ant-dropdown-menu-submenu-title {
	clear: both;
	color: #545454;
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	margin: 0;
	padding: 5px 12px;
	transition: all 0.3s;
	white-space: nowrap;
}

.ant-dropdown-menu-item>.anticon:first-child,
.ant-dropdown-menu-item>a>.anticon:first-child,
.ant-dropdown-menu-item>span>.anticon:first-child,
.ant-dropdown-menu-submenu-title>.anticon:first-child,
.ant-dropdown-menu-submenu-title>a>.anticon:first-child,
.ant-dropdown-menu-submenu-title>span>.anticon:first-child {
	font-size: 12px;
	margin-right: 8px;
	min-width: 12px;
	vertical-align: -0.1em;
}

.ant-dropdown-menu-item>a,
.ant-dropdown-menu-submenu-title>a {
	color: #545454;
	display: block;
	margin: -5px -12px;
	padding: 5px 12px;
	transition: all 0.3s;
}

.ant-dropdown-menu-item>a:hover,
.ant-dropdown-menu-submenu-title>a:hover {
	color: #545454;
}

.ant-dropdown-menu-item>.anticon+span>a,
.ant-dropdown-menu-submenu-title>.anticon+span>a {
	color: #545454;
	transition: all 0.3s;
}

.ant-dropdown-menu-item>.anticon+span>a:hover,
.ant-dropdown-menu-submenu-title>.anticon+span>a:hover {
	color: #545454;
}

.ant-dropdown-menu-item-selected,
.ant-dropdown-menu-item-selected>a,
.ant-dropdown-menu-submenu-title-selected,
.ant-dropdown-menu-submenu-title-selected>a {
	background-color: #e6faff;
	color: #038fde;
}

.ant-dropdown-menu-item:hover,
.ant-dropdown-menu-submenu-title:hover {
	background-color: #f5f5f5;
}

.ant-dropdown-menu-item-disabled,
.ant-dropdown-menu-submenu-title-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-dropdown-menu-item-disabled:hover,
.ant-dropdown-menu-submenu-title-disabled:hover {
	background-color: #fff;
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-dropdown-menu-item-disabled>.anticon+span>a,
.ant-dropdown-menu-item-disabled>a,
.ant-dropdown-menu-submenu-title-disabled>.anticon+span>a,
.ant-dropdown-menu-submenu-title-disabled>a {
	color: rgba(0, 0, 0, 0.25);
	pointer-events: none;
	position: relative;
}

.ant-dropdown-menu-item-disabled>.anticon+span>a:after,
.ant-dropdown-menu-item-disabled>a:after,
.ant-dropdown-menu-submenu-title-disabled>.anticon+span>a:after,
.ant-dropdown-menu-submenu-title-disabled>a:after {
	content: '';
	cursor: not-allowed;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.ant-dropdown-menu-item-divider,
.ant-dropdown-menu-submenu-title-divider {
	background-color: #e8e8e8;
	height: 1px;
	line-height: 0;
	margin: 4px 0;
	overflow: hidden;
}

.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon {
	position: absolute;
	right: 8px;
}

.ant-dropdown-menu-item .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-expand-icon .ant-dropdown-menu-submenu-arrow-icon {
	color: #595959;
	font-size: 10px;
	font-style: normal;
	margin-right: 0 !important;
}

.ant-dropdown-menu-item-group-list {
	list-style: none;
	margin: 0 8px;
	padding: 0;
}

.ant-dropdown-menu-submenu-title {
	padding-right: 24px;
}

.ant-dropdown-menu-submenu-vertical {
	position: relative;
}

.ant-dropdown-menu-submenu-vertical>.ant-dropdown-menu {
	left: 100%;
	margin-left: 4px;
	min-width: 100%;
	position: absolute;
	top: 0;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
}

.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-submenu.ant-dropdown-menu-submenu-disabled .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
	background-color: #fff;
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-dropdown-menu-submenu-selected .ant-dropdown-menu-submenu-title {
	color: #038fde;
}

.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-appear.slide-down-appear-active.ant-dropdown-placement-bottomRight,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-enter.slide-down-enter-active.ant-dropdown-placement-bottomRight {
	-webkit-animation-name: antSlideUpIn;
	animation-name: antSlideUpIn;
}

.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-appear.slide-up-appear-active.ant-dropdown-placement-topRight,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-enter.slide-up-enter-active.ant-dropdown-placement-topRight {
	-webkit-animation-name: antSlideDownIn;
	animation-name: antSlideDownIn;
}

.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomCenter,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomLeft,
.ant-dropdown.slide-down-leave.slide-down-leave-active.ant-dropdown-placement-bottomRight {
	-webkit-animation-name: antSlideUpOut;
	animation-name: antSlideUpOut;
}

.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topCenter,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topLeft,
.ant-dropdown.slide-up-leave.slide-up-leave-active.ant-dropdown-placement-topRight {
	-webkit-animation-name: antSlideDownOut;
	animation-name: antSlideDownOut;
}

.ant-dropdown-button>.anticon.anticon-down,
.ant-dropdown-link>.anticon.anticon-down,
.ant-dropdown-trigger>.anticon.anticon-down {
	font-size: 10px;
	vertical-align: baseline;
}

.ant-dropdown-button {
	white-space: nowrap;
}

.ant-dropdown-button.ant-btn-group>.ant-btn:last-child:not(:first-child):not(.ant-btn-icon-only) {
	padding-left: 8px;
	padding-right: 8px;
}

.ant-dropdown-menu-dark,
.ant-dropdown-menu-dark .ant-dropdown-menu {
	background: #038fde;
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item,
.ant-dropdown-menu-dark .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow:after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item>.anticon+span>a,
.ant-dropdown-menu-dark .ant-dropdown-menu-item>.anticon+span>a .ant-dropdown-menu-submenu-arrow:after,
.ant-dropdown-menu-dark .ant-dropdown-menu-item>a,
.ant-dropdown-menu-dark .ant-dropdown-menu-item>a .ant-dropdown-menu-submenu-arrow:after,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow:after {
	color: hsla(0, 0%, 100%, 0.65);
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item>.anticon+span>a:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item>a:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-submenu-title:hover {
	background: transparent;
	color: #fff;
}

.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected:hover,
.ant-dropdown-menu-dark .ant-dropdown-menu-item-selected>a {
	background: #038fde;
	color: #fff;
}

.ant-dropdown-rtl {
	direction: rtl;
}

.ant-dropdown-rtl.ant-dropdown:before {
	left: 0;
	right: -7px;
}

.ant-dropdown-menu.ant-dropdown-menu-rtl,
.ant-dropdown-rtl .ant-dropdown-menu-item-group-title {
	direction: rtl;
	text-align: right;
}

.ant-dropdown-menu-submenu-popup.ant-dropdown-menu-submenu-rtl {
	-webkit-transform-origin: 100% 0;
	transform-origin: 100% 0;
}

.ant-dropdown-rtl .ant-dropdown-menu-item,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup li,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-popup ul,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
	text-align: right;
}

.ant-dropdown-rtl .ant-dropdown-menu-item>.anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-item>span>.anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title>.anticon:first-child,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title>span>.anticon:first-child {
	margin-left: 8px;
	margin-right: 0;
}

.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow {
	left: 8px;
	right: auto;
}

.ant-dropdown-rtl .ant-dropdown-menu-item .ant-dropdown-menu-submenu-arrow-icon,
.ant-dropdown-rtl .ant-dropdown-menu-submenu-title .ant-dropdown-menu-submenu-arrow-icon {
	margin-left: 0 !important;
	-webkit-transform: scaleX(-1);
	transform: scaleX(-1);
}

.ant-dropdown-rtl .ant-dropdown-menu-submenu-title {
	padding-left: 24px;
	padding-right: 12px;
}

.ant-dropdown-rtl .ant-dropdown-menu-submenu-vertical>.ant-dropdown-menu {
	left: 0;
	margin-left: 0;
	margin-right: 4px;
	right: 100%;
}

.ant-empty {
	font-size: 14px;
	line-height: 1.3;
	margin: 0 8px;
	text-align: center;
}

.ant-empty-image {
	height: 100px;
	margin-bottom: 8px;
}

.ant-empty-image img {
	height: 100%;
}

.ant-empty-image svg {
	height: 100%;
	margin: auto;
}

.ant-empty-footer {
	margin-top: 16px;
}

.ant-empty-normal {
	color: rgba(0, 0, 0, 0.25);
	margin: 32px 0;
}

.ant-empty-normal .ant-empty-image {
	height: 40px;
}

.ant-empty-small {
	color: rgba(0, 0, 0, 0.25);
	margin: 8px 0;
}

.ant-empty-small .ant-empty-image {
	height: 35px;
}

.ant-empty-img-default-ellipse {
	fill: #f5f5f5;
	fill-opacity: 0.8;
}

.ant-empty-img-default-path-1 {
	fill: #aeb8c2;
}

.ant-empty-img-default-path-2 {
	fill: url(#linearGradient-1);
}

.ant-empty-img-default-path-3 {
	fill: #f5f5f7;
}

.ant-empty-img-default-path-4,
.ant-empty-img-default-path-5 {
	fill: #dce0e6;
}

.ant-empty-img-default-g {
	fill: #fff;
}

.ant-empty-img-simple-ellipse {
	fill: #f5f5f5;
}

.ant-empty-img-simple-g {
	stroke: #d9d9d9;
}

.ant-empty-img-simple-path {
	fill: #fafafa;
}

.ant-empty-rtl {
	direction: rtl;
}

.ant-form-item .ant-mentions,
.ant-form-item textarea.ant-input {
	height: auto;
}

.ant-form-item .ant-upload {
	background: transparent;
}

.ant-form-item .ant-upload.ant-upload-drag {
	background: #fafafa;
}

.ant-form-item input[type='checkbox'],
.ant-form-item input[type='radio'] {
	height: 14px;
	width: 14px;
}

.ant-form-item .ant-checkbox-inline,
.ant-form-item .ant-radio-inline {
	cursor: pointer;
	display: inline-block;
	font-weight: 400;
	margin-left: 8px;
	vertical-align: middle;
}

.ant-form-item .ant-checkbox-inline:first-child,
.ant-form-item .ant-radio-inline:first-child {
	margin-left: 0;
}

.ant-form-item .ant-checkbox-vertical,
.ant-form-item .ant-radio-vertical {
	display: block;
}

.ant-form-item .ant-checkbox-vertical+.ant-checkbox-vertical,
.ant-form-item .ant-radio-vertical+.ant-radio-vertical {
	margin-left: 0;
}

.ant-form-item .ant-input-number+.ant-form-text {
	margin-left: 8px;
}

.ant-form-item .ant-input-number-handler-wrap {
	z-index: 2;
}

.ant-form-item .ant-cascader-picker,
.ant-form-item .ant-select {
	width: 100%;
}

.ant-form-item .ant-input-group .ant-cascader-picker,
.ant-form-item .ant-input-group .ant-select {
	width: auto;
}

.ant-form-inline {
	display: flex;
	flex-wrap: wrap;
}

.ant-form-inline .ant-form-item {
	flex: none;
	flex-wrap: nowrap;
	margin-bottom: 0;
	margin-right: 16px;
}

.ant-form-inline .ant-form-item-with-help {
	margin-bottom: 20px;
}

.ant-form-inline .ant-form-item>.ant-form-item-control,
.ant-form-inline .ant-form-item>.ant-form-item-label {
	display: inline-block;
	vertical-align: top;
}

.ant-form-inline .ant-form-item>.ant-form-item-label {
	flex: none;
}

.ant-form-inline .ant-form-item .ant-form-item-has-feedback,
.ant-form-inline .ant-form-item .ant-form-text {
	display: inline-block;
}

.ant-form-horizontal .ant-form-item-label {
	flex-grow: 0;
	white-space: inherit;
}

.ant-form-horizontal .ant-form-item-control {
	flex: 1 1;
}

.ant-form-vertical .ant-form-item {
	flex-direction: column;
}

.ant-form-vertical .ant-form-item-label>label {
	height: auto;
}

.ant-col-24.ant-form-item-label,
.ant-col-xl-24.ant-form-item-label,
.ant-form-vertical .ant-form-item-label {
	line-height: 1.3;
	padding: 0 0 8px;
	text-align: left;
	white-space: normal;
}

.ant-col-24.ant-form-item-label>label,
.ant-col-xl-24.ant-form-item-label>label,
.ant-form-vertical .ant-form-item-label>label {
	margin: 0;
}

.ant-col-24.ant-form-item-label>label:after,
.ant-col-xl-24.ant-form-item-label>label:after,
.ant-form-vertical .ant-form-item-label>label:after {
	display: none;
}

.ant-form-rtl.ant-col-24.ant-form-item-label,
.ant-form-rtl.ant-col-xl-24.ant-form-item-label,
.ant-form-rtl.ant-form-vertical .ant-form-item-label {
	text-align: right;
}

@media (max-width: 575px) {
	.ant-form-item .ant-form-item-label {
		line-height: 1.3;
		padding: 0 0 8px;
		text-align: left;
		white-space: normal;
	}

	.ant-form-item .ant-form-item-label>label {
		margin: 0;
	}

	.ant-form-item .ant-form-item-label>label:after {
		display: none;
	}

	.ant-form-rtl.ant-form-item .ant-form-item-label {
		text-align: right;
	}

	.ant-form .ant-form-item {
		flex-wrap: wrap;
	}

	.ant-form .ant-form-item .ant-form-item-control,
	.ant-form .ant-form-item .ant-form-item-label {
		flex: 0 0 100%;
		max-width: 100%;
	}

	.ant-col-xs-24.ant-form-item-label {
		line-height: 1.3;
		padding: 0 0 8px;
		text-align: left;
		white-space: normal;
	}

	.ant-col-xs-24.ant-form-item-label>label {
		margin: 0;
	}

	.ant-col-xs-24.ant-form-item-label>label:after {
		display: none;
	}

	.ant-form-rtl.ant-col-xs-24.ant-form-item-label {
		text-align: right;
	}
}

@media (max-width: 767px) {
	.ant-col-sm-24.ant-form-item-label {
		line-height: 1.3;
		padding: 0 0 8px;
		text-align: left;
		white-space: normal;
	}

	.ant-col-sm-24.ant-form-item-label>label {
		margin: 0;
	}

	.ant-col-sm-24.ant-form-item-label>label:after {
		display: none;
	}

	.ant-form-rtl.ant-col-sm-24.ant-form-item-label {
		text-align: right;
	}
}

@media (max-width: 991px) {
	.ant-col-md-24.ant-form-item-label {
		line-height: 1.3;
		padding: 0 0 8px;
		text-align: left;
		white-space: normal;
	}

	.ant-col-md-24.ant-form-item-label>label {
		margin: 0;
	}

	.ant-col-md-24.ant-form-item-label>label:after {
		display: none;
	}

	.ant-form-rtl.ant-col-md-24.ant-form-item-label {
		text-align: right;
	}
}

@media (max-width: 1199px) {
	.ant-col-lg-24.ant-form-item-label {
		line-height: 1.3;
		padding: 0 0 8px;
		text-align: left;
		white-space: normal;
	}

	.ant-col-lg-24.ant-form-item-label>label {
		margin: 0;
	}

	.ant-col-lg-24.ant-form-item-label>label:after {
		display: none;
	}

	.ant-form-rtl.ant-col-lg-24.ant-form-item-label {
		text-align: right;
	}
}

@media (max-width: 1599px) {
	.ant-col-xl-24.ant-form-item-label {
		line-height: 1.3;
		padding: 0 0 8px;
		text-align: left;
		white-space: normal;
	}

	.ant-col-xl-24.ant-form-item-label>label {
		margin: 0;
	}

	.ant-col-xl-24.ant-form-item-label>label:after {
		display: none;
	}

	.ant-form-rtl.ant-col-xl-24.ant-form-item-label {
		text-align: right;
	}
}

.ant-form-item-explain.ant-form-item-explain-error {
	color: #f5222d;
}

.ant-form-item-explain.ant-form-item-explain-warning {
	color: #fa8c16;
}

.ant-form-item-has-feedback .ant-input {
	padding-right: 24px;
}

.ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
	padding-right: 18px;
}

.ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
	right: 28px;
}

.ant-form-item-has-feedback .ant-switch {
	margin: 2px 0 4px;
}

.ant-form-item-has-feedback:not(.ant-input-group-addon)>.ant-select .ant-select-arrow,
.ant-form-item-has-feedback:not(.ant-input-group-addon)>.ant-select .ant-select-clear,
.ant-form-item-has-feedback>.ant-select .ant-select-arrow,
.ant-form-item-has-feedback>.ant-select .ant-select-clear {
	right: 32px;
}

.ant-form-item-has-feedback:not(.ant-input-group-addon)>.ant-select .ant-select-selection-selected-value,
.ant-form-item-has-feedback>.ant-select .ant-select-selection-selected-value {
	padding-right: 42px;
}

.ant-form-item-has-feedback .ant-cascader-picker-arrow {
	margin-right: 19px;
}

.ant-form-item-has-feedback .ant-cascader-picker-clear {
	right: 32px;
}

.ant-form-item-has-feedback .ant-picker,
.ant-form-item-has-feedback .ant-picker-large {
	padding-right: 29.2px;
}

.ant-form-item-has-feedback .ant-picker-small {
	padding-right: 25.2px;
}

.ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
	-webkit-animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
	animation: zoomIn 0.3s cubic-bezier(0.12, 0.4, 0.29, 1.46);
	font-size: 14px;
	height: 20px;
	line-height: 20px;
	margin-top: -10px;
	pointer-events: none;
	position: absolute;
	right: 0;
	text-align: center;
	top: 50%;
	visibility: visible;
	width: 36px;
	z-index: 1;
}

.ant-form-item-has-success.ant-form-item-has-feedback .ant-form-item-children-icon {
	-webkit-animation-name: diffZoomIn1 !important;
	animation-name: diffZoomIn1 !important;
	color: #52c41a;
}

.ant-form-item-has-warning .ant-form-item-split {
	color: #fa8c16;
}

.ant-form-item-has-warning .ant-input,
.ant-form-item-has-warning .ant-input-affix-wrapper,
.ant-form-item-has-warning .ant-input-affix-wrapper:hover,
.ant-form-item-has-warning .ant-input:hover {
	background-color: #fff;
	border-color: #fa8c16;
}

.ant-form-item-has-warning .ant-input-affix-wrapper-focused,
.ant-form-item-has-warning .ant-input-affix-wrapper:focus,
.ant-form-item-has-warning .ant-input-focused,
.ant-form-item-has-warning .ant-input:focus {
	border-color: #ffa940;
	border-right-width: 1px !important;
	box-shadow: 0 0 0 2px rgba(250, 140, 22, 0.2);
	outline: 0;
}

.ant-form-item-has-warning .ant-input-affix-wrapper-disabled,
.ant-form-item-has-warning .ant-input-disabled {
	background-color: #f5f5f5;
	border-color: #d9d9d9;
}

.ant-form-item-has-warning .ant-input-affix-wrapper-disabled input:focus {
	box-shadow: none !important;
}

.ant-form-item-has-warning .ant-calendar-picker-open .ant-calendar-picker-input {
	border-color: #ffa940;
	border-right-width: 1px !important;
	box-shadow: 0 0 0 2px rgba(250, 140, 22, 0.2);
	outline: 0;
}

.ant-form-item-has-warning .ant-input-prefix {
	color: #fa8c16;
}

.ant-form-item-has-warning .ant-input-group-addon {
	border-color: #fa8c16;
	color: #fa8c16;
}

.ant-form-item-has-warning .has-feedback {
	color: #fa8c16;
}

.ant-form-item-has-warning.ant-form-item-has-feedback .ant-form-item-children-icon {
	-webkit-animation-name: diffZoomIn3 !important;
	animation-name: diffZoomIn3 !important;
	color: #fa8c16;
}

.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
	background-color: #fff;
	border-color: #fa8c16 !important;
}

.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector,
.ant-form-item-has-warning .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector {
	border-color: #ffa940;
	border-right-width: 1px !important;
	box-shadow: 0 0 0 2px rgba(250, 140, 22, 0.2);
	outline: 0;
}

.ant-form-item-has-warning .ant-input-number,
.ant-form-item-has-warning .ant-picker {
	background-color: #fff;
	border-color: #fa8c16;
}

.ant-form-item-has-warning .ant-input-number-focused,
.ant-form-item-has-warning .ant-input-number:focus,
.ant-form-item-has-warning .ant-picker-focused,
.ant-form-item-has-warning .ant-picker:focus {
	border-color: #ffa940;
	border-right-width: 1px !important;
	box-shadow: 0 0 0 2px rgba(250, 140, 22, 0.2);
	outline: 0;
}

.ant-form-item-has-warning .ant-input-number:not([disabled]):hover,
.ant-form-item-has-warning .ant-picker:not([disabled]):hover {
	background-color: #fff;
	border-color: #fa8c16;
}

.ant-form-item-has-warning .ant-cascader-picker:focus .ant-cascader-input {
	border-color: #ffa940;
	border-right-width: 1px !important;
	box-shadow: 0 0 0 2px rgba(250, 140, 22, 0.2);
	outline: 0;
}

.ant-form-item-has-error .ant-form-item-split {
	color: #f5222d;
}

.ant-form-item-has-error .ant-input,
.ant-form-item-has-error .ant-input-affix-wrapper,
.ant-form-item-has-error .ant-input-affix-wrapper:hover,
.ant-form-item-has-error .ant-input:hover {
	background-color: #fff;
	border-color: #f5222d;
}

.ant-form-item-has-error .ant-input-affix-wrapper-focused,
.ant-form-item-has-error .ant-input-affix-wrapper:focus,
.ant-form-item-has-error .ant-input-focused,
.ant-form-item-has-error .ant-input:focus {
	border-color: #ff4d4f;
	border-right-width: 1px !important;
	box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
	outline: 0;
}

.ant-form-item-has-error .ant-input-affix-wrapper-disabled,
.ant-form-item-has-error .ant-input-disabled {
	background-color: #f5f5f5;
	border-color: #d9d9d9;
}

.ant-form-item-has-error .ant-input-affix-wrapper-disabled input:focus {
	box-shadow: none !important;
}

.ant-form-item-has-error .ant-calendar-picker-open .ant-calendar-picker-input {
	border-color: #ff4d4f;
	border-right-width: 1px !important;
	box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
	outline: 0;
}

.ant-form-item-has-error .ant-input-prefix {
	color: #f5222d;
}

.ant-form-item-has-error .ant-input-group-addon {
	border-color: #f5222d;
	color: #f5222d;
}

.ant-form-item-has-error .has-feedback {
	color: #f5222d;
}

.ant-form-item-has-error.ant-form-item-has-feedback .ant-form-item-children-icon {
	-webkit-animation-name: diffZoomIn2 !important;
	animation-name: diffZoomIn2 !important;
	color: #f5222d;
}

.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input) .ant-select-selector {
	background-color: #fff;
	border-color: #f5222d !important;
}

.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-focused .ant-select-selector,
.ant-form-item-has-error .ant-select:not(.ant-select-disabled):not(.ant-select-customize-input).ant-select-open .ant-select-selector {
	border-color: #ff4d4f;
	border-right-width: 1px !important;
	box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
	outline: 0;
}

.ant-form-item-has-error .ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	border: 0;
}

.ant-form-item-has-error .ant-select.ant-select-auto-complete .ant-input:focus {
	border-color: #f5222d;
}

.ant-form-item-has-error .ant-input-number,
.ant-form-item-has-error .ant-picker {
	background-color: #fff;
	border-color: #f5222d;
}

.ant-form-item-has-error .ant-input-number-focused,
.ant-form-item-has-error .ant-input-number:focus,
.ant-form-item-has-error .ant-picker-focused,
.ant-form-item-has-error .ant-picker:focus {
	border-color: #ff4d4f;
	border-right-width: 1px !important;
	box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
	outline: 0;
}

.ant-form-item-has-error .ant-input-number:not([disabled]):hover,
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor,
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):hover,
.ant-form-item-has-error .ant-picker:not([disabled]):hover {
	background-color: #fff;
	border-color: #f5222d;
}

.ant-form-item-has-error .ant-cascader-picker:focus .ant-cascader-input,
.ant-form-item-has-error .ant-mention-wrapper .ant-mention-editor:not([disabled]):focus,
.ant-form-item-has-error .ant-mention-wrapper.ant-mention-active:not([disabled]) .ant-mention-editor {
	border-color: #ff4d4f;
	border-right-width: 1px !important;
	box-shadow: 0 0 0 2px rgba(245, 34, 45, 0.2);
	outline: 0;
}

.ant-form-item-has-error .ant-cascader-picker:focus .ant-cascader-input {
	background-color: #fff;
}

.ant-form-item-has-error .ant-transfer-list {
	border-color: #f5222d;
}

.ant-form-item-has-error .ant-transfer-list-search:not([disabled]) {
	border-color: #d9d9d9;
}

.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
	border-color: #28aaeb;
	border-right-width: 1px !important;
}

.ant-input-rtl .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):hover {
	border-left-width: 1px !important;
	border-right-width: 0;
}

.ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
	border-color: #28aaeb;
	border-right-width: 1px !important;
	box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
	outline: 0;
}

.ant-input-rtl .ant-form-item-has-error .ant-transfer-list-search:not([disabled]):focus {
	border-left-width: 1px !important;
	border-right-width: 0;
}

.ant-form-item-has-error .ant-radio-button-wrapper {
	border-color: #f5222d !important;
}

.ant-form-item-has-error .ant-radio-button-wrapper:not(:first-child):before {
	background-color: #f5222d;
}

.ant-form-item-is-validating.ant-form-item-has-feedback .ant-form-item-children-icon {
	color: #038fde;
	display: inline-block;
}

.ant-form {
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
}

.ant-form legend {
	border: 0;
	border-bottom: 1px solid #d9d9d9;
	color: #595959;
	display: block;
	font-size: 16px;
	line-height: inherit;
	margin-bottom: 20px;
	padding: 0;
	width: 100%;
}

.ant-form label {
	font-size: 14px;
}

.ant-form input[type='search'] {
	box-sizing: border-box;
}

.ant-form input[type='checkbox'],
.ant-form input[type='radio'] {
	line-height: normal;
}

.ant-form input[type='file'] {
	display: block;
}

.ant-form input[type='range'] {
	display: block;
	width: 100%;
}

.ant-form select[multiple],
.ant-form select[size] {
	height: auto;
}

.ant-form input[type='checkbox']:focus,
.ant-form input[type='file']:focus,
.ant-form input[type='radio']:focus {
	outline: thin dotted;
	outline: 5px auto -webkit-focus-ring-color;
	outline-offset: -2px;
}

.ant-form output {
	color: #545454;
	display: block;
	font-size: 14px;
	line-height: 1.3;
	padding-top: 15px;
}

.ant-form .ant-form-text {
	display: inline-block;
	padding-right: 8px;
}

.ant-form-small .ant-form-item-label>label {
	height: 24px;
}

.ant-form-small .ant-form-item-control-input {
	min-height: 24px;
}

.ant-form-large .ant-form-item-label>label {
	height: 40px;
}

.ant-form-large .ant-form-item-control-input {
	min-height: 40px;
}

.ant-form-item {
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0 0 20px;
	padding: 0;
	vertical-align: top;
}

.ant-form-item-with-help {
	margin-bottom: 0;
}

.ant-form-item-hidden,
.ant-form-item-hidden.ant-row {
	display: none;
}

.ant-form-item-label {
	display: inline-block;
	flex-grow: 0;
	overflow: hidden;
	text-align: right;
	vertical-align: middle;
	white-space: nowrap;
}

.ant-form-item-label-left {
	text-align: left;
}

.ant-form-item-label>label {
	align-items: center;
	color: #535353;
	display: inline-flex;
	font-size: 14px;
	height: 36px;
	position: relative;
}

.ant-form-item-label>label>.anticon {
	font-size: 14px;
	vertical-align: top;
}

.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
	color: #f5222d;
	content: '*';
	display: inline-block;
	font-family: SimSun, sans-serif;
	font-size: 14px;
	line-height: 1;
	margin-right: 4px;
}

.ant-form-hide-required-mark .ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional):before {
	display: none;
}

.ant-form-item-label>label .ant-form-item-optional {
	color: #595959;
	display: inline-block;
	margin-left: 4px;
}

.ant-form-hide-required-mark .ant-form-item-label>label .ant-form-item-optional {
	display: none;
}

.ant-form-item-label>label .ant-form-item-tooltip {
	-webkit-margin-start: 4px;
	color: #595959;
	cursor: help;
	margin-inline-start: 4px;
	-webkit-writing-mode: horizontal-tb;
	-ms-writing-mode: lr-tb;
	writing-mode: horizontal-tb;
}

.ant-form-item-label>label:after {
	content: none;
	margin: 0 8px 0 2px;
	position: relative;
	top: -0.5px;
}

.ant-form-item-label>label.ant-form-item-no-colon:after {
	content: ' ';
}

.ant-form-item-control {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

.ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
	width: 100%;
}

.ant-form-item-control-input {
	align-items: center;
	display: flex;
	min-height: 36px;
	position: relative;
}

.ant-form-item-control-input-content {
	flex: auto;
	max-width: 100%;
}

.ant-form-item-control-input-content>textarea {
	min-height: 36px !important;
}

.ant-form-item-explain,
.ant-form-item-extra {
	clear: both;
	color: #595959;
	font-size: 14px;
	line-height: 1.3;
	min-height: 20px;
	transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.ant-form-item .ant-input-textarea-show-count:after {
	margin-bottom: -22px;
}

.ant-show-help-appear,
.ant-show-help-enter,
.ant-show-help-leave {
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}

.ant-show-help-appear.ant-show-help-appear-active,
.ant-show-help-enter.ant-show-help-enter-active {
	-webkit-animation-name: antShowHelpIn;
	animation-name: antShowHelpIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}

.ant-show-help-leave.ant-show-help-leave-active {
	-webkit-animation-name: antShowHelpOut;
	animation-name: antShowHelpOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
	pointer-events: none;
}

.ant-show-help-appear,
.ant-show-help-enter {
	opacity: 0;
}

.ant-show-help-appear,
.ant-show-help-enter,
.ant-show-help-leave {
	-webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

@-webkit-keyframes antShowHelpIn {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-5px);
		transform: translateY(-5px);
	}

	to {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@keyframes antShowHelpIn {
	0% {
		opacity: 0;
		-webkit-transform: translateY(-5px);
		transform: translateY(-5px);
	}

	to {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}

@-webkit-keyframes antShowHelpOut {
	to {
		opacity: 0;
		-webkit-transform: translateY(-5px);
		transform: translateY(-5px);
	}
}

@keyframes antShowHelpOut {
	to {
		opacity: 0;
		-webkit-transform: translateY(-5px);
		transform: translateY(-5px);
	}
}

@-webkit-keyframes diffZoomIn1 {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	to {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes diffZoomIn1 {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	to {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@-webkit-keyframes diffZoomIn2 {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	to {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes diffZoomIn2 {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	to {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@-webkit-keyframes diffZoomIn3 {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	to {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes diffZoomIn3 {
	0% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	to {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.ant-form-rtl {
	direction: rtl;
}

.ant-form-rtl .ant-form-item-label {
	text-align: left;
}

.ant-form-rtl .ant-form-item-label>label.ant-form-item-required:before {
	margin-left: 4px;
	margin-right: 0;
}

.ant-form-rtl .ant-form-item-label>label:after {
	margin: 0 2px 0 8px;
}

.ant-form-rtl .ant-form-item-label>label .ant-form-item-optional {
	margin-left: 0;
	margin-right: 4px;
}

.ant-col-rtl .ant-form-item-control:first-child {
	width: 100%;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input {
	padding-left: 24px;
	padding-right: 11px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input-suffix {
	padding-left: 18px;
	padding-right: 11px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-affix-wrapper .ant-input {
	padding: 0;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-search:not(.ant-input-search-enter-button) .ant-input-suffix {
	left: 28px;
	right: auto;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-input-number {
	padding-left: 18px;
}

.ant-form-rtl .ant-form-item-has-feedback:not(.ant-input-group-addon)>.ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback:not(.ant-input-group-addon)>.ant-select .ant-select-clear,
.ant-form-rtl .ant-form-item-has-feedback>.ant-select .ant-select-arrow,
.ant-form-rtl .ant-form-item-has-feedback>.ant-select .ant-select-clear {
	left: 32px;
	right: auto;
}

.ant-form-rtl .ant-form-item-has-feedback:not(.ant-input-group-addon)>.ant-select .ant-select-selection-selected-value,
.ant-form-rtl .ant-form-item-has-feedback>.ant-select .ant-select-selection-selected-value {
	padding-left: 42px;
	padding-right: 0;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-arrow {
	margin-left: 19px;
	margin-right: 0;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-cascader-picker-clear {
	left: 32px;
	right: auto;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-picker,
.ant-form-rtl .ant-form-item-has-feedback .ant-picker-large {
	padding-left: 29.2px;
	padding-right: 11px;
}

.ant-form-rtl .ant-form-item-has-feedback .ant-picker-small {
	padding-left: 25.2px;
	padding-right: 7px;
}

.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-error .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-success .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-has-warning .ant-form-item-children-icon,
.ant-form-rtl .ant-form-item-has-feedback.ant-form-item-is-validating .ant-form-item-children-icon {
	left: 0;
	right: auto;
}

.ant-form-rtl.ant-form-inline .ant-form-item {
	margin-left: 16px;
	margin-right: 0;
}

.ant-row {
	flex-flow: row wrap;
}

.ant-row:after,
.ant-row:before {
	display: flex;
}

.ant-row-no-wrap {
	flex-wrap: nowrap;
}

.ant-row-start {
	justify-content: flex-start;
}

.ant-row-center {
	justify-content: center;
}

.ant-row-end {
	justify-content: flex-end;
}

.ant-row-space-between {
	justify-content: space-between;
}

.ant-row-space-around {
	justify-content: space-around;
}

.ant-row-top {
	align-items: flex-start;
}

.ant-row-middle {
	align-items: center;
}

.ant-row-bottom {
	align-items: flex-end;
}

.ant-col {
	max-width: 100%;
	min-height: 1px;
	position: relative;
}

.ant-col-24 {
	display: block;
	flex: 0 0 100%;
	max-width: 100%;
}

.ant-col-push-24 {
	left: 100%;
}

.ant-col-pull-24 {
	right: 100%;
}

.ant-col-offset-24 {
	margin-left: 100%;
}

.ant-col-order-24 {
	order: 24;
}

.ant-col-23 {
	display: block;
	flex: 0 0 95.83333333%;
	max-width: 95.83333333%;
}

.ant-col-push-23 {
	left: 95.83333333%;
}

.ant-col-pull-23 {
	right: 95.83333333%;
}

.ant-col-offset-23 {
	margin-left: 95.83333333%;
}

.ant-col-order-23 {
	order: 23;
}

.ant-col-22 {
	display: block;
	flex: 0 0 91.66666667%;
	max-width: 91.66666667%;
}

.ant-col-push-22 {
	left: 91.66666667%;
}

.ant-col-pull-22 {
	right: 91.66666667%;
}

.ant-col-offset-22 {
	margin-left: 91.66666667%;
}

.ant-col-order-22 {
	order: 22;
}

.ant-col-21 {
	display: block;
	flex: 0 0 87.5%;
	max-width: 87.5%;
}

.ant-col-push-21 {
	left: 87.5%;
}

.ant-col-pull-21 {
	right: 87.5%;
}

.ant-col-offset-21 {
	margin-left: 87.5%;
}

.ant-col-order-21 {
	order: 21;
}

.ant-col-20 {
	display: block;
	flex: 0 0 83.33333333%;
	max-width: 83.33333333%;
}

.ant-col-push-20 {
	left: 83.33333333%;
}

.ant-col-pull-20 {
	right: 83.33333333%;
}

.ant-col-offset-20 {
	margin-left: 83.33333333%;
}

.ant-col-order-20 {
	order: 20;
}

.ant-col-19 {
	display: block;
	flex: 0 0 79.16666667%;
	max-width: 79.16666667%;
}

.ant-col-push-19 {
	left: 79.16666667%;
}

.ant-col-pull-19 {
	right: 79.16666667%;
}

.ant-col-offset-19 {
	margin-left: 79.16666667%;
}

.ant-col-order-19 {
	order: 19;
}

.ant-col-18 {
	display: block;
	flex: 0 0 75%;
	max-width: 75%;
}

.ant-col-push-18 {
	left: 75%;
}

.ant-col-pull-18 {
	right: 75%;
}

.ant-col-offset-18 {
	margin-left: 75%;
}

.ant-col-order-18 {
	order: 18;
}

.ant-col-17 {
	display: block;
	flex: 0 0 70.83333333%;
	max-width: 70.83333333%;
}

.ant-col-push-17 {
	left: 70.83333333%;
}

.ant-col-pull-17 {
	right: 70.83333333%;
}

.ant-col-offset-17 {
	margin-left: 70.83333333%;
}

.ant-col-order-17 {
	order: 17;
}

.ant-col-16 {
	display: block;
	flex: 0 0 66.66666667%;
	max-width: 66.66666667%;
}

.ant-col-push-16 {
	left: 66.66666667%;
}

.ant-col-pull-16 {
	right: 66.66666667%;
}

.ant-col-offset-16 {
	margin-left: 66.66666667%;
}

.ant-col-order-16 {
	order: 16;
}

.ant-col-15 {
	display: block;
	flex: 0 0 62.5%;
	max-width: 62.5%;
}

.ant-col-push-15 {
	left: 62.5%;
}

.ant-col-pull-15 {
	right: 62.5%;
}

.ant-col-offset-15 {
	margin-left: 62.5%;
}

.ant-col-order-15 {
	order: 15;
}

.ant-col-14 {
	display: block;
	flex: 0 0 58.33333333%;
	max-width: 58.33333333%;
}

.ant-col-push-14 {
	left: 58.33333333%;
}

.ant-col-pull-14 {
	right: 58.33333333%;
}

.ant-col-offset-14 {
	margin-left: 58.33333333%;
}

.ant-col-order-14 {
	order: 14;
}

.ant-col-13 {
	display: block;
	flex: 0 0 54.16666667%;
	max-width: 54.16666667%;
}

.ant-col-push-13 {
	left: 54.16666667%;
}

.ant-col-pull-13 {
	right: 54.16666667%;
}

.ant-col-offset-13 {
	margin-left: 54.16666667%;
}

.ant-col-order-13 {
	order: 13;
}

.ant-col-12 {
	display: block;
	flex: 0 0 50%;
	max-width: 50%;
}

.ant-col-push-12 {
	left: 50%;
}

.ant-col-pull-12 {
	right: 50%;
}

.ant-col-offset-12 {
	margin-left: 50%;
}

.ant-col-order-12 {
	order: 12;
}

.ant-col-11 {
	display: block;
	flex: 0 0 45.83333333%;
	max-width: 45.83333333%;
}

.ant-col-push-11 {
	left: 45.83333333%;
}

.ant-col-pull-11 {
	right: 45.83333333%;
}

.ant-col-offset-11 {
	margin-left: 45.83333333%;
}

.ant-col-order-11 {
	order: 11;
}

.ant-col-10 {
	display: block;
	flex: 0 0 41.66666667%;
	max-width: 41.66666667%;
}

.ant-col-push-10 {
	left: 41.66666667%;
}

.ant-col-pull-10 {
	right: 41.66666667%;
}

.ant-col-offset-10 {
	margin-left: 41.66666667%;
}

.ant-col-order-10 {
	order: 10;
}

.ant-col-9 {
	display: block;
	flex: 0 0 37.5%;
	max-width: 37.5%;
}

.ant-col-push-9 {
	left: 37.5%;
}

.ant-col-pull-9 {
	right: 37.5%;
}

.ant-col-offset-9 {
	margin-left: 37.5%;
}

.ant-col-order-9 {
	order: 9;
}

.ant-col-8 {
	display: block;
	flex: 0 0 33.33333333%;
	max-width: 33.33333333%;
}

.ant-col-push-8 {
	left: 33.33333333%;
}

.ant-col-pull-8 {
	right: 33.33333333%;
}

.ant-col-offset-8 {
	margin-left: 33.33333333%;
}

.ant-col-order-8 {
	order: 8;
}

.ant-col-7 {
	display: block;
	flex: 0 0 29.16666667%;
	max-width: 29.16666667%;
}

.ant-col-push-7 {
	left: 29.16666667%;
}

.ant-col-pull-7 {
	right: 29.16666667%;
}

.ant-col-offset-7 {
	margin-left: 29.16666667%;
}

.ant-col-order-7 {
	order: 7;
}

.ant-col-6 {
	display: block;
	flex: 0 0 25%;
	max-width: 25%;
}

.ant-col-push-6 {
	left: 25%;
}

.ant-col-pull-6 {
	right: 25%;
}

.ant-col-offset-6 {
	margin-left: 25%;
}

.ant-col-order-6 {
	order: 6;
}

.ant-col-5 {
	display: block;
	flex: 0 0 20.83333333%;
	max-width: 20.83333333%;
}

.ant-col-push-5 {
	left: 20.83333333%;
}

.ant-col-pull-5 {
	right: 20.83333333%;
}

.ant-col-offset-5 {
	margin-left: 20.83333333%;
}

.ant-col-order-5 {
	order: 5;
}

.ant-col-4 {
	display: block;
	flex: 0 0 16.66666667%;
	max-width: 16.66666667%;
}

.ant-col-push-4 {
	left: 16.66666667%;
}

.ant-col-pull-4 {
	right: 16.66666667%;
}

.ant-col-offset-4 {
	margin-left: 16.66666667%;
}

.ant-col-order-4 {
	order: 4;
}

.ant-col-3 {
	display: block;
	flex: 0 0 12.5%;
	max-width: 12.5%;
}

.ant-col-push-3 {
	left: 12.5%;
}

.ant-col-pull-3 {
	right: 12.5%;
}

.ant-col-offset-3 {
	margin-left: 12.5%;
}

.ant-col-order-3 {
	order: 3;
}

.ant-col-2 {
	display: block;
	flex: 0 0 8.33333333%;
	max-width: 8.33333333%;
}

.ant-col-push-2 {
	left: 8.33333333%;
}

.ant-col-pull-2 {
	right: 8.33333333%;
}

.ant-col-offset-2 {
	margin-left: 8.33333333%;
}

.ant-col-order-2 {
	order: 2;
}

.ant-col-1 {
	display: block;
	flex: 0 0 4.16666667%;
	max-width: 4.16666667%;
}

.ant-col-push-1 {
	left: 4.16666667%;
}

.ant-col-pull-1 {
	right: 4.16666667%;
}

.ant-col-offset-1 {
	margin-left: 4.16666667%;
}

.ant-col-order-1 {
	order: 1;
}

.ant-col-0 {
	display: none;
}

.ant-col-offset-0 {
	margin-left: 0;
}

.ant-col-order-0 {
	order: 0;
}

.ant-col-offset-0.ant-col-rtl {
	margin-right: 0;
}

.ant-col-push-1.ant-col-rtl {
	left: auto;
	right: 4.16666667%;
}

.ant-col-pull-1.ant-col-rtl {
	left: 4.16666667%;
	right: auto;
}

.ant-col-offset-1.ant-col-rtl {
	margin-left: 0;
	margin-right: 4.16666667%;
}

.ant-col-push-2.ant-col-rtl {
	left: auto;
	right: 8.33333333%;
}

.ant-col-pull-2.ant-col-rtl {
	left: 8.33333333%;
	right: auto;
}

.ant-col-offset-2.ant-col-rtl {
	margin-left: 0;
	margin-right: 8.33333333%;
}

.ant-col-push-3.ant-col-rtl {
	left: auto;
	right: 12.5%;
}

.ant-col-pull-3.ant-col-rtl {
	left: 12.5%;
	right: auto;
}

.ant-col-offset-3.ant-col-rtl {
	margin-left: 0;
	margin-right: 12.5%;
}

.ant-col-push-4.ant-col-rtl {
	left: auto;
	right: 16.66666667%;
}

.ant-col-pull-4.ant-col-rtl {
	left: 16.66666667%;
	right: auto;
}

.ant-col-offset-4.ant-col-rtl {
	margin-left: 0;
	margin-right: 16.66666667%;
}

.ant-col-push-5.ant-col-rtl {
	left: auto;
	right: 20.83333333%;
}

.ant-col-pull-5.ant-col-rtl {
	left: 20.83333333%;
	right: auto;
}

.ant-col-offset-5.ant-col-rtl {
	margin-left: 0;
	margin-right: 20.83333333%;
}

.ant-col-push-6.ant-col-rtl {
	left: auto;
	right: 25%;
}

.ant-col-pull-6.ant-col-rtl {
	left: 25%;
	right: auto;
}

.ant-col-offset-6.ant-col-rtl {
	margin-left: 0;
	margin-right: 25%;
}

.ant-col-push-7.ant-col-rtl {
	left: auto;
	right: 29.16666667%;
}

.ant-col-pull-7.ant-col-rtl {
	left: 29.16666667%;
	right: auto;
}

.ant-col-offset-7.ant-col-rtl {
	margin-left: 0;
	margin-right: 29.16666667%;
}

.ant-col-push-8.ant-col-rtl {
	left: auto;
	right: 33.33333333%;
}

.ant-col-pull-8.ant-col-rtl {
	left: 33.33333333%;
	right: auto;
}

.ant-col-offset-8.ant-col-rtl {
	margin-left: 0;
	margin-right: 33.33333333%;
}

.ant-col-push-9.ant-col-rtl {
	left: auto;
	right: 37.5%;
}

.ant-col-pull-9.ant-col-rtl {
	left: 37.5%;
	right: auto;
}

.ant-col-offset-9.ant-col-rtl {
	margin-left: 0;
	margin-right: 37.5%;
}

.ant-col-push-10.ant-col-rtl {
	left: auto;
	right: 41.66666667%;
}

.ant-col-pull-10.ant-col-rtl {
	left: 41.66666667%;
	right: auto;
}

.ant-col-offset-10.ant-col-rtl {
	margin-left: 0;
	margin-right: 41.66666667%;
}

.ant-col-push-11.ant-col-rtl {
	left: auto;
	right: 45.83333333%;
}

.ant-col-pull-11.ant-col-rtl {
	left: 45.83333333%;
	right: auto;
}

.ant-col-offset-11.ant-col-rtl {
	margin-left: 0;
	margin-right: 45.83333333%;
}

.ant-col-push-12.ant-col-rtl {
	left: auto;
	right: 50%;
}

.ant-col-pull-12.ant-col-rtl {
	left: 50%;
	right: auto;
}

.ant-col-offset-12.ant-col-rtl {
	margin-left: 0;
	margin-right: 50%;
}

.ant-col-push-13.ant-col-rtl {
	left: auto;
	right: 54.16666667%;
}

.ant-col-pull-13.ant-col-rtl {
	left: 54.16666667%;
	right: auto;
}

.ant-col-offset-13.ant-col-rtl {
	margin-left: 0;
	margin-right: 54.16666667%;
}

.ant-col-push-14.ant-col-rtl {
	left: auto;
	right: 58.33333333%;
}

.ant-col-pull-14.ant-col-rtl {
	left: 58.33333333%;
	right: auto;
}

.ant-col-offset-14.ant-col-rtl {
	margin-left: 0;
	margin-right: 58.33333333%;
}

.ant-col-push-15.ant-col-rtl {
	left: auto;
	right: 62.5%;
}

.ant-col-pull-15.ant-col-rtl {
	left: 62.5%;
	right: auto;
}

.ant-col-offset-15.ant-col-rtl {
	margin-left: 0;
	margin-right: 62.5%;
}

.ant-col-push-16.ant-col-rtl {
	left: auto;
	right: 66.66666667%;
}

.ant-col-pull-16.ant-col-rtl {
	left: 66.66666667%;
	right: auto;
}

.ant-col-offset-16.ant-col-rtl {
	margin-left: 0;
	margin-right: 66.66666667%;
}

.ant-col-push-17.ant-col-rtl {
	left: auto;
	right: 70.83333333%;
}

.ant-col-pull-17.ant-col-rtl {
	left: 70.83333333%;
	right: auto;
}

.ant-col-offset-17.ant-col-rtl {
	margin-left: 0;
	margin-right: 70.83333333%;
}

.ant-col-push-18.ant-col-rtl {
	left: auto;
	right: 75%;
}

.ant-col-pull-18.ant-col-rtl {
	left: 75%;
	right: auto;
}

.ant-col-offset-18.ant-col-rtl {
	margin-left: 0;
	margin-right: 75%;
}

.ant-col-push-19.ant-col-rtl {
	left: auto;
	right: 79.16666667%;
}

.ant-col-pull-19.ant-col-rtl {
	left: 79.16666667%;
	right: auto;
}

.ant-col-offset-19.ant-col-rtl {
	margin-left: 0;
	margin-right: 79.16666667%;
}

.ant-col-push-20.ant-col-rtl {
	left: auto;
	right: 83.33333333%;
}

.ant-col-pull-20.ant-col-rtl {
	left: 83.33333333%;
	right: auto;
}

.ant-col-offset-20.ant-col-rtl {
	margin-left: 0;
	margin-right: 83.33333333%;
}

.ant-col-push-21.ant-col-rtl {
	left: auto;
	right: 87.5%;
}

.ant-col-pull-21.ant-col-rtl {
	left: 87.5%;
	right: auto;
}

.ant-col-offset-21.ant-col-rtl {
	margin-left: 0;
	margin-right: 87.5%;
}

.ant-col-push-22.ant-col-rtl {
	left: auto;
	right: 91.66666667%;
}

.ant-col-pull-22.ant-col-rtl {
	left: 91.66666667%;
	right: auto;
}

.ant-col-offset-22.ant-col-rtl {
	margin-left: 0;
	margin-right: 91.66666667%;
}

.ant-col-push-23.ant-col-rtl {
	left: auto;
	right: 95.83333333%;
}

.ant-col-pull-23.ant-col-rtl {
	left: 95.83333333%;
	right: auto;
}

.ant-col-offset-23.ant-col-rtl {
	margin-left: 0;
	margin-right: 95.83333333%;
}

.ant-col-push-24.ant-col-rtl {
	left: auto;
	right: 100%;
}

.ant-col-pull-24.ant-col-rtl {
	left: 100%;
	right: auto;
}

.ant-col-offset-24.ant-col-rtl {
	margin-left: 0;
	margin-right: 100%;
}

.ant-col-xs-24 {
	display: block;
	flex: 0 0 100%;
	max-width: 100%;
}

.ant-col-xs-push-24 {
	left: 100%;
}

.ant-col-xs-pull-24 {
	right: 100%;
}

.ant-col-xs-offset-24 {
	margin-left: 100%;
}

.ant-col-xs-order-24 {
	order: 24;
}

.ant-col-xs-23 {
	display: block;
	flex: 0 0 95.83333333%;
	max-width: 95.83333333%;
}

.ant-col-xs-push-23 {
	left: 95.83333333%;
}

.ant-col-xs-pull-23 {
	right: 95.83333333%;
}

.ant-col-xs-offset-23 {
	margin-left: 95.83333333%;
}

.ant-col-xs-order-23 {
	order: 23;
}

.ant-col-xs-22 {
	display: block;
	flex: 0 0 91.66666667%;
	max-width: 91.66666667%;
}

.ant-col-xs-push-22 {
	left: 91.66666667%;
}

.ant-col-xs-pull-22 {
	right: 91.66666667%;
}

.ant-col-xs-offset-22 {
	margin-left: 91.66666667%;
}

.ant-col-xs-order-22 {
	order: 22;
}

.ant-col-xs-21 {
	display: block;
	flex: 0 0 87.5%;
	max-width: 87.5%;
}

.ant-col-xs-push-21 {
	left: 87.5%;
}

.ant-col-xs-pull-21 {
	right: 87.5%;
}

.ant-col-xs-offset-21 {
	margin-left: 87.5%;
}

.ant-col-xs-order-21 {
	order: 21;
}

.ant-col-xs-20 {
	display: block;
	flex: 0 0 83.33333333%;
	max-width: 83.33333333%;
}

.ant-col-xs-push-20 {
	left: 83.33333333%;
}

.ant-col-xs-pull-20 {
	right: 83.33333333%;
}

.ant-col-xs-offset-20 {
	margin-left: 83.33333333%;
}

.ant-col-xs-order-20 {
	order: 20;
}

.ant-col-xs-19 {
	display: block;
	flex: 0 0 79.16666667%;
	max-width: 79.16666667%;
}

.ant-col-xs-push-19 {
	left: 79.16666667%;
}

.ant-col-xs-pull-19 {
	right: 79.16666667%;
}

.ant-col-xs-offset-19 {
	margin-left: 79.16666667%;
}

.ant-col-xs-order-19 {
	order: 19;
}

.ant-col-xs-18 {
	display: block;
	flex: 0 0 75%;
	max-width: 75%;
}

.ant-col-xs-push-18 {
	left: 75%;
}

.ant-col-xs-pull-18 {
	right: 75%;
}

.ant-col-xs-offset-18 {
	margin-left: 75%;
}

.ant-col-xs-order-18 {
	order: 18;
}

.ant-col-xs-17 {
	display: block;
	flex: 0 0 70.83333333%;
	max-width: 70.83333333%;
}

.ant-col-xs-push-17 {
	left: 70.83333333%;
}

.ant-col-xs-pull-17 {
	right: 70.83333333%;
}

.ant-col-xs-offset-17 {
	margin-left: 70.83333333%;
}

.ant-col-xs-order-17 {
	order: 17;
}

.ant-col-xs-16 {
	display: block;
	flex: 0 0 66.66666667%;
	max-width: 66.66666667%;
}

.ant-col-xs-push-16 {
	left: 66.66666667%;
}

.ant-col-xs-pull-16 {
	right: 66.66666667%;
}

.ant-col-xs-offset-16 {
	margin-left: 66.66666667%;
}

.ant-col-xs-order-16 {
	order: 16;
}

.ant-col-xs-15 {
	display: block;
	flex: 0 0 62.5%;
	max-width: 62.5%;
}

.ant-col-xs-push-15 {
	left: 62.5%;
}

.ant-col-xs-pull-15 {
	right: 62.5%;
}

.ant-col-xs-offset-15 {
	margin-left: 62.5%;
}

.ant-col-xs-order-15 {
	order: 15;
}

.ant-col-xs-14 {
	display: block;
	flex: 0 0 58.33333333%;
	max-width: 58.33333333%;
}

.ant-col-xs-push-14 {
	left: 58.33333333%;
}

.ant-col-xs-pull-14 {
	right: 58.33333333%;
}

.ant-col-xs-offset-14 {
	margin-left: 58.33333333%;
}

.ant-col-xs-order-14 {
	order: 14;
}

.ant-col-xs-13 {
	display: block;
	flex: 0 0 54.16666667%;
	max-width: 54.16666667%;
}

.ant-col-xs-push-13 {
	left: 54.16666667%;
}

.ant-col-xs-pull-13 {
	right: 54.16666667%;
}

.ant-col-xs-offset-13 {
	margin-left: 54.16666667%;
}

.ant-col-xs-order-13 {
	order: 13;
}

.ant-col-xs-12 {
	display: block;
	flex: 0 0 50%;
	max-width: 50%;
}

.ant-col-xs-push-12 {
	left: 50%;
}

.ant-col-xs-pull-12 {
	right: 50%;
}

.ant-col-xs-offset-12 {
	margin-left: 50%;
}

.ant-col-xs-order-12 {
	order: 12;
}

.ant-col-xs-11 {
	display: block;
	flex: 0 0 45.83333333%;
	max-width: 45.83333333%;
}

.ant-col-xs-push-11 {
	left: 45.83333333%;
}

.ant-col-xs-pull-11 {
	right: 45.83333333%;
}

.ant-col-xs-offset-11 {
	margin-left: 45.83333333%;
}

.ant-col-xs-order-11 {
	order: 11;
}

.ant-col-xs-10 {
	display: block;
	flex: 0 0 41.66666667%;
	max-width: 41.66666667%;
}

.ant-col-xs-push-10 {
	left: 41.66666667%;
}

.ant-col-xs-pull-10 {
	right: 41.66666667%;
}

.ant-col-xs-offset-10 {
	margin-left: 41.66666667%;
}

.ant-col-xs-order-10 {
	order: 10;
}

.ant-col-xs-9 {
	display: block;
	flex: 0 0 37.5%;
	max-width: 37.5%;
}

.ant-col-xs-push-9 {
	left: 37.5%;
}

.ant-col-xs-pull-9 {
	right: 37.5%;
}

.ant-col-xs-offset-9 {
	margin-left: 37.5%;
}

.ant-col-xs-order-9 {
	order: 9;
}

.ant-col-xs-8 {
	display: block;
	flex: 0 0 33.33333333%;
	max-width: 33.33333333%;
}

.ant-col-xs-push-8 {
	left: 33.33333333%;
}

.ant-col-xs-pull-8 {
	right: 33.33333333%;
}

.ant-col-xs-offset-8 {
	margin-left: 33.33333333%;
}

.ant-col-xs-order-8 {
	order: 8;
}

.ant-col-xs-7 {
	display: block;
	flex: 0 0 29.16666667%;
	max-width: 29.16666667%;
}

.ant-col-xs-push-7 {
	left: 29.16666667%;
}

.ant-col-xs-pull-7 {
	right: 29.16666667%;
}

.ant-col-xs-offset-7 {
	margin-left: 29.16666667%;
}

.ant-col-xs-order-7 {
	order: 7;
}

.ant-col-xs-6 {
	display: block;
	flex: 0 0 25%;
	max-width: 25%;
}

.ant-col-xs-push-6 {
	left: 25%;
}

.ant-col-xs-pull-6 {
	right: 25%;
}

.ant-col-xs-offset-6 {
	margin-left: 25%;
}

.ant-col-xs-order-6 {
	order: 6;
}

.ant-col-xs-5 {
	display: block;
	flex: 0 0 20.83333333%;
	max-width: 20.83333333%;
}

.ant-col-xs-push-5 {
	left: 20.83333333%;
}

.ant-col-xs-pull-5 {
	right: 20.83333333%;
}

.ant-col-xs-offset-5 {
	margin-left: 20.83333333%;
}

.ant-col-xs-order-5 {
	order: 5;
}

.ant-col-xs-4 {
	display: block;
	flex: 0 0 16.66666667%;
	max-width: 16.66666667%;
}

.ant-col-xs-push-4 {
	left: 16.66666667%;
}

.ant-col-xs-pull-4 {
	right: 16.66666667%;
}

.ant-col-xs-offset-4 {
	margin-left: 16.66666667%;
}

.ant-col-xs-order-4 {
	order: 4;
}

.ant-col-xs-3 {
	display: block;
	flex: 0 0 12.5%;
	max-width: 12.5%;
}

.ant-col-xs-push-3 {
	left: 12.5%;
}

.ant-col-xs-pull-3 {
	right: 12.5%;
}

.ant-col-xs-offset-3 {
	margin-left: 12.5%;
}

.ant-col-xs-order-3 {
	order: 3;
}

.ant-col-xs-2 {
	display: block;
	flex: 0 0 8.33333333%;
	max-width: 8.33333333%;
}

.ant-col-xs-push-2 {
	left: 8.33333333%;
}

.ant-col-xs-pull-2 {
	right: 8.33333333%;
}

.ant-col-xs-offset-2 {
	margin-left: 8.33333333%;
}

.ant-col-xs-order-2 {
	order: 2;
}

.ant-col-xs-1 {
	display: block;
	flex: 0 0 4.16666667%;
	max-width: 4.16666667%;
}

.ant-col-xs-push-1 {
	left: 4.16666667%;
}

.ant-col-xs-pull-1 {
	right: 4.16666667%;
}

.ant-col-xs-offset-1 {
	margin-left: 4.16666667%;
}

.ant-col-xs-order-1 {
	order: 1;
}

.ant-col-xs-0 {
	display: none;
}

.ant-col-push-0 {
	left: auto;
}

.ant-col-pull-0 {
	right: auto;
}

.ant-col-xs-push-0 {
	left: auto;
}

.ant-col-xs-pull-0 {
	right: auto;
}

.ant-col-xs-offset-0 {
	margin-left: 0;
}

.ant-col-xs-order-0 {
	order: 0;
}

.ant-col-push-0.ant-col-rtl {
	right: auto;
}

.ant-col-pull-0.ant-col-rtl {
	left: auto;
}

.ant-col-xs-push-0.ant-col-rtl {
	right: auto;
}

.ant-col-xs-pull-0.ant-col-rtl {
	left: auto;
}

.ant-col-xs-offset-0.ant-col-rtl {
	margin-right: 0;
}

.ant-col-xs-push-1.ant-col-rtl {
	left: auto;
	right: 4.16666667%;
}

.ant-col-xs-pull-1.ant-col-rtl {
	left: 4.16666667%;
	right: auto;
}

.ant-col-xs-offset-1.ant-col-rtl {
	margin-left: 0;
	margin-right: 4.16666667%;
}

.ant-col-xs-push-2.ant-col-rtl {
	left: auto;
	right: 8.33333333%;
}

.ant-col-xs-pull-2.ant-col-rtl {
	left: 8.33333333%;
	right: auto;
}

.ant-col-xs-offset-2.ant-col-rtl {
	margin-left: 0;
	margin-right: 8.33333333%;
}

.ant-col-xs-push-3.ant-col-rtl {
	left: auto;
	right: 12.5%;
}

.ant-col-xs-pull-3.ant-col-rtl {
	left: 12.5%;
	right: auto;
}

.ant-col-xs-offset-3.ant-col-rtl {
	margin-left: 0;
	margin-right: 12.5%;
}

.ant-col-xs-push-4.ant-col-rtl {
	left: auto;
	right: 16.66666667%;
}

.ant-col-xs-pull-4.ant-col-rtl {
	left: 16.66666667%;
	right: auto;
}

.ant-col-xs-offset-4.ant-col-rtl {
	margin-left: 0;
	margin-right: 16.66666667%;
}

.ant-col-xs-push-5.ant-col-rtl {
	left: auto;
	right: 20.83333333%;
}

.ant-col-xs-pull-5.ant-col-rtl {
	left: 20.83333333%;
	right: auto;
}

.ant-col-xs-offset-5.ant-col-rtl {
	margin-left: 0;
	margin-right: 20.83333333%;
}

.ant-col-xs-push-6.ant-col-rtl {
	left: auto;
	right: 25%;
}

.ant-col-xs-pull-6.ant-col-rtl {
	left: 25%;
	right: auto;
}

.ant-col-xs-offset-6.ant-col-rtl {
	margin-left: 0;
	margin-right: 25%;
}

.ant-col-xs-push-7.ant-col-rtl {
	left: auto;
	right: 29.16666667%;
}

.ant-col-xs-pull-7.ant-col-rtl {
	left: 29.16666667%;
	right: auto;
}

.ant-col-xs-offset-7.ant-col-rtl {
	margin-left: 0;
	margin-right: 29.16666667%;
}

.ant-col-xs-push-8.ant-col-rtl {
	left: auto;
	right: 33.33333333%;
}

.ant-col-xs-pull-8.ant-col-rtl {
	left: 33.33333333%;
	right: auto;
}

.ant-col-xs-offset-8.ant-col-rtl {
	margin-left: 0;
	margin-right: 33.33333333%;
}

.ant-col-xs-push-9.ant-col-rtl {
	left: auto;
	right: 37.5%;
}

.ant-col-xs-pull-9.ant-col-rtl {
	left: 37.5%;
	right: auto;
}

.ant-col-xs-offset-9.ant-col-rtl {
	margin-left: 0;
	margin-right: 37.5%;
}

.ant-col-xs-push-10.ant-col-rtl {
	left: auto;
	right: 41.66666667%;
}

.ant-col-xs-pull-10.ant-col-rtl {
	left: 41.66666667%;
	right: auto;
}

.ant-col-xs-offset-10.ant-col-rtl {
	margin-left: 0;
	margin-right: 41.66666667%;
}

.ant-col-xs-push-11.ant-col-rtl {
	left: auto;
	right: 45.83333333%;
}

.ant-col-xs-pull-11.ant-col-rtl {
	left: 45.83333333%;
	right: auto;
}

.ant-col-xs-offset-11.ant-col-rtl {
	margin-left: 0;
	margin-right: 45.83333333%;
}

.ant-col-xs-push-12.ant-col-rtl {
	left: auto;
	right: 50%;
}

.ant-col-xs-pull-12.ant-col-rtl {
	left: 50%;
	right: auto;
}

.ant-col-xs-offset-12.ant-col-rtl {
	margin-left: 0;
	margin-right: 50%;
}

.ant-col-xs-push-13.ant-col-rtl {
	left: auto;
	right: 54.16666667%;
}

.ant-col-xs-pull-13.ant-col-rtl {
	left: 54.16666667%;
	right: auto;
}

.ant-col-xs-offset-13.ant-col-rtl {
	margin-left: 0;
	margin-right: 54.16666667%;
}

.ant-col-xs-push-14.ant-col-rtl {
	left: auto;
	right: 58.33333333%;
}

.ant-col-xs-pull-14.ant-col-rtl {
	left: 58.33333333%;
	right: auto;
}

.ant-col-xs-offset-14.ant-col-rtl {
	margin-left: 0;
	margin-right: 58.33333333%;
}

.ant-col-xs-push-15.ant-col-rtl {
	left: auto;
	right: 62.5%;
}

.ant-col-xs-pull-15.ant-col-rtl {
	left: 62.5%;
	right: auto;
}

.ant-col-xs-offset-15.ant-col-rtl {
	margin-left: 0;
	margin-right: 62.5%;
}

.ant-col-xs-push-16.ant-col-rtl {
	left: auto;
	right: 66.66666667%;
}

.ant-col-xs-pull-16.ant-col-rtl {
	left: 66.66666667%;
	right: auto;
}

.ant-col-xs-offset-16.ant-col-rtl {
	margin-left: 0;
	margin-right: 66.66666667%;
}

.ant-col-xs-push-17.ant-col-rtl {
	left: auto;
	right: 70.83333333%;
}

.ant-col-xs-pull-17.ant-col-rtl {
	left: 70.83333333%;
	right: auto;
}

.ant-col-xs-offset-17.ant-col-rtl {
	margin-left: 0;
	margin-right: 70.83333333%;
}

.ant-col-xs-push-18.ant-col-rtl {
	left: auto;
	right: 75%;
}

.ant-col-xs-pull-18.ant-col-rtl {
	left: 75%;
	right: auto;
}

.ant-col-xs-offset-18.ant-col-rtl {
	margin-left: 0;
	margin-right: 75%;
}

.ant-col-xs-push-19.ant-col-rtl {
	left: auto;
	right: 79.16666667%;
}

.ant-col-xs-pull-19.ant-col-rtl {
	left: 79.16666667%;
	right: auto;
}

.ant-col-xs-offset-19.ant-col-rtl {
	margin-left: 0;
	margin-right: 79.16666667%;
}

.ant-col-xs-push-20.ant-col-rtl {
	left: auto;
	right: 83.33333333%;
}

.ant-col-xs-pull-20.ant-col-rtl {
	left: 83.33333333%;
	right: auto;
}

.ant-col-xs-offset-20.ant-col-rtl {
	margin-left: 0;
	margin-right: 83.33333333%;
}

.ant-col-xs-push-21.ant-col-rtl {
	left: auto;
	right: 87.5%;
}

.ant-col-xs-pull-21.ant-col-rtl {
	left: 87.5%;
	right: auto;
}

.ant-col-xs-offset-21.ant-col-rtl {
	margin-left: 0;
	margin-right: 87.5%;
}

.ant-col-xs-push-22.ant-col-rtl {
	left: auto;
	right: 91.66666667%;
}

.ant-col-xs-pull-22.ant-col-rtl {
	left: 91.66666667%;
	right: auto;
}

.ant-col-xs-offset-22.ant-col-rtl {
	margin-left: 0;
	margin-right: 91.66666667%;
}

.ant-col-xs-push-23.ant-col-rtl {
	left: auto;
	right: 95.83333333%;
}

.ant-col-xs-pull-23.ant-col-rtl {
	left: 95.83333333%;
	right: auto;
}

.ant-col-xs-offset-23.ant-col-rtl {
	margin-left: 0;
	margin-right: 95.83333333%;
}

.ant-col-xs-push-24.ant-col-rtl {
	left: auto;
	right: 100%;
}

.ant-col-xs-pull-24.ant-col-rtl {
	left: 100%;
	right: auto;
}

.ant-col-xs-offset-24.ant-col-rtl {
	margin-left: 0;
	margin-right: 100%;
}

@media (min-width: 576px) {
	.ant-col-sm-24 {
		display: block;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.ant-col-sm-push-24 {
		left: 100%;
	}

	.ant-col-sm-pull-24 {
		right: 100%;
	}

	.ant-col-sm-offset-24 {
		margin-left: 100%;
	}

	.ant-col-sm-order-24 {
		order: 24;
	}

	.ant-col-sm-23 {
		display: block;
		flex: 0 0 95.83333333%;
		max-width: 95.83333333%;
	}

	.ant-col-sm-push-23 {
		left: 95.83333333%;
	}

	.ant-col-sm-pull-23 {
		right: 95.83333333%;
	}

	.ant-col-sm-offset-23 {
		margin-left: 95.83333333%;
	}

	.ant-col-sm-order-23 {
		order: 23;
	}

	.ant-col-sm-22 {
		display: block;
		flex: 0 0 91.66666667%;
		max-width: 91.66666667%;
	}

	.ant-col-sm-push-22 {
		left: 91.66666667%;
	}

	.ant-col-sm-pull-22 {
		right: 91.66666667%;
	}

	.ant-col-sm-offset-22 {
		margin-left: 91.66666667%;
	}

	.ant-col-sm-order-22 {
		order: 22;
	}

	.ant-col-sm-21 {
		display: block;
		flex: 0 0 87.5%;
		max-width: 87.5%;
	}

	.ant-col-sm-push-21 {
		left: 87.5%;
	}

	.ant-col-sm-pull-21 {
		right: 87.5%;
	}

	.ant-col-sm-offset-21 {
		margin-left: 87.5%;
	}

	.ant-col-sm-order-21 {
		order: 21;
	}

	.ant-col-sm-20 {
		display: block;
		flex: 0 0 83.33333333%;
		max-width: 83.33333333%;
	}

	.ant-col-sm-push-20 {
		left: 83.33333333%;
	}

	.ant-col-sm-pull-20 {
		right: 83.33333333%;
	}

	.ant-col-sm-offset-20 {
		margin-left: 83.33333333%;
	}

	.ant-col-sm-order-20 {
		order: 20;
	}

	.ant-col-sm-19 {
		display: block;
		flex: 0 0 79.16666667%;
		max-width: 79.16666667%;
	}

	.ant-col-sm-push-19 {
		left: 79.16666667%;
	}

	.ant-col-sm-pull-19 {
		right: 79.16666667%;
	}

	.ant-col-sm-offset-19 {
		margin-left: 79.16666667%;
	}

	.ant-col-sm-order-19 {
		order: 19;
	}

	.ant-col-sm-18 {
		display: block;
		flex: 0 0 75%;
		max-width: 75%;
	}

	.ant-col-sm-push-18 {
		left: 75%;
	}

	.ant-col-sm-pull-18 {
		right: 75%;
	}

	.ant-col-sm-offset-18 {
		margin-left: 75%;
	}

	.ant-col-sm-order-18 {
		order: 18;
	}

	.ant-col-sm-17 {
		display: block;
		flex: 0 0 70.83333333%;
		max-width: 70.83333333%;
	}

	.ant-col-sm-push-17 {
		left: 70.83333333%;
	}

	.ant-col-sm-pull-17 {
		right: 70.83333333%;
	}

	.ant-col-sm-offset-17 {
		margin-left: 70.83333333%;
	}

	.ant-col-sm-order-17 {
		order: 17;
	}

	.ant-col-sm-16 {
		display: block;
		flex: 0 0 66.66666667%;
		max-width: 66.66666667%;
	}

	.ant-col-sm-push-16 {
		left: 66.66666667%;
	}

	.ant-col-sm-pull-16 {
		right: 66.66666667%;
	}

	.ant-col-sm-offset-16 {
		margin-left: 66.66666667%;
	}

	.ant-col-sm-order-16 {
		order: 16;
	}

	.ant-col-sm-15 {
		display: block;
		flex: 0 0 62.5%;
		max-width: 62.5%;
	}

	.ant-col-sm-push-15 {
		left: 62.5%;
	}

	.ant-col-sm-pull-15 {
		right: 62.5%;
	}

	.ant-col-sm-offset-15 {
		margin-left: 62.5%;
	}

	.ant-col-sm-order-15 {
		order: 15;
	}

	.ant-col-sm-14 {
		display: block;
		flex: 0 0 58.33333333%;
		max-width: 58.33333333%;
	}

	.ant-col-sm-push-14 {
		left: 58.33333333%;
	}

	.ant-col-sm-pull-14 {
		right: 58.33333333%;
	}

	.ant-col-sm-offset-14 {
		margin-left: 58.33333333%;
	}

	.ant-col-sm-order-14 {
		order: 14;
	}

	.ant-col-sm-13 {
		display: block;
		flex: 0 0 54.16666667%;
		max-width: 54.16666667%;
	}

	.ant-col-sm-push-13 {
		left: 54.16666667%;
	}

	.ant-col-sm-pull-13 {
		right: 54.16666667%;
	}

	.ant-col-sm-offset-13 {
		margin-left: 54.16666667%;
	}

	.ant-col-sm-order-13 {
		order: 13;
	}

	.ant-col-sm-12 {
		display: block;
		flex: 0 0 50%;
		max-width: 50%;
	}

	.ant-col-sm-push-12 {
		left: 50%;
	}

	.ant-col-sm-pull-12 {
		right: 50%;
	}

	.ant-col-sm-offset-12 {
		margin-left: 50%;
	}

	.ant-col-sm-order-12 {
		order: 12;
	}

	.ant-col-sm-11 {
		display: block;
		flex: 0 0 45.83333333%;
		max-width: 45.83333333%;
	}

	.ant-col-sm-push-11 {
		left: 45.83333333%;
	}

	.ant-col-sm-pull-11 {
		right: 45.83333333%;
	}

	.ant-col-sm-offset-11 {
		margin-left: 45.83333333%;
	}

	.ant-col-sm-order-11 {
		order: 11;
	}

	.ant-col-sm-10 {
		display: block;
		flex: 0 0 41.66666667%;
		max-width: 41.66666667%;
	}

	.ant-col-sm-push-10 {
		left: 41.66666667%;
	}

	.ant-col-sm-pull-10 {
		right: 41.66666667%;
	}

	.ant-col-sm-offset-10 {
		margin-left: 41.66666667%;
	}

	.ant-col-sm-order-10 {
		order: 10;
	}

	.ant-col-sm-9 {
		display: block;
		flex: 0 0 37.5%;
		max-width: 37.5%;
	}

	.ant-col-sm-push-9 {
		left: 37.5%;
	}

	.ant-col-sm-pull-9 {
		right: 37.5%;
	}

	.ant-col-sm-offset-9 {
		margin-left: 37.5%;
	}

	.ant-col-sm-order-9 {
		order: 9;
	}

	.ant-col-sm-8 {
		display: block;
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%;
	}

	.ant-col-sm-push-8 {
		left: 33.33333333%;
	}

	.ant-col-sm-pull-8 {
		right: 33.33333333%;
	}

	.ant-col-sm-offset-8 {
		margin-left: 33.33333333%;
	}

	.ant-col-sm-order-8 {
		order: 8;
	}

	.ant-col-sm-7 {
		display: block;
		flex: 0 0 29.16666667%;
		max-width: 29.16666667%;
	}

	.ant-col-sm-push-7 {
		left: 29.16666667%;
	}

	.ant-col-sm-pull-7 {
		right: 29.16666667%;
	}

	.ant-col-sm-offset-7 {
		margin-left: 29.16666667%;
	}

	.ant-col-sm-order-7 {
		order: 7;
	}

	.ant-col-sm-6 {
		display: block;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.ant-col-sm-push-6 {
		left: 25%;
	}

	.ant-col-sm-pull-6 {
		right: 25%;
	}

	.ant-col-sm-offset-6 {
		margin-left: 25%;
	}

	.ant-col-sm-order-6 {
		order: 6;
	}

	.ant-col-sm-5 {
		display: block;
		flex: 0 0 20.83333333%;
		max-width: 20.83333333%;
	}

	.ant-col-sm-push-5 {
		left: 20.83333333%;
	}

	.ant-col-sm-pull-5 {
		right: 20.83333333%;
	}

	.ant-col-sm-offset-5 {
		margin-left: 20.83333333%;
	}

	.ant-col-sm-order-5 {
		order: 5;
	}

	.ant-col-sm-4 {
		display: block;
		flex: 0 0 16.66666667%;
		max-width: 16.66666667%;
	}

	.ant-col-sm-push-4 {
		left: 16.66666667%;
	}

	.ant-col-sm-pull-4 {
		right: 16.66666667%;
	}

	.ant-col-sm-offset-4 {
		margin-left: 16.66666667%;
	}

	.ant-col-sm-order-4 {
		order: 4;
	}

	.ant-col-sm-3 {
		display: block;
		flex: 0 0 12.5%;
		max-width: 12.5%;
	}

	.ant-col-sm-push-3 {
		left: 12.5%;
	}

	.ant-col-sm-pull-3 {
		right: 12.5%;
	}

	.ant-col-sm-offset-3 {
		margin-left: 12.5%;
	}

	.ant-col-sm-order-3 {
		order: 3;
	}

	.ant-col-sm-2 {
		display: block;
		flex: 0 0 8.33333333%;
		max-width: 8.33333333%;
	}

	.ant-col-sm-push-2 {
		left: 8.33333333%;
	}

	.ant-col-sm-pull-2 {
		right: 8.33333333%;
	}

	.ant-col-sm-offset-2 {
		margin-left: 8.33333333%;
	}

	.ant-col-sm-order-2 {
		order: 2;
	}

	.ant-col-sm-1 {
		display: block;
		flex: 0 0 4.16666667%;
		max-width: 4.16666667%;
	}

	.ant-col-sm-push-1 {
		left: 4.16666667%;
	}

	.ant-col-sm-pull-1 {
		right: 4.16666667%;
	}

	.ant-col-sm-offset-1 {
		margin-left: 4.16666667%;
	}

	.ant-col-sm-order-1 {
		order: 1;
	}

	.ant-col-sm-0 {
		display: none;
	}

	.ant-col-push-0 {
		left: auto;
	}

	.ant-col-pull-0 {
		right: auto;
	}

	.ant-col-sm-push-0 {
		left: auto;
	}

	.ant-col-sm-pull-0 {
		right: auto;
	}

	.ant-col-sm-offset-0 {
		margin-left: 0;
	}

	.ant-col-sm-order-0 {
		order: 0;
	}

	.ant-col-push-0.ant-col-rtl {
		right: auto;
	}

	.ant-col-pull-0.ant-col-rtl {
		left: auto;
	}

	.ant-col-sm-push-0.ant-col-rtl {
		right: auto;
	}

	.ant-col-sm-pull-0.ant-col-rtl {
		left: auto;
	}

	.ant-col-sm-offset-0.ant-col-rtl {
		margin-right: 0;
	}

	.ant-col-sm-push-1.ant-col-rtl {
		left: auto;
		right: 4.16666667%;
	}

	.ant-col-sm-pull-1.ant-col-rtl {
		left: 4.16666667%;
		right: auto;
	}

	.ant-col-sm-offset-1.ant-col-rtl {
		margin-left: 0;
		margin-right: 4.16666667%;
	}

	.ant-col-sm-push-2.ant-col-rtl {
		left: auto;
		right: 8.33333333%;
	}

	.ant-col-sm-pull-2.ant-col-rtl {
		left: 8.33333333%;
		right: auto;
	}

	.ant-col-sm-offset-2.ant-col-rtl {
		margin-left: 0;
		margin-right: 8.33333333%;
	}

	.ant-col-sm-push-3.ant-col-rtl {
		left: auto;
		right: 12.5%;
	}

	.ant-col-sm-pull-3.ant-col-rtl {
		left: 12.5%;
		right: auto;
	}

	.ant-col-sm-offset-3.ant-col-rtl {
		margin-left: 0;
		margin-right: 12.5%;
	}

	.ant-col-sm-push-4.ant-col-rtl {
		left: auto;
		right: 16.66666667%;
	}

	.ant-col-sm-pull-4.ant-col-rtl {
		left: 16.66666667%;
		right: auto;
	}

	.ant-col-sm-offset-4.ant-col-rtl {
		margin-left: 0;
		margin-right: 16.66666667%;
	}

	.ant-col-sm-push-5.ant-col-rtl {
		left: auto;
		right: 20.83333333%;
	}

	.ant-col-sm-pull-5.ant-col-rtl {
		left: 20.83333333%;
		right: auto;
	}

	.ant-col-sm-offset-5.ant-col-rtl {
		margin-left: 0;
		margin-right: 20.83333333%;
	}

	.ant-col-sm-push-6.ant-col-rtl {
		left: auto;
		right: 25%;
	}

	.ant-col-sm-pull-6.ant-col-rtl {
		left: 25%;
		right: auto;
	}

	.ant-col-sm-offset-6.ant-col-rtl {
		margin-left: 0;
		margin-right: 25%;
	}

	.ant-col-sm-push-7.ant-col-rtl {
		left: auto;
		right: 29.16666667%;
	}

	.ant-col-sm-pull-7.ant-col-rtl {
		left: 29.16666667%;
		right: auto;
	}

	.ant-col-sm-offset-7.ant-col-rtl {
		margin-left: 0;
		margin-right: 29.16666667%;
	}

	.ant-col-sm-push-8.ant-col-rtl {
		left: auto;
		right: 33.33333333%;
	}

	.ant-col-sm-pull-8.ant-col-rtl {
		left: 33.33333333%;
		right: auto;
	}

	.ant-col-sm-offset-8.ant-col-rtl {
		margin-left: 0;
		margin-right: 33.33333333%;
	}

	.ant-col-sm-push-9.ant-col-rtl {
		left: auto;
		right: 37.5%;
	}

	.ant-col-sm-pull-9.ant-col-rtl {
		left: 37.5%;
		right: auto;
	}

	.ant-col-sm-offset-9.ant-col-rtl {
		margin-left: 0;
		margin-right: 37.5%;
	}

	.ant-col-sm-push-10.ant-col-rtl {
		left: auto;
		right: 41.66666667%;
	}

	.ant-col-sm-pull-10.ant-col-rtl {
		left: 41.66666667%;
		right: auto;
	}

	.ant-col-sm-offset-10.ant-col-rtl {
		margin-left: 0;
		margin-right: 41.66666667%;
	}

	.ant-col-sm-push-11.ant-col-rtl {
		left: auto;
		right: 45.83333333%;
	}

	.ant-col-sm-pull-11.ant-col-rtl {
		left: 45.83333333%;
		right: auto;
	}

	.ant-col-sm-offset-11.ant-col-rtl {
		margin-left: 0;
		margin-right: 45.83333333%;
	}

	.ant-col-sm-push-12.ant-col-rtl {
		left: auto;
		right: 50%;
	}

	.ant-col-sm-pull-12.ant-col-rtl {
		left: 50%;
		right: auto;
	}

	.ant-col-sm-offset-12.ant-col-rtl {
		margin-left: 0;
		margin-right: 50%;
	}

	.ant-col-sm-push-13.ant-col-rtl {
		left: auto;
		right: 54.16666667%;
	}

	.ant-col-sm-pull-13.ant-col-rtl {
		left: 54.16666667%;
		right: auto;
	}

	.ant-col-sm-offset-13.ant-col-rtl {
		margin-left: 0;
		margin-right: 54.16666667%;
	}

	.ant-col-sm-push-14.ant-col-rtl {
		left: auto;
		right: 58.33333333%;
	}

	.ant-col-sm-pull-14.ant-col-rtl {
		left: 58.33333333%;
		right: auto;
	}

	.ant-col-sm-offset-14.ant-col-rtl {
		margin-left: 0;
		margin-right: 58.33333333%;
	}

	.ant-col-sm-push-15.ant-col-rtl {
		left: auto;
		right: 62.5%;
	}

	.ant-col-sm-pull-15.ant-col-rtl {
		left: 62.5%;
		right: auto;
	}

	.ant-col-sm-offset-15.ant-col-rtl {
		margin-left: 0;
		margin-right: 62.5%;
	}

	.ant-col-sm-push-16.ant-col-rtl {
		left: auto;
		right: 66.66666667%;
	}

	.ant-col-sm-pull-16.ant-col-rtl {
		left: 66.66666667%;
		right: auto;
	}

	.ant-col-sm-offset-16.ant-col-rtl {
		margin-left: 0;
		margin-right: 66.66666667%;
	}

	.ant-col-sm-push-17.ant-col-rtl {
		left: auto;
		right: 70.83333333%;
	}

	.ant-col-sm-pull-17.ant-col-rtl {
		left: 70.83333333%;
		right: auto;
	}

	.ant-col-sm-offset-17.ant-col-rtl {
		margin-left: 0;
		margin-right: 70.83333333%;
	}

	.ant-col-sm-push-18.ant-col-rtl {
		left: auto;
		right: 75%;
	}

	.ant-col-sm-pull-18.ant-col-rtl {
		left: 75%;
		right: auto;
	}

	.ant-col-sm-offset-18.ant-col-rtl {
		margin-left: 0;
		margin-right: 75%;
	}

	.ant-col-sm-push-19.ant-col-rtl {
		left: auto;
		right: 79.16666667%;
	}

	.ant-col-sm-pull-19.ant-col-rtl {
		left: 79.16666667%;
		right: auto;
	}

	.ant-col-sm-offset-19.ant-col-rtl {
		margin-left: 0;
		margin-right: 79.16666667%;
	}

	.ant-col-sm-push-20.ant-col-rtl {
		left: auto;
		right: 83.33333333%;
	}

	.ant-col-sm-pull-20.ant-col-rtl {
		left: 83.33333333%;
		right: auto;
	}

	.ant-col-sm-offset-20.ant-col-rtl {
		margin-left: 0;
		margin-right: 83.33333333%;
	}

	.ant-col-sm-push-21.ant-col-rtl {
		left: auto;
		right: 87.5%;
	}

	.ant-col-sm-pull-21.ant-col-rtl {
		left: 87.5%;
		right: auto;
	}

	.ant-col-sm-offset-21.ant-col-rtl {
		margin-left: 0;
		margin-right: 87.5%;
	}

	.ant-col-sm-push-22.ant-col-rtl {
		left: auto;
		right: 91.66666667%;
	}

	.ant-col-sm-pull-22.ant-col-rtl {
		left: 91.66666667%;
		right: auto;
	}

	.ant-col-sm-offset-22.ant-col-rtl {
		margin-left: 0;
		margin-right: 91.66666667%;
	}

	.ant-col-sm-push-23.ant-col-rtl {
		left: auto;
		right: 95.83333333%;
	}

	.ant-col-sm-pull-23.ant-col-rtl {
		left: 95.83333333%;
		right: auto;
	}

	.ant-col-sm-offset-23.ant-col-rtl {
		margin-left: 0;
		margin-right: 95.83333333%;
	}

	.ant-col-sm-push-24.ant-col-rtl {
		left: auto;
		right: 100%;
	}

	.ant-col-sm-pull-24.ant-col-rtl {
		left: 100%;
		right: auto;
	}

	.ant-col-sm-offset-24.ant-col-rtl {
		margin-left: 0;
		margin-right: 100%;
	}
}

@media (min-width: 768px) {
	.ant-col-md-24 {
		display: block;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.ant-col-md-push-24 {
		left: 100%;
	}

	.ant-col-md-pull-24 {
		right: 100%;
	}

	.ant-col-md-offset-24 {
		margin-left: 100%;
	}

	.ant-col-md-order-24 {
		order: 24;
	}

	.ant-col-md-23 {
		display: block;
		flex: 0 0 95.83333333%;
		max-width: 95.83333333%;
	}

	.ant-col-md-push-23 {
		left: 95.83333333%;
	}

	.ant-col-md-pull-23 {
		right: 95.83333333%;
	}

	.ant-col-md-offset-23 {
		margin-left: 95.83333333%;
	}

	.ant-col-md-order-23 {
		order: 23;
	}

	.ant-col-md-22 {
		display: block;
		flex: 0 0 91.66666667%;
		max-width: 91.66666667%;
	}

	.ant-col-md-push-22 {
		left: 91.66666667%;
	}

	.ant-col-md-pull-22 {
		right: 91.66666667%;
	}

	.ant-col-md-offset-22 {
		margin-left: 91.66666667%;
	}

	.ant-col-md-order-22 {
		order: 22;
	}

	.ant-col-md-21 {
		display: block;
		flex: 0 0 87.5%;
		max-width: 87.5%;
	}

	.ant-col-md-push-21 {
		left: 87.5%;
	}

	.ant-col-md-pull-21 {
		right: 87.5%;
	}

	.ant-col-md-offset-21 {
		margin-left: 87.5%;
	}

	.ant-col-md-order-21 {
		order: 21;
	}

	.ant-col-md-20 {
		display: block;
		flex: 0 0 83.33333333%;
		max-width: 83.33333333%;
	}

	.ant-col-md-push-20 {
		left: 83.33333333%;
	}

	.ant-col-md-pull-20 {
		right: 83.33333333%;
	}

	.ant-col-md-offset-20 {
		margin-left: 83.33333333%;
	}

	.ant-col-md-order-20 {
		order: 20;
	}

	.ant-col-md-19 {
		display: block;
		flex: 0 0 79.16666667%;
		max-width: 79.16666667%;
	}

	.ant-col-md-push-19 {
		left: 79.16666667%;
	}

	.ant-col-md-pull-19 {
		right: 79.16666667%;
	}

	.ant-col-md-offset-19 {
		margin-left: 79.16666667%;
	}

	.ant-col-md-order-19 {
		order: 19;
	}

	.ant-col-md-18 {
		display: block;
		flex: 0 0 75%;
		max-width: 75%;
	}

	.ant-col-md-push-18 {
		left: 75%;
	}

	.ant-col-md-pull-18 {
		right: 75%;
	}

	.ant-col-md-offset-18 {
		margin-left: 75%;
	}

	.ant-col-md-order-18 {
		order: 18;
	}

	.ant-col-md-17 {
		display: block;
		flex: 0 0 70.83333333%;
		max-width: 70.83333333%;
	}

	.ant-col-md-push-17 {
		left: 70.83333333%;
	}

	.ant-col-md-pull-17 {
		right: 70.83333333%;
	}

	.ant-col-md-offset-17 {
		margin-left: 70.83333333%;
	}

	.ant-col-md-order-17 {
		order: 17;
	}

	.ant-col-md-16 {
		display: block;
		flex: 0 0 66.66666667%;
		max-width: 66.66666667%;
	}

	.ant-col-md-push-16 {
		left: 66.66666667%;
	}

	.ant-col-md-pull-16 {
		right: 66.66666667%;
	}

	.ant-col-md-offset-16 {
		margin-left: 66.66666667%;
	}

	.ant-col-md-order-16 {
		order: 16;
	}

	.ant-col-md-15 {
		display: block;
		flex: 0 0 62.5%;
		max-width: 62.5%;
	}

	.ant-col-md-push-15 {
		left: 62.5%;
	}

	.ant-col-md-pull-15 {
		right: 62.5%;
	}

	.ant-col-md-offset-15 {
		margin-left: 62.5%;
	}

	.ant-col-md-order-15 {
		order: 15;
	}

	.ant-col-md-14 {
		display: block;
		flex: 0 0 58.33333333%;
		max-width: 58.33333333%;
	}

	.ant-col-md-push-14 {
		left: 58.33333333%;
	}

	.ant-col-md-pull-14 {
		right: 58.33333333%;
	}

	.ant-col-md-offset-14 {
		margin-left: 58.33333333%;
	}

	.ant-col-md-order-14 {
		order: 14;
	}

	.ant-col-md-13 {
		display: block;
		flex: 0 0 54.16666667%;
		max-width: 54.16666667%;
	}

	.ant-col-md-push-13 {
		left: 54.16666667%;
	}

	.ant-col-md-pull-13 {
		right: 54.16666667%;
	}

	.ant-col-md-offset-13 {
		margin-left: 54.16666667%;
	}

	.ant-col-md-order-13 {
		order: 13;
	}

	.ant-col-md-12 {
		display: block;
		flex: 0 0 50%;
		max-width: 50%;
	}

	.ant-col-md-push-12 {
		left: 50%;
	}

	.ant-col-md-pull-12 {
		right: 50%;
	}

	.ant-col-md-offset-12 {
		margin-left: 50%;
	}

	.ant-col-md-order-12 {
		order: 12;
	}

	.ant-col-md-11 {
		display: block;
		flex: 0 0 45.83333333%;
		max-width: 45.83333333%;
	}

	.ant-col-md-push-11 {
		left: 45.83333333%;
	}

	.ant-col-md-pull-11 {
		right: 45.83333333%;
	}

	.ant-col-md-offset-11 {
		margin-left: 45.83333333%;
	}

	.ant-col-md-order-11 {
		order: 11;
	}

	.ant-col-md-10 {
		display: block;
		flex: 0 0 41.66666667%;
		max-width: 41.66666667%;
	}

	.ant-col-md-push-10 {
		left: 41.66666667%;
	}

	.ant-col-md-pull-10 {
		right: 41.66666667%;
	}

	.ant-col-md-offset-10 {
		margin-left: 41.66666667%;
	}

	.ant-col-md-order-10 {
		order: 10;
	}

	.ant-col-md-9 {
		display: block;
		flex: 0 0 37.5%;
		max-width: 37.5%;
	}

	.ant-col-md-push-9 {
		left: 37.5%;
	}

	.ant-col-md-pull-9 {
		right: 37.5%;
	}

	.ant-col-md-offset-9 {
		margin-left: 37.5%;
	}

	.ant-col-md-order-9 {
		order: 9;
	}

	.ant-col-md-8 {
		display: block;
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%;
	}

	.ant-col-md-push-8 {
		left: 33.33333333%;
	}

	.ant-col-md-pull-8 {
		right: 33.33333333%;
	}

	.ant-col-md-offset-8 {
		margin-left: 33.33333333%;
	}

	.ant-col-md-order-8 {
		order: 8;
	}

	.ant-col-md-7 {
		display: block;
		flex: 0 0 29.16666667%;
		max-width: 29.16666667%;
	}

	.ant-col-md-push-7 {
		left: 29.16666667%;
	}

	.ant-col-md-pull-7 {
		right: 29.16666667%;
	}

	.ant-col-md-offset-7 {
		margin-left: 29.16666667%;
	}

	.ant-col-md-order-7 {
		order: 7;
	}

	.ant-col-md-6 {
		display: block;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.ant-col-md-push-6 {
		left: 25%;
	}

	.ant-col-md-pull-6 {
		right: 25%;
	}

	.ant-col-md-offset-6 {
		margin-left: 25%;
	}

	.ant-col-md-order-6 {
		order: 6;
	}

	.ant-col-md-5 {
		display: block;
		flex: 0 0 20.83333333%;
		max-width: 20.83333333%;
	}

	.ant-col-md-push-5 {
		left: 20.83333333%;
	}

	.ant-col-md-pull-5 {
		right: 20.83333333%;
	}

	.ant-col-md-offset-5 {
		margin-left: 20.83333333%;
	}

	.ant-col-md-order-5 {
		order: 5;
	}

	.ant-col-md-4 {
		display: block;
		flex: 0 0 16.66666667%;
		max-width: 16.66666667%;
	}

	.ant-col-md-push-4 {
		left: 16.66666667%;
	}

	.ant-col-md-pull-4 {
		right: 16.66666667%;
	}

	.ant-col-md-offset-4 {
		margin-left: 16.66666667%;
	}

	.ant-col-md-order-4 {
		order: 4;
	}

	.ant-col-md-3 {
		display: block;
		flex: 0 0 12.5%;
		max-width: 12.5%;
	}

	.ant-col-md-push-3 {
		left: 12.5%;
	}

	.ant-col-md-pull-3 {
		right: 12.5%;
	}

	.ant-col-md-offset-3 {
		margin-left: 12.5%;
	}

	.ant-col-md-order-3 {
		order: 3;
	}

	.ant-col-md-2 {
		display: block;
		flex: 0 0 8.33333333%;
		max-width: 8.33333333%;
	}

	.ant-col-md-push-2 {
		left: 8.33333333%;
	}

	.ant-col-md-pull-2 {
		right: 8.33333333%;
	}

	.ant-col-md-offset-2 {
		margin-left: 8.33333333%;
	}

	.ant-col-md-order-2 {
		order: 2;
	}

	.ant-col-md-1 {
		display: block;
		flex: 0 0 4.16666667%;
		max-width: 4.16666667%;
	}

	.ant-col-md-push-1 {
		left: 4.16666667%;
	}

	.ant-col-md-pull-1 {
		right: 4.16666667%;
	}

	.ant-col-md-offset-1 {
		margin-left: 4.16666667%;
	}

	.ant-col-md-order-1 {
		order: 1;
	}

	.ant-col-md-0 {
		display: none;
	}

	.ant-col-push-0 {
		left: auto;
	}

	.ant-col-pull-0 {
		right: auto;
	}

	.ant-col-md-push-0 {
		left: auto;
	}

	.ant-col-md-pull-0 {
		right: auto;
	}

	.ant-col-md-offset-0 {
		margin-left: 0;
	}

	.ant-col-md-order-0 {
		order: 0;
	}

	.ant-col-push-0.ant-col-rtl {
		right: auto;
	}

	.ant-col-pull-0.ant-col-rtl {
		left: auto;
	}

	.ant-col-md-push-0.ant-col-rtl {
		right: auto;
	}

	.ant-col-md-pull-0.ant-col-rtl {
		left: auto;
	}

	.ant-col-md-offset-0.ant-col-rtl {
		margin-right: 0;
	}

	.ant-col-md-push-1.ant-col-rtl {
		left: auto;
		right: 4.16666667%;
	}

	.ant-col-md-pull-1.ant-col-rtl {
		left: 4.16666667%;
		right: auto;
	}

	.ant-col-md-offset-1.ant-col-rtl {
		margin-left: 0;
		margin-right: 4.16666667%;
	}

	.ant-col-md-push-2.ant-col-rtl {
		left: auto;
		right: 8.33333333%;
	}

	.ant-col-md-pull-2.ant-col-rtl {
		left: 8.33333333%;
		right: auto;
	}

	.ant-col-md-offset-2.ant-col-rtl {
		margin-left: 0;
		margin-right: 8.33333333%;
	}

	.ant-col-md-push-3.ant-col-rtl {
		left: auto;
		right: 12.5%;
	}

	.ant-col-md-pull-3.ant-col-rtl {
		left: 12.5%;
		right: auto;
	}

	.ant-col-md-offset-3.ant-col-rtl {
		margin-left: 0;
		margin-right: 12.5%;
	}

	.ant-col-md-push-4.ant-col-rtl {
		left: auto;
		right: 16.66666667%;
	}

	.ant-col-md-pull-4.ant-col-rtl {
		left: 16.66666667%;
		right: auto;
	}

	.ant-col-md-offset-4.ant-col-rtl {
		margin-left: 0;
		margin-right: 16.66666667%;
	}

	.ant-col-md-push-5.ant-col-rtl {
		left: auto;
		right: 20.83333333%;
	}

	.ant-col-md-pull-5.ant-col-rtl {
		left: 20.83333333%;
		right: auto;
	}

	.ant-col-md-offset-5.ant-col-rtl {
		margin-left: 0;
		margin-right: 20.83333333%;
	}

	.ant-col-md-push-6.ant-col-rtl {
		left: auto;
		right: 25%;
	}

	.ant-col-md-pull-6.ant-col-rtl {
		left: 25%;
		right: auto;
	}

	.ant-col-md-offset-6.ant-col-rtl {
		margin-left: 0;
		margin-right: 25%;
	}

	.ant-col-md-push-7.ant-col-rtl {
		left: auto;
		right: 29.16666667%;
	}

	.ant-col-md-pull-7.ant-col-rtl {
		left: 29.16666667%;
		right: auto;
	}

	.ant-col-md-offset-7.ant-col-rtl {
		margin-left: 0;
		margin-right: 29.16666667%;
	}

	.ant-col-md-push-8.ant-col-rtl {
		left: auto;
		right: 33.33333333%;
	}

	.ant-col-md-pull-8.ant-col-rtl {
		left: 33.33333333%;
		right: auto;
	}

	.ant-col-md-offset-8.ant-col-rtl {
		margin-left: 0;
		margin-right: 33.33333333%;
	}

	.ant-col-md-push-9.ant-col-rtl {
		left: auto;
		right: 37.5%;
	}

	.ant-col-md-pull-9.ant-col-rtl {
		left: 37.5%;
		right: auto;
	}

	.ant-col-md-offset-9.ant-col-rtl {
		margin-left: 0;
		margin-right: 37.5%;
	}

	.ant-col-md-push-10.ant-col-rtl {
		left: auto;
		right: 41.66666667%;
	}

	.ant-col-md-pull-10.ant-col-rtl {
		left: 41.66666667%;
		right: auto;
	}

	.ant-col-md-offset-10.ant-col-rtl {
		margin-left: 0;
		margin-right: 41.66666667%;
	}

	.ant-col-md-push-11.ant-col-rtl {
		left: auto;
		right: 45.83333333%;
	}

	.ant-col-md-pull-11.ant-col-rtl {
		left: 45.83333333%;
		right: auto;
	}

	.ant-col-md-offset-11.ant-col-rtl {
		margin-left: 0;
		margin-right: 45.83333333%;
	}

	.ant-col-md-push-12.ant-col-rtl {
		left: auto;
		right: 50%;
	}

	.ant-col-md-pull-12.ant-col-rtl {
		left: 50%;
		right: auto;
	}

	.ant-col-md-offset-12.ant-col-rtl {
		margin-left: 0;
		margin-right: 50%;
	}

	.ant-col-md-push-13.ant-col-rtl {
		left: auto;
		right: 54.16666667%;
	}

	.ant-col-md-pull-13.ant-col-rtl {
		left: 54.16666667%;
		right: auto;
	}

	.ant-col-md-offset-13.ant-col-rtl {
		margin-left: 0;
		margin-right: 54.16666667%;
	}

	.ant-col-md-push-14.ant-col-rtl {
		left: auto;
		right: 58.33333333%;
	}

	.ant-col-md-pull-14.ant-col-rtl {
		left: 58.33333333%;
		right: auto;
	}

	.ant-col-md-offset-14.ant-col-rtl {
		margin-left: 0;
		margin-right: 58.33333333%;
	}

	.ant-col-md-push-15.ant-col-rtl {
		left: auto;
		right: 62.5%;
	}

	.ant-col-md-pull-15.ant-col-rtl {
		left: 62.5%;
		right: auto;
	}

	.ant-col-md-offset-15.ant-col-rtl {
		margin-left: 0;
		margin-right: 62.5%;
	}

	.ant-col-md-push-16.ant-col-rtl {
		left: auto;
		right: 66.66666667%;
	}

	.ant-col-md-pull-16.ant-col-rtl {
		left: 66.66666667%;
		right: auto;
	}

	.ant-col-md-offset-16.ant-col-rtl {
		margin-left: 0;
		margin-right: 66.66666667%;
	}

	.ant-col-md-push-17.ant-col-rtl {
		left: auto;
		right: 70.83333333%;
	}

	.ant-col-md-pull-17.ant-col-rtl {
		left: 70.83333333%;
		right: auto;
	}

	.ant-col-md-offset-17.ant-col-rtl {
		margin-left: 0;
		margin-right: 70.83333333%;
	}

	.ant-col-md-push-18.ant-col-rtl {
		left: auto;
		right: 75%;
	}

	.ant-col-md-pull-18.ant-col-rtl {
		left: 75%;
		right: auto;
	}

	.ant-col-md-offset-18.ant-col-rtl {
		margin-left: 0;
		margin-right: 75%;
	}

	.ant-col-md-push-19.ant-col-rtl {
		left: auto;
		right: 79.16666667%;
	}

	.ant-col-md-pull-19.ant-col-rtl {
		left: 79.16666667%;
		right: auto;
	}

	.ant-col-md-offset-19.ant-col-rtl {
		margin-left: 0;
		margin-right: 79.16666667%;
	}

	.ant-col-md-push-20.ant-col-rtl {
		left: auto;
		right: 83.33333333%;
	}

	.ant-col-md-pull-20.ant-col-rtl {
		left: 83.33333333%;
		right: auto;
	}

	.ant-col-md-offset-20.ant-col-rtl {
		margin-left: 0;
		margin-right: 83.33333333%;
	}

	.ant-col-md-push-21.ant-col-rtl {
		left: auto;
		right: 87.5%;
	}

	.ant-col-md-pull-21.ant-col-rtl {
		left: 87.5%;
		right: auto;
	}

	.ant-col-md-offset-21.ant-col-rtl {
		margin-left: 0;
		margin-right: 87.5%;
	}

	.ant-col-md-push-22.ant-col-rtl {
		left: auto;
		right: 91.66666667%;
	}

	.ant-col-md-pull-22.ant-col-rtl {
		left: 91.66666667%;
		right: auto;
	}

	.ant-col-md-offset-22.ant-col-rtl {
		margin-left: 0;
		margin-right: 91.66666667%;
	}

	.ant-col-md-push-23.ant-col-rtl {
		left: auto;
		right: 95.83333333%;
	}

	.ant-col-md-pull-23.ant-col-rtl {
		left: 95.83333333%;
		right: auto;
	}

	.ant-col-md-offset-23.ant-col-rtl {
		margin-left: 0;
		margin-right: 95.83333333%;
	}

	.ant-col-md-push-24.ant-col-rtl {
		left: auto;
		right: 100%;
	}

	.ant-col-md-pull-24.ant-col-rtl {
		left: 100%;
		right: auto;
	}

	.ant-col-md-offset-24.ant-col-rtl {
		margin-left: 0;
		margin-right: 100%;
	}
}

@media (min-width: 992px) {
	.ant-col-lg-24 {
		display: block;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.ant-col-lg-push-24 {
		left: 100%;
	}

	.ant-col-lg-pull-24 {
		right: 100%;
	}

	.ant-col-lg-offset-24 {
		margin-left: 100%;
	}

	.ant-col-lg-order-24 {
		order: 24;
	}

	.ant-col-lg-23 {
		display: block;
		flex: 0 0 95.83333333%;
		max-width: 95.83333333%;
	}

	.ant-col-lg-push-23 {
		left: 95.83333333%;
	}

	.ant-col-lg-pull-23 {
		right: 95.83333333%;
	}

	.ant-col-lg-offset-23 {
		margin-left: 95.83333333%;
	}

	.ant-col-lg-order-23 {
		order: 23;
	}

	.ant-col-lg-22 {
		display: block;
		flex: 0 0 91.66666667%;
		max-width: 91.66666667%;
	}

	.ant-col-lg-push-22 {
		left: 91.66666667%;
	}

	.ant-col-lg-pull-22 {
		right: 91.66666667%;
	}

	.ant-col-lg-offset-22 {
		margin-left: 91.66666667%;
	}

	.ant-col-lg-order-22 {
		order: 22;
	}

	.ant-col-lg-21 {
		display: block;
		flex: 0 0 87.5%;
		max-width: 87.5%;
	}

	.ant-col-lg-push-21 {
		left: 87.5%;
	}

	.ant-col-lg-pull-21 {
		right: 87.5%;
	}

	.ant-col-lg-offset-21 {
		margin-left: 87.5%;
	}

	.ant-col-lg-order-21 {
		order: 21;
	}

	.ant-col-lg-20 {
		display: block;
		flex: 0 0 83.33333333%;
		max-width: 83.33333333%;
	}

	.ant-col-lg-push-20 {
		left: 83.33333333%;
	}

	.ant-col-lg-pull-20 {
		right: 83.33333333%;
	}

	.ant-col-lg-offset-20 {
		margin-left: 83.33333333%;
	}

	.ant-col-lg-order-20 {
		order: 20;
	}

	.ant-col-lg-19 {
		display: block;
		flex: 0 0 79.16666667%;
		max-width: 79.16666667%;
	}

	.ant-col-lg-push-19 {
		left: 79.16666667%;
	}

	.ant-col-lg-pull-19 {
		right: 79.16666667%;
	}

	.ant-col-lg-offset-19 {
		margin-left: 79.16666667%;
	}

	.ant-col-lg-order-19 {
		order: 19;
	}

	.ant-col-lg-18 {
		display: block;
		flex: 0 0 75%;
		max-width: 75%;
	}

	.ant-col-lg-push-18 {
		left: 75%;
	}

	.ant-col-lg-pull-18 {
		right: 75%;
	}

	.ant-col-lg-offset-18 {
		margin-left: 75%;
	}

	.ant-col-lg-order-18 {
		order: 18;
	}

	.ant-col-lg-17 {
		display: block;
		flex: 0 0 70.83333333%;
		max-width: 70.83333333%;
	}

	.ant-col-lg-push-17 {
		left: 70.83333333%;
	}

	.ant-col-lg-pull-17 {
		right: 70.83333333%;
	}

	.ant-col-lg-offset-17 {
		margin-left: 70.83333333%;
	}

	.ant-col-lg-order-17 {
		order: 17;
	}

	.ant-col-lg-16 {
		display: block;
		flex: 0 0 66.66666667%;
		max-width: 66.66666667%;
	}

	.ant-col-lg-push-16 {
		left: 66.66666667%;
	}

	.ant-col-lg-pull-16 {
		right: 66.66666667%;
	}

	.ant-col-lg-offset-16 {
		margin-left: 66.66666667%;
	}

	.ant-col-lg-order-16 {
		order: 16;
	}

	.ant-col-lg-15 {
		display: block;
		flex: 0 0 62.5%;
		max-width: 62.5%;
	}

	.ant-col-lg-push-15 {
		left: 62.5%;
	}

	.ant-col-lg-pull-15 {
		right: 62.5%;
	}

	.ant-col-lg-offset-15 {
		margin-left: 62.5%;
	}

	.ant-col-lg-order-15 {
		order: 15;
	}

	.ant-col-lg-14 {
		display: block;
		flex: 0 0 58.33333333%;
		max-width: 58.33333333%;
	}

	.ant-col-lg-push-14 {
		left: 58.33333333%;
	}

	.ant-col-lg-pull-14 {
		right: 58.33333333%;
	}

	.ant-col-lg-offset-14 {
		margin-left: 58.33333333%;
	}

	.ant-col-lg-order-14 {
		order: 14;
	}

	.ant-col-lg-13 {
		display: block;
		flex: 0 0 54.16666667%;
		max-width: 54.16666667%;
	}

	.ant-col-lg-push-13 {
		left: 54.16666667%;
	}

	.ant-col-lg-pull-13 {
		right: 54.16666667%;
	}

	.ant-col-lg-offset-13 {
		margin-left: 54.16666667%;
	}

	.ant-col-lg-order-13 {
		order: 13;
	}

	.ant-col-lg-12 {
		display: block;
		flex: 0 0 50%;
		max-width: 50%;
	}

	.ant-col-lg-push-12 {
		left: 50%;
	}

	.ant-col-lg-pull-12 {
		right: 50%;
	}

	.ant-col-lg-offset-12 {
		margin-left: 50%;
	}

	.ant-col-lg-order-12 {
		order: 12;
	}

	.ant-col-lg-11 {
		display: block;
		flex: 0 0 45.83333333%;
		max-width: 45.83333333%;
	}

	.ant-col-lg-push-11 {
		left: 45.83333333%;
	}

	.ant-col-lg-pull-11 {
		right: 45.83333333%;
	}

	.ant-col-lg-offset-11 {
		margin-left: 45.83333333%;
	}

	.ant-col-lg-order-11 {
		order: 11;
	}

	.ant-col-lg-10 {
		display: block;
		flex: 0 0 41.66666667%;
		max-width: 41.66666667%;
	}

	.ant-col-lg-push-10 {
		left: 41.66666667%;
	}

	.ant-col-lg-pull-10 {
		right: 41.66666667%;
	}

	.ant-col-lg-offset-10 {
		margin-left: 41.66666667%;
	}

	.ant-col-lg-order-10 {
		order: 10;
	}

	.ant-col-lg-9 {
		display: block;
		flex: 0 0 37.5%;
		max-width: 37.5%;
	}

	.ant-col-lg-push-9 {
		left: 37.5%;
	}

	.ant-col-lg-pull-9 {
		right: 37.5%;
	}

	.ant-col-lg-offset-9 {
		margin-left: 37.5%;
	}

	.ant-col-lg-order-9 {
		order: 9;
	}

	.ant-col-lg-8 {
		display: block;
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%;
	}

	.ant-col-lg-push-8 {
		left: 33.33333333%;
	}

	.ant-col-lg-pull-8 {
		right: 33.33333333%;
	}

	.ant-col-lg-offset-8 {
		margin-left: 33.33333333%;
	}

	.ant-col-lg-order-8 {
		order: 8;
	}

	.ant-col-lg-7 {
		display: block;
		flex: 0 0 29.16666667%;
		max-width: 29.16666667%;
	}

	.ant-col-lg-push-7 {
		left: 29.16666667%;
	}

	.ant-col-lg-pull-7 {
		right: 29.16666667%;
	}

	.ant-col-lg-offset-7 {
		margin-left: 29.16666667%;
	}

	.ant-col-lg-order-7 {
		order: 7;
	}

	.ant-col-lg-6 {
		display: block;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.ant-col-lg-push-6 {
		left: 25%;
	}

	.ant-col-lg-pull-6 {
		right: 25%;
	}

	.ant-col-lg-offset-6 {
		margin-left: 25%;
	}

	.ant-col-lg-order-6 {
		order: 6;
	}

	.ant-col-lg-5 {
		display: block;
		flex: 0 0 20.83333333%;
		max-width: 20.83333333%;
	}

	.ant-col-lg-push-5 {
		left: 20.83333333%;
	}

	.ant-col-lg-pull-5 {
		right: 20.83333333%;
	}

	.ant-col-lg-offset-5 {
		margin-left: 20.83333333%;
	}

	.ant-col-lg-order-5 {
		order: 5;
	}

	.ant-col-lg-4 {
		display: block;
		flex: 0 0 16.66666667%;
		max-width: 16.66666667%;
	}

	.ant-col-lg-push-4 {
		left: 16.66666667%;
	}

	.ant-col-lg-pull-4 {
		right: 16.66666667%;
	}

	.ant-col-lg-offset-4 {
		margin-left: 16.66666667%;
	}

	.ant-col-lg-order-4 {
		order: 4;
	}

	.ant-col-lg-3 {
		display: block;
		flex: 0 0 12.5%;
		max-width: 12.5%;
	}

	.ant-col-lg-push-3 {
		left: 12.5%;
	}

	.ant-col-lg-pull-3 {
		right: 12.5%;
	}

	.ant-col-lg-offset-3 {
		margin-left: 12.5%;
	}

	.ant-col-lg-order-3 {
		order: 3;
	}

	.ant-col-lg-2 {
		display: block;
		flex: 0 0 8.33333333%;
		max-width: 8.33333333%;
	}

	.ant-col-lg-push-2 {
		left: 8.33333333%;
	}

	.ant-col-lg-pull-2 {
		right: 8.33333333%;
	}

	.ant-col-lg-offset-2 {
		margin-left: 8.33333333%;
	}

	.ant-col-lg-order-2 {
		order: 2;
	}

	.ant-col-lg-1 {
		display: block;
		flex: 0 0 4.16666667%;
		max-width: 4.16666667%;
	}

	.ant-col-lg-push-1 {
		left: 4.16666667%;
	}

	.ant-col-lg-pull-1 {
		right: 4.16666667%;
	}

	.ant-col-lg-offset-1 {
		margin-left: 4.16666667%;
	}

	.ant-col-lg-order-1 {
		order: 1;
	}

	.ant-col-lg-0 {
		display: none;
	}

	.ant-col-push-0 {
		left: auto;
	}

	.ant-col-pull-0 {
		right: auto;
	}

	.ant-col-lg-push-0 {
		left: auto;
	}

	.ant-col-lg-pull-0 {
		right: auto;
	}

	.ant-col-lg-offset-0 {
		margin-left: 0;
	}

	.ant-col-lg-order-0 {
		order: 0;
	}

	.ant-col-push-0.ant-col-rtl {
		right: auto;
	}

	.ant-col-pull-0.ant-col-rtl {
		left: auto;
	}

	.ant-col-lg-push-0.ant-col-rtl {
		right: auto;
	}

	.ant-col-lg-pull-0.ant-col-rtl {
		left: auto;
	}

	.ant-col-lg-offset-0.ant-col-rtl {
		margin-right: 0;
	}

	.ant-col-lg-push-1.ant-col-rtl {
		left: auto;
		right: 4.16666667%;
	}

	.ant-col-lg-pull-1.ant-col-rtl {
		left: 4.16666667%;
		right: auto;
	}

	.ant-col-lg-offset-1.ant-col-rtl {
		margin-left: 0;
		margin-right: 4.16666667%;
	}

	.ant-col-lg-push-2.ant-col-rtl {
		left: auto;
		right: 8.33333333%;
	}

	.ant-col-lg-pull-2.ant-col-rtl {
		left: 8.33333333%;
		right: auto;
	}

	.ant-col-lg-offset-2.ant-col-rtl {
		margin-left: 0;
		margin-right: 8.33333333%;
	}

	.ant-col-lg-push-3.ant-col-rtl {
		left: auto;
		right: 12.5%;
	}

	.ant-col-lg-pull-3.ant-col-rtl {
		left: 12.5%;
		right: auto;
	}

	.ant-col-lg-offset-3.ant-col-rtl {
		margin-left: 0;
		margin-right: 12.5%;
	}

	.ant-col-lg-push-4.ant-col-rtl {
		left: auto;
		right: 16.66666667%;
	}

	.ant-col-lg-pull-4.ant-col-rtl {
		left: 16.66666667%;
		right: auto;
	}

	.ant-col-lg-offset-4.ant-col-rtl {
		margin-left: 0;
		margin-right: 16.66666667%;
	}

	.ant-col-lg-push-5.ant-col-rtl {
		left: auto;
		right: 20.83333333%;
	}

	.ant-col-lg-pull-5.ant-col-rtl {
		left: 20.83333333%;
		right: auto;
	}

	.ant-col-lg-offset-5.ant-col-rtl {
		margin-left: 0;
		margin-right: 20.83333333%;
	}

	.ant-col-lg-push-6.ant-col-rtl {
		left: auto;
		right: 25%;
	}

	.ant-col-lg-pull-6.ant-col-rtl {
		left: 25%;
		right: auto;
	}

	.ant-col-lg-offset-6.ant-col-rtl {
		margin-left: 0;
		margin-right: 25%;
	}

	.ant-col-lg-push-7.ant-col-rtl {
		left: auto;
		right: 29.16666667%;
	}

	.ant-col-lg-pull-7.ant-col-rtl {
		left: 29.16666667%;
		right: auto;
	}

	.ant-col-lg-offset-7.ant-col-rtl {
		margin-left: 0;
		margin-right: 29.16666667%;
	}

	.ant-col-lg-push-8.ant-col-rtl {
		left: auto;
		right: 33.33333333%;
	}

	.ant-col-lg-pull-8.ant-col-rtl {
		left: 33.33333333%;
		right: auto;
	}

	.ant-col-lg-offset-8.ant-col-rtl {
		margin-left: 0;
		margin-right: 33.33333333%;
	}

	.ant-col-lg-push-9.ant-col-rtl {
		left: auto;
		right: 37.5%;
	}

	.ant-col-lg-pull-9.ant-col-rtl {
		left: 37.5%;
		right: auto;
	}

	.ant-col-lg-offset-9.ant-col-rtl {
		margin-left: 0;
		margin-right: 37.5%;
	}

	.ant-col-lg-push-10.ant-col-rtl {
		left: auto;
		right: 41.66666667%;
	}

	.ant-col-lg-pull-10.ant-col-rtl {
		left: 41.66666667%;
		right: auto;
	}

	.ant-col-lg-offset-10.ant-col-rtl {
		margin-left: 0;
		margin-right: 41.66666667%;
	}

	.ant-col-lg-push-11.ant-col-rtl {
		left: auto;
		right: 45.83333333%;
	}

	.ant-col-lg-pull-11.ant-col-rtl {
		left: 45.83333333%;
		right: auto;
	}

	.ant-col-lg-offset-11.ant-col-rtl {
		margin-left: 0;
		margin-right: 45.83333333%;
	}

	.ant-col-lg-push-12.ant-col-rtl {
		left: auto;
		right: 50%;
	}

	.ant-col-lg-pull-12.ant-col-rtl {
		left: 50%;
		right: auto;
	}

	.ant-col-lg-offset-12.ant-col-rtl {
		margin-left: 0;
		margin-right: 50%;
	}

	.ant-col-lg-push-13.ant-col-rtl {
		left: auto;
		right: 54.16666667%;
	}

	.ant-col-lg-pull-13.ant-col-rtl {
		left: 54.16666667%;
		right: auto;
	}

	.ant-col-lg-offset-13.ant-col-rtl {
		margin-left: 0;
		margin-right: 54.16666667%;
	}

	.ant-col-lg-push-14.ant-col-rtl {
		left: auto;
		right: 58.33333333%;
	}

	.ant-col-lg-pull-14.ant-col-rtl {
		left: 58.33333333%;
		right: auto;
	}

	.ant-col-lg-offset-14.ant-col-rtl {
		margin-left: 0;
		margin-right: 58.33333333%;
	}

	.ant-col-lg-push-15.ant-col-rtl {
		left: auto;
		right: 62.5%;
	}

	.ant-col-lg-pull-15.ant-col-rtl {
		left: 62.5%;
		right: auto;
	}

	.ant-col-lg-offset-15.ant-col-rtl {
		margin-left: 0;
		margin-right: 62.5%;
	}

	.ant-col-lg-push-16.ant-col-rtl {
		left: auto;
		right: 66.66666667%;
	}

	.ant-col-lg-pull-16.ant-col-rtl {
		left: 66.66666667%;
		right: auto;
	}

	.ant-col-lg-offset-16.ant-col-rtl {
		margin-left: 0;
		margin-right: 66.66666667%;
	}

	.ant-col-lg-push-17.ant-col-rtl {
		left: auto;
		right: 70.83333333%;
	}

	.ant-col-lg-pull-17.ant-col-rtl {
		left: 70.83333333%;
		right: auto;
	}

	.ant-col-lg-offset-17.ant-col-rtl {
		margin-left: 0;
		margin-right: 70.83333333%;
	}

	.ant-col-lg-push-18.ant-col-rtl {
		left: auto;
		right: 75%;
	}

	.ant-col-lg-pull-18.ant-col-rtl {
		left: 75%;
		right: auto;
	}

	.ant-col-lg-offset-18.ant-col-rtl {
		margin-left: 0;
		margin-right: 75%;
	}

	.ant-col-lg-push-19.ant-col-rtl {
		left: auto;
		right: 79.16666667%;
	}

	.ant-col-lg-pull-19.ant-col-rtl {
		left: 79.16666667%;
		right: auto;
	}

	.ant-col-lg-offset-19.ant-col-rtl {
		margin-left: 0;
		margin-right: 79.16666667%;
	}

	.ant-col-lg-push-20.ant-col-rtl {
		left: auto;
		right: 83.33333333%;
	}

	.ant-col-lg-pull-20.ant-col-rtl {
		left: 83.33333333%;
		right: auto;
	}

	.ant-col-lg-offset-20.ant-col-rtl {
		margin-left: 0;
		margin-right: 83.33333333%;
	}

	.ant-col-lg-push-21.ant-col-rtl {
		left: auto;
		right: 87.5%;
	}

	.ant-col-lg-pull-21.ant-col-rtl {
		left: 87.5%;
		right: auto;
	}

	.ant-col-lg-offset-21.ant-col-rtl {
		margin-left: 0;
		margin-right: 87.5%;
	}

	.ant-col-lg-push-22.ant-col-rtl {
		left: auto;
		right: 91.66666667%;
	}

	.ant-col-lg-pull-22.ant-col-rtl {
		left: 91.66666667%;
		right: auto;
	}

	.ant-col-lg-offset-22.ant-col-rtl {
		margin-left: 0;
		margin-right: 91.66666667%;
	}

	.ant-col-lg-push-23.ant-col-rtl {
		left: auto;
		right: 95.83333333%;
	}

	.ant-col-lg-pull-23.ant-col-rtl {
		left: 95.83333333%;
		right: auto;
	}

	.ant-col-lg-offset-23.ant-col-rtl {
		margin-left: 0;
		margin-right: 95.83333333%;
	}

	.ant-col-lg-push-24.ant-col-rtl {
		left: auto;
		right: 100%;
	}

	.ant-col-lg-pull-24.ant-col-rtl {
		left: 100%;
		right: auto;
	}

	.ant-col-lg-offset-24.ant-col-rtl {
		margin-left: 0;
		margin-right: 100%;
	}
}

@media (min-width: 1200px) {
	.ant-col-xl-24 {
		display: block;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.ant-col-xl-push-24 {
		left: 100%;
	}

	.ant-col-xl-pull-24 {
		right: 100%;
	}

	.ant-col-xl-offset-24 {
		margin-left: 100%;
	}

	.ant-col-xl-order-24 {
		order: 24;
	}

	.ant-col-xl-23 {
		display: block;
		flex: 0 0 95.83333333%;
		max-width: 95.83333333%;
	}

	.ant-col-xl-push-23 {
		left: 95.83333333%;
	}

	.ant-col-xl-pull-23 {
		right: 95.83333333%;
	}

	.ant-col-xl-offset-23 {
		margin-left: 95.83333333%;
	}

	.ant-col-xl-order-23 {
		order: 23;
	}

	.ant-col-xl-22 {
		display: block;
		flex: 0 0 91.66666667%;
		max-width: 91.66666667%;
	}

	.ant-col-xl-push-22 {
		left: 91.66666667%;
	}

	.ant-col-xl-pull-22 {
		right: 91.66666667%;
	}

	.ant-col-xl-offset-22 {
		margin-left: 91.66666667%;
	}

	.ant-col-xl-order-22 {
		order: 22;
	}

	.ant-col-xl-21 {
		display: block;
		flex: 0 0 87.5%;
		max-width: 87.5%;
	}

	.ant-col-xl-push-21 {
		left: 87.5%;
	}

	.ant-col-xl-pull-21 {
		right: 87.5%;
	}

	.ant-col-xl-offset-21 {
		margin-left: 87.5%;
	}

	.ant-col-xl-order-21 {
		order: 21;
	}

	.ant-col-xl-20 {
		display: block;
		flex: 0 0 83.33333333%;
		max-width: 83.33333333%;
	}

	.ant-col-xl-push-20 {
		left: 83.33333333%;
	}

	.ant-col-xl-pull-20 {
		right: 83.33333333%;
	}

	.ant-col-xl-offset-20 {
		margin-left: 83.33333333%;
	}

	.ant-col-xl-order-20 {
		order: 20;
	}

	.ant-col-xl-19 {
		display: block;
		flex: 0 0 79.16666667%;
		max-width: 79.16666667%;
	}

	.ant-col-xl-push-19 {
		left: 79.16666667%;
	}

	.ant-col-xl-pull-19 {
		right: 79.16666667%;
	}

	.ant-col-xl-offset-19 {
		margin-left: 79.16666667%;
	}

	.ant-col-xl-order-19 {
		order: 19;
	}

	.ant-col-xl-18 {
		display: block;
		flex: 0 0 75%;
		max-width: 75%;
	}

	.ant-col-xl-push-18 {
		left: 75%;
	}

	.ant-col-xl-pull-18 {
		right: 75%;
	}

	.ant-col-xl-offset-18 {
		margin-left: 75%;
	}

	.ant-col-xl-order-18 {
		order: 18;
	}

	.ant-col-xl-17 {
		display: block;
		flex: 0 0 70.83333333%;
		max-width: 70.83333333%;
	}

	.ant-col-xl-push-17 {
		left: 70.83333333%;
	}

	.ant-col-xl-pull-17 {
		right: 70.83333333%;
	}

	.ant-col-xl-offset-17 {
		margin-left: 70.83333333%;
	}

	.ant-col-xl-order-17 {
		order: 17;
	}

	.ant-col-xl-16 {
		display: block;
		flex: 0 0 66.66666667%;
		max-width: 66.66666667%;
	}

	.ant-col-xl-push-16 {
		left: 66.66666667%;
	}

	.ant-col-xl-pull-16 {
		right: 66.66666667%;
	}

	.ant-col-xl-offset-16 {
		margin-left: 66.66666667%;
	}

	.ant-col-xl-order-16 {
		order: 16;
	}

	.ant-col-xl-15 {
		display: block;
		flex: 0 0 62.5%;
		max-width: 62.5%;
	}

	.ant-col-xl-push-15 {
		left: 62.5%;
	}

	.ant-col-xl-pull-15 {
		right: 62.5%;
	}

	.ant-col-xl-offset-15 {
		margin-left: 62.5%;
	}

	.ant-col-xl-order-15 {
		order: 15;
	}

	.ant-col-xl-14 {
		display: block;
		flex: 0 0 58.33333333%;
		max-width: 58.33333333%;
	}

	.ant-col-xl-push-14 {
		left: 58.33333333%;
	}

	.ant-col-xl-pull-14 {
		right: 58.33333333%;
	}

	.ant-col-xl-offset-14 {
		margin-left: 58.33333333%;
	}

	.ant-col-xl-order-14 {
		order: 14;
	}

	.ant-col-xl-13 {
		display: block;
		flex: 0 0 54.16666667%;
		max-width: 54.16666667%;
	}

	.ant-col-xl-push-13 {
		left: 54.16666667%;
	}

	.ant-col-xl-pull-13 {
		right: 54.16666667%;
	}

	.ant-col-xl-offset-13 {
		margin-left: 54.16666667%;
	}

	.ant-col-xl-order-13 {
		order: 13;
	}

	.ant-col-xl-12 {
		display: block;
		flex: 0 0 50%;
		max-width: 50%;
	}

	.ant-col-xl-push-12 {
		left: 50%;
	}

	.ant-col-xl-pull-12 {
		right: 50%;
	}

	.ant-col-xl-offset-12 {
		margin-left: 50%;
	}

	.ant-col-xl-order-12 {
		order: 12;
	}

	.ant-col-xl-11 {
		display: block;
		flex: 0 0 45.83333333%;
		max-width: 45.83333333%;
	}

	.ant-col-xl-push-11 {
		left: 45.83333333%;
	}

	.ant-col-xl-pull-11 {
		right: 45.83333333%;
	}

	.ant-col-xl-offset-11 {
		margin-left: 45.83333333%;
	}

	.ant-col-xl-order-11 {
		order: 11;
	}

	.ant-col-xl-10 {
		display: block;
		flex: 0 0 41.66666667%;
		max-width: 41.66666667%;
	}

	.ant-col-xl-push-10 {
		left: 41.66666667%;
	}

	.ant-col-xl-pull-10 {
		right: 41.66666667%;
	}

	.ant-col-xl-offset-10 {
		margin-left: 41.66666667%;
	}

	.ant-col-xl-order-10 {
		order: 10;
	}

	.ant-col-xl-9 {
		display: block;
		flex: 0 0 37.5%;
		max-width: 37.5%;
	}

	.ant-col-xl-push-9 {
		left: 37.5%;
	}

	.ant-col-xl-pull-9 {
		right: 37.5%;
	}

	.ant-col-xl-offset-9 {
		margin-left: 37.5%;
	}

	.ant-col-xl-order-9 {
		order: 9;
	}

	.ant-col-xl-8 {
		display: block;
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%;
	}

	.ant-col-xl-push-8 {
		left: 33.33333333%;
	}

	.ant-col-xl-pull-8 {
		right: 33.33333333%;
	}

	.ant-col-xl-offset-8 {
		margin-left: 33.33333333%;
	}

	.ant-col-xl-order-8 {
		order: 8;
	}

	.ant-col-xl-7 {
		display: block;
		flex: 0 0 29.16666667%;
		max-width: 29.16666667%;
	}

	.ant-col-xl-push-7 {
		left: 29.16666667%;
	}

	.ant-col-xl-pull-7 {
		right: 29.16666667%;
	}

	.ant-col-xl-offset-7 {
		margin-left: 29.16666667%;
	}

	.ant-col-xl-order-7 {
		order: 7;
	}

	.ant-col-xl-6 {
		display: block;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.ant-col-xl-push-6 {
		left: 25%;
	}

	.ant-col-xl-pull-6 {
		right: 25%;
	}

	.ant-col-xl-offset-6 {
		margin-left: 25%;
	}

	.ant-col-xl-order-6 {
		order: 6;
	}

	.ant-col-xl-5 {
		display: block;
		flex: 0 0 20.83333333%;
		max-width: 20.83333333%;
	}

	.ant-col-xl-push-5 {
		left: 20.83333333%;
	}

	.ant-col-xl-pull-5 {
		right: 20.83333333%;
	}

	.ant-col-xl-offset-5 {
		margin-left: 20.83333333%;
	}

	.ant-col-xl-order-5 {
		order: 5;
	}

	.ant-col-xl-4 {
		display: block;
		flex: 0 0 16.66666667%;
		max-width: 16.66666667%;
	}

	.ant-col-xl-push-4 {
		left: 16.66666667%;
	}

	.ant-col-xl-pull-4 {
		right: 16.66666667%;
	}

	.ant-col-xl-offset-4 {
		margin-left: 16.66666667%;
	}

	.ant-col-xl-order-4 {
		order: 4;
	}

	.ant-col-xl-3 {
		display: block;
		flex: 0 0 12.5%;
		max-width: 12.5%;
	}

	.ant-col-xl-push-3 {
		left: 12.5%;
	}

	.ant-col-xl-pull-3 {
		right: 12.5%;
	}

	.ant-col-xl-offset-3 {
		margin-left: 12.5%;
	}

	.ant-col-xl-order-3 {
		order: 3;
	}

	.ant-col-xl-2 {
		display: block;
		flex: 0 0 8.33333333%;
		max-width: 8.33333333%;
	}

	.ant-col-xl-push-2 {
		left: 8.33333333%;
	}

	.ant-col-xl-pull-2 {
		right: 8.33333333%;
	}

	.ant-col-xl-offset-2 {
		margin-left: 8.33333333%;
	}

	.ant-col-xl-order-2 {
		order: 2;
	}

	.ant-col-xl-1 {
		display: block;
		flex: 0 0 4.16666667%;
		max-width: 4.16666667%;
	}

	.ant-col-xl-push-1 {
		left: 4.16666667%;
	}

	.ant-col-xl-pull-1 {
		right: 4.16666667%;
	}

	.ant-col-xl-offset-1 {
		margin-left: 4.16666667%;
	}

	.ant-col-xl-order-1 {
		order: 1;
	}

	.ant-col-xl-0 {
		display: none;
	}

	.ant-col-push-0 {
		left: auto;
	}

	.ant-col-pull-0 {
		right: auto;
	}

	.ant-col-xl-push-0 {
		left: auto;
	}

	.ant-col-xl-pull-0 {
		right: auto;
	}

	.ant-col-xl-offset-0 {
		margin-left: 0;
	}

	.ant-col-xl-order-0 {
		order: 0;
	}

	.ant-col-push-0.ant-col-rtl {
		right: auto;
	}

	.ant-col-pull-0.ant-col-rtl {
		left: auto;
	}

	.ant-col-xl-push-0.ant-col-rtl {
		right: auto;
	}

	.ant-col-xl-pull-0.ant-col-rtl {
		left: auto;
	}

	.ant-col-xl-offset-0.ant-col-rtl {
		margin-right: 0;
	}

	.ant-col-xl-push-1.ant-col-rtl {
		left: auto;
		right: 4.16666667%;
	}

	.ant-col-xl-pull-1.ant-col-rtl {
		left: 4.16666667%;
		right: auto;
	}

	.ant-col-xl-offset-1.ant-col-rtl {
		margin-left: 0;
		margin-right: 4.16666667%;
	}

	.ant-col-xl-push-2.ant-col-rtl {
		left: auto;
		right: 8.33333333%;
	}

	.ant-col-xl-pull-2.ant-col-rtl {
		left: 8.33333333%;
		right: auto;
	}

	.ant-col-xl-offset-2.ant-col-rtl {
		margin-left: 0;
		margin-right: 8.33333333%;
	}

	.ant-col-xl-push-3.ant-col-rtl {
		left: auto;
		right: 12.5%;
	}

	.ant-col-xl-pull-3.ant-col-rtl {
		left: 12.5%;
		right: auto;
	}

	.ant-col-xl-offset-3.ant-col-rtl {
		margin-left: 0;
		margin-right: 12.5%;
	}

	.ant-col-xl-push-4.ant-col-rtl {
		left: auto;
		right: 16.66666667%;
	}

	.ant-col-xl-pull-4.ant-col-rtl {
		left: 16.66666667%;
		right: auto;
	}

	.ant-col-xl-offset-4.ant-col-rtl {
		margin-left: 0;
		margin-right: 16.66666667%;
	}

	.ant-col-xl-push-5.ant-col-rtl {
		left: auto;
		right: 20.83333333%;
	}

	.ant-col-xl-pull-5.ant-col-rtl {
		left: 20.83333333%;
		right: auto;
	}

	.ant-col-xl-offset-5.ant-col-rtl {
		margin-left: 0;
		margin-right: 20.83333333%;
	}

	.ant-col-xl-push-6.ant-col-rtl {
		left: auto;
		right: 25%;
	}

	.ant-col-xl-pull-6.ant-col-rtl {
		left: 25%;
		right: auto;
	}

	.ant-col-xl-offset-6.ant-col-rtl {
		margin-left: 0;
		margin-right: 25%;
	}

	.ant-col-xl-push-7.ant-col-rtl {
		left: auto;
		right: 29.16666667%;
	}

	.ant-col-xl-pull-7.ant-col-rtl {
		left: 29.16666667%;
		right: auto;
	}

	.ant-col-xl-offset-7.ant-col-rtl {
		margin-left: 0;
		margin-right: 29.16666667%;
	}

	.ant-col-xl-push-8.ant-col-rtl {
		left: auto;
		right: 33.33333333%;
	}

	.ant-col-xl-pull-8.ant-col-rtl {
		left: 33.33333333%;
		right: auto;
	}

	.ant-col-xl-offset-8.ant-col-rtl {
		margin-left: 0;
		margin-right: 33.33333333%;
	}

	.ant-col-xl-push-9.ant-col-rtl {
		left: auto;
		right: 37.5%;
	}

	.ant-col-xl-pull-9.ant-col-rtl {
		left: 37.5%;
		right: auto;
	}

	.ant-col-xl-offset-9.ant-col-rtl {
		margin-left: 0;
		margin-right: 37.5%;
	}

	.ant-col-xl-push-10.ant-col-rtl {
		left: auto;
		right: 41.66666667%;
	}

	.ant-col-xl-pull-10.ant-col-rtl {
		left: 41.66666667%;
		right: auto;
	}

	.ant-col-xl-offset-10.ant-col-rtl {
		margin-left: 0;
		margin-right: 41.66666667%;
	}

	.ant-col-xl-push-11.ant-col-rtl {
		left: auto;
		right: 45.83333333%;
	}

	.ant-col-xl-pull-11.ant-col-rtl {
		left: 45.83333333%;
		right: auto;
	}

	.ant-col-xl-offset-11.ant-col-rtl {
		margin-left: 0;
		margin-right: 45.83333333%;
	}

	.ant-col-xl-push-12.ant-col-rtl {
		left: auto;
		right: 50%;
	}

	.ant-col-xl-pull-12.ant-col-rtl {
		left: 50%;
		right: auto;
	}

	.ant-col-xl-offset-12.ant-col-rtl {
		margin-left: 0;
		margin-right: 50%;
	}

	.ant-col-xl-push-13.ant-col-rtl {
		left: auto;
		right: 54.16666667%;
	}

	.ant-col-xl-pull-13.ant-col-rtl {
		left: 54.16666667%;
		right: auto;
	}

	.ant-col-xl-offset-13.ant-col-rtl {
		margin-left: 0;
		margin-right: 54.16666667%;
	}

	.ant-col-xl-push-14.ant-col-rtl {
		left: auto;
		right: 58.33333333%;
	}

	.ant-col-xl-pull-14.ant-col-rtl {
		left: 58.33333333%;
		right: auto;
	}

	.ant-col-xl-offset-14.ant-col-rtl {
		margin-left: 0;
		margin-right: 58.33333333%;
	}

	.ant-col-xl-push-15.ant-col-rtl {
		left: auto;
		right: 62.5%;
	}

	.ant-col-xl-pull-15.ant-col-rtl {
		left: 62.5%;
		right: auto;
	}

	.ant-col-xl-offset-15.ant-col-rtl {
		margin-left: 0;
		margin-right: 62.5%;
	}

	.ant-col-xl-push-16.ant-col-rtl {
		left: auto;
		right: 66.66666667%;
	}

	.ant-col-xl-pull-16.ant-col-rtl {
		left: 66.66666667%;
		right: auto;
	}

	.ant-col-xl-offset-16.ant-col-rtl {
		margin-left: 0;
		margin-right: 66.66666667%;
	}

	.ant-col-xl-push-17.ant-col-rtl {
		left: auto;
		right: 70.83333333%;
	}

	.ant-col-xl-pull-17.ant-col-rtl {
		left: 70.83333333%;
		right: auto;
	}

	.ant-col-xl-offset-17.ant-col-rtl {
		margin-left: 0;
		margin-right: 70.83333333%;
	}

	.ant-col-xl-push-18.ant-col-rtl {
		left: auto;
		right: 75%;
	}

	.ant-col-xl-pull-18.ant-col-rtl {
		left: 75%;
		right: auto;
	}

	.ant-col-xl-offset-18.ant-col-rtl {
		margin-left: 0;
		margin-right: 75%;
	}

	.ant-col-xl-push-19.ant-col-rtl {
		left: auto;
		right: 79.16666667%;
	}

	.ant-col-xl-pull-19.ant-col-rtl {
		left: 79.16666667%;
		right: auto;
	}

	.ant-col-xl-offset-19.ant-col-rtl {
		margin-left: 0;
		margin-right: 79.16666667%;
	}

	.ant-col-xl-push-20.ant-col-rtl {
		left: auto;
		right: 83.33333333%;
	}

	.ant-col-xl-pull-20.ant-col-rtl {
		left: 83.33333333%;
		right: auto;
	}

	.ant-col-xl-offset-20.ant-col-rtl {
		margin-left: 0;
		margin-right: 83.33333333%;
	}

	.ant-col-xl-push-21.ant-col-rtl {
		left: auto;
		right: 87.5%;
	}

	.ant-col-xl-pull-21.ant-col-rtl {
		left: 87.5%;
		right: auto;
	}

	.ant-col-xl-offset-21.ant-col-rtl {
		margin-left: 0;
		margin-right: 87.5%;
	}

	.ant-col-xl-push-22.ant-col-rtl {
		left: auto;
		right: 91.66666667%;
	}

	.ant-col-xl-pull-22.ant-col-rtl {
		left: 91.66666667%;
		right: auto;
	}

	.ant-col-xl-offset-22.ant-col-rtl {
		margin-left: 0;
		margin-right: 91.66666667%;
	}

	.ant-col-xl-push-23.ant-col-rtl {
		left: auto;
		right: 95.83333333%;
	}

	.ant-col-xl-pull-23.ant-col-rtl {
		left: 95.83333333%;
		right: auto;
	}

	.ant-col-xl-offset-23.ant-col-rtl {
		margin-left: 0;
		margin-right: 95.83333333%;
	}

	.ant-col-xl-push-24.ant-col-rtl {
		left: auto;
		right: 100%;
	}

	.ant-col-xl-pull-24.ant-col-rtl {
		left: 100%;
		right: auto;
	}

	.ant-col-xl-offset-24.ant-col-rtl {
		margin-left: 0;
		margin-right: 100%;
	}
}

@media (min-width: 1600px) {
	.ant-col-xxl-24 {
		display: block;
		flex: 0 0 100%;
		max-width: 100%;
	}

	.ant-col-xxl-push-24 {
		left: 100%;
	}

	.ant-col-xxl-pull-24 {
		right: 100%;
	}

	.ant-col-xxl-offset-24 {
		margin-left: 100%;
	}

	.ant-col-xxl-order-24 {
		order: 24;
	}

	.ant-col-xxl-23 {
		display: block;
		flex: 0 0 95.83333333%;
		max-width: 95.83333333%;
	}

	.ant-col-xxl-push-23 {
		left: 95.83333333%;
	}

	.ant-col-xxl-pull-23 {
		right: 95.83333333%;
	}

	.ant-col-xxl-offset-23 {
		margin-left: 95.83333333%;
	}

	.ant-col-xxl-order-23 {
		order: 23;
	}

	.ant-col-xxl-22 {
		display: block;
		flex: 0 0 91.66666667%;
		max-width: 91.66666667%;
	}

	.ant-col-xxl-push-22 {
		left: 91.66666667%;
	}

	.ant-col-xxl-pull-22 {
		right: 91.66666667%;
	}

	.ant-col-xxl-offset-22 {
		margin-left: 91.66666667%;
	}

	.ant-col-xxl-order-22 {
		order: 22;
	}

	.ant-col-xxl-21 {
		display: block;
		flex: 0 0 87.5%;
		max-width: 87.5%;
	}

	.ant-col-xxl-push-21 {
		left: 87.5%;
	}

	.ant-col-xxl-pull-21 {
		right: 87.5%;
	}

	.ant-col-xxl-offset-21 {
		margin-left: 87.5%;
	}

	.ant-col-xxl-order-21 {
		order: 21;
	}

	.ant-col-xxl-20 {
		display: block;
		flex: 0 0 83.33333333%;
		max-width: 83.33333333%;
	}

	.ant-col-xxl-push-20 {
		left: 83.33333333%;
	}

	.ant-col-xxl-pull-20 {
		right: 83.33333333%;
	}

	.ant-col-xxl-offset-20 {
		margin-left: 83.33333333%;
	}

	.ant-col-xxl-order-20 {
		order: 20;
	}

	.ant-col-xxl-19 {
		display: block;
		flex: 0 0 79.16666667%;
		max-width: 79.16666667%;
	}

	.ant-col-xxl-push-19 {
		left: 79.16666667%;
	}

	.ant-col-xxl-pull-19 {
		right: 79.16666667%;
	}

	.ant-col-xxl-offset-19 {
		margin-left: 79.16666667%;
	}

	.ant-col-xxl-order-19 {
		order: 19;
	}

	.ant-col-xxl-18 {
		display: block;
		flex: 0 0 75%;
		max-width: 75%;
	}

	.ant-col-xxl-push-18 {
		left: 75%;
	}

	.ant-col-xxl-pull-18 {
		right: 75%;
	}

	.ant-col-xxl-offset-18 {
		margin-left: 75%;
	}

	.ant-col-xxl-order-18 {
		order: 18;
	}

	.ant-col-xxl-17 {
		display: block;
		flex: 0 0 70.83333333%;
		max-width: 70.83333333%;
	}

	.ant-col-xxl-push-17 {
		left: 70.83333333%;
	}

	.ant-col-xxl-pull-17 {
		right: 70.83333333%;
	}

	.ant-col-xxl-offset-17 {
		margin-left: 70.83333333%;
	}

	.ant-col-xxl-order-17 {
		order: 17;
	}

	.ant-col-xxl-16 {
		display: block;
		flex: 0 0 66.66666667%;
		max-width: 66.66666667%;
	}

	.ant-col-xxl-push-16 {
		left: 66.66666667%;
	}

	.ant-col-xxl-pull-16 {
		right: 66.66666667%;
	}

	.ant-col-xxl-offset-16 {
		margin-left: 66.66666667%;
	}

	.ant-col-xxl-order-16 {
		order: 16;
	}

	.ant-col-xxl-15 {
		display: block;
		flex: 0 0 62.5%;
		max-width: 62.5%;
	}

	.ant-col-xxl-push-15 {
		left: 62.5%;
	}

	.ant-col-xxl-pull-15 {
		right: 62.5%;
	}

	.ant-col-xxl-offset-15 {
		margin-left: 62.5%;
	}

	.ant-col-xxl-order-15 {
		order: 15;
	}

	.ant-col-xxl-14 {
		display: block;
		flex: 0 0 58.33333333%;
		max-width: 58.33333333%;
	}

	.ant-col-xxl-push-14 {
		left: 58.33333333%;
	}

	.ant-col-xxl-pull-14 {
		right: 58.33333333%;
	}

	.ant-col-xxl-offset-14 {
		margin-left: 58.33333333%;
	}

	.ant-col-xxl-order-14 {
		order: 14;
	}

	.ant-col-xxl-13 {
		display: block;
		flex: 0 0 54.16666667%;
		max-width: 54.16666667%;
	}

	.ant-col-xxl-push-13 {
		left: 54.16666667%;
	}

	.ant-col-xxl-pull-13 {
		right: 54.16666667%;
	}

	.ant-col-xxl-offset-13 {
		margin-left: 54.16666667%;
	}

	.ant-col-xxl-order-13 {
		order: 13;
	}

	.ant-col-xxl-12 {
		display: block;
		flex: 0 0 50%;
		max-width: 50%;
	}

	.ant-col-xxl-push-12 {
		left: 50%;
	}

	.ant-col-xxl-pull-12 {
		right: 50%;
	}

	.ant-col-xxl-offset-12 {
		margin-left: 50%;
	}

	.ant-col-xxl-order-12 {
		order: 12;
	}

	.ant-col-xxl-11 {
		display: block;
		flex: 0 0 45.83333333%;
		max-width: 45.83333333%;
	}

	.ant-col-xxl-push-11 {
		left: 45.83333333%;
	}

	.ant-col-xxl-pull-11 {
		right: 45.83333333%;
	}

	.ant-col-xxl-offset-11 {
		margin-left: 45.83333333%;
	}

	.ant-col-xxl-order-11 {
		order: 11;
	}

	.ant-col-xxl-10 {
		display: block;
		flex: 0 0 41.66666667%;
		max-width: 41.66666667%;
	}

	.ant-col-xxl-push-10 {
		left: 41.66666667%;
	}

	.ant-col-xxl-pull-10 {
		right: 41.66666667%;
	}

	.ant-col-xxl-offset-10 {
		margin-left: 41.66666667%;
	}

	.ant-col-xxl-order-10 {
		order: 10;
	}

	.ant-col-xxl-9 {
		display: block;
		flex: 0 0 37.5%;
		max-width: 37.5%;
	}

	.ant-col-xxl-push-9 {
		left: 37.5%;
	}

	.ant-col-xxl-pull-9 {
		right: 37.5%;
	}

	.ant-col-xxl-offset-9 {
		margin-left: 37.5%;
	}

	.ant-col-xxl-order-9 {
		order: 9;
	}

	.ant-col-xxl-8 {
		display: block;
		flex: 0 0 33.33333333%;
		max-width: 33.33333333%;
	}

	.ant-col-xxl-push-8 {
		left: 33.33333333%;
	}

	.ant-col-xxl-pull-8 {
		right: 33.33333333%;
	}

	.ant-col-xxl-offset-8 {
		margin-left: 33.33333333%;
	}

	.ant-col-xxl-order-8 {
		order: 8;
	}

	.ant-col-xxl-7 {
		display: block;
		flex: 0 0 29.16666667%;
		max-width: 29.16666667%;
	}

	.ant-col-xxl-push-7 {
		left: 29.16666667%;
	}

	.ant-col-xxl-pull-7 {
		right: 29.16666667%;
	}

	.ant-col-xxl-offset-7 {
		margin-left: 29.16666667%;
	}

	.ant-col-xxl-order-7 {
		order: 7;
	}

	.ant-col-xxl-6 {
		display: block;
		flex: 0 0 25%;
		max-width: 25%;
	}

	.ant-col-xxl-push-6 {
		left: 25%;
	}

	.ant-col-xxl-pull-6 {
		right: 25%;
	}

	.ant-col-xxl-offset-6 {
		margin-left: 25%;
	}

	.ant-col-xxl-order-6 {
		order: 6;
	}

	.ant-col-xxl-5 {
		display: block;
		flex: 0 0 20.83333333%;
		max-width: 20.83333333%;
	}

	.ant-col-xxl-push-5 {
		left: 20.83333333%;
	}

	.ant-col-xxl-pull-5 {
		right: 20.83333333%;
	}

	.ant-col-xxl-offset-5 {
		margin-left: 20.83333333%;
	}

	.ant-col-xxl-order-5 {
		order: 5;
	}

	.ant-col-xxl-4 {
		display: block;
		flex: 0 0 16.66666667%;
		max-width: 16.66666667%;
	}

	.ant-col-xxl-push-4 {
		left: 16.66666667%;
	}

	.ant-col-xxl-pull-4 {
		right: 16.66666667%;
	}

	.ant-col-xxl-offset-4 {
		margin-left: 16.66666667%;
	}

	.ant-col-xxl-order-4 {
		order: 4;
	}

	.ant-col-xxl-3 {
		display: block;
		flex: 0 0 12.5%;
		max-width: 12.5%;
	}

	.ant-col-xxl-push-3 {
		left: 12.5%;
	}

	.ant-col-xxl-pull-3 {
		right: 12.5%;
	}

	.ant-col-xxl-offset-3 {
		margin-left: 12.5%;
	}

	.ant-col-xxl-order-3 {
		order: 3;
	}

	.ant-col-xxl-2 {
		display: block;
		flex: 0 0 8.33333333%;
		max-width: 8.33333333%;
	}

	.ant-col-xxl-push-2 {
		left: 8.33333333%;
	}

	.ant-col-xxl-pull-2 {
		right: 8.33333333%;
	}

	.ant-col-xxl-offset-2 {
		margin-left: 8.33333333%;
	}

	.ant-col-xxl-order-2 {
		order: 2;
	}

	.ant-col-xxl-1 {
		display: block;
		flex: 0 0 4.16666667%;
		max-width: 4.16666667%;
	}

	.ant-col-xxl-push-1 {
		left: 4.16666667%;
	}

	.ant-col-xxl-pull-1 {
		right: 4.16666667%;
	}

	.ant-col-xxl-offset-1 {
		margin-left: 4.16666667%;
	}

	.ant-col-xxl-order-1 {
		order: 1;
	}

	.ant-col-xxl-0 {
		display: none;
	}

	.ant-col-push-0 {
		left: auto;
	}

	.ant-col-pull-0 {
		right: auto;
	}

	.ant-col-xxl-push-0 {
		left: auto;
	}

	.ant-col-xxl-pull-0 {
		right: auto;
	}

	.ant-col-xxl-offset-0 {
		margin-left: 0;
	}

	.ant-col-xxl-order-0 {
		order: 0;
	}

	.ant-col-push-0.ant-col-rtl {
		right: auto;
	}

	.ant-col-pull-0.ant-col-rtl {
		left: auto;
	}

	.ant-col-xxl-push-0.ant-col-rtl {
		right: auto;
	}

	.ant-col-xxl-pull-0.ant-col-rtl {
		left: auto;
	}

	.ant-col-xxl-offset-0.ant-col-rtl {
		margin-right: 0;
	}

	.ant-col-xxl-push-1.ant-col-rtl {
		left: auto;
		right: 4.16666667%;
	}

	.ant-col-xxl-pull-1.ant-col-rtl {
		left: 4.16666667%;
		right: auto;
	}

	.ant-col-xxl-offset-1.ant-col-rtl {
		margin-left: 0;
		margin-right: 4.16666667%;
	}

	.ant-col-xxl-push-2.ant-col-rtl {
		left: auto;
		right: 8.33333333%;
	}

	.ant-col-xxl-pull-2.ant-col-rtl {
		left: 8.33333333%;
		right: auto;
	}

	.ant-col-xxl-offset-2.ant-col-rtl {
		margin-left: 0;
		margin-right: 8.33333333%;
	}

	.ant-col-xxl-push-3.ant-col-rtl {
		left: auto;
		right: 12.5%;
	}

	.ant-col-xxl-pull-3.ant-col-rtl {
		left: 12.5%;
		right: auto;
	}

	.ant-col-xxl-offset-3.ant-col-rtl {
		margin-left: 0;
		margin-right: 12.5%;
	}

	.ant-col-xxl-push-4.ant-col-rtl {
		left: auto;
		right: 16.66666667%;
	}

	.ant-col-xxl-pull-4.ant-col-rtl {
		left: 16.66666667%;
		right: auto;
	}

	.ant-col-xxl-offset-4.ant-col-rtl {
		margin-left: 0;
		margin-right: 16.66666667%;
	}

	.ant-col-xxl-push-5.ant-col-rtl {
		left: auto;
		right: 20.83333333%;
	}

	.ant-col-xxl-pull-5.ant-col-rtl {
		left: 20.83333333%;
		right: auto;
	}

	.ant-col-xxl-offset-5.ant-col-rtl {
		margin-left: 0;
		margin-right: 20.83333333%;
	}

	.ant-col-xxl-push-6.ant-col-rtl {
		left: auto;
		right: 25%;
	}

	.ant-col-xxl-pull-6.ant-col-rtl {
		left: 25%;
		right: auto;
	}

	.ant-col-xxl-offset-6.ant-col-rtl {
		margin-left: 0;
		margin-right: 25%;
	}

	.ant-col-xxl-push-7.ant-col-rtl {
		left: auto;
		right: 29.16666667%;
	}

	.ant-col-xxl-pull-7.ant-col-rtl {
		left: 29.16666667%;
		right: auto;
	}

	.ant-col-xxl-offset-7.ant-col-rtl {
		margin-left: 0;
		margin-right: 29.16666667%;
	}

	.ant-col-xxl-push-8.ant-col-rtl {
		left: auto;
		right: 33.33333333%;
	}

	.ant-col-xxl-pull-8.ant-col-rtl {
		left: 33.33333333%;
		right: auto;
	}

	.ant-col-xxl-offset-8.ant-col-rtl {
		margin-left: 0;
		margin-right: 33.33333333%;
	}

	.ant-col-xxl-push-9.ant-col-rtl {
		left: auto;
		right: 37.5%;
	}

	.ant-col-xxl-pull-9.ant-col-rtl {
		left: 37.5%;
		right: auto;
	}

	.ant-col-xxl-offset-9.ant-col-rtl {
		margin-left: 0;
		margin-right: 37.5%;
	}

	.ant-col-xxl-push-10.ant-col-rtl {
		left: auto;
		right: 41.66666667%;
	}

	.ant-col-xxl-pull-10.ant-col-rtl {
		left: 41.66666667%;
		right: auto;
	}

	.ant-col-xxl-offset-10.ant-col-rtl {
		margin-left: 0;
		margin-right: 41.66666667%;
	}

	.ant-col-xxl-push-11.ant-col-rtl {
		left: auto;
		right: 45.83333333%;
	}

	.ant-col-xxl-pull-11.ant-col-rtl {
		left: 45.83333333%;
		right: auto;
	}

	.ant-col-xxl-offset-11.ant-col-rtl {
		margin-left: 0;
		margin-right: 45.83333333%;
	}

	.ant-col-xxl-push-12.ant-col-rtl {
		left: auto;
		right: 50%;
	}

	.ant-col-xxl-pull-12.ant-col-rtl {
		left: 50%;
		right: auto;
	}

	.ant-col-xxl-offset-12.ant-col-rtl {
		margin-left: 0;
		margin-right: 50%;
	}

	.ant-col-xxl-push-13.ant-col-rtl {
		left: auto;
		right: 54.16666667%;
	}

	.ant-col-xxl-pull-13.ant-col-rtl {
		left: 54.16666667%;
		right: auto;
	}

	.ant-col-xxl-offset-13.ant-col-rtl {
		margin-left: 0;
		margin-right: 54.16666667%;
	}

	.ant-col-xxl-push-14.ant-col-rtl {
		left: auto;
		right: 58.33333333%;
	}

	.ant-col-xxl-pull-14.ant-col-rtl {
		left: 58.33333333%;
		right: auto;
	}

	.ant-col-xxl-offset-14.ant-col-rtl {
		margin-left: 0;
		margin-right: 58.33333333%;
	}

	.ant-col-xxl-push-15.ant-col-rtl {
		left: auto;
		right: 62.5%;
	}

	.ant-col-xxl-pull-15.ant-col-rtl {
		left: 62.5%;
		right: auto;
	}

	.ant-col-xxl-offset-15.ant-col-rtl {
		margin-left: 0;
		margin-right: 62.5%;
	}

	.ant-col-xxl-push-16.ant-col-rtl {
		left: auto;
		right: 66.66666667%;
	}

	.ant-col-xxl-pull-16.ant-col-rtl {
		left: 66.66666667%;
		right: auto;
	}

	.ant-col-xxl-offset-16.ant-col-rtl {
		margin-left: 0;
		margin-right: 66.66666667%;
	}

	.ant-col-xxl-push-17.ant-col-rtl {
		left: auto;
		right: 70.83333333%;
	}

	.ant-col-xxl-pull-17.ant-col-rtl {
		left: 70.83333333%;
		right: auto;
	}

	.ant-col-xxl-offset-17.ant-col-rtl {
		margin-left: 0;
		margin-right: 70.83333333%;
	}

	.ant-col-xxl-push-18.ant-col-rtl {
		left: auto;
		right: 75%;
	}

	.ant-col-xxl-pull-18.ant-col-rtl {
		left: 75%;
		right: auto;
	}

	.ant-col-xxl-offset-18.ant-col-rtl {
		margin-left: 0;
		margin-right: 75%;
	}

	.ant-col-xxl-push-19.ant-col-rtl {
		left: auto;
		right: 79.16666667%;
	}

	.ant-col-xxl-pull-19.ant-col-rtl {
		left: 79.16666667%;
		right: auto;
	}

	.ant-col-xxl-offset-19.ant-col-rtl {
		margin-left: 0;
		margin-right: 79.16666667%;
	}

	.ant-col-xxl-push-20.ant-col-rtl {
		left: auto;
		right: 83.33333333%;
	}

	.ant-col-xxl-pull-20.ant-col-rtl {
		left: 83.33333333%;
		right: auto;
	}

	.ant-col-xxl-offset-20.ant-col-rtl {
		margin-left: 0;
		margin-right: 83.33333333%;
	}

	.ant-col-xxl-push-21.ant-col-rtl {
		left: auto;
		right: 87.5%;
	}

	.ant-col-xxl-pull-21.ant-col-rtl {
		left: 87.5%;
		right: auto;
	}

	.ant-col-xxl-offset-21.ant-col-rtl {
		margin-left: 0;
		margin-right: 87.5%;
	}

	.ant-col-xxl-push-22.ant-col-rtl {
		left: auto;
		right: 91.66666667%;
	}

	.ant-col-xxl-pull-22.ant-col-rtl {
		left: 91.66666667%;
		right: auto;
	}

	.ant-col-xxl-offset-22.ant-col-rtl {
		margin-left: 0;
		margin-right: 91.66666667%;
	}

	.ant-col-xxl-push-23.ant-col-rtl {
		left: auto;
		right: 95.83333333%;
	}

	.ant-col-xxl-pull-23.ant-col-rtl {
		left: 95.83333333%;
		right: auto;
	}

	.ant-col-xxl-offset-23.ant-col-rtl {
		margin-left: 0;
		margin-right: 95.83333333%;
	}

	.ant-col-xxl-push-24.ant-col-rtl {
		left: auto;
		right: 100%;
	}

	.ant-col-xxl-pull-24.ant-col-rtl {
		left: 100%;
		right: auto;
	}

	.ant-col-xxl-offset-24.ant-col-rtl {
		margin-left: 0;
		margin-right: 100%;
	}
}

.ant-row-rtl {
	direction: rtl;
}

.ant-image {
	display: inline-block;
	position: relative;
}

.ant-image-img {
	display: block;
	height: auto;
	width: 100%;
}

.ant-image-img-placeholder {
	background-color: #f5f5f5;
	background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTE0LjUgMi41aC0xM0EuNS41IDAgMCAwIDEgM3YxMGEuNS41IDAgMCAwIC41LjVoMTNhLjUuNSAwIDAgMCAuNS0uNVYzYS41LjUgMCAwIDAtLjUtLjV6TTUuMjgxIDQuNzVhMSAxIDAgMCAxIDAgMiAxIDEgMCAwIDEgMC0yem04LjAzIDYuODNhLjEyNy4xMjcgMCAwIDEtLjA4MS4wM0gyLjc2OWEuMTI1LjEyNSAwIDAgMS0uMDk2LS4yMDdsMi42NjEtMy4xNTZhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTYuMDE2TDcuMDggMTAuMDlsMi40Ny0yLjkzYS4xMjYuMTI2IDAgMCAxIC4xNzctLjAxNmwuMDE1LjAxNiAzLjU4OCA0LjI0NGEuMTI3LjEyNyAwIDAgMS0uMDIuMTc1eiIgZmlsbD0iIzhDOEM4QyIvPjwvc3ZnPg==);
	background-position: 50%;
	background-repeat: no-repeat;
	background-size: 30%;
}

.ant-image-mask {
	align-items: center;
	background: rgba(0, 0, 0, 0.5);
	bottom: 0;
	color: #fff;
	cursor: pointer;
	display: flex;
	justify-content: center;
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	transition: opacity 0.3s;
}

.ant-image-mask-info .anticon {
	-webkit-margin-end: 4px;
	margin-inline-end: 4px;
}

.ant-image-mask:hover {
	opacity: 1;
}

.ant-image-placeholder {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-image-preview {
	height: 100%;
	pointer-events: none;
	text-align: center;
}

.ant-image-preview.zoom-appear,
.ant-image-preview.zoom-enter {
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	opacity: 0;
	-webkit-transform: none;
	transform: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ant-image-preview-mask {
	background-color: rgba(0, 0, 0, 0.45);
	bottom: 0;
	height: 100%;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1000;
}

.ant-image-preview-mask-hidden {
	display: none;
}

.ant-image-preview-wrap {
	-webkit-overflow-scrolling: touch;
	bottom: 0;
	left: 0;
	outline: 0;
	overflow: auto;
	position: fixed;
	right: 0;
	top: 0;
}

.ant-image-preview-body {
	bottom: 0;
	left: 0;
	overflow: hidden;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-image-preview-img {
	cursor: grab;
	max-height: 100%;
	max-width: 100%;
	pointer-events: auto;
	-webkit-transform: scaleX(1);
	transform: scaleX(1);
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	vertical-align: middle;
}

.ant-image-preview-img,
.ant-image-preview-img-wrapper {
	transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
	transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s,
		-webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}

.ant-image-preview-img-wrapper {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-image-preview-img-wrapper:before {
	content: '';
	display: inline-block;
	height: 50%;
	margin-right: -1px;
	width: 1px;
}

.ant-image-preview-moving .ant-image-preview-img {
	cursor: grabbing;
}

.ant-image-preview-moving .ant-image-preview-img-wrapper {
	transition-duration: 0s;
}

.ant-image-preview-wrap {
	z-index: 1080;
}

.ant-image-preview-operations {
	align-items: center;
	background: rgba(0, 0, 0, 0.1);
	box-sizing: border-box;
	color: #545454;
	color: hsla(0, 0%, 100%, 0.85);
	display: flex;
	flex-direction: row-reverse;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
	pointer-events: auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 1;
}

.ant-image-preview-operations-operation {
	cursor: pointer;
	margin-left: 12px;
	padding: 12px;
}

.ant-image-preview-operations-operation-disabled {
	color: hsla(0, 0%, 100%, 0.25);
	pointer-events: none;
}

.ant-image-preview-operations-operation:last-of-type {
	margin-left: 0;
}

.ant-image-preview-operations-icon {
	font-size: 18px;
}

.ant-image-preview-switch-left,
.ant-image-preview-switch-right {
	align-items: center;
	background: rgba(0, 0, 0, 0.1);
	border-radius: 50%;
	color: hsla(0, 0%, 100%, 0.85);
	cursor: pointer;
	display: flex;
	height: 44px;
	justify-content: center;
	margin-top: -22px;
	pointer-events: auto;
	position: absolute;
	right: 10px;
	top: 50%;
	width: 44px;
	z-index: 1;
}

.ant-image-preview-switch-left-disabled,
.ant-image-preview-switch-right-disabled {
	color: hsla(0, 0%, 100%, 0.25);
	cursor: not-allowed;
}

.ant-image-preview-switch-left-disabled>.anticon,
.ant-image-preview-switch-right-disabled>.anticon {
	cursor: not-allowed;
}

.ant-image-preview-switch-left>.anticon,
.ant-image-preview-switch-right>.anticon {
	font-size: 18px;
}

.ant-image-preview-switch-left {
	left: 10px;
}

.ant-image-preview-switch-right {
	right: 10px;
}

.ant-input-affix-wrapper {
	background-color: #fff;
	background-image: none;
	border: 1px solid #d9d9d9;
	border-radius: 6px;
	color: #545454;
	display: inline-block;
	display: inline-flex;
	font-size: 14px;
	line-height: 1.3;
	min-width: 0;
	padding: 7.9px 11px;
	position: relative;
	transition: all 0.3s;
	width: 100%;
}

.ant-input-affix-wrapper::-webkit-input-placeholder {
	color: #bfbfbf;
}

.ant-input-affix-wrapper:-ms-input-placeholder {
	color: #bfbfbf;
}

.ant-input-affix-wrapper::placeholder {
	color: #bfbfbf;
}

.ant-input-affix-wrapper:-ms-input-placeholder {
	text-overflow: ellipsis;
}

.ant-input-affix-wrapper:placeholder-shown {
	text-overflow: ellipsis;
}

.ant-input-affix-wrapper:hover {
	border-color: #28aaeb;
	border-right-width: 1px !important;
}

.ant-input-rtl .ant-input-affix-wrapper:hover {
	border-left-width: 1px !important;
	border-right-width: 0;
}

.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
	border-color: #28aaeb;
	border-right-width: 1px !important;
	box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
	outline: 0;
}

.ant-input-rtl .ant-input-affix-wrapper-focused,
.ant-input-rtl .ant-input-affix-wrapper:focus {
	border-left-width: 1px !important;
	border-right-width: 0;
}

.ant-input-affix-wrapper-disabled {
	background-color: #f5f5f5;
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
	opacity: 1;
}

.ant-input-affix-wrapper-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}

.ant-input-affix-wrapper[disabled] {
	background-color: #f5f5f5;
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
	opacity: 1;
}

.ant-input-affix-wrapper[disabled]:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}

.ant-input-affix-wrapper-borderless,
.ant-input-affix-wrapper-borderless-disabled,
.ant-input-affix-wrapper-borderless-focused,
.ant-input-affix-wrapper-borderless:focus,
.ant-input-affix-wrapper-borderless:hover,
.ant-input-affix-wrapper-borderless[disabled] {
	background-color: transparent;
	border: none;
	box-shadow: none;
}

textarea.ant-input-affix-wrapper {
	height: auto;
	line-height: 1.3;
	max-width: 100%;
	min-height: 36px;
	transition: all 0.3s, height 0s;
	vertical-align: bottom;
}

.ant-input-affix-wrapper-lg {
	font-size: 16px;
	padding: 8.6px 11px;
}

.ant-input-affix-wrapper-sm {
	padding: 1.9px 7px;
}

.ant-input-affix-wrapper-rtl {
	direction: rtl;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
	border-color: #28aaeb;
	border-right-width: 1px !important;
	z-index: 1;
}

.ant-input-rtl .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
	border-left-width: 1px !important;
	border-right-width: 0;
}

.ant-input-search-with-button .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
	z-index: 0;
}

.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus {
	z-index: 1;
}

.ant-input-affix-wrapper-disabled .ant-input[disabled] {
	background: transparent;
}

.ant-input-affix-wrapper>input.ant-input {
	border: none;
	outline: none;
	padding: 0;
}

.ant-input-affix-wrapper>input.ant-input:focus {
	box-shadow: none;
}

.ant-input-affix-wrapper:before {
	content: '\a0';
	visibility: hidden;
	width: 0;
}

.ant-input-prefix,
.ant-input-suffix {
	align-items: center;
	display: flex;
	flex: none;
}

.ant-input-prefix {
	margin-right: 4px;
}

.ant-input-suffix {
	margin-left: 4px;
}

.ant-input-clear-icon {
	color: rgba(0, 0, 0, 0.25);
	cursor: pointer;
	font-size: 12px;
	margin: 0 4px;
	transition: color 0.3s;
	vertical-align: -1px;
}

.ant-input-clear-icon:hover {
	color: #595959;
}

.ant-input-clear-icon:active {
	color: #545454;
}

.ant-input-clear-icon-hidden {
	visibility: hidden;
}

.ant-input-clear-icon:last-child {
	margin-right: 0;
}

.ant-input-affix-wrapper-textarea-with-clear-btn {
	border: 0 !important;
	padding: 0 !important;
}

.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input-clear-icon {
	position: absolute;
	right: 8px;
	top: 8px;
	z-index: 1;
}

.ant-input {
	background-color: #fff;
	background-image: none;
	border: 1px solid #d9d9d9;
	border-radius: 6px;
	box-sizing: border-box;
	color: #545454;
	display: inline-block;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	min-width: 0;
	padding: 7.9px 11px;
	position: relative;
	transition: all 0.3s;
	width: 100%;
}

.ant-input::-webkit-input-placeholder {
	color: #bfbfbf;
}

.ant-input:-ms-input-placeholder {
	color: #bfbfbf;
}

.ant-input::placeholder {
	color: #bfbfbf;
}

.ant-input:-ms-input-placeholder {
	text-overflow: ellipsis;
}

.ant-input:placeholder-shown {
	text-overflow: ellipsis;
}

.ant-input:hover {
	border-color: #28aaeb;
	border-right-width: 1px !important;
}

.ant-input-rtl .ant-input:hover {
	border-left-width: 1px !important;
	border-right-width: 0;
}

.ant-input-focused,
.ant-input:focus {
	border-color: #28aaeb;
	border-right-width: 1px !important;
	box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
	outline: 0;
}

.ant-input-rtl .ant-input-focused,
.ant-input-rtl .ant-input:focus {
	border-left-width: 1px !important;
	border-right-width: 0;
}

.ant-input-disabled {
	background-color: #f5f5f5;
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
	opacity: 1;
}

.ant-input-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}

.ant-input[disabled] {
	background-color: #f5f5f5;
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
	opacity: 1;
}

.ant-input[disabled]:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}

.ant-input-borderless,
.ant-input-borderless-disabled,
.ant-input-borderless-focused,
.ant-input-borderless:focus,
.ant-input-borderless:hover,
.ant-input-borderless[disabled] {
	background-color: transparent;
	border: none;
	box-shadow: none;
}

textarea.ant-input {
	height: auto;
	line-height: 1.3;
	max-width: 100%;
	min-height: 36px;
	transition: all 0.3s, height 0s;
	vertical-align: bottom;
}

.ant-input-lg {
	font-size: 16px;
	padding: 8.6px 11px;
}

.ant-input-sm {
	padding: 1.9px 7px;
}

.ant-input-rtl {
	direction: rtl;
}

.ant-input-group {
	border-collapse: separate;
	border-spacing: 0;
	box-sizing: border-box;
	color: #545454;
	display: table;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
	width: 100%;
}

.ant-input-group[class*='col-'] {
	float: none;
	padding-left: 0;
	padding-right: 0;
}

.ant-input-group>[class*='col-'] {
	padding-right: 8px;
}

.ant-input-group>[class*='col-']:last-child {
	padding-right: 0;
}

.ant-input-group-addon,
.ant-input-group-wrap,
.ant-input-group>.ant-input {
	display: table-cell;
}

.ant-input-group-addon:not(:first-child):not(:last-child),
.ant-input-group-wrap:not(:first-child):not(:last-child),
.ant-input-group>.ant-input:not(:first-child):not(:last-child) {
	border-radius: 0;
}

.ant-input-group-addon,
.ant-input-group-wrap {
	vertical-align: middle;
	white-space: nowrap;
	width: 1px;
}

.ant-input-group-wrap>* {
	display: block !important;
}

.ant-input-group .ant-input {
	float: left;
	margin-bottom: 0;
	text-align: inherit;
	width: 100%;
}

.ant-input-group .ant-input:focus,
.ant-input-group .ant-input:hover {
	border-right-width: 1px;
	z-index: 1;
}

.ant-input-search-with-button .ant-input-group .ant-input:hover {
	z-index: 0;
}

.ant-input-group-addon {
	background-color: #fafafa;
	border: 1px solid #d9d9d9;
	border-radius: 6px;
	color: #545454;
	font-size: 14px;
	font-weight: 400;
	padding: 0 11px;
	position: relative;
	text-align: center;
	transition: all 0.3s;
}

.ant-input-group-addon .ant-select {
	margin: -8.9px -11px;
}

.ant-input-group-addon .ant-select.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	background-color: inherit;
	border: 1px solid transparent;
	box-shadow: none;
}

.ant-input-group-addon .ant-select-focused .ant-select-selector,
.ant-input-group-addon .ant-select-open .ant-select-selector {
	color: #038fde;
}

.ant-input-group-addon:first-child,
.ant-input-group-addon:first-child .ant-select .ant-select-selector,
.ant-input-group>.ant-input:first-child,
.ant-input-group>.ant-input:first-child .ant-select .ant-select-selector {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.ant-input-group>.ant-input-affix-wrapper:not(:first-child) .ant-input {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.ant-input-group>.ant-input-affix-wrapper:not(:last-child) .ant-input {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.ant-input-group-addon:first-child {
	border-right: 0;
}

.ant-input-group-addon:last-child {
	border-left: 0;
}

.ant-input-group-addon:last-child,
.ant-input-group-addon:last-child .ant-select .ant-select-selector,
.ant-input-group>.ant-input:last-child,
.ant-input-group>.ant-input:last-child .ant-select .ant-select-selector {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.ant-input-group-lg .ant-input,
.ant-input-group-lg>.ant-input-group-addon {
	font-size: 16px;
	padding: 8.6px 11px;
}

.ant-input-group-sm .ant-input,
.ant-input-group-sm>.ant-input-group-addon {
	padding: 1.9px 7px;
}

.ant-input-group-lg .ant-select-single .ant-select-selector {
	height: 40px;
}

.ant-input-group-sm .ant-select-single .ant-select-selector {
	height: 24px;
}

.ant-input-group .ant-input-affix-wrapper:not(:first-child) {
	border-bottom-left-radius: 0;
	border-top-left-radius: 0;
}

.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
	border-bottom-right-radius: 0;
	border-top-right-radius: 0;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
	border-bottom-left-radius: 6px;
	border-top-left-radius: 6px;
}

.ant-input-group.ant-input-group-compact {
	display: block;
}

.ant-input-group.ant-input-group-compact:before {
	content: '';
	display: table;
}

.ant-input-group.ant-input-group-compact:after {
	clear: both;
	content: '';
	display: table;
}

.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child),
.ant-input-group.ant-input-group-compact>.ant-input:not(:first-child):not(:last-child) {
	border-right-width: 1px;
}

.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-addon:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact-wrap:not(:first-child):not(:last-child):hover,
.ant-input-group.ant-input-group-compact>.ant-input:not(:first-child):not(:last-child):focus,
.ant-input-group.ant-input-group-compact>.ant-input:not(:first-child):not(:last-child):hover {
	z-index: 1;
}

.ant-input-group.ant-input-group-compact>* {
	border-radius: 0;
	display: inline-block;
	float: none;
	vertical-align: top;
}

.ant-input-group.ant-input-group-compact>.ant-input-affix-wrapper,
.ant-input-group.ant-input-group-compact>.ant-picker-range {
	display: inline-flex;
}

.ant-input-group.ant-input-group-compact> :not(:last-child) {
	border-right-width: 1px;
	margin-right: -1px;
}

.ant-input-group.ant-input-group-compact .ant-input {
	float: none;
}

.ant-input-group.ant-input-group-compact>.ant-cascader-picker .ant-input,
.ant-input-group.ant-input-group-compact>.ant-input-group-wrapper .ant-input,
.ant-input-group.ant-input-group-compact>.ant-select-auto-complete .ant-input,
.ant-input-group.ant-input-group-compact>.ant-select>.ant-select-selector {
	border-radius: 0;
	border-right-width: 1px;
}

.ant-input-group.ant-input-group-compact>.ant-cascader-picker .ant-input:focus,
.ant-input-group.ant-input-group-compact>.ant-cascader-picker .ant-input:hover,
.ant-input-group.ant-input-group-compact>.ant-input-group-wrapper .ant-input:focus,
.ant-input-group.ant-input-group-compact>.ant-input-group-wrapper .ant-input:hover,
.ant-input-group.ant-input-group-compact>.ant-select-auto-complete .ant-input:focus,
.ant-input-group.ant-input-group-compact>.ant-select-auto-complete .ant-input:hover,
.ant-input-group.ant-input-group-compact>.ant-select-focused,
.ant-input-group.ant-input-group-compact>.ant-select>.ant-select-arrow,
.ant-input-group.ant-input-group-compact>.ant-select>.ant-select-selector:focus,
.ant-input-group.ant-input-group-compact>.ant-select>.ant-select-selector:hover {
	z-index: 1;
}

.ant-input-group.ant-input-group-compact>.ant-cascader-picker:first-child .ant-input,
.ant-input-group.ant-input-group-compact>.ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact>.ant-select:first-child>.ant-select-selector,
.ant-input-group.ant-input-group-compact> :first-child {
	border-bottom-left-radius: 6px;
	border-top-left-radius: 6px;
}

.ant-input-group.ant-input-group-compact>.ant-cascader-picker-focused:last-child .ant-input,
.ant-input-group.ant-input-group-compact>.ant-cascader-picker:last-child .ant-input,
.ant-input-group.ant-input-group-compact>.ant-select:last-child>.ant-select-selector,
.ant-input-group.ant-input-group-compact> :last-child {
	border-bottom-right-radius: 6px;
	border-right-width: 1px;
	border-top-right-radius: 6px;
}

.ant-input-group.ant-input-group-compact>.ant-select-auto-complete .ant-input {
	vertical-align: top;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper+.ant-input-group-wrapper {
	margin-left: -1px;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper+.ant-input-group-wrapper .ant-input-affix-wrapper,
.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search>.ant-input-group>.ant-input-group-addon>.ant-input-search-button {
	border-radius: 0;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper:not(:last-child).ant-input-search>.ant-input-group>.ant-input {
	border-radius: 6px 0 0 6px;
}

.ant-input-group-rtl .ant-input-group-addon:first-child,
.ant-input-group>.ant-input-rtl:first-child {
	border-radius: 0 6px 6px 0;
}

.ant-input-group-rtl .ant-input-group-addon:first-child {
	border-left: 0;
	border-right: 1px solid #d9d9d9;
}

.ant-input-group-rtl .ant-input-group-addon:last-child {
	border-left: 1px solid #d9d9d9;
	border-right: 0;
}

.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:first-child),
.ant-input-group-rtl.ant-input-group-addon:last-child,
.ant-input-group-rtl.ant-input-group>.ant-input:last-child {
	border-radius: 6px 0 0 6px;
}

.ant-input-group-rtl.ant-input-group .ant-input-affix-wrapper:not(:last-child) {
	border-radius: 0 6px 6px 0;
}

.ant-input-group-rtl.ant-input-group.ant-input-group-compact> :not(:last-child) {
	border-left-width: 1px;
	margin-left: -1px;
	margin-right: 0;
}

.ant-input-group-rtl.ant-input-group.ant-input-group-compact>.ant-cascader-picker:first-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact>.ant-select-auto-complete:first-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact>.ant-select:first-child>.ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact> :first-child {
	border-radius: 0 6px 6px 0;
}

.ant-input-group-rtl.ant-input-group.ant-input-group-compact>.ant-cascader-picker-focused:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact>.ant-cascader-picker:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact>.ant-select-auto-complete:last-child .ant-input,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact>.ant-select:last-child>.ant-select-selector,
.ant-input-group-rtl.ant-input-group.ant-input-group-compact> :last-child {
	border-left-width: 1px;
	border-radius: 6px 0 0 6px;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl+.ant-input-group-wrapper-rtl {
	margin-left: 0;
	margin-right: -1px;
}

.ant-input-group.ant-input-group-compact .ant-input-group-wrapper-rtl:not(:last-child).ant-input-search>.ant-input-group>.ant-input {
	border-radius: 0 6px 6px 0;
}

.ant-input-group-wrapper {
	display: inline-block;
	text-align: start;
	vertical-align: top;
	width: 100%;
}

.ant-input-password-icon {
	color: #595959;
	cursor: pointer;
	transition: all 0.3s;
}

.ant-input-password-icon:hover {
	color: rgba(0, 0, 0, 0.85);
}

.ant-input[type='color'] {
	height: 36px;
}

.ant-input[type='color'].ant-input-lg {
	height: 40px;
}

.ant-input[type='color'].ant-input-sm {
	height: 24px;
	padding-bottom: 3px;
	padding-top: 3px;
}

.ant-input-textarea-show-count:after {
	color: #595959;
	content: attr(data-count);
	float: right;
	pointer-events: none;
	white-space: nowrap;
}

.ant-input-search .ant-input:focus,
.ant-input-search .ant-input:hover {
	border-color: #28aaeb;
}

.ant-input-search .ant-input:focus+.ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search .ant-input:hover+.ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
	border-left-color: #28aaeb;
}

.ant-input-search .ant-input-affix-wrapper {
	border-radius: 0;
}

.ant-input-search .ant-input-lg {
	line-height: 1.2998;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child {
	border: 0;
	left: -1px;
	padding: 0;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
	border-radius: 0 6px 6px 0;
	padding-bottom: 0;
	padding-top: 0;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary) {
	color: #595959;
}

.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button:not(.ant-btn-primary).ant-btn-loading:before {
	bottom: 0;
	left: 0;
	right: 0;
	top: 0;
}

.ant-input-search-button {
	height: 36px;
}

.ant-input-search-button:focus,
.ant-input-search-button:hover {
	z-index: 1;
}

.ant-input-search-large .ant-input-search-button {
	height: 40px;
}

.ant-input-search-small .ant-input-search-button {
	height: 24px;
}

.ant-input-group-rtl,
.ant-input-group-wrapper-rtl {
	direction: rtl;
}

.ant-input-affix-wrapper.ant-input-affix-wrapper-rtl>input.ant-input {
	border: none;
	outline: none;
}

.ant-input-affix-wrapper-rtl .ant-input-prefix {
	margin: 0 0 0 4px;
}

.ant-input-affix-wrapper-rtl .ant-input-suffix {
	margin: 0 4px 0 0;
}

.ant-input-textarea-rtl {
	direction: rtl;
}

.ant-input-textarea-rtl.ant-input-textarea-show-count:after {
	text-align: left;
}

.ant-input-affix-wrapper-rtl .ant-input-clear-icon:last-child {
	margin-left: 0;
	margin-right: 4px;
}

.ant-input-affix-wrapper-rtl .ant-input-clear-icon {
	left: 8px;
	right: auto;
}

.ant-input-search-rtl {
	direction: rtl;
}

.ant-input-search-rtl .ant-input:focus+.ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary),
.ant-input-search-rtl .ant-input:hover+.ant-input-group-addon .ant-input-search-button:not(.ant-btn-primary) {
	border-left-color: #d9d9d9;
	border-right-color: #28aaeb;
}

.ant-input-search-rtl>.ant-input-group>.ant-input-affix-wrapper-focused,
.ant-input-search-rtl>.ant-input-group>.ant-input-affix-wrapper:hover {
	border-right-color: #28aaeb;
}

.ant-input-search-rtl>.ant-input-group>.ant-input-group-addon {
	left: auto;
	right: -1px;
}

.ant-input-search-rtl>.ant-input-group>.ant-input-group-addon .ant-input-search-button {
	border-radius: 6px 0 0 6px;
}

@media (-ms-high-contrast: none),
screen and (-ms-high-contrast: active) {
	.ant-input {
		height: 36px;
	}

	.ant-input-lg {
		height: 40px;
	}

	.ant-input-sm {
		height: 24px;
	}

	.ant-input-affix-wrapper>input.ant-input {
		height: auto;
	}
}

.ant-input-number {
	background-color: #fff;
	background-image: none;
	border: 1px solid #d9d9d9;
	border-radius: 6px;
	box-sizing: border-box;
	color: #545454;
	display: inline-block;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	min-width: 0;
	padding: 0;
	position: relative;
	transition: all 0.3s;
	width: 100%;
	width: 90px;
}

.ant-input-number::-webkit-input-placeholder {
	color: #bfbfbf;
}

.ant-input-number:-ms-input-placeholder {
	color: #bfbfbf;
}

.ant-input-number::placeholder {
	color: #bfbfbf;
}

.ant-input-number:-ms-input-placeholder {
	text-overflow: ellipsis;
}

.ant-input-number:placeholder-shown {
	text-overflow: ellipsis;
}

.ant-input-rtl .ant-input-number:hover {
	border-left-width: 1px !important;
	border-right-width: 0;
}

.ant-input-number-focused,
.ant-input-number:focus {
	border-color: #28aaeb;
	border-right-width: 1px !important;
	box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
	outline: 0;
}

.ant-input-rtl .ant-input-number-focused,
.ant-input-rtl .ant-input-number:focus {
	border-left-width: 1px !important;
	border-right-width: 0;
}

.ant-input-number[disabled] {
	background-color: #f5f5f5;
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
	opacity: 1;
}

.ant-input-number[disabled]:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}

.ant-input-number-borderless,
.ant-input-number-borderless-disabled,
.ant-input-number-borderless-focused,
.ant-input-number-borderless:focus,
.ant-input-number-borderless:hover,
.ant-input-number-borderless[disabled] {
	background-color: transparent;
	border: none;
	box-shadow: none;
}

textarea.ant-input-number {
	height: auto;
	line-height: 1.3;
	max-width: 100%;
	min-height: 36px;
	transition: all 0.3s, height 0s;
	vertical-align: bottom;
}

.ant-input-number-lg {
	padding: 8.6px 11px;
}

.ant-input-number-sm {
	padding: 1.9px 7px;
}

.ant-input-number-handler {
	color: #595959;
	display: block;
	font-weight: 700;
	height: 50%;
	line-height: 0;
	overflow: hidden;
	position: relative;
	text-align: center;
	transition: all 0.1s linear;
	width: 100%;
}

.ant-input-number-handler:active {
	background: #f4f4f4;
}

.ant-input-number-handler:hover .ant-input-number-handler-down-inner,
.ant-input-number-handler:hover .ant-input-number-handler-up-inner {
	color: #28aaeb;
}

.ant-input-number-handler-down-inner,
.ant-input-number-handler-up-inner {
	text-rendering: optimizeLegibility;

	-moz-osx-font-smoothing: grayscale;
	color: inherit;
	color: #595959;
	display: inline-block;
	font-style: normal;
	height: 12px;
	line-height: 0;
	line-height: 12px;
	position: absolute;
	right: 4px;
	text-align: center;
	text-transform: none;
	transition: all 0.1s linear;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	vertical-align: -0.125em;
	width: 12px;
}

.ant-input-number-handler-down-inner>*,
.ant-input-number-handler-up-inner>* {
	line-height: 1;
}

.ant-input-number-handler-down-inner svg,
.ant-input-number-handler-up-inner svg {
	display: inline-block;
}

.ant-input-number-handler-down-inner:before,
.ant-input-number-handler-up-inner:before {
	display: none;
}

.ant-input-number-handler-down-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-down-inner .ant-input-number-handler-up-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-down-inner-icon,
.ant-input-number-handler-up-inner .ant-input-number-handler-up-inner-icon {
	display: block;
}

.ant-input-number:hover {
	border-color: #28aaeb;
	border-right-width: 1px !important;
}

.ant-input-number:hover+.ant-form-item-children-icon {
	opacity: 0;
	transition: opacity 0.24s linear 0.24s;
}

.ant-input-number-focused {
	border-color: #28aaeb;
	border-right-width: 1px !important;
	box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
	outline: 0;
}

.ant-input-rtl .ant-input-number-focused {
	border-left-width: 1px !important;
	border-right-width: 0;
}

.ant-input-number-disabled {
	background-color: #f5f5f5;
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
	opacity: 1;
}

.ant-input-number-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}

.ant-input-number-disabled .ant-input-number-input {
	cursor: not-allowed;
}

.ant-input-number-disabled .ant-input-number-handler-wrap,
.ant-input-number-readonly .ant-input-number-handler-wrap {
	display: none;
}

.ant-input-number-input {
	-moz-appearance: textfield !important;
	background-color: transparent;
	border: 0;
	border-radius: 6px;
	height: 34px;
	outline: 0;
	padding: 0 11px;
	text-align: left;
	transition: all 0.3s linear;
	width: 100%;
}

.ant-input-number-input::-webkit-input-placeholder {
	color: #bfbfbf;
}

.ant-input-number-input:-ms-input-placeholder {
	color: #bfbfbf;
}

.ant-input-number-input::placeholder {
	color: #bfbfbf;
}

.ant-input-number-input:-ms-input-placeholder {
	text-overflow: ellipsis;
}

.ant-input-number-input:placeholder-shown {
	text-overflow: ellipsis;
}

.ant-input-number-input[type='number']::-webkit-inner-spin-button,
.ant-input-number-input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.ant-input-number-lg {
	font-size: 16px;
	padding: 0;
}

.ant-input-number-lg input {
	height: 38px;
}

.ant-input-number-sm {
	padding: 0;
}

.ant-input-number-sm input {
	height: 22px;
	padding: 0 7px;
}

.ant-input-number-handler-wrap {
	background: #fff;
	border-left: 1px solid #d9d9d9;
	border-radius: 0 6px 6px 0;
	height: 100%;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	transition: opacity 0.24s linear 0.1s;
	width: 22px;
}

.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner,
.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner {
	font-size: 7px;
	margin-right: 0;
	min-width: auto;
}

.ant-input-number-borderless .ant-input-number-handler-wrap {
	border-left-width: 0;
}

.ant-input-number-handler-wrap:hover .ant-input-number-handler {
	height: 40%;
}

.ant-input-number:hover .ant-input-number-handler-wrap {
	opacity: 1;
}

.ant-input-number-handler-up {
	border-top-right-radius: 6px;
	cursor: pointer;
}

.ant-input-number-handler-up-inner {
	margin-top: -5px;
	text-align: center;
	top: 50%;
}

.ant-input-number-handler-up:hover {
	height: 60% !important;
}

.ant-input-number-handler-down {
	border-bottom-right-radius: 6px;
	border-top: 1px solid #d9d9d9;
	cursor: pointer;
	top: 0;
}

.ant-input-number-handler-down-inner {
	text-align: center;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.ant-input-number-handler-down:hover {
	height: 60% !important;
}

.ant-input-number-borderless .ant-input-number-handler-down {
	border-top-width: 0;
}

.ant-input-number-handler-down-disabled,
.ant-input-number-handler-up-disabled {
	cursor: not-allowed;
}

.ant-input-number-handler-down-disabled:hover .ant-input-number-handler-down-inner,
.ant-input-number-handler-up-disabled:hover .ant-input-number-handler-up-inner {
	color: rgba(0, 0, 0, 0.25);
}

.ant-input-number-borderless {
	box-shadow: none;
}

.ant-input-number-out-of-range input {
	color: #f5222d;
}

.ant-input-number-rtl {
	direction: rtl;
}

.ant-input-number-rtl .ant-input-number-handler-wrap {
	border-left: 0;
	border-radius: 6px 0 0 6px;
	border-right: 1px solid #d9d9d9;
	left: 0;
	right: auto;
}

.ant-input-number-rtl.ant-input-number-borderless .ant-input-number-handler-wrap {
	border-right-width: 0;
}

.ant-input-number-rtl .ant-input-number-input {
	direction: ltr;
	text-align: right;
}

.ant-layout {
	background: #f5f5f5;
	display: flex;
	flex: auto;
	flex-direction: column;
	min-height: 0;
}

.ant-layout,
.ant-layout * {
	box-sizing: border-box;
}

.ant-layout.ant-layout-has-sider {
	flex-direction: row;
}

.ant-layout.ant-layout-has-sider>.ant-layout,
.ant-layout.ant-layout-has-sider>.ant-layout-content {
	width: 0;
}

.ant-layout-footer,
.ant-layout-header {
	flex: 0 0 auto;
}

.ant-layout-header {
	background: #fefefe;
	color: #545454;
	line-height: 72px;
}

.ant-layout-footer {
	background: #fffffe;
	color: #545454;
	font-size: 14px;
	padding: 14px 32px;
}

.ant-layout-content {
	flex: auto;
	min-height: 0;
}

.ant-layout-sider {
	background: #038fde;
	min-width: 0;
	position: relative;
	transition: all 0.2s;
}

.ant-layout-sider-children {
	height: 100%;
	margin-top: -0.1px;
	padding-top: 0.1px;
}

.ant-layout-sider-has-trigger {
	padding-bottom: 30px;
}

.ant-layout-sider-right {
	order: 1;
}

.ant-layout-sider-trigger {
	background: #002140;
	bottom: 0;
	color: #fff;
	cursor: pointer;
	height: 30px;
	line-height: 30px;
	position: fixed;
	text-align: center;
	transition: all 0.2s;
	z-index: 1;
}

.ant-layout-sider-zero-width>* {
	overflow: hidden;
}

.ant-layout-sider-zero-width-trigger {
	background: #038fde;
	border-radius: 0 6px 6px 0;
	color: #fff;
	cursor: pointer;
	font-size: 18px;
	height: 28px;
	line-height: 28px;
	position: absolute;
	right: -36px;
	text-align: center;
	top: 72px;
	transition: background 0.3s ease;
	width: 36px;
	z-index: 1;
}

.ant-layout-sider-zero-width-trigger:after {
	background: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	transition: all 0.3s;
}

.ant-layout-sider-zero-width-trigger:hover:after {
	background: hsla(0, 0%, 100%, 0.1);
}

.ant-layout-sider-zero-width-trigger-right {
	border-radius: 6px 0 0 6px;
	left: -36px;
}

.ant-layout-sider-light {
	background: #fff;
}

.ant-layout-sider-light .ant-layout-sider-trigger,
.ant-layout-sider-light .ant-layout-sider-zero-width-trigger {
	background: #fff;
	color: #545454;
}

.ant-layout-rtl {
	direction: rtl;
}

.ant-list {
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}

.ant-list * {
	outline: none;
}

.ant-list-pagination {
	margin-top: 24px;
	text-align: right;
}

.ant-list-pagination .ant-pagination-options {
	text-align: left;
}

.ant-list-more {
	margin-top: 12px;
	text-align: center;
}

.ant-list-more button {
	padding-left: 32px;
	padding-right: 32px;
}

.ant-list-spin {
	min-height: 40px;
	text-align: center;
}

.ant-list-empty-text {
	color: rgba(0, 0, 0, 0.25);
	font-size: 14px;
	padding: 16px;
	text-align: center;
}

.ant-list-items {
	list-style: none;
	margin: 0;
	padding: 0;
}

.ant-list-item {
	align-items: center;
	color: #545454;
	display: flex;
	justify-content: space-between;
	padding: 12px 0;
}

.ant-list-item-meta {
	align-items: flex-start;
	display: flex;
	flex: 1 1;
	max-width: 100%;
}

.ant-list-item-meta-avatar {
	margin-right: 16px;
}

.ant-list-item-meta-content {
	color: #545454;
	flex: 1 0;
	width: 0;
}

.ant-list-item-meta-title {
	color: #545454;
	font-size: 14px;
	line-height: 1.3;
	margin-bottom: 4px;
}

.ant-list-item-meta-title>a {
	color: #545454;
	transition: all 0.3s;
}

.ant-list-item-meta-title>a:hover {
	color: #038fde;
}

.ant-list-item-meta-description {
	color: #595959;
	font-size: 14px;
	line-height: 1.3;
}

.ant-list-item-action {
	flex: 0 0 auto;
	font-size: 0;
	list-style: none;
	margin-left: 48px;
	padding: 0;
}

.ant-list-item-action>li {
	color: #595959;
	display: inline-block;
	font-size: 14px;
	line-height: 1.3;
	padding: 0 8px;
	position: relative;
	text-align: center;
}

.ant-list-item-action>li:first-child {
	padding-left: 0;
}

.ant-list-item-action-split {
	background-color: #e8e8e8;
	height: 14px;
	margin-top: -7px;
	position: absolute;
	right: 0;
	top: 50%;
	width: 1px;
}

.ant-list-footer,
.ant-list-header {
	background: transparent;
	padding-bottom: 12px;
	padding-top: 12px;
}

.ant-list-empty {
	color: #595959;
	font-size: 12px;
	padding: 16px 0;
	text-align: center;
}

.ant-list-split .ant-list-item {
	border-bottom: 1px solid #e8e8e8;
}

.ant-list-split .ant-list-item:last-child {
	border-bottom: none;
}

.ant-list-split .ant-list-header {
	border-bottom: 1px solid #e8e8e8;
}

.ant-list-split.ant-list-empty .ant-list-footer {
	border-top: 1px solid #e8e8e8;
}

.ant-list-loading .ant-list-spin-nested-loading {
	min-height: 32px;
}

.ant-list-split.ant-list-something-after-last-item .ant-spin-container>.ant-list-items>.ant-list-item:last-child {
	border-bottom: 1px solid #e8e8e8;
}

.ant-list-lg .ant-list-item {
	padding: 16px 24px;
}

.ant-list-sm .ant-list-item {
	padding: 8px 16px;
}

.ant-list-vertical .ant-list-item {
	align-items: normal;
}

.ant-list-vertical .ant-list-item-main {
	display: block;
	flex: 1 1;
}

.ant-list-vertical .ant-list-item-extra {
	margin-left: 40px;
}

.ant-list-vertical .ant-list-item-meta {
	margin-bottom: 16px;
}

.ant-list-vertical .ant-list-item-meta-title {
	color: #535353;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 12px;
}

.ant-list-vertical .ant-list-item-action {
	margin-left: auto;
	margin-top: 16px;
}

.ant-list-vertical .ant-list-item-action>li {
	padding: 0 16px;
}

.ant-list-vertical .ant-list-item-action>li:first-child {
	padding-left: 0;
}

.ant-list-grid .ant-col>.ant-list-item {
	border-bottom: none;
	display: block;
	margin-bottom: 16px;
	max-width: 100%;
	padding-bottom: 0;
	padding-top: 0;
}

.ant-list-item-no-flex {
	display: block;
}

.ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
	float: right;
}

.ant-list-bordered {
	border: 1px solid #d9d9d9;
	border-radius: 6px;
}

.ant-list-bordered .ant-list-footer,
.ant-list-bordered .ant-list-header,
.ant-list-bordered .ant-list-item {
	padding-left: 24px;
	padding-right: 24px;
}

.ant-list-bordered .ant-list-pagination {
	margin: 16px 24px;
}

.ant-list-bordered.ant-list-sm .ant-list-footer,
.ant-list-bordered.ant-list-sm .ant-list-header,
.ant-list-bordered.ant-list-sm .ant-list-item {
	padding: 8px 16px;
}

.ant-list-bordered.ant-list-lg .ant-list-footer,
.ant-list-bordered.ant-list-lg .ant-list-header,
.ant-list-bordered.ant-list-lg .ant-list-item {
	padding: 16px 24px;
}

@media screen and (max-width: 768px) {

	.ant-list-item-action,
	.ant-list-vertical .ant-list-item-extra {
		margin-left: 24px;
	}
}

@media screen and (max-width: 576px) {
	.ant-list-item {
		flex-wrap: wrap;
	}

	.ant-list-item-action {
		margin-left: 12px;
	}

	.ant-list-vertical .ant-list-item {
		flex-wrap: wrap-reverse;
	}

	.ant-list-vertical .ant-list-item-main {
		min-width: 220px;
	}

	.ant-list-vertical .ant-list-item-extra {
		margin: auto auto 16px;
	}
}

.ant-list-rtl {
	direction: rtl;
	text-align: right;
}

.ant-list-rtl .ReactVirtualized__List .ant-list-item {
	direction: rtl;
}

.ant-list-rtl .ant-list-pagination {
	text-align: left;
}

.ant-list-rtl .ant-list-item-meta-avatar {
	margin-left: 16px;
	margin-right: 0;
}

.ant-list-rtl .ant-list-item-action {
	margin-left: 0;
	margin-right: 48px;
}

.ant-list.ant-list-rtl .ant-list-item-action>li:first-child {
	padding-left: 16px;
	padding-right: 0;
}

.ant-list-rtl .ant-list-item-action-split {
	left: 0;
	right: auto;
}

.ant-list-rtl.ant-list-vertical .ant-list-item-extra {
	margin-left: 0;
	margin-right: 40px;
}

.ant-list-rtl.ant-list-vertical .ant-list-item-action {
	margin-right: auto;
}

.ant-list-rtl .ant-list-vertical .ant-list-item-action>li:first-child {
	padding-left: 16px;
	padding-right: 0;
}

.ant-list-rtl .ant-list:not(.ant-list-vertical) .ant-list-item-no-flex .ant-list-item-action {
	float: left;
}

@media screen and (max-width: 768px) {

	.ant-list-rtl .ant-list-item-action,
	.ant-list-rtl .ant-list-vertical .ant-list-item-extra {
		margin-left: 0;
		margin-right: 24px;
	}
}

@media screen and (max-width: 576px) {
	.ant-list-rtl .ant-list-item-action {
		margin-left: 0;
		margin-right: 22px;
	}

	.ant-list-rtl.ant-list-vertical .ant-list-item-extra {
		margin: auto auto 16px;
	}
}

.ant-mentions {
	background-color: #fff;
	background-image: none;
	border: 1px solid #d9d9d9;
	border-radius: 6px;
	box-sizing: border-box;
	color: #545454;
	display: inline-block;
	font-size: 14px;
	font-variant: tabular-nums;
	height: auto;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	min-width: 0;
	overflow: hidden;
	padding: 0;
	position: relative;
	transition: all 0.3s;
	vertical-align: bottom;
	white-space: pre-wrap;
	width: 100%;
}

.ant-mentions::-webkit-input-placeholder {
	color: #bfbfbf;
}

.ant-mentions:-ms-input-placeholder {
	color: #bfbfbf;
}

.ant-mentions::placeholder {
	color: #bfbfbf;
}

.ant-mentions:-ms-input-placeholder {
	text-overflow: ellipsis;
}

.ant-mentions:placeholder-shown {
	text-overflow: ellipsis;
}

.ant-mentions:hover {
	border-color: #28aaeb;
	border-right-width: 1px !important;
}

.ant-input-rtl .ant-mentions:hover {
	border-left-width: 1px !important;
	border-right-width: 0;
}

.ant-mentions-focused,
.ant-mentions:focus {
	border-color: #28aaeb;
	border-right-width: 1px !important;
	box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
	outline: 0;
}

.ant-input-rtl .ant-mentions-focused,
.ant-input-rtl .ant-mentions:focus {
	border-left-width: 1px !important;
	border-right-width: 0;
}

.ant-mentions-disabled {
	background-color: #f5f5f5;
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
	opacity: 1;
}

.ant-mentions-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}

.ant-mentions[disabled] {
	background-color: #f5f5f5;
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
	opacity: 1;
}

.ant-mentions[disabled]:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}

.ant-mentions-borderless,
.ant-mentions-borderless-disabled,
.ant-mentions-borderless-focused,
.ant-mentions-borderless:focus,
.ant-mentions-borderless:hover,
.ant-mentions-borderless[disabled] {
	background-color: transparent;
	border: none;
	box-shadow: none;
}

textarea.ant-mentions {
	height: auto;
	line-height: 1.3;
	max-width: 100%;
	min-height: 36px;
	transition: all 0.3s, height 0s;
	vertical-align: bottom;
}

.ant-mentions-lg {
	font-size: 16px;
	padding: 8.6px 11px;
}

.ant-mentions-sm {
	padding: 1.9px 7px;
}

.ant-mentions-disabled>textarea {
	background-color: #f5f5f5;
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
	opacity: 1;
}

.ant-mentions-disabled>textarea:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}

.ant-mentions-focused {
	border-color: #28aaeb;
	border-right-width: 1px !important;
	box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
	outline: 0;
}

.ant-input-rtl .ant-mentions-focused {
	border-left-width: 1px !important;
	border-right-width: 0;
}

.ant-mentions-measure,
.ant-mentions>textarea {
	word-wrap: break-word;
	direction: inherit;
	font-family: inherit;
	font-size: inherit;
	font-size-adjust: inherit;
	font-stretch: inherit;
	font-style: inherit;
	font-variant: inherit;
	font-weight: inherit;
	letter-spacing: inherit;
	line-height: inherit;
	margin: 0;
	min-height: 34px;
	overflow: inherit;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 7.9px 11px;
	tab-size: inherit;
	text-align: inherit;
	vertical-align: top;
	white-space: inherit;
	word-break: inherit;
}

.ant-mentions>textarea {
	border: none;
	outline: none;
	resize: none;
	width: 100%;
}

.ant-mentions>textarea::-webkit-input-placeholder {
	color: #bfbfbf;
}

.ant-mentions>textarea:-ms-input-placeholder {
	color: #bfbfbf;
}

.ant-mentions>textarea::placeholder {
	color: #bfbfbf;
}

.ant-mentions>textarea:-ms-input-placeholder {
	text-overflow: ellipsis;
}

.ant-mentions>textarea:placeholder-shown {
	text-overflow: ellipsis;
}

.ant-mentions-measure {
	bottom: 0;
	color: transparent;
	left: 0;
	pointer-events: none;
	position: absolute;
	right: 0;
	top: 0;
	z-index: -1;
}

.ant-mentions-measure>span {
	display: inline-block;
	min-height: 1em;
}

.ant-mentions-dropdown {
	background-color: #fff;
	border-radius: 6px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	font-variant: normal;
	left: -9999px;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	outline: none;
	padding: 0;
	position: absolute;
	top: -9999px;
	z-index: 1050;
}

.ant-mentions-dropdown-hidden {
	display: none;
}

.ant-mentions-dropdown-menu {
	list-style: none;
	margin-bottom: 0;
	max-height: 250px;
	outline: none;
	overflow: auto;
	padding-left: 0;
}

.ant-mentions-dropdown-menu-item {
	color: #545454;
	cursor: pointer;
	display: block;
	font-weight: 400;
	line-height: 1.3;
	min-width: 100px;
	overflow: hidden;
	padding: 5px 12px;
	position: relative;
	text-overflow: ellipsis;
	transition: background 0.3s ease;
	white-space: nowrap;
}

.ant-mentions-dropdown-menu-item:hover {
	background-color: #f5f5f5;
}

.ant-mentions-dropdown-menu-item:first-child {
	border-radius: 6px 6px 0 0;
}

.ant-mentions-dropdown-menu-item:last-child {
	border-radius: 0 0 6px 6px;
}

.ant-mentions-dropdown-menu-item-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-mentions-dropdown-menu-item-disabled:hover {
	background-color: #fff;
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-mentions-dropdown-menu-item-selected {
	background-color: #fafafa;
	color: #545454;
	font-weight: 600;
}

.ant-mentions-dropdown-menu-item-active {
	background-color: #f5f5f5;
}

.ant-mentions-rtl {
	direction: rtl;
}

.ant-menu-item-danger.ant-menu-item,
.ant-menu-item-danger.ant-menu-item-active,
.ant-menu-item-danger.ant-menu-item:hover {
	color: #f5222d;
}

.ant-menu-item-danger.ant-menu-item:active {
	background: #fff1f0;
}

.ant-menu-item-danger.ant-menu-item-selected,
.ant-menu-item-danger.ant-menu-item-selected>a,
.ant-menu-item-danger.ant-menu-item-selected>a:hover {
	color: #f5222d;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
	background-color: #fff1f0;
}

.ant-menu-inline .ant-menu-item-danger.ant-menu-item:after {
	border-right-color: #f5222d;
}

.ant-menu-dark .ant-menu-item-danger.ant-menu-item,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item:hover,
.ant-menu-dark .ant-menu-item-danger.ant-menu-item>a {
	color: #f5222d;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-danger.ant-menu-item-selected {
	background-color: #f5222d;
	color: #fa8c15;
}

.ant-menu {
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	box-sizing: border-box;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	line-height: 0;
	list-style: none;
	margin: 0;
	outline: none;
	padding: 0;
	text-align: left;
	transition: background 0.3s, width 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
}

.ant-menu:after,
.ant-menu:before {
	content: '';
	display: table;
}

.ant-menu:after {
	clear: both;
}

.ant-menu ol,
.ant-menu ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.ant-menu-hidden {
	display: none;
}

.ant-menu-item-group-title {
	font-size: 14px;
	height: 1.3;
	padding: 8px 16px;
	transition: all 0.3s;
}

.ant-menu-horizontal .ant-menu-submenu {
	transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-submenu,
.ant-menu-submenu-inline {
	transition: border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-submenu-selected {
	color: #038fde;
}

.ant-menu-submenu .ant-menu-sub {
	cursor: auto;
	transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		padding 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-item a {
	color: #545454;
}

.ant-menu-item a:hover {
	color: #038fde;
}

.ant-menu-item a:before {
	background-color: transparent;
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-menu-item>.ant-badge a {
	color: #545454;
}

.ant-menu-item>.ant-badge a:hover {
	color: #038fde;
}

.ant-menu-item-divider {
	height: 1px;
	line-height: 0;
	overflow: hidden;
}

.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu {
	margin-top: -1px;
}

.ant-menu-item-selected a,
.ant-menu-item-selected a:hover {
	color: #038fde;
}

.ant-menu-vertical-left.ant-menu-sub,
.ant-menu-vertical-right.ant-menu-sub,
.ant-menu-vertical.ant-menu-sub {
	border-right: 0;
	max-height: calc(100vh - 100px);
	min-width: 160px;
	overflow: hidden;
	padding: 0;
}

.ant-menu-vertical-left.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-right.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical.ant-menu-sub:not([class*='-active']) {
	overflow-x: hidden;
	overflow-y: auto;
}

.ant-menu-vertical-left.ant-menu-sub .ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item,
.ant-menu-vertical.ant-menu-sub .ant-menu-item {
	border-right: 0;
	left: 0;
	margin-left: 0;
}

.ant-menu-vertical-left.ant-menu-sub .ant-menu-item:after,
.ant-menu-vertical-right.ant-menu-sub .ant-menu-item:after,
.ant-menu-vertical.ant-menu-sub .ant-menu-item:after {
	border-right: 0;
}

.ant-menu-vertical-left.ant-menu-sub>.ant-menu-item,
.ant-menu-vertical-left.ant-menu-sub>.ant-menu-submenu,
.ant-menu-vertical-right.ant-menu-sub>.ant-menu-item,
.ant-menu-vertical-right.ant-menu-sub>.ant-menu-submenu,
.ant-menu-vertical.ant-menu-sub>.ant-menu-item,
.ant-menu-vertical.ant-menu-sub>.ant-menu-submenu {
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
}

.ant-menu-horizontal.ant-menu-sub {
	min-width: 114px;
}

.ant-menu-horizontal .ant-menu-item,
.ant-menu-horizontal .ant-menu-submenu-title {
	transition: border-color 0.3s, background 0.3s;
}

.ant-menu-item,
.ant-menu-submenu-title {
	cursor: pointer;
	display: block;
	margin: 0;
	position: relative;
	transition: border-color 0.3s, background 0.3s,
		padding 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
	white-space: nowrap;
}

.ant-menu-item .ant-menu-item-icon,
.ant-menu-item .anticon,
.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-submenu-title .anticon {
	font-size: 14px;
	margin-right: 10px;
	min-width: 14px;
	transition: font-size 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
		margin 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}

.ant-menu-item .ant-menu-item-icon+span,
.ant-menu-item .anticon+span,
.ant-menu-submenu-title .ant-menu-item-icon+span,
.ant-menu-submenu-title .anticon+span {
	opacity: 1;
	transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), color 0.3s;
}

.ant-menu-item.ant-menu-item-only-child>.ant-menu-item-icon,
.ant-menu-item.ant-menu-item-only-child>.anticon,
.ant-menu-submenu-title.ant-menu-item-only-child>.ant-menu-item-icon,
.ant-menu-submenu-title.ant-menu-item-only-child>.anticon {
	margin-right: 0;
}

.ant-menu>.ant-menu-item-divider {
	height: 1px;
	line-height: 0;
	margin: 1px 0;
	overflow: hidden;
	padding: 0;
}

.ant-menu-submenu-popup {
	background: transparent;
	border-radius: 6px;
	box-shadow: none;
	position: absolute;
	-webkit-transform-origin: 0 0;
	transform-origin: 0 0;
	z-index: 1050;
}

.ant-menu-submenu-popup:before {
	bottom: 0;
	content: ' ';
	height: 100%;
	left: 0;
	opacity: 0.0001;
	position: absolute;
	right: 0;
	top: -7px;
	width: 100%;
	z-index: -1;
}

.ant-menu-submenu-placement-rightTop:before {
	left: -7px;
	top: 0;
}

.ant-menu-submenu>.ant-menu {
	border-radius: 6px;
}

.ant-menu-submenu>.ant-menu-submenu-title:after {
	transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		-webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-submenu-popup>.ant-menu {
	background-color: #fff;
}

.ant-menu-submenu-arrow,
.ant-menu-submenu-expand-icon {
	color: #545454;
	position: absolute;
	right: 16px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		-webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	width: 10px;
}

.ant-menu-submenu-arrow:after,
.ant-menu-submenu-arrow:before {
	background-color: currentColor;
	border-radius: 2px;
	content: '';
	height: 1.5px;
	position: absolute;
	transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		-webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: background 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		top 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
		-webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	width: 6px;
}

.ant-menu-submenu-arrow:before {
	-webkit-transform: rotate(45deg) translateY(-2.5px);
	transform: rotate(45deg) translateY(-2.5px);
}

.ant-menu-submenu-arrow:after {
	-webkit-transform: rotate(-45deg) translateY(2.5px);
	transform: rotate(-45deg) translateY(2.5px);
}

.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.ant-menu-submenu:hover>.ant-menu-submenu-title>.ant-menu-submenu-expand-icon {
	color: #038fde;
}

.ant-menu-submenu-inline .ant-menu-submenu-arrow:before {
	-webkit-transform: rotate(-45deg) translateX(2.5px);
	transform: rotate(-45deg) translateX(2.5px);
}

.ant-menu-submenu-inline .ant-menu-submenu-arrow:after {
	-webkit-transform: rotate(45deg) translateX(-2.5px);
	transform: rotate(45deg) translateX(-2.5px);
}

.ant-menu-submenu-horizontal .ant-menu-submenu-arrow {
	display: none;
}

.ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
	-webkit-transform: translateY(-2px);
	transform: translateY(-2px);
}

.ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after {
	-webkit-transform: rotate(-45deg) translateX(-2.5px);
	transform: rotate(-45deg) translateX(-2.5px);
}

.ant-menu-submenu-open.ant-menu-submenu-inline>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before {
	-webkit-transform: rotate(45deg) translateX(2.5px);
	transform: rotate(45deg) translateX(2.5px);
}

.ant-menu-horizontal {
	border: 0;
	box-shadow: none;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu {
	margin: -1px 20px 0;
	padding: 0;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover {
	border-bottom: 2px solid #038fde;
	color: #038fde;
}

.ant-menu-horizontal>.ant-menu-item,
.ant-menu-horizontal>.ant-menu-submenu {
	display: inline-block;
	position: relative;
	top: 1px;
	vertical-align: bottom;
}

.ant-menu-horizontal>.ant-menu-submenu>.ant-menu-submenu-title {
	padding: 0;
}

.ant-menu-horizontal>.ant-menu-item a {
	color: #545454;
}

.ant-menu-horizontal>.ant-menu-item a:hover {
	color: #038fde;
}

.ant-menu-horizontal>.ant-menu-item a:before {
	bottom: -2px;
}

.ant-menu-horizontal>.ant-menu-item-selected a {
	color: #038fde;
}

.ant-menu-horizontal:after {
	clear: both;
	content: '\20';
	display: block;
	height: 0;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-right .ant-menu-item {
	position: relative;
}

.ant-menu-inline .ant-menu-item:after,
.ant-menu-vertical .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after {
	bottom: 0;
	content: '';
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	-webkit-transform: scaleY(0.0001);
	transform: scaleY(0.0001);
	transition: opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
		-webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
	transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
		opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
	transition: transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
		opacity 0.15s cubic-bezier(0.215, 0.61, 0.355, 1),
		-webkit-transform 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-vertical-right .ant-menu-submenu-title {
	height: 42px;
	line-height: 42px;
	margin-bottom: 4px;
	margin-top: 4px;
	overflow: hidden;
	padding: 0 16px;
	text-overflow: ellipsis;
}

.ant-menu-inline .ant-menu-submenu,
.ant-menu-vertical .ant-menu-submenu,
.ant-menu-vertical-left .ant-menu-submenu,
.ant-menu-vertical-right .ant-menu-submenu {
	padding-bottom: 0.02px;
}

.ant-menu-inline .ant-menu-item:not(:last-child),
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child) {
	margin-bottom: 8px;
}

.ant-menu-inline>.ant-menu-item,
.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-left>.ant-menu-item,
.ant-menu-vertical-left>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical-right>.ant-menu-item,
.ant-menu-vertical-right>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-vertical>.ant-menu-item,
.ant-menu-vertical>.ant-menu-submenu>.ant-menu-submenu-title {
	height: 42px;
	line-height: 42px;
}

.ant-menu-vertical .ant-menu-submenu-title {
	padding-right: 34px;
}

.ant-menu-inline {
	width: 100%;
}

.ant-menu-inline .ant-menu-item-selected:after,
.ant-menu-inline .ant-menu-selected:after {
	opacity: 1;
	-webkit-transform: scaleY(1);
	transform: scaleY(1);
	transition: opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
		-webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
		opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
	transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
		opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1),
		-webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
	width: calc(100% + 1px);
}

.ant-menu-inline .ant-menu-submenu-title {
	padding-right: 34px;
}

.ant-menu-inline-collapsed>.ant-menu-item,
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item,
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title,
.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
	left: 0;
	padding: 0 calc(50% - 8px);
	text-overflow: clip;
}

.ant-menu-inline-collapsed>.ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-submenu-arrow {
	display: none;
}

.ant-menu-inline-collapsed>.ant-menu-item .ant-menu-item-icon,
.ant-menu-inline-collapsed>.ant-menu-item .anticon,
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .ant-menu-item-icon,
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .anticon,
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .anticon,
.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .anticon {
	font-size: 16px;
	line-height: 42px;
	margin: 0;
}

.ant-menu-inline-collapsed>.ant-menu-item .ant-menu-item-icon+span,
.ant-menu-inline-collapsed>.ant-menu-item .anticon+span,
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .ant-menu-item-icon+span,
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .anticon+span,
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon+span,
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-submenu>.ant-menu-submenu-title .anticon+span,
.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-item-icon+span,
.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .anticon+span {
	display: inline-block;
	max-width: 0;
	opacity: 0;
}

.ant-menu-inline-collapsed .ant-menu-item-icon,
.ant-menu-inline-collapsed .anticon {
	display: inline-block;
}

.ant-menu-inline-collapsed-tooltip {
	pointer-events: none;
}

.ant-menu-inline-collapsed-tooltip .ant-menu-item-icon,
.ant-menu-inline-collapsed-tooltip .anticon {
	display: none;
}

.ant-menu-inline-collapsed .ant-menu-item-group-title {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ant-menu-item-group-list {
	margin: 0;
	padding: 0;
}

.ant-menu-root.ant-menu-inline,
.ant-menu-root.ant-menu-vertical,
.ant-menu-root.ant-menu-vertical-left,
.ant-menu-root.ant-menu-vertical-right {
	box-shadow: none;
}

.ant-menu-root.ant-menu-inline-collapsed .ant-menu-item>.ant-menu-inline-collapsed-noicon,
.ant-menu-root.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title>.ant-menu-inline-collapsed-noicon {
	font-size: 16px;
	text-align: center;
}

.ant-menu-sub.ant-menu-inline {
	background: #fafafa;
	border-radius: 0;
	box-shadow: none;
	padding: 0;
}

.ant-menu-sub.ant-menu-inline>.ant-menu-item,
.ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
	list-style-position: inside;
	list-style-type: disc;
}

.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
	padding-left: 32px;
}

.ant-menu-item-disabled,
.ant-menu-submenu-disabled {
	background: none;
	border-color: transparent !important;
	cursor: not-allowed;
}

.ant-menu-item-disabled a,
.ant-menu-submenu-disabled a {
	color: rgba(0, 0, 0, 0.25) !important;
	pointer-events: none;
}

.ant-menu-item-disabled>.ant-menu-submenu-title,
.ant-menu-submenu-disabled>.ant-menu-submenu-title {
	cursor: not-allowed;
}

.ant-menu-item-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-item-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before,
.ant-menu-submenu-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-submenu-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before {
	background: rgba(0, 0, 0, 0.25) !important;
}

.ant-layout-header .ant-menu {
	line-height: inherit;
}

.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu.ant-menu-dark .ant-menu-sub {
	background: #038fde;
	color: #038fdd;
}

.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow {
	opacity: 0.45;
	transition: all 0.3s;
}

.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
	background: #038fdd;
}

.ant-menu-dark.ant-menu-submenu-popup {
	background: transparent;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
	background: #000c17;
}

.ant-menu-dark.ant-menu-horizontal {
	border-bottom: 0;
}

.ant-menu-dark.ant-menu-horizontal>.ant-menu-item,
.ant-menu-dark.ant-menu-horizontal>.ant-menu-submenu {
	border-bottom: 0;
	margin-top: 0;
	padding: 0 20px;
	top: 0;
}

.ant-menu-dark.ant-menu-horizontal>.ant-menu-item:hover {
	background-color: #fff;
}

.ant-menu-dark.ant-menu-horizontal>.ant-menu-item>a:before {
	bottom: 0;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item>a,
.ant-menu-dark .ant-menu-item>span>a {
	color: #038fdd;
}

.ant-menu-dark.ant-menu-inline,
.ant-menu-dark.ant-menu-vertical,
.ant-menu-dark.ant-menu-vertical-left,
.ant-menu-dark.ant-menu-vertical-right {
	border-right: 0;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-vertical .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item {
	border-right: 0;
	left: 0;
	margin-left: 0;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item:after,
.ant-menu-dark.ant-menu-vertical .ant-menu-item:after,
.ant-menu-dark.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-dark.ant-menu-vertical-right .ant-menu-item:after {
	border-right: 0;
}

.ant-menu-dark.ant-menu-inline .ant-menu-item,
.ant-menu-dark.ant-menu-inline .ant-menu-submenu-title {
	width: 100%;
}

.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-title:hover {
	background-color: transparent;
	color: #fa8c15;
}

.ant-menu-dark .ant-menu-item-active>a,
.ant-menu-dark .ant-menu-item-active>span>a,
.ant-menu-dark .ant-menu-item:hover>a,
.ant-menu-dark .ant-menu-item:hover>span>a,
.ant-menu-dark .ant-menu-submenu-active>a,
.ant-menu-dark .ant-menu-submenu-active>span>a,
.ant-menu-dark .ant-menu-submenu-open>a,
.ant-menu-dark .ant-menu-submenu-open>span>a,
.ant-menu-dark .ant-menu-submenu-selected>a,
.ant-menu-dark .ant-menu-submenu-selected>span>a,
.ant-menu-dark .ant-menu-submenu-title:hover>a,
.ant-menu-dark .ant-menu-submenu-title:hover>span>a {
	color: #fa8c15;
}

.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-open>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow {
	opacity: 1;
}

.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-open>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-open>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before {
	background: #fa8c15;
}

.ant-menu-dark .ant-menu-item:hover {
	background-color: transparent;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: #fff;
}

.ant-menu-dark .ant-menu-item-selected,
.ant-menu-dark .ant-menu-item-selected:after {
	border-right: 0;
}

.ant-menu-dark .ant-menu-item-selected>a,
.ant-menu-dark .ant-menu-item-selected>a:hover,
.ant-menu-dark .ant-menu-item-selected>span>a,
.ant-menu-dark .ant-menu-item-selected>span>a:hover {
	color: #fa8c15;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon+span,
.ant-menu-dark .ant-menu-item-selected .anticon,
.ant-menu-dark .ant-menu-item-selected .anticon+span {
	color: #fff;
}

.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-item-selected {
	background-color: #fff;
}

.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-item-disabled>a,
.ant-menu-dark .ant-menu-item-disabled>span>a,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-submenu-disabled>a,
.ant-menu-dark .ant-menu-submenu-disabled>span>a {
	color: hsla(0, 0%, 100%, 0.35) !important;
	opacity: 0.8;
}

.ant-menu-dark .ant-menu-item-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-disabled>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before {
	background: hsla(0, 0%, 100%, 0.35) !important;
}

.ant-menu.ant-menu-rtl {
	direction: rtl;
	text-align: right;
}

.ant-menu-rtl .ant-menu-item-group-title {
	text-align: right;
}

.ant-menu-rtl.ant-menu-inline,
.ant-menu-rtl.ant-menu-vertical {
	border-left: 1px solid #e8e8e8;
	border-right: none;
}

.ant-menu-rtl.ant-menu-dark.ant-menu-inline,
.ant-menu-rtl.ant-menu-dark.ant-menu-vertical {
	border-left: none;
}

.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub>.ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left.ant-menu-sub>.ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub>.ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right.ant-menu-sub>.ant-menu-submenu,
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub>.ant-menu-item,
.ant-menu-rtl.ant-menu-vertical.ant-menu-sub>.ant-menu-submenu {
	-webkit-transform-origin: top right;
	transform-origin: top right;
}

.ant-menu-rtl .ant-menu-item .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item .anticon,
.ant-menu-rtl .ant-menu-submenu-title .ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title .anticon {
	margin-left: 10px;
	margin-right: auto;
}

.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child>.ant-menu-item-icon,
.ant-menu-rtl .ant-menu-item.ant-menu-item-only-child>.anticon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child>.ant-menu-item-icon,
.ant-menu-rtl .ant-menu-submenu-title.ant-menu-item-only-child>.anticon {
	margin-left: 0;
}

.ant-menu-submenu-rtl.ant-menu-submenu-popup {
	-webkit-transform-origin: 100% 0;
	transform-origin: 100% 0;
}

.ant-menu-rtl .ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-menu-rtl .ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow {
	left: 16px;
	right: auto;
}

.ant-menu-rtl .ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-rtl .ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-rtl .ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow:before {
	-webkit-transform: rotate(-45deg) translateY(-2px);
	transform: rotate(-45deg) translateY(-2px);
}

.ant-menu-rtl .ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-rtl .ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-rtl .ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow:after {
	-webkit-transform: rotate(45deg) translateY(2px);
	transform: rotate(45deg) translateY(2px);
}

.ant-menu-rtl.ant-menu-inline .ant-menu-item:after,
.ant-menu-rtl.ant-menu-vertical .ant-menu-item:after,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item:after {
	left: 0;
	right: auto;
}

.ant-menu-rtl.ant-menu-inline .ant-menu-item,
.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-item,
.ant-menu-rtl.ant-menu-vertical-right .ant-menu-submenu-title {
	text-align: right;
}

.ant-menu-rtl.ant-menu-inline .ant-menu-submenu-title {
	padding-left: 34px;
	padding-right: 0;
}

.ant-menu-rtl.ant-menu-vertical .ant-menu-submenu-title {
	padding-left: 34px;
	padding-right: 16px;
}

.ant-menu-rtl.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-submenu-title {
	padding: 0 calc(50% - 8px);
}

.ant-menu-rtl .ant-menu-item-group-list .ant-menu-item,
.ant-menu-rtl .ant-menu-item-group-list .ant-menu-submenu-title {
	padding: 0 28px 0 16px;
}

.ant-menu-sub.ant-menu-inline {
	border: 0;
}

.ant-menu-rtl.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
	padding-left: 0;
	padding-right: 32px;
}

.ant-message {
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	left: 0;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
	pointer-events: none;
	position: fixed;
	top: 8px;
	width: 100%;
	z-index: 1010;
}

.ant-message-notice {
	padding: 8px;
	text-align: center;
}

.ant-message-notice-content {
	background: #fff;
	border-radius: 6px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	display: inline-block;
	padding: 10px 16px;
	pointer-events: all;
}

.ant-message-success .anticon {
	color: #52c41a;
}

.ant-message-error .anticon {
	color: #f5222d;
}

.ant-message-warning .anticon {
	color: #fa8c16;
}

.ant-message-info .anticon,
.ant-message-loading .anticon {
	color: #1890ff;
}

.ant-message .anticon {
	font-size: 16px;
	margin-right: 8px;
	position: relative;
	top: 1px;
}

.ant-message-notice.move-up-leave.move-up-leave-active {
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-animation-name: MessageMoveOut;
	animation-name: MessageMoveOut;
}

@-webkit-keyframes MessageMoveOut {
	0% {
		max-height: 150px;
		opacity: 1;
		padding: 8px;
	}

	to {
		max-height: 0;
		opacity: 0;
		padding: 0;
	}
}

@keyframes MessageMoveOut {
	0% {
		max-height: 150px;
		opacity: 1;
		padding: 8px;
	}

	to {
		max-height: 0;
		opacity: 0;
		padding: 0;
	}
}

.ant-message-rtl,
.ant-message-rtl span {
	direction: rtl;
}

.ant-message-rtl .anticon {
	margin-left: 8px;
	margin-right: 0;
}

.ant-modal {
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0 auto;
	max-width: calc(100vw - 32px);
	padding: 0 0 24px;
	pointer-events: none;
	position: relative;
	top: 100px;
	width: auto;
}

.ant-modal.zoom-appear,
.ant-modal.zoom-enter {
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	opacity: 0;
	-webkit-transform: none;
	transform: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ant-modal-mask {
	background-color: rgba(0, 0, 0, 0.45);
	bottom: 0;
	height: 100%;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1080;
}

.ant-modal-mask-hidden {
	display: none;
}

.ant-modal-wrap {
	-webkit-overflow-scrolling: touch;
	bottom: 0;
	left: 0;
	outline: 0;
	overflow: auto;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 1080;
}

.ant-modal-title {
	word-wrap: break-word;
	color: #535353;
	font-size: 16px;
	font-weight: 500;
	line-height: 22px;
	margin: 0;
}

.ant-modal-content {
	background-clip: padding-box;
	background-color: #fff;
	border: 0;
	border-radius: 6px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	pointer-events: auto;
	position: relative;
}

.ant-modal-close {
	background: transparent;
	border: 0;
	color: #595959;
	cursor: pointer;
	font-weight: 700;
	line-height: 1;
	outline: 0;
	padding: 0;
	position: absolute;
	right: 0;
	text-decoration: none;
	top: 0;
	transition: color 0.3s;
	z-index: 10;
}

.ant-modal-close-x {
	text-rendering: auto;
	display: block;
	font-size: 16px;
	font-style: normal;
	height: 56px;
	line-height: 56px;
	text-align: center;
	text-transform: none;
	width: 56px;
}

.ant-modal-close:focus,
.ant-modal-close:hover {
	color: rgba(0, 0, 0, 0.75);
	text-decoration: none;
}

.ant-modal-header {
	background: #fff;
	border-bottom: 1px solid #e8e8e8;
	border-radius: 6px 6px 0 0;
	color: #545454;
	padding: 16px 24px;
}

.ant-modal-body {
	word-wrap: break-word;
	font-size: 14px;
	line-height: 1.3;
	padding: 24px;
}

.ant-modal-footer {
	background: transparent;
	border-radius: 0 0 6px 6px;
	border-top: 1px solid #e8e8e8;
	padding: 10px 16px;
	text-align: right;
}

.ant-modal-footer .ant-btn+.ant-btn {
	margin-bottom: 0;
	margin-left: 8px;
}

.ant-modal-open {
	overflow: hidden;
}

.ant-modal-centered {
	text-align: center;
}

.ant-modal-centered:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
	width: 0;
}

.ant-modal-centered .ant-modal {
	display: inline-block;
	text-align: left;
	top: 0;
	vertical-align: middle;
}

@media (max-width: 767px) {
	.ant-modal {
		margin: 8px auto;
		max-width: calc(100vw - 16px);
	}

	.ant-modal-centered .ant-modal {
		flex: 1 1;
	}
}

.ant-modal-confirm .ant-modal-header {
	display: none;
}

.ant-modal-confirm .ant-modal-body {
	padding: 32px 32px 24px;
}

.ant-modal-confirm-body-wrapper:before {
	content: '';
	display: table;
}

.ant-modal-confirm-body-wrapper:after {
	clear: both;
	content: '';
	display: table;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
	color: #535353;
	display: block;
	font-size: 16px;
	font-weight: 500;
	line-height: 1.4;
	overflow: hidden;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
	color: #545454;
	font-size: 14px;
	margin-top: 8px;
}

.ant-modal-confirm-body>.anticon {
	float: left;
	font-size: 22px;
	margin-right: 16px;
}

.ant-modal-confirm-body>.anticon+.ant-modal-confirm-title+.ant-modal-confirm-content {
	margin-left: 38px;
}

.ant-modal-confirm .ant-modal-confirm-btns {
	float: right;
	margin-top: 24px;
}

.ant-modal-confirm .ant-modal-confirm-btns .ant-btn+.ant-btn {
	margin-bottom: 0;
	margin-left: 8px;
}

.ant-modal-confirm-error .ant-modal-confirm-body>.anticon {
	color: #f5222d;
}

.ant-modal-confirm-confirm .ant-modal-confirm-body>.anticon,
.ant-modal-confirm-warning .ant-modal-confirm-body>.anticon {
	color: #fa8c16;
}

.ant-modal-confirm-info .ant-modal-confirm-body>.anticon {
	color: #1890ff;
}

.ant-modal-confirm-success .ant-modal-confirm-body>.anticon {
	color: #52c41a;
}

.ant-modal-wrap-rtl {
	direction: rtl;
}

.ant-modal-wrap-rtl .ant-modal-close {
	left: 0;
	right: auto;
}

.ant-modal-wrap-rtl .ant-modal-footer {
	text-align: left;
}

.ant-modal-wrap-rtl .ant-modal-footer .ant-btn+.ant-btn {
	margin-left: 0;
	margin-right: 8px;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body {
	direction: rtl;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body>.anticon {
	float: right;
	margin-left: 16px;
	margin-right: 0;
}

.ant-modal-wrap-rtl .ant-modal-confirm-body>.anticon+.ant-modal-confirm-title+.ant-modal-confirm-content {
	margin-left: 0;
	margin-right: 38px;
}

.ant-modal-wrap-rtl .ant-modal-confirm-btns {
	float: left;
}

.ant-modal-wrap-rtl .ant-modal-confirm-btns .ant-btn+.ant-btn {
	margin-left: 0;
	margin-right: 8px;
}

.ant-modal-wrap-rtl.ant-modal-centered .ant-modal {
	text-align: right;
}

.ant-notification {
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0 24px 0 0;
	padding: 0;
	position: fixed;
	z-index: 1090;
}

.ant-notification-bottomLeft,
.ant-notification-topLeft {
	margin-left: 24px;
	margin-right: 0;
}

.ant-notification-bottomLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-bottomLeft .ant-notification-fade-enter.ant-notification-fade-enter-active,
.ant-notification-topLeft .ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-topLeft .ant-notification-fade-enter.ant-notification-fade-enter-active {
	-webkit-animation-name: NotificationLeftFadeIn;
	animation-name: NotificationLeftFadeIn;
}

.ant-notification-close-icon {
	cursor: pointer;
	font-size: 14px;
}

.ant-notification-hook-holder,
.ant-notification-notice {
	word-wrap: break-word;
	background: #fff;
	border-radius: 6px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	margin-bottom: 16px;
	margin-left: auto;
	max-width: calc(100vw - 48px);
	overflow: hidden;
	position: relative;
	width: 384px;
}

.ant-notification-bottomLeft .ant-notification-hook-holder,
.ant-notification-bottomLeft .ant-notification-notice,
.ant-notification-topLeft .ant-notification-hook-holder,
.ant-notification-topLeft .ant-notification-notice {
	margin-left: 0;
	margin-right: auto;
}

.ant-notification-hook-holder>.ant-notification-notice {
	box-shadow: none;
	margin-bottom: 0;
}

.ant-notification-notice {
	line-height: 1.3;
	padding: 16px 24px;
}

.ant-notification-notice-message {
	color: #535353;
	font-size: 16px;
	line-height: 24px;
	margin-bottom: 8px;
}

.ant-notification-notice-message-single-line-auto-margin {
	background-color: transparent;
	display: block;
	max-width: 4px;
	pointer-events: none;
	width: calc(264px - 100%);
}

.ant-notification-notice-message-single-line-auto-margin:before {
	content: '';
	display: block;
}

.ant-notification-notice-description {
	font-size: 14px;
}

.ant-notification-notice-closable .ant-notification-notice-message {
	padding-right: 24px;
}

.ant-notification-notice-with-icon .ant-notification-notice-message {
	font-size: 16px;
	margin-bottom: 4px;
	margin-left: 48px;
}

.ant-notification-notice-with-icon .ant-notification-notice-description {
	font-size: 14px;
	margin-left: 48px;
}

.ant-notification-notice-icon {
	font-size: 24px;
	line-height: 24px;
	margin-left: 4px;
	position: absolute;
}

.anticon.ant-notification-notice-icon-success {
	color: #52c41a;
}

.anticon.ant-notification-notice-icon-info {
	color: #1890ff;
}

.anticon.ant-notification-notice-icon-warning {
	color: #fa8c16;
}

.anticon.ant-notification-notice-icon-error {
	color: #f5222d;
}

.ant-notification-notice-close {
	color: #595959;
	outline: none;
	position: absolute;
	right: 22px;
	top: 16px;
}

.ant-notification-notice-close:hover {
	color: #353535;
}

.ant-notification-notice-btn {
	float: right;
	margin-top: 16px;
}

.ant-notification .notification-fade-effect {
	-webkit-animation-duration: 0.24s;
	animation-duration: 0.24s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-notification-fade-appear,
.ant-notification-fade-enter {
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
	opacity: 0;
}

.ant-notification-fade-appear,
.ant-notification-fade-enter,
.ant-notification-fade-leave {
	-webkit-animation-duration: 0.24s;
	animation-duration: 0.24s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
	animation-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-notification-fade-leave {
	-webkit-animation-duration: 0.2s;
	animation-duration: 0.2s;
	-webkit-animation-play-state: paused;
	animation-play-state: paused;
}

.ant-notification-fade-appear.ant-notification-fade-appear-active,
.ant-notification-fade-enter.ant-notification-fade-enter-active {
	-webkit-animation-name: NotificationFadeIn;
	animation-name: NotificationFadeIn;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}

.ant-notification-fade-leave.ant-notification-fade-leave-active {
	-webkit-animation-name: NotificationFadeOut;
	animation-name: NotificationFadeOut;
	-webkit-animation-play-state: running;
	animation-play-state: running;
}

@-webkit-keyframes NotificationFadeIn {
	0% {
		left: 384px;
		opacity: 0;
	}

	to {
		left: 0;
		opacity: 1;
	}
}

@keyframes NotificationFadeIn {
	0% {
		left: 384px;
		opacity: 0;
	}

	to {
		left: 0;
		opacity: 1;
	}
}

@-webkit-keyframes NotificationLeftFadeIn {
	0% {
		opacity: 0;
		right: 384px;
	}

	to {
		opacity: 1;
		right: 0;
	}
}

@keyframes NotificationLeftFadeIn {
	0% {
		opacity: 0;
		right: 384px;
	}

	to {
		opacity: 1;
		right: 0;
	}
}

@-webkit-keyframes NotificationFadeOut {
	0% {
		margin-bottom: 16px;
		max-height: 150px;
		opacity: 1;
	}

	to {
		margin-bottom: 0;
		max-height: 0;
		opacity: 0;
		padding-bottom: 0;
		padding-top: 0;
	}
}

@keyframes NotificationFadeOut {
	0% {
		margin-bottom: 16px;
		max-height: 150px;
		opacity: 1;
	}

	to {
		margin-bottom: 0;
		max-height: 0;
		opacity: 0;
		padding-bottom: 0;
		padding-top: 0;
	}
}

.ant-notification-rtl {
	direction: rtl;
}

.ant-notification-rtl .ant-notification-notice-closable .ant-notification-notice-message {
	padding-left: 24px;
	padding-right: 0;
}

.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-description,
.ant-notification-rtl .ant-notification-notice-with-icon .ant-notification-notice-message {
	margin-left: 0;
	margin-right: 48px;
}

.ant-notification-rtl .ant-notification-notice-icon {
	margin-left: 0;
	margin-right: 4px;
}

.ant-notification-rtl .ant-notification-notice-close {
	left: 22px;
	right: auto;
}

.ant-notification-rtl .ant-notification-notice-btn {
	float: left;
}

.ant-page-header {
	background-color: #fff;
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 16px 24px;
	position: relative;
}

.ant-page-header-ghost {
	background-color: inherit;
}

.ant-page-header.has-breadcrumb {
	padding-top: 12px;
}

.ant-page-header.has-footer {
	padding-bottom: 0;
}

.ant-page-header-back {
	font-size: 16px;
	line-height: 1;
	margin-right: 16px;
}

.ant-page-header-back-button {
	color: #038fde;
	color: #000;
	cursor: pointer;
	outline: none;
	text-decoration: none;
	transition: color 0.3s;
}

.ant-page-header-back-button:focus,
.ant-page-header-back-button:hover {
	color: #28aaeb;
}

.ant-page-header-back-button:active {
	color: #006eb8;
}

.ant-page-header .ant-divider-vertical {
	height: 14px;
	margin: 0 12px;
	vertical-align: middle;
}

.ant-breadcrumb+.ant-page-header-heading {
	margin-top: 8px;
}

.ant-page-header-heading {
	display: flex;
	justify-content: space-between;
}

.ant-page-header-heading-left {
	align-items: center;
	display: flex;
	margin: 4px 0;
	overflow: hidden;
}

.ant-page-header-heading-title {
	color: #535353;
	font-size: 20px;
	font-weight: 600;
	line-height: 32px;
	margin-bottom: 0;
	margin-right: 12px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ant-page-header-heading .ant-avatar {
	margin-right: 12px;
}

.ant-page-header-heading-sub-title {
	color: #595959;
	font-size: 14px;
	line-height: 1.3;
	margin-right: 12px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ant-page-header-heading-extra {
	margin: 4px 0;
	white-space: nowrap;
}

.ant-page-header-heading-extra>* {
	margin-left: 12px;
	white-space: unset;
}

.ant-page-header-heading-extra> :first-child {
	margin-left: 0;
}

.ant-page-header-content {
	padding-top: 12px;
}

.ant-page-header-footer {
	margin-top: 16px;
}

.ant-page-header-footer .ant-tabs>.ant-tabs-nav {
	margin: 0;
}

.ant-page-header-footer .ant-tabs>.ant-tabs-nav:before {
	border: none;
}

.ant-page-header-footer .ant-tabs .ant-tabs-tab {
	font-size: 16px;
	padding-bottom: 8px;
	padding-top: 8px;
}

.ant-page-header-compact .ant-page-header-heading {
	flex-wrap: wrap;
}

.ant-page-header-rtl {
	direction: rtl;
}

.ant-page-header-rtl .ant-page-header-back {
	float: right;
	margin-left: 16px;
	margin-right: 0;
}

.ant-page-header-rtl .ant-page-header-heading .ant-avatar,
.ant-page-header-rtl .ant-page-header-heading-title {
	margin-left: 12px;
	margin-right: 0;
}

.ant-page-header-rtl .ant-page-header-heading-sub-title {
	float: right;
	margin-left: 12px;
	margin-right: 0;
}

.ant-page-header-rtl .ant-page-header-heading-tags {
	float: right;
}

.ant-page-header-rtl .ant-page-header-heading-extra {
	float: left;
}

.ant-page-header-rtl .ant-page-header-heading-extra>* {
	margin-left: 0;
	margin-right: 12px;
}

.ant-page-header-rtl .ant-page-header-heading-extra> :first-child {
	margin-right: 0;
}

.ant-page-header-rtl .ant-page-header-footer .ant-tabs-bar .ant-tabs-nav {
	float: right;
}

.ant-pagination {
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
}

.ant-pagination,
.ant-pagination ol,
.ant-pagination ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

.ant-pagination:after {
	clear: both;
	content: ' ';
	display: block;
	height: 0;
	overflow: hidden;
	visibility: hidden;
}

.ant-pagination-item,
.ant-pagination-total-text {
	display: inline-block;
	height: 32px;
	line-height: 30px;
	margin-right: 8px;
	vertical-align: middle;
}

.ant-pagination-item {
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	font-family: NoirPro, sans-serif;
	list-style: none;
	min-width: 32px;
	outline: 0;
	text-align: center;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ant-pagination-item a {
	color: #545454;
	display: block;
	padding: 0 6px;
	transition: none;
}

.ant-pagination-item a:hover {
	text-decoration: none;
}

.ant-pagination-item:focus-visible,
.ant-pagination-item:hover {
	border-color: #038fde;
	transition: all 0.3s;
}

.ant-pagination-item:focus-visible a,
.ant-pagination-item:hover a {
	color: #038fde;
}

.ant-pagination-item-active {
	background: #fff;
	border-color: #038fde;
	font-weight: 500;
}

.ant-pagination-item-active a {
	color: #038fde;
}

.ant-pagination-item-active:focus-visible,
.ant-pagination-item-active:hover {
	border-color: #28aaeb;
}

.ant-pagination-item-active:focus-visible a,
.ant-pagination-item-active:hover a {
	color: #28aaeb;
}

.ant-pagination-jump-next,
.ant-pagination-jump-prev {
	outline: 0;
}

.ant-pagination-jump-next .ant-pagination-item-container,
.ant-pagination-jump-prev .ant-pagination-item-container {
	position: relative;
}

.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
	color: #038fde;
	font-size: 12px;
	letter-spacing: -1px;
	opacity: 0;
	transition: all 0.2s;
}

.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon-svg,
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon-svg {
	bottom: 0;
	left: 0;
	margin: auto;
	right: 0;
	top: 0;
}

.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
	bottom: 0;
	color: rgba(0, 0, 0, 0.25);
	display: block;
	font-family: Arial, Helvetica, sans-serif;
	left: 0;
	letter-spacing: 2px;
	margin: auto;
	opacity: 1;
	position: absolute;
	right: 0;
	text-align: center;
	text-indent: 0.13em;
	top: 0;
	transition: all 0.2s;
}

.ant-pagination-jump-next:focus-visible .ant-pagination-item-link-icon,
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon,
.ant-pagination-jump-prev:focus-visible .ant-pagination-item-link-icon,
.ant-pagination-jump-prev:hover .ant-pagination-item-link-icon {
	opacity: 1;
}

.ant-pagination-jump-next:focus-visible .ant-pagination-item-ellipsis,
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev:focus-visible .ant-pagination-item-ellipsis,
.ant-pagination-jump-prev:hover .ant-pagination-item-ellipsis {
	opacity: 0;
}

.ant-pagination-jump-next,
.ant-pagination-jump-prev,
.ant-pagination-prev {
	margin-right: 8px;
}

.ant-pagination-jump-next,
.ant-pagination-jump-prev,
.ant-pagination-next,
.ant-pagination-prev {
	border-radius: 6px;
	color: #545454;
	cursor: pointer;
	display: inline-block;
	font-family: NoirPro, sans-serif;
	height: 32px;
	line-height: 32px;
	list-style: none;
	min-width: 32px;
	text-align: center;
	transition: all 0.3s;
	vertical-align: middle;
}

.ant-pagination-next,
.ant-pagination-prev {
	font-family: Arial, Helvetica, sans-serif;
	outline: 0;
}

.ant-pagination-next button,
.ant-pagination-prev button {
	color: #545454;
	cursor: pointer;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ant-pagination-next:hover button,
.ant-pagination-prev:hover button {
	border-color: #28aaeb;
}

.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-prev .ant-pagination-item-link {
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 6px;
	display: block;
	font-size: 12px;
	height: 100%;
	outline: none;
	padding: 0;
	text-align: center;
	transition: all 0.3s;
	width: 100%;
}

.ant-pagination-next:focus-visible .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link,
.ant-pagination-prev:focus-visible .ant-pagination-item-link,
.ant-pagination-prev:hover .ant-pagination-item-link {
	border-color: #038fde;
	color: #038fde;
}

.ant-pagination-disabled,
.ant-pagination-disabled:focus-visible,
.ant-pagination-disabled:hover {
	cursor: not-allowed;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:focus-visible .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
	border-color: #d9d9d9;
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-pagination-slash {
	margin: 0 10px 0 5px;
}

.ant-pagination-options {
	display: inline-block;
	margin-left: 16px;
	vertical-align: middle;
}

@media (-ms-high-contrast: none) {

	.ant-pagination-options,
	.ant-pagination-options ::-ms-backdrop {
		vertical-align: top;
	}
}

.ant-pagination-options-size-changer.ant-select {
	display: inline-block;
	width: auto;
}

.ant-pagination-options-quick-jumper {
	display: inline-block;
	height: 36px;
	line-height: 36px;
	margin-left: 8px;
	vertical-align: top;
}

.ant-pagination-options-quick-jumper input {
	background-color: #fff;
	background-image: none;
	border: 1px solid #d9d9d9;
	border-radius: 6px;
	color: #545454;
	display: inline-block;
	font-size: 14px;
	height: 36px;
	line-height: 1.3;
	margin: 0 8px;
	min-width: 0;
	padding: 7.9px 11px;
	position: relative;
	transition: all 0.3s;
	width: 100%;
	width: 50px;
}

.ant-pagination-options-quick-jumper input::-webkit-input-placeholder {
	color: #bfbfbf;
}

.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
	color: #bfbfbf;
}

.ant-pagination-options-quick-jumper input::placeholder {
	color: #bfbfbf;
}

.ant-pagination-options-quick-jumper input:-ms-input-placeholder {
	text-overflow: ellipsis;
}

.ant-pagination-options-quick-jumper input:placeholder-shown {
	text-overflow: ellipsis;
}

.ant-pagination-options-quick-jumper input:hover {
	border-color: #28aaeb;
	border-right-width: 1px !important;
}

.ant-input-rtl .ant-pagination-options-quick-jumper input:hover {
	border-left-width: 1px !important;
	border-right-width: 0;
}

.ant-pagination-options-quick-jumper input-focused,
.ant-pagination-options-quick-jumper input:focus {
	border-color: #28aaeb;
	border-right-width: 1px !important;
	box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
	outline: 0;
}

.ant-input-rtl .ant-pagination-options-quick-jumper input-focused,
.ant-input-rtl .ant-pagination-options-quick-jumper input:focus {
	border-left-width: 1px !important;
	border-right-width: 0;
}

.ant-pagination-options-quick-jumper input-disabled {
	background-color: #f5f5f5;
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
	opacity: 1;
}

.ant-pagination-options-quick-jumper input-disabled:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}

.ant-pagination-options-quick-jumper input[disabled] {
	background-color: #f5f5f5;
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
	opacity: 1;
}

.ant-pagination-options-quick-jumper input[disabled]:hover {
	border-color: #d9d9d9;
	border-right-width: 1px !important;
}

.ant-pagination-options-quick-jumper input-borderless,
.ant-pagination-options-quick-jumper input-borderless-disabled,
.ant-pagination-options-quick-jumper input-borderless-focused,
.ant-pagination-options-quick-jumper input-borderless:focus,
.ant-pagination-options-quick-jumper input-borderless:hover,
.ant-pagination-options-quick-jumper input-borderless[disabled] {
	background-color: transparent;
	border: none;
	box-shadow: none;
}

textarea.ant-pagination-options-quick-jumper input {
	height: auto;
	line-height: 1.3;
	max-width: 100%;
	min-height: 36px;
	transition: all 0.3s, height 0s;
	vertical-align: bottom;
}

.ant-pagination-options-quick-jumper input-lg {
	font-size: 16px;
	padding: 8.6px 11px;
}

.ant-pagination-options-quick-jumper input-sm {
	padding: 1.9px 7px;
}

.ant-pagination-options-quick-jumper input-rtl {
	direction: rtl;
}

.ant-pagination-simple .ant-pagination-next,
.ant-pagination-simple .ant-pagination-prev {
	height: 24px;
	line-height: 24px;
	vertical-align: top;
}

.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link,
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link {
	background-color: transparent;
	border: 0;
	height: 24px;
}

.ant-pagination-simple .ant-pagination-next .ant-pagination-item-link:after,
.ant-pagination-simple .ant-pagination-prev .ant-pagination-item-link:after {
	height: 24px;
	line-height: 24px;
}

.ant-pagination-simple .ant-pagination-simple-pager {
	display: inline-block;
	height: 24px;
	margin-right: 8px;
}

.ant-pagination-simple .ant-pagination-simple-pager input {
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 6px;
	box-sizing: border-box;
	height: 100%;
	margin-right: 8px;
	outline: none;
	padding: 0 6px;
	text-align: center;
	transition: border-color 0.3s;
}

.ant-pagination-simple .ant-pagination-simple-pager input:hover {
	border-color: #038fde;
}

.ant-pagination-simple .ant-pagination-simple-pager input[disabled] {
	background: #f5f5f5;
	border-color: #d9d9d9;
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-pagination.mini .ant-pagination-simple-pager,
.ant-pagination.mini .ant-pagination-total-text {
	height: 24px;
	line-height: 24px;
}

.ant-pagination.mini .ant-pagination-item {
	height: 24px;
	line-height: 22px;
	margin: 0;
	min-width: 24px;
}

.ant-pagination.mini .ant-pagination-item:not(.ant-pagination-item-active) {
	background: transparent;
	border-color: transparent;
}

.ant-pagination.mini .ant-pagination-next,
.ant-pagination.mini .ant-pagination-prev {
	height: 24px;
	line-height: 24px;
	margin: 0;
	min-width: 24px;
}

.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link,
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link {
	background: transparent;
	border-color: transparent;
}

.ant-pagination.mini .ant-pagination-next .ant-pagination-item-link:after,
.ant-pagination.mini .ant-pagination-prev .ant-pagination-item-link:after {
	height: 24px;
	line-height: 24px;
}

.ant-pagination.mini .ant-pagination-jump-next,
.ant-pagination.mini .ant-pagination-jump-prev {
	height: 24px;
	line-height: 24px;
	margin-right: 0;
}

.ant-pagination.mini .ant-pagination-options {
	margin-left: 2px;
}

.ant-pagination.mini .ant-pagination-options-size-changer {
	top: 0;
}

.ant-pagination.mini .ant-pagination-options-quick-jumper {
	height: 24px;
	line-height: 24px;
}

.ant-pagination.mini .ant-pagination-options-quick-jumper input {
	height: 24px;
	padding: 1.9px 7px;
	width: 44px;
}

.ant-pagination.ant-pagination-disabled {
	cursor: not-allowed;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item {
	background: #f5f5f5;
	border-color: #d9d9d9;
	cursor: not-allowed;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item a {
	background: transparent;
	border: none;
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-active {
	background: #dbdbdb;
	border-color: transparent;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-active a {
	color: #fff;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
	background: #f5f5f5;
	border-color: #d9d9d9;
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-pagination-simple.ant-pagination.ant-pagination-disabled .ant-pagination-item-link {
	background: transparent;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-link-icon {
	opacity: 0;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-item-ellipsis {
	opacity: 1;
}

.ant-pagination.ant-pagination-disabled .ant-pagination-simple-pager {
	color: rgba(0, 0, 0, 0.25);
}

@media only screen and (max-width: 992px) {

	.ant-pagination-item-after-jump-prev,
	.ant-pagination-item-before-jump-next {
		display: none;
	}
}

@media only screen and (max-width: 576px) {
	.ant-pagination-options {
		display: none;
	}
}

.ant-pagination-rtl .ant-pagination-item,
.ant-pagination-rtl .ant-pagination-jump-next,
.ant-pagination-rtl .ant-pagination-jump-prev,
.ant-pagination-rtl .ant-pagination-prev,
.ant-pagination-rtl .ant-pagination-total-text {
	margin-left: 8px;
	margin-right: 0;
}

.ant-pagination-rtl .ant-pagination-slash {
	margin: 0 5px 0 10px;
}

.ant-pagination-rtl .ant-pagination-options {
	margin-left: 0;
	margin-right: 16px;
}

.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-size-changer.ant-select {
	margin-left: 8px;
	margin-right: 0;
}

.ant-pagination-rtl .ant-pagination-options .ant-pagination-options-quick-jumper {
	margin-left: 0;
}

.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager,
.ant-pagination-rtl.ant-pagination-simple .ant-pagination-simple-pager input {
	margin-left: 8px;
	margin-right: 0;
}

.ant-pagination-rtl.ant-pagination.mini .ant-pagination-options {
	margin-left: 0;
	margin-right: 2px;
}

.ant-popconfirm {
	z-index: 1060;
}

.ant-popover {
	box-sizing: border-box;
	color: #545454;
	cursor: auto;
	font-size: 14px;
	font-variant: tabular-nums;
	font-weight: 400;
	left: 0;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
	position: absolute;
	text-align: left;
	top: 0;
	-webkit-user-select: text;
	-ms-user-select: text;
	user-select: text;
	white-space: normal;
	z-index: 1030;
}

.ant-popover:after {
	background: hsla(0, 0%, 100%, 0.01);
	content: '';
	position: absolute;
}

.ant-popover-hidden {
	display: none;
}

.ant-popover-placement-top,
.ant-popover-placement-topLeft,
.ant-popover-placement-topRight {
	padding-bottom: 10px;
}

.ant-popover-placement-right,
.ant-popover-placement-rightBottom,
.ant-popover-placement-rightTop {
	padding-left: 10px;
}

.ant-popover-placement-bottom,
.ant-popover-placement-bottomLeft,
.ant-popover-placement-bottomRight {
	padding-top: 10px;
}

.ant-popover-placement-left,
.ant-popover-placement-leftBottom,
.ant-popover-placement-leftTop {
	padding-right: 10px;
}

.ant-popover-inner {
	background-clip: padding-box;
	background-color: #fff;
	border-radius: 6px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.15) \9;
}

@media (-ms-high-contrast: none),
screen and (-ms-high-contrast: active) {
	.ant-popover-inner {
		box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
			0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	}
}

.ant-popover-title {
	border-bottom: 1px solid #e8e8e8;
	color: #535353;
	font-weight: 500;
	margin: 0;
	min-height: 32px;
	min-width: 177px;
	padding: 5px 16px 4px;
}

.ant-popover-inner-content {
	color: #545454;
	padding: 12px 16px;
}

.ant-popover-message {
	color: #545454;
	font-size: 14px;
	padding: 4px 0 12px;
	position: relative;
}

.ant-popover-message>.anticon {
	color: #fa8c16;
	font-size: 14px;
	position: absolute;
	top: 6.1px;
}

.ant-popover-message-title {
	padding-left: 22px;
}

.ant-popover-buttons {
	margin-bottom: 4px;
	text-align: right;
}

.ant-popover-buttons button {
	margin-left: 8px;
}

.ant-popover-arrow {
	background: transparent;
	border-style: solid;
	border-width: 4.24264069px;
	display: block;
	height: 8.48528137px;
	position: absolute;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	width: 8.48528137px;
}

.ant-popover-placement-top>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-topLeft>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-topRight>.ant-popover-content>.ant-popover-arrow {
	border-color: transparent #fff #fff transparent;
	bottom: 6.2px;
	box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
}

.ant-popover-placement-top>.ant-popover-content>.ant-popover-arrow {
	left: 50%;
	-webkit-transform: translateX(-50%) rotate(45deg);
	transform: translateX(-50%) rotate(45deg);
}

.ant-popover-placement-topLeft>.ant-popover-content>.ant-popover-arrow {
	left: 16px;
}

.ant-popover-placement-topRight>.ant-popover-content>.ant-popover-arrow {
	right: 16px;
}

.ant-popover-placement-right>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-rightBottom>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-rightTop>.ant-popover-content>.ant-popover-arrow {
	border-color: transparent transparent #fff #fff;
	box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
	left: 6px;
}

.ant-popover-placement-right>.ant-popover-content>.ant-popover-arrow {
	top: 50%;
	-webkit-transform: translateY(-50%) rotate(45deg);
	transform: translateY(-50%) rotate(45deg);
}

.ant-popover-placement-rightTop>.ant-popover-content>.ant-popover-arrow {
	top: 12px;
}

.ant-popover-placement-rightBottom>.ant-popover-content>.ant-popover-arrow {
	bottom: 12px;
}

.ant-popover-placement-bottom>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-bottomLeft>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-bottomRight>.ant-popover-content>.ant-popover-arrow {
	border-color: #fff transparent transparent #fff;
	box-shadow: -2px -2px 5px rgba(0, 0, 0, 0.06);
	top: 6px;
}

.ant-popover-placement-bottom>.ant-popover-content>.ant-popover-arrow {
	left: 50%;
	-webkit-transform: translateX(-50%) rotate(45deg);
	transform: translateX(-50%) rotate(45deg);
}

.ant-popover-placement-bottomLeft>.ant-popover-content>.ant-popover-arrow {
	left: 16px;
}

.ant-popover-placement-bottomRight>.ant-popover-content>.ant-popover-arrow {
	right: 16px;
}

.ant-popover-placement-left>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-leftBottom>.ant-popover-content>.ant-popover-arrow,
.ant-popover-placement-leftTop>.ant-popover-content>.ant-popover-arrow {
	border-color: #fff #fff transparent transparent;
	box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
	right: 6px;
}

.ant-popover-placement-left>.ant-popover-content>.ant-popover-arrow {
	top: 50%;
	-webkit-transform: translateY(-50%) rotate(45deg);
	transform: translateY(-50%) rotate(45deg);
}

.ant-popover-placement-leftTop>.ant-popover-content>.ant-popover-arrow {
	top: 12px;
}

.ant-popover-placement-leftBottom>.ant-popover-content>.ant-popover-arrow {
	bottom: 12px;
}

.ant-popover-rtl {
	direction: rtl;
	text-align: right;
}

.ant-popover-rtl .ant-popover-message-title {
	padding-left: 16px;
	padding-right: 22px;
}

.ant-popover-rtl .ant-popover-buttons {
	text-align: left;
}

.ant-popover-rtl .ant-popover-buttons button {
	margin-left: 0;
	margin-right: 8px;
}

.ant-progress {
	box-sizing: border-box;
	color: #545454;
	display: inline-block;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
}

.ant-progress-line {
	font-size: 14px;
	position: relative;
	width: 100%;
}

.ant-progress-steps {
	display: inline-block;
}

.ant-progress-steps-outer {
	align-items: center;
	display: flex;
	flex-direction: row;
}

.ant-progress-steps-item {
	background: #f3f3f3;
	flex-shrink: 0;
	margin-right: 2px;
	min-width: 2px;
	transition: all 0.3s;
}

.ant-progress-steps-item-active {
	background: #038fde;
}

.ant-progress-small.ant-progress-line,
.ant-progress-small.ant-progress-line .ant-progress-text .anticon {
	font-size: 12px;
}

.ant-progress-outer {
	display: inline-block;
	margin-right: 0;
	padding-right: 0;
	width: 100%;
}

.ant-progress-show-info .ant-progress-outer {
	margin-right: calc(-2em - 8px);
	padding-right: calc(2em + 8px);
}

.ant-progress-inner {
	background-color: #f5f5f5;
	border-radius: 100px;
	display: inline-block;
	overflow: hidden;
	position: relative;
	vertical-align: middle;
	width: 100%;
}

.ant-progress-circle-trail {
	stroke: #f5f5f5;
}

.ant-progress-circle-path {
	-webkit-animation: ant-progress-appear 0.3s;
	animation: ant-progress-appear 0.3s;
}

.ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
	stroke: #038fde;
}

.ant-progress-bg,
.ant-progress-success-bg {
	background-color: #038fde;
	border-radius: 100px;
	position: relative;
	transition: all 0.4s cubic-bezier(0.08, 0.82, 0.17, 1) 0s;
}

.ant-progress-success-bg {
	background-color: #52c41a;
	left: 0;
	position: absolute;
	top: 0;
}

.ant-progress-text {
	color: #545454;
	display: inline-block;
	font-size: 1em;
	line-height: 1;
	margin-left: 8px;
	text-align: left;
	vertical-align: middle;
	white-space: nowrap;
	width: 2em;
	word-break: normal;
}

.ant-progress-text .anticon {
	font-size: 14px;
}

.ant-progress-status-active .ant-progress-bg:before {
	-webkit-animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
	animation: ant-progress-active 2.4s cubic-bezier(0.23, 1, 0.32, 1) infinite;
	background: #fff;
	border-radius: 10px;
	bottom: 0;
	content: '';
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.ant-progress-status-exception .ant-progress-bg {
	background-color: #f5222d;
}

.ant-progress-status-exception .ant-progress-text {
	color: #f5222d;
}

.ant-progress-status-exception .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
	stroke: #f5222d;
}

.ant-progress-status-success .ant-progress-bg {
	background-color: #52c41a;
}

.ant-progress-status-success .ant-progress-text {
	color: #52c41a;
}

.ant-progress-status-success .ant-progress-inner:not(.ant-progress-circle-gradient) .ant-progress-circle-path {
	stroke: #52c41a;
}

.ant-progress-circle .ant-progress-inner {
	background-color: transparent;
	line-height: 1;
	position: relative;
}

.ant-progress-circle .ant-progress-text {
	color: #545454;
	font-size: 1em;
	left: 50%;
	line-height: 1;
	margin: 0;
	padding: 0;
	position: absolute;
	text-align: center;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	white-space: normal;
	width: 100%;
}

.ant-progress-circle .ant-progress-text .anticon {
	font-size: 1.16666667em;
}

.ant-progress-circle.ant-progress-status-exception .ant-progress-text {
	color: #f5222d;
}

.ant-progress-circle.ant-progress-status-success .ant-progress-text {
	color: #52c41a;
}

@-webkit-keyframes ant-progress-active {
	0% {
		opacity: 0.1;
		width: 0;
	}

	20% {
		opacity: 0.5;
		width: 0;
	}

	to {
		opacity: 0;
		width: 100%;
	}
}

@keyframes ant-progress-active {
	0% {
		opacity: 0.1;
		width: 0;
	}

	20% {
		opacity: 0.5;
		width: 0;
	}

	to {
		opacity: 0;
		width: 100%;
	}
}

.ant-progress-rtl {
	direction: rtl;
}

.ant-progress-rtl.ant-progress-show-info .ant-progress-outer {
	margin-left: calc(-2em - 8px);
	margin-right: 0;
	padding-left: calc(2em + 8px);
	padding-right: 0;
}

.ant-progress-rtl .ant-progress-success-bg {
	left: auto;
	right: 0;
}

.ant-progress-rtl.ant-progress-line .ant-progress-text,
.ant-progress-rtl.ant-progress-steps .ant-progress-text {
	margin-left: 0;
	margin-right: 8px;
	text-align: right;
}

.ant-radio-group {
	box-sizing: border-box;
	color: #545454;
	display: inline-block;
	font-size: 14px;
	font-size: 0;
	font-variant: tabular-nums;
	line-height: 1.3;
	line-height: unset;
	list-style: none;
	margin: 0;
	padding: 0;
}

.ant-radio-group .ant-badge-count {
	z-index: 1;
}

.ant-radio-group>.ant-badge:not(:first-child)>.ant-radio-button-wrapper {
	border-left: none;
}

.ant-radio-wrapper {
	align-items: baseline;
	display: inline-flex;
	margin: 0 8px 0 0;
}

.ant-radio,
.ant-radio-wrapper {
	box-sizing: border-box;
	color: #545454;
	cursor: pointer;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	padding: 0;
	position: relative;
}

.ant-radio {
	display: inline-block;
	margin: 0;
	outline: none;
	top: 0.2em;
}

.ant-radio-input:focus+.ant-radio-inner,
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner {
	border-color: #038fde;
}

.ant-radio-input:focus+.ant-radio-inner {
	box-shadow: 0 0 0 3px rgba(3, 143, 222, 0.08);
}

.ant-radio-checked:after {
	-webkit-animation: antRadioEffect 0.36s ease-in-out;
	animation: antRadioEffect 0.36s ease-in-out;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	border: 1px solid #038fde;
	border-radius: 50%;
	content: '';
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	visibility: hidden;
	width: 100%;
}

.ant-radio-wrapper:hover .ant-radio:after,
.ant-radio:hover:after {
	visibility: visible;
}

.ant-radio-inner {
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 50%;
	display: block;
	height: 16px;
	left: 0;
	position: relative;
	top: 0;
	transition: all 0.3s;
	width: 16px;
}

.ant-radio-inner:after {
	background-color: #038fde;
	border-left: 0;
	border-radius: 8px;
	border-top: 0;
	content: ' ';
	display: table;
	height: 8px;
	left: 3px;
	opacity: 0;
	position: absolute;
	top: 3px;
	-webkit-transform: scale(0);
	transform: scale(0);
	transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
	width: 8px;
}

.ant-radio-input {
	bottom: 0;
	cursor: pointer;
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
}

.ant-radio-checked .ant-radio-inner {
	border-color: #038fde;
}

.ant-radio-checked .ant-radio-inner:after {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1);
	transition: all 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-radio-disabled {
	cursor: not-allowed;
}

.ant-radio-disabled .ant-radio-inner {
	background-color: #f5f5f5;
	border-color: #d9d9d9 !important;
	cursor: not-allowed;
}

.ant-radio-disabled .ant-radio-inner:after {
	background-color: rgba(0, 0, 0, 0.2);
}

.ant-radio-disabled .ant-radio-input {
	cursor: not-allowed;
}

.ant-radio-disabled+span {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

span.ant-radio+* {
	padding-left: 8px;
	padding-right: 8px;
}

.ant-radio-button-wrapper {
	background: #fff;
	border-color: #d9d9d9;
	border-style: solid;
	border-width: 1.02px 1px 1px 0;
	color: #545454;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	height: 36px;
	line-height: 34px;
	margin: 0;
	padding: 0 15px;
	position: relative;
	transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
}

.ant-radio-button-wrapper a {
	color: #545454;
}

.ant-radio-button-wrapper>.ant-radio-button {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: -1;
}

.ant-radio-group-large .ant-radio-button-wrapper {
	font-size: 16px;
	height: 40px;
	line-height: 38px;
}

.ant-radio-group-small .ant-radio-button-wrapper {
	height: 24px;
	line-height: 22px;
	padding: 0 7px;
}

.ant-radio-button-wrapper:not(:first-child):before {
	background-color: #d9d9d9;
	box-sizing: content-box;
	content: '';
	display: block;
	height: 100%;
	left: -1px;
	padding: 1px 0;
	position: absolute;
	top: -1px;
	transition: background-color 0.3s;
	width: 1px;
}

.ant-radio-button-wrapper:first-child {
	border-left: 1px solid #d9d9d9;
	border-radius: 6px 0 0 6px;
}

.ant-radio-button-wrapper:last-child {
	border-radius: 0 6px 6px 0;
}

.ant-radio-button-wrapper:first-child:last-child {
	border-radius: 6px;
}

.ant-radio-button-wrapper:hover {
	color: #038fde;
	position: relative;
}

.ant-radio-button-wrapper:focus-within {
	box-shadow: 0 0 0 3px rgba(3, 143, 222, 0.08);
}

.ant-radio-button-wrapper .ant-radio-inner,
.ant-radio-button-wrapper input[type='checkbox'],
.ant-radio-button-wrapper input[type='radio'] {
	height: 0;
	opacity: 0;
	pointer-events: none;
	width: 0;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	background: #fff;
	border-color: #038fde;
	color: #038fde;
	z-index: 1;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
	background-color: #038fde;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):first-child {
	border-color: #038fde;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
	border-color: #28aaeb;
	color: #28aaeb;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover:before {
	background-color: #28aaeb;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
	border-color: #006eb8;
	color: #006eb8;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active:before {
	background-color: #006eb8;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
	box-shadow: 0 0 0 3px rgba(3, 143, 222, 0.08);
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
	background: #038fde;
	border-color: #038fde;
	color: #fff;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
	background: #28aaeb;
	border-color: #28aaeb;
	color: #fff;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
	background: #006eb8;
	border-color: #006eb8;
	color: #fff;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
	box-shadow: 0 0 0 3px rgba(3, 143, 222, 0.08);
}

.ant-radio-button-wrapper-disabled {
	cursor: not-allowed;
}

.ant-radio-button-wrapper-disabled,
.ant-radio-button-wrapper-disabled:first-child,
.ant-radio-button-wrapper-disabled:hover {
	background-color: #f5f5f5;
	border-color: #d9d9d9;
	color: rgba(0, 0, 0, 0.25);
}

.ant-radio-button-wrapper-disabled:first-child {
	border-left-color: #d9d9d9;
}

.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked {
	background-color: #e6e6e6;
	border-color: #d9d9d9;
	box-shadow: none;
	color: rgba(0, 0, 0, 0.25);
}

@-webkit-keyframes antRadioEffect {
	0% {
		opacity: 0.5;
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	to {
		opacity: 0;
		-webkit-transform: scale(1.6);
		transform: scale(1.6);
	}
}

@keyframes antRadioEffect {
	0% {
		opacity: 0.5;
		-webkit-transform: scale(1);
		transform: scale(1);
	}

	to {
		opacity: 0;
		-webkit-transform: scale(1.6);
		transform: scale(1.6);
	}
}

.ant-radio-group.ant-radio-group-rtl {
	direction: rtl;
}

.ant-radio-wrapper.ant-radio-wrapper-rtl {
	direction: rtl;
	margin-left: 8px;
	margin-right: 0;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl {
	border-left-width: 1px;
	border-right-width: 0;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:not(:first-child):before {
	left: 0;
	right: -1px;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:first-child {
	border-radius: 0 6px 6px 0;
	border-right: 1px solid #d9d9d9;
}

.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
	border-right-color: #28aaeb;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper:last-child {
	border-radius: 6px 0 0 6px;
}

.ant-radio-button-wrapper.ant-radio-button-wrapper-rtl.ant-radio-button-wrapper-disabled:first-child {
	border-right-color: #d9d9d9;
}

.ant-rate {
	box-sizing: border-box;
	color: #545454;
	color: #fadb14;
	display: inline-block;
	font-size: 14px;
	font-size: 20px;
	font-variant: tabular-nums;
	line-height: 1.3;
	line-height: unset;
	list-style: none;
	margin: 0;
	outline: none;
	padding: 0;
}

.ant-rate-disabled .ant-rate-star {
	cursor: default;
}

.ant-rate-disabled .ant-rate-star:hover {
	-webkit-transform: scale(1);
	transform: scale(1);
}

.ant-rate-star {
	color: inherit;
	cursor: pointer;
	display: inline-block;
	position: relative;
	transition: all 0.3s;
}

.ant-rate-star:not(:last-child) {
	margin-right: 8px;
}

.ant-rate-star>div:focus {
	outline: 0;
}

.ant-rate-star>div:focus,
.ant-rate-star>div:hover {
	-webkit-transform: scale(1.1);
	transform: scale(1.1);
}

.ant-rate-star-first,
.ant-rate-star-second {
	color: #e8e8e8;
	transition: all 0.3s;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ant-rate-star-first .anticon,
.ant-rate-star-second .anticon {
	vertical-align: middle;
}

.ant-rate-star-first {
	height: 100%;
	left: 0;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	top: 0;
	width: 50%;
}

.ant-rate-star-half .ant-rate-star-first,
.ant-rate-star-half .ant-rate-star-second {
	opacity: 1;
}

.ant-rate-star-full .ant-rate-star-second,
.ant-rate-star-half .ant-rate-star-first {
	color: inherit;
}

.ant-rate-text {
	display: inline-block;
	font-size: 14px;
	margin: 0 8px;
}

.ant-rate-rtl {
	direction: rtl;
}

.ant-rate-rtl .ant-rate-star:not(:last-child) {
	margin-left: 8px;
	margin-right: 0;
}

.ant-rate-rtl .ant-rate-star-first {
	left: auto;
	right: 0;
}

.ant-result {
	padding: 48px 32px;
}

.ant-result-success .ant-result-icon>.anticon {
	color: #52c41a;
}

.ant-result-error .ant-result-icon>.anticon {
	color: #f5222d;
}

.ant-result-info .ant-result-icon>.anticon {
	color: #1890ff;
}

.ant-result-warning .ant-result-icon>.anticon {
	color: #fa8c16;
}

.ant-result-image {
	height: 295px;
	margin: auto;
	width: 250px;
}

.ant-result-icon {
	margin-bottom: 24px;
	text-align: center;
}

.ant-result-icon>.anticon {
	font-size: 72px;
}

.ant-result-title {
	color: #535353;
	font-size: 24px;
	line-height: 1.8;
	text-align: center;
}

.ant-result-subtitle {
	color: #595959;
	font-size: 14px;
	line-height: 1.6;
	text-align: center;
}

.ant-result-extra {
	margin: 24px 0 0;
	text-align: center;
}

.ant-result-extra>* {
	margin-right: 8px;
}

.ant-result-extra> :last-child {
	margin-right: 0;
}

.ant-result-content {
	background-color: #fafafa;
	margin-top: 24px;
	padding: 24px 40px;
}

.ant-result-rtl {
	direction: rtl;
}

.ant-result-rtl .ant-result-extra>* {
	margin-left: 8px;
	margin-right: 0;
}

.ant-result-rtl .ant-result-extra> :last-child {
	margin-left: 0;
}

.ant-select-single .ant-select-selector {
	display: flex;
}

.ant-select-single .ant-select-selector .ant-select-selection-search {
	bottom: 0;
	left: 11px;
	position: absolute;
	right: 11px;
	top: 0;
}

.ant-select-single .ant-select-selector .ant-select-selection-search-input {
	width: 100%;
}

.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
	line-height: 34px;
	padding: 0;
	transition: all 0.3s;
}

@supports (-moz-appearance: meterbar) {

	.ant-select-single .ant-select-selector .ant-select-selection-item,
	.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
		line-height: 34px;
	}
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
	position: relative;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
	pointer-events: none;
}

.ant-select-single .ant-select-selector .ant-select-selection-item:after,
.ant-select-single .ant-select-selector .ant-select-selection-placeholder:after,
.ant-select-single .ant-select-selector:after {
	content: '\a0';
	display: inline-block;
	visibility: hidden;
	width: 0;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
	right: 25px;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
	padding-right: 18px;
}

.ant-select-single.ant-select-open .ant-select-selection-item {
	color: #bfbfbf;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	height: 36px;
	padding: 0 11px;
	width: 100%;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
	height: 34px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:after {
	line-height: 34px;
}

.ant-select-single.ant-select-customize-input .ant-select-selector:after {
	display: none;
}

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-search {
	position: static;
	width: 100%;
}

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder {
	left: 0;
	padding: 0 11px;
	position: absolute;
	right: 0;
}

.ant-select-single.ant-select-customize-input .ant-select-selector .ant-select-selection-placeholder:after {
	display: none;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
	height: 40px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector:after {
	line-height: 38px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
	height: 38px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
	height: 24px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector:after {
	line-height: 22px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input):not(.ant-select-customize-input) .ant-select-selection-search-input {
	height: 22px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selection-search {
	left: 7px;
	right: 7px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input) .ant-select-selector {
	padding: 0 7px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
	right: 28px;
}

.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
	padding-right: 21px;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
	padding: 0 11px;
}

.ant-select-selection-overflow {
	display: flex;
	flex: auto;
	flex-wrap: wrap;
	max-width: 100%;
	position: relative;
}

.ant-select-selection-overflow-item {
	align-self: center;
	flex: none;
	max-width: 100%;
}

.ant-select-multiple .ant-select-selector {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	padding: 2.9px 7.9px;
}

.ant-select-show-search.ant-select-multiple .ant-select-selector {
	cursor: text;
}

.ant-select-disabled.ant-select-multiple .ant-select-selector {
	background: #f5f5f5;
	cursor: not-allowed;
}

.ant-select-multiple .ant-select-selector:after {
	content: '\a0';
	display: inline-block;
	line-height: 20.2px;
	margin: 4px 0;
	width: 0;
}

.ant-select-multiple.ant-select-allow-clear .ant-select-selector,
.ant-select-multiple.ant-select-show-arrow .ant-select-selector {
	padding-right: 24px;
}

.ant-select-multiple .ant-select-selection-item {
	-webkit-margin-end: 7.9px;
	-webkit-padding-start: 8px;
	-webkit-padding-end: 4px;
	background: #f5f5f5;
	border: 1px solid #e8e8e8;
	border-radius: 6px;
	box-sizing: border-box;
	cursor: default;
	display: flex;
	flex: none;
	height: 20.2px;
	line-height: 18.2px;
	margin-bottom: 4px;
	margin-inline-end: 7.9px;
	margin-top: 4px;
	max-width: 100%;
	padding-inline-end: 4px;
	padding-inline-start: 8px;
	position: relative;
	transition: font-size 0.3s, line-height 0.3s, height 0.3s;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ant-select-disabled.ant-select-multiple .ant-select-selection-item {
	border-color: #d9d9d9;
	color: #bfbfbf;
	cursor: not-allowed;
}

.ant-select-multiple .ant-select-selection-item-content {
	display: inline-block;
	margin-right: 4px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: pre;
}

.ant-select-multiple .ant-select-selection-item-remove {
	text-rendering: optimizeLegibility;

	-moz-osx-font-smoothing: grayscale;
	color: inherit;
	color: #595959;
	cursor: pointer;
	display: inline-block;
	font-size: 10px;
	font-style: normal;
	font-weight: 700;
	line-height: 0;
	line-height: inherit;
	text-align: center;
	text-transform: none;
	vertical-align: -0.125em;
}

.ant-select-multiple .ant-select-selection-item-remove>* {
	line-height: 1;
}

.ant-select-multiple .ant-select-selection-item-remove svg {
	display: inline-block;
}

.ant-select-multiple .ant-select-selection-item-remove:before {
	display: none;
}

.ant-select-multiple .ant-select-selection-item-remove .ant-select-multiple .ant-select-selection-item-remove-icon {
	display: block;
}

.ant-select-multiple .ant-select-selection-item-remove>.anticon {
	vertical-align: -0.2em;
}

.ant-select-multiple .ant-select-selection-item-remove:hover {
	color: rgba(0, 0, 0, 0.75);
}

.ant-select-multiple .ant-select-selection-overflow-item+.ant-select-selection-overflow-item .ant-select-selection-search {
	-webkit-margin-start: 0;
	margin-inline-start: 0;
}

.ant-select-multiple .ant-select-selection-search {
	-webkit-margin-start: 3.1px;
	margin-bottom: 4px;
	margin-inline-start: 3.1px;
	margin-top: 4px;
	max-width: 100%;
	position: relative;
}

.ant-select-multiple .ant-select-selection-search-input,
.ant-select-multiple .ant-select-selection-search-mirror {
	font-family: NoirPro, sans-serif;
	height: 20.2px;
	line-height: 20.2px;
	transition: all 0.3s;
}

.ant-select-multiple .ant-select-selection-search-input {
	min-width: 4.1px;
	width: 100%;
}

.ant-select-multiple .ant-select-selection-search-mirror {
	left: 0;
	position: absolute;
	top: 0;
	visibility: hidden;
	white-space: pre;
	z-index: 999;
}

.ant-select-multiple .ant-select-selection-placeholder {
	left: 11px;
	position: absolute;
	right: 11px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	transition: all 0.3s;
}

.ant-select-multiple.ant-select-lg .ant-select-selector:after {
	line-height: 24.2px;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
	height: 24.2px;
	line-height: 22.2px;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-search {
	height: 24.2px;
	line-height: 24.2px;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-search-input,
.ant-select-multiple.ant-select-lg .ant-select-selection-search-mirror {
	height: 24.2px;
	line-height: 22.2px;
}

.ant-select-multiple.ant-select-sm .ant-select-selector:after {
	line-height: 8.2px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-item {
	height: 8.2px;
	line-height: 6.2px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-search {
	height: 8.2px;
	line-height: 8.2px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-search-input,
.ant-select-multiple.ant-select-sm .ant-select-selection-search-mirror {
	height: 8.2px;
	line-height: 6.2px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
	left: 7px;
}

.ant-select-multiple.ant-select-sm .ant-select-selection-search {
	-webkit-margin-start: 3px;
	margin-inline-start: 3px;
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
	height: 32px;
	line-height: 32px;
}

.ant-select-disabled .ant-select-selection-item-remove {
	display: none;
}

.ant-select {
	box-sizing: border-box;
	color: #545454;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 6px;
	position: relative;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
	cursor: pointer;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	cursor: text;
}

.ant-select-show-search.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
	cursor: auto;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border-color: #28aaeb;
	border-right-width: 1px !important;
	box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
	outline: 0;
}

.ant-input-rtl .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border-left-width: 1px !important;
	border-right-width: 0;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	background: #f5f5f5;
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	background: #f5f5f5;
}

.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector input {
	cursor: not-allowed;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
	-webkit-appearance: none;
	appearance: none;
	background: transparent;
	border: none;
	margin: 0;
	outline: none;
	padding: 0;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input::-webkit-search-cancel-button {
	-webkit-appearance: none;
	display: none;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
	border-color: #28aaeb;
	border-right-width: 1px !important;
}

.ant-input-rtl .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
	border-left-width: 1px !important;
	border-right-width: 0;
}

.ant-select-selection-item {
	flex: 1 1;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@media (-ms-high-contrast: none) {

	.ant-select-selection-item,
	.ant-select-selection-item ::-ms-backdrop {
		flex: auto;
	}
}

.ant-select-selection-placeholder {
	color: #bfbfbf;
	flex: 1 1;
	overflow: hidden;
	pointer-events: none;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@media (-ms-high-contrast: none) {

	.ant-select-selection-placeholder,
	.ant-select-selection-placeholder ::-ms-backdrop {
		flex: auto;
	}
}

.ant-select-arrow {
	text-rendering: optimizeLegibility;

	-moz-osx-font-smoothing: grayscale;
	color: inherit;
	color: rgba(0, 0, 0, 0.25);
	display: inline-block;
	font-size: 12px;
	font-style: normal;
	height: 12px;
	line-height: 0;
	line-height: 1;
	margin-top: -6px;
	pointer-events: none;
	position: absolute;
	right: 11px;
	text-align: center;
	text-transform: none;
	top: 53%;
	vertical-align: -0.125em;
	width: 12px;
}

.ant-select-arrow>* {
	line-height: 1;
}

.ant-select-arrow svg {
	display: inline-block;
}

.ant-select-arrow:before {
	display: none;
}

.ant-select-arrow .ant-select-arrow-icon {
	display: block;
}

.ant-select-arrow .anticon {
	transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
	transition: transform 0.3s, -webkit-transform 0.3s;
	vertical-align: top;
}

.ant-select-arrow .anticon>svg {
	vertical-align: top;
}

.ant-select-arrow .anticon:not(.ant-select-suffix) {
	pointer-events: auto;
}

.ant-select-disabled .ant-select-arrow {
	cursor: not-allowed;
}

.ant-select-clear {
	text-rendering: auto;
	background: #fff;
	color: rgba(0, 0, 0, 0.25);
	cursor: pointer;
	display: inline-block;
	font-size: 12px;
	font-style: normal;
	height: 12px;
	line-height: 1;
	margin-top: -6px;
	opacity: 0;
	position: absolute;
	right: 11px;
	text-align: center;
	text-transform: none;
	top: 50%;
	transition: color 0.3s ease, opacity 0.15s ease;
	width: 12px;
	z-index: 1;
}

.ant-select-clear:before {
	display: block;
}

.ant-select-clear:hover {
	color: #595959;
}

.ant-select:hover .ant-select-clear {
	opacity: 1;
}

.ant-select-dropdown {
	background-color: #fff;
	border-radius: 6px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	font-variant: normal;
	left: -9999px;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	outline: none;
	overflow: hidden;
	padding: 4px 0;
	position: absolute;
	top: -9999px;
	z-index: 1050;
}

.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-bottomLeft,
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-bottomLeft {
	-webkit-animation-name: antSlideUpIn;
	animation-name: antSlideUpIn;
}

.ant-select-dropdown.slide-up-appear.slide-up-appear-active.ant-select-dropdown-placement-topLeft,
.ant-select-dropdown.slide-up-enter.slide-up-enter-active.ant-select-dropdown-placement-topLeft {
	-webkit-animation-name: antSlideDownIn;
	animation-name: antSlideDownIn;
}

.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-bottomLeft {
	-webkit-animation-name: antSlideUpOut;
	animation-name: antSlideUpOut;
}

.ant-select-dropdown.slide-up-leave.slide-up-leave-active.ant-select-dropdown-placement-topLeft {
	-webkit-animation-name: antSlideDownOut;
	animation-name: antSlideDownOut;
}

.ant-select-dropdown-hidden {
	display: none;
}

.ant-select-dropdown-empty {
	color: rgba(0, 0, 0, 0.25);
}

.ant-select-item-empty {
	color: #545454;
	color: rgba(0, 0, 0, 0.25);
}

.ant-select-item,
.ant-select-item-empty {
	display: block;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	min-height: 32px;
	padding: 0 !important;
	position: relative;
}

.ant-select-item {
	color: #545454;
	cursor: pointer;
	transition: background 0.3s ease;
}

.ant-select-item-group {
	color: #595959;
	cursor: default;
	font-size: 12px;
}

.ant-select-item-option {
	display: flex;
}

.ant-select-item-option-content {
	flex: auto;
	overflow: hidden;
	padding: 5px 12px !important;
	text-align: left;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ant-select-item-option-state {
	flex: none;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
	background-color: #f5f5f5;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	background-color: #e6faff;
	color: #545454;
	font-weight: 600;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) .ant-select-item-option-state {
	color: #038fde;
}

.ant-select-item-option-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-select-item-option-grouped {
	padding-left: 24px;
}

.ant-select-lg {
	font-size: 16px;
}

.ant-select-borderless .ant-select-selector {
	background-color: transparent !important;
	border-color: transparent !important;
	box-shadow: none !important;
}

.ant-select-rtl {
	direction: rtl;
}

.ant-select-rtl .ant-select-arrow,
.ant-select-rtl .ant-select-clear {
	left: 11px;
	right: auto;
}

.ant-select-dropdown-rtl {
	direction: rtl;
}

.ant-select-dropdown-rtl .ant-select-item-option-grouped {
	padding-left: 12px;
	padding-right: 24px;
}

.ant-select-rtl.ant-select-multiple.ant-select-allow-clear .ant-select-selector,
.ant-select-rtl.ant-select-multiple.ant-select-show-arrow .ant-select-selector {
	padding-left: 24px;
	padding-right: 7.9px;
}

.ant-select-rtl.ant-select-multiple .ant-select-selection-item {
	text-align: right;
}

.ant-select-rtl.ant-select-multiple .ant-select-selection-item-content {
	margin-left: 4px;
	margin-right: 0;
	text-align: right;
}

.ant-select-rtl.ant-select-multiple .ant-select-selection-search-mirror {
	left: auto;
	right: 0;
}

.ant-select-rtl.ant-select-multiple .ant-select-selection-placeholder {
	left: auto;
	right: 11px;
}

.ant-select-rtl.ant-select-multiple.ant-select-sm .ant-select-selection-placeholder {
	right: 7px;
}

.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-item,
.ant-select-rtl.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
	left: 9px;
	right: 0;
	text-align: right;
}

.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-search {
	left: 25px;
	right: 11px;
}

.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
	padding-left: 18px;
	padding-right: 0;
}

.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-search {
	right: 6px;
}

.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-item,
.ant-select-rtl.ant-select-single.ant-select-sm:not(.ant-select-customize-input).ant-select-show-arrow .ant-select-selection-placeholder {
	padding-left: 21px;
	padding-right: 0;
}

.ant-skeleton {
	display: table;
	width: 100%;
}

.ant-skeleton-header {
	display: table-cell;
	padding-right: 16px;
	vertical-align: top;
}

.ant-skeleton-header .ant-skeleton-avatar {
	background: hsla(0, 0%, 75%, 0.2);
	display: inline-block;
	height: 32px;
	line-height: 32px;
	vertical-align: top;
	width: 32px;
}

.ant-skeleton-header .ant-skeleton-avatar.ant-skeleton-avatar-circle {
	border-radius: 50%;
}

.ant-skeleton-header .ant-skeleton-avatar-lg {
	height: 40px;
	line-height: 40px;
	width: 40px;
}

.ant-skeleton-header .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
	border-radius: 50%;
}

.ant-skeleton-header .ant-skeleton-avatar-sm {
	height: 24px;
	line-height: 24px;
	width: 24px;
}

.ant-skeleton-header .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
	border-radius: 50%;
}

.ant-skeleton-content {
	display: table-cell;
	vertical-align: top;
	width: 100%;
}

.ant-skeleton-content .ant-skeleton-title {
	background: hsla(0, 0%, 75%, 0.2);
	border-radius: 4px;
	height: 16px;
	margin-top: 16px;
	width: 100%;
}

.ant-skeleton-content .ant-skeleton-title+.ant-skeleton-paragraph {
	margin-top: 24px;
}

.ant-skeleton-content .ant-skeleton-paragraph {
	padding: 0;
}

.ant-skeleton-content .ant-skeleton-paragraph>li {
	background: hsla(0, 0%, 75%, 0.2);
	border-radius: 4px;
	height: 16px;
	list-style: none;
	width: 100%;
}

.ant-skeleton-content .ant-skeleton-paragraph>li:last-child:not(:first-child):not(:nth-child(2)) {
	width: 61%;
}

.ant-skeleton-content .ant-skeleton-paragraph>li+li {
	margin-top: 16px;
}

.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title {
	margin-top: 12px;
}

.ant-skeleton-with-avatar .ant-skeleton-content .ant-skeleton-title+.ant-skeleton-paragraph {
	margin-top: 28px;
}

.ant-skeleton-round .ant-skeleton-content .ant-skeleton-paragraph>li,
.ant-skeleton-round .ant-skeleton-content .ant-skeleton-title {
	border-radius: 100px;
}

.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar,
.ant-skeleton.ant-skeleton-active .ant-skeleton-button,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph>li,
.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title,
.ant-skeleton.ant-skeleton-active .ant-skeleton-image,
.ant-skeleton.ant-skeleton-active .ant-skeleton-input {
	-webkit-animation: ant-skeleton-loading 1.4s ease infinite;
	animation: ant-skeleton-loading 1.4s ease infinite;
	background: linear-gradient(90deg,
			hsla(0, 0%, 75%, 0.2) 25%,
			hsla(0, 0%, 51%, 0.24) 37%,
			hsla(0, 0%, 75%, 0.2) 63%);
	background-size: 400% 100%;
}

.ant-skeleton-element {
	display: inline-block;
	width: auto;
}

.ant-skeleton-element .ant-skeleton-button {
	background: hsla(0, 0%, 75%, 0.2);
	border-radius: 6px;
	display: inline-block;
	height: 36px;
	line-height: 36px;
	vertical-align: top;
	width: 72px;
}

.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-circle {
	border-radius: 50%;
	width: 36px;
}

.ant-skeleton-element .ant-skeleton-button.ant-skeleton-button-round {
	border-radius: 36px;
}

.ant-skeleton-element .ant-skeleton-button-lg {
	height: 40px;
	line-height: 40px;
	width: 80px;
}

.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-circle {
	border-radius: 50%;
	width: 40px;
}

.ant-skeleton-element .ant-skeleton-button-lg.ant-skeleton-button-round {
	border-radius: 40px;
}

.ant-skeleton-element .ant-skeleton-button-sm {
	height: 24px;
	line-height: 24px;
	width: 48px;
}

.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-circle {
	border-radius: 50%;
	width: 24px;
}

.ant-skeleton-element .ant-skeleton-button-sm.ant-skeleton-button-round {
	border-radius: 24px;
}

.ant-skeleton-element .ant-skeleton-avatar {
	background: hsla(0, 0%, 75%, 0.2);
	display: inline-block;
	height: 32px;
	line-height: 32px;
	vertical-align: top;
	width: 32px;
}

.ant-skeleton-element .ant-skeleton-avatar.ant-skeleton-avatar-circle {
	border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-avatar-lg {
	height: 40px;
	line-height: 40px;
	width: 40px;
}

.ant-skeleton-element .ant-skeleton-avatar-lg.ant-skeleton-avatar-circle {
	border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-avatar-sm {
	height: 24px;
	line-height: 24px;
	width: 24px;
}

.ant-skeleton-element .ant-skeleton-avatar-sm.ant-skeleton-avatar-circle {
	border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-input {
	background: hsla(0, 0%, 75%, 0.2);
	display: inline-block;
	height: 36px;
	line-height: 36px;
	vertical-align: top;
	width: 100%;
}

.ant-skeleton-element .ant-skeleton-input-lg {
	height: 40px;
	line-height: 40px;
	width: 100%;
}

.ant-skeleton-element .ant-skeleton-input-sm {
	height: 24px;
	line-height: 24px;
	width: 100%;
}

.ant-skeleton-element .ant-skeleton-image {
	align-items: center;
	background: hsla(0, 0%, 75%, 0.2);
	display: flex;
	height: 96px;
	justify-content: center;
	line-height: 96px;
	vertical-align: top;
	width: 96px;
}

.ant-skeleton-element .ant-skeleton-image.ant-skeleton-image-circle {
	border-radius: 50%;
}

.ant-skeleton-element .ant-skeleton-image-path {
	fill: #bfbfbf;
}

.ant-skeleton-element .ant-skeleton-image-svg {
	height: 48px;
	line-height: 48px;
	max-height: 192px;
	max-width: 192px;
	width: 48px;
}

.ant-skeleton-element .ant-skeleton-image-svg.ant-skeleton-image-circle {
	border-radius: 50%;
}

@-webkit-keyframes ant-skeleton-loading {
	0% {
		background-position: 100% 50%;
	}

	to {
		background-position: 0 50%;
	}
}

@keyframes ant-skeleton-loading {
	0% {
		background-position: 100% 50%;
	}

	to {
		background-position: 0 50%;
	}
}

.ant-skeleton-rtl {
	direction: rtl;
}

.ant-skeleton-rtl .ant-skeleton-header {
	padding-left: 16px;
	padding-right: 0;
}

.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-avatar,
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-paragraph>li,
.ant-skeleton-rtl.ant-skeleton.ant-skeleton-active .ant-skeleton-content .ant-skeleton-title {
	-webkit-animation-name: ant-skeleton-loading-rtl;
	animation-name: ant-skeleton-loading-rtl;
}

@-webkit-keyframes ant-skeleton-loading-rtl {
	0% {
		background-position: 0 50%;
	}

	to {
		background-position: 100% 50%;
	}
}

@keyframes ant-skeleton-loading-rtl {
	0% {
		background-position: 0 50%;
	}

	to {
		background-position: 100% 50%;
	}
}

.ant-slider {
	box-sizing: border-box;
	color: #545454;
	cursor: pointer;
	font-size: 14px;
	font-variant: tabular-nums;
	height: 12px;
	line-height: 1.3;
	list-style: none;
	margin: 10px 6px;
	padding: 4px 0;
	position: relative;
	touch-action: none;
}

.ant-slider-vertical {
	height: 100%;
	margin: 6px 10px;
	padding: 0 4px;
	width: 12px;
}

.ant-slider-vertical .ant-slider-rail {
	height: 100%;
	width: 4px;
}

.ant-slider-vertical .ant-slider-track {
	width: 4px;
}

.ant-slider-vertical .ant-slider-handle {
	margin-left: -5px;
	margin-top: -6px;
}

.ant-slider-vertical .ant-slider-mark {
	height: 100%;
	left: 12px;
	top: 0;
	width: 18px;
}

.ant-slider-vertical .ant-slider-mark-text {
	left: 4px;
	white-space: nowrap;
}

.ant-slider-vertical .ant-slider-step {
	height: 100%;
	width: 4px;
}

.ant-slider-vertical .ant-slider-dot {
	left: 2px;
	margin-bottom: -4px;
	top: auto;
}

.ant-slider-tooltip .ant-tooltip-inner {
	min-width: unset;
}

.ant-slider-rtl.ant-slider-vertical .ant-slider-handle {
	margin-left: 0;
	margin-right: -5px;
}

.ant-slider-rtl.ant-slider-vertical .ant-slider-mark {
	left: auto;
	right: 12px;
}

.ant-slider-rtl.ant-slider-vertical .ant-slider-mark-text {
	left: auto;
	right: 4px;
}

.ant-slider-rtl.ant-slider-vertical .ant-slider-dot {
	left: auto;
	right: 2px;
}

.ant-slider-with-marks {
	margin-bottom: 28px;
}

.ant-slider-rail {
	background-color: #f5f5f5;
	width: 100%;
}

.ant-slider-rail,
.ant-slider-track {
	border-radius: 6px;
	height: 4px;
	position: absolute;
	transition: background-color 0.3s;
}

.ant-slider-track {
	background-color: #7ddcff;
}

.ant-slider-handle {
	background-color: #fff;
	border: 2px solid #7ddcff;
	border-radius: 50%;
	box-shadow: 0;
	cursor: pointer;
	height: 14px;
	margin-top: -5px;
	position: absolute;
	transition: border-color 0.3s, box-shadow 0.6s,
		-webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
	transition: border-color 0.3s, box-shadow 0.6s,
		transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
	transition: border-color 0.3s, box-shadow 0.6s,
		transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28),
		-webkit-transform 0.3s cubic-bezier(0.18, 0.89, 0.32, 1.28);
	width: 14px;
}

.ant-slider-handle-dragging.ant-slider-handle-dragging.ant-slider-handle-dragging,
.ant-slider-handle:focus {
	border-color: #35a5e5;
	box-shadow: 0 0 0 5px rgba(3, 143, 222, 0.12);
}

.ant-slider-handle:focus {
	outline: none;
}

.ant-slider-handle.ant-tooltip-open {
	border-color: #038fde;
}

.ant-slider:hover .ant-slider-rail {
	background-color: #e1e1e1;
}

.ant-slider:hover .ant-slider-track {
	background-color: #52c6f7;
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
	border-color: #52c6f7;
}

.ant-slider-mark {
	font-size: 14px;
	left: 0;
	position: absolute;
	top: 14px;
	width: 100%;
}

.ant-slider-mark-text {
	color: #595959;
	cursor: pointer;
	display: inline-block;
	position: absolute;
	text-align: center;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	word-break: keep-all;
}

.ant-slider-mark-text-active {
	color: #545454;
}

.ant-slider-step {
	background: transparent;
	height: 4px;
	position: absolute;
	width: 100%;
}

.ant-slider-dot {
	background-color: #fff;
	border: 2px solid #e8e8e8;
	border-radius: 50%;
	cursor: pointer;
	height: 8px;
	position: absolute;
	top: -2px;
	width: 8px;
}

.ant-slider-dot,
.ant-slider-dot:first-child,
.ant-slider-dot:last-child {
	margin-left: -4px;
}

.ant-slider-dot-active {
	border-color: #81c7ef;
}

.ant-slider-disabled {
	cursor: not-allowed;
}

.ant-slider-disabled .ant-slider-track {
	background-color: rgba(0, 0, 0, 0.25) !important;
}

.ant-slider-disabled .ant-slider-dot,
.ant-slider-disabled .ant-slider-handle {
	background-color: #fff;
	border-color: rgba(0, 0, 0, 0.25) !important;
	box-shadow: none;
	cursor: not-allowed;
}

.ant-slider-disabled .ant-slider-dot,
.ant-slider-disabled .ant-slider-mark-text {
	cursor: not-allowed !important;
}

.ant-slider-rtl {
	direction: rtl;
}

.ant-slider-rtl .ant-slider-mark {
	left: auto;
	right: 0;
}

.ant-slider-rtl .ant-slider-dot,
.ant-slider-rtl .ant-slider-dot:first-child,
.ant-slider-rtl .ant-slider-dot:last-child {
	margin-left: 0;
	margin-right: -4px;
}

.ant-space {
	display: inline-flex;
}

.ant-space-vertical {
	flex-direction: column;
}

.ant-space-align-center {
	align-items: center;
}

.ant-space-align-start {
	align-items: flex-start;
}

.ant-space-align-end {
	align-items: flex-end;
}

.ant-space-align-baseline {
	align-items: baseline;
}

.ant-space-item:empty {
	display: none;
}

.ant-space-rtl {
	direction: rtl;
}

.ant-spin {
	box-sizing: border-box;
	color: #545454;
	color: #038fde;
	display: none;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	opacity: 0;
	padding: 0;
	position: absolute;
	text-align: center;
	transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
	transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
	transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
		-webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
	vertical-align: middle;
}

.ant-spin-spinning {
	display: inline-block;
	opacity: 1;
	position: static;
}

.ant-spin-nested-loading {
	position: relative;
}

.ant-spin-nested-loading>div>.ant-spin {
	display: block;
	height: 100%;
	left: 0;
	max-height: 400px;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 4;
}

.ant-spin-nested-loading>div>.ant-spin .ant-spin-dot {
	left: 50%;
	margin: -10px;
	position: absolute;
	top: 50%;
}

.ant-spin-nested-loading>div>.ant-spin .ant-spin-text {
	padding-top: 5px;
	position: absolute;
	text-shadow: 0 1px 2px #fff;
	top: 50%;
	width: 100%;
}

.ant-spin-nested-loading>div>.ant-spin.ant-spin-show-text .ant-spin-dot {
	margin-top: -20px;
}

.ant-spin-nested-loading>div>.ant-spin-sm .ant-spin-dot {
	margin: -7px;
}

.ant-spin-nested-loading>div>.ant-spin-sm .ant-spin-text {
	padding-top: 2px;
}

.ant-spin-nested-loading>div>.ant-spin-sm.ant-spin-show-text .ant-spin-dot {
	margin-top: -17px;
}

.ant-spin-nested-loading>div>.ant-spin-lg .ant-spin-dot {
	margin: -16px;
}

.ant-spin-nested-loading>div>.ant-spin-lg .ant-spin-text {
	padding-top: 11px;
}

.ant-spin-nested-loading>div>.ant-spin-lg.ant-spin-show-text .ant-spin-dot {
	margin-top: -26px;
}

.ant-spin-container {
	position: relative;
	transition: opacity 0.3s;
}

.ant-spin-container:after {
	background: #fff;
	bottom: 0;
	content: '';
	display: none\9;
	height: 100%;
	left: 0;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	right: 0;
	top: 0;
	transition: all 0.3s;
	width: 100%;
	z-index: 10;
}

.ant-spin-blur {
	clear: both;
	opacity: 0.5;
	overflow: hidden;
	pointer-events: none;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ant-spin-blur:after {
	opacity: 0.4;
	pointer-events: auto;
}

.ant-spin-tip {
	color: #595959;
}

.ant-spin-dot {
	display: inline-block;
	font-size: 20px;
	height: 1em;
	position: relative;
	width: 1em;
}

.ant-spin-dot-item {
	-webkit-animation: antSpinMove 1s linear infinite alternate;
	animation: antSpinMove 1s linear infinite alternate;
	background-color: #038fde;
	border-radius: 100%;
	display: block;
	height: 9px;
	opacity: 0.3;
	position: absolute;
	-webkit-transform: scale(0.75);
	transform: scale(0.75);
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	width: 9px;
}

.ant-spin-dot-item:first-child {
	left: 0;
	top: 0;
}

.ant-spin-dot-item:nth-child(2) {
	-webkit-animation-delay: 0.4s;
	animation-delay: 0.4s;
	right: 0;
	top: 0;
}

.ant-spin-dot-item:nth-child(3) {
	-webkit-animation-delay: 0.8s;
	animation-delay: 0.8s;
	bottom: 0;
	right: 0;
}

.ant-spin-dot-item:nth-child(4) {
	-webkit-animation-delay: 1.2s;
	animation-delay: 1.2s;
	bottom: 0;
	left: 0;
}

.ant-spin-dot-spin {
	-webkit-animation: antRotate 1.2s linear infinite;
	animation: antRotate 1.2s linear infinite;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
}

.ant-spin-sm .ant-spin-dot {
	font-size: 14px;
}

.ant-spin-sm .ant-spin-dot i {
	height: 6px;
	width: 6px;
}

.ant-spin-lg .ant-spin-dot {
	font-size: 32px;
}

.ant-spin-lg .ant-spin-dot i {
	height: 14px;
	width: 14px;
}

.ant-spin.ant-spin-show-text .ant-spin-text {
	display: block;
}

@media (-ms-high-contrast: active),
(-ms-high-contrast: none) {
	.ant-spin-blur {
		background: #fff;
		opacity: 0.5;
	}
}

@-webkit-keyframes antSpinMove {
	to {
		opacity: 1;
	}
}

@keyframes antSpinMove {
	to {
		opacity: 1;
	}
}

@-webkit-keyframes antRotate {
	to {
		-webkit-transform: rotate(405deg);
		transform: rotate(405deg);
	}
}

@keyframes antRotate {
	to {
		-webkit-transform: rotate(405deg);
		transform: rotate(405deg);
	}
}

.ant-spin-rtl {
	direction: rtl;
}

.ant-spin-rtl .ant-spin-dot-spin {
	-webkit-animation-name: antRotateRtl;
	animation-name: antRotateRtl;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
}

@-webkit-keyframes antRotateRtl {
	to {
		-webkit-transform: rotate(-405deg);
		transform: rotate(-405deg);
	}
}

@keyframes antRotateRtl {
	to {
		-webkit-transform: rotate(-405deg);
		transform: rotate(-405deg);
	}
}

.ant-statistic {
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
}

.ant-statistic-title {
	color: #595959;
	font-size: 14px;
	margin-bottom: 4px;
}

.ant-statistic-content {
	color: #535353;
	font-family: NoirPro, sans-serif;
	font-size: 24px;
}

.ant-statistic-content-value {
	direction: ltr;
	display: inline-block;
}

.ant-statistic-content-prefix,
.ant-statistic-content-suffix {
	display: inline-block;
}

.ant-statistic-content-prefix {
	margin-right: 4px;
}

.ant-statistic-content-suffix {
	margin-left: 4px;
}

.ant-statistic-rtl {
	direction: rtl;
}

.ant-statistic-rtl .ant-statistic-content-prefix {
	margin-left: 4px;
	margin-right: 0;
}

.ant-statistic-rtl .ant-statistic-content-suffix {
	margin-left: 0;
	margin-right: 4px;
}

.ant-steps {
	box-sizing: border-box;
	color: #545454;
	display: flex;
	font-size: 14px;
	font-size: 0;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: left;
	text-align: initial;
	width: 100%;
}

.ant-steps-item {
	display: inline-block;
	flex: 1 1;
	overflow: hidden;
	position: relative;
	vertical-align: top;
}

.ant-steps-item-container {
	outline: none;
}

.ant-steps-item:last-child {
	flex: none;
}

.ant-steps-item:last-child>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after,
.ant-steps-item:last-child>.ant-steps-item-container>.ant-steps-item-tail {
	display: none;
}

.ant-steps-item-content,
.ant-steps-item-icon {
	display: inline-block;
	vertical-align: top;
}

.ant-steps-item-icon {
	border: 1px solid rgba(0, 0, 0, 0.25);
	border-radius: 32px;
	font-family: NoirPro, sans-serif;
	font-size: 16px;
	height: 32px;
	line-height: 32px;
	margin: 0 8px 0 0;
	text-align: center;
	transition: background-color 0.3s, border-color 0.3s;
	width: 32px;
}

.ant-steps-item-icon .ant-steps-icon {
	color: #038fde;
	line-height: 1;
	position: relative;
	top: -0.5px;
}

.ant-steps-item-tail {
	left: 0;
	padding: 0 10px;
	position: absolute;
	top: 12px;
	width: 100%;
}

.ant-steps-item-tail:after {
	background: #e8e8e8;
	border-radius: 1px;
	content: '';
	display: inline-block;
	height: 1px;
	transition: background 0.3s;
	width: 100%;
}

.ant-steps-item-title {
	color: #545454;
	display: inline-block;
	font-size: 16px;
	line-height: 32px;
	padding-right: 16px;
	position: relative;
}

.ant-steps-item-title:after {
	background: #e8e8e8;
	content: '';
	display: block;
	height: 1px;
	left: 100%;
	position: absolute;
	top: 16px;
	width: 9999px;
}

.ant-steps-item-subtitle {
	display: inline;
	font-weight: 400;
	margin-left: 8px;
}

.ant-steps-item-description,
.ant-steps-item-subtitle {
	color: #595959;
	font-size: 14px;
}

.ant-steps-item-wait .ant-steps-item-icon {
	background-color: #fff;
	border-color: rgba(0, 0, 0, 0.25);
}

.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon {
	color: rgba(0, 0, 0, 0.25);
}

.ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
	background: rgba(0, 0, 0, 0.25);
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
	color: #595959;
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
	background-color: #e8e8e8;
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
	color: #595959;
}

.ant-steps-item-wait>.ant-steps-item-container>.ant-steps-item-tail:after {
	background-color: #e8e8e8;
}

.ant-steps-item-process .ant-steps-item-icon {
	background-color: #fff;
	border-color: #038fde;
}

.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon {
	color: #038fde;
}

.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
	background: #038fde;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
	color: #535353;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
	background-color: #e8e8e8;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
	color: #545454;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-tail:after {
	background-color: #e8e8e8;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
	background: #038fde;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon .ant-steps-icon {
	color: #fff;
}

.ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-title {
	font-weight: 500;
}

.ant-steps-item-finish .ant-steps-item-icon {
	background-color: #fff;
	border-color: #038fde;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon {
	color: #038fde;
}

.ant-steps-item-finish .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
	background: #038fde;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
	color: #545454;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
	background-color: #038fde;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
	color: #595959;
}

.ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail:after {
	background-color: #038fde;
}

.ant-steps-item-error .ant-steps-item-icon {
	background-color: #fff;
	border-color: #f5222d;
}

.ant-steps-item-error .ant-steps-item-icon>.ant-steps-icon {
	color: #f5222d;
}

.ant-steps-item-error .ant-steps-item-icon>.ant-steps-icon .ant-steps-icon-dot {
	background: #f5222d;
}

.ant-steps-item-error>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title {
	color: #f5222d;
}

.ant-steps-item-error>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
	background-color: #e8e8e8;
}

.ant-steps-item-error>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-description {
	color: #f5222d;
}

.ant-steps-item-error>.ant-steps-item-container>.ant-steps-item-tail:after {
	background-color: #e8e8e8;
}

.ant-steps-item.ant-steps-next-error .ant-steps-item-title:after {
	background: #f5222d;
}

.ant-steps-item-disabled {
	cursor: not-allowed;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button'] {
	cursor: pointer;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button'] .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button'] .ant-steps-item-icon .ant-steps-icon,
.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button'] .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button'] .ant-steps-item-title {
	transition: color 0.3s;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-description,
.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
.ant-steps .ant-steps-item:not(.ant-steps-item-active)>.ant-steps-item-container[role='button']:hover .ant-steps-item-title {
	color: #038fde;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon {
	border-color: #038fde;
}

.ant-steps .ant-steps-item:not(.ant-steps-item-active):not(.ant-steps-item-process)>.ant-steps-item-container[role='button']:hover .ant-steps-item-icon .ant-steps-icon {
	color: #038fde;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
	padding-left: 16px;
	white-space: nowrap;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
	padding-left: 0;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
	padding-right: 0;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
	display: none;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-description {
	max-width: 140px;
	white-space: normal;
}

.ant-steps-item-custom>.ant-steps-item-container>.ant-steps-item-icon {
	background: none;
	border: 0;
	height: auto;
}

.ant-steps-item-custom>.ant-steps-item-container>.ant-steps-item-icon>.ant-steps-icon {
	font-size: 24px;
	height: 32px;
	left: 0.5px;
	line-height: 32px;
	top: 0;
	width: 32px;
}

.ant-steps-item-custom.ant-steps-item-process .ant-steps-item-icon>.ant-steps-icon {
	color: #038fde;
}

.ant-steps:not(.ant-steps-vertical) .ant-steps-item-custom .ant-steps-item-icon {
	background: none;
	width: auto;
}

.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
	padding-left: 12px;
}

.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
	padding-left: 0;
}

.ant-steps-small .ant-steps-item-icon {
	border-radius: 24px;
	font-size: 12px;
	height: 24px;
	line-height: 24px;
	margin: 0 8px 0 0;
	text-align: center;
	width: 24px;
}

.ant-steps-small .ant-steps-item-title {
	font-size: 14px;
	line-height: 24px;
	padding-right: 12px;
}

.ant-steps-small .ant-steps-item-title:after {
	top: 12px;
}

.ant-steps-small .ant-steps-item-description {
	color: #595959;
	font-size: 14px;
}

.ant-steps-small .ant-steps-item-tail {
	top: 8px;
}

.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon {
	background: none;
	border: 0;
	border-radius: 0;
	height: inherit;
	line-height: inherit;
	width: inherit;
}

.ant-steps-small .ant-steps-item-custom .ant-steps-item-icon>.ant-steps-icon {
	font-size: 24px;
	line-height: 24px;
	-webkit-transform: none;
	transform: none;
}

.ant-steps-vertical {
	display: flex;
	flex-direction: column;
}

.ant-steps-vertical>.ant-steps-item {
	display: block;
	flex: 1 0 auto;
	overflow: visible;
	padding-left: 0;
}

.ant-steps-vertical>.ant-steps-item .ant-steps-item-icon {
	float: left;
	margin-right: 16px;
}

.ant-steps-vertical>.ant-steps-item .ant-steps-item-content {
	display: block;
	min-height: 48px;
	overflow: hidden;
}

.ant-steps-vertical>.ant-steps-item .ant-steps-item-title {
	line-height: 32px;
}

.ant-steps-vertical>.ant-steps-item .ant-steps-item-description {
	padding-bottom: 12px;
}

.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
	height: 100%;
	left: 16px;
	padding: 38px 0 6px;
	position: absolute;
	top: 0;
	width: 1px;
}

.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail:after {
	height: 100%;
	width: 1px;
}

.ant-steps-vertical>.ant-steps-item:not(:last-child)>.ant-steps-item-container>.ant-steps-item-tail {
	display: block;
}

.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-content>.ant-steps-item-title:after {
	display: none;
}

.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
	left: 12px;
	padding: 30px 0 6px;
	position: absolute;
	top: 0;
}

.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-title {
	line-height: 24px;
}

.ant-steps-label-vertical .ant-steps-item {
	overflow: visible;
}

.ant-steps-label-vertical .ant-steps-item-tail {
	margin-left: 58px;
	padding: 3.5px 24px;
}

.ant-steps-label-vertical .ant-steps-item-content {
	display: block;
	margin-top: 8px;
	text-align: center;
	width: 116px;
}

.ant-steps-label-vertical .ant-steps-item-icon {
	display: inline-block;
	margin-left: 42px;
}

.ant-steps-label-vertical .ant-steps-item-title {
	padding-left: 0;
	padding-right: 0;
}

.ant-steps-label-vertical .ant-steps-item-title:after {
	display: none;
}

.ant-steps-label-vertical .ant-steps-item-subtitle {
	display: block;
	line-height: 1.3;
	margin-bottom: 4px;
	margin-left: 0;
}

.ant-steps-label-vertical.ant-steps-small:not(.ant-steps-dot) .ant-steps-item-icon {
	margin-left: 46px;
}

.ant-steps-dot .ant-steps-item-title,
.ant-steps-dot.ant-steps-small .ant-steps-item-title {
	line-height: 1.3;
}

.ant-steps-dot .ant-steps-item-tail,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
	margin: 0 0 0 70px;
	padding: 0;
	top: 2px;
	width: 100%;
}

.ant-steps-dot .ant-steps-item-tail:after,
.ant-steps-dot.ant-steps-small .ant-steps-item-tail:after {
	height: 3px;
	margin-left: 12px;
	width: calc(100% - 20px);
}

.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
	left: 2px;
}

.ant-steps-dot .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
	background: transparent;
	border: 0;
	height: 8px;
	line-height: 8px;
	margin-left: 67px;
	padding-right: 0;
	width: 8px;
}

.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
	border-radius: 100px;
	float: left;
	height: 100%;
	position: relative;
	transition: all 0.3s;
	width: 100%;
}

.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot:after,
.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot:after {
	background: rgba(0, 0, 0, 0.001);
	content: '';
	height: 32px;
	left: -26px;
	position: absolute;
	top: -12px;
	width: 60px;
}

.ant-steps-dot .ant-steps-item-content,
.ant-steps-dot.ant-steps-small .ant-steps-item-content {
	width: 140px;
}

.ant-steps-dot .ant-steps-item-process .ant-steps-item-icon,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-item-icon {
	background: none;
	height: 10px;
	line-height: 10px;
	position: relative;
	top: -1px;
	width: 10px;
}

.ant-steps-dot .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot,
.ant-steps-dot.ant-steps-small .ant-steps-item-process .ant-steps-icon:first-child .ant-steps-icon-dot {
	left: 0;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
	background: none;
	margin-left: 0;
	margin-top: 8px;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
	left: -9px;
	margin: 0;
	padding: 22px 0 4px;
	top: 2px;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
	left: 0;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item-content {
	width: inherit;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon-dot {
	left: -2px;
}

.ant-steps-navigation {
	padding-top: 12px;
}

.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
	margin-left: -12px;
}

.ant-steps-navigation .ant-steps-item {
	overflow: visible;
	text-align: center;
}

.ant-steps-navigation .ant-steps-item-container {
	display: inline-block;
	height: 100%;
	margin-left: -16px;
	padding-bottom: 12px;
	text-align: left;
	transition: opacity 0.3s;
}

.ant-steps-navigation .ant-steps-item-container .ant-steps-item-content {
	max-width: auto;
}

.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
	max-width: 100%;
	overflow: hidden;
	padding-right: 0;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title:after {
	display: none;
}

.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button'] {
	cursor: pointer;
}

.ant-steps-navigation .ant-steps-item:not(.ant-steps-item-active) .ant-steps-item-container[role='button']:hover {
	opacity: 0.85;
}

.ant-steps-navigation .ant-steps-item:last-child {
	flex: 1 1;
}

.ant-steps-navigation .ant-steps-item:last-child:after {
	display: none;
}

.ant-steps-navigation .ant-steps-item:after {
	border: 1px solid rgba(0, 0, 0, 0.25);
	border-bottom: none;
	border-left: none;
	content: '';
	display: inline-block;
	height: 12px;
	left: 100%;
	margin-left: -2px;
	margin-top: -14px;
	position: absolute;
	top: 50%;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	width: 12px;
}

.ant-steps-navigation .ant-steps-item:before {
	background-color: #038fde;
	bottom: 0;
	content: '';
	display: inline-block;
	height: 2px;
	left: 50%;
	position: absolute;
	transition: width 0.3s, left 0.3s;
	transition-timing-function: ease-out;
	width: 0;
}

.ant-steps-navigation .ant-steps-item.ant-steps-item-active:before {
	left: 0;
	width: 100%;
}

.ant-steps-navigation.ant-steps-vertical>.ant-steps-item {
	margin-right: 0 !important;
}

.ant-steps-navigation.ant-steps-vertical>.ant-steps-item:before {
	display: none;
}

.ant-steps-navigation.ant-steps-vertical>.ant-steps-item.ant-steps-item-active:before {
	display: block;
	height: calc(100% - 24px);
	left: unset;
	right: 0;
	top: 0;
	width: 3px;
}

.ant-steps-navigation.ant-steps-vertical>.ant-steps-item:after {
	display: block;
	height: 8px;
	left: 50%;
	margin-bottom: 8px;
	position: relative;
	text-align: center;
	top: -2px;
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg);
	width: 8px;
}

.ant-steps-navigation.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
	visibility: hidden;
}

.ant-steps-rtl {
	direction: rtl;
}

.ant-steps.ant-steps-rtl .ant-steps-item-icon {
	margin-left: 8px;
	margin-right: 0;
}

.ant-steps-rtl .ant-steps-item-tail {
	left: auto;
	right: 0;
}

.ant-steps-rtl .ant-steps-item-title {
	padding-left: 16px;
	padding-right: 0;
}

.ant-steps-rtl .ant-steps-item-title:after {
	left: auto;
	right: 100%;
}

.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
	padding-left: 0;
	padding-right: 16px;
}

.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
	padding-right: 0;
}

.ant-steps-rtl.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:last-child .ant-steps-item-title {
	padding-left: 0;
}

.ant-steps-rtl .ant-steps-item-custom .ant-steps-item-icon>.ant-steps-icon {
	left: auto;
	right: 0.5px;
}

.ant-steps-rtl.ant-steps-navigation.ant-steps-small .ant-steps-item-container {
	margin-left: 0;
	margin-right: -12px;
}

.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container {
	margin-left: 0;
	margin-right: -16px;
	text-align: right;
}

.ant-steps-rtl.ant-steps-navigation .ant-steps-item-container .ant-steps-item-title {
	padding-left: 0;
}

.ant-steps-rtl.ant-steps-navigation .ant-steps-item:after {
	left: auto;
	margin-left: 0;
	margin-right: -2px;
	right: 100%;
	-webkit-transform: rotate(225deg);
	transform: rotate(225deg);
}

.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
	padding-left: 0;
	padding-right: 12px;
}

.ant-steps-rtl.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item:first-child {
	padding-right: 0;
}

.ant-steps-rtl.ant-steps-small .ant-steps-item-title {
	padding-left: 12px;
	padding-right: 0;
}

.ant-steps-rtl.ant-steps-vertical>.ant-steps-item .ant-steps-item-icon {
	float: right;
	margin-left: 16px;
	margin-right: 0;
}

.ant-steps-rtl.ant-steps-vertical>.ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
	left: auto;
	right: 16px;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-small .ant-steps-item-container .ant-steps-item-tail {
	left: auto;
	right: 12px;
}

.ant-steps-rtl.ant-steps-label-vertical .ant-steps-item-title {
	padding-left: 0;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail {
	margin: 0 70px 0 0;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-tail:after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-tail:after {
	margin-left: 0;
	margin-right: 12px;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item:first-child .ant-steps-icon-dot {
	left: auto;
	right: 2px;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon {
	margin-left: 0;
	margin-right: 67px;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot {
	float: right;
}

.ant-steps-rtl.ant-steps-dot .ant-steps-item-icon .ant-steps-icon-dot:after,
.ant-steps-rtl.ant-steps-dot.ant-steps-small .ant-steps-item-icon .ant-steps-icon-dot:after {
	left: auto;
	right: -26px;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-icon {
	margin-left: 16px;
	margin-right: 0;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item>.ant-steps-item-container>.ant-steps-item-tail {
	left: auto;
	right: -9px;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item:first-child .ant-steps-icon-dot {
	left: auto;
	right: 0;
}

.ant-steps-rtl.ant-steps-vertical.ant-steps-dot .ant-steps-item-process .ant-steps-icon-dot {
	left: auto;
	right: -2px;
}

.ant-steps-with-progress .ant-steps-item {
	padding-top: 4px;
}

.ant-steps-with-progress .ant-steps-item .ant-steps-item-tail {
	top: 4px !important;
}

.ant-steps-with-progress.ant-steps-horizontal .ant-steps-item:first-child {
	padding-left: 4px;
}

.ant-steps-with-progress .ant-steps-item-icon {
	position: relative;
}

.ant-steps-with-progress .ant-steps-item-icon .ant-progress {
	bottom: -5px;
	left: -5px;
	position: absolute;
	right: -5px;
	top: -5px;
}

.ant-switch {
	background-color: rgba(0, 0, 0, 0.25);
	border: 0;
	border-radius: 100px;
	box-sizing: border-box;
	color: #545454;
	cursor: pointer;
	display: inline-block;
	font-size: 14px;
	font-variant: tabular-nums;
	height: 22px;
	line-height: 1.3;
	line-height: 22px;
	list-style: none;
	margin: 0;
	min-width: 44px;
	padding: 0;
	position: relative;
	transition: all 0.2s;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	vertical-align: middle;
}

.ant-switch:focus {
	box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
	outline: 0;
}

.ant-switch-checked:focus {
	box-shadow: 0 0 0 2px rgba(3, 143, 222, 0.2);
}

.ant-switch:focus:hover {
	box-shadow: none;
}

.ant-switch-checked {
	background-color: #038fde;
}

.ant-switch-disabled,
.ant-switch-loading {
	cursor: not-allowed;
	opacity: 0.4;
}

.ant-switch-disabled *,
.ant-switch-loading * {
	box-shadow: none;
	cursor: not-allowed;
}

.ant-switch-inner {
	color: #fff;
	display: block;
	font-size: 12px;
	margin: 0 7px 0 25px;
	transition: margin 0.2s;
}

.ant-switch-checked .ant-switch-inner {
	margin: 0 25px 0 7px;
}

.ant-switch-handle {
	height: 18px;
	left: 2px;
	top: 2px;
	width: 18px;
}

.ant-switch-handle,
.ant-switch-handle:before {
	position: absolute;
	transition: all 0.2s ease-in-out;
}

.ant-switch-handle:before {
	background-color: #fff;
	border-radius: 9px;
	bottom: 0;
	box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
	content: '';
	left: 0;
	right: 0;
	top: 0;
}

.ant-switch-checked .ant-switch-handle {
	left: calc(100% - 20px);
}

.ant-switch:not(.ant-switch-disabled):active .ant-switch-handle:before {
	left: 0;
	right: -30%;
}

.ant-switch:not(.ant-switch-disabled):active.ant-switch-checked .ant-switch-handle:before {
	left: -30%;
	right: 0;
}

.ant-switch-loading-icon {
	color: rgba(0, 0, 0, 0.65);
	position: relative;
	top: 2px;
	vertical-align: top;
}

.ant-switch-checked .ant-switch-loading-icon {
	color: #038fde;
}

.ant-switch-small {
	height: 16px;
	line-height: 16px;
	min-width: 28px;
}

.ant-switch-small .ant-switch-inner {
	font-size: 12px;
	margin: 0 5px 0 18px;
}

.ant-switch-small .ant-switch-handle {
	height: 12px;
	width: 12px;
}

.ant-switch-small .ant-switch-loading-icon {
	font-size: 9px;
	top: 1.5px;
}

.ant-switch-small.ant-switch-checked .ant-switch-inner {
	margin: 0 18px 0 5px;
}

.ant-switch-small.ant-switch-checked .ant-switch-handle {
	left: calc(100% - 14px);
}

.ant-switch-rtl {
	direction: rtl;
}

.ant-switch-rtl .ant-switch-inner {
	margin: 0 25px 0 7px;
}

.ant-switch-rtl .ant-switch-handle {
	left: auto;
	right: 2px;
}

.ant-switch-rtl:not(.ant-switch-rtl-disabled):active .ant-switch-handle:before {
	left: -30%;
	right: 0;
}

.ant-switch-rtl:not(.ant-switch-rtl-disabled):active.ant-switch-checked .ant-switch-handle:before {
	left: 0;
	right: -30%;
}

.ant-switch-rtl.ant-switch-checked .ant-switch-inner {
	margin: 0 7px 0 25px;
}

.ant-switch-rtl.ant-switch-checked .ant-switch-handle {
	right: calc(100% - 20px);
}

.ant-switch-rtl.ant-switch-small.ant-switch-checked .ant-switch-handle {
	right: calc(100% - 14px);
}

.ant-table.ant-table-middle {
	font-size: 14px;
}

.ant-table.ant-table-middle .ant-table-footer,
.ant-table.ant-table-middle .ant-table-tbody>tr>td,
.ant-table.ant-table-middle .ant-table-thead>tr>th,
.ant-table.ant-table-middle .ant-table-title,
.ant-table.ant-table-middle tfoot>tr>td,
.ant-table.ant-table-middle tfoot>tr>th {
	padding: 12px 8px;
}

.ant-table.ant-table-middle .ant-table-thead th.ant-table-column-has-sorters {
	padding: 0;
}

.ant-table.ant-table-middle .ant-table-thead .ant-table-filter-column {
	margin: -12px -8px;
}

.ant-table.ant-table-middle .ant-table-thead .ant-table-filter-column-title {
	padding: 12px 2.3em 12px 8px;
}

.ant-table.ant-table-middle .ant-table-thead .ant-table-column-sorters {
	padding: 12px 8px;
}

.ant-table.ant-table-middle .ant-table-expanded-row-fixed {
	margin: -12px -8px;
}

.ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
	margin: -12px -8px -12px 25px;
}

.ant-table.ant-table-small {
	font-size: 14px;
}

.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-tbody>tr>td,
.ant-table.ant-table-small .ant-table-thead>tr>th,
.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small tfoot>tr>td,
.ant-table.ant-table-small tfoot>tr>th {
	padding: 8px;
}

.ant-table.ant-table-small .ant-table-thead th.ant-table-column-has-sorters {
	padding: 0;
}

.ant-table.ant-table-small .ant-table-thead .ant-table-filter-column {
	margin: -8px;
}

.ant-table.ant-table-small .ant-table-thead .ant-table-filter-column-title {
	padding: 8px 2.3em 8px 8px;
}

.ant-table.ant-table-small .ant-table-thead .ant-table-column-sorters {
	padding: 8px;
}

.ant-table.ant-table-small .ant-table-expanded-row-fixed {
	margin: -8px;
}

.ant-table.ant-table-small .ant-table-tbody .ant-table-wrapper:only-child .ant-table {
	margin: -8px -8px -8px 25px;
}

.ant-table-small .ant-table-thead>tr>th {
	background-color: #fafafa;
}

.ant-table-small .ant-table-selection-column {
	min-width: 46px;
	width: 46px;
}

.ant-table.ant-table-bordered>.ant-table-title {
	border: 1px solid #e8e8e8;
	border-bottom: 0;
}

.ant-table.ant-table-bordered>.ant-table-container {
	border: 1px solid #e8e8e8;
	border-bottom: 0;
	border-right: 0;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th {
	border-right: 1px solid #e8e8e8;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr:not(:last-child)>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr:not(:last-child)>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr:not(:last-child)>th {
	border-bottom: 1px solid #e8e8e8;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>.ant-table-cell-fix-right-first:after,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>.ant-table-cell-fix-right-first:after,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>.ant-table-cell-fix-right-first:after,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>.ant-table-cell-fix-right-first:after,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>.ant-table-cell-fix-right-first:after,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>.ant-table-cell-fix-right-first:after,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>.ant-table-cell-fix-right-first:after,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>.ant-table-cell-fix-right-first:after,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>.ant-table-cell-fix-right-first:after {
	border-right: 1px solid #e8e8e8;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td>.ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td>.ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td>.ant-table-expanded-row-fixed {
	margin: -16px -17px;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td>.ant-table-expanded-row-fixed:after,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td>.ant-table-expanded-row-fixed:after,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td>.ant-table-expanded-row-fixed:after {
	border-right: 1px solid #e8e8e8;
	bottom: 0;
	content: '';
	position: absolute;
	right: 1px;
	top: 0;
}

.ant-table.ant-table-bordered.ant-table-scroll-horizontal>.ant-table-container>.ant-table-body>table>tbody>tr.ant-table-expanded-row>td,
.ant-table.ant-table-bordered.ant-table-scroll-horizontal>.ant-table-container>.ant-table-body>table>tbody>tr.ant-table-placeholder>td {
	border-right: 0;
}

.ant-table.ant-table-bordered.ant-table-middle>.ant-table-container>.ant-table-body>table>tbody>tr>td>.ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-middle>.ant-table-container>.ant-table-content>table>tbody>tr>td>.ant-table-expanded-row-fixed {
	margin: -12px -9px;
}

.ant-table.ant-table-bordered.ant-table-small>.ant-table-container>.ant-table-body>table>tbody>tr>td>.ant-table-expanded-row-fixed,
.ant-table.ant-table-bordered.ant-table-small>.ant-table-container>.ant-table-content>table>tbody>tr>td>.ant-table-expanded-row-fixed {
	margin: -8px -9px;
}

.ant-table.ant-table-bordered>.ant-table-footer {
	border: 1px solid #e8e8e8;
	border-top: 0;
}

.ant-table-cell .ant-table-container:first-child {
	border-top: 0;
}

.ant-table-cell-scrollbar {
	box-shadow: 0 1px 0 1px #fafafa;
}

.ant-table-wrapper {
	clear: both;
	max-width: 100%;
}

.ant-table-wrapper:before {
	content: '';
	display: table;
}

.ant-table-wrapper:after {
	clear: both;
	content: '';
	display: table;
}

.ant-table {
	background: #fff;
	border-radius: 6px;
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}

.ant-table table {
	border-collapse: separate;
	border-radius: 6px 6px 0 0;
	border-spacing: 0;
	text-align: left;
	width: 100%;
}

.ant-table tfoot>tr>td,
.ant-table tfoot>tr>th,
.ant-table-tbody>tr>td,
.ant-table-thead>tr>th {
	overflow-wrap: break-word;
	padding: 16px;
	position: relative;
}

.ant-table-cell-ellipsis {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	word-break: keep-all;
}

.ant-table-cell-ellipsis.ant-table-cell-fix-left-last,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first {
	overflow: visible;
}

.ant-table-cell-ellipsis.ant-table-cell-fix-left-last .ant-table-cell-content,
.ant-table-cell-ellipsis.ant-table-cell-fix-right-first .ant-table-cell-content {
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ant-table-title {
	padding: 16px;
}

.ant-table-footer {
	background: #fafafa;
	color: #535353;
	padding: 16px;
}

.ant-table-thead>tr>th {
	background: #fafafa;
	border-bottom: 1px solid #e8e8e8;
	color: #535353;
	font-weight: 500;
	text-align: left;
	transition: background 0.3s ease;
}

.ant-table-thead>tr>th[colspan]:not([colspan='1']) {
	text-align: center;
}

.ant-table-thead>tr:not(:last-child)>th[colspan] {
	border-bottom: 0;
}

.ant-table-tbody>tr>td {
	border-bottom: 1px solid #e8e8e8;
	transition: background 0.3s;
}

.ant-table-tbody>tr>td>.ant-table-wrapper:only-child .ant-table {
	margin: -16px -16px -16px 33px;
}

.ant-table-tbody>tr>td>.ant-table-wrapper:only-child .ant-table-tbody>tr:last-child>td {
	border-bottom: 0;
}

.ant-table-tbody>tr>td>.ant-table-wrapper:only-child .ant-table-tbody>tr:last-child>td:first-child,
.ant-table-tbody>tr>td>.ant-table-wrapper:only-child .ant-table-tbody>tr:last-child>td:last-child {
	border-radius: 0;
}

.ant-table-tbody>tr.ant-table-row:hover>td {
	background: #fafafa;
}

.ant-table-tbody>tr.ant-table-row-selected>td {
	background: #e6faff;
	border-color: rgba(0, 0, 0, 0.03);
}

.ant-table-tbody>tr.ant-table-row-selected:hover>td {
	background: #dcf8ff;
}

.ant-table tfoot>tr>td,
.ant-table tfoot>tr>th {
	border-bottom: 1px solid #e8e8e8;
}

.ant-table-pagination.ant-pagination {
	margin: 16px 0;
}

.ant-table-pagination {
	grid-row-gap: 8px;
	display: flex;
	flex-wrap: wrap;
	row-gap: 8px;
}

.ant-table-pagination>* {
	flex: none;
}

.ant-table-pagination-left {
	justify-content: flex-start;
}

.ant-table-pagination-center {
	justify-content: center;
}

.ant-table-pagination-right {
	justify-content: flex-end;
}

.ant-table-thead th.ant-table-column-has-sorters {
	cursor: pointer;
	padding: 0;
	transition: all 0.3s;
}

.ant-table-thead th.ant-table-column-has-sorters:hover {
	background: #f2f2f2;
}

.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container {
	background: #f7f7f7;
}

.ant-table-thead th.ant-table-column-sort {
	background: #f5f5f5;
}

td.ant-table-column-sort {
	background: #fafafa;
}

.ant-table-column-sorters-with-tooltip {
	display: inline-block;
	width: 100%;
}

.ant-table-column-sorters {
	align-items: center;
	display: inline-flex;
	padding: 16px;
}

.ant-table-column-sorter {
	color: #bfbfbf;
	margin-bottom: -0.15em;
	margin-left: 8px;
	margin-top: 0.15em;
}

.ant-table-column-sorter-full {
	margin-bottom: 0;
	margin-top: -0.2em;
}

.ant-table-column-sorter-inner {
	align-items: center;
	display: inline-flex;
	flex-direction: column;
}

.ant-table-column-sorter-down,
.ant-table-column-sorter-up {
	font-size: 11px;
}

.ant-table-column-sorter-down.active,
.ant-table-column-sorter-up.active {
	color: #038fde;
}

.ant-table-column-sorter-up+.ant-table-column-sorter-down {
	margin-top: -0.3em;
}

.ant-table-filter-column {
	align-items: center;
	display: flex;
	margin: -16px;
}

.ant-table-filter-column-title {
	flex: auto;
	padding: 16px 2.3em 16px 16px;
}

.ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column {
	margin: 0;
}

.ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
	padding: 0 2.3em 0 0;
}

.ant-table-filter-trigger-container {
	align-items: stretch;
	align-self: stretch;
	bottom: 0;
	cursor: pointer;
	display: flex;
	flex: none;
	position: absolute;
	right: 0;
	top: 0;
	transition: background-color 0.3s;
}

.ant-table-filter-trigger-container-open,
.ant-table-filter-trigger-container:hover,
.ant-table-thead th.ant-table-column-has-sorters:hover .ant-table-filter-trigger-container:hover {
	background: #e5e5e5;
}

.ant-table-filter-trigger {
	color: #bfbfbf;
	display: block;
	font-size: 12px;
	transition: color 0.3s;
	width: 2.3em;
}

.ant-table-filter-trigger .anticon {
	left: 50%;
	position: absolute;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.ant-table-filter-trigger-container-open .ant-table-filter-trigger,
.ant-table-filter-trigger:hover {
	color: #595959;
}

.ant-table-filter-trigger.active {
	color: #038fde;
}

.ant-table-filter-dropdown {
	background-color: #fff;
	border-radius: 6px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	min-width: 120px;
	padding: 0;
}

.ant-table-filter-dropdown .ant-dropdown-menu {
	border: 0;
	box-shadow: none;
	max-height: 264px;
	overflow-x: hidden;
}

.ant-table-filter-dropdown-submenu>ul {
	max-height: calc(100vh - 130px);
	overflow-x: hidden;
	overflow-y: auto;
}

.ant-table-filter-dropdown .ant-checkbox-wrapper+span,
.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper+span {
	padding-left: 8px;
}

.ant-table-filter-dropdown-btns {
	background-color: inherit;
	border-top: 1px solid #e8e8e8;
	display: flex;
	justify-content: space-between;
	overflow: hidden;
	padding: 7px 8px 7px 3px;
}

.ant-table .ant-table-selection-col {
	width: 32px;
}

table tr td.ant-table-selection-column,
table tr th.ant-table-selection-column {
	padding-left: 8px;
	padding-right: 8px;
	text-align: center;
}

table tr td.ant-table-selection-column .ant-radio-wrapper,
table tr th.ant-table-selection-column .ant-radio-wrapper {
	margin-right: 0;
}

.ant-table-selection {
	display: inline-flex;
	flex-direction: column;
	position: relative;
}

.ant-table-selection-extra {
	-webkit-margin-start: 100%;
	-webkit-padding-start: 4px;
	cursor: pointer;
	margin-inline-start: 100%;
	padding-inline-start: 4px;
	position: absolute;
	top: 0;
	transition: all 0.3s;
	z-index: 1;
}

.ant-table-selection-extra .anticon {
	color: #bfbfbf;
	font-size: 10px;
}

.ant-table-selection-extra .anticon:hover {
	color: #a6a6a6;
}

.ant-table-expand-icon-col {
	width: 48px;
}

.ant-table-row-expand-icon-cell {
	text-align: center;
}

.ant-table-row-indent {
	float: left;
	height: 1px;
}

.ant-table-row-expand-icon {
	background: #fff;
	border: 1px solid #e8e8e8;
	border-radius: 6px;
	box-sizing: border-box;
	color: #038fde;
	color: inherit;
	cursor: pointer;
	display: inline-flex;
	float: left;
	height: 17px;
	line-height: 17px;
	outline: none;
	padding: 0;
	position: relative;
	text-decoration: none;
	-webkit-transform: scale(0.94117647);
	transform: scale(0.94117647);
	-webkit-transform-origin: bottom;
	transform-origin: bottom;
	transition: color 0.3s;
	transition: all 0.3s;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	width: 17px;
}

.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
	color: #28aaeb;
}

.ant-table-row-expand-icon:active {
	color: #006eb8;
}

.ant-table-row-expand-icon:active,
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
	border-color: currentColor;
}

.ant-table-row-expand-icon:after,
.ant-table-row-expand-icon:before {
	background: currentColor;
	content: '';
	position: absolute;
	transition: -webkit-transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
}

.ant-table-row-expand-icon:before {
	height: 1px;
	left: 3px;
	right: 3px;
	top: 7px;
}

.ant-table-row-expand-icon:after {
	bottom: 3px;
	left: 7px;
	top: 3px;
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
	width: 1px;
}

.ant-table-row-expand-icon-collapsed:before {
	-webkit-transform: rotate(-180deg);
	transform: rotate(-180deg);
}

.ant-table-row-expand-icon-collapsed:after {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
}

.ant-table-row-expand-icon-spaced {
	background: transparent;
	border: 0;
	visibility: hidden;
}

.ant-table-row-expand-icon-spaced:after,
.ant-table-row-expand-icon-spaced:before {
	content: none;
	display: none;
}

.ant-table-row-indent+.ant-table-row-expand-icon {
	margin-right: 8px;
	margin-top: 0.6px;
}

tr.ant-table-expanded-row:hover>td,
tr.ant-table-expanded-row>td {
	background: #fbfbfb;
}

tr.ant-table-expanded-row .ant-descriptions-view {
	display: flex;
}

tr.ant-table-expanded-row .ant-descriptions-view table {
	flex: auto;
	width: auto;
}

.ant-table .ant-table-expanded-row-fixed {
	margin: -16px;
	padding: 16px;
	position: relative;
}

.ant-table-tbody>tr.ant-table-placeholder {
	text-align: center;
}

.ant-table-empty .ant-table-tbody>tr.ant-table-placeholder {
	color: rgba(0, 0, 0, 0.25);
}

.ant-table-tbody>tr.ant-table-placeholder:hover>td {
	background: #fff;
}

.ant-table-cell-fix-left,
.ant-table-cell-fix-right {
	background: #fff;
	position: -webkit-sticky !important;
	position: sticky !important;
	z-index: 2;
}

.ant-table-cell-fix-left-first:after,
.ant-table-cell-fix-left-last:after {
	bottom: -1px;
	content: '';
	pointer-events: none;
	position: absolute;
	right: 0;
	top: 0;
	-webkit-transform: translateX(100%);
	transform: translateX(100%);
	transition: box-shadow 0.3s;
	width: 30px;
}

.ant-table-cell-fix-right-first:after,
.ant-table-cell-fix-right-last:after {
	bottom: -1px;
	content: '';
	left: 0;
	pointer-events: none;
	position: absolute;
	top: 0;
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
	transition: box-shadow 0.3s;
	width: 30px;
}

.ant-table .ant-table-container:after,
.ant-table .ant-table-container:before {
	bottom: 0;
	content: '';
	pointer-events: none;
	position: absolute;
	top: 0;
	transition: box-shadow 0.3s;
	width: 30px;
	z-index: 1;
}

.ant-table .ant-table-container:before {
	left: 0;
}

.ant-table .ant-table-container:after {
	right: 0;
}

.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container {
	position: relative;
}

.ant-table-ping-left .ant-table-cell-fix-left-first:after,
.ant-table-ping-left .ant-table-cell-fix-left-last:after,
.ant-table-ping-left:not(.ant-table-has-fix-left) .ant-table-container:before {
	box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.15);
}

.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container {
	position: relative;
}

.ant-table-ping-right .ant-table-cell-fix-right-first:after,
.ant-table-ping-right .ant-table-cell-fix-right-last:after,
.ant-table-ping-right:not(.ant-table-has-fix-right) .ant-table-container:after {
	box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.15);
}

.ant-table-sticky-header,
.ant-table-sticky-scroll {
	position: -webkit-sticky;
	position: sticky;
	z-index: 3;
}

.ant-table-sticky-scroll {
	align-items: center;
	background: #fff;
	border-top: 1px solid #e8e8e8;
	bottom: 0;
	display: flex;
	opacity: 0.6;
}

.ant-table-sticky-scroll:hover {
	-webkit-transform-origin: center bottom;
	transform-origin: center bottom;
}

.ant-table-sticky-scroll-bar {
	background-color: rgba(0, 0, 0, 0.35);
	border-radius: 4px;
	height: 8px;
}

.ant-table-sticky-scroll-bar-active,
.ant-table-sticky-scroll-bar:hover {
	background-color: rgba(0, 0, 0, 0.8);
}

@media (-ms-high-contrast: none) {

	.ant-table-ping-left .ant-table-cell-fix-left-last:after,
	.ant-table-ping-right .ant-table-cell-fix-right-first:after {
		box-shadow: none !important;
	}
}

.ant-table-title {
	border-radius: 6px 6px 0 0;
}

.ant-table-title+.ant-table-container {
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.ant-table-title+.ant-table-container table>thead>tr:first-child th:first-child,
.ant-table-title+.ant-table-container table>thead>tr:first-child th:last-child {
	border-radius: 0;
}

.ant-table-container {
	border-top-right-radius: 6px;
}

.ant-table-container,
.ant-table-container table>thead>tr:first-child th:first-child {
	border-top-left-radius: 6px;
}

.ant-table-container table>thead>tr:first-child th:last-child {
	border-top-right-radius: 6px;
}

.ant-table-footer {
	border-radius: 0 0 6px 6px;
}

.ant-table-rtl,
.ant-table-wrapper-rtl {
	direction: rtl;
}

.ant-table-wrapper-rtl .ant-table table {
	text-align: right;
}

.ant-table-wrapper-rtl .ant-table-thead>tr>th[colspan]:not([colspan='1']) {
	text-align: center;
}

.ant-table-wrapper-rtl .ant-table-thead>tr>th {
	text-align: right;
}

.ant-table-tbody>tr .ant-table-wrapper:only-child .ant-table.ant-table-rtl {
	margin: -16px 33px -16px -16px;
}

.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-left {
	justify-content: flex-end;
}

.ant-table-wrapper.ant-table-wrapper-rtl .ant-table-pagination-right {
	justify-content: flex-start;
}

.ant-table-wrapper-rtl .ant-table-column-sorter {
	margin-left: 0;
	margin-right: 8px;
}

.ant-table-wrapper-rtl .ant-table-filter-column-title {
	padding: 16px 16px 16px 2.3em;
}

.ant-table-rtl .ant-table-thead tr th.ant-table-column-has-sorters .ant-table-filter-column-title {
	padding: 0 0 0 2.3em;
}

.ant-table-wrapper-rtl .ant-table-filter-trigger-container {
	left: 0;
	right: auto;
}

.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown .ant-checkbox-wrapper+span,
.ant-dropdown-menu-submenu-rtl.ant-table-filter-dropdown-submenu .ant-checkbox-wrapper+span,
.ant-dropdown-rtl .ant-table-filter-dropdown .ant-checkbox-wrapper+span,
.ant-dropdown-rtl .ant-table-filter-dropdown-submenu .ant-checkbox-wrapper+span {
	padding-left: 0;
	padding-right: 8px;
}

.ant-table-wrapper-rtl .ant-table-selection {
	text-align: center;
}

.ant-table-wrapper-rtl .ant-table-row-expand-icon,
.ant-table-wrapper-rtl .ant-table-row-indent {
	float: right;
}

.ant-table-wrapper-rtl .ant-table-row-indent+.ant-table-row-expand-icon {
	margin-left: 8px;
	margin-right: 0;
}

.ant-table-wrapper-rtl .ant-table-row-expand-icon:after {
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg);
}

.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed:before {
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.ant-table-wrapper-rtl .ant-table-row-expand-icon-collapsed:after {
	-webkit-transform: rotate(0deg);
	transform: rotate(0deg);
}

.ant-tabs-small>.ant-tabs-nav .ant-tabs-tab {
	font-size: 14px;
	padding: 8px 0;
}

.ant-tabs-large>.ant-tabs-nav .ant-tabs-tab {
	font-size: 16px;
	padding: 16px 0;
}

.ant-tabs-card.ant-tabs-small>.ant-tabs-nav .ant-tabs-tab {
	padding: 6px 16px;
}

.ant-tabs-card.ant-tabs-large>.ant-tabs-nav .ant-tabs-tab {
	padding: 7px 16px 6px;
}

.ant-tabs-rtl {
	direction: rtl;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab {
	margin: 0 0 0 32px;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab:last-of-type {
	margin-left: 0;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .anticon {
	margin-left: 12px;
	margin-right: 0;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove {
	margin-left: -4px;
	margin-right: 8px;
}

.ant-tabs-rtl .ant-tabs-nav .ant-tabs-tab .ant-tabs-tab-remove .anticon {
	margin: 0;
}

.ant-tabs-rtl.ant-tabs-left>.ant-tabs-nav {
	order: 1;
}

.ant-tabs-rtl.ant-tabs-left>.ant-tabs-content-holder,
.ant-tabs-rtl.ant-tabs-right>.ant-tabs-nav {
	order: 0;
}

.ant-tabs-rtl.ant-tabs-right>.ant-tabs-content-holder {
	order: 1;
}

.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-rtl.ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab {
	margin-left: 2px;
	margin-right: 0;
}

.ant-tabs-dropdown-rtl {
	direction: rtl;
}

.ant-tabs-dropdown-rtl .ant-tabs-dropdown-menu-item {
	text-align: right;
}

.ant-tabs-bottom,
.ant-tabs-top {
	flex-direction: column;
}

.ant-tabs-bottom>.ant-tabs-nav,
.ant-tabs-bottom>div>.ant-tabs-nav,
.ant-tabs-top>.ant-tabs-nav,
.ant-tabs-top>div>.ant-tabs-nav {
	margin: 0 0 16px;
}

.ant-tabs-bottom>.ant-tabs-nav:before,
.ant-tabs-bottom>div>.ant-tabs-nav:before,
.ant-tabs-top>.ant-tabs-nav:before,
.ant-tabs-top>div>.ant-tabs-nav:before {
	border-bottom: 1px solid #e8e8e8;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
}

.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar {
	height: 2px;
}

.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar-animated {
	transition: width 0.3s, left 0.3s, right 0.3s;
}

.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-nav-wrap:before,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-nav-wrap:before,
.ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap:before,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-nav-wrap:before {
	bottom: 0;
	top: 0;
	width: 30px;
}

.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-nav-wrap:before,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-nav-wrap:before,
.ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap:before,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-nav-wrap:before {
	box-shadow: inset 10px 0 8px -8px rgba(0, 0, 0, 0.08);
	left: 0;
}

.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-nav-wrap:after {
	box-shadow: inset -10px 0 8px -8px rgba(0, 0, 0, 0.08);
	right: 0;
}

.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left:before,
.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right:after,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left:before,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right:after,
.ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left:before,
.ant-tabs-top>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right:after,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-left:before,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-right:after {
	opacity: 1;
}

.ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top>.ant-tabs-nav:before,
.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-top>div>.ant-tabs-nav:before {
	bottom: 0;
}

.ant-tabs-bottom>.ant-tabs-nav,
.ant-tabs-bottom>div>.ant-tabs-nav {
	margin-bottom: 0;
	margin-top: 16px;
	order: 1;
}

.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom>.ant-tabs-nav:before,
.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-bottom>div>.ant-tabs-nav:before {
	top: 0;
}

.ant-tabs-bottom>.ant-tabs-content-holder,
.ant-tabs-bottom>div>.ant-tabs-content-holder {
	order: 0;
}

.ant-tabs-left>.ant-tabs-nav,
.ant-tabs-left>div>.ant-tabs-nav,
.ant-tabs-right>.ant-tabs-nav,
.ant-tabs-right>div>.ant-tabs-nav {
	flex-direction: column;
	min-width: 50px;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-tab {
	padding: 8px 24px;
	text-align: center;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab {
	margin: 16px 0 0;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-nav-wrap {
	flex-direction: column;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs-left>.ant-tabs-nav .ant-tabs-nav-wrap:before,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-nav-wrap:before,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-nav-wrap:before,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-nav-wrap:before {
	height: 30px;
	left: 0;
	right: 0;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-nav-wrap:before,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-nav-wrap:before,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-nav-wrap:before,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-nav-wrap:before {
	box-shadow: inset 0 10px 8px -8px rgba(0, 0, 0, 0.08);
	top: 0;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-nav-wrap:after {
	bottom: 0;
	box-shadow: inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08);
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom:after,
.ant-tabs-left>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top:before,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom:after,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top:before,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom:after,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top:before,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-bottom:after,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-nav-wrap.ant-tabs-nav-wrap-ping-top:before {
	opacity: 1;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-ink-bar {
	width: 2px;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-ink-bar-animated,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-ink-bar-animated {
	transition: height 0.3s, top 0.3s;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left>.ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right>.ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-nav-operations {
	flex-direction: column;
}

.ant-tabs-left>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-ink-bar {
	right: 0;
}

.ant-tabs-left>.ant-tabs-content-holder,
.ant-tabs-left>div>.ant-tabs-content-holder {
	border-left: 1px solid #e8e8e8;
	margin-left: -1px;
}

.ant-tabs-left>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane,
.ant-tabs-left>div>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane {
	padding-left: 24px;
}

.ant-tabs-right>.ant-tabs-nav,
.ant-tabs-right>div>.ant-tabs-nav {
	order: 1;
}

.ant-tabs-right>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-ink-bar {
	left: 0;
}

.ant-tabs-right>.ant-tabs-content-holder,
.ant-tabs-right>div>.ant-tabs-content-holder {
	border-right: 1px solid #e8e8e8;
	margin-right: -1px;
	order: 0;
}

.ant-tabs-right>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane,
.ant-tabs-right>div>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane {
	padding-right: 24px;
}

.ant-tabs-dropdown {
	box-sizing: border-box;
	color: #545454;
	display: block;
	font-size: 14px;
	font-variant: tabular-nums;
	left: -9999px;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
	position: absolute;
	top: -9999px;
	z-index: 1050;
}

.ant-tabs-dropdown-hidden {
	display: none;
}

.ant-tabs-dropdown-menu {
	background-clip: padding-box;
	background-color: #fff;
	border-radius: 6px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	list-style-type: none;
	margin: 0;
	max-height: 200px;
	outline: none;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 4px 0;
	text-align: left;
}

.ant-tabs-dropdown-menu-item {
	color: #545454;
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	margin: 0;
	min-width: 120px;
	overflow: hidden;
	padding: 5px 12px;
	text-overflow: ellipsis;
	transition: all 0.3s;
	white-space: nowrap;
}

.ant-tabs-dropdown-menu-item:hover {
	background: #f5f5f5;
}

.ant-tabs-dropdown-menu-item-disabled,
.ant-tabs-dropdown-menu-item-disabled:hover {
	background: transparent;
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab {
	background: #fafafa;
	border: 1px solid #e8e8e8;
	margin: 0;
	padding: 10px 16px;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab-active {
	background: #fff;
	color: #038fde;
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-ink-bar,
.ant-tabs-card>div>.ant-tabs-nav .ant-tabs-ink-bar {
	visibility: hidden;
}

.ant-tabs-card.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab {
	margin-left: 2px;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab {
	border-radius: 6px 6px 0 0;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab-active {
	border-bottom-color: #fff;
}

.ant-tabs-card.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-tab {
	border-radius: 0 0 6px 6px;
}

.ant-tabs-card.ant-tabs-bottom>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-bottom>div>.ant-tabs-nav .ant-tabs-tab-active {
	border-top-color: #fff;
}

.ant-tabs-card.ant-tabs-left>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-card.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-card.ant-tabs-right>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab,
.ant-tabs-card.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-tab+.ant-tabs-tab {
	margin-top: 2px;
}

.ant-tabs-card.ant-tabs-left>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-tab {
	border-radius: 6px 0 0 6px;
}

.ant-tabs-card.ant-tabs-left>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-left>div>.ant-tabs-nav .ant-tabs-tab-active {
	border-right-color: #fff;
}

.ant-tabs-card.ant-tabs-right>.ant-tabs-nav .ant-tabs-tab,
.ant-tabs-card.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-tab {
	border-radius: 0 6px 6px 0;
}

.ant-tabs-card.ant-tabs-right>.ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-right>div>.ant-tabs-nav .ant-tabs-tab-active {
	border-left-color: #fff;
}

.ant-tabs {
	box-sizing: border-box;
	color: #545454;
	display: flex;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	overflow: hidden;
	padding: 0;
}

.ant-tabs>.ant-tabs-nav,
.ant-tabs>div>.ant-tabs-nav {
	align-items: center;
	display: flex;
	flex: none;
	position: relative;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap {
	align-self: stretch;
	display: inline-block;
	display: flex;
	flex: auto;
	overflow: hidden;
	position: relative;
	-webkit-transform: translate(0);
	transform: translate(0);
	white-space: nowrap;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs>.ant-tabs-nav .ant-tabs-nav-wrap:before,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap:after,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-wrap:before {
	content: '';
	opacity: 0;
	pointer-events: none;
	position: absolute;
	transition: opacity 0.3s;
	z-index: 1;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-list,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-list {
	display: flex;
	flex: 1 0 auto;
	position: relative;
	transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
	transition: transform 0.3s, -webkit-transform 0.3s;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-operations,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-operations {
	align-self: stretch;
	display: flex;
	flex: 1 0 auto;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-operations-hidden,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-operations-hidden {
	pointer-events: none;
	position: absolute;
	visibility: hidden;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-more,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-more {
	background: transparent;
	border: 0;
	padding: 10px 16px;
	position: relative;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-more:after,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-more:after {
	bottom: 0;
	content: '';
	height: 5px;
	left: 0;
	position: absolute;
	right: 0;
	-webkit-transform: translateY(100%);
	transform: translateY(100%);
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-add,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-add {
	background: #fafafa;
	border: 1px solid #e8e8e8;
	border-radius: 6px 6px 0 0;
	cursor: pointer;
	min-width: 40px;
	outline: none;
	padding: 0 8px;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-add:hover,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-add:hover {
	color: #28aaeb;
}

.ant-tabs>.ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs>.ant-tabs-nav .ant-tabs-nav-add:focus,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-add:active,
.ant-tabs>div>.ant-tabs-nav .ant-tabs-nav-add:focus {
	color: #006eb8;
}

.ant-tabs-extra-content {
	flex: none;
}

.ant-tabs-centered>.ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']),
.ant-tabs-centered>div>.ant-tabs-nav .ant-tabs-nav-wrap:not([class*='ant-tabs-nav-wrap-ping']) {
	justify-content: center;
}

.ant-tabs-ink-bar {
	background: #038fde;
	pointer-events: none;
	position: absolute;
}

.ant-tabs-tab {
	align-items: center;
	background: transparent;
	border: 0;
	cursor: pointer;
	display: inline-flex;
	font-size: 14px;
	outline: none;
	padding: 12px 0;
	position: relative;
}

.ant-tabs-tab-btn:active,
.ant-tabs-tab-btn:focus,
.ant-tabs-tab-remove:active,
.ant-tabs-tab-remove:focus {
	color: #006eb8;
}

.ant-tabs-tab-btn,
.ant-tabs-tab-remove {
	outline: none;
	transition: all 0.3s;
}

.ant-tabs-tab-remove {
	background: transparent;
	border: none;
	color: #595959;
	cursor: pointer;
	flex: none;
	font-size: 12px;
	margin-left: 8px;
	margin-right: -4px;
}

.ant-tabs-tab-remove:hover {
	color: #535353;
}

.ant-tabs-tab:hover {
	color: #28aaeb;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	color: #038fde;
	text-shadow: 0 0 0.25px currentColor;
}

.ant-tabs-tab.ant-tabs-tab-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:active,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-btn:focus,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:active,
.ant-tabs-tab.ant-tabs-tab-disabled .ant-tabs-tab-remove:focus {
	color: rgba(0, 0, 0, 0.25);
}

.ant-tabs-tab .ant-tabs-tab-remove .anticon {
	margin: 0;
}

.ant-tabs-tab .anticon {
	margin-right: 12px;
}

.ant-tabs-tab+.ant-tabs-tab {
	margin: 0 0 0 32px;
}

.ant-tabs-content {
	display: flex;
	width: 100%;
}

.ant-tabs-content-holder {
	flex: auto;
	min-height: 0;
	min-width: 0;
}

.ant-tabs-content-animated {
	transition: margin 0.3s;
}

.ant-tabs-tabpane {
	flex: none;
	outline: none;
	width: 100%;
}

.ant-tag {
	background: #fafafa;
	border: 1px solid #d9d9d9;
	border-radius: 6px;
	box-sizing: border-box;
	display: inline-block;
	font-size: 14px;
	font-size: 12px;
	font-variant: tabular-nums;
	height: auto;
	line-height: 1.3;
	line-height: 20px;
	list-style: none;
	margin: 0 8px 0 0;
	opacity: 1;
	padding: 0 7px;
	transition: all 0.3s;
	white-space: nowrap;
}

.ant-tag,
.ant-tag a,
.ant-tag a:hover {
	color: #545454;
}

.ant-tag>a:first-child:last-child {
	display: inline-block;
	margin: 0 -8px;
	padding: 0 8px;
}

.ant-tag-close-icon {
	color: #595959;
	cursor: pointer;
	font-size: 10px;
	margin-left: 3px;
	transition: all 0.3s;
}

.ant-tag-close-icon:hover {
	color: #535353;
}

.ant-tag-has-color {
	border-color: transparent;
}

.ant-tag-has-color,
.ant-tag-has-color .anticon-close,
.ant-tag-has-color .anticon-close:hover,
.ant-tag-has-color a,
.ant-tag-has-color a:hover {
	color: #fff;
}

.ant-tag-checkable {
	background-color: transparent;
	border-color: transparent;
	cursor: pointer;
}

.ant-tag-checkable:not(.ant-tag-checkable-checked):hover {
	color: #038fde;
}

.ant-tag-checkable-checked,
.ant-tag-checkable:active {
	color: #fff;
}

.ant-tag-checkable-checked {
	background-color: #038fde;
}

.ant-tag-checkable:active {
	background-color: #006eb8;
}

.ant-tag-hidden {
	display: none;
}

.ant-tag-pink {
	background: #fff0f6;
	border-color: #ffadd2;
	color: #c41d7f;
}

.ant-tag-pink-inverse {
	background: #eb2f96;
	border-color: #eb2f96;
	color: #fff;
}

.ant-tag-magenta {
	background: #fff0f6;
	border-color: #ffadd2;
	color: #c41d7f;
}

.ant-tag-magenta-inverse {
	background: #eb2f96;
	border-color: #eb2f96;
	color: #fff;
}

.ant-tag-red {
	background: #fff1f0;
	border-color: #ffa39e;
	color: #cf1322;
}

.ant-tag-red-inverse {
	background: #f5222d;
	border-color: #f5222d;
	color: #fff;
}

.ant-tag-volcano {
	background: #fff2e8;
	border-color: #ffbb96;
	color: #d4380d;
}

.ant-tag-volcano-inverse {
	background: #fa541c;
	border-color: #fa541c;
	color: #fff;
}

.ant-tag-orange {
	background: #fff7e6;
	border-color: #ffd591;
	color: #d46b08;
}

.ant-tag-orange-inverse {
	background: #fa8c16;
	border-color: #fa8c16;
	color: #fff;
}

.ant-tag-yellow {
	background: #feffe6;
	border-color: #fffb8f;
	color: #d4b106;
}

.ant-tag-yellow-inverse {
	background: #fadb14;
	border-color: #fadb14;
	color: #fff;
}

.ant-tag-gold {
	background: #fffbe6;
	border-color: #ffe58f;
	color: #d48806;
}

.ant-tag-gold-inverse {
	background: #faad14;
	border-color: #faad14;
	color: #fff;
}

.ant-tag-cyan {
	background: #e6fffb;
	border-color: #87e8de;
	color: #08979c;
}

.ant-tag-cyan-inverse {
	background: #13c2c2;
	border-color: #13c2c2;
	color: #fff;
}

.ant-tag-lime {
	background: #fcffe6;
	border-color: #eaff8f;
	color: #7cb305;
}

.ant-tag-lime-inverse {
	background: #a0d911;
	border-color: #a0d911;
	color: #fff;
}

.ant-tag-green {
	background: #f6ffed;
	border-color: #b7eb8f;
	color: #389e0d;
}

.ant-tag-green-inverse {
	background: #52c41a;
	border-color: #52c41a;
	color: #fff;
}

.ant-tag-blue {
	background: #e6f7ff;
	border-color: #91d5ff;
	color: #096dd9;
}

.ant-tag-blue-inverse {
	background: #1890ff;
	border-color: #1890ff;
	color: #fff;
}

.ant-tag-geekblue {
	background: #f0f5ff;
	border-color: #adc6ff;
	color: #1d39c4;
}

.ant-tag-geekblue-inverse {
	background: #2f54eb;
	border-color: #2f54eb;
	color: #fff;
}

.ant-tag-purple {
	background: #f9f0ff;
	border-color: #d3adf7;
	color: #531dab;
}

.ant-tag-purple-inverse {
	background: #722ed1;
	border-color: #722ed1;
	color: #fff;
}

.ant-tag-success {
	background: #f6ffed;
	border-color: #b7eb8f;
	color: #52c41a;
}

.ant-tag-processing {
	background: #e6f7ff;
	border-color: #91d5ff;
	color: #1890ff;
}

.ant-tag-error {
	background: #fff1f0;
	border-color: #ffa39e;
	color: #f5222d;
}

.ant-tag-warning {
	background: #fff7e6;
	border-color: #ffd591;
	color: #fa8c16;
}

.ant-tag>.anticon+span,
.ant-tag>span+.anticon {
	margin-left: 7px;
}

.ant-tag.ant-tag-rtl {
	direction: rtl;
	margin-left: 8px;
	margin-right: 0;
	text-align: right;
}

.ant-tag-rtl .ant-tag-close-icon {
	margin-left: 0;
	margin-right: 3px;
}

.ant-tag-rtl.ant-tag>.anticon+span,
.ant-tag-rtl.ant-tag>span+.anticon {
	margin-left: 0;
	margin-right: 7px;
}

.ant-timeline {
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
}

.ant-timeline-item {
	font-size: 14px;
	list-style: none;
	margin: 0;
	padding-bottom: 20px;
	position: relative;
}

.ant-timeline-item-tail {
	border-left: 2px solid #e8e8e8;
	height: calc(100% - 10px);
	left: 4px;
	position: absolute;
	top: 10px;
}

.ant-timeline-item-pending .ant-timeline-item-head {
	background-color: transparent;
	font-size: 12px;
}

.ant-timeline-item-pending .ant-timeline-item-tail {
	display: none;
}

.ant-timeline-item-head {
	background-color: #fff;
	border: 2px solid transparent;
	border-radius: 100px;
	height: 10px;
	position: absolute;
	width: 10px;
}

.ant-timeline-item-head-blue {
	border-color: #038fde;
	color: #038fde;
}

.ant-timeline-item-head-red {
	border-color: #f5222d;
	color: #f5222d;
}

.ant-timeline-item-head-green {
	border-color: #52c41a;
	color: #52c41a;
}

.ant-timeline-item-head-gray {
	border-color: rgba(0, 0, 0, 0.25);
	color: rgba(0, 0, 0, 0.25);
}

.ant-timeline-item-head-custom {
	border: 0;
	border-radius: 0;
	height: auto;
	left: 5px;
	line-height: 1;
	margin-top: 0;
	padding: 3px 1px;
	position: absolute;
	text-align: center;
	top: 5.5px;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: auto;
}

.ant-timeline-item-content {
	margin: 0 0 0 26px;
	position: relative;
	top: -3.2px;
	word-break: break-word;
}

.ant-timeline-item-last>.ant-timeline-item-tail {
	display: none;
}

.ant-timeline-item-last>.ant-timeline-item-content {
	min-height: 48px;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-tail {
	left: 50%;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-head {
	margin-left: -4px;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
	margin-left: 1px;
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content {
	left: calc(50% - 4px);
	text-align: left;
	width: calc(50% - 14px);
}

.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
	margin: 0;
	text-align: right;
	width: calc(50% - 12px);
}

.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom,
.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail {
	left: calc(100% - 6px);
}

.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
	width: calc(100% - 18px);
}

.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail {
	border-left: 2px dotted #e8e8e8;
	display: block;
	height: calc(100% - 14px);
}

.ant-timeline.ant-timeline-reverse .ant-timeline-item-last .ant-timeline-item-tail {
	display: none;
}

.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
	border-left: 2px dotted #e8e8e8;
	display: block;
	height: calc(100% - 15px);
	top: 15px;
}

.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-content {
	min-height: 48px;
}

.ant-timeline.ant-timeline-label .ant-timeline-item-label {
	position: absolute;
	text-align: right;
	top: -3.2px;
	width: calc(50% - 12px);
}

.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
	left: calc(50% + 14px);
	text-align: left;
	width: calc(50% - 14px);
}

.ant-timeline-rtl {
	direction: rtl;
}

.ant-timeline-rtl .ant-timeline-item-tail {
	border-left: none;
	border-right: 2px solid #e8e8e8;
	left: auto;
	right: 4px;
}

.ant-timeline-rtl .ant-timeline-item-head-custom {
	left: auto;
	right: 5px;
	-webkit-transform: translate(50%, -50%);
	transform: translate(50%, -50%);
}

.ant-timeline-rtl .ant-timeline-item-content {
	margin: 0 18px 0 0;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-tail {
	left: auto;
	right: 50%;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head {
	margin-left: 0;
	margin-right: -4px;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-head-custom {
	margin-left: 0;
	margin-right: 1px;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-left .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-left .ant-timeline-item-content {
	left: auto;
	right: calc(50% - 4px);
	text-align: right;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-content,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
	text-align: left;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-head-custom,
.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-tail {
	left: auto;
	right: 0;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
	margin-right: 18px;
	text-align: right;
	width: 100%;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-pending .ant-timeline-item-last .ant-timeline-item-tail,
.ant-timeline-rtl.ant-timeline.ant-timeline-reverse .ant-timeline-item-pending .ant-timeline-item-tail {
	border-left: none;
	border-right: 2px dotted #e8e8e8;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-label {
	text-align: left;
}

.ant-timeline-rtl.ant-timeline.ant-timeline-label .ant-timeline-item-right .ant-timeline-item-label {
	right: calc(50% + 14px);
	text-align: right;
}

.ant-tooltip {
	box-sizing: border-box;
	color: #545454;
	display: block;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
	position: absolute;
	visibility: visible;
	z-index: 1070;
}

.ant-tooltip-hidden {
	display: none;
}

.ant-tooltip-placement-top,
.ant-tooltip-placement-topLeft,
.ant-tooltip-placement-topRight {
	padding-bottom: 8px;
}

.ant-tooltip-placement-right,
.ant-tooltip-placement-rightBottom,
.ant-tooltip-placement-rightTop {
	padding-left: 8px;
}

.ant-tooltip-placement-bottom,
.ant-tooltip-placement-bottomLeft,
.ant-tooltip-placement-bottomRight {
	padding-top: 8px;
}

.ant-tooltip-placement-left,
.ant-tooltip-placement-leftBottom,
.ant-tooltip-placement-leftTop {
	padding-right: 8px;
}

.ant-tooltip-inner {
	word-wrap: break-word;
	background-color: rgba(0, 0, 0, 0.75);
	border-radius: 6px;
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	color: #fff;
	min-height: 32px;
	min-width: 30px;
	padding: 6px 8px;
	text-align: left;
	text-decoration: none;
}

.ant-tooltip-arrow {
	background: transparent;
	display: block;
	height: 13.07106781px;
	overflow: hidden;
	pointer-events: none;
	position: absolute;
	width: 13.07106781px;
}

.ant-tooltip-arrow-content {
	background-color: rgba(0, 0, 0, 0.75);
	bottom: 0;
	content: '';
	display: block;
	height: 5px;
	left: 0;
	margin: auto;
	pointer-events: auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 5px;
}

.ant-tooltip-placement-top .ant-tooltip-arrow,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow,
.ant-tooltip-placement-topRight .ant-tooltip-arrow {
	bottom: -5.07106781px;
}

.ant-tooltip-placement-top .ant-tooltip-arrow-content,
.ant-tooltip-placement-topLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-topRight .ant-tooltip-arrow-content {
	box-shadow: 3px 3px 7px rgba(0, 0, 0, 0.07);
	-webkit-transform: translateY(-6.53553391px) rotate(45deg);
	transform: translateY(-6.53553391px) rotate(45deg);
}

.ant-tooltip-placement-top .ant-tooltip-arrow {
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

.ant-tooltip-placement-topLeft .ant-tooltip-arrow {
	left: 13px;
}

.ant-tooltip-placement-topRight .ant-tooltip-arrow {
	right: 13px;
}

.ant-tooltip-placement-right .ant-tooltip-arrow,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
	left: -5.07106781px;
}

.ant-tooltip-placement-right .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightBottom .ant-tooltip-arrow-content,
.ant-tooltip-placement-rightTop .ant-tooltip-arrow-content {
	box-shadow: -3px 3px 7px rgba(0, 0, 0, 0.07);
	-webkit-transform: translateX(6.53553391px) rotate(45deg);
	transform: translateX(6.53553391px) rotate(45deg);
}

.ant-tooltip-placement-right .ant-tooltip-arrow {
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.ant-tooltip-placement-rightTop .ant-tooltip-arrow {
	top: 5px;
}

.ant-tooltip-placement-rightBottom .ant-tooltip-arrow {
	bottom: 5px;
}

.ant-tooltip-placement-left .ant-tooltip-arrow,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
	right: -5.07106781px;
}

.ant-tooltip-placement-left .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftBottom .ant-tooltip-arrow-content,
.ant-tooltip-placement-leftTop .ant-tooltip-arrow-content {
	box-shadow: 3px -3px 7px rgba(0, 0, 0, 0.07);
	-webkit-transform: translateX(-6.53553391px) rotate(45deg);
	transform: translateX(-6.53553391px) rotate(45deg);
}

.ant-tooltip-placement-left .ant-tooltip-arrow {
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}

.ant-tooltip-placement-leftTop .ant-tooltip-arrow {
	top: 5px;
}

.ant-tooltip-placement-leftBottom .ant-tooltip-arrow {
	bottom: 5px;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
	top: -5.07106781px;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow-content,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow-content {
	box-shadow: -3px -3px 7px rgba(0, 0, 0, 0.07);
	-webkit-transform: translateY(6.53553391px) rotate(45deg);
	transform: translateY(6.53553391px) rotate(45deg);
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow {
	left: 50%;
	-webkit-transform: translateX(-50%);
	transform: translateX(-50%);
}

.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow {
	left: 13px;
}

.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
	right: 13px;
}

.ant-tooltip-magenta .ant-tooltip-arrow-content,
.ant-tooltip-magenta .ant-tooltip-inner,
.ant-tooltip-pink .ant-tooltip-arrow-content,
.ant-tooltip-pink .ant-tooltip-inner {
	background-color: #eb2f96;
}

.ant-tooltip-red .ant-tooltip-arrow-content,
.ant-tooltip-red .ant-tooltip-inner {
	background-color: #f5222d;
}

.ant-tooltip-volcano .ant-tooltip-arrow-content,
.ant-tooltip-volcano .ant-tooltip-inner {
	background-color: #fa541c;
}

.ant-tooltip-orange .ant-tooltip-arrow-content,
.ant-tooltip-orange .ant-tooltip-inner {
	background-color: #fa8c16;
}

.ant-tooltip-yellow .ant-tooltip-arrow-content,
.ant-tooltip-yellow .ant-tooltip-inner {
	background-color: #fadb14;
}

.ant-tooltip-gold .ant-tooltip-arrow-content,
.ant-tooltip-gold .ant-tooltip-inner {
	background-color: #faad14;
}

.ant-tooltip-cyan .ant-tooltip-arrow-content,
.ant-tooltip-cyan .ant-tooltip-inner {
	background-color: #13c2c2;
}

.ant-tooltip-lime .ant-tooltip-arrow-content,
.ant-tooltip-lime .ant-tooltip-inner {
	background-color: #a0d911;
}

.ant-tooltip-green .ant-tooltip-arrow-content,
.ant-tooltip-green .ant-tooltip-inner {
	background-color: #52c41a;
}

.ant-tooltip-blue .ant-tooltip-arrow-content,
.ant-tooltip-blue .ant-tooltip-inner {
	background-color: #1890ff;
}

.ant-tooltip-geekblue .ant-tooltip-arrow-content,
.ant-tooltip-geekblue .ant-tooltip-inner {
	background-color: #2f54eb;
}

.ant-tooltip-purple .ant-tooltip-arrow-content,
.ant-tooltip-purple .ant-tooltip-inner {
	background-color: #722ed1;
}

.ant-tooltip-rtl {
	direction: rtl;
}

.ant-tooltip-rtl .ant-tooltip-inner {
	text-align: right;
}

.ant-transfer-customize-list .ant-transfer-list {
	flex: 1 1 50%;
	height: auto;
	min-height: 200px;
	width: auto;
}

.ant-transfer-customize-list .ant-table-wrapper .ant-table-small {
	border: 0;
	border-radius: 0;
}

.ant-transfer-customize-list .ant-table-wrapper .ant-table-small>.ant-table-content>.ant-table-body>table>.ant-table-thead>tr>th {
	background: #fafafa;
}

.ant-transfer-customize-list .ant-table-wrapper .ant-table-small>.ant-table-content .ant-table-row:last-child td {
	border-bottom: 1px solid #e8e8e8;
}

.ant-transfer-customize-list .ant-table-wrapper .ant-table-small .ant-table-body {
	margin: 0;
}

.ant-transfer-customize-list .ant-table-wrapper .ant-table-pagination.ant-pagination {
	margin: 16px 0 4px;
}

.ant-transfer-customize-list .ant-input[disabled] {
	background-color: transparent;
}

.ant-transfer {
	align-items: stretch;
	box-sizing: border-box;
	color: #545454;
	display: flex;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
	position: relative;
}

.ant-transfer-disabled .ant-transfer-list {
	background: #f5f5f5;
}

.ant-transfer-list {
	border: 1px solid #d9d9d9;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	height: 200px;
	width: 180px;
}

.ant-transfer-list-with-pagination {
	height: auto;
	width: 250px;
}

.ant-transfer-list-search {
	padding-left: 8px;
	padding-right: 24px;
}

.ant-transfer-list-search-action {
	bottom: 12px;
	color: rgba(0, 0, 0, 0.25);
	line-height: 36px;
	position: absolute;
	right: 12px;
	text-align: center;
	top: 12px;
	width: 28px;
}

.ant-transfer-list-search-action .anticon {
	color: rgba(0, 0, 0, 0.25);
	transition: all 0.3s;
}

.ant-transfer-list-search-action .anticon:hover {
	color: #595959;
}

span.ant-transfer-list-search-action {
	pointer-events: none;
}

.ant-transfer-list-header {
	align-items: center;
	background: #fff;
	border-bottom: 1px solid #e8e8e8;
	border-radius: 6px 6px 0 0;
	color: #545454;
	display: flex;
	flex: none;
	height: 40px;
	padding: 10px 12px 11px;
}

.ant-transfer-list-header> :not(:last-child) {
	margin-right: 4px;
}

.ant-transfer-list-header>* {
	flex: none;
}

.ant-transfer-list-header-title {
	flex: auto;
	overflow: hidden;
	text-align: right;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ant-transfer-list-header-dropdown {
	cursor: pointer;
	font-size: 10px;
	-webkit-transform: translateY(10%);
	transform: translateY(10%);
}

.ant-transfer-list-header-dropdown[disabled] {
	cursor: not-allowed;
}

.ant-transfer-list-body {
	display: flex;
	flex: auto;
	flex-direction: column;
	font-size: 14px;
	overflow: hidden;
}

.ant-transfer-list-body-search-wrapper {
	flex: none;
	padding: 12px;
	position: relative;
}

.ant-transfer-list-content {
	flex: auto;
	list-style: none;
	margin: 0;
	overflow: auto;
	padding: 0;
}

.ant-transfer-list-content-item {
	align-items: center;
	display: flex;
	line-height: 20px;
	min-height: 32px;
	padding: 6px 12px;
	transition: all 0.3s;
}

.ant-transfer-list-content-item> :not(:last-child) {
	margin-right: 8px;
}

.ant-transfer-list-content-item>* {
	flex: none;
}

.ant-transfer-list-content-item-text {
	flex: auto;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.ant-transfer-list-content-item-remove {
	color: #038fde;
	color: #d9d9d9;
	cursor: pointer;
	outline: none;
	position: relative;
	text-decoration: none;
	transition: color 0.3s;
}

.ant-transfer-list-content-item-remove:focus,
.ant-transfer-list-content-item-remove:hover {
	color: #28aaeb;
}

.ant-transfer-list-content-item-remove:active {
	color: #006eb8;
}

.ant-transfer-list-content-item-remove:after {
	bottom: -6px;
	content: '';
	left: -50%;
	position: absolute;
	right: -50%;
	top: -6px;
}

.ant-transfer-list-content-item-remove:hover {
	color: #28aaeb;
}

.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
	background-color: #f5f5f5;
	cursor: pointer;
}

.ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled).ant-transfer-list-content-item-checked:hover {
	background-color: #dcf8ff;
}

.ant-transfer-list-content-show-remove .ant-transfer-list-content-item:not(.ant-transfer-list-content-item-disabled):hover {
	background: transparent;
	cursor: default;
}

.ant-transfer-list-content-item-checked {
	background-color: #e6faff;
}

.ant-transfer-list-content-item-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-transfer-list-pagination {
	border-top: 1px solid #e8e8e8;
	padding: 8px 0;
	text-align: right;
}

.ant-transfer-list-body-not-found {
	color: rgba(0, 0, 0, 0.25);
	flex: none;
	margin: auto 0;
	text-align: center;
	width: 100%;
}

.ant-transfer-list-footer {
	border-top: 1px solid #e8e8e8;
}

.ant-transfer-list-checkbox .ant-checkbox {
	top: 0;
}

.ant-transfer-operation {
	align-self: center;
	display: flex;
	flex: none;
	flex-direction: column;
	margin: 0 8px;
	vertical-align: middle;
}

.ant-transfer-operation .ant-btn {
	display: block;
}

.ant-transfer-operation .ant-btn:first-child {
	margin-bottom: 4px;
}

.ant-transfer-operation .ant-btn .anticon {
	font-size: 12px;
}

.ant-transfer .ant-empty-image {
	max-height: -2px;
}

.ant-transfer-rtl {
	direction: rtl;
}

.ant-transfer-rtl .ant-transfer-list-search {
	padding-left: 24px;
	padding-right: 8px;
}

.ant-transfer-rtl .ant-transfer-list-search-action {
	left: 12px;
	right: auto;
}

.ant-transfer-rtl .ant-transfer-list-header> :not(:last-child) {
	margin-left: 4px;
	margin-right: 0;
}

.ant-transfer-rtl .ant-transfer-list-header {
	left: auto;
	right: 0;
}

.ant-transfer-rtl .ant-transfer-list-header-title {
	text-align: left;
}

.ant-transfer-rtl .ant-transfer-list-content-item> :not(:last-child) {
	margin-left: 8px;
	margin-right: 0;
}

.ant-transfer-rtl .ant-transfer-list-pagination {
	text-align: left;
}

.ant-transfer-rtl .ant-transfer-list-footer {
	left: auto;
	right: 0;
}

.ant-tree-treenode-leaf-last .ant-tree-switcher-leaf-line:before {
	bottom: auto !important;
	height: 14px !important;
	top: auto !important;
}

.ant-tree.ant-tree-directory .ant-tree-treenode {
	position: relative;
}

.ant-tree.ant-tree-directory .ant-tree-treenode:before {
	bottom: 4px;
	content: '';
	left: 0;
	pointer-events: none;
	position: absolute;
	right: 0;
	top: 0;
	transition: background-color 0.3s;
}

.ant-tree.ant-tree-directory .ant-tree-treenode:hover:before {
	background: #f5f5f5;
}

.ant-tree.ant-tree-directory .ant-tree-treenode>* {
	z-index: 1;
}

.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-switcher {
	transition: color 0.3s;
}

.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper {
	border-radius: 0;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper:hover {
	background: transparent;
}

.ant-tree.ant-tree-directory .ant-tree-treenode .ant-tree-node-content-wrapper.ant-tree-node-selected {
	background: transparent;
	color: #fff;
}

.ant-tree.ant-tree-directory .ant-tree-treenode-selected:before,
.ant-tree.ant-tree-directory .ant-tree-treenode-selected:hover:before {
	background: #038fde;
}

.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-switcher {
	color: #fff;
}

.ant-tree.ant-tree-directory .ant-tree-treenode-selected .ant-tree-node-content-wrapper {
	background: transparent;
	color: #fff;
}

.ant-tree-checkbox {
	box-sizing: border-box;
	color: #545454;
	cursor: pointer;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	line-height: 1;
	list-style: none;
	margin: 0;
	outline: none;
	padding: 0;
	position: relative;
	top: 0.2em;
	white-space: nowrap;
}

.ant-tree-checkbox-input:focus+.ant-tree-checkbox-inner,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-inner,
.ant-tree-checkbox:hover .ant-tree-checkbox-inner {
	border-color: #038fde;
}

.ant-tree-checkbox-checked:after {
	-webkit-animation: antCheckboxEffect 0.36s ease-in-out;
	animation: antCheckboxEffect 0.36s ease-in-out;
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	border: 1px solid #038fde;
	border-radius: 6px;
	content: '';
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	visibility: hidden;
	width: 100%;
}

.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox:after,
.ant-tree-checkbox:hover:after {
	visibility: visible;
}

.ant-tree-checkbox-inner {
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-collapse: separate;
	border-radius: 6px;
	direction: ltr;
	display: block;
	height: 16px;
	left: 0;
	position: relative;
	top: 0;
	transition: all 0.3s;
	width: 16px;
}

.ant-tree-checkbox-inner:after {
	border: 2px solid #fff;
	border-left: 0;
	border-top: 0;
	content: ' ';
	display: table;
	height: 9.14285714px;
	left: 22%;
	opacity: 0;
	position: absolute;
	top: 50%;
	-webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
	transform: rotate(45deg) scale(0) translate(-50%, -50%);
	transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
	width: 5.71428571px;
}

.ant-tree-checkbox-input {
	bottom: 0;
	cursor: pointer;
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 1;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner:after {
	border: 2px solid #fff;
	border-left: 0;
	border-top: 0;
	content: ' ';
	display: table;
	opacity: 1;
	position: absolute;
	-webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
	transform: rotate(45deg) scale(1) translate(-50%, -50%);
	transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.ant-tree-checkbox-checked .ant-tree-checkbox-inner {
	background-color: #038fde;
	border-color: #038fde;
}

.ant-tree-checkbox-disabled {
	cursor: not-allowed;
}

.ant-tree-checkbox-disabled.ant-tree-checkbox-checked .ant-tree-checkbox-inner:after {
	-webkit-animation-name: none;
	animation-name: none;
	border-color: rgba(0, 0, 0, 0.25);
}

.ant-tree-checkbox-disabled .ant-tree-checkbox-input {
	cursor: not-allowed;
}

.ant-tree-checkbox-disabled .ant-tree-checkbox-inner {
	background-color: #f5f5f5;
	border-color: #d9d9d9 !important;
}

.ant-tree-checkbox-disabled .ant-tree-checkbox-inner:after {
	-webkit-animation-name: none;
	animation-name: none;
	border-collapse: separate;
	border-color: #f5f5f5;
}

.ant-tree-checkbox-disabled+span {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-tree-checkbox-disabled:hover:after,
.ant-tree-checkbox-wrapper:hover .ant-tree-checkbox-disabled:after {
	visibility: hidden;
}

.ant-tree-checkbox-wrapper {
	align-items: baseline;
	box-sizing: border-box;
	color: #545454;
	cursor: pointer;
	display: inline-flex;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	line-height: unset;
	list-style: none;
	margin: 0;
	padding: 0;
}

.ant-tree-checkbox-wrapper.ant-tree-checkbox-wrapper-disabled {
	cursor: not-allowed;
}

.ant-tree-checkbox-wrapper+.ant-tree-checkbox-wrapper {
	margin-left: 8px;
}

.ant-tree-checkbox+span {
	padding-left: 8px;
	padding-right: 8px;
}

.ant-tree-checkbox-group {
	box-sizing: border-box;
	color: #545454;
	display: inline-block;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
}

.ant-tree-checkbox-group-item {
	margin-right: 8px;
}

.ant-tree-checkbox-group-item:last-child {
	margin-right: 0;
}

.ant-tree-checkbox-group-item+.ant-tree-checkbox-group-item {
	margin-left: 0;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner {
	background-color: #fff;
	border-color: #d9d9d9;
}

.ant-tree-checkbox-indeterminate .ant-tree-checkbox-inner:after {
	background-color: #038fde;
	border: 0;
	content: ' ';
	height: 8px;
	left: 50%;
	opacity: 1;
	top: 50%;
	-webkit-transform: translate(-50%, -50%) scale(1);
	transform: translate(-50%, -50%) scale(1);
	width: 8px;
}

.ant-tree-checkbox-indeterminate.ant-tree-checkbox-disabled .ant-tree-checkbox-inner:after {
	background-color: rgba(0, 0, 0, 0.25);
	border-color: rgba(0, 0, 0, 0.25);
}

.ant-tree-checkbox-rtl {
	direction: rtl;
}

.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item {
	margin-left: 8px;
	margin-right: 0;
}

.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item:last-child {
	margin-left: 0 !important;
}

.ant-tree-checkbox-group-rtl .ant-tree-checkbox-group-item+.ant-tree-checkbox-group-item {
	margin-left: 8px;
}

.ant-tree {
	background: #fff;
	border-radius: 6px;
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
	transition: background-color 0.3s;
}

.ant-tree-focused:not(:hover):not(.ant-tree-active-focused) {
	background: #e6faff;
}

.ant-tree-list-holder-inner {
	align-items: flex-start;
}

.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner {
	align-items: stretch;
}

.ant-tree.ant-tree-block-node .ant-tree-list-holder-inner .ant-tree-node-content-wrapper {
	flex: auto;
}

.ant-tree .ant-tree-treenode {
	align-items: flex-start;
	display: flex;
	outline: none;
	padding: 0 0 4px;
}

.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-tree .ant-tree-treenode-disabled .ant-tree-node-content-wrapper:hover {
	background: transparent;
}

.ant-tree .ant-tree-treenode-active .ant-tree-node-content-wrapper {
	background: #f5f5f5;
}

.ant-tree .ant-tree-treenode:not(.ant-tree .ant-tree-treenode-disabled).filter-node .ant-tree-title {
	color: inherit;
	font-weight: 500;
}

.ant-tree-indent {
	align-self: stretch;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	white-space: nowrap;
}

.ant-tree-indent-unit {
	display: inline-block;
	width: 24px;
}

.ant-tree-switcher {
	align-self: stretch;
	cursor: pointer;
	flex: none;
	line-height: 24px;
	margin: 0;
	position: relative;
	text-align: center;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	width: 24px;
}

.ant-tree-switcher .ant-select-tree-switcher-icon,
.ant-tree-switcher .ant-tree-switcher-icon {
	display: inline-block;
	font-size: 10px;
	vertical-align: baseline;
}

.ant-tree-switcher .ant-select-tree-switcher-icon svg,
.ant-tree-switcher .ant-tree-switcher-icon svg {
	transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
	transition: transform 0.3s, -webkit-transform 0.3s;
}

.ant-tree-switcher-noop {
	cursor: default;
}

.ant-tree-switcher_close .ant-tree-switcher-icon svg {
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg);
}

.ant-tree-switcher-loading-icon {
	color: #038fde;
}

.ant-tree-switcher-leaf-line {
	display: inline-block;
	height: 100%;
	position: relative;
	width: 100%;
	z-index: 1;
}

.ant-tree-switcher-leaf-line:before {
	border-left: 1px solid #d9d9d9;
	bottom: -4px;
	content: ' ';
	margin-left: -1px;
	position: absolute;
	top: 0;
}

.ant-tree-switcher-leaf-line:after {
	border-bottom: 1px solid #d9d9d9;
	content: ' ';
	height: 14px;
	margin-left: -1px;
	position: absolute;
	width: 10px;
}

.ant-tree-checkbox {
	margin: 4px 8px 0 0;
	top: auto;
}

.ant-tree .ant-tree-node-content-wrapper {
	background: transparent;
	border-radius: 6px;
	color: inherit;
	cursor: pointer;
	line-height: 24px;
	margin: 0;
	min-height: 24px;
	padding: 0 4px;
	position: relative;
	transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
	z-index: auto;
}

.ant-tree .ant-tree-node-content-wrapper:hover {
	background-color: #f5f5f5;
}

.ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
	background-color: #a6eaff;
}

.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle {
	display: inline-block;
	height: 24px;
	line-height: 24px;
	text-align: center;
	vertical-align: top;
	width: 24px;
}

.ant-tree .ant-tree-node-content-wrapper .ant-tree-iconEle:empty {
	display: none;
}

.ant-tree-unselectable .ant-tree-node-content-wrapper:hover {
	background-color: transparent;
}

.ant-tree-node-content-wrapper[draggable='true'] {
	line-height: 24px;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator {
	background-color: #038fde;
	border-radius: 1px;
	height: 2px;
	pointer-events: none;
	position: absolute;
	z-index: 1;
}

.ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator:after {
	background-color: transparent;
	border: 2px solid #038fde;
	border-radius: 50%;
	content: '';
	height: 8px;
	left: -6px;
	position: absolute;
	top: -3px;
	width: 8px;
}

.ant-tree .ant-tree-treenode.drop-container>[draggable] {
	box-shadow: 0 0 0 2px #038fde;
}

.ant-tree-show-line .ant-tree-indent-unit {
	height: 100%;
	position: relative;
}

.ant-tree-show-line .ant-tree-indent-unit:before {
	border-right: 1px solid #d9d9d9;
	bottom: -4px;
	content: '';
	position: absolute;
	right: 12px;
	top: 0;
}

.ant-tree-show-line .ant-tree-indent-unit-end:before {
	display: none;
}

.ant-tree-show-line .ant-tree-switcher {
	background: #fff;
}

.ant-tree-show-line .ant-tree-switcher-line-icon {
	vertical-align: -0.225em;
}

.ant-tree-rtl {
	direction: rtl;
}

.ant-tree-rtl .ant-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator:after {
	left: unset;
	right: -6px;
}

.ant-tree .ant-tree-treenode-rtl {
	direction: rtl;
}

.ant-tree-rtl .ant-tree-switcher_close .ant-tree-switcher-icon svg {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}

.ant-tree-rtl.ant-tree-show-line .ant-tree-indent-unit:before {
	border-left: 1px solid #d9d9d9;
	border-right: none;
	left: -13px;
	right: auto;
}

.ant-tree-rtl.ant-tree-checkbox,
.ant-tree-select-dropdown-rtl .ant-select-tree-checkbox {
	margin: 4px 0 0 8px;
}

.ant-select-tree-checkbox {
	box-sizing: border-box;
	color: #545454;
	cursor: pointer;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	line-height: 1;
	list-style: none;
	margin: 0;
	outline: none;
	padding: 0;
	position: relative;
	top: 0.2em;
	white-space: nowrap;
}

.ant-select-tree-checkbox-input:focus+.ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-inner,
.ant-select-tree-checkbox:hover .ant-select-tree-checkbox-inner {
	border-color: #038fde;
}

.ant-select-tree-checkbox-checked:after {
	-webkit-animation: antCheckboxEffect 0.36s ease-in-out;
	animation: antCheckboxEffect 0.36s ease-in-out;
	-webkit-animation-fill-mode: backwards;
	animation-fill-mode: backwards;
	border: 1px solid #038fde;
	border-radius: 6px;
	content: '';
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	visibility: hidden;
	width: 100%;
}

.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox:after,
.ant-select-tree-checkbox:hover:after {
	visibility: visible;
}

.ant-select-tree-checkbox-inner {
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-collapse: separate;
	border-radius: 6px;
	direction: ltr;
	display: block;
	height: 16px;
	left: 0;
	position: relative;
	top: 0;
	transition: all 0.3s;
	width: 16px;
}

.ant-select-tree-checkbox-inner:after {
	border: 2px solid #fff;
	border-left: 0;
	border-top: 0;
	content: ' ';
	display: table;
	height: 9.14285714px;
	left: 22%;
	opacity: 0;
	position: absolute;
	top: 50%;
	-webkit-transform: rotate(45deg) scale(0) translate(-50%, -50%);
	transform: rotate(45deg) scale(0) translate(-50%, -50%);
	transition: all 0.1s cubic-bezier(0.71, -0.46, 0.88, 0.6), opacity 0.1s;
	width: 5.71428571px;
}

.ant-select-tree-checkbox-input {
	bottom: 0;
	cursor: pointer;
	height: 100%;
	left: 0;
	opacity: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
	z-index: 1;
}

.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner:after {
	border: 2px solid #fff;
	border-left: 0;
	border-top: 0;
	content: ' ';
	display: table;
	opacity: 1;
	position: absolute;
	-webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
	transform: rotate(45deg) scale(1) translate(-50%, -50%);
	transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
}

.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner {
	background-color: #038fde;
	border-color: #038fde;
}

.ant-select-tree-checkbox-disabled {
	cursor: not-allowed;
}

.ant-select-tree-checkbox-disabled.ant-select-tree-checkbox-checked .ant-select-tree-checkbox-inner:after {
	-webkit-animation-name: none;
	animation-name: none;
	border-color: rgba(0, 0, 0, 0.25);
}

.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-input {
	cursor: not-allowed;
}

.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner {
	background-color: #f5f5f5;
	border-color: #d9d9d9 !important;
}

.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner:after {
	-webkit-animation-name: none;
	animation-name: none;
	border-collapse: separate;
	border-color: #f5f5f5;
}

.ant-select-tree-checkbox-disabled+span {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-select-tree-checkbox-disabled:hover:after,
.ant-select-tree-checkbox-wrapper:hover .ant-select-tree-checkbox-disabled:after {
	visibility: hidden;
}

.ant-select-tree-checkbox-wrapper {
	align-items: baseline;
	box-sizing: border-box;
	color: #545454;
	cursor: pointer;
	display: inline-flex;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	line-height: unset;
	list-style: none;
	margin: 0;
	padding: 0;
}

.ant-select-tree-checkbox-wrapper.ant-select-tree-checkbox-wrapper-disabled {
	cursor: not-allowed;
}

.ant-select-tree-checkbox-wrapper+.ant-select-tree-checkbox-wrapper {
	margin-left: 8px;
}

.ant-select-tree-checkbox+span {
	padding-left: 8px;
	padding-right: 8px;
}

.ant-select-tree-checkbox-group {
	box-sizing: border-box;
	color: #545454;
	display: inline-block;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
}

.ant-select-tree-checkbox-group-item {
	margin-right: 8px;
}

.ant-select-tree-checkbox-group-item:last-child {
	margin-right: 0;
}

.ant-select-tree-checkbox-group-item+.ant-select-tree-checkbox-group-item {
	margin-left: 0;
}

.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner {
	background-color: #fff;
	border-color: #d9d9d9;
}

.ant-select-tree-checkbox-indeterminate .ant-select-tree-checkbox-inner:after {
	background-color: #038fde;
	border: 0;
	content: ' ';
	height: 8px;
	left: 50%;
	opacity: 1;
	top: 50%;
	-webkit-transform: translate(-50%, -50%) scale(1);
	transform: translate(-50%, -50%) scale(1);
	width: 8px;
}

.ant-select-tree-checkbox-indeterminate.ant-select-tree-checkbox-disabled .ant-select-tree-checkbox-inner:after {
	background-color: rgba(0, 0, 0, 0.25);
	border-color: rgba(0, 0, 0, 0.25);
}

.ant-select-tree-checkbox-rtl {
	direction: rtl;
}

.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item {
	margin-left: 8px;
	margin-right: 0;
}

.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item:last-child {
	margin-left: 0 !important;
}

.ant-select-tree-checkbox-group-rtl .ant-select-tree-checkbox-group-item+.ant-select-tree-checkbox-group-item {
	margin-left: 8px;
}

.ant-tree-select-dropdown {
	padding: 8px 4px 0;
}

.ant-tree-select-dropdown-rtl {
	direction: rtl;
}

.ant-tree-select-dropdown .ant-select-tree {
	border-radius: 0;
}

.ant-tree-select-dropdown .ant-select-tree-list-holder-inner {
	align-items: stretch;
}

.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode {
	padding-bottom: 8px;
}

.ant-tree-select-dropdown .ant-select-tree-list-holder-inner .ant-select-tree-treenode .ant-select-tree-node-content-wrapper {
	flex: auto;
}

.ant-select-tree {
	background: #fff;
	border-radius: 6px;
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
	transition: background-color 0.3s;
}

.ant-select-tree-focused:not(:hover):not(.ant-select-tree-active-focused) {
	background: #e6faff;
}

.ant-select-tree-list-holder-inner {
	align-items: flex-start;
}

.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner {
	align-items: stretch;
}

.ant-select-tree.ant-select-tree-block-node .ant-select-tree-list-holder-inner .ant-select-tree-node-content-wrapper {
	flex: auto;
}

.ant-select-tree .ant-select-tree-treenode {
	align-items: flex-start;
	display: flex;
	outline: none;
	padding: 0 0 4px;
}

.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-select-tree .ant-select-tree-treenode-disabled .ant-select-tree-node-content-wrapper:hover {
	background: transparent;
}

.ant-select-tree .ant-select-tree-treenode-active .ant-select-tree-node-content-wrapper {
	background: #f5f5f5;
}

.ant-select-tree .ant-select-tree-treenode:not(.ant-select-tree .ant-select-tree-treenode-disabled).filter-node .ant-select-tree-title {
	color: inherit;
	font-weight: 500;
}

.ant-select-tree-indent {
	align-self: stretch;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	white-space: nowrap;
}

.ant-select-tree-indent-unit {
	display: inline-block;
	width: 24px;
}

.ant-select-tree-switcher {
	align-self: stretch;
	cursor: pointer;
	flex: none;
	line-height: 24px;
	margin: 0;
	position: relative;
	text-align: center;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
	width: 24px;
}

.ant-select-tree-switcher .ant-select-tree-switcher-icon,
.ant-select-tree-switcher .ant-tree-switcher-icon {
	display: inline-block;
	font-size: 10px;
	vertical-align: baseline;
}

.ant-select-tree-switcher .ant-select-tree-switcher-icon svg,
.ant-select-tree-switcher .ant-tree-switcher-icon svg {
	transition: -webkit-transform 0.3s;
	transition: transform 0.3s;
	transition: transform 0.3s, -webkit-transform 0.3s;
}

.ant-select-tree-switcher-noop {
	cursor: default;
}

.ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
	-webkit-transform: rotate(-90deg);
	transform: rotate(-90deg);
}

.ant-select-tree-switcher-loading-icon {
	color: #038fde;
}

.ant-select-tree-switcher-leaf-line {
	display: inline-block;
	height: 100%;
	position: relative;
	width: 100%;
	z-index: 1;
}

.ant-select-tree-switcher-leaf-line:before {
	border-left: 1px solid #d9d9d9;
	bottom: -4px;
	content: ' ';
	margin-left: -1px;
	position: absolute;
	top: 0;
}

.ant-select-tree-switcher-leaf-line:after {
	border-bottom: 1px solid #d9d9d9;
	content: ' ';
	height: 14px;
	margin-left: -1px;
	position: absolute;
	width: 10px;
}

.ant-select-tree-checkbox {
	margin: 4px 8px 0 0;
	top: auto;
}

.ant-select-tree .ant-select-tree-node-content-wrapper {
	background: transparent;
	border-radius: 6px;
	color: inherit;
	cursor: pointer;
	line-height: 24px;
	margin: 0;
	min-height: 24px;
	padding: 0 4px;
	position: relative;
	transition: all 0.3s, border 0s, line-height 0s, box-shadow 0s;
	z-index: auto;
}

.ant-select-tree .ant-select-tree-node-content-wrapper:hover {
	background-color: #f5f5f5;
}

.ant-select-tree .ant-select-tree-node-content-wrapper.ant-select-tree-node-selected {
	background-color: #a6eaff;
}

.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle {
	display: inline-block;
	height: 24px;
	line-height: 24px;
	text-align: center;
	vertical-align: top;
	width: 24px;
}

.ant-select-tree .ant-select-tree-node-content-wrapper .ant-select-tree-iconEle:empty {
	display: none;
}

.ant-select-tree-unselectable .ant-select-tree-node-content-wrapper:hover {
	background-color: transparent;
}

.ant-select-tree-node-content-wrapper[draggable='true'] {
	line-height: 24px;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ant-select-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator {
	background-color: #038fde;
	border-radius: 1px;
	height: 2px;
	pointer-events: none;
	position: absolute;
	z-index: 1;
}

.ant-select-tree-node-content-wrapper[draggable='true'] .ant-tree-drop-indicator:after {
	background-color: transparent;
	border: 2px solid #038fde;
	border-radius: 50%;
	content: '';
	height: 8px;
	left: -6px;
	position: absolute;
	top: -3px;
	width: 8px;
}

.ant-select-tree .ant-select-tree-treenode.drop-container>[draggable] {
	box-shadow: 0 0 0 2px #038fde;
}

.ant-select-tree-show-line .ant-select-tree-indent-unit {
	height: 100%;
	position: relative;
}

.ant-select-tree-show-line .ant-select-tree-indent-unit:before {
	border-right: 1px solid #d9d9d9;
	bottom: -4px;
	content: '';
	position: absolute;
	right: 12px;
	top: 0;
}

.ant-select-tree-show-line .ant-select-tree-indent-unit-end:before {
	display: none;
}

.ant-select-tree-show-line .ant-select-tree-switcher {
	background: #fff;
}

.ant-select-tree-show-line .ant-select-tree-switcher-line-icon {
	vertical-align: -0.225em;
}

.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher_close .ant-select-tree-switcher-icon svg {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
}

.ant-tree-select-dropdown-rtl .ant-select-tree .ant-select-tree-switcher-loading-icon {
	-webkit-transform: scaleY(-1);
	transform: scaleY(-1);
}

.ant-typography {
	color: #545454;
	overflow-wrap: break-word;
}

.ant-typography.ant-typography-secondary {
	color: #595959;
}

.ant-typography.ant-typography-success {
	color: #52c41a;
}

.ant-typography.ant-typography-warning {
	color: #fa8c16;
}

.ant-typography.ant-typography-danger {
	color: #f5222d;
}

a.ant-typography.ant-typography-danger:active,
a.ant-typography.ant-typography-danger:focus,
a.ant-typography.ant-typography-danger:hover {
	color: #ff4d4f;
}

.ant-typography.ant-typography-disabled {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.ant-typography p,
div.ant-typography {
	margin-bottom: 1em;
}

.ant-typography h1,
h1.ant-typography {
	color: #535353;
	font-size: 38px;
	font-weight: 600;
	line-height: 1.23;
	margin-bottom: 0.5em;
}

.ant-typography h2,
h2.ant-typography {
	color: #535353;
	font-size: 30px;
	font-weight: 600;
	line-height: 1.35;
	margin-bottom: 0.5em;
}

.ant-typography h3,
h3.ant-typography {
	color: #535353;
	font-size: 24px;
	font-weight: 600;
	line-height: 1.35;
	margin-bottom: 0.5em;
}

.ant-typography h4,
h4.ant-typography {
	color: #535353;
	font-size: 20px;
	font-weight: 600;
	line-height: 1.4;
	margin-bottom: 0.5em;
}

.ant-typography h5,
h5.ant-typography {
	color: #535353;
	font-size: 16px;
	font-weight: 600;
	line-height: 1.5;
	margin-bottom: 0.5em;
}

.ant-typography div+h1,
.ant-typography div+h2,
.ant-typography div+h3,
.ant-typography div+h4,
.ant-typography div+h5,
.ant-typography h1+h1,
.ant-typography h1+h2,
.ant-typography h1+h3,
.ant-typography h1+h4,
.ant-typography h1+h5,
.ant-typography h2+h1,
.ant-typography h2+h2,
.ant-typography h2+h3,
.ant-typography h2+h4,
.ant-typography h2+h5,
.ant-typography h3+h1,
.ant-typography h3+h2,
.ant-typography h3+h3,
.ant-typography h3+h4,
.ant-typography h3+h5,
.ant-typography h4+h1,
.ant-typography h4+h2,
.ant-typography h4+h3,
.ant-typography h4+h4,
.ant-typography h4+h5,
.ant-typography h5+h1,
.ant-typography h5+h2,
.ant-typography h5+h3,
.ant-typography h5+h4,
.ant-typography h5+h5,
.ant-typography li+h1,
.ant-typography li+h2,
.ant-typography li+h3,
.ant-typography li+h4,
.ant-typography li+h5,
.ant-typography p+h1,
.ant-typography p+h2,
.ant-typography p+h3,
.ant-typography p+h4,
.ant-typography p+h5,
.ant-typography ul+h1,
.ant-typography ul+h2,
.ant-typography ul+h3,
.ant-typography ul+h4,
.ant-typography ul+h5,
.ant-typography+h1.ant-typography,
.ant-typography+h2.ant-typography,
.ant-typography+h3.ant-typography,
.ant-typography+h4.ant-typography,
.ant-typography+h5.ant-typography {
	margin-top: 1.2em;
}

a.ant-typography-ellipsis,
span.ant-typography-ellipsis {
	display: inline-block;
}

.ant-typography a,
a.ant-typography {
	color: #038fde;
	cursor: pointer;
	outline: none;
	text-decoration: none;
	transition: color 0.3s;
}

.ant-typography a:focus,
.ant-typography a:hover,
a.ant-typography:focus,
a.ant-typography:hover {
	color: #28aaeb;
}

.ant-typography a:active,
a.ant-typography:active {
	color: #006eb8;
}

.ant-typography a:active,
.ant-typography a:hover,
a.ant-typography:active,
a.ant-typography:hover {
	text-decoration: none;
}

.ant-typography a.ant-typography-disabled,
.ant-typography a[disabled],
a.ant-typography.ant-typography-disabled,
a.ant-typography[disabled] {
	color: rgba(0, 0, 0, 0.25);
	cursor: not-allowed;
}

.ant-typography a.ant-typography-disabled:active,
.ant-typography a.ant-typography-disabled:hover,
.ant-typography a[disabled]:active,
.ant-typography a[disabled]:hover,
a.ant-typography.ant-typography-disabled:active,
a.ant-typography.ant-typography-disabled:hover,
a.ant-typography[disabled]:active,
a.ant-typography[disabled]:hover {
	color: rgba(0, 0, 0, 0.25);
}

.ant-typography a.ant-typography-disabled:active,
.ant-typography a[disabled]:active,
a.ant-typography.ant-typography-disabled:active,
a.ant-typography[disabled]:active {
	pointer-events: none;
}

.ant-typography code {
	background: hsla(0, 0%, 59%, 0.1);
	border: 1px solid hsla(0, 0%, 39%, 0.2);
	border-radius: 3px;
	font-size: 85%;
	margin: 0 0.2em;
	padding: 0.2em 0.4em 0.1em;
}

.ant-typography kbd {
	background: hsla(0, 0%, 59%, 0.06);
	border: solid hsla(0, 0%, 39%, 0.2);
	border-radius: 3px;
	border-width: 1px 1px 2px;
	font-size: 90%;
	margin: 0 0.2em;
	padding: 0.15em 0.4em 0.1em;
}

.ant-typography mark {
	background-color: #ffe58f;
	padding: 0;
}

.ant-typography ins,
.ant-typography u {
	-webkit-text-decoration-skip: ink;
	text-decoration: underline;
	text-decoration-skip-ink: auto;
}

.ant-typography del,
.ant-typography s {
	text-decoration: line-through;
}

.ant-typography strong {
	font-weight: 600;
}

.ant-typography-copy,
.ant-typography-edit,
.ant-typography-expand {
	color: #038fde;
	cursor: pointer;
	margin-left: 4px;
	outline: none;
	text-decoration: none;
	transition: color 0.3s;
}

.ant-typography-copy:focus,
.ant-typography-copy:hover,
.ant-typography-edit:focus,
.ant-typography-edit:hover,
.ant-typography-expand:focus,
.ant-typography-expand:hover {
	color: #28aaeb;
}

.ant-typography-copy:active,
.ant-typography-edit:active,
.ant-typography-expand:active {
	color: #006eb8;
}

.ant-typography-copy-success,
.ant-typography-copy-success:focus,
.ant-typography-copy-success:hover {
	color: #52c41a;
}

.ant-typography-edit-content {
	position: relative;
}

div.ant-typography-edit-content {
	left: -12px;
	margin-bottom: calc(1em - 8.9px);
	margin-top: -8.9px;
}

.ant-typography-edit-content-confirm {
	bottom: 8px;
	color: #595959;
	pointer-events: none;
	position: absolute;
	right: 10px;
}

.ant-typography-edit-content textarea {
	-moz-transition: none;
}

.ant-typography ol,
.ant-typography ul {
	margin: 0 0 1em;
	padding: 0;
}

.ant-typography ol li,
.ant-typography ul li {
	margin: 0 0 0 20px;
	padding: 0 0 0 4px;
}

.ant-typography ul {
	list-style-type: circle;
}

.ant-typography ul ul {
	list-style-type: disc;
}

.ant-typography ol {
	list-style-type: decimal;
}

.ant-typography blockquote,
.ant-typography pre {
	margin: 1em 0;
}

.ant-typography pre {
	word-wrap: break-word;
	background: hsla(0, 0%, 59%, 0.1);
	border: 1px solid hsla(0, 0%, 39%, 0.2);
	border-radius: 3px;
	padding: 0.4em 0.6em;
	white-space: pre-wrap;
}

.ant-typography pre code {
	background: transparent;
	border: 0;
	display: inline;
	font-family: inherit;
	font-size: inherit;
	margin: 0;
	padding: 0;
}

.ant-typography blockquote {
	border-left: 4px solid hsla(0, 0%, 39%, 0.2);
	opacity: 0.85;
	padding: 0 0 0 0.6em;
}

.ant-typography-ellipsis-single-line {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

a.ant-typography-ellipsis-single-line,
span.ant-typography-ellipsis-single-line {
	vertical-align: bottom;
}

.ant-typography-ellipsis-multiple-line {
	-webkit-line-clamp: 3;
	/*! autoprefixer: ignore next */
	-webkit-box-orient: vertical;
	display: -webkit-box;
	overflow: hidden;
}

.ant-typography-rtl {
	direction: rtl;
}

.ant-typography-rtl .ant-typography-copy,
.ant-typography-rtl .ant-typography-edit,
.ant-typography-rtl .ant-typography-expand {
	margin-left: 0;
	margin-right: 4px;
}

.ant-typography-rtl .ant-typography-expand {
	float: left;
}

div.ant-typography-edit-content.ant-typography-rtl {
	left: auto;
	right: -12px;
}

.ant-typography-rtl .ant-typography-edit-content-confirm {
	left: 10px;
	right: auto;
}

.ant-typography-rtl.ant-typography ol li,
.ant-typography-rtl.ant-typography ul li {
	margin: 0 20px 0 0;
	padding: 0 4px 0 0;
}

.ant-upload {
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	outline: 0;
	padding: 0;
}

.ant-upload p {
	margin: 0;
}

.ant-upload-btn {
	display: block;
	outline: none;
	width: 100%;
}

.ant-upload input[type='file'] {
	cursor: pointer;
}

.ant-upload.ant-upload-select {
	display: inline-block;
}

.ant-upload.ant-upload-disabled {
	cursor: not-allowed;
}

.ant-upload.ant-upload-select-picture-card {
	background-color: #fafafa;
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	height: 104px;
	margin-bottom: 8px;
	margin-right: 8px;
	text-align: center;
	transition: border-color 0.3s;
	vertical-align: top;
	width: 104px;
}

.ant-upload.ant-upload-select-picture-card>.ant-upload {
	align-items: center;
	display: flex;
	height: 100%;
	justify-content: center;
	text-align: center;
}

.ant-upload.ant-upload-select-picture-card:hover {
	border-color: #038fde;
}

.ant-upload-disabled.ant-upload.ant-upload-select-picture-card:hover {
	border-color: #d9d9d9;
}

.ant-upload.ant-upload-drag {
	background: #fafafa;
	border: 1px dashed #d9d9d9;
	border-radius: 6px;
	cursor: pointer;
	height: 100%;
	position: relative;
	text-align: center;
	transition: border-color 0.3s;
	width: 100%;
}

.ant-upload.ant-upload-drag .ant-upload {
	padding: 16px 0;
}

.ant-upload.ant-upload-drag.ant-upload-drag-hover:not(.ant-upload-disabled) {
	border-color: #006eb8;
}

.ant-upload.ant-upload-drag.ant-upload-disabled {
	cursor: not-allowed;
}

.ant-upload.ant-upload-drag .ant-upload-btn {
	display: table;
	height: 100%;
}

.ant-upload.ant-upload-drag .ant-upload-drag-container {
	display: table-cell;
	vertical-align: middle;
}

.ant-upload.ant-upload-drag:not(.ant-upload-disabled):hover {
	border-color: #28aaeb;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon {
	margin-bottom: 20px;
}

.ant-upload.ant-upload-drag p.ant-upload-drag-icon .anticon {
	color: #28aaeb;
	font-size: 48px;
}

.ant-upload.ant-upload-drag p.ant-upload-text {
	color: #535353;
	font-size: 16px;
	margin: 0 0 4px;
}

.ant-upload.ant-upload-drag p.ant-upload-hint {
	color: #595959;
	font-size: 14px;
}

.ant-upload.ant-upload-drag .anticon-plus {
	color: rgba(0, 0, 0, 0.25);
	font-size: 30px;
	transition: all 0.3s;
}

.ant-upload.ant-upload-drag .anticon-plus:hover,
.ant-upload.ant-upload-drag:hover .anticon-plus {
	color: #595959;
}

.ant-upload-picture-card-wrapper {
	display: inline-block;
	width: 100%;
}

.ant-upload-picture-card-wrapper:before {
	content: '';
	display: table;
}

.ant-upload-picture-card-wrapper:after {
	clear: both;
	content: '';
	display: table;
}

.ant-upload-list {
	box-sizing: border-box;
	color: #545454;
	font-size: 14px;
	font-variant: tabular-nums;
	line-height: 1.3;
	list-style: none;
	margin: 0;
	padding: 0;
}

.ant-upload-list:after,
.ant-upload-list:before {
	content: '';
	display: table;
}

.ant-upload-list:after {
	clear: both;
}

.ant-upload-list-item {
	font-size: 14px;
	height: 18.2px;
	margin-top: 8px;
	position: relative;
}

.ant-upload-list-item-name {
	display: inline-block;
	line-height: 1.3;
	overflow: hidden;
	padding-left: 22px;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}

.ant-upload-list-item-card-actions {
	position: absolute;
	right: 0;
}

.ant-upload-list-item-card-actions-btn {
	opacity: 0;
}

.ant-upload-list-item-card-actions-btn.ant-btn-sm {
	height: 20px;
	line-height: 1;
}

.ant-upload-list-item-card-actions.picture {
	line-height: 0;
	top: 22px;
}

.ant-upload-list-item-card-actions-btn:focus,
.ant-upload-list-item-card-actions.picture .ant-upload-list-item-card-actions-btn {
	opacity: 1;
}

.ant-upload-list-item-card-actions .anticon {
	color: #595959;
}

.ant-upload-list-item-info {
	height: 100%;
	padding: 0 4px;
	transition: background-color 0.3s;
}

.ant-upload-list-item-info>span {
	display: block;
	height: 100%;
	width: 100%;
}

.ant-upload-list-item-info .ant-upload-text-icon .anticon,
.ant-upload-list-item-info .anticon-loading .anticon {
	color: #595959;
	font-size: 14px;
	position: absolute;
	top: 5px;
}

.ant-upload-list-item .anticon-close {
	color: #595959;
	cursor: pointer;
	font-size: 10px;
	line-height: 0;
	opacity: 0;
	position: absolute;
	right: 4px;
	top: 6px;
	transition: all 0.3s;
}

.ant-upload-list-item .anticon-close:hover {
	color: #545454;
}

.ant-upload-list-item:hover .ant-upload-list-item-info {
	background-color: #f5f5f5;
}

.ant-upload-list-item:hover .ant-upload-list-item-card-actions-btn,
.ant-upload-list-item:hover .anticon-close {
	opacity: 1;
}

.ant-upload-list-item-error,
.ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon,
.ant-upload-list-item-error .ant-upload-list-item-name,
.ant-upload-list-item-error .ant-upload-text-icon>.anticon {
	color: #f5222d;
}

.ant-upload-list-item-error .ant-upload-list-item-card-actions-btn {
	opacity: 1;
}

.ant-upload-list-item-progress {
	bottom: -12px;
	font-size: 14px;
	line-height: 0;
	padding-left: 26px;
	position: absolute;
	width: 100%;
}

.ant-upload-list-picture .ant-upload-list-item,
.ant-upload-list-picture-card .ant-upload-list-item {
	border: 1px solid #d9d9d9;
	border-radius: 6px;
	height: 66px;
	padding: 8px;
	position: relative;
}

.ant-upload-list-picture .ant-upload-list-item:hover,
.ant-upload-list-picture-card .ant-upload-list-item:hover {
	background: transparent;
}

.ant-upload-list-picture .ant-upload-list-item-error,
.ant-upload-list-picture-card .ant-upload-list-item-error {
	border-color: #f5222d;
}

.ant-upload-list-picture .ant-upload-list-item:hover .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info {
	background: transparent;
}

.ant-upload-list-picture .ant-upload-list-item-uploading,
.ant-upload-list-picture-card .ant-upload-list-item-uploading {
	border-style: dashed;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
	height: 48px;
	line-height: 54px;
	opacity: 0.8;
	text-align: center;
	width: 48px;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
	font-size: 26px;
}

.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
	fill: #fff1f0;
}

.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
.ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'] {
	fill: #f5222d;
}

.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-picture-card .ant-upload-list-item-icon {
	font-size: 26px;
	left: 50%;
	position: absolute;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}

.ant-upload-list-picture .ant-upload-list-item-icon .anticon,
.ant-upload-list-picture-card .ant-upload-list-item-icon .anticon {
	font-size: 26px;
}

.ant-upload-list-picture .ant-upload-list-item-image,
.ant-upload-list-picture-card .ant-upload-list-item-image {
	max-width: 100%;
}

.ant-upload-list-picture .ant-upload-list-item-thumbnail img,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
	display: block;
	height: 48px;
	overflow: hidden;
	width: 48px;
}

.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-name {
	box-sizing: border-box;
	display: inline-block;
	line-height: 44px;
	margin: 0 0 0 8px;
	max-width: 100%;
	overflow: hidden;
	padding-left: 48px;
	padding-right: 8px;
	text-overflow: ellipsis;
	transition: all 0.3s;
	white-space: nowrap;
}

.ant-upload-list-picture .ant-upload-list-item-uploading .ant-upload-list-item-name,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-name {
	line-height: 28px;
}

.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-picture-card .ant-upload-list-item-progress {
	bottom: 14px;
	margin-top: 0;
	padding-left: 56px;
	width: calc(100% - 24px);
}

.ant-upload-list-picture .anticon-close,
.ant-upload-list-picture-card .anticon-close {
	line-height: 1;
	opacity: 1;
	position: absolute;
	right: 8px;
	top: 8px;
}

.ant-upload-list-picture-card-container {
	display: inline-block;
	height: 104px;
	margin: 0 8px 8px 0;
	vertical-align: top;
	width: 104px;
}

.ant-upload-list-picture-card.ant-upload-list:after {
	display: none;
}

.ant-upload-list-picture-card .ant-upload-list-item {
	height: 100%;
	margin: 0;
}

.ant-upload-list-picture-card .ant-upload-list-item-info {
	height: 100%;
	overflow: hidden;
	position: relative;
}

.ant-upload-list-picture-card .ant-upload-list-item-info:before {
	background-color: rgba(0, 0, 0, 0.5);
	content: ' ';
	height: 100%;
	opacity: 0;
	position: absolute;
	transition: all 0.3s;
	width: 100%;
	z-index: 1;
}

.ant-upload-list-picture-card .ant-upload-list-item:hover .ant-upload-list-item-info:before {
	opacity: 1;
}

.ant-upload-list-picture-card .ant-upload-list-item-actions {
	left: 50%;
	opacity: 0;
	position: absolute;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	transition: all 0.3s;
	white-space: nowrap;
	z-index: 10;
}

.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye {
	color: hsla(0, 0%, 100%, 0.85);
	cursor: pointer;
	font-size: 16px;
	margin: 0 4px;
	transition: all 0.3s;
	width: 16px;
	z-index: 10;
}

.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-delete:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-download:hover,
.ant-upload-list-picture-card .ant-upload-list-item-actions .anticon-eye:hover {
	color: #fff;
}

.ant-upload-list-picture-card .ant-upload-list-item-actions:hover,
.ant-upload-list-picture-card .ant-upload-list-item-info:hover+.ant-upload-list-item-actions {
	opacity: 1;
}

.ant-upload-list-picture-card .ant-upload-list-item-thumbnail,
.ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
	display: block;
	height: 100%;
	object-fit: contain;
	position: static;
	width: 100%;
}

.ant-upload-list-picture-card .ant-upload-list-item-name {
	display: none;
	line-height: 1.3;
	margin: 8px 0 0;
	padding: 0;
	text-align: center;
}

.ant-upload-list-picture-card .ant-upload-list-item-file+.ant-upload-list-item-name {
	bottom: 10px;
	display: block;
	position: absolute;
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading.ant-upload-list-item {
	background-color: #fafafa;
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info {
	height: auto;
}

.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-delete,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info .anticon-eye,
.ant-upload-list-picture-card .ant-upload-list-item-uploading .ant-upload-list-item-info:before {
	display: none;
}

.ant-upload-list-picture-card .ant-upload-list-item-progress {
	bottom: 32px;
	padding-left: 0;
	width: calc(100% - 14px);
}

.ant-upload-list-picture-container,
.ant-upload-list-text-container {
	transition: opacity 0.3s, height 0.3s;
}

.ant-upload-list-picture-container:before,
.ant-upload-list-text-container:before {
	content: '';
	display: table;
	height: 0;
	width: 0;
}

.ant-upload-list-picture-container .ant-upload-span,
.ant-upload-list-text-container .ant-upload-span {
	display: block;
	flex: auto;
}

.ant-upload-list-picture .ant-upload-span,
.ant-upload-list-text .ant-upload-span {
	align-items: center;
	display: flex;
}

.ant-upload-list-picture .ant-upload-span>*,
.ant-upload-list-text .ant-upload-span>* {
	flex: none;
}

.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-text .ant-upload-list-item-name {
	flex: auto;
	padding: 0 8px;
}

.ant-upload-list-picture .ant-upload-list-item-card-actions,
.ant-upload-list-text .ant-upload-list-item-card-actions,
.ant-upload-list-text .ant-upload-text-icon .anticon {
	position: static;
}

.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter,
.ant-upload-list .ant-upload-animate-inline-leave {
	-webkit-animation-duration: 0.3s;
	animation-duration: 0.3s;
	-webkit-animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
	animation-fill-mode: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-upload-list .ant-upload-animate-inline-appear,
.ant-upload-list .ant-upload-animate-inline-enter {
	-webkit-animation-name: uploadAnimateInlineIn;
	animation-name: uploadAnimateInlineIn;
}

.ant-upload-list .ant-upload-animate-inline-leave {
	-webkit-animation-name: uploadAnimateInlineOut;
	animation-name: uploadAnimateInlineOut;
}

@-webkit-keyframes uploadAnimateInlineIn {
	0% {
		height: 0;
		margin: 0;
		opacity: 0;
		padding: 0;
		width: 0;
	}
}

@keyframes uploadAnimateInlineIn {
	0% {
		height: 0;
		margin: 0;
		opacity: 0;
		padding: 0;
		width: 0;
	}
}

@-webkit-keyframes uploadAnimateInlineOut {
	to {
		height: 0;
		margin: 0;
		opacity: 0;
		padding: 0;
		width: 0;
	}
}

@keyframes uploadAnimateInlineOut {
	to {
		height: 0;
		margin: 0;
		opacity: 0;
		padding: 0;
		width: 0;
	}
}

.ant-upload-rtl {
	direction: rtl;
}

.ant-upload-rtl.ant-upload.ant-upload-select-picture-card {
	margin-left: 8px;
	margin-right: auto;
}

.ant-upload-list-rtl {
	direction: rtl;
}

.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-1 {
	padding-left: 14px;
	padding-right: 22px;
}

.ant-upload-list-rtl .ant-upload-list-item-list-type-text:hover .ant-upload-list-item-name-icon-count-2 {
	padding-left: 28px;
	padding-right: 22px;
}

.ant-upload-list-rtl .ant-upload-list-item-name {
	padding-left: 0;
	padding-right: 22px;
}

.ant-upload-list-rtl .ant-upload-list-item-name-icon-count-1 {
	padding-left: 14px;
}

.ant-upload-list-rtl .ant-upload-list-item-card-actions {
	left: 0;
	right: auto;
}

.ant-upload-list-rtl .ant-upload-list-item-card-actions .anticon {
	padding-left: 5px;
	padding-right: 0;
}

.ant-upload-list-rtl .ant-upload-list-item-info {
	padding: 0 4px 0 12px;
}

.ant-upload-list-rtl .ant-upload-list-item .anticon-close {
	left: 4px;
	right: auto;
}

.ant-upload-list-rtl .ant-upload-list-item-error .ant-upload-list-item-card-actions .anticon {
	padding-left: 5px;
	padding-right: 0;
}

.ant-upload-list-rtl .ant-upload-list-item-progress {
	padding-left: 0;
	padding-right: 26px;
}

.ant-upload-list-picture .ant-upload-list-item-info,
.ant-upload-list-picture-card .ant-upload-list-item-info {
	padding: 0;
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-thumbnail,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
	left: auto;
	right: 8px;
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-icon,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-icon {
	left: auto;
	right: 50%;
	-webkit-transform: translate(50%, -50%);
	transform: translate(50%, -50%);
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name {
	margin: 0 8px 0 0;
	padding-left: 8px;
	padding-right: 48px;
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-1,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-1 {
	padding-left: 18px;
	padding-right: 48px;
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-name-icon-count-2,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-name-icon-count-2 {
	padding-left: 36px;
	padding-right: 48px;
}

.ant-upload-list-rtl.ant-upload-list-picture .ant-upload-list-item-progress,
.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-progress {
	padding-left: 0;
	padding-right: 0;
}

.ant-upload-list-rtl.ant-upload-list-picture .anticon-close,
.ant-upload-list-rtl.ant-upload-list-picture-card .anticon-close {
	left: 8px;
	right: auto;
}

.ant-upload-list-rtl .ant-upload-list-picture-card-container {
	margin: 0 0 8px 8px;
}

.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-actions {
	left: auto;
	right: 50%;
	-webkit-transform: translate(50%, -50%);
	transform: translate(50%, -50%);
}

.ant-upload-list-rtl.ant-upload-list-picture-card .ant-upload-list-item-file+.ant-upload-list-item-name {
	margin: 8px 0 0;
	padding: 0;
}

.oitis-bg-primary,
a.oitis-bg-primary:focus,
a.oitis-bg-primary:hover {
	background-color: #038fde !important;
}

.oitis-bg-primary-light {
	background-color: #52c6f7 !important;
}

.oitis-bg-secondary,
a.oitis-bg-secondary:focus,
a.oitis-bg-secondary:hover {
	background-color: #fa8c15 !important;
}

.oitis-bg-success {
	background-color: #52c41a !important;
}

a.oitis-bg-success:focus,
a.oitis-bg-success:hover {
	background-color: #3f9714 !important;
}

.oitis-bg-success-dark {
	background-color: #368011 !important;
}

a.oitis-bg-success-dark:focus,
a.oitis-bg-success-dark:hover {
	background-color: #2c6a0e !important;
}

.oitis-bg-info {
	background-color: #1890ff !important;
}

a.oitis-bg-info:focus,
a.oitis-bg-info:hover {
	background-color: #0076e4 !important;
}

.oitis-bg-warning {
	background-color: #fa8c16 !important;
}

a.oitis-bg-warning:focus,
a.oitis-bg-warning:hover {
	background-color: #d87205 !important;
}

.oitis-bg-danger {
	background-color: #f44336 !important;
}

a.oitis-bg-danger:focus,
a.oitis-bg-danger:hover {
	background-color: #ea1c0d !important;
}

.oitis-bg-light {
	background-color: #e8e8e8 !important;
}

a.oitis-bg-light:focus,
a.oitis-bg-light:hover {
	background-color: #d9d9d9 !important;
}

.oitis-bg-dark {
	background-color: #000 !important;
}

a.oitis-bg-dark:focus,
a.oitis-bg-dark:hover {
	background-color: #1a1a1a !important;
}

.oitis-bg-white {
	background-color: #fff !important;
}

a.oitis-bg-white:focus,
a.oitis-bg-white:hover {
	background-color: #f2f2f2 !important;
}

.oitis-bg-grey {
	background-color: #ccc !important;
}

a.oitis-bg-grey:focus,
a.oitis-bg-grey:hover {
	background-color: #b2b2b2 !important;
}

.oitis-bg-light-grey {
	background-color: #fafafa !important;
}

.oitis-bg-transparent {
	background-color: transparent !important;
}

.oitis-bg-pink {
	background-color: #eb2f96 !important;
}

a.oitis-bg-pink:focus,
a.oitis-bg-pink:hover {
	background-color: #d3147d !important;
}

.oitis-bg-green {
	background-color: #52c41a !important;
}

a.oitis-bg-green:focus,
a.oitis-bg-green:hover {
	background-color: #3f9714 !important;
}

.oitis-bg-green-light {
	background-color: #caf5b5 !important;
}

a.oitis-bg-green-light:focus,
a.oitis-bg-green-light:hover {
	background-color: #8ae95b !important;
}

.oitis-bg-red {
	background-color: #f5222d !important;
}

a.oitis-bg-red:focus,
a.oitis-bg-red:hover {
	background-color: #da0a15 !important;
}

.oitis-bg-amber {
	background-color: #fadb14 !important;
}

a.oitis-bg-amber:focus,
a.oitis-bg-amber:hover {
	background-color: #d6ba05 !important;
}

.oitis-bg-amber-light {
	background-color: #fdf2aa !important;
}

a.oitis-bg-amber-light:focus,
a.oitis-bg-amber-light:hover {
	background-color: #fcea78 !important;
}

.oitis-bg-blue {
	background-color: #1890ff !important;
}

a.oitis-bg-blue:focus,
a.oitis-bg-blue:hover {
	background-color: #0076e4 !important;
}

.oitis-bg-light-blue {
	background-color: #329cff !important;
}

a.oitis-bg-light-blue:focus,
a.oitis-bg-light-blue:hover {
	background-color: #4ba9ff !important;
}

.oitis-bg-indigo {
	background-color: #3c1991 !important;
}

a.oitis-bg-indigo:focus,
a.oitis-bg-indigo:hover {
	background-color: #2a1166 !important;
}

.oitis-bg-purple {
	background-color: #722ed1 !important;
}

a.oitis-bg-purple:focus,
a.oitis-bg-purple:hover {
	background-color: #5b25a7 !important;
}

.oitis-bg-orange {
	background-color: #fa8c16 !important;
}

a.oitis-bg-orange:focus,
a.oitis-bg-orange:hover {
	background-color: #d87205 !important;
}

.oitis-bg-yellow {
	background-color: #fadb14 !important;
}

a.oitis-bg-yellow:focus,
a.oitis-bg-yellow:hover {
	background-color: #d6ba05 !important;
}

.oitis-bg-teal {
	background-color: #20c997 !important;
}

a.oitis-bg-teal:focus,
a.oitis-bg-teal:hover {
	background-color: #199d76 !important;
}

.oitis-bg-cyan {
	background-color: #13c2c2 !important;
}

a.oitis-bg-cyan:focus,
a.oitis-bg-cyan:hover {
	background-color: #0e9494 !important;
}

.oitis-bg-sepia {
	background-color: #7c3535 !important;
}

a.oitis-bg-sepia:focus,
a.oitis-bg-sepia:hover {
	background-color: #582626 !important;
}

.oitis-bg-geekblue {
	background-color: #030852 !important;
}

a.oitis-bg-geekblue:focus,
a.oitis-bg-geekblue:hover {
	background-color: #010321 !important;
}

.oitis-text-white {
	color: #fff !important;
}

.oitis-text-primary,
a.oitis-text-primary:focus,
a.oitis-text-primary:hover {
	color: #038fde !important;
}

.oitis-text-secondary,
a.oitis-text-secondary:focus,
a.oitis-text-secondary:hover {
	color: #fa8c15 !important;
}

.oitis-text-success {
	color: #52c41a !important;
}

a.oitis-text-success:focus,
a.oitis-text-success:hover {
	color: #49ad17 !important;
}

.oitis-text-success-dark {
	color: #368011 !important;
}

a.oitis-text-success-dark:focus,
a.oitis-text-success-dark:hover {
	color: #2c6a0e !important;
}

.oitis-text-info {
	color: #1890ff !important;
}

a.oitis-text-info:focus,
a.oitis-text-info:hover {
	color: #0084fe !important;
}

.oitis-text-warning {
	color: #fa8c16 !important;
}

a.oitis-text-warning:focus,
a.oitis-text-warning:hover {
	color: #f17f05 !important;
}

.oitis-text-danger {
	color: #f44336 !important;
}

a.oitis-text-danger:focus,
a.oitis-text-danger:hover {
	color: #f32c1e !important;
}

.oitis-text-light {
	color: #ababab !important;
}

a.oitis-text-light:focus,
a.oitis-text-light:hover {
	color: #787878 !important;
}

.oitis-text-light-grey {
	color: #ccc !important;
}

a.oitis-text-light-grey:focus,
a.oitis-text-light-grey:hover {
	color: #bfbfbf !important;
}

.oitis-text-grey {
	color: #8c8c8c !important;
}

a.oitis-text-grey:focus,
a.oitis-text-grey:hover {
	color: #262626 !important;
}

.oitis-text-dark {
	color: #595959 !important;
}

a.oitis-text-dark:focus,
a.oitis-text-dark:hover {
	color: #4c4c4c !important;
}

.oitis-text-black {
	color: #000 !important;
}

a.oitis-text-black:focus,
a.oitis-text-black:hover {
	color: #4c4c4c !important;
}

.oitis-text-pink {
	color: #eb2f96 !important;
}

a.oitis-text-pink:focus,
a.oitis-text-pink:hover {
	color: #e9188a !important;
}

.oitis-text-green {
	color: #52c41a !important;
}

a.oitis-text-green:focus,
a.oitis-text-green:hover {
	color: #49ad17 !important;
}

.oitis-text-red {
	color: #f5222d !important;
}

a.oitis-text-red:focus,
a.oitis-text-red:hover {
	color: #f30b17 !important;
}

.oitis-text-amber {
	color: #fadb14 !important;
}

a.oitis-text-amber:focus,
a.oitis-text-amber:hover {
	color: #efd005 !important;
}

.oitis-text-blue {
	color: #1890ff !important;
}

a.oitis-text-blue:focus,
a.oitis-text-blue:hover {
	color: #0084fe !important;
}

.oitis-text-indigo {
	color: #3c1991 !important;
}

a.oitis-text-indigo:focus,
a.oitis-text-indigo:hover {
	color: #33157b !important;
}

.oitis-text-purple {
	color: #722ed1 !important;
}

a.oitis-text-purple:focus,
a.oitis-text-purple:hover {
	color: #6729bc !important;
}

.oitis-text-orange {
	color: #fa8c16 !important;
}

a.oitis-text-orange:focus,
a.oitis-text-orange:hover {
	color: #f17f05 !important;
}

.oitis-text-yellow {
	color: #fadb14 !important;
}

a.oitis-text-yellow:focus,
a.oitis-text-yellow:hover {
	color: #efd005 !important;
}

.oitis-text-teal {
	color: #20c997 !important;
}

a.oitis-text-teal:focus,
a.oitis-text-teal:hover {
	color: #1cb386 !important;
}

.oitis-text-cyan {
	color: #13c2c2 !important;
}

a.oitis-text-cyan:focus,
a.oitis-text-cyan:hover {
	color: #11abab !important;
}

.oitis-text-muted {
	color: #8c8c8c !important;
}

.oitis-text-geekblue {
	color: #030852 !important;
}

a.oitis-text-geekblue:focus,
a.oitis-text-geekblue:hover {
	color: #10239e !important;
}

.oitis-text-sepia,
a.oitis-text-sepia:focus,
a.oitis-text-sepia:hover {
	color: #7c3535 !important;
}

.oitis-icon-white {
	color: #fff !important;
}

.oitis-border-primary {
	border-color: #038fde !important;
}

.oitis-border-secondary {
	border-color: #fa8c15 !important;
}

.oitis-border-success {
	border-color: #52c41a !important;
}

.oitis-border-success-dark {
	border-color: #368011 !important;
}

.oitis-border-info {
	border-color: #1890ff !important;
}

.oitis-border-warning {
	border-color: #fa8c16 !important;
}

.oitis-border-danger {
	border-color: #f44336 !important;
}

.oitis-border-light {
	border-color: #bfbfbf !important;
}

.oitis-border-dark {
	border-color: #595959 !important;
}

.oitis-border-white {
	border-color: #fff !important;
}

.oitis-border-grey {
	border-color: #bfbfbf !important;
}

.oitis-border-pink {
	border-color: #eb2f96 !important;
}

.oitis-border-green {
	border-color: #52c41a !important;
}

.oitis-border-red {
	border-color: #f5222d !important;
}

.oitis-border-amber {
	border-color: #fadb14 !important;
}

.oitis-border-blue {
	border-color: #1890ff !important;
}

.oitis-border-indigo {
	border-color: #3c1991 !important;
}

.oitis-border-purple {
	border-color: #722ed1 !important;
}

.oitis-border-orange {
	border-color: #fa8c16 !important;
}

.oitis-border-yellow {
	border-color: #fadb14 !important;
}

.oitis-border-teal {
	border-color: #1890ff !important;
}

.oitis-border-cyan {
	border-color: #13c2c2 !important;
}

.oitis-border-sepia {
	border-color: #7c3535 !important;
}

.oitis-border-geekblue {
	border-color: #030852 !important;
}

.btn-primary.disabled,
.btn-primary:disabled,
.oitis-btn-primary.disabled,
.oitis-btn-primary:disabled,
a.oitis-btn-primary.disabled,
a.oitis-btn-primary:disabled {
	background-color: #038fde !important;
	border-color: #038fde !important;
}

.btn-primary:not([disabled]):not(.disabled).active,
.btn-primary:not([disabled]):not(.disabled):active,
.oitis-btn-primary:not([disabled]):not(.disabled).active,
.oitis-btn-primary:not([disabled]):not(.disabled):active,
a.oitis-btn-primary:not([disabled]):not(.disabled).active,
a.oitis-btn-primary:not([disabled]):not(.disabled):active {
	background-color: #038fde !important;
	border-color: #038fde !important;
	color: #fff;
}

.btn-primary-light,
.oitis-btn-primary-light,
a.oitis-btn-primary-light {
	background-color: #c8ebfe !important;
	border-color: #c8ebfe !important;
	color: #595959 !important;
}

.btn-primary-light:focus,
.btn-primary-light:hover,
.oitis-btn-primary-light:focus,
.oitis-btn-primary-light:hover,
a.oitis-btn-primary-light:focus,
a.oitis-btn-primary-light:hover {
	background-color: #7dcffd !important;
	border-color: #7dcffd !important;
	color: #595959 !important;
}

.btn-primary-light.disabled,
.btn-primary-light:disabled,
.oitis-btn-primary-light.disabled,
.oitis-btn-primary-light:disabled,
a.oitis-btn-primary-light.disabled,
a.oitis-btn-primary-light:disabled {
	background-color: #e1f4ff !important;
	border-color: #e1f4ff !important;
}

.btn-primary-light:not([disabled]):not(.disabled).active,
.btn-primary-light:not([disabled]):not(.disabled):active,
.oitis-btn-primary-light:not([disabled]):not(.disabled).active,
.oitis-btn-primary-light:not([disabled]):not(.disabled):active,
a.oitis-btn-primary-light:not([disabled]):not(.disabled).active,
a.oitis-btn-primary-light:not([disabled]):not(.disabled):active {
	background-color: #afe1fe !important;
	border-color: #afe1fe !important;
	color: #595959;
}

.oitis-btn-secondary,
a.oitis-btn-secondary {
	background-color: #fa8c15 !important;
	border-color: #fa8c15 !important;
	color: #fff !important;
}

.oitis-btn-secondary:focus,
.oitis-btn-secondary:hover,
a.oitis-btn-secondary:focus,
a.oitis-btn-secondary:hover {
	background-color: #fa8c15 !important;
	border-color: #fa8c15 !important;
	color: #fff;
}

.oitis-btn-secondary.disabled,
.oitis-btn-secondary:disabled,
a.oitis-btn-secondary.disabled,
a.oitis-btn-secondary:disabled {
	background-color: #fa8c15 !important;
	border-color: #fa8c15 !important;
}

.oitis-btn-secondary:not([disabled]):not(.disabled).active,
.oitis-btn-secondary:not([disabled]):not(.disabled):active,
a.oitis-btn-secondary:not([disabled]):not(.disabled).active,
a.oitis-btn-secondary:not([disabled]):not(.disabled):active {
	background-color: #fa8c15 !important;
	border-color: #fa8c15 !important;
	color: #fff;
}

.oitis-btn-success,
a.oitis-btn-success {
	background-color: #52c41a !important;
	border-color: #52c41a !important;
	color: #fff !important;
}

.oitis-btn-success:focus,
.oitis-btn-success:hover,
a.oitis-btn-success:focus,
a.oitis-btn-success:hover {
	background-color: #3f9714 !important;
	border-color: #3f9714 !important;
	color: #fff !important;
}

.oitis-btn-success.disabled,
.oitis-btn-success:disabled,
a.oitis-btn-success.disabled,
a.oitis-btn-success:disabled {
	background-color: #52c41a !important;
	border-color: #52c41a !important;
}

.oitis-btn-success:not([disabled]):not(.disabled).active,
.oitis-btn-success:not([disabled]):not(.disabled):active,
a.oitis-btn-success:not([disabled]):not(.disabled).active,
a.oitis-btn-success:not([disabled]):not(.disabled):active {
	background-color: #3f9714 !important;
	border-color: #3f9714 !important;
	color: #fff !important;
}

.oitis-btn-success-dark,
a.oitis-btn-success-dark {
	background-color: #368011 !important;
	border-color: #368011 !important;
	color: #fff !important;
}

.oitis-btn-success-dark:focus,
.oitis-btn-success-dark:hover,
a.oitis-btn-success-dark:focus,
a.oitis-btn-success-dark:hover {
	background-color: #2c6a0e !important;
	border-color: #2c6a0e !important;
	color: #fff !important;
}

.oitis-btn-success-dark.disabled,
.oitis-btn-success-dark:disabled,
a.oitis-btn-success-dark.disabled,
a.oitis-btn-success-dark:disabled {
	background-color: #52c41a !important;
	border-color: #52c41a !important;
}

.oitis-btn-success-dark:not([disabled]):not(.disabled).active,
.oitis-btn-success-dark:not([disabled]):not(.disabled):active,
a.oitis-btn-success-dark:not([disabled]):not(.disabled).active,
a.oitis-btn-success-dark:not([disabled]):not(.disabled):active {
	background-color: #2c6a0e !important;
	border-color: #2c6a0e !important;
	color: #fff !important;
}

.oitis-btn-info,
a.oitis-btn-info {
	background-color: #1890ff !important;
	border-color: #1890ff !important;
	color: #fff !important;
}

.oitis-btn-info:focus,
.oitis-btn-info:hover,
a.oitis-btn-info:focus,
a.oitis-btn-info:hover {
	background-color: #0076e4 !important;
	border-color: #0076e4 !important;
	color: #fff !important;
}

.oitis-btn-info.disabled,
.oitis-btn-info:disabled,
a.oitis-btn-info.disabled,
a.oitis-btn-info:disabled {
	background-color: #1890ff !important;
	border-color: #1890ff !important;
}

.oitis-btn-info:not([disabled]):not(.disabled).active,
.oitis-btn-info:not([disabled]):not(.disabled):active,
a.oitis-btn-info:not([disabled]):not(.disabled).active,
a.oitis-btn-info:not([disabled]):not(.disabled):active {
	background-color: #0076e4 !important;
	border-color: #0076e4 !important;
	color: #fff !important;
}

.oitis-btn-warning,
a.oitis-btn-warning {
	background-color: #fa8c16 !important;
	border-color: #fa8c16 !important;
	color: #fff !important;
}

.oitis-btn-warning:focus,
.oitis-btn-warning:hover,
a.oitis-btn-warning:focus,
a.oitis-btn-warning:hover {
	background-color: #d87205 !important;
	border-color: #d87205 !important;
	color: #fff !important;
}

.oitis-btn-warning.disabled,
.oitis-btn-warning:disabled,
a.oitis-btn-warning.disabled,
a.oitis-btn-warning:disabled {
	background-color: #fa8c16 !important;
	border-color: #fa8c16 !important;
}

.oitis-btn-warning:not([disabled]):not(.disabled).active,
.oitis-btn-warning:not([disabled]):not(.disabled):active,
a.oitis-btn-warning:not([disabled]):not(.disabled).active,
a.oitis-btn-warning:not([disabled]):not(.disabled):active {
	background-color: #d87205 !important;
	border-color: #d87205 !important;
	color: #fff !important;
}

.oitis-btn-yellow,
a.oitis-btn-yellow {
	background-color: #fadb14 !important;
	border-color: #fadb14 !important;
	color: #262626 !important;
}

.oitis-btn-yellow:focus,
.oitis-btn-yellow:hover,
a.oitis-btn-yellow:focus,
a.oitis-btn-yellow:hover {
	background-color: #d6ba05 !important;
	border-color: #d6ba05 !important;
	color: #fff !important;
}

.oitis-btn-yellow.disabled,
.oitis-btn-yellow:disabled,
a.oitis-btn-yellow.disabled,
a.oitis-btn-yellow:disabled {
	background-color: #fadb14 !important;
	border-color: #fadb14 !important;
}

.oitis-btn-yellow:not([disabled]):not(.disabled).active,
.oitis-btn-yellow:not([disabled]):not(.disabled):active,
a.oitis-btn-yellow:not([disabled]):not(.disabled).active,
a.oitis-btn-yellow:not([disabled]):not(.disabled):active {
	background-color: #d6ba05 !important;
	border-color: #d6ba05 !important;
	color: #fff !important;
}

.oitis-btn-danger,
.oitis-btn-red,
a.oitis-btn-danger,
a.oitis-btn-red {
	background-color: #f44336 !important;
	border-color: #f44336 !important;
	color: #fff !important;
}

.oitis-btn-danger:focus,
.oitis-btn-danger:hover,
.oitis-btn-red:focus,
.oitis-btn-red:hover,
a.oitis-btn-danger:focus,
a.oitis-btn-danger:hover,
a.oitis-btn-red:focus,
a.oitis-btn-red:hover {
	background-color: #ea1c0d !important;
	border-color: #ea1c0d !important;
	color: #fff !important;
}

.oitis-btn-danger.disabled,
.oitis-btn-danger:disabled,
.oitis-btn-red.disabled,
.oitis-btn-red:disabled,
a.oitis-btn-danger.disabled,
a.oitis-btn-danger:disabled,
a.oitis-btn-red.disabled,
a.oitis-btn-red:disabled {
	background-color: #f44336 !important;
	border-color: #f44336 !important;
}

.oitis-btn-danger:not([disabled]):not(.disabled).active,
.oitis-btn-danger:not([disabled]):not(.disabled):active,
.oitis-btn-red:not([disabled]):not(.disabled).active,
.oitis-btn-red:not([disabled]):not(.disabled):active,
a.oitis-btn-danger:not([disabled]):not(.disabled).active,
a.oitis-btn-danger:not([disabled]):not(.disabled):active,
a.oitis-btn-red:not([disabled]):not(.disabled).active,
a.oitis-btn-red:not([disabled]):not(.disabled):active {
	background-color: #ea1c0d !important;
	border-color: #ea1c0d !important;
	color: #fff !important;
}

.oitis-btn-light,
a.oitis-btn-light {
	background-color: #e8e8e8 !important;
	border-color: #e8e8e8 !important;
	color: #8c8c8c !important;
}

.oitis-btn-light:focus,
.oitis-btn-light:hover,
a.oitis-btn-light:focus,
a.oitis-btn-light:hover {
	background-color: #d9d9d9 !important;
	border-color: #d9d9d9 !important;
	color: #8c8c8c !important;
}

.oitis-btn-light.disabled,
.oitis-btn-light:disabled,
a.oitis-btn-light.disabled,
a.oitis-btn-light:disabled {
	background-color: #f5f5f5 !important;
	border-color: #f5f5f5 !important;
}

.oitis-btn-light:not([disabled]):not(.disabled).active,
.oitis-btn-light:not([disabled]):not(.disabled):active,
a.oitis-btn-light:not([disabled]):not(.disabled).active,
a.oitis-btn-light:not([disabled]):not(.disabled):active {
	background-color: #e8e8e8 !important;
	border-color: #e8e8e8 !important;
	color: #262626 !important;
}

.oitis-btn-dark,
a.oitis-btn-dark {
	background-color: #262626 !important;
	border-color: #262626 !important;
	color: #fff !important;
}

.oitis-btn-dark:focus,
.oitis-btn-dark:hover,
a.oitis-btn-dark:focus,
a.oitis-btn-dark:hover {
	background-color: #0c0c0c !important;
	border-color: #0c0c0c !important;
	color: #fff !important;
}

.oitis-btn-dark.disabled,
.oitis-btn-dark:disabled,
a.oitis-btn-dark.disabled,
a.oitis-btn-dark:disabled {
	background-color: #262626 !important;
	border-color: #262626 !important;
}

.oitis-btn-dark:not([disabled]):not(.disabled).active,
.oitis-btn-dark:not([disabled]):not(.disabled):active,
a.oitis-btn-dark:not([disabled]):not(.disabled).active,
a.oitis-btn-dark:not([disabled]):not(.disabled):active {
	background-color: #0c0c0c !important;
	border-color: #0c0c0c !important;
	color: #fff !important;
}

.oitis-btn-purple,
a.oitis-btn-purple {
	background-color: #722ed1 !important;
	border-color: #722ed1 !important;
	color: #fff !important;
}

.oitis-btn-purple:focus,
.oitis-btn-purple:hover,
a.oitis-btn-purple:focus,
a.oitis-btn-purple:hover {
	background-color: #5b25a7 !important;
	border-color: #5b25a7 !important;
	color: #fff !important;
}

.oitis-btn-purple.disabled,
.oitis-btn-purple:disabled,
a.oitis-btn-purple.disabled,
a.oitis-btn-purple:disabled {
	background-color: #722ed1 !important;
	border-color: #722ed1 !important;
}

.oitis-btn-purple:not([disabled]):not(.disabled).active,
.oitis-btn-purple:not([disabled]):not(.disabled):active,
a.oitis-btn-purple:not([disabled]):not(.disabled).active,
a.oitis-btn-purple:not([disabled]):not(.disabled):active {
	background-color: #5b25a7 !important;
	border-color: #5b25a7 !important;
	color: #fff !important;
}

.oitis-btn-sepia,
a.oitis-btn-sepia {
	background-color: #7c3535 !important;
	border-color: #7c3535 !important;
	color: #fff !important;
}

.oitis-btn-sepia:focus,
.oitis-btn-sepia:hover,
a.oitis-btn-sepia:focus,
a.oitis-btn-sepia:hover {
	background-color: #461e1e !important;
	border-color: #461e1e !important;
	color: #fff !important;
}

.oitis-btn-sepia.disabled,
.oitis-btn-sepia:disabled,
a.oitis-btn-sepia.disabled,
a.oitis-btn-sepia:disabled {
	background-color: #7c3535 !important;
	border-color: #7c3535 !important;
}

.oitis-btn-sepia:not([disabled]):not(.disabled).active,
.oitis-btn-sepia:not([disabled]):not(.disabled):active,
a.oitis-btn-sepia:not([disabled]):not(.disabled).active,
a.oitis-btn-sepia:not([disabled]):not(.disabled):active {
	background-color: #461e1e !important;
	border-color: #461e1e !important;
	color: #fff !important;
}

.oitis-btn-geekblue,
a.oitis-btn-geekblue {
	background-color: #10239e !important;
	border-color: #10239e !important;
	color: #fff !important;
}

.oitis-btn-geekblue:focus,
.oitis-btn-geekblue:hover,
a.oitis-btn-geekblue:focus,
a.oitis-btn-geekblue:hover {
	background-color: #0b1970 !important;
	border-color: #0b1970 !important;
	color: #fff !important;
}

.oitis-btn-geekblue.disabled,
.oitis-btn-geekblue:disabled,
a.oitis-btn-geekblue.disabled,
a.oitis-btn-geekblue:disabled {
	background-color: #10239e !important;
	border-color: #10239e !important;
}

.oitis-btn-geekblue:not([disabled]):not(.disabled).active,
.oitis-btn-geekblue:not([disabled]):not(.disabled):active,
a.oitis-btn-geekblue:not([disabled]):not(.disabled).active,
a.oitis-btn-geekblue:not([disabled]):not(.disabled):active {
	background-color: #091459 !important;
	border-color: #091459 !important;
	color: #fff !important;
}

.oitis-btn-cyan,
a.oitis-btn-cyan {
	background-color: #13c2c2 !important;
	border-color: #13c2c2 !important;
	color: #fff !important;
}

.oitis-btn-cyan:focus,
.oitis-btn-cyan:hover,
a.oitis-btn-cyan:focus,
a.oitis-btn-cyan:hover {
	background-color: #0e9494 !important;
	border-color: #0e9494 !important;
	color: #fff !important;
}

.oitis-btn-cyan.disabled,
.oitis-btn-cyan:disabled,
a.oitis-btn-cyan.disabled,
a.oitis-btn-cyan:disabled {
	background-color: #13c2c2 !important;
	border-color: #13c2c2 !important;
}

.oitis-btn-cyan:not([disabled]):not(.disabled).active,
.oitis-btn-cyan:not([disabled]):not(.disabled):active,
a.oitis-btn-cyan:not([disabled]):not(.disabled).active,
a.oitis-btn-cyan:not([disabled]):not(.disabled):active {
	background-color: #0e8a8a !important;
	border-color: #0e8a8a !important;
	color: #fff !important;
}

.oitis-btn-white,
a.oitis-btn-white {
	background-color: #fff !important;
	border-color: #fff !important;
	color: #545454 !important;
}

.oitis-btn-white:focus,
.oitis-btn-white:hover,
a.oitis-btn-white:focus,
a.oitis-btn-white:hover {
	background-color: #e6e6e6 !important;
	border-color: #e6e6e6 !important;
	color: #545454 !important;
}

.oitis-btn-white.disabled,
.oitis-btn-white:disabled,
a.oitis-btn-white.disabled,
a.oitis-btn-white:disabled {
	background-color: #fff !important;
	border-color: #fff !important;
}

.oitis-btn-white:not([disabled]):not(.disabled).active,
.oitis-btn-white:not([disabled]):not(.disabled):active,
a.oitis-btn-white:not([disabled]):not(.disabled).active,
a.oitis-btn-white:not([disabled]):not(.disabled):active {
	background-color: #e6e6e6 !important;
	border-color: #e6e6e6 !important;
	color: #545454 !important;
}

.oitis-btn-orange,
a.oitis-btn-orange {
	background-color: #fa8c16 !important;
	border-color: #fa8c16 !important;
	color: #fff !important;
}

.oitis-btn-orange:focus,
.oitis-btn-orange:hover,
a.oitis-btn-orange:focus,
a.oitis-btn-orange:hover {
	background-color: #d87205 !important;
	border-color: #d87205 !important;
	color: #fff !important;
}

.oitis-btn-orange.disabled,
.oitis-btn-orange:disabled,
a.oitis-btn-orange.disabled,
a.oitis-btn-orange:disabled {
	background-color: #fa8c16 !important;
	border-color: #fa8c16 !important;
}

.oitis-btn-orange:not([disabled]):not(.disabled).active,
.oitis-btn-orange:not([disabled]):not(.disabled):active,
a.oitis-btn-orange:not([disabled]):not(.disabled).active,
a.oitis-btn-orange:not([disabled]):not(.disabled):active {
	background-color: #ce6d04 !important;
	border-color: #ce6d04 !important;
	color: #fff !important;
}

.oitis-btn-outline-primary,
a.oitis-btn-outline-primary {
	background-color: transparent !important;
	border-color: #038fde !important;
	color: #038fde !important;
}

.oitis-btn-outline-primary:focus,
.oitis-btn-outline-primary:hover,
a.oitis-btn-outline-primary:focus,
a.oitis-btn-outline-primary:hover {
	background-color: #038fde !important;
	border-color: #038fde !important;
	color: #fff !important;
}

.oitis-btn-outline-primary.disabled,
.oitis-btn-outline-primary:disabled,
a.oitis-btn-outline-primary.disabled,
a.oitis-btn-outline-primary:disabled {
	background-color: transparent !important;
	color: #038fde !important;
}

.oitis-btn-outline-secondary,
a.oitis-btn-outline-secondary {
	background-color: transparent !important;
	border-color: #fa8c15 !important;
	color: #fa8c15 !important;
}

.oitis-btn-outline-secondary:focus,
.oitis-btn-outline-secondary:hover,
a.oitis-btn-outline-secondary:focus,
a.oitis-btn-outline-secondary:hover {
	background-color: #fa8c15 !important;
	border-color: #fa8c15 !important;
	color: #fff !important;
}

.oitis-btn-outline-secondary.disabled,
.oitis-btn-outline-secondary:disabled,
a.oitis-btn-outline-secondary.disabled,
a.oitis-btn-outline-secondary:disabled {
	background-color: transparent !important;
	color: #fa8c15 !important;
}

.oitis-btn-outline-success,
a.oitis-btn-outline-success {
	background-color: transparent !important;
	border-color: #52c41a !important;
	color: #52c41a !important;
}

.oitis-btn-outline-success:focus,
.oitis-btn-outline-success:hover,
a.oitis-btn-outline-success:focus,
a.oitis-btn-outline-success:hover {
	background-color: #52c41a !important;
	border-color: #52c41a !important;
	color: #fff !important;
}

.oitis-btn-outline-success-dark,
.oitis-btn-outline-success.disabled,
.oitis-btn-outline-success:disabled,
a.oitis-btn-outline-success-dark,
a.oitis-btn-outline-success.disabled,
a.oitis-btn-outline-success:disabled {
	background-color: transparent !important;
	color: #52c41a !important;
}

.oitis-btn-outline-success-dark,
a.oitis-btn-outline-success-dark {
	border-color: #368011 !important;
}

.oitis-btn-outline-success-dark:focus,
.oitis-btn-outline-success-dark:hover,
a.oitis-btn-outline-success-dark:focus,
a.oitis-btn-outline-success-dark:hover {
	background-color: #2c6a0e !important;
	border-color: #2c6a0e !important;
	color: #fff !important;
}

.oitis-btn-outline-success-dark.disabled,
.oitis-btn-outline-success-dark:disabled,
a.oitis-btn-outline-success-dark.disabled,
a.oitis-btn-outline-success-dark:disabled {
	background-color: transparent !important;
	color: #2c6a0e !important;
}

.oitis-btn-outline-info,
a.oitis-btn-outline-info {
	background-color: transparent !important;
	border-color: #1890ff !important;
	color: #1890ff !important;
}

.oitis-btn-outline-info:focus,
.oitis-btn-outline-info:hover,
a.oitis-btn-outline-info:focus,
a.oitis-btn-outline-info:hover {
	background-color: #1890ff !important;
	border-color: #1890ff !important;
	color: #fff !important;
}

.oitis-btn-outline-info.disabled,
.oitis-btn-outline-info:disabled,
a.oitis-btn-outline-info.disabled,
a.oitis-btn-outline-info:disabled {
	background-color: transparent !important;
	color: #1890ff !important;
}

.oitis-btn-outline-warning,
a.oitis-btn-outline-warning {
	background-color: transparent !important;
	border-color: #fa8c16 !important;
	color: #fa8c16 !important;
}

.oitis-btn-outline-warning:focus,
.oitis-btn-outline-warning:hover,
a.oitis-btn-outline-warning:focus,
a.oitis-btn-outline-warning:hover {
	background-color: #fa8c16 !important;
	border-color: #fa8c16 !important;
	color: #262626 !important;
}

.oitis-btn-outline-warning.disabled,
.oitis-btn-outline-warning:disabled,
a.oitis-btn-outline-warning.disabled,
a.oitis-btn-outline-warning:disabled {
	background-color: transparent !important;
	color: #fa8c16 !important;
}

.oitis-btn-outline-danger,
.oitis-btn-outline-red,
a.oitis-btn-outline-danger,
a.oitis-btn-outline-red {
	background-color: transparent !important;
	border-color: #f44336 !important;
	color: #f44336 !important;
}

.oitis-btn-outline-danger:focus,
.oitis-btn-outline-danger:hover,
.oitis-btn-outline-red:focus,
.oitis-btn-outline-red:hover,
a.oitis-btn-outline-danger:focus,
a.oitis-btn-outline-danger:hover,
a.oitis-btn-outline-red:focus,
a.oitis-btn-outline-red:hover {
	background-color: #f44336 !important;
	border-color: #f44336 !important;
	color: #fff !important;
}

.oitis-btn-outline-danger.disabled,
.oitis-btn-outline-danger:disabled,
.oitis-btn-outline-red.disabled,
.oitis-btn-outline-red:disabled,
a.oitis-btn-outline-danger.disabled,
a.oitis-btn-outline-danger:disabled,
a.oitis-btn-outline-red.disabled,
a.oitis-btn-outline-red:disabled {
	background-color: transparent !important;
	color: #f44336 !important;
}

.oitis-btn-outline-light,
a.oitis-btn-outline-light {
	background-color: transparent !important;
	border-color: #d9d9d9 !important;
	color: #8c8c8c !important;
}

.oitis-btn-outline-light:focus,
.oitis-btn-outline-light:hover,
a.oitis-btn-outline-light:focus,
a.oitis-btn-outline-light:hover {
	background-color: #d9d9d9 !important;
	border-color: #d9d9d9 !important;
	color: #8c8c8c !important;
}

.oitis-btn-outline-light.disabled,
.oitis-btn-outline-light:disabled,
a.oitis-btn-outline-light.disabled,
a.oitis-btn-outline-light:disabled {
	background-color: transparent !important;
	color: #e8e8e8 !important;
}

.oitis-btn-outline-dark,
a.oitis-btn-outline-dark {
	background-color: transparent !important;
	border-color: #262626 !important;
	color: #262626 !important;
}

.oitis-btn-outline-dark:focus,
.oitis-btn-outline-dark:hover,
a.oitis-btn-outline-dark:focus,
a.oitis-btn-outline-dark:hover {
	background-color: #262626 !important;
	border-color: #262626 !important;
	color: #fff !important;
}

.oitis-btn-outline-dark.disabled,
.oitis-btn-outline-dark:disabled,
a.oitis-btn-outline-dark.disabled,
a.oitis-btn-outline-dark:disabled {
	background-color: transparent !important;
	color: #262626 !important;
}

.oitis-btn-outline-sepia,
a.oitis-btn-outline-sepia {
	background-color: transparent !important;
	border-color: #7c3535 !important;
	color: #fff !important;
}

.oitis-btn-outline-sepia:focus,
.oitis-btn-outline-sepia:hover,
a.oitis-btn-outline-sepia:focus,
a.oitis-btn-outline-sepia:hover {
	background-color: #7c3535 !important;
	border-color: #7c3535 !important;
	color: #fff !important;
}

.oitis-btn-outline-sepia.disabled,
.oitis-btn-outline-sepia:disabled,
a.oitis-btn-outline-sepia.disabled,
a.oitis-btn-outline-sepia:disabled {
	background-color: transparent !important;
	color: #7c3535 !important;
}

.oitis-btn-outline-geekblue,
a.oitis-btn-outline-geekblue {
	background-color: transparent !important;
	border-color: #030852 !important;
	color: #fff !important;
}

.oitis-btn-outline-geekblue:focus,
.oitis-btn-outline-geekblue:hover,
a.oitis-btn-outline-geekblue:focus,
a.oitis-btn-outline-geekblue:hover {
	background-color: #030852 !important;
	border-color: #030852 !important;
	color: #fff !important;
}

.oitis-btn-outline-geekblue.disabled,
.oitis-btn-outline-geekblue:disabled,
a.oitis-btn-outline-geekblue.disabled,
a.oitis-btn-outline-geekblue:disabled {
	background-color: transparent !important;
	color: #2f54eb !important;
}

.oitis-btn-outline-orange,
a.oitis-btn-outline-orange {
	background-color: transparent !important;
	border-color: #fa8c16 !important;
	color: #fff !important;
}

.oitis-btn-outline-orange:focus,
.oitis-btn-outline-orange:hover,
a.oitis-btn-outline-orange:focus,
a.oitis-btn-outline-orange:hover {
	background-color: #fa8c16 !important;
	border-color: #fa8c16 !important;
	color: #fff !important;
}

.oitis-btn-outline-orange.disabled,
.oitis-btn-outline-orange:disabled,
a.oitis-btn-outline-orange.disabled,
a.oitis-btn-outline-orange:disabled {
	background-color: transparent !important;
	color: #fa8c16 !important;
}

.oitis-badge-primary {
	background-color: #038fde !important;
}

.oitis-badge-primary-light {
	background-color: #52c6f7 !important;
}

.oitis-badge-secondary {
	background-color: #fa8c15 !important;
}

.oitis-badge-success {
	background-color: #52c41a !important;
}

.oitis-badge-success-dark {
	background-color: #368011 !important;
}

.oitis-badge-info {
	background-color: #1890ff !important;
}

.oitis-badge-warning {
	background-color: #fa8c16 !important;
}

.oitis-badge-danger {
	background-color: #f44336 !important;
}

.oitis-badge-light {
	background-color: #e8e8e8 !important;
}

.oitis-badge-dark {
	background-color: #000 !important;
}

.oitis-badge-white {
	background-color: #fff !important;
}

.oitis-badge-grey {
	background-color: #bfbfbf !important;
}

.oitis-badge-light-grey {
	background-color: #d9d9d9 !important;
}

.oitis-badge-pink {
	background-color: #eb2f96 !important;
}

.oitis-badge-green {
	background-color: #52c41a !important;
}

.oitis-badge-green-light {
	background-color: #caf5b5 !important;
}

.oitis-badge-red {
	background-color: #f5222d !important;
}

.oitis-badge-amber {
	background-color: #fadb14 !important;
}

.oitis-badge-amber-light {
	background-color: #fdf2aa !important;
}

.oitis-badge-blue {
	background-color: #1890ff !important;
}

.oitis-badge-light-blue {
	background-color: #329cff !important;
}

.oitis-badge-indigo {
	background-color: #3c1991 !important;
}

.oitis-badge-purple {
	background-color: #722ed1 !important;
}

.oitis-badge-orange {
	background-color: #fa8c16 !important;
}

.oitis-badge-yellow {
	background-color: #fadb14 !important;
}

.oitis-badge-teal {
	background-color: #20c997 !important;
}

.oitis-badge-cyan {
	background-color: #13c2c2 !important;
}

.oitis-badge-sepia {
	background-color: #7c3535 !important;
}

.oitis-badge-geekblue {
	background-color: #030852 !important;
}

.oitis-ripple-effect {
	overflow: hidden;
	position: relative;
}

.oitis-ripple-effect:after {
	background: hsla(0, 0%, 100%, 0.5);
	border-radius: 100%;
	content: '';
	height: 5px;
	left: 50%;
	opacity: 0;
	position: absolute;
	top: 50%;
	-webkit-transform: scale(1) translate(-50%);
	transform: scale(1) translate(-50%);
	-webkit-transform-origin: 50% 50%;
	transform-origin: 50% 50%;
	width: 5px;
}

.oitis-ripple-effect:hover:after {
	-webkit-animation: ripple 1s ease-out;
	animation: ripple 1s ease-out;
}

@keyframes ripple {
	0% {
		opacity: 0.5;
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	20% {
		opacity: 0.3;
		-webkit-transform: scale(60);
		transform: scale(60);
	}

	to {
		opacity: 0;
		-webkit-transform: scale(100);
		transform: scale(100);
	}
}

@-webkit-keyframes ripple {
	0% {
		opacity: 0.5;
		-webkit-transform: scale(0);
		transform: scale(0);
	}

	20% {
		opacity: 0.3;
		-webkit-transform: scale(60);
		transform: scale(60);
	}

	to {
		opacity: 0;
		-webkit-transform: scale(100);
		transform: scale(100);
	}
}

.oitis-pulse-effect {
	-webkit-animation: pulse 2s infinite;
	animation: pulse 2s infinite;
	box-shadow: 0 0 0 rgba(244, 67, 54, 0.4);
	display: block;
}

@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.4);
	}

	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(244, 67, 54, 0);
	}

	to {
		-webkit-box-shadow: 0 0 0 0 rgba(244, 67, 54, 0);
	}
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(244, 67, 54, 0.4);
	}

	70% {
		box-shadow: 0 0 0 10px rgba(244, 67, 54, 0);
	}

	to {
		box-shadow: 0 0 0 0 rgba(244, 67, 54, 0);
	}
}

.oitis-online-effect {
	-webkit-animation: online 2s infinite;
	animation: online 2s infinite;
	box-shadow: 0 0 0 rgba(82, 196, 26, 0.4);
	display: block;
}

@-webkit-keyframes online {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(82, 196, 26, 0.4);
	}

	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(82, 196, 26, 0);
	}

	to {
		-webkit-box-shadow: 0 0 0 0 rgba(82, 196, 26, 0);
	}
}

@keyframes online {
	0% {
		box-shadow: 0 0 0 0 rgba(82, 196, 26, 0.4);
	}

	70% {
		box-shadow: 0 0 0 10px rgba(82, 196, 26, 0);
	}

	to {
		box-shadow: 0 0 0 0 rgba(82, 196, 26, 0);
	}
}

.oitis-away-effect {
	box-shadow: 0 0 0 rgba(250, 219, 20, 0.4);
}

.oitis-away-effect,
.oitis-orange-effect {
	-webkit-animation: away 2s infinite;
	animation: away 2s infinite;
	display: block;
}

.oitis-orange-effect {
	box-shadow: 0 0 0 rgba(250, 140, 22, 0.4);
}

@-webkit-keyframes away {
	0% {
		-webkit-box-shadow: 0 0 0 0 rgba(250, 140, 22, 0.4);
	}

	70% {
		-webkit-box-shadow: 0 0 0 10px rgba(250, 140, 22, 0);
	}

	to {
		-webkit-box-shadow: 0 0 0 0 rgba(250, 140, 22, 0);
	}
}

@keyframes away {
	0% {
		box-shadow: 0 0 0 0 rgba(250, 140, 22, 0.4);
	}

	70% {
		box-shadow: 0 0 0 10px rgba(250, 140, 22, 0);
	}

	to {
		box-shadow: 0 0 0 0 rgba(250, 140, 22, 0);
	}
}

@-webkit-keyframes fxicon-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

@keyframes fxicon-spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	to {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg);
	}
}

.fxicon-hc-spin {
	-webkit-animation: fxicon-spin 1.5s linear infinite;
	animation: fxicon-spin 1.5s linear infinite;
}

@-webkit-keyframes fadein {
	0% {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fadein {
	0% {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.fadein {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-name: fadein;
	animation-name: fadein;
}

@-webkit-keyframes fadeout {
	0% {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

@keyframes fadeout {
	0% {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}

.fadeout {
	-webkit-animation-duration: 1s;
	animation-duration: 1s;
	-webkit-animation-name: fadeout;
	animation-name: fadeout;
}

body.framed-layout {
	background-color: #038fde;
	padding: 20px;
}

@media screen and (max-width: 991px) {
	body.framed-layout {
		padding: 0;
	}
}

body.boxed-layout {
	background-color: #038fde;
	box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
	margin: 0 auto;
	max-width: 1280px;
}

@media screen and (min-width: 1600px) {
	body.boxed-layout {
		max-width: 1500px;
	}
}

.oitis-link,
.oitis-pointer {
	cursor: pointer;
}

/* .oitis-link,
.oitis-link:focus,
.oitis-link:hover,
a,
a:focus,
a:hover {
  color: #038fde;
}
a:focus {
  text-decoration: none;
}
img {
  max-width: 100%;
} */
.oitis-w-25 {
	width: 25% !important;
}

.oitis-w-50 {
	width: 50% !important;
}

.oitis-w-75 {
	width: 75% !important;
}

.oitis-w-100 {
	width: 100% !important;
}

.oitis-h-25 {
	height: 25% !important;
}

.oitis-h-50 {
	height: 50% !important;
}

.oitis-h-75 {
	height: 75% !important;
}

.oitis-h-100 {
	height: 100% !important;
}

.oitis-mw-100 {
	max-width: 100% !important;
}

.oitis-mh-100 {
	max-height: 100% !important;
}

.oitis-minw50 {
	min-width: 50px;
}

.oitis-border {
	border: 1px solid #e8e8e8;
}

.oitis-border-2 {
	border: 2px solid #e8e8e8;
}

.oitis-border-top {
	border-top: 1px solid #e8e8e8;
}

.oitis-border-bottom {
	border-bottom: 1px solid #e8e8e8;
}

.oitis-border-bottom-2 {
	border-bottom: 2px solid #e8e8e8;
}

.oitis-border-left {
	border-left: 1px solid #e8e8e8;
}

.oitis-border-right {
	border-right: 1px solid #e8e8e8;
}

.oitis-border-0 {
	border: 0 !important;
}

.oitis-page-heading {
	margin-bottom: 32px;
}

@media screen and (max-width: 575px) {
	.oitis-page-heading {
		margin-bottom: 20px;
	}
}

.oitis-page-title {
	font-size: 20px;
	margin-bottom: 0;
}

.oitis-size-200 {
	height: 200px !important;
	line-height: 200px !important;
	width: 200px !important;
}

.oitis-size-150 {
	height: 150px !important;
	line-height: 150px !important;
	width: 150px !important;
}

.oitis-size-120 {
	height: 120px !important;
	line-height: 120px !important;
	width: 120px !important;
}

.oitis-size-100 {
	height: 100px !important;
	line-height: 100px !important;
	width: 100px !important;
}

.oitis-size-90 {
	height: 90px !important;
	line-height: 90px !important;
	width: 90px !important;
}

.oitis-size-80 {
	height: 80px !important;
	line-height: 80px !important;
	width: 80px !important;
}

.oitis-size-60 {
	height: 60px !important;
	line-height: 60px !important;
	width: 60px !important;
}

.oitis-size-50 {
	height: 50px !important;
	line-height: 50px !important;
	width: 50px !important;
}

.oitis-size-40 {
	height: 40px !important;
	line-height: 40px !important;
	width: 40px !important;
}

.oitis-size-36 {
	height: 36px !important;
	line-height: 36px !important;
	width: 36px !important;
}

.oitis-size-30 {
	height: 30px !important;
	line-height: 30px !important;
	width: 30px !important;
}

.oitis-size-24 {
	height: 24px !important;
	line-height: 24px !important;
	width: 24px !important;
}

.oitis-size-20 {
	height: 20px !important;
	line-height: 20px !important;
	width: 20px !important;
}

.oitis-size-15 {
	height: 15px !important;
	line-height: 14px !important;
	width: 15px !important;
}

.oitis-size-10 {
	height: 10px !important;
	line-height: 10px !important;
	width: 10px !important;
}

.oitis-size-8 {
	height: 8px !important;
	line-height: 8px !important;
	width: 8px !important;
}

.oitis-width-175 {
	width: 175px !important;
}

.oitis-img-fluid,
.oitis-img-thumbnail {
	height: auto;
	max-width: 100%;
}

.oitis-img-fluid-full {
	width: 100%;
}

.oitis-social-link {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	list-style: none;
	margin: 0 -5px 10px;
	padding-left: 0;
}

.oitis-social-link li {
	line-height: 1;
	margin-bottom: 5px;
	padding: 0 5px;
}

.oitis-social-link li .anticon,
.oitis-social-link li .oitis-link,
.oitis-social-link li a,
.oitis-social-link li i {
	border: 1px solid #038fde;
	border-radius: 50%;
	color: #038fde;
	cursor: pointer;
	font-size: 16px;
	height: 30px;
	line-height: 34px;
	text-align: center;
	width: 30px;
}

.oitis-social-link li .anticon:hover,
.oitis-social-link li .oitis-link:focus,
.oitis-social-link li .oitis-link:hover,
.oitis-social-link li a:focus,
.oitis-social-link li a:hover,
.oitis-social-link li i:focus .oitis-social-link li .anticon:focus,
.oitis-social-link li i:hover,
.oitis-social-link li.active .anticon,
.oitis-social-link li.active .oitis-link,
.oitis-social-link li.active a,
.oitis-social-link li.active i {
	background: #038fde;
	color: #fff;
}

.oitis-card-social .oitis-social-link {
	-ms-justify-content: space-around;
	justify-content: space-around;
	margin-bottom: 0;
}

.oitis-float-left {
	float: left !important;
}

.oitis-float-right {
	float: right !important;
}

.oitis-float-none {
	float: none !important;
}

@media (min-width: 576px) {
	.oitis-float-sm-left {
		float: left !important;
	}

	.oitis-float-sm-right {
		float: right !important;
	}

	.oitis-float-sm-none {
		float: none !important;
	}
}

@media (min-width: 768px) {
	.oitis-float-md-left {
		float: left !important;
	}

	.oitis-float-md-right {
		float: right !important;
	}

	.oitis-float-md-none {
		float: none !important;
	}
}

@media (min-width: 992px) {
	.oitis-float-lg-left {
		float: left !important;
	}

	.oitis-float-lg-right {
		float: right !important;
	}

	.oitis-float-lg-none {
		float: none !important;
	}
}

@media (min-width: 1200px) {
	.oitis-float-xl-left {
		float: left !important;
	}

	.oitis-float-xl-right {
		float: right !important;
	}

	.oitis-float-xl-none {
		float: none !important;
	}
}

.oitis-position-static {
	position: static !important;
}

.oitis-position-relative {
	position: relative !important;
}

.oitis-position-absolute {
	position: absolute !important;
}

.oitis-position-fixed {
	position: fixed !important;
}

.oitis-position-sticky {
	position: -webkit-sticky !important;
	position: sticky !important;
}

.oitis-fixed-top {
	top: 0;
}

.oitis-fixed-bottom,
.oitis-fixed-top {
	left: 0;
	position: fixed;
	right: 0;
	z-index: 1030;
}

.oitis-fixed-bottom {
	bottom: 0;
}

.oitis-top-0 {
	top: 0 !important;
}

.oitis-bottom-0 {
	bottom: 0 !important;
}

.oitis-left-0 {
	left: 0 !important;
}

.oitis-right-0 {
	right: 0 !important;
}

.oitis-embed-responsive {
	display: block;
	overflow: hidden;
	padding: 0;
	position: relative;
	width: 100%;
}

.oitis-embed-responsive:before {
	content: '';
	display: block;
}

.oitis-embed-responsive .oitis-embed-responsive-item,
.oitis-embed-responsive embed,
.oitis-embed-responsive iframe,
.oitis-embed-responsive object,
.oitis-embed-responsive video {
	border: 0;
	bottom: 0;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.oitis-embed-responsive-21by9:before {
	padding-top: 42.85714286%;
}

.oitis-embed-responsive-16by9:before {
	padding-top: 56.25%;
}

.oitis-embed-responsive-4by3:before {
	padding-top: 75%;
}

.oitis-embed-responsive-1by1:before {
	padding-top: 100%;
}

.oitis-media {
	-ms-align-items: flex-start;
	align-items: flex-start;
	cursor: pointer;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	position: relative;
}

.oitis-media:hover:after {
	background: #00000005;
	content: ' ';
	height: 100%;
	left: -16px;
	position: absolute;
	top: 0;
	width: calc(100% + 32px);
	z-index: 1;
}

.oitis-media-body {
	flex: 1 1;
}

.oitis-overflow-auto {
	overflow: auto;
}

.oitis-z-index-20 {
	position: relative;
	z-index: 20;
}

hr {
	border-color: hsla(0, 0%, 98%, 0.2);
	margin: 15px 0;
}

.oitis-status-pos {
	position: relative;
}

.oitis-status-pos .oitis-status {
	border-radius: 50%;
	display: block;
	height: 12px;
	left: 0;
	position: absolute;
	top: 2px;
	width: 12px;
	z-index: 1;
}

.oitis-status-pos .oitis-status.oitis-small {
	height: 8px;
	width: 8px;
}

.oitis-status-pos .oitis-status.oitis-status-rtl {
	left: auto;
	right: -4px;
	top: -4px;
}

.oitis-status-pos .oitis-status.oitis-online {
	background-color: #52c41a;
}

.oitis-status-pos .oitis-status.oitis-offline,
.oitis-status-pos .oitis-status.oitis-online {
	-webkit-animation: online 2s infinite;
	animation: online 2s infinite;
	box-shadow: 0 0 0 rgba(82, 196, 26, 0.4);
	display: block;
}

.oitis-status-pos .oitis-status.oitis-offline {
	background-color: #fff;
	border: 1px solid #52c41a;
}

.oitis-status-pos .oitis-status.oitis-away {
	background-color: #fadb14;
	box-shadow: 0 0 0 rgba(250, 219, 20, 0.4);
}

.oitis-status-pos .oitis-status.oitis-away,
.oitis-status-pos .oitis-status.oitis-orange {
	-webkit-animation: away 2s infinite;
	animation: away 2s infinite;
	display: block;
}

.oitis-status-pos .oitis-status.oitis-orange {
	background-color: #fa8c16;
	box-shadow: 0 0 0 rgba(250, 140, 22, 0.4);
}

.oitis-box-shadow {
	box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
}

.oitis-no-box-shadow {
	box-shadow: none;
}

.oitis-rounded-xs {
	border-radius: 2px !important;
}

.oitis-rounded-sm {
	border-radius: 4px !important;
}

.oitis-rounded-base {
	border-radius: 6px !important;
}

.oitis-rounded-lg {
	border-radius: 10px !important;
}

.oitis-rounded-xxl {
	border-radius: 30px !important;
}

.oitis-rounded-circle {
	border-radius: 50% !important;
}

.oitis-border-radius-top-left-0 {
	-moz-border-top-left-radius: 0;
	border-top-left-radius: 0;
}

.oitis-border-radius-top-right-0 {
	-moz-border-top-right-radius: 0;
	border-top-right-radius: 0;
}

.oitis-border-radius-bottom-left-0 {
	-moz-border-bottom-left-radius: 0;
	border-bottom-left-radius: 0;
}

.oitis-border-radius-bottom-right-0 {
	-moz-border-bottom-right-radius: 0;
	border-bottom-right-radius: 0;
}

.oitis-vertical-align-top {
	vertical-align: top;
}

.oitis-vertical-align-middle {
	vertical-align: middle;
}

.oitis-vertical-align-bottom {
	vertical-align: bottom;
}

.oitis-separator {
	background-color: #038fde;
	height: 2px;
	margin: 0 auto 10px;
	width: 20px;
}

.oitis-loader-400 {
	height: 400px;
}

@media screen and (max-width: 1199px) {
	.oitis-loader-400 {
		height: 300px;
	}
}

.oitis-object-cover {
	object-fit: cover;
}

.cke_editable,
.cke_editor_editor2 {
	font-family: NoirPro, sans-serif !important;
}

.oitis-top2 {
	position: relative;
	top: 2px;
}

.ant-collapse-content-box> :last-child {
	margin-bottom: 0;
}

.react-stickies-wrapper {
	padding-bottom: 16px;
}

@media screen and (max-width: 1366px) {
	.oitis-hide-1366 {
		display: none;
	}
}

.oitis-d-flex {
	display: flex !important;
}

.oitis-d-none {
	display: none !important;
}

.oitis-d-inline-block {
	display: inline-block !important;
}

.oitis-d-block {
	display: block !important;
}

.oitis-d-inline-flex {
	display: inline-flex !important;
}

.oitis-flex-1 {
	flex: 1 1 auto;
}

.oitis-flex-row {
	flex-direction: row !important;
	flex-wrap: wrap !important;
}

.oitis-flex-column,
.oitis-flex-row {
	display: -ms-flex !important;
	display: flex !important;
}

.oitis-flex-column {
	flex-direction: column !important;
}

.oitis-flex-column,
.oitis-flex-nowrap {
	flex-wrap: nowrap !important;
}

.oitis-flex-row-reverse {
	flex-direction: row-reverse !important;
}

.oitis-flex-column-reverse {
	flex-direction: column-reverse !important;
}

.oitis-justify-content-start {
	-ms-justify-content: flex-start !important;
	justify-content: flex-start !important;
}

.oitis-justify-content-end {
	-ms-justify-content: flex-end !important;
	justify-content: flex-end !important;
}

.oitis-justify-content-center {
	-ms-justify-content: center !important;
	justify-content: center !important;
}

.oitis-justify-content-between {
	-ms-justify-content: space-between !important;
	justify-content: space-between !important;
}

.oitis-justify-content-around {
	-ms-justify-content: space-around !important;
	justify-content: space-around !important;
}

.oitis-align-items-start {
	-ms-align-items: flex-start !important;
	align-items: flex-start !important;
}

.oitis-align-items-end {
	-ms-align-items: flex-end !important;
	align-items: flex-end !important;
}

.oitis-align-items-center {
	-ms-align-items: center !important;
	align-items: center !important;
}

.oitis-align-items-stretch {
	-ms-align-items: stretch !important;
	align-items: stretch !important;
}

.oitis-align-content-start {
	-ms-align-content: flex-start !important;
	align-content: flex-start !important;
}

.oitis-align-content-end {
	-ms-align-content: flex-end !important;
	align-content: flex-end !important;
}

.oitis-align-content-center {
	-ms-align-content: center !important;
	align-content: center !important;
}

.oitis-align-content-between {
	-ms-align-content: space-between !important;
	align-content: space-between !important;
}

.oitis-align-content-around {
	-ms-align-content: space-around !important;
	align-content: space-around !important;
}

.oitis-align-content-stretch {
	-ms-align-content: stretch !important;
	align-content: stretch !important;
}

.oitis-align-self-auto {
	-ms-align-self: auto !important;
	align-self: auto !important;
}

.oitis-align-self-start {
	-ms-align-self: flex-start !important;
	align-self: flex-start !important;
}

.oitis-align-self-end {
	-ms-align-self: flex-end !important;
	align-self: flex-end !important;
}

.oitis-align-self-center {
	-ms-align-self: center !important;
	align-self: center !important;
}

.oitis-align-self-baseline {
	-ms-align-self: baseline !important;
	align-self: baseline !important;
}

.oitis-align-self-stretch {
	-ms-align-self: stretch !important;
	align-self: stretch !important;
}

.oitis-order-first {
	order: -1;
}

.oitis-order-1 {
	order: 1;
}

.oitis-order-2 {
	order: 2;
}

.oitis-order-3 {
	order: 3;
}

.oitis-order-4 {
	order: 4;
}

.oitis-order-5 {
	order: 5;
}

.oitis-order-6 {
	order: 6;
}

.oitis-order-7 {
	order: 7;
}

.oitis-order-8 {
	order: 8;
}

.oitis-order-9 {
	order: 9;
}

.oitis-order-10 {
	order: 10;
}

.oitis-order-11 {
	order: 11;
}

.oitis-order-12 {
	order: 12;
}

.oitis-offset-1 {
	margin-left: 8.333333%;
}

.oitis-offset-2 {
	margin-left: 16.666667%;
}

.oitis-offset-3 {
	margin-left: 25%;
}

.oitis-offset-4 {
	margin-left: 33.333333%;
}

.oitis-offset-5 {
	margin-left: 41.666667%;
}

.oitis-offset-6 {
	margin-left: 50%;
}

.oitis-offset-7 {
	margin-left: 58.333333%;
}

.oitis-offset-8 {
	margin-left: 66.666667%;
}

.oitis-offset-9 {
	margin-left: 75%;
}

.oitis-offset-10 {
	margin-left: 83.333333%;
}

.oitis-offset-11 {
	margin-left: 91.666667%;
}

@media screen and (min-width: 576px) {
	.oitis-d-sm-none {
		display: none !important;
	}

	.oitis-d-sm-inline-block {
		display: inline-block !important;
	}

	.oitis-d-sm-block {
		display: block !important;
	}

	.oitis-d-sm-flex {
		display: flex !important;
	}

	.oitis-d-sm-inline-flex {
		display: inline-flex !important;
	}

	.oitis-flex-sm-row {
		flex-direction: row !important;
	}

	.oitis-flex-sm-column {
		flex-direction: column !important;
	}

	.oitis-flex-sm-row-reverse {
		flex-direction: row-reverse !important;
	}

	.oitis-flex-sm-column-reverse {
		flex-direction: column-reverse !important;
	}

	.oitis-flex-sm-wrap {
		flex-wrap: wrap !important;
	}

	.oitis-flex-sm-nowrap {
		flex-wrap: nowrap !important;
	}

	.oitis-flex-sm-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}

	.oitis-justify-content-sm-start {
		justify-content: flex-start !important;
	}

	.oitis-justify-content-sm-end {
		justify-content: flex-end !important;
	}

	.oitis-justify-content-sm-center {
		justify-content: center !important;
	}

	.oitis-justify-content-sm-between {
		justify-content: space-between !important;
	}

	.oitis-justify-content-sm-around {
		justify-content: space-around !important;
	}

	.oitis-align-items-sm-start {
		align-items: flex-start !important;
	}

	.oitis-align-items-sm-end {
		align-items: flex-end !important;
	}

	.oitis-align-items-sm-center {
		align-items: center !important;
	}

	.oitis-align-items-sm-baseline {
		align-items: baseline !important;
	}

	.oitis-align-items-sm-stretch {
		align-items: stretch !important;
	}

	.oitis-align-content-sm-start {
		align-content: flex-start !important;
	}

	.oitis-align-content-sm-end {
		align-content: flex-end !important;
	}

	.oitis-align-content-sm-center {
		align-content: center !important;
	}

	.oitis-align-content-sm-between {
		align-content: space-between !important;
	}

	.oitis-align-content-sm-around {
		align-content: space-around !important;
	}

	.oitis-align-content-sm-stretch {
		align-content: stretch !important;
	}

	.oitis-align-self-sm-auto {
		align-self: auto !important;
	}

	.oitis-align-self-sm-start {
		align-self: flex-start !important;
	}

	.oitis-align-self-sm-end {
		align-self: flex-end !important;
	}

	.oitis-align-self-sm-center {
		align-self: center !important;
	}

	.oitis-align-self-sm-baseline {
		align-self: baseline !important;
	}

	.oitis-align-self-sm-stretch {
		align-self: stretch !important;
	}

	.oitis-order-sm-first {
		order: -1;
	}

	.oitis-order-sm-1 {
		order: 1;
	}

	.oitis-order-sm-2 {
		order: 2;
	}

	.oitis-order-sm-3 {
		order: 3;
	}

	.oitis-order-sm-4 {
		order: 4;
	}

	.oitis-order-sm-5 {
		order: 5;
	}

	.oitis-order-sm-6 {
		order: 6;
	}

	.oitis-order-sm-7 {
		order: 7;
	}

	.oitis-order-sm-8 {
		order: 8;
	}

	.oitis-order-sm-9 {
		order: 9;
	}

	.oitis-order-sm-10 {
		order: 10;
	}

	.oitis-order-sm-11 {
		order: 11;
	}

	.oitis-order-sm-12 {
		order: 12;
	}

	.oitis-offset-sm-0 {
		margin-left: 0;
	}

	.oitis-offset-sm-1 {
		margin-left: 8.333333%;
	}

	.oitis-offset-sm-2 {
		margin-left: 16.666667%;
	}

	.oitis-offset-sm-3 {
		margin-left: 25%;
	}

	.oitis-offset-sm-4 {
		margin-left: 33.333333%;
	}

	.oitis-offset-sm-5 {
		margin-left: 41.666667%;
	}

	.oitis-offset-sm-6 {
		margin-left: 50%;
	}

	.oitis-offset-sm-7 {
		margin-left: 58.333333%;
	}

	.oitis-offset-sm-8 {
		margin-left: 66.666667%;
	}

	.oitis-offset-sm-9 {
		margin-left: 75%;
	}

	.oitis-offset-sm-10 {
		margin-left: 83.333333%;
	}

	.oitis-offset-sm-11 {
		margin-left: 91.666667%;
	}
}

@media screen and (min-width: 768px) {
	.oitis-d-md-none {
		display: none !important;
	}

	.oitis-d-md-inline-block {
		display: inline-block !important;
	}

	.oitis-d-md-block {
		display: block !important;
	}

	.oitis-d-md-flex {
		display: flex !important;
	}

	.oitis-d-md-inline-flex {
		display: inline-flex !important;
	}

	.oitis-flex-md-row {
		flex-direction: row !important;
	}

	.oitis-flex-md-column {
		flex-direction: column !important;
	}

	.oitis-flex-md-row-reverse {
		flex-direction: row-reverse !important;
	}

	.oitis-flex-md-column-reverse {
		flex-direction: column-reverse !important;
	}

	.oitis-flex-md-wrap {
		flex-wrap: wrap !important;
	}

	.oitis-flex-md-nowrap {
		flex-wrap: nowrap !important;
	}

	.oitis-flex-md-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}

	.oitis-justify-content-md-start {
		justify-content: flex-start !important;
	}

	.oitis-justify-content-md-end {
		justify-content: flex-end !important;
	}

	.oitis-justify-content-md-center {
		justify-content: center !important;
	}

	.oitis-justify-content-md-between {
		justify-content: space-between !important;
	}

	.oitis-justify-content-md-around {
		justify-content: space-around !important;
	}

	.oitis-align-items-md-start {
		align-items: flex-start !important;
	}

	.oitis-align-items-md-end {
		align-items: flex-end !important;
	}

	.oitis-align-items-md-center {
		align-items: center !important;
	}

	.oitis-align-items-md-baseline {
		align-items: baseline !important;
	}

	.oitis-align-items-md-stretch {
		align-items: stretch !important;
	}

	.oitis-align-content-md-start {
		align-content: flex-start !important;
	}

	.oitis-align-content-md-end {
		align-content: flex-end !important;
	}

	.oitis-align-content-md-center {
		align-content: center !important;
	}

	.oitis-align-content-md-between {
		align-content: space-between !important;
	}

	.oitis-align-content-md-around {
		align-content: space-around !important;
	}

	.oitis-align-content-md-stretch {
		align-content: stretch !important;
	}

	.oitis-align-self-md-auto {
		align-self: auto !important;
	}

	.oitis-align-self-md-start {
		align-self: flex-start !important;
	}

	.oitis-align-self-md-end {
		align-self: flex-end !important;
	}

	.oitis-align-self-md-center {
		align-self: center !important;
	}

	.oitis-align-self-md-baseline {
		align-self: baseline !important;
	}

	.oitis-align-self-md-stretch {
		align-self: stretch !important;
	}

	.oitis-order-md-first {
		order: -1;
	}

	.oitis-order-md-1 {
		order: 1;
	}

	.oitis-order-md-2 {
		order: 2;
	}

	.oitis-order-md-3 {
		order: 3;
	}

	.oitis-order-md-4 {
		order: 4;
	}

	.oitis-order-md-5 {
		order: 5;
	}

	.oitis-order-md-6 {
		order: 6;
	}

	.oitis-order-md-7 {
		order: 7;
	}

	.oitis-order-md-8 {
		order: 8;
	}

	.oitis-order-md-9 {
		order: 9;
	}

	.oitis-order-md-10 {
		order: 10;
	}

	.oitis-order-md-11 {
		order: 11;
	}

	.oitis-order-md-12 {
		order: 12;
	}

	.oitis-offset-md-0 {
		margin-left: 0;
	}

	.oitis-offset-md-1 {
		margin-left: 8.333333%;
	}

	.oitis-offset-md-2 {
		margin-left: 16.666667%;
	}

	.oitis-offset-md-3 {
		margin-left: 25%;
	}

	.oitis-offset-md-4 {
		margin-left: 33.333333%;
	}

	.oitis-offset-md-5 {
		margin-left: 41.666667%;
	}

	.oitis-offset-md-6 {
		margin-left: 50%;
	}

	.oitis-offset-md-7 {
		margin-left: 58.333333%;
	}

	.oitis-offset-md-8 {
		margin-left: 66.666667%;
	}

	.oitis-offset-md-9 {
		margin-left: 75%;
	}

	.oitis-offset-md-10 {
		margin-left: 83.333333%;
	}

	.oitis-offset-md-11 {
		margin-left: 91.666667%;
	}
}

@media screen and (min-width: 992px) {
	.oitis-d-lg-none {
		display: none !important;
	}

	.oitis-d-lg-inline-block {
		display: inline-block !important;
	}

	.oitis-d-lg-block {
		display: block !important;
	}

	.oitis-d-lg-flex {
		display: flex !important;
	}

	.oitis-d-lg-inline-flex {
		display: inline-flex !important;
	}

	.oitis-flex-lg-row {
		flex-direction: row !important;
	}

	.oitis-flex-lg-column {
		flex-direction: column !important;
	}

	.oitis-flex-lg-row-reverse {
		flex-direction: row-reverse !important;
	}

	.oitis-flex-lg-column-reverse {
		flex-direction: column-reverse !important;
	}

	.oitis-flex-lg-wrap {
		flex-wrap: wrap !important;
	}

	.oitis-flex-lg-nowrap {
		flex-wrap: nowrap !important;
	}

	.oitis-flex-lg-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}

	.oitis-justify-content-lg-start {
		justify-content: flex-start !important;
	}

	.oitis-justify-content-lg-end {
		justify-content: flex-end !important;
	}

	.oitis-justify-content-lg-center {
		justify-content: center !important;
	}

	.oitis-justify-content-lg-between {
		justify-content: space-between !important;
	}

	.oitis-justify-content-lg-around {
		justify-content: space-around !important;
	}

	.oitis-align-items-lg-start {
		align-items: flex-start !important;
	}

	.oitis-align-items-lg-end {
		align-items: flex-end !important;
	}

	.oitis-align-items-lg-center {
		align-items: center !important;
	}

	.oitis-align-items-lg-baseline {
		align-items: baseline !important;
	}

	.oitis-align-items-lg-stretch {
		align-items: stretch !important;
	}

	.oitis-align-content-lg-start {
		align-content: flex-start !important;
	}

	.oitis-align-content-lg-end {
		align-content: flex-end !important;
	}

	.oitis-align-content-lg-center {
		align-content: center !important;
	}

	.oitis-align-content-lg-between {
		align-content: space-between !important;
	}

	.oitis-align-content-lg-around {
		align-content: space-around !important;
	}

	.oitis-align-content-lg-stretch {
		align-content: stretch !important;
	}

	.oitis-align-self-lg-auto {
		align-self: auto !important;
	}

	.oitis-align-self-lg-start {
		align-self: flex-start !important;
	}

	.oitis-align-self-lg-end {
		align-self: flex-end !important;
	}

	.oitis-align-self-lg-center {
		align-self: center !important;
	}

	.oitis-align-self-lg-baseline {
		align-self: baseline !important;
	}

	.oitis-align-self-lg-stretch {
		align-self: stretch !important;
	}

	.oitis-order-lg-first {
		order: -1;
	}

	.oitis-order-lg-1 {
		order: 1;
	}

	.oitis-order-lg-2 {
		order: 2;
	}

	.oitis-order-lg-3 {
		order: 3;
	}

	.oitis-order-lg-4 {
		order: 4;
	}

	.oitis-order-lg-5 {
		order: 5;
	}

	.oitis-order-lg-6 {
		order: 6;
	}

	.oitis-order-lg-7 {
		order: 7;
	}

	.oitis-order-lg-8 {
		order: 8;
	}

	.oitis-order-lg-9 {
		order: 9;
	}

	.oitis-order-lg-10 {
		order: 10;
	}

	.oitis-order-lg-11 {
		order: 11;
	}

	.oitis-order-lg-12 {
		order: 12;
	}

	.oitis-offset-lg-0 {
		margin-left: 0;
	}

	.oitis-offset-lg-1 {
		margin-left: 8.333333%;
	}

	.oitis-offset-lg-2 {
		margin-left: 16.666667%;
	}

	.oitis-offset-lg-3 {
		margin-left: 25%;
	}

	.oitis-offset-lg-4 {
		margin-left: 33.333333%;
	}

	.oitis-offset-lg-5 {
		margin-left: 41.666667%;
	}

	.oitis-offset-lg-6 {
		margin-left: 50%;
	}

	.oitis-offset-lg-7 {
		margin-left: 58.333333%;
	}

	.oitis-offset-lg-8 {
		margin-left: 66.666667%;
	}

	.oitis-offset-lg-9 {
		margin-left: 75%;
	}

	.oitis-offset-lg-10 {
		margin-left: 83.333333%;
	}

	.oitis-offset-lg-11 {
		margin-left: 91.666667%;
	}
}

@media screen and (min-width: 1200px) {
	.oitis-d-xl-none {
		display: none !important;
	}

	.oitis-d-xl-inline-block {
		display: inline-block !important;
	}

	.oitis-d-xl-block {
		display: block !important;
	}

	.oitis-d-xl-flex {
		display: flex !important;
	}

	.oitis-d-xl-inline-flex {
		display: inline-flex !important;
	}

	.oitis-flex-xl-row {
		flex-direction: row !important;
	}

	.oitis-flex-xl-column {
		flex-direction: column !important;
	}

	.oitis-flex-xl-row-reverse {
		flex-direction: row-reverse !important;
	}

	.oitis-flex-xl-column-reverse {
		flex-direction: column-reverse !important;
	}

	.oitis-flex-xl-wrap {
		flex-wrap: wrap !important;
	}

	.oitis-flex-xl-nowrap {
		flex-wrap: nowrap !important;
	}

	.oitis-flex-xl-wrap-reverse {
		flex-wrap: wrap-reverse !important;
	}

	.oitis-justify-content-xl-start {
		justify-content: flex-start !important;
	}

	.oitis-justify-content-xl-end {
		justify-content: flex-end !important;
	}

	.oitis-justify-content-xl-center {
		justify-content: center !important;
	}

	.justify-content-xl-between {
		justify-content: space-between !important;
	}

	.oitis-justify-content-xl-around {
		justify-content: space-around !important;
	}

	.oitis-align-items-xl-start {
		align-items: flex-start !important;
	}

	.oitis-align-items-xl-end {
		align-items: flex-end !important;
	}

	.oitis-align-items-xl-center {
		align-items: center !important;
	}

	.oitis-align-items-xl-baseline {
		align-items: baseline !important;
	}

	.oitis-align-items-xl-stretch {
		align-items: stretch !important;
	}

	.oitis-align-content-xl-start {
		align-content: flex-start !important;
	}

	.oitis-align-content-xl-end {
		align-content: flex-end !important;
	}

	.oitis-align-content-xl-center {
		align-content: center !important;
	}

	.oitis-align-content-xl-between {
		align-content: space-between !important;
	}

	.oitis-align-content-xl-around {
		align-content: space-around !important;
	}

	.oitis-align-content-xl-stretch {
		align-content: stretch !important;
	}

	.oitis-align-self-xl-auto {
		align-self: auto !important;
	}

	.oitis-align-self-xl-start {
		align-self: flex-start !important;
	}

	.oitis-align-self-xl-end {
		align-self: flex-end !important;
	}

	.oitis-align-self-xl-center {
		align-self: center !important;
	}

	.oitis-align-self-xl-baseline {
		align-self: baseline !important;
	}

	.oitis-align-self-xl-stretch {
		align-self: stretch !important;
	}

	.oitis-order-xl-first {
		order: -1;
	}

	.oitis-order-xl-1 {
		order: 1;
	}

	.oitis-order-xl-2 {
		order: 2;
	}

	.oitis-order-xl-3 {
		order: 3;
	}

	.oitis-order-xl-4 {
		order: 4;
	}

	.oitis-order-xl-5 {
		order: 5;
	}

	.oitis-order-xl-6 {
		order: 6;
	}

	.oitis-order-xl-7 {
		order: 7;
	}

	.oitis-order-xl-8 {
		order: 8;
	}

	.oitis-order-xl-9 {
		order: 9;
	}

	.oitis-order-xl-10 {
		order: 10;
	}

	.oitis-order-xl-11 {
		order: 11;
	}

	.oitis-order-xl-12 {
		order: 12;
	}

	.oitis-offset-xl-0 {
		margin-left: 0;
	}

	.oitis-offset-xl-1 {
		margin-left: 8.333333%;
	}

	.oitis-offset-xl-2 {
		margin-left: 16.666667%;
	}

	.oitis-offset-xl-3 {
		margin-left: 25%;
	}

	.oitis-offset-xl-4 {
		margin-left: 33.333333%;
	}

	.oitis-offset-xl-5 {
		margin-left: 41.666667%;
	}

	.oitis-offset-xl-6 {
		margin-left: 50%;
	}

	.oitis-offset-xl-7 {
		margin-left: 58.333333%;
	}

	.oitis-offset-xl-8 {
		margin-left: 66.666667%;
	}

	.oitis-offset-xl-9 {
		margin-left: 75%;
	}

	.oitis-offset-xl-10 {
		margin-left: 83.333333%;
	}

	.oitis-offset-xl-11 {
		margin-left: 91.666667%;
	}
}

.ant-row {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-left: -16px;
	margin-right: -16px;
}

.ant-row>[class*='ant-col-'] {
	margin: 0 -0.3px;
}

.ant-col-1,
.ant-col-10,
.ant-col-11,
.ant-col-12,
.ant-col-13,
.ant-col-14,
.ant-col-15,
.ant-col-16,
.ant-col-17,
.ant-col-18,
.ant-col-19,
.ant-col-2,
.ant-col-20,
.ant-col-21,
.ant-col-22,
.ant-col-23,
.ant-col-24,
.ant-col-3,
.ant-col-4,
.ant-col-5,
.ant-col-6,
.ant-col-7,
.ant-col-8,
.ant-col-9,
.ant-col-lg-1,
.ant-col-lg-10,
.ant-col-lg-11,
.ant-col-lg-12,
.ant-col-lg-13,
.ant-col-lg-14,
.ant-col-lg-15,
.ant-col-lg-16,
.ant-col-lg-17,
.ant-col-lg-18,
.ant-col-lg-19,
.ant-col-lg-2,
.ant-col-lg-20,
.ant-col-lg-21,
.ant-col-lg-22,
.ant-col-lg-23,
.ant-col-lg-24,
.ant-col-lg-3,
.ant-col-lg-4,
.ant-col-lg-5,
.ant-col-lg-6,
.ant-col-lg-7,
.ant-col-lg-8,
.ant-col-lg-9,
.ant-col-md-1,
.ant-col-md-10,
.ant-col-md-11,
.ant-col-md-12,
.ant-col-md-13,
.ant-col-md-14,
.ant-col-md-15,
.ant-col-md-16,
.ant-col-md-17,
.ant-col-md-18,
.ant-col-md-19,
.ant-col-md-2,
.ant-col-md-20,
.ant-col-md-21,
.ant-col-md-22,
.ant-col-md-23,
.ant-col-md-24,
.ant-col-md-3,
.ant-col-md-4,
.ant-col-md-5,
.ant-col-md-6,
.ant-col-md-7,
.ant-col-md-8,
.ant-col-md-9,
.ant-col-sm-1,
.ant-col-sm-10,
.ant-col-sm-11,
.ant-col-sm-12,
.ant-col-sm-13,
.ant-col-sm-14,
.ant-col-sm-15,
.ant-col-sm-16,
.ant-col-sm-17,
.ant-col-sm-18,
.ant-col-sm-19,
.ant-col-sm-2,
.ant-col-sm-20,
.ant-col-sm-21,
.ant-col-sm-22,
.ant-col-sm-23,
.ant-col-sm-24,
.ant-col-sm-3,
.ant-col-sm-4,
.ant-col-sm-5,
.ant-col-sm-6,
.ant-col-sm-7,
.ant-col-sm-8,
.ant-col-sm-9,
.ant-col-xs-1,
.ant-col-xs-10,
.ant-col-xs-11,
.ant-col-xs-12,
.ant-col-xs-13,
.ant-col-xs-14,
.ant-col-xs-15,
.ant-col-xs-16,
.ant-col-xs-17,
.ant-col-xs-18,
.ant-col-xs-19,
.ant-col-xs-2,
.ant-col-xs-20,
.ant-col-xs-21,
.ant-col-xs-22,
.ant-col-xs-23,
.ant-col-xs-24,
.ant-col-xs-3,
.ant-col-xs-4,
.ant-col-xs-5,
.ant-col-xs-6,
.ant-col-xs-7,
.ant-col-xs-8,
.ant-col-xs-9 {
	float: none !important;
	padding-left: 16px;
	padding-right: 16px;
}

.oitis-col {
	flex-basis: 0;
	flex-grow: 1;
	max-width: 100%;
	padding-left: 16px;
	padding-right: 16px;
}

@media screen and (max-width: 480px) {
	.oitis-col-full {
		width: 100%;
	}
}

.ant-row-flex {
	display: flex;
	flex-flow: row wrap;
}

.oitis-mb-30 {
	margin-bottom: 30px !important;
}

.oitis-mb-10 {
	margin-bottom: 10px !important;
}

.oitis-m-0 {
	margin: 0 !important;
}

.oitis-mt-0,
.oitis-my-0 {
	margin-top: 0 !important;
}

.oitis-mr-0,
.oitis-mx-0 {
	margin-right: 0 !important;
}

.oitis-mb-0,
.oitis-my-0 {
	margin-bottom: 0 !important;
}

.oitis-ml-0,
.oitis-mx-0 {
	margin-left: 0 !important;
}

.oitis-m-1 {
	margin: 0.25rem !important;
}

.oitis-mt-1,
.oitis-my-1 {
	margin-top: 0.25rem !important;
}

.oitis-mr-1,
.oitis-mx-1 {
	margin-right: 0.25rem !important;
}

.oitis-mb-1,
.oitis-my-1 {
	margin-bottom: 0.25rem !important;
}

.oitis-ml-1,
.oitis-mx-1 {
	margin-left: 0.25rem !important;
}

.oitis-m-2 {
	margin: 0.5rem !important;
}

.oitis-mt-2,
.oitis-my-2 {
	margin-top: 0.5rem !important;
}

.oitis-mr-2,
.oitis-mx-2 {
	margin-right: 0.5rem !important;
}

.oitis-mb-2,
.oitis-my-2 {
	margin-bottom: 0.5rem !important;
}

.oitis-ml-2,
.oitis-mx-2 {
	margin-left: 0.5rem !important;
}

.oitis-m-3 {
	margin: 1rem !important;
}

.oitis-mt-3,
.oitis-my-3 {
	margin-top: 1rem !important;
}

.oitis-mr-3,
.oitis-mx-3 {
	margin-right: 1rem !important;
}

.oitis-mb-3,
.oitis-my-3 {
	margin-bottom: 1rem !important;
}

.oitis-ml-3,
.oitis-mx-3 {
	margin-left: 1rem !important;
}

.oitis-m-4 {
	margin: 1.5rem !important;
}

.oitis-mt-4,
.oitis-my-4 {
	margin-top: 1.5rem !important;
}

.oitis-mr-4,
.oitis-mx-4 {
	margin-right: 1.5rem !important;
}

.oitis-mb-4,
.oitis-my-4 {
	margin-bottom: 1.5rem !important;
}

.oitis-ml-4,
.oitis-mx-4 {
	margin-left: 1.5rem !important;
}

.oitis-m-5 {
	margin: 3rem !important;
}

.oitis-mt-5,
.oitis-my-5 {
	margin-top: 3rem !important;
}

.oitis-mr-5,
.oitis-mx-5 {
	margin-right: 3rem !important;
}

.oitis-mb-5,
.oitis-my-5 {
	margin-bottom: 3rem !important;
}

.oitis-ml-5,
.oitis-mx-5 {
	margin-left: 3rem !important;
}

.oitis-p-0 {
	padding: 0 !important;
}

.oitis-pt-0,
.oitis-py-0 {
	padding-top: 0 !important;
}

.oitis-pr-0,
.oitis-px-0 {
	padding-right: 0 !important;
}

.oitis-pb-0,
.oitis-py-0 {
	padding-bottom: 0 !important;
}

.oitis-pl-0,
.oitis-px-0 {
	padding-left: 0 !important;
}

.oitis-p-1 {
	padding: 0.25rem !important;
}

.oitis-pt-1,
.oitis-py-1 {
	padding-top: 0.25rem !important;
}

.oitis-pr-1,
.oitis-px-1 {
	padding-right: 0.25rem !important;
}

.oitis-pb-1,
.oitis-py-1 {
	padding-bottom: 0.25rem !important;
}

.oitis-pl-1,
.oitis-px-1 {
	padding-left: 0.25rem !important;
}

.oitis-p-2 {
	padding: 0.5rem !important;
}

.oitis-pt-2,
.oitis-py-2 {
	padding-top: 0.5rem !important;
}

.oitis-pr-2,
.oitis-px-2 {
	padding-right: 0.5rem !important;
}

.oitis-pb-2,
.oitis-py-2 {
	padding-bottom: 0.5rem !important;
}

.oitis-pl-2,
.oitis-px-2 {
	padding-left: 0.5rem !important;
}

.oitis-p-3 {
	padding: 1rem !important;
}

.oitis-pt-3,
.oitis-py-3 {
	padding-top: 1rem !important;
}

.oitis-pr-3,
.oitis-px-3 {
	padding-right: 1rem !important;
}

.oitis-pb-3,
.oitis-py-3 {
	padding-bottom: 1rem !important;
}

.oitis-pl-3,
.oitis-px-3 {
	padding-left: 1rem !important;
}

.oitis-p-4 {
	padding: 1.5rem !important;
}

.oitis-pt-4,
.oitis-py-4 {
	padding-top: 1.5rem !important;
}

.oitis-pr-4,
.oitis-px-4 {
	padding-right: 1.5rem !important;
}

.oitis-pb-4,
.oitis-py-4 {
	padding-bottom: 1.5rem !important;
}

.oitis-pl-4,
.oitis-px-4 {
	padding-left: 1.5rem !important;
}

.oitis-p-5 {
	padding: 2rem !important;
}

.oitis-pt-5,
.oitis-py-5 {
	padding-top: 2rem !important;
}

.oitis-pr-5,
.oitis-px-5 {
	padding-right: 2rem !important;
}

.oitis-pb-5,
.oitis-py-5 {
	padding-bottom: 2rem !important;
}

.oitis-pl-5,
.oitis-px-5 {
	padding-left: 2rem !important;
}

.oitis-m-auto {
	margin: auto !important;
}

.oitis-mt-auto,
.oitis-my-auto {
	margin-top: auto !important;
}

.oitis-mr-auto,
.oitis-mx-auto {
	margin-right: auto !important;
}

.oitis-mb-auto,
.oitis-my-auto {
	margin-bottom: auto !important;
}

.oitis-ml-auto,
.oitis-mx-auto {
	margin-left: auto !important;
}

@media (min-width: 576px) {
	.oitis-m-sm-0 {
		margin: 0 !important;
	}

	.oitis-mt-sm-0,
	.oitis-my-sm-0 {
		margin-top: 0 !important;
	}

	.oitis-mr-sm-0,
	.oitis-mx-sm-0 {
		margin-right: 0 !important;
	}

	.oitis-mb-sm-0,
	.oitis-my-sm-0 {
		margin-bottom: 0 !important;
	}

	.oitis-ml-sm-0,
	.oitis-mx-sm-0 {
		margin-left: 0 !important;
	}

	.oitis-m-sm-1 {
		margin: 0.25rem !important;
	}

	.oitis-mt-sm-1,
	.oitis-my-sm-1 {
		margin-top: 0.25rem !important;
	}

	.oitis-mr-sm-1,
	.oitis-mx-sm-1 {
		margin-right: 0.25rem !important;
	}

	.oitis-mb-sm-1,
	.oitis-my-sm-1 {
		margin-bottom: 0.25rem !important;
	}

	.oitis-ml-sm-1,
	.oitis-mx-sm-1 {
		margin-left: 0.25rem !important;
	}

	.oitis-m-sm-2 {
		margin: 0.5rem !important;
	}

	.oitis-mt-sm-2,
	.oitis-my-sm-2 {
		margin-top: 0.5rem !important;
	}

	.oitis-mr-sm-2,
	.oitis-mx-sm-2 {
		margin-right: 0.5rem !important;
	}

	.oitis-mb-sm-2,
	.oitis-my-sm-2 {
		margin-bottom: 0.5rem !important;
	}

	.oitis-ml-sm-2,
	.oitis-mx-sm-2 {
		margin-left: 0.5rem !important;
	}

	.oitis-m-sm-3 {
		margin: 1rem !important;
	}

	.oitis-mt-sm-3,
	.oitis-my-sm-3 {
		margin-top: 1rem !important;
	}

	.oitis-mr-sm-3,
	.oitis-mx-sm-3 {
		margin-right: 1rem !important;
	}

	.oitis-mb-sm-3,
	.oitis-my-sm-3 {
		margin-bottom: 1rem !important;
	}

	.oitis-ml-sm-3,
	.oitis-mx-sm-3 {
		margin-left: 1rem !important;
	}

	.oitis-m-sm-4 {
		margin: 1.5rem !important;
	}

	.oitis-mt-sm-4,
	.oitis-my-sm-4 {
		margin-top: 1.5rem !important;
	}

	.oitis-mr-sm-4,
	.oitis-mx-sm-4 {
		margin-right: 1.5rem !important;
	}

	.oitis-mb-sm-4,
	.oitis-my-sm-4 {
		margin-bottom: 1.5rem !important;
	}

	.oitis-ml-sm-4,
	.oitis-mx-sm-4 {
		margin-left: 1.5rem !important;
	}

	.oitis-m-sm-5 {
		margin: 3rem !important;
	}

	.oitis-mt-sm-5,
	.oitis-my-sm-5 {
		margin-top: 3rem !important;
	}

	.oitis-mr-sm-5,
	.oitis-mx-sm-5 {
		margin-right: 3rem !important;
	}

	.oitis-mb-sm-5,
	.oitis-my-sm-5 {
		margin-bottom: 3rem !important;
	}

	.oitis-ml-sm-5,
	.oitis-mx-sm-5 {
		margin-left: 3rem !important;
	}

	.oitis-p-sm-0 {
		padding: 0 !important;
	}

	.oitis-pt-sm-0,
	.oitis-py-sm-0 {
		padding-top: 0 !important;
	}

	.oitis-pr-sm-0,
	.oitis-px-sm-0 {
		padding-right: 0 !important;
	}

	.oitis-pb-sm-0,
	.oitis-py-sm-0 {
		padding-bottom: 0 !important;
	}

	.oitis-pl-sm-0,
	.oitis-px-sm-0 {
		padding-left: 0 !important;
	}

	.oitis-p-sm-1 {
		padding: 0.25rem !important;
	}

	.oitis-pt-sm-1,
	.oitis-py-sm-1 {
		padding-top: 0.25rem !important;
	}

	.oitis-pr-sm-1,
	.oitis-px-sm-1 {
		padding-right: 0.25rem !important;
	}

	.oitis-pb-sm-1,
	.oitis-py-sm-1 {
		padding-bottom: 0.25rem !important;
	}

	.oitis-pl-sm-1,
	.oitis-px-sm-1 {
		padding-left: 0.25rem !important;
	}

	.oitis-p-sm-2 {
		padding: 0.5rem !important;
	}

	.oitis-pt-sm-2,
	.oitis-py-sm-2 {
		padding-top: 0.5rem !important;
	}

	.oitis-pr-sm-2,
	.oitis-px-sm-2 {
		padding-right: 0.5rem !important;
	}

	.oitis-pb-sm-2,
	.oitis-py-sm-2 {
		padding-bottom: 0.5rem !important;
	}

	.oitis-pl-sm-2,
	.oitis-px-sm-2 {
		padding-left: 0.5rem !important;
	}

	.oitis-p-sm-3 {
		padding: 1rem !important;
	}

	.oitis-pt-sm-3,
	.oitis-py-sm-3 {
		padding-top: 1rem !important;
	}

	.oitis-pr-sm-3,
	.oitis-px-sm-3 {
		padding-right: 1rem !important;
	}

	.oitis-pb-sm-3,
	.oitis-py-sm-3 {
		padding-bottom: 1rem !important;
	}

	.oitis-pl-sm-3,
	.oitis-px-sm-3 {
		padding-left: 1rem !important;
	}

	.oitis-p-sm-4 {
		padding: 1.5rem !important;
	}

	.oitis-pt-sm-4,
	.oitis-py-sm-4 {
		padding-top: 1.5rem !important;
	}

	.oitis-pr-sm-4,
	.oitis-px-sm-4 {
		padding-right: 1.5rem !important;
	}

	.oitis-pb-sm-4,
	.oitis-py-sm-4 {
		padding-bottom: 1.5rem !important;
	}

	.oitis-pl-sm-4,
	.oitis-px-sm-4 {
		padding-left: 1.5rem !important;
	}

	.oitis-p-sm-5 {
		padding: 2rem !important;
	}

	.oitis-pt-sm-5,
	.oitis-py-sm-5 {
		padding-top: 2rem !important;
	}

	.oitis-pr-sm-5,
	.oitis-px-sm-5 {
		padding-right: 2rem !important;
	}

	.oitis-pb-sm-5,
	.oitis-py-sm-5 {
		padding-bottom: 2rem !important;
	}

	.oitis-pl-sm-5,
	.oitis-px-sm-5 {
		padding-left: 2rem !important;
	}

	.oitis-m-sm-auto {
		margin: auto !important;
	}

	.oitis-mt-sm-auto,
	.oitis-my-sm-auto {
		margin-top: auto !important;
	}

	.oitis-mr-sm-auto,
	.oitis-mx-sm-auto {
		margin-right: auto !important;
	}

	.oitis-mb-sm-auto,
	.oitis-my-sm-auto {
		margin-bottom: auto !important;
	}

	.oitis-ml-sm-auto,
	.oitis-mx-sm-auto {
		margin-left: auto !important;
	}
}

@media (min-width: 768px) {
	.oitis-m-md-0 {
		margin: 0 !important;
	}

	.oitis-mt-md-0,
	.oitis-my-md-0 {
		margin-top: 0 !important;
	}

	.oitis-mr-md-0,
	.oitis-mx-md-0 {
		margin-right: 0 !important;
	}

	.oitis-mb-md-0,
	.oitis-my-md-0 {
		margin-bottom: 0 !important;
	}

	.oitis-ml-md-0,
	.oitis-mx-md-0 {
		margin-left: 0 !important;
	}

	.oitis-m-md-1 {
		margin: 0.25rem !important;
	}

	.oitis-mt-md-1,
	.oitis-my-md-1 {
		margin-top: 0.25rem !important;
	}

	.oitis-mr-md-1,
	.oitis-mx-md-1 {
		margin-right: 0.25rem !important;
	}

	.oitis-mb-md-1,
	.oitis-my-md-1 {
		margin-bottom: 0.25rem !important;
	}

	.oitis-ml-md-1,
	.oitis-mx-md-1 {
		margin-left: 0.25rem !important;
	}

	.oitis-m-md-2 {
		margin: 0.5rem !important;
	}

	.oitis-mt-md-2,
	.oitis-my-md-2 {
		margin-top: 0.5rem !important;
	}

	.oitis-mr-md-2,
	.oitis-mx-md-2 {
		margin-right: 0.5rem !important;
	}

	.oitis-mb-md-2,
	.oitis-my-md-2 {
		margin-bottom: 0.5rem !important;
	}

	.oitis-ml-md-2,
	.oitis-mx-md-2 {
		margin-left: 0.5rem !important;
	}

	.oitis-m-md-3 {
		margin: 1rem !important;
	}

	.oitis-mt-md-3,
	.oitis-my-md-3 {
		margin-top: 1rem !important;
	}

	.oitis-mr-md-3,
	.oitis-mx-md-3 {
		margin-right: 1rem !important;
	}

	.oitis-mb-md-3,
	.oitis-my-md-3 {
		margin-bottom: 1rem !important;
	}

	.oitis-ml-md-3,
	.oitis-mx-md-3 {
		margin-left: 1rem !important;
	}

	.oitis-m-md-4 {
		margin: 1.5rem !important;
	}

	.oitis-mt-md-4,
	.oitis-my-md-4 {
		margin-top: 1.5rem !important;
	}

	.oitis-mr-md-4,
	.oitis-mx-md-4 {
		margin-right: 1.5rem !important;
	}

	.oitis-mb-md-4,
	.oitis-my-md-4 {
		margin-bottom: 1.5rem !important;
	}

	.oitis-ml-md-4,
	.oitis-mx-md-4 {
		margin-left: 1.5rem !important;
	}

	.oitis-m-md-5 {
		margin: 3rem !important;
	}

	.oitis-mt-md-5,
	.oitis-my-md-5 {
		margin-top: 3rem !important;
	}

	.oitis-mr-md-5,
	.oitis-mx-md-5 {
		margin-right: 3rem !important;
	}

	.oitis-mb-md-5,
	.oitis-my-md-5 {
		margin-bottom: 3rem !important;
	}

	.oitis-ml-md-5,
	.oitis-mx-md-5 {
		margin-left: 3rem !important;
	}

	.oitis-p-md-0 {
		padding: 0 !important;
	}

	.oitis-pt-md-0,
	.oitis-py-md-0 {
		padding-top: 0 !important;
	}

	.oitis-pr-md-0,
	.oitis-px-md-0 {
		padding-right: 0 !important;
	}

	.oitis-pb-md-0,
	.oitis-py-md-0 {
		padding-bottom: 0 !important;
	}

	.oitis-pl-md-0,
	.oitis-px-md-0 {
		padding-left: 0 !important;
	}

	.oitis-p-md-1 {
		padding: 0.25rem !important;
	}

	.oitis-pt-md-1,
	.oitis-py-md-1 {
		padding-top: 0.25rem !important;
	}

	.oitis-pr-md-1,
	.oitis-px-md-1 {
		padding-right: 0.25rem !important;
	}

	.oitis-pb-md-1,
	.oitis-py-md-1 {
		padding-bottom: 0.25rem !important;
	}

	.oitis-pl-md-1,
	.oitis-px-md-1 {
		padding-left: 0.25rem !important;
	}

	.oitis-p-md-2 {
		padding: 0.5rem !important;
	}

	.oitis-pt-md-2,
	.oitis-py-md-2 {
		padding-top: 0.5rem !important;
	}

	.oitis-pr-md-2,
	.oitis-px-md-2 {
		padding-right: 0.5rem !important;
	}

	.oitis-pb-md-2,
	.oitis-py-md-2 {
		padding-bottom: 0.5rem !important;
	}

	.oitis-pl-md-2,
	.oitis-px-md-2 {
		padding-left: 0.5rem !important;
	}

	.oitis-p-md-3 {
		padding: 1rem !important;
	}

	.oitis-pt-md-3,
	.oitis-py-md-3 {
		padding-top: 1rem !important;
	}

	.oitis-pr-md-3,
	.oitis-px-md-3 {
		padding-right: 1rem !important;
	}

	.oitis-pb-md-3,
	.oitis-py-md-3 {
		padding-bottom: 1rem !important;
	}

	.oitis-pl-md-3,
	.oitis-px-md-3 {
		padding-left: 1rem !important;
	}

	.oitis-p-md-4 {
		padding: 1.5rem !important;
	}

	.oitis-pt-md-4,
	.oitis-py-md-4 {
		padding-top: 1.5rem !important;
	}

	.oitis-pr-md-4,
	.oitis-px-md-4 {
		padding-right: 1.5rem !important;
	}

	.oitis-pb-md-4,
	.oitis-py-md-4 {
		padding-bottom: 1.5rem !important;
	}

	.oitis-pl-md-4,
	.oitis-px-md-4 {
		padding-left: 1.5rem !important;
	}

	.oitis-p-md-5 {
		padding: 2rem !important;
	}

	.oitis-pt-md-5,
	.oitis-py-md-5 {
		padding-top: 2rem !important;
	}

	.oitis-pr-md-5,
	.oitis-px-md-5 {
		padding-right: 2rem !important;
	}

	.oitis-pb-md-5,
	.oitis-py-md-5 {
		padding-bottom: 2rem !important;
	}

	.oitis-pl-md-5,
	.oitis-px-md-5 {
		padding-left: 2rem !important;
	}

	.oitis-m-md-auto {
		margin: auto !important;
	}

	.oitis-mt-md-auto,
	.oitis-my-md-auto {
		margin-top: auto !important;
	}

	.oitis-mr-md-auto,
	.oitis-mx-md-auto {
		margin-right: auto !important;
	}

	.oitis-mb-md-auto,
	.oitis-my-md-auto {
		margin-bottom: auto !important;
	}

	.oitis-ml-md-auto,
	.oitis-mx-md-auto {
		margin-left: auto !important;
	}
}

@media (min-width: 992px) {
	.oitis-m-lg-0 {
		margin: 0 !important;
	}

	.oitis-mt-lg-0,
	.oitis-my-lg-0 {
		margin-top: 0 !important;
	}

	.oitis-mr-lg-0,
	.oitis-mx-lg-0 {
		margin-right: 0 !important;
	}

	.oitis-mb-lg-0,
	.oitis-my-lg-0 {
		margin-bottom: 0 !important;
	}

	.oitis-ml-lg-0,
	.oitis-mx-lg-0 {
		margin-left: 0 !important;
	}

	.oitis-m-lg-1 {
		margin: 0.25rem !important;
	}

	.my-lg-1,
	.oitis-mt-lg-1 {
		margin-top: 0.25rem !important;
	}

	.oitis-mr-lg-1,
	.oitis-mx-lg-1 {
		margin-right: 0.25rem !important;
	}

	.oitis-mb-lg-1,
	.oitis-my-lg-1 {
		margin-bottom: 0.25rem !important;
	}

	.oitis-ml-lg-1,
	.oitis-mx-lg-1 {
		margin-left: 0.25rem !important;
	}

	.oitis-m-lg-2 {
		margin: 0.5rem !important;
	}

	.oitis-mt-lg-2,
	.oitis-my-lg-2 {
		margin-top: 0.5rem !important;
	}

	.oitis-mr-lg-2,
	.oitis-mx-lg-2 {
		margin-right: 0.5rem !important;
	}

	.oitis-mb-lg-2,
	.oitis-my-lg-2 {
		margin-bottom: 0.5rem !important;
	}

	.oitis-ml-lg-2,
	.oitis-mx-lg-2 {
		margin-left: 0.5rem !important;
	}

	.oitis-m-lg-3 {
		margin: 1rem !important;
	}

	.oitis-mt-lg-3,
	.oitis-my-lg-3 {
		margin-top: 1rem !important;
	}

	.oitis-mr-lg-3,
	.oitis-mx-lg-3 {
		margin-right: 1rem !important;
	}

	.oitis-mb-lg-3,
	.oitis-my-lg-3 {
		margin-bottom: 1rem !important;
	}

	.oitis-ml-lg-3,
	.oitis-mx-lg-3 {
		margin-left: 1rem !important;
	}

	.oitis-m-lg-4 {
		margin: 1.5rem !important;
	}

	.oitis-mt-lg-4,
	.oitis-my-lg-4 {
		margin-top: 1.5rem !important;
	}

	.oitis-mr-lg-4,
	.oitis-mx-lg-4 {
		margin-right: 1.5rem !important;
	}

	.oitis-mb-lg-4,
	.oitis-my-lg-4 {
		margin-bottom: 1.5rem !important;
	}

	.oitis-ml-lg-4,
	.oitis-mx-lg-4 {
		margin-left: 1.5rem !important;
	}

	.oitis-m-lg-5 {
		margin: 3rem !important;
	}

	.oitis-mt-lg-5,
	.oitis-my-lg-5 {
		margin-top: 3rem !important;
	}

	.oitis-mr-lg-5,
	.oitis-mx-lg-5 {
		margin-right: 3rem !important;
	}

	.oitis-mb-lg-5,
	.oitis-my-lg-5 {
		margin-bottom: 3rem !important;
	}

	.oitis-ml-lg-5,
	.oitis-mx-lg-5 {
		margin-left: 3rem !important;
	}

	.oitis-p-lg-0 {
		padding: 0 !important;
	}

	.oitis-pt-lg-0,
	.oitis-py-lg-0 {
		padding-top: 0 !important;
	}

	.oitis-pr-lg-0,
	.oitis-px-lg-0 {
		padding-right: 0 !important;
	}

	.oitis-pb-lg-0,
	.oitis-py-lg-0 {
		padding-bottom: 0 !important;
	}

	.oitis-pl-lg-0,
	.oitis-px-lg-0 {
		padding-left: 0 !important;
	}

	.oitis-p-lg-1 {
		padding: 0.25rem !important;
	}

	.oitis-pt-lg-1,
	.oitis-py-lg-1 {
		padding-top: 0.25rem !important;
	}

	.oitis-pr-lg-1,
	.oitis-px-lg-1 {
		padding-right: 0.25rem !important;
	}

	.oitis-pb-lg-1,
	.oitis-py-lg-1 {
		padding-bottom: 0.25rem !important;
	}

	.oitis-pl-lg-1,
	.oitis-px-lg-1 {
		padding-left: 0.25rem !important;
	}

	.oitis-p-lg-2 {
		padding: 0.5rem !important;
	}

	.oitis-pt-lg-2,
	.oitis-py-lg-2 {
		padding-top: 0.5rem !important;
	}

	.oitis-pr-lg-2,
	.oitis-px-lg-2 {
		padding-right: 0.5rem !important;
	}

	.oitis-pb-lg-2,
	.oitis-py-lg-2 {
		padding-bottom: 0.5rem !important;
	}

	.oitis-pl-lg-2,
	.oitis-px-lg-2 {
		padding-left: 0.5rem !important;
	}

	.oitis-p-lg-3 {
		padding: 1rem !important;
	}

	.oitis-pt-lg-3,
	.oitis-py-lg-3 {
		padding-top: 1rem !important;
	}

	.oitis-pr-lg-3,
	.oitis-px-lg-3 {
		padding-right: 1rem !important;
	}

	.oitis-pb-lg-3,
	.oitis-py-lg-3 {
		padding-bottom: 1rem !important;
	}

	.oitis-pl-lg-3,
	.oitis-px-lg-3 {
		padding-left: 1rem !important;
	}

	.oitis-p-lg-4 {
		padding: 1.5rem !important;
	}

	.oitis-pt-lg-4,
	.oitis-py-lg-4 {
		padding-top: 1.5rem !important;
	}

	.oitis-pr-lg-4,
	.oitis-px-lg-4 {
		padding-right: 1.5rem !important;
	}

	.oitis-pb-lg-4,
	.oitis-py-lg-4 {
		padding-bottom: 1.5rem !important;
	}

	.oitis-pl-lg-4,
	.oitis-px-lg-4 {
		padding-left: 1.5rem !important;
	}

	.oitis-p-lg-5 {
		padding: 2rem !important;
	}

	.oitis-pt-lg-5,
	.oitis-py-lg-5 {
		padding-top: 2rem !important;
	}

	.oitis-pr-lg-5,
	.oitis-px-lg-5 {
		padding-right: 2rem !important;
	}

	.oitis-pb-lg-5,
	.oitis-py-lg-5 {
		padding-bottom: 2rem !important;
	}

	.oitis-pl-lg-5,
	.oitis-px-lg-5 {
		padding-left: 2rem !important;
	}

	.oitis-m-lg-auto {
		margin: auto !important;
	}

	.oitis-mt-lg-auto,
	.oitis-my-lg-auto {
		margin-top: auto !important;
	}

	.oitis-mr-lg-auto,
	.oitis-mx-lg-auto {
		margin-right: auto !important;
	}

	.oitis-mb-lg-auto,
	.oitis-my-lg-auto {
		margin-bottom: auto !important;
	}

	.oitis-ml-lg-auto,
	.oitis-mx-lg-auto {
		margin-left: auto !important;
	}
}

@media (min-width: 1200px) {
	.oitis-m-xl-0 {
		margin: 0 !important;
	}

	.oitis-mt-xl-0,
	.oitis-my-xl-0 {
		margin-top: 0 !important;
	}

	.oitis-mr-xl-0,
	.oitis-mx-xl-0 {
		margin-right: 0 !important;
	}

	.oitis-mb-xl-0,
	.oitis-my-xl-0 {
		margin-bottom: 0 !important;
	}

	.oitis-ml-xl-0,
	.oitis-mx-xl-0 {
		margin-left: 0 !important;
	}

	.oitis-m-xl-1 {
		margin: 0.25rem !important;
	}

	.oitis-mt-xl-1,
	.oitis-my-xl-1 {
		margin-top: 0.25rem !important;
	}

	.oitis-mr-xl-1,
	.oitis-mx-xl-1 {
		margin-right: 0.25rem !important;
	}

	.oitis-mb-xl-1,
	.oitis-my-xl-1 {
		margin-bottom: 0.25rem !important;
	}

	.oitis-ml-xl-1,
	.oitis-mx-xl-1 {
		margin-left: 0.25rem !important;
	}

	.oitis-m-xl-2 {
		margin: 0.5rem !important;
	}

	.oitis-mt-xl-2,
	.oitis-my-xl-2 {
		margin-top: 0.5rem !important;
	}

	.oitis-mr-xl-2,
	.oitis-mx-xl-2 {
		margin-right: 0.5rem !important;
	}

	.oitis-mb-xl-2,
	.oitis-my-xl-2 {
		margin-bottom: 0.5rem !important;
	}

	.oitis-ml-xl-2,
	.oitis-mx-xl-2 {
		margin-left: 0.5rem !important;
	}

	.oitis-m-xl-3 {
		margin: 1rem !important;
	}

	.oitis-mt-xl-3,
	.oitis-my-xl-3 {
		margin-top: 1rem !important;
	}

	.oitis-mr-xl-3,
	.oitis-mx-xl-3 {
		margin-right: 1rem !important;
	}

	.oitis-mb-xl-3,
	.oitis-my-xl-3 {
		margin-bottom: 1rem !important;
	}

	.oitis-ml-xl-3,
	.oitis-mx-xl-3 {
		margin-left: 1rem !important;
	}

	.oitis-m-xl-4 {
		margin: 1.5rem !important;
	}

	.oitis-mt-xl-4,
	.oitis-my-xl-4 {
		margin-top: 1.5rem !important;
	}

	.oitis-mr-xl-4,
	.oitis-mx-xl-4 {
		margin-right: 1.5rem !important;
	}

	.oitis-mb-xl-4,
	.oitis-my-xl-4 {
		margin-bottom: 1.5rem !important;
	}

	.oitis-ml-xl-4,
	.oitis-mx-xl-4 {
		margin-left: 1.5rem !important;
	}

	.oitis-m-xl-5 {
		margin: 3rem !important;
	}

	.oitis-mt-xl-5,
	.oitis-my-xl-5 {
		margin-top: 3rem !important;
	}

	.oitis-mr-xl-5,
	.oitis-mx-xl-5 {
		margin-right: 3rem !important;
	}

	.oitis-mb-xl-5,
	.oitis-my-xl-5 {
		margin-bottom: 3rem !important;
	}

	.oitis-ml-xl-5,
	.oitis-mx-xl-5 {
		margin-left: 3rem !important;
	}

	.oitis-p-xl-0 {
		padding: 0 !important;
	}

	.oitis-pt-xl-0,
	.oitis-py-xl-0 {
		padding-top: 0 !important;
	}

	.oitis-pr-xl-0,
	.oitis-px-xl-0 {
		padding-right: 0 !important;
	}

	.oitis-pb-xl-0,
	.oitis-py-xl-0 {
		padding-bottom: 0 !important;
	}

	.oitis-pl-xl-0,
	.oitis-px-xl-0 {
		padding-left: 0 !important;
	}

	.oitis-p-xl-1 {
		padding: 0.25rem !important;
	}

	.oitis-pt-xl-1,
	.oitis-py-xl-1 {
		padding-top: 0.25rem !important;
	}

	.oitis-pr-xl-1,
	.oitis-px-xl-1 {
		padding-right: 0.25rem !important;
	}

	.oitis-pb-xl-1,
	.oitis-py-xl-1 {
		padding-bottom: 0.25rem !important;
	}

	.oitis-pl-xl-1,
	.oitis-px-xl-1 {
		padding-left: 0.25rem !important;
	}

	.oitis-p-xl-2 {
		padding: 0.5rem !important;
	}

	.oitis-pt-xl-2,
	.oitis-py-xl-2 {
		padding-top: 0.5rem !important;
	}

	.oitis-pr-xl-2,
	.oitis-px-xl-2 {
		padding-right: 0.5rem !important;
	}

	.oitis-pb-xl-2,
	.oitis-py-xl-2 {
		padding-bottom: 0.5rem !important;
	}

	.oitis-pl-xl-2,
	.oitis-px-xl-2 {
		padding-left: 0.5rem !important;
	}

	.oitis-p-xl-3 {
		padding: 1rem !important;
	}

	.oitis-pt-xl-3,
	.oitis-py-xl-3 {
		padding-top: 1rem !important;
	}

	.oitis-pr-xl-3,
	.oitis-px-xl-3 {
		padding-right: 1rem !important;
	}

	.oitis-pb-xl-3,
	.oitis-py-xl-3 {
		padding-bottom: 1rem !important;
	}

	.oitis-pl-xl-3,
	.oitis-px-xl-3 {
		padding-left: 1rem !important;
	}

	.oitis-p-xl-4 {
		padding: 1.5rem !important;
	}

	.oitis-pt-xl-4,
	.oitis-py-xl-4 {
		padding-top: 1.5rem !important;
	}

	.oitis-pr-xl-4,
	.oitis-px-xl-4 {
		padding-right: 1.5rem !important;
	}

	.oitis-pb-xl-4,
	.oitis-py-xl-4 {
		padding-bottom: 1.5rem !important;
	}

	.oitis-pl-xl-4,
	.oitis-px-xl-4 {
		padding-left: 1.5rem !important;
	}

	.oitis-p-xl-5 {
		padding: 2rem !important;
	}

	.oitis-pt-xl-5,
	.oitis-py-xl-5 {
		padding-top: 2rem !important;
	}

	.oitis-pr-xl-5,
	.oitis-px-xl-5 {
		padding-right: 2rem !important;
	}

	.oitis-pb-xl-5,
	.oitis-py-xl-5 {
		padding-bottom: 2rem !important;
	}

	.oitis-pl-xl-5,
	.oitis-px-xl-5 {
		padding-left: 2rem !important;
	}

	.oitis-m-xl-auto {
		margin: auto !important;
	}

	.oitis-mt-xl-auto,
	.oitis-my-xl-auto {
		margin-top: auto !important;
	}

	.oitis-mr-xl-auto,
	.oitis-mx-xl-auto {
		margin-right: auto !important;
	}

	.oitis-mb-xl-auto,
	.oitis-my-xl-auto {
		margin-bottom: auto !important;
	}

	.oitis-ml-xl-auto,
	.oitis-mx-xl-auto {
		margin-left: auto !important;
	}
}

@media (min-width: 1368px) {
	.oitis-m-xxl-0 {
		margin: 0 !important;
	}

	.oitis-mt-xxl-0,
	.oitis-my-xxl-0 {
		margin-top: 0 !important;
	}

	.oitis-mr-xxl-0,
	.oitis-mx-xxl-0 {
		margin-right: 0 !important;
	}

	.oitis-mb-xxl-0,
	.oitis-my-xxl-0 {
		margin-bottom: 0 !important;
	}

	.oitis-ml-xxl-0,
	.oitis-mx-xxl-0 {
		margin-left: 0 !important;
	}

	.oitis-m-xxl-1 {
		margin: 0.25rem !important;
	}

	.oitis-mt-xxl-1,
	.oitis-my-xxl-1 {
		margin-top: 0.25rem !important;
	}

	.oitis-mr-xxl-1,
	.oitis-mx-xxl-1 {
		margin-right: 0.25rem !important;
	}

	.oitis-mb-xxl-1,
	.oitis-my-xxl-1 {
		margin-bottom: 0.25rem !important;
	}

	.oitis-ml-xxl-1,
	.oitis-mx-xxl-1 {
		margin-left: 0.25rem !important;
	}

	.oitis-m-xxl-2 {
		margin: 0.5rem !important;
	}

	.oitis-mt-xxl-2,
	.oitis-my-xxl-2 {
		margin-top: 0.5rem !important;
	}

	.oitis-mr-xxl-2,
	.oitis-mx-xxl-2 {
		margin-right: 0.5rem !important;
	}

	.oitis-mb-xxl-2,
	.oitis-my-xxl-2 {
		margin-bottom: 0.5rem !important;
	}

	.oitis-ml-xxl-2,
	.oitis-mx-xxl-2 {
		margin-left: 0.5rem !important;
	}

	.oitis-m-xxl-3 {
		margin: 1rem !important;
	}

	.oitis-mt-xxl-3,
	.oitis-my-xxl-3 {
		margin-top: 1rem !important;
	}

	.oitis-mr-xxl-3,
	.oitis-mx-xxl-3 {
		margin-right: 1rem !important;
	}

	.oitis-mb-xxl-3,
	.oitis-my-xxl-3 {
		margin-bottom: 1rem !important;
	}

	.oitis-ml-xxl-3,
	.oitis-mx-xxl-3 {
		margin-left: 1rem !important;
	}

	.oitis-m-xxl-4 {
		margin: 1.5rem !important;
	}

	.oitis-mt-xxl-4,
	.oitis-my-xxl-4 {
		margin-top: 1.5rem !important;
	}

	.oitis-mr-xxl-4,
	.oitis-mx-xxl-4 {
		margin-right: 1.5rem !important;
	}

	.oitis-mb-xxl-4,
	.oitis-my-xxl-4 {
		margin-bottom: 1.5rem !important;
	}

	.oitis-ml-xxl-4,
	.oitis-mx-xxl-4 {
		margin-left: 1.5rem !important;
	}

	.oitis-m-xxl-5 {
		margin: 3rem !important;
	}

	.oitis-mt-xxl-5,
	.oitis-my-xxl-5 {
		margin-top: 3rem !important;
	}

	.oitis-mr-xxl-5,
	.oitis-mx-xxl-5 {
		margin-right: 3rem !important;
	}

	.oitis-mb-xxl-5,
	.oitis-my-xxl-5 {
		margin-bottom: 3rem !important;
	}

	.oitis-ml-xxl-5,
	.oitis-mx-xxl-5 {
		margin-left: 3rem !important;
	}

	.oitis-p-xxl-0 {
		padding: 0 !important;
	}

	.oitis-pt-xxl-0,
	.oitis-py-xxl-0 {
		padding-top: 0 !important;
	}

	.oitis-pr-xxl-0,
	.oitis-px-xxl-0 {
		padding-right: 0 !important;
	}

	.oitis-pb-xxl-0,
	.oitis-py-xxl-0 {
		padding-bottom: 0 !important;
	}

	.oitis-pl-xxl-0,
	.oitis-px-xxl-0 {
		padding-left: 0 !important;
	}

	.oitis-p-xxl-1 {
		padding: 0.25rem !important;
	}

	.oitis-pt-xxl-1,
	.oitis-py-xxl-1 {
		padding-top: 0.25rem !important;
	}

	.oitis-pr-xxl-1,
	.oitis-px-xxl-1 {
		padding-right: 0.25rem !important;
	}

	.oitis-pb-xxl-1,
	.oitis-py-xxl-1 {
		padding-bottom: 0.25rem !important;
	}

	.oitis-pl-xxl-1,
	.oitis-px-xxl-1 {
		padding-left: 0.25rem !important;
	}

	.oitis-p-xxl-2 {
		padding: 0.5rem !important;
	}

	.oitis-pt-xxl-2,
	.oitis-py-xxl-2 {
		padding-top: 0.5rem !important;
	}

	.oitis-pr-xxl-2,
	.oitis-px-xxl-2 {
		padding-right: 0.5rem !important;
	}

	.oitis-pb-xxl-2,
	.oitis-py-xxl-2 {
		padding-bottom: 0.5rem !important;
	}

	.oitis-pl-xxl-2,
	.oitis-px-xxl-2 {
		padding-left: 0.5rem !important;
	}

	.oitis-p-xxl-3 {
		padding: 1rem !important;
	}

	.oitis-pt-xxl-3,
	.oitis-py-xxl-3 {
		padding-top: 1rem !important;
	}

	.oitis-pr-xxl-3,
	.oitis-px-xxl-3 {
		padding-right: 1rem !important;
	}

	.oitis-pb-xxl-3,
	.oitis-py-xxl-3 {
		padding-bottom: 1rem !important;
	}

	.oitis-pl-xxl-3,
	.oitis-px-xxl-3 {
		padding-left: 1rem !important;
	}

	.oitis-p-xxl-4 {
		padding: 1.5rem !important;
	}

	.oitis-pt-xxl-4,
	.oitis-py-xxl-4 {
		padding-top: 1.5rem !important;
	}

	.oitis-pr-xxl-4,
	.oitis-px-xxl-4 {
		padding-right: 1.5rem !important;
	}

	.oitis-pb-xxl-4,
	.oitis-py-xxl-4 {
		padding-bottom: 1.5rem !important;
	}

	.oitis-pl-xxl-4,
	.oitis-px-xxl-4 {
		padding-left: 1.5rem !important;
	}

	.oitis-p-xxl-5 {
		padding: 2rem !important;
	}

	.oitis-pt-xxl-5,
	.oitis-py-xxl-5 {
		padding-top: 2rem !important;
	}

	.oitis-pr-xxl-5,
	.oitis-px-xxl-5 {
		padding-right: 2rem !important;
	}

	.oitis-pb-xxl-5,
	.oitis-py-xxl-5 {
		padding-bottom: 2rem !important;
	}

	.oitis-pl-xxl-5,
	.oitis-px-xxl-5 {
		padding-left: 2rem !important;
	}

	.oitis-m-xxl-auto {
		margin: auto !important;
	}

	.oitis-mt-xxl-auto,
	.oitis-my-xxl-auto {
		margin-top: auto !important;
	}

	.oitis-mr-xxl-auto,
	.oitis-mx-xxl-auto {
		margin-right: auto !important;
	}

	.oitis-mb-xxl-auto,
	.oitis-my-xxl-auto {
		margin-bottom: auto !important;
	}

	.oitis-ml-xxl-auto,
	.oitis-mx-xxl-auto {
		margin-left: auto !important;
	}
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	color: #262626;
	font-weight: 400;
	line-height: 1.2;
}

.h1,
h1 {
	font-size: 22px;
}

.framed-layout .h1,
.framed-layout h1 {
	font-size: 20px;
}

@media screen and (max-width: 767px) {

	.framed-layout .h1,
	.framed-layout h1 {
		font-size: 18px;
	}
}

.boxed-layout .h1,
.boxed-layout h1 {
	font-size: 18px;
}

@media screen and (max-width: 767px) {

	.boxed-layout .h1,
	.boxed-layout h1 {
		font-size: 16px;
	}
}

@media screen and (min-width: 1600px) {

	.h1,
	h1 {
		font-size: 28px;
	}

	.boxed-layout .h1,
	.boxed-layout h1 {
		font-size: 18px;
	}
}

.h2,
h2 {
	font-size: 20px;
}

.h3,
h3 {
	font-size: 18px;
}

.h4,
h4 {
	font-size: 16px;
}

.h5,
h5 {
	font-size: 14px;
}

.h6,
h6 {
	font-size: 12px;
}

.oitis-h1-lg {
	font-size: 28px;
}

.oitis-text-strikethrough {
	text-decoration: line-through !important;
}

.oitis-text-underline {
	text-decoration: underline !important;
}

.oitis-fs-xxs {
	font-size: 8px;
}

.oitis-fs-xs {
	font-size: 10px;
}

.oitis-fs-11 {
	font-size: 11px;
}

.oitis-fs-sm {
	font-size: 12px;
	line-height: 1.5;
}

.oitis-fs-md {
	font-size: 14px;
	line-height: 1;
}

.oitis-fs-lg {
	font-size: 16px;
	line-height: 1;
}

.oitis-fs-xl {
	font-size: 20px;
	line-height: 1;
}

.oitis-fs-xxl {
	font-size: 24px;
	line-height: 1.3;
}

@media screen and (max-width: 1199px) {
	.oitis-fs-xxl {
		font-size: 20px;
	}
}

.oitis-fs-2xl {
	font-size: 28px !important;
	line-height: 1;
}

@media screen and (max-width: 1199px) {
	.oitis-fs-2xl {
		font-size: 20px !important;
	}
}

.oitis-fs-xxxl {
	font-size: 30px;
	line-height: 1;
}

@media screen and (max-width: 1366px) {
	.oitis-fs-xxxl {
		font-size: 28px;
	}
}

@media screen and (max-width: 1199px) {
	.oitis-fs-xxxl {
		font-size: 26px;
	}
}

@media screen and (max-width: 991px) {
	.oitis-fs-xxxl {
		font-size: 24px;
	}
}

.oitis-fs-xlxl {
	font-size: 40px;
	line-height: 1;
}

@media screen and (max-width: 1199px) {
	.oitis-fs-xlxl {
		font-size: 32px;
	}
}

@media screen and (max-width: 991px) {
	.oitis-fs-xlxl {
		font-size: 28px;
	}
}

.oitis-fs-icon-lg {
	font-size: 50px;
	line-height: 1;
}

@media screen and (max-width: 1366px) {
	.oitis-fs-icon-lg {
		font-size: 38px;
	}
}

@media screen and (max-width: 575px) {
	.oitis-fs-icon-lg {
		font-size: 30px;
	}
}

.oitis-fs-iconcard {
	font-size: 54px;
	line-height: 1;
}

@media screen and (max-width: 1366px) {
	.oitis-fs-iconcard {
		font-size: 34px;
	}
}

.oitis-text-lowercase {
	text-transform: lowercase;
}

.oitis-text-uppercase {
	text-transform: uppercase;
}

.oitis-text-capitalize {
	text-transform: capitalize;
}

.oitis-font-weight-light {
	font-weight: 300;
}

.oitis-font-weight-normal {
	font-weight: 400;
}

.oitis-font-weight-medium {
	font-weight: 500;
}

.oitis-font-weight-semi-bold {
	font-weight: 600;
}

.oitis-font-weight-bold {
	font-weight: 700;
}

.oitis-font-weight-heavy {
	font-weight: 900;
}

.oitis-font-italic {
	font-style: italic;
}

.oitis-letter-spacing-base {
	letter-spacing: 3px;
}

.oitis-letter-spacing-lg {
	letter-spacing: 6px;
}

.oitis-letter-spacing-xl {
	letter-spacing: 8px;
}

.oitis-text-justify {
	text-align: justify !important;
}

.oitis-text-nowrap {
	white-space: nowrap !important;
}

.oitis-text-left {
	text-align: left !important;
}

.oitis-text-right {
	text-align: right !important;
}

.oitis-text-center {
	text-align: center !important;
}

.oitis-text-truncate {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}

.ant-layout {
	background: none;
	position: relative;
}

.ant-layout>.oitis-layout-content {
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	overflow-x: hidden;
}

.full-scroll .ant-layout>.oitis-layout-content {
	overflow-x: inherit;
}

.ant-layout.ant-layout-has-sider>.ant-layout {
	background: #f5f5f5;
	position: relative;
}

.boxed-layout .ant-layout,
.framed-layout .ant-layout {
	background: #f5f5f5;
}

.ant-layout.oitis-app-layout {
	height: 100vh;
}

.framed-layout .ant-layout.oitis-app-layout {
	height: calc(100vh - 40px);
}

@media screen and (max-width: 991px) {
	.framed-layout .ant-layout.oitis-app-layout {
		height: 100vh;
	}
}

.boxed-layout.full-scroll .ant-layout.oitis-app-layout,
.framed-layout.full-scroll .ant-layout.oitis-app-layout {
	overflow-y: scroll;
}

.ant-layout.oitis-app-layout>.ant-layout {
	height: 100vh;
}

.framed-layout .ant-layout.oitis-app-layout>.ant-layout {
	height: calc(100vh - 40px);
}

@media screen and (max-width: 991px) {
	.framed-layout .ant-layout.oitis-app-layout>.ant-layout {
		height: 100vh;
	}
}

.full-scroll .ant-layout.ant-layout-has-sider>.ant-layout,
.full-scroll .ant-layout.ant-layout-has-sider>.ant-layout-content {
	overflow-x: inherit;
}

.oitis-main-content-wrapper {
	flex: 1 1;
	padding: 32px 32px 0;
}

@media screen and (max-width: 575px) {
	.oitis-main-content-wrapper {
		padding: 20px 15px 0;
	}
}

.oitis-container-wrap .oitis-main-content-wrapper {
	margin-left: auto;
	margin-right: auto;
	padding-left: 16px;
	padding-right: 16px;
	width: 94vw;
}

@media screen and (min-width: 768px) {
	.oitis-container-wrap .oitis-main-content-wrapper {
		width: 720px;
	}
}

@media screen and (min-width: 992px) {
	.oitis-container-wrap .oitis-main-content-wrapper {
		width: 920px;
	}
}

@media screen and (min-width: 1200px) {
	.oitis-container-wrap .oitis-main-content-wrapper {
		width: 1100px;
	}
}

@media screen and (min-width: 1367px) {
	.oitis-container-wrap .oitis-main-content-wrapper {
		width: 1200px;
	}
}

@media screen and (min-width: 1600px) {
	.oitis-container-wrap .oitis-main-content-wrapper {
		width: 1400px;
	}
}

.oitis-main-content {
	display: -ms-flex;
	display: flex;
	flex: 1 1;
	flex-direction: column;
	flex-wrap: nowrap;
	height: 100%;
}

.oitis-container {
	margin-left: auto;
	margin-right: auto;
	padding-left: 16px;
	padding-right: 16px;
	width: 94vw;
}

@media screen and (min-width: 768px) {
	.oitis-container {
		width: 720px;
	}
}

@media screen and (min-width: 992px) {
	.oitis-container {
		width: 920px;
	}
}

@media screen and (min-width: 1200px) {
	.oitis-container {
		width: 1100px;
	}
}

@media screen and (min-width: 1367px) {
	.oitis-container {
		width: 1200px;
	}
}

@media screen and (min-width: 1600px) {
	.oitis-container {
		width: 1400px;
	}
}

.ant-layout-header {
	-ms-align-items: center;
	align-items: center;
	box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
	color: #262626;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	height: 72px;
	-ms-justify-content: space-between;
	justify-content: space-between;
	line-height: 1;
	padding: 0 32px;
	position: relative;
	z-index: 10;
}

@media screen and (max-width: 991px) {
	.ant-layout-header {
		padding: 0 15px;
	}
}

@media screen and (max-width: 575px) {
	.ant-layout-header {
		height: 50px;
	}
}

.oitis-nav-header {
	background-color: #fefefe;
	border-bottom: 1px solid hsla(0, 0%, 100%, 0.5);
	padding: 0 32px;
}

.oitis-nav-header .ant-menu-horizontal {
	border-bottom-color: transparent;
	margin-bottom: -1px;
}

.oitis-nav-header-below {
	box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.28);
	position: relative;
	z-index: 999;
}

.ant-dropdown-menu-item {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.oitis-linebar {
	font-size: 20px;
}

.oitis-header-horizontal-dark .oitis-linebar {
	color: #fff;
}

.oitis-header-notifications {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	list-style: none;
	margin: 0;
	padding-left: 0;
}

.oitis-header-notifications>li {
	font-size: 18px;
}

.oitis-header-notifications>li:not(:last-child) {
	margin-right: 42px;
}

.oitis-inside-header-horizontal .oitis-header-notifications>li:not(:last-child) {
	margin-right: 20px;
}

.oitis-header-horizontal-main .oitis-header-notifications>li:not(:last-child),
.oitis-header-horizontal-nav .oitis-header-notifications>li:not(:last-child) {
	margin-right: 30px;
}

@media screen and (max-width: 991px) {

	.oitis-header-horizontal-main .oitis-header-notifications>li:not(:last-child),
	.oitis-header-horizontal-nav .oitis-header-notifications>li:not(:last-child),
	.oitis-header-notifications>li:not(:last-child) {
		margin-right: 16px;
	}
}

.oitis-header-notifications>li.oitis-notify {
	margin-right: 22px;
}

.oitis-header-horizontal-main .oitis-header-notifications>li.oitis-notify {
	margin-right: 30px;
}

@media screen and (max-width: 991px) {
	.oitis-header-horizontal-main .oitis-header-notifications>li.oitis-notify {
		margin-right: 16px;
	}
}

.oitis-inside-header-horizontal .oitis-header-notifications>li.oitis-notify {
	margin-right: 20px;
}

@media screen and (max-width: 991px) {
	.oitis-header-notifications>li.oitis-notify {
		margin-right: 16px;
	}
}

.oitis-header-notifications>li.oitis-language {
	font-size: 14px;
}

@media screen and (max-width: 575px) {

	.oitis-header-notifications>li.oitis-language .icon,
	.oitis-header-notifications>li.oitis-language .oitis-language-name {
		display: none;
	}

	.oitis-header-notifications>li.oitis-language .flag.flag-24 {
		height: 20px;
		margin-top: -4px;
		width: 20px;
	}

	.oitis-header-notifications>li.oitis-msg,
	.oitis-header-notifications>li.oitis-notify {
		margin-top: 2px;
	}

	.oitis-header-notifications>li.oitis-language {
		margin-bottom: 2px;
	}
}

@media screen and (max-width: 991px) {
	.oitis-header-horizontal-main .oitis-header-notifications>li.oitis-user-nav {
		margin-left: 0;
	}
}

@media screen and (max-width: 1199px) {
	.oitis-inside-header-horizontal .oitis-header-horizontal-main .oitis-header-notifications>li.oitis-user-nav {
		margin-left: 0;
	}
}

.oitis-header-horizontal-main .oitis-header-notifications li>span {
	color: #545454;
}

.oitis-header-horizontal-dark .oitis-header-horizontal-main .oitis-header-notifications li>span {
	color: #fa8c15;
}

.oitis-popover-scroll {
	height: 280px !important;
	margin: 0 -16px;
	width: 300px !important;
}

@media screen and (max-width: 575px) {
	.oitis-popover-scroll {
		height: 280px !important;
		width: 200px !important;
	}
}

.oitis-popover-lang-scroll {
	margin: 0 -16px;
	width: 160px !important;
}

.ant-popover-placement-bottomRight>.ant-popover-content>.ant-popover-arrow {
	right: 10px;
}

.oitis-popover-header {
	-ms-align-items: center;
	align-items: center;
	border-bottom: 1px solid #e8e8e8;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	-ms-justify-content: space-between;
	justify-content: space-between;
	margin: 0 -16px;
	padding: 0 16px 12px;
}

.oitis-sub-popover {
	list-style: none;
	margin: 0 16px;
	padding-left: 0;
}

.oitis-sub-popover li {
	padding: 20px 0 14px;
}

.oitis-popover-lang-scroll .oitis-sub-popover li {
	padding: 5px 0;
}

.oitis-sub-popover li:not(:last-child) {
	border-bottom: 1px solid #e8e8e8;
}

.oitis-user-popover {
	list-style: none;
	margin: -12px -16px;
	padding: 7px 0;
}

.oitis-user-popover li {
	cursor: pointer;
	padding: 8px 15px;
	width: 150px;
}

.oitis-user-popover li:focus,
.oitis-user-popover li:hover {
	background-color: #fafafa;
}

@media screen and (max-width: 575px) {
	.oitis-user-popover li {
		width: 100%;
	}
}

.oitis-header-horizontal {
	background-color: #fff;
	position: relative;
}

.oitis-header-horizontal-dark {
	background-color: #038fde;
	color: #fa8c15;
	position: relative;
}

.oitis-header-horizontal-dark:before {
	background-color: hsla(0, 0%, 100%, 0.2);
	border-radius: 0 0 135px 135px;
	content: '';
	height: 350px;
	position: absolute;
	right: 169px;
	top: -212px;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	width: 280px;
	z-index: 1;
}

@media screen and (max-width: 1049px) {
	.oitis-header-horizontal-dark:before {
		right: 110px;
	}
}

@media screen and (max-width: 1199px) {
	.oitis-header-horizontal-dark:before {
		height: 300px;
		right: 80px;
		top: -184px;
	}
}

@media screen and (max-width: 991px) {
	.oitis-header-horizontal-dark:before {
		height: 240px;
		right: 140px;
		top: -154px;
		width: 200px;
	}
}

@media screen and (max-width: 575px) {
	.oitis-header-horizontal-dark:before {
		display: none;
		right: 60px;
	}
}

.oitis-header-horizontal-dark>div {
	position: relative;
	z-index: 2;
}

.oitis-header-horizontal-top {
	font-size: 14px;
	padding: 12px 0;
}

.oitis-header-horizontal-main-flex,
.oitis-header-horizontal-nav-flex,
.oitis-header-horizontal-top-flex {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.oitis-header-horizontal-top-left {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	margin-right: 15px;
}

@media screen and (max-width: 767px) {
	.oitis-header-horizontal-top-left {
		margin-right: 0;
		width: 100%;
	}
}

.oitis-header-horizontal-main {
	background-color: transparent;
	box-shadow: none;
	display: -webkit-block;
	display: -ms-blockbox;
	display: -ms-block;
	display: block;
	flex-direction: row;
	flex-wrap: nowrap;
	height: auto;
	padding: 25px 0;
}

@media screen and (max-width: 1199px) {
	.oitis-header-horizontal-main {
		padding: 20px 0;
	}

	.oitis-header-horizontal-main .oitis-w-logo {
		padding: 7px 5px;
	}
}

@media screen and (max-width: 991px) {
	.oitis-header-horizontal-main {
		padding: 0;
	}
}

@media screen and (max-width: 575px) {
	.oitis-header-horizontal-main .oitis-w-logo {
		padding-right: 0;
	}
}

.oitis-inside-header-horizontal .oitis-header-horizontal-main {
	padding: 0;
}

.oitis-header-horizontal-nav {
	background-color: #038fde;
	color: #fffffd;
}

.oitis-header-horizontal-dark .oitis-header-horizontal-nav {
	background-color: transparent;
}

.oitis-above-header-horizontal .oitis-header-horizontal-nav,
.oitis-below-header-horizontal .oitis-header-horizontal-nav {
	background-color: #fefefe;
	color: #7a7a7a;
}

.oitis-inside-header-horizontal .oitis-logo {
	margin-right: 25px;
}

.oitis-inside-header-horizontal:before {
	top: -230px;
}

@media screen and (max-width: 1199px) {
	.oitis-inside-header-horizontal:before {
		top: -190px;
	}
}

@media screen and (max-width: 991px) {
	.oitis-inside-header-horizontal:before {
		top: -154px;
	}
}

.oitis-above-header-horizontal:before {
	top: -165px;
}

@media screen and (max-width: 991px) {
	.oitis-above-header-horizontal:before {
		top: -154px;
	}
}

.oitis-login-list {
	list-style: none;
	margin-bottom: 0;
	margin-left: auto;
	padding-left: 0;
}

.oitis-login-list li {
	color: #fa8c15;
	cursor: pointer;
	display: inline-block;
	font-size: 12px;
	line-height: 12px;
	padding: 0 10px;
	text-transform: uppercase;
}

.oitis-login-list li:not(:first-child) {
	border-left: 1px solid #e8e8e8;
}

.oitis-header-horizontal-dark .oitis-login-list li:not(:first-child) {
	border: 0;
}

@media screen and (max-width: 767px) {
	.oitis-login-list {
		display: none;
	}
}

.oitis-header-search {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	margin-left: 28px;
}

.oitis-header-search .ant-select-selection,
.oitis-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	background-color: #038fde;
	border-color: #038fde;
	border-radius: 0 4px 4px 0;
	color: #fffffd;
	margin-bottom: 0;
}

.oitis-header-search .ant-select-selection:focus,
.oitis-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:focus {
	background-color: #038fde;
	border-color: #038fde;
	color: #fffffd;
}

.oitis-header-horizontal-dark .oitis-header-search .ant-select-selection,
.oitis-header-horizontal-dark .oitis-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	background-color: hsla(0, 0%, 100%, 0.2);
	border-color: hsla(0, 0%, 100%, 0.2);
	color: #fffffd;
	margin-left: 1px;
}

.oitis-header-horizontal-dark .oitis-header-search .ant-select-selection:focus,
.oitis-header-horizontal-dark .oitis-header-search .ant-select-single:not(.ant-select-customize-input) .ant-select-selector:focus {
	background-color: hsla(0, 0%, 100%, 0.2);
	border-color: hsla(0, 0%, 100%, 0.2);
	color: #fffffd;
}

.oitis-header-search .ant-select-arrow {
	color: #fffffd;
}

.oitis-popover-horizontal .ant-btn {
	border: 0;
	border-radius: 0;
	border-right: 1px solid #e8e8e8;
	height: 24px;
	line-height: 24px;
	margin: 0 !important;
}

.oitis-popover-horizontal .oitis-popover-search-bar {
	margin: 0;
}

.oitis-popover-horizontal .oitis-popover-search-bar .ant-input {
	height: 24px;
	line-height: 24px;
}

@media screen and (max-width: 575px) {
	.oitis-popover-horizontal {
		left: 0 !important;
		margin-top: -9px;
		padding-top: 0;
	}

	.oitis-popover-horizontal .ant-popover-inner-content {
		width: 100vw;
	}

	.oitis-popover-horizontal .ant-popover-arrow {
		display: none;
	}

	.oitis-popover-horizontal .oitis-popover-search-bar {
		width: 100%;
	}

	.oitis-popover-horizontal .oitis-popover-lang-scroll,
	.oitis-popover-horizontal .oitis-popover-scroll {
		margin: 0;
		width: 100% !important;
	}

	.oitis-popover-horizontal .oitis-sub-popover {
		margin: 0;
		padding-left: 5px;
	}
}

.oitis-no-header-horizontal {
	padding: 14px 32px 13px 79px;
	position: relative;
	text-align: center;
}

.oitis-no-header-horizontal-top {
	background-color: #d9f7be;
	border-radius: 4px;
	color: #237804;
	display: inline-block;
	padding: 10px 20px;
}

@media screen and (max-width: 575px) {
	.oitis-no-header-horizontal-top {
		font-size: 12px;
		width: 100%;
	}
}

.oitis-no-header-horizontal-top-center {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.oitis-no-header-horizontal .oitis-linebar {
	left: 32px;
	position: absolute;
	top: 15px;
	z-index: 1;
}

@media screen and (max-width: 575px) {
	.oitis-no-header-horizontal .oitis-linebar {
		left: 15px;
	}

	.oitis-no-header-horizontal {
		padding: 14px 15px 13px 60px;
	}
}

.oitis-app-sidebar {
	transition: all 0.2s ease;
}

.ant-layout-sider {
	background-color: #fffffc;
	color: #545454;
	flex: 0 0 auto !important;
	max-width: none !important;
	min-width: 10px !important;
	overflow: hidden;
	width: 280px !important;
}

@media screen and (max-width: 1366px) {
	.ant-layout-sider {
		width: 240px !important;
	}
}

.ant-layout-sider-collapsed {
	width: 80px !important;
}

@media screen and (max-width: 991px) {
	.ant-layout-sider-collapsed.oitis-mini-custom-sidebar {
		min-width: 0 !important;
		width: 0 !important;
	}
}

.ant-layout-sider.oitis-collapsed-sidebar {
	min-width: 0 !important;
	width: 0 !important;
}

@media screen and (max-width: 991px) {
	.ant-layout-sider {
		min-width: 0 !important;
		width: 0 !important;
	}
}

.oitis-layout-sider-dark {
	background-color: #038fde;
	color: #038fdd;
}

.oitis-layout-sider-header {
	-ms-align-items: center;
	align-items: center;
	box-shadow: 0 0 4px 4px rgba(0, 0, 0, 0.08);
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	height: 72px;
	padding: 10px 30px 10px 70px;
	position: relative;
	z-index: 1;
}

@media screen and (max-width: 575px) {
	.oitis-layout-sider-header {
		height: 50px;
	}
}

.oitis-layout-sider-header .oitis-site-logo {
	display: block;
}

.ant-layout-sider-collapsed .oitis-layout-sider-header .oitis-site-logo {
	display: none;
}

.oitis-mini-custom-sidebar .oitis-layout-sider-header .oitis-site-logo {
	display: block;
}

.oitis-layout-sider-header .oitis-linebar {
	left: 20px;
	position: absolute;
	top: 15px;
	transition: all 0.3s ease-out;
	z-index: 1;
}

.ant-layout-sider-collapsed .oitis-layout-sider-header .oitis-linebar {
	left: 20px;
}

@media screen and (max-width: 991px) {
	.oitis-layout-sider-header .oitis-linebar {
		display: none;
	}
}

.ant-layout-sider-collapsed .oitis-layout-sider-header {
	padding-left: 20px;
	padding-right: 20px;
}

.oitis-drawer-sidebar .oitis-layout-sider-header {
	padding-left: 30px;
}

.oitis-drawer-sidebar-dark .oitis-layout-sider-header {
	background-color: rgba(0, 0, 0, 0.3);
}

.oitis-layout-sider-dark .oitis-layout-sider-header {
	background-color: rgba(0, 0, 0, 0.3);
	box-shadow: none;
}

.oitis-mini-custom-sidebar .oitis-layout-sider-header {
	background-color: transparent;
	-ms-justify-content: center;
	justify-content: center;
}

.oitis-custom-sidebar .oitis-layout-sider-header {
	background-color: transparent;
	padding-left: 30px;
}

.oitis-custom-sidebar.ant-layout-sider-lite .oitis-layout-sider-header,
.oitis-mini-custom-sidebar.ant-layout-sider-lite .oitis-layout-sider-header {
	border-right: 1px solid #e8e8e8;
	box-shadow: none;
}

@media screen and (max-width: 991px) {
	.oitis-layout-sider-header {
		padding-left: 30px;
	}
}

.oitis-sidebar-content {
	border-right: 1px solid #e8e8e8;
}

.oitis-drawer-sidebar-dark .oitis-sidebar-content,
.oitis-layout-sider-dark .oitis-sidebar-content {
	border-right: 0;
}

.oitis-layout-sider-scrollbar {
	height: calc(100vh - 217px) !important;
}

.framed-layout .oitis-layout-sider-scrollbar {
	height: calc(100vh - 257px) !important;
}

@media screen and (max-width: 991px) {
	.framed-layout .oitis-layout-sider-scrollbar {
		height: calc(100vh - 72px) !important;
	}
}

.ant-layout-sider-collapsed .oitis-layout-sider-scrollbar {
	height: calc(100vh - 163px) !important;
}

.framed-layout .ant-layout-sider-collapsed .oitis-layout-sider-scrollbar {
	height: calc(100vh - 203px) !important;
}

@media screen and (max-width: 991px) {

	.ant-layout-sider-collapsed .oitis-layout-sider-scrollbar,
	.framed-layout .ant-layout-sider-collapsed .oitis-layout-sider-scrollbar {
		height: calc(100vh - 72px) !important;
	}
}

@media screen and (max-width: 575px) {
	.ant-layout-sider-collapsed .oitis-layout-sider-scrollbar {
		height: calc(100vh - 50px) !important;
	}
}

.oitis-mini-custom-sidebar .oitis-layout-sider-scrollbar {
	height: calc(100vh - 278px) !important;
}

.framed-layout .oitis-mini-custom-sidebar .oitis-layout-sider-scrollbar {
	height: calc(100vh - 318px) !important;
}

@media screen and (max-width: 991px) {

	.framed-layout .oitis-mini-custom-sidebar .oitis-layout-sider-scrollbar,
	.oitis-layout-sider-scrollbar {
		height: calc(100vh - 72px) !important;
	}
}

@media screen and (max-width: 575px) {
	.oitis-layout-sider-scrollbar {
		height: calc(100vh - 50px) !important;
	}
}

@media screen and (max-width: 991px) {
	.oitis-no-header-notifications+.oitis-layout-sider-scrollbar {
		height: calc(100vh - 217px) !important;
	}
}

@media screen and (max-width: 575px) {
	.oitis-no-header-notifications+.oitis-layout-sider-scrollbar {
		height: calc(100vh - 195px) !important;
	}
}

.oitis-sidebar-notifications {
	border-bottom: 1px solid #e8e8e8;
	margin: 0 20px 10px;
	padding: 30px 10px 10px;
}

.ant-layout-sider-dark .oitis-sidebar-notifications,
.oitis-drawer-sidebar-dark .oitis-sidebar-notifications {
	border-bottom-color: #038fdd;
}

@media screen and (max-width: 991px) {
	.oitis-sidebar-notifications {
		display: none;
	}

	.oitis-sidebar-notifications.oitis-no-header-notifications {
		display: block;
	}
}

.oitis-app-nav {
	-ms-align-items: center;
	align-items: center;
	color: #038fde;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	list-style: none;
	margin: 0 -20px;
	padding-left: 0;
}

.ant-layout-sider-dark .oitis-app-nav,
.oitis-drawer-sidebar-dark .oitis-app-nav {
	color: #038fdd;
}

.oitis-app-nav li {
	font-size: 20px;
	padding: 0 20px;
}

.oitis-app-nav li .icon {
	cursor: pointer;
}

.ant-layout-sider-collapsed .oitis-app-nav {
	display: none;
}

.oitis-mini-custom-sidebar .oitis-app-nav {
	display: block;
	margin-top: 15px;
}

.oitis-mini-custom-sidebar .oitis-app-nav li:not(:last-child) {
	margin-bottom: 5px;
}

.oitis-avatar-name {
	cursor: pointer;
}

.ant-layout-sider-collapsed .oitis-avatar-name {
	display: none;
}

.ant-layout-sider-collapsed .oitis-avatar-row {
	margin-bottom: 0 !important;
}

.oitis-avatar-row .ant-avatar {
	left: 0;
	position: relative;
	transition: all 0.3s ease-out;
}

.ant-layout-sider-collapsed .oitis-avatar-row .ant-avatar {
	left: -10px;
	margin: 0 !important;
}

.oitis-menu-group .ant-menu-item-group-title {
	font-size: 16px;
	padding-top: 30px !important;
}

.oitis-layouts-view .oitis-pointer {
	display: block;
	margin-bottom: 15px;
}

.oitis-layouts-view .oitis-pointer img {
	width: 100%;
}

.oitis-layouts-view .oitis-pointer.active {
	border-radius: 8px;
	box-shadow: 0 0 10px 0 #038fde;
}

.ant-menu {
	background: #fff;
	color: #545454;
}

.ant-menu-horizontal {
	background: none;
}

.ant-menu-item-group-title {
	color: #545454;
	font-size: 11px;
	line-height: 1.3;
	padding: 10px 30px;
}

.ant-menu-submenu-popup .ant-menu-item-group-title {
	padding-left: 20px;
}

.ant-layout-sider .ant-menu-item-group-title {
	padding-top: 40px;
}

.ant-menu-item:active,
.ant-menu-submenu-title:active {
	background: #e6faff;
}

.ant-menu-item .icon {
	display: inline-block;
	margin-right: 20px;
	min-width: 14px;
	vertical-align: middle;
}

.ant-menu-item .icon[class*=' icon-']:before,
.ant-menu-item .icon[class^='icon-']:before {
	position: relative;
	top: 2px;
}

.ant-menu-sub .ant-menu-item .icon {
	font-size: 12px;
}

.ant-menu-item>a {
	color: #545454;
}

.ant-menu-item>a:hover {
	color: #038fde;
}

.ant-menu-item-divider {
	background-color: #e8e8e8;
}

.ant-menu-submenu-inline {
	transition: all 0.3s ease-out;
}

.ant-menu-item-active,
.ant-menu-item:hover,
.ant-menu-submenu-active,
.ant-menu-submenu-title:hover,
.ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
	color: #038fde;
}

.ant-menu-horizontal>.ant-menu-item-active,
.ant-menu-horizontal>.ant-menu-item:hover,
.ant-menu-horizontal>.ant-menu-submenu .ant-menu-submenu-title:hover {
	background-color: transparent;
}

.ant-menu-item-selected {
	color: #038fde;
}

.ant-menu-item-selected>a {
	color: #545454;
}

.ant-menu-item-selected>a:hover {
	color: #038fde;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: #e6faff;
}

.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
	border-right: 1px solid #e8e8e8;
}

.ant-layout-sider .ant-menu-inline,
.ant-layout-sider .ant-menu-vertical,
.ant-layout-sider .ant-menu-vertical-left,
.oitis-drawer-sidebar .ant-menu-inline,
.oitis-drawer-sidebar .ant-menu-vertical,
.oitis-drawer-sidebar .ant-menu-vertical-left {
	border-right: 0;
}

.ant-menu-vertical-right {
	border-left: 1px solid #e8e8e8;
}

.ant-menu-item,
.ant-menu-submenu-title {
	padding: 0 20px;
}

.oitis-inside-header-horizontal .ant-menu-item,
.oitis-inside-header-horizontal .ant-menu-submenu-title {
	padding: 0 10px;
}

@media screen and (max-width: 1199px) {

	.oitis-inside-header-horizontal .ant-menu-item,
	.oitis-inside-header-horizontal .ant-menu-submenu-title {
		padding: 0 6px;
	}
}

.ant-menu>.ant-menu-item-divider {
	background-color: #e8e8e8;
}

.ant-menu-sub>li>a {
	position: relative;
}

.ant-menu-submenu>.ant-menu {
	background-color: #fff;
}

.ant-menu-submenu>.ant-menu.ant-menu-sub .ant-menu-item {
	padding-left: 54px !important;
}

.ant-drawer .ant-menu-submenu>.ant-menu.ant-menu-sub .ant-menu-item,
.ant-layout-sider .ant-menu-submenu>.ant-menu.ant-menu-sub .ant-menu-item {
	padding-left: 80px !important;
}

.ant-drawer .ant-menu-submenu>.ant-menu.ant-menu-sub .ant-menu-sub .ant-menu-item,
.ant-layout-sider .ant-menu-submenu>.ant-menu.ant-menu-sub .ant-menu-sub .ant-menu-item {
	padding-left: 106px !important;
}

.ant-menu-submenu-popup {
	z-index: 1050 !important;
}

.ant-menu-submenu-popup .ant-menu-sub.ant-menu .ant-menu-item {
	padding-left: 20px !important;
}

.ant-menu-submenu-inline .ant-menu-item:not(:last-child),
.ant-menu-submenu-vertical .ant-menu-item:not(:last-child),
.ant-menu-submenu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-submenu-vertical-right .ant-menu-item:not(:last-child) {
	margin-bottom: 0;
}

.ant-drawer .ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.oitis-app-sidebar .ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.oitis-app-sidebar .ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.oitis-app-sidebar .ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow,
.oitis-app-sidebar .ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow {
	left: 22px;
	right: auto;
}

.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-inline>.ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical>.ant-menu-submenu-title .ant-menu-submenu-arrow:before {
	background-image: linear-gradient(90deg, #545454, #545454);
}

.ant-drawer .ant-menu-submenu-inline .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-left .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-drawer .ant-menu-submenu-vertical-right .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.oitis-app-sidebar .ant-menu-submenu-inline .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.oitis-app-sidebar .ant-menu-submenu-vertical .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.oitis-app-sidebar .ant-menu-submenu-vertical-left .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.oitis-app-sidebar .ant-menu-submenu-vertical-right .ant-menu-submenu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow {
	left: 60px;
}

.ant-menu-submenu-inline>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-inline>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-left>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical-right>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.ant-menu-submenu-vertical>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.ant-menu-submenu-vertical>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
	background: linear-gradient(90deg, #038fde, #038fde);
}

.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu-vertical .ant-menu-submenu-selected>a,
.ant-menu-vertical-left .ant-menu-submenu-selected,
.ant-menu-vertical-left .ant-menu-submenu-selected>a,
.ant-menu-vertical-right .ant-menu-submenu-selected,
.ant-menu-vertical-right .ant-menu-submenu-selected>a {
	color: #038fde;
}

.ant-menu-horizontal {
	border-bottom: 1px solid #e8e8e8;
	line-height: 46px;
	padding: 0;
}

.ant-layout-header .ant-menu-horizontal {
	border-bottom: 0;
	line-height: 70px;
}

.ant-menu-horizontal>.ant-menu-item,
.ant-menu-horizontal>.ant-menu-submenu {
	border-bottom: 2px solid transparent;
}

.ant-menu-horizontal>.ant-menu-item-active,
.ant-menu-horizontal>.ant-menu-item-open,
.ant-menu-horizontal>.ant-menu-item-selected,
.ant-menu-horizontal>.ant-menu-item:hover,
.ant-menu-horizontal>.ant-menu-submenu-active,
.ant-menu-horizontal>.ant-menu-submenu-open,
.ant-menu-horizontal>.ant-menu-submenu-selected,
.ant-menu-horizontal>.ant-menu-submenu:hover {
	border-bottom: 2px solid #038fde;
	color: #038fde;
}

.ant-menu-horizontal>.ant-menu-item>a,
.ant-menu-horizontal>.ant-menu-submenu>a {
	color: #545454;
}

.ant-menu-horizontal>.ant-menu-item>a:hover,
.ant-menu-horizontal>.ant-menu-submenu>a:hover {
	color: #038fde;
}

.ant-menu-horizontal>.ant-menu-item>a:before,
.ant-menu-horizontal>.ant-menu-submenu>a:before {
	bottom: -2px;
}

.ant-menu-inline .ant-menu-item:not(:last-child),
.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child) {
	margin-bottom: 0;
}

.ant-menu-inline .ant-menu-item:after,
.ant-menu-vertical .ant-menu-item:after,
.ant-menu-vertical-left .ant-menu-item:after,
.ant-menu-vertical-right .ant-menu-item:after {
	border-right: 3px solid #038fde;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-item,
.ant-menu-vertical .ant-menu-submenu-title,
.ant-menu-vertical-left .ant-menu-item,
.ant-menu-vertical-left .ant-menu-submenu-title,
.ant-menu-vertical-right .ant-menu-item,
.ant-menu-vertical-right .ant-menu-submenu-title {
	font-size: 14px;
	margin-bottom: 0;
	margin-top: 0;
	padding: 0 30px 0 20px !important;
	text-overflow: inherit;
}

.ant-drawer .ant-menu-inline .ant-menu-item,
.ant-drawer .ant-menu-inline .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical .ant-menu-item,
.ant-drawer .ant-menu-vertical .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-left .ant-menu-item,
.ant-drawer .ant-menu-vertical-left .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-right .ant-menu-item,
.ant-drawer .ant-menu-vertical-right .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-inline .ant-menu-item,
.ant-layout-sider .ant-menu-inline .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical .ant-menu-item,
.ant-layout-sider .ant-menu-vertical .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-left .ant-menu-item,
.ant-layout-sider .ant-menu-vertical-left .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-right .ant-menu-item,
.ant-layout-sider .ant-menu-vertical-right .ant-menu-submenu-title {
	padding-left: 40px !important;
}

.ant-menu-inline .ant-menu-item .icon,
.ant-menu-inline .ant-menu-submenu-title .icon,
.ant-menu-vertical .ant-menu-item .icon,
.ant-menu-vertical .ant-menu-submenu-title .icon,
.ant-menu-vertical-left .ant-menu-item .icon,
.ant-menu-vertical-left .ant-menu-submenu-title .icon,
.ant-menu-vertical-right .ant-menu-item .icon,
.ant-menu-vertical-right .ant-menu-submenu-title .icon {
	margin-right: 20px;
	min-width: 14px;
}

.ant-menu-inline .ant-menu-item .icon[class*=' icon-']:before,
.ant-menu-inline .ant-menu-item .icon[class^='icon-']:before,
.ant-menu-inline .ant-menu-submenu-title .icon[class*=' icon-']:before,
.ant-menu-inline .ant-menu-submenu-title .icon[class^='icon-']:before,
.ant-menu-vertical .ant-menu-item .icon[class*=' icon-']:before,
.ant-menu-vertical .ant-menu-item .icon[class^='icon-']:before,
.ant-menu-vertical .ant-menu-submenu-title .icon[class*=' icon-']:before,
.ant-menu-vertical .ant-menu-submenu-title .icon[class^='icon-']:before,
.ant-menu-vertical-left .ant-menu-item .icon[class*=' icon-']:before,
.ant-menu-vertical-left .ant-menu-item .icon[class^='icon-']:before,
.ant-menu-vertical-left .ant-menu-submenu-title .icon[class*=' icon-']:before,
.ant-menu-vertical-left .ant-menu-submenu-title .icon[class^='icon-']:before,
.ant-menu-vertical-right .ant-menu-item .icon[class*=' icon-']:before,
.ant-menu-vertical-right .ant-menu-item .icon[class^='icon-']:before,
.ant-menu-vertical-right .ant-menu-submenu-title .icon[class*=' icon-']:before,
.ant-menu-vertical-right .ant-menu-submenu-title .icon[class^='icon-']:before {
	position: relative;
	top: 2px;
}

.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-item .icon+span,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-submenu-title .icon+span,
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-item .icon+span,
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-submenu-title .icon+span,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-item .icon+span,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-submenu-title .icon+span,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-item .icon+span,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-submenu-title .icon+span {
	display: inline-block;
	max-width: 0;
	opacity: 0;
}

.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-item .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-item .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-item .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-submenu-title .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-item .ant-menu-submenu-arrow,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-submenu-title .ant-menu-submenu-arrow {
	display: none;
}

.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-item,
.ant-layout-sider-collapsed .ant-menu-inline .ant-menu-submenu-title,
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-item,
.ant-layout-sider-collapsed .ant-menu-vertical .ant-menu-submenu-title,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-item,
.ant-layout-sider-collapsed .ant-menu-vertical-left .ant-menu-submenu-title,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-item,
.ant-layout-sider-collapsed .ant-menu-vertical-right .ant-menu-submenu-title {
	padding: 0 64px/2 !important;
}

.ant-card .ant-menu-inline.ant-menu-sub .ant-menu-submenu-title,
.ant-card .ant-menu-vertical-left.ant-menu-sub .ant-menu-submenu-title,
.ant-card .ant-menu-vertical-right.ant-menu-sub .ant-menu-submenu-title,
.ant-card .ant-menu-vertical.ant-menu-sub .ant-menu-submenu-title {
	padding-left: 54px !important;
}

.ant-drawer .ant-menu-inline.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-left.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical-right.ant-menu-sub .ant-menu-submenu-title,
.ant-drawer .ant-menu-vertical.ant-menu-sub .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-inline.ant-menu-sub .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-left.ant-menu-sub .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical-right.ant-menu-sub .ant-menu-submenu-title,
.ant-layout-sider .ant-menu-vertical.ant-menu-sub .ant-menu-submenu-title {
	padding-left: 80px !important;
}

.ant-menu-inline .ant-menu-submenu-title {
	padding-right: 30px !important;
}

.ant-menu-inline-collapsed {
	width: 80px;
}

.ant-menu-inline-collapsed>.ant-menu-item,
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item,
.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
	left: 0;
	padding: 0 64px/2 !important;
}

.ant-menu-inline-collapsed>.ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .ant-menu-submenu-arrow,
.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .ant-menu-submenu-arrow {
	display: none;
}

.ant-menu-inline-collapsed>.ant-menu-item .icon+span,
.ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item .icon+span,
.ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title .icon+span {
	display: inline-block;
	max-width: 0;
	opacity: 0;
}

.ant-menu-inline-collapsed-tooltip .icon {
	display: none;
}

.ant-menu-inline-collapsed-tooltip a {
	color: hsla(0, 0%, 100%, 0.85);
}

.ant-menu-inline-collapsed .ant-menu-item-group-title {
	display: none;
	padding-left: 4px;
	padding-right: 4px;
}

.ant-menu-item-group-list .ant-menu-item,
.ant-menu-item-group-list .ant-menu-submenu-title {
	padding: 0 16px 0 28px;
}

.ant-menu-sub.ant-menu-inline>.ant-menu-item,
.ant-menu-sub.ant-menu-inline>.ant-menu-submenu>.ant-menu-submenu-title {
	height: 42px;
	line-height: 42px;
}

.ant-menu-sub.ant-menu-inline .ant-menu-item-group-title {
	height: 42px;
	line-height: 42px;
	padding: 0 30px 0 80px;
	text-transform: uppercase;
}

.ant-menu-item-disabled,
.ant-menu-item-disabled>.ant-menu-submenu-title,
.ant-menu-item-disabled>a,
.ant-menu-submenu-disabled,
.ant-menu-submenu-disabled>.ant-menu-submenu-title,
.ant-menu-submenu-disabled>a {
	color: rgba(0, 0, 0, 0.25) !important;
}

.ant-menu-submenu-selected .ant-menu-submenu-title {
	color: #038fde;
}

.ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
	background: linear-gradient(90deg, #038fde, #038fde);
}

.ant-menu-dark,
.ant-menu-dark .ant-menu-sub {
	background: #038fde;
	color: #038fdd;
}

.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-sub .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title .ant-menu-submenu-arrow:before {
	background: #038fdd;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
	background: none;
	box-shadow: none;
}

.ant-menu-dark.ant-menu-horizontal {
	border-bottom-color: #038fde;
}

.ant-menu-dark.ant-menu-horizontal>.ant-menu-item,
.ant-menu-dark.ant-menu-horizontal>.ant-menu-submenu {
	border-color: #038fde;
}

.ant-menu-dark .ant-menu-item .icon {
	margin-right: 20px;
	min-width: 14px;
}

.ant-menu-dark .ant-menu-item .icon[class*=' icon-']:before,
.ant-menu-dark .ant-menu-item .icon[class^='icon-']:before {
	position: relative;
	top: 2px;
}

.ant-menu-dark .ant-menu-item,
.ant-menu-dark .ant-menu-item-group-title,
.ant-menu-dark .ant-menu-item>a {
	color: #038fdd;
}

.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item>a {
	color: #fff;
}

.ant-menu-dark .ant-menu-item-active,
.ant-menu-dark .ant-menu-item-active>a,
.ant-menu-dark .ant-menu-item:hover,
.ant-menu-dark .ant-menu-item:hover>a,
.ant-menu-dark .ant-menu-submenu-active,
.ant-menu-dark .ant-menu-submenu-active>a,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item>a:hover,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item>a:hover>a,
.ant-menu-dark .ant-menu-submenu-selected,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-selected .ant-menu-item-selected>a,
.ant-menu-dark .ant-menu-submenu-selected>a,
.ant-menu-dark .ant-menu-submenu-title:hover,
.ant-menu-dark .ant-menu-submenu-title:hover>a {
	color: #fa8c15;
}

.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item>a:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item>a:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before {
	background: #fff;
}

.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item>a:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item>a:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before {
	background: #fa8c15;
}

.ant-menu-dark .ant-menu-submenu-open,
.ant-menu-dark.ant-menu-inline-collapsed .ant-menu-submenu-active .ant-menu-submenu-title span,
.ant-menu-dark.ant-menu-inline-collapsed .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open .ant-menu-submenu-title span {
	color: #fff;
}

.ant-menu-dark .ant-menu-item-selected,
.ant-menu-dark .ant-menu-item-selected>a,
.ant-menu-dark .ant-menu-item-selected>a:hover {
	color: #fa8c15;
}

.ant-menu-dark .ant-menu-item-selected span {
	color: inherit;
}

.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-selected,
.ant-menu.ant-menu-dark .ant-menu-item-selected {
	background-color: transparent;
}

.ant-menu-dark .ant-menu-item-disabled,
.ant-menu-dark .ant-menu-item-disabled>.ant-menu-submenu-title,
.ant-menu-dark .ant-menu-item-disabled>a,
.ant-menu-dark .ant-menu-submenu-disabled,
.ant-menu-dark .ant-menu-submenu-disabled>.ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-disabled>a {
	color: hsla(0, 0%, 100%, 0.35) !important;
}

.ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-title {
	color: #038fdd;
}

.ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-open .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-selected .ant-menu-submenu-title,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-title {
	color: #fff;
}

.ant-menu-dark .ant-menu-submenu-selected .ant-menu-sub .ant-menu-submenu-open .ant-menu-submenu-title:hover,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-selected .ant-menu-submenu-title:hover,
.ant-menu-dark .ant-menu-submenu-selected .ant-menu-submenu-title:hover {
	color: #fa8c15;
}

.ant-menu-submenu-horizontal>.ant-menu-submenu-title {
	color: #262626;
}

.ant-menu-dark .ant-menu-submenu-open>.ant-menu-submenu-title>.ant-menu-submenu-arrow:after,
.ant-menu-dark .ant-menu-submenu-open>.ant-menu-submenu-title>.ant-menu-submenu-arrow:before {
	background: #fff;
}

.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-vertical:hover>.ant-menu-submenu-title {
	color: #fff;
}

.ant-drawer .ant-menu .ant-menu-item .icon,
.ant-drawer .ant-menu .ant-menu-submenu .ant-menu-submenu-title .icon,
.ant-layout-sider .ant-menu .ant-menu-item .icon,
.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-submenu-title .icon {
	font-size: 20px;
}

.ant-drawer .ant-menu .ant-menu-submenu .ant-menu-item .icon,
.ant-drawer .ant-menu .ant-menu-submenu .ant-menu-submenu .ant-menu-submenu-title .icon,
.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-item .icon,
.ant-layout-sider .ant-menu .ant-menu-submenu .ant-menu-submenu .ant-menu-submenu-title .icon {
	font-size: 14px;
	margin-right: 12px;
}

.ant-drawer .ant-menu .ant-menu-submenu-title span,
.ant-layout-sider .ant-menu .ant-menu-submenu-title span {
	display: flex;
}

.ant-menu-submenu-popup.ant-menu-light .ant-menu-submenu-open>.ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.ant-menu-submenu-popup.ant-menu-light .ant-menu-submenu-open>.ant-menu-submenu-title .ant-menu-submenu-arrow:before {
	background-image: linear-gradient(90deg, #038fde, #038fde);
}

.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-item:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-active>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item>a:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-open .ant-menu-submenu-open .ant-menu-item>a:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-selected>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:after,
.ant-menu-submenu-popup.ant-menu-dark .ant-menu-submenu-title:hover>.ant-menu-submenu-title:hover>.ant-menu-submenu-arrow:before {
	background: #fff;
}

.oitis-navbar-nav {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	list-style: none;
	margin: 0 -5px;
	padding-left: 0;
}

.oitis-navbar-nav li {
	padding: 0 5px;
}

.oitis-navbar-nav li a {
	background-color: #038fde;
	border-radius: 4px;
	color: #fff;
	display: block;
	padding: 4px 10px;
}

.oitis-profile-banner .oitis-navbar-nav li a {
	background-color: transparent;
	border-radius: 0;
	color: #fff;
	padding: 0;
}

.oitis-navbar-nav li a * {
	color: #bfbfbf;
}

.oitis-navbar-nav li a:focus,
.oitis-navbar-nav li a:hover {
	background-color: #026fac;
	color: #fff;
}

.oitis-profile-banner .oitis-navbar-nav li a:focus,
.oitis-profile-banner .oitis-navbar-nav li a:hover {
	background-color: transparent;
	color: #fa8c15;
}

.oitis-profile-banner .oitis-navbar-nav li {
	padding: 0 15px;
}

@media screen and (max-width: 575px) {
	.oitis-profile-banner .oitis-navbar-nav li {
		padding: 0 6px;
	}
}

.oitis-profile-banner .oitis-navbar-nav {
	margin: 0 -15px;
}

@media screen and (max-width: 575px) {
	.oitis-profile-banner .oitis-navbar-nav {
		-ms-justify-content: center;
		justify-content: center;
		margin: 0 -6px;
	}
}

.oitis-menu-horizontal {
	border-radius: 10px;
}

.oitis-menu-horizontal:before {
	display: none;
}

.oitis-header-horizontal-nav .ant-menu-horizontal {
	border-bottom: 0;
}

.oitis-header-horizontal-nav .ant-menu-submenu-horizontal>.ant-menu-submenu-title {
	color: #fff;
}

.oitis-above-header-horizontal .oitis-header-horizontal-nav .ant-menu-submenu-horizontal>.ant-menu-submenu-title,
.oitis-below-header-horizontal .oitis-header-horizontal-nav .ant-menu-submenu-horizontal>.ant-menu-submenu-title {
	color: #7a7a7a;
}

.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item,
.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu {
	border-bottom: 0;
	margin-top: 0;
	top: 0;
}

.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item-active,
.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item-open,
.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item-selected,
.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item:hover,
.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu-active,
.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu-open,
.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu-selected,
.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu:hover {
	border-bottom: 0;
	position: relative;
}

.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item-active>.ant-menu-submenu-title,
.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item-open>.ant-menu-submenu-title,
.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item-selected>.ant-menu-submenu-title,
.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item:hover>.ant-menu-submenu-title,
.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu-active>.ant-menu-submenu-title,
.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu-open>.ant-menu-submenu-title,
.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu-selected>.ant-menu-submenu-title,
.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu:hover>.ant-menu-submenu-title {
	position: relative;
}

.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item-active>.ant-menu-submenu-title:before,
.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item-open>.ant-menu-submenu-title:before,
.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item-selected>.ant-menu-submenu-title:before,
.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item:hover>.ant-menu-submenu-title:before,
.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu-active>.ant-menu-submenu-title:before,
.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu-open>.ant-menu-submenu-title:before,
.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu-selected>.ant-menu-submenu-title:before,
.oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu:hover>.ant-menu-submenu-title:before {
	bottom: 0;
	color: #f5f5f5;
	content: '\e049';
	font-family: gaxon !important;
	font-size: 31px;
	left: 0;
	line-height: 5px;
	position: absolute;
	right: 0;
	text-align: center;
	z-index: 1;
}

.oitis-below-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item-active>.ant-menu-submenu-title:before,
.oitis-below-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item-open>.ant-menu-submenu-title:before,
.oitis-below-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item-selected>.ant-menu-submenu-title:before,
.oitis-below-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item:hover>.ant-menu-submenu-title:before,
.oitis-below-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu-active>.ant-menu-submenu-title:before,
.oitis-below-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu-open>.ant-menu-submenu-title:before,
.oitis-below-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu-selected>.ant-menu-submenu-title:before,
.oitis-below-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu:hover>.ant-menu-submenu-title:before,
.oitis-inside-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item-active>.ant-menu-submenu-title:before,
.oitis-inside-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item-open>.ant-menu-submenu-title:before,
.oitis-inside-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item-selected>.ant-menu-submenu-title:before,
.oitis-inside-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item:hover>.ant-menu-submenu-title:before,
.oitis-inside-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu-active>.ant-menu-submenu-title:before,
.oitis-inside-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu-open>.ant-menu-submenu-title:before,
.oitis-inside-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu-selected>.ant-menu-submenu-title:before,
.oitis-inside-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu:hover>.ant-menu-submenu-title:before {
	color: #f5f5f5;
}

.oitis-above-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item-active>.ant-menu-submenu-title:before,
.oitis-above-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item-open>.ant-menu-submenu-title:before,
.oitis-above-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item-selected>.ant-menu-submenu-title:before,
.oitis-above-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item:hover>.ant-menu-submenu-title:before,
.oitis-above-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu-active>.ant-menu-submenu-title:before,
.oitis-above-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu-open>.ant-menu-submenu-title:before,
.oitis-above-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu-selected>.ant-menu-submenu-title:before,
.oitis-above-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu:hover>.ant-menu-submenu-title:before {
	color: #038fde;
}

.oitis-above-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item-active>.ant-menu-submenu-title:before,
.oitis-above-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item-open>.ant-menu-submenu-title:before,
.oitis-above-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu-active>.ant-menu-submenu-title:before,
.oitis-above-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu-open>.ant-menu-submenu-title:before,
.oitis-above-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu:hover>.ant-menu-submenu-title:before,
.oitis-below-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item-active>.ant-menu-submenu-title:before,
.oitis-below-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item-open>.ant-menu-submenu-title:before,
.oitis-below-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu-active>.ant-menu-submenu-title:before,
.oitis-below-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu-open>.ant-menu-submenu-title:before,
.oitis-below-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu:hover>.ant-menu-submenu-title:before {
	color: #fa8c15;
}

.oitis-inside-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item-active>.ant-menu-submenu-title:before,
.oitis-inside-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-item-open>.ant-menu-submenu-title:before,
.oitis-inside-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu-active>.ant-menu-submenu-title:before,
.oitis-inside-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu-open>.ant-menu-submenu-title:before,
.oitis-inside-header-horizontal .oitis-header-horizontal-nav-curve .ant-menu-horizontal>.ant-menu-submenu:hover>.ant-menu-submenu-title:before {
	color: #fff;
}

.oitis-submenu-popup-curve.ant-menu-submenu-popup.ant-menu-submenu>.ant-menu {
	border-radius: 10px;
	margin-top: -7px;
}

.oitis-submenu-popup-curve.ant-menu-submenu-popup.ant-menu-submenu>.ant-menu .ant-menu-item:first-child {
	border-radius: 10px 10px 0 0;
}

.oitis-submenu-popup-curve.ant-menu-submenu-popup.ant-menu-submenu>.ant-menu .ant-menu-item:last-child {
	border-radius: 0 0 10px 10px;
}

.oitis-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu,
.oitis-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu>.ant-menu {
	border-radius: 10px;
}

.oitis-submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: #fa8c15;
	color: #038fde;
}

.oitis-submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected>a {
	color: #038fde;
}

.oitis-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-selected .ant-menu-submenu-title {
	color: #fa8c15;
}

.oitis-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-inline>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.oitis-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-inline>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.oitis-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-open>.ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.oitis-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-open>.ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.oitis-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow:after,
.oitis-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-selected .ant-menu-submenu-title .ant-menu-submenu-arrow:before,
.oitis-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical-left>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.oitis-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical-left>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.oitis-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical-right>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.oitis-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical-right>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before,
.oitis-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow:after,
.oitis-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-vertical>.ant-menu-submenu-title:hover .ant-menu-submenu-arrow:before {
	background-image: linear-gradient(90deg, #fa8c15, #fa8c15);
}

.oitis-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-item-active,
.oitis-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-item:hover,
.oitis-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-item>a:hover,
.oitis-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-active,
.oitis-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu-title:hover,
.oitis-submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open {
	color: #fa8c15;
}

.oitis-submenu-popup-curve.ant-menu-submenu-popup.oitis-above-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.oitis-submenu-popup-curve.ant-menu-submenu-popup.oitis-below-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.oitis-submenu-popup-curve.ant-menu-submenu-popup.oitis-inside-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: transparent;
	color: #fa8c15;
}

.oitis-submenu-popup-curve.ant-menu-submenu-popup.oitis-above-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected>a,
.oitis-submenu-popup-curve.ant-menu-submenu-popup.oitis-below-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected>a,
.oitis-submenu-popup-curve.ant-menu-submenu-popup.oitis-inside-submenu-popup-curve .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected>a {
	color: #fa8c15;
}

.oitis-mini-custom-sidebar .ant-menu-inline-collapsed {
	padding-bottom: 10px;
}

.oitis-mini-custom-sidebar .ant-menu-inline-collapsed>.ant-menu-item,
.oitis-mini-custom-sidebar .ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item,
.oitis-mini-custom-sidebar .ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title {
	padding: 0 20px !important;
}

.oitis-mini-custom-sidebar .ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item>span,
.oitis-mini-custom-sidebar .ant-menu-inline-collapsed>.ant-menu-item>span,
.oitis-mini-custom-sidebar .ant-menu-inline-collapsed>.ant-menu-submenu>.ant-menu-submenu-title>span {
	-ms-justify-content: center;
	justify-content: center;
}

.oitis-mini-custom-sidebar .ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item>a,
.oitis-mini-custom-sidebar .ant-menu-inline-collapsed>.ant-menu-item>a {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	-ms-justify-content: center;
	justify-content: center;
}

.oitis-mini-custom-sidebar .ant-menu-inline-collapsed>.ant-menu-item-group>.ant-menu-item-group-list>.ant-menu-item.ant-menu-item-selected>a,
.oitis-mini-custom-sidebar .ant-menu-inline-collapsed>.ant-menu-item.ant-menu-item-selected>a,
.oitis-mini-custom-sidebar .ant-menu-inline-collapsed>.ant-menu-submenu.ant-menu-submenu-selected>.ant-menu-submenu-title>span {
	background-color: #fa8c15;
	border-radius: 2px;
	color: #038fde;
}

.oitis-mini-custom-sidebar .ant-menu-inline .ant-menu-item .icon,
.oitis-mini-custom-sidebar .ant-menu-inline .ant-menu-submenu-title .icon,
.oitis-mini-custom-sidebar .ant-menu-vertical .ant-menu-item .icon,
.oitis-mini-custom-sidebar .ant-menu-vertical .ant-menu-submenu-title .icon,
.oitis-mini-custom-sidebar .ant-menu-vertical-left .ant-menu-item .icon,
.oitis-mini-custom-sidebar .ant-menu-vertical-left .ant-menu-submenu-title .icon,
.oitis-mini-custom-sidebar .ant-menu-vertical-right .ant-menu-item .icon,
.oitis-mini-custom-sidebar .ant-menu-vertical-right .ant-menu-submenu-title .icon {
	margin-right: 0;
}

.oitis-no-header-submenu-popup .ant-menu-sub {
	border-radius: 4px;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: transparent;
}

.ant-layout-footer {
	border-top: 1px solid #e8e8e8;
}

@media screen and (max-width: 575px) {
	.ant-layout-footer {
		padding: 14px 15px;
	}
}

.oitis-container-wrap .ant-layout-footer {
	padding-left: 0;
	padding-right: 0;
}

.oitis-container-wrap .oitis-layout-footer-content {
	margin-left: auto;
	margin-right: auto;
	padding-left: 16px;
	padding-right: 16px;
	width: 94vw;
}

@media screen and (min-width: 768px) {
	.oitis-container-wrap .oitis-layout-footer-content {
		width: 720px;
	}
}

@media screen and (min-width: 992px) {
	.oitis-container-wrap .oitis-layout-footer-content {
		width: 920px;
	}
}

@media screen and (min-width: 1200px) {
	.oitis-container-wrap .oitis-layout-footer-content {
		width: 1100px;
	}
}

@media screen and (min-width: 1367px) {
	.oitis-container-wrap .oitis-layout-footer-content {
		width: 1200px;
	}
}

@media screen and (min-width: 1600px) {
	.oitis-container-wrap .oitis-layout-footer-content {
		width: 1400px;
	}
}

.oitis-customizer {
	height: calc(100vh - 30px) !important;
}

.oitis-customizer-item:not(:last-child) {
	border-bottom: 1px solid #e8e8e8;
	margin-bottom: 20px;
	padding-bottom: 20px;
}

.oitis-customizer-item .ant-radio-group .ant-radio-button {
	display: none;
}

.oitis-customizer-option {
	position: absolute;
	right: 0;
	top: 150px;
	z-index: 99;
}

.full-scroll .oitis-customizer-option {
	position: fixed;
}

.full-scroll.boxed-layout .oitis-customizer-option {
	position: absolute;
}

.full-scroll.framed-layout .oitis-customizer-option {
	right: 20px;
}

@media screen and (max-width: 991px) {
	.full-scroll.framed-layout .oitis-customizer-option {
		right: 0;
	}
}

.horizontal-layout.full-scroll .oitis-customizer-option {
	top: 200px;
}

@media screen and (max-width: 575px) {
	.oitis-customizer {
		width: 250px !important;
	}

	.oitis-customizer .ant-radio-button-wrapper {
		padding: 0 10px;
	}
}

.oitis-customizer .oitis-list-inline {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.framed-layout .oitis-customizer {
	height: calc(100vh - 70px) !important;
}

@media screen and (max-width: 991px) {
	.framed-layout .oitis-customizer {
		height: calc(100vh - 30px) !important;
	}
}

.oitis-color-option {
	list-style: none;
	margin-bottom: 0;
	padding-left: 0;
}

.oitis-color-option li {
	font-size: 36px;
	line-height: 1;
}

.oitis-color-option li .oitis-link {
	border-radius: 50%;
	display: block;
	height: 40px;
	overflow: hidden;
	position: relative;
	width: 40px;
}

.oitis-color-option li .oitis-link:before {
	content: '\30';
	display: none;
	font-family: gaxon;
	font-size: 20px;
	height: 40px;
	left: 0;
	line-height: 40px;
	position: absolute;
	text-align: center;
	top: 0;
	width: 40px;
	z-index: 2;
}

.oitis-color-option li .oitis-link.active:before {
	display: block;
}

.oitis-color-option li .oitis-link:after {
	background-color: #9283d4;
	content: '';
	height: 40px;
	left: 0;
	position: absolute;
	top: 0;
	width: 20px;
	z-index: 1;
}

.oitis-color-option li .oitis-link.oitis-color-red:after {
	background-color: #ff2b7a;
}

.oitis-color-option li .oitis-link.oitis-color-blue:after {
	background-color: #3da4e6;
}

.oitis-color-option li .oitis-link.oitis-color-dark-blue:after {
	background-color: #0469b9;
}

.oitis-color-option li .oitis-link.oitis-color-orange:after {
	background-color: #f18805;
}

.oitis-color-option li .oitis-link.oitis-color-light-blue:after {
	background-color: #6a95ff;
}

.oitis-color-option li .oitis-link.oitis-color-deep-orange:after {
	background-color: #f87060;
}

.oitis-color-option li .oitis-link.oitis-color-light-purple1:after {
	background-color: #a172e7;
}

.oitis-color-option li .oitis-link.oitis-color-light-purple2:after {
	background-color: #956fe7;
}

.oitis-cus-customiz {
	padding-right: 20px;
}

.oitis-layout-option li,
.oitis-nav-option li {
	margin-bottom: 10px;
}

.oitis-layout-option li span,
.oitis-nav-option li span {
	display: block;
	position: relative;
}

.oitis-layout-option li span:before,
.oitis-nav-option li span:before {
	background-color: #038fde;
	border-radius: 50%;
	color: #fff;
	content: '\30';
	display: none;
	font-family: gaxon !important;
	font-size: 12px;
	height: 20px;
	left: 50%;
	padding: 2px;
	position: absolute;
	text-align: center;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	width: 20px;
	z-index: 1;
}

.oitis-layout-option li span.active:before,
.oitis-nav-option li span.active:before {
	display: block;
}

.ant-drawer {
	overflow: hidden;
}

.ant-drawer-body {
	padding: 0;
}

.oitis-drawer-sidebar-dark .ant-drawer-content {
	background-color: #038fde;
	color: #038fdd;
}

.ant-drawer-content-wrapper {
	width: 280px !important;
}

@media screen and (max-width: 991px) {
	.ant-drawer-content-wrapper {
		width: 260px !important;
	}
}

.framed-layout .ant-drawer-content-wrapper {
	border-radius: 11px 0 0 11px;
	bottom: 20px;
	height: calc(100vh - 40px);
	overflow: hidden;
	top: 20px;
}

@media screen and (max-width: 991px) {
	.framed-layout .ant-drawer-content-wrapper {
		border-radius: 0 !important;
		bottom: 0;
		height: 100vh;
		top: 0;
	}
}

.ant-drawer-right .ant-drawer-content-wrapper {
	background: #fff;
	padding: 15px;
	width: 370px !important;
}

@media screen and (max-width: 991px) {
	.ant-drawer-right .ant-drawer-content-wrapper {
		width: 300px !important;
	}
}

@media screen and (max-width: 575px) {
	.ant-drawer-right .ant-drawer-content-wrapper {
		width: 280px !important;
	}
}

.framed-layout .ant-drawer-right .ant-drawer-content-wrapper {
	border-radius: 0 11px 11px 0;
	bottom: 20px;
	height: calc(100vh - 40px);
	overflow: hidden;
	top: 20px;
}

@media screen and (max-width: 991px) {
	.framed-layout .ant-drawer-right .ant-drawer-content-wrapper {
		border-radius: 0 !important;
		bottom: 0;
		height: 100vh;
		top: 0;
	}
}

.boxed-layout .ant-drawer-content-wrapper,
.framed-layout .ant-drawer-content-wrapper {
	position: absolute;
}

.boxed-layout .ant-drawer {
	margin: 0 auto;
	max-width: 1280px;
}

@media screen and (min-width: 1600px) {
	.boxed-layout .ant-drawer {
		max-width: 1500px;
	}
}

.boxed-layout .ant-drawer-open.ant-drawer-left,
.boxed-layout .ant-drawer-open.ant-drawer-right,
.framed-layout .ant-drawer-open.ant-drawer-left,
.framed-layout .ant-drawer-open.ant-drawer-right {
	left: 0;
	margin: 0 auto;
	position: absolute;
	right: 0;
}

.framed-layout .ant-drawer-open {
	width: calc(100% - 40px);
}

@media screen and (max-width: 991px) {
	.framed-layout .ant-drawer-open {
		width: 100%;
	}
}

@media screen and (min-width: 1600px) {

	.boxed-layout .ant-drawer.ant-drawer-left,
	.boxed-layout .ant-drawer.ant-drawer-right {
		position: absolute;
	}
}

@media screen and (min-width: 991px) {

	.framed-layout .ant-drawer.ant-drawer-left,
	.framed-layout .ant-drawer.ant-drawer-right {
		position: absolute;
	}
}

@media screen and (max-width: 991px) {

	.framed-layout .ant-drawer.ant-drawer-left,
	.framed-layout .ant-drawer.ant-drawer-right {
		left: 20px;
		margin: 0;
		right: 20px;
	}
}

.ant-drawer-close {
	top: -11px;
}

.ant-drawer-close-x {
	height: 35px;
	line-height: 35px;
	width: 35px;
}

.ant-drawer-mask {
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
}

.ant-alert {
	margin-bottom: 16px;
}

.oitis-custom-sweet-alert .text-muted,
.oitis-custom-sweet-alert h2 {
	color: #fff !important;
}

.notification {
	border-radius: 4px;
}

.notification .title {
	color: #fff;
	font-size: 18px;
	font-weight: 500;
}

.oitis-sweet-alert-top-space {
	padding-top: 40px !important;
}

.sweet-alert h2 {
	font-size: 20px !important;
	font-weight: 400 !important;
}

.oitis-avatar-img {
	border: 1px solid #e8e8e8;
	border-radius: 50%;
	height: 50px;
	position: relative;
	width: 50px;
}

.oitis-avatar-img-lg {
	height: 100px;
	width: 100px;
}

.ant-avatar {
	position: relative;
}

.ant-avatar.oitis-size-150>* {
	line-height: 150px;
}

.ant-avatar.oitis-size-120>* {
	line-height: 120px;
}

.ant-avatar.oitis-size-100>* {
	line-height: 100px;
}

.ant-avatar.oitis-size-80>* {
	line-height: 80px;
}

.ant-avatar.oitis-size-60>* {
	line-height: 60px;
}

.ant-avatar.oitis-size-50>* {
	line-height: 50px;
}

.ant-avatar.oitis-size-40>* {
	line-height: 40px;
}

.ant-avatar.oitis-size-36>* {
	line-height: 36px;
}

.ant-avatar.oitis-size-30>* {
	line-height: 30px;
}

.ant-avatar.oitis-size-24>* {
	line-height: 24px;
}

.ant-avatar.oitis-size-20>* {
	line-height: 20px;
}

.ant-avatar.oitis-size-15>* {
	line-height: 14px;
}

.oitis-customers-list .ant-avatar {
	height: 70px;
	width: 70px;
}

@media (max-width: 1500px) {
	.oitis-customers-list .ant-avatar {
		height: 60px;
		width: 60px;
	}
}

@media (max-width: 1366px) {
	.oitis-customers-list .ant-avatar {
		height: 48px;
		width: 48px;
	}
}

@media (max-width: 1199px) {
	.oitis-customers-list .ant-avatar {
		height: 60px;
		width: 60px;
	}
}

@media screen and (max-width: 400px) {
	.oitis-customers-list .ant-avatar {
		height: 52px;
		width: 52px;
	}
}

.oitis-avatar {
	-ms-align-items: center;
	align-items: center;
	border: 1px solid #e8e8e8;
	border-radius: 50%;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	height: 50px;
	-ms-justify-content: center;
	justify-content: center;
	overflow: hidden;
	position: relative;
	width: 50px;
}

@media screen and (max-width: 991px) {
	.oitis-user-nav .oitis-avatar {
		height: 40px;
		line-height: 40px;
		width: 40px;
	}
}

@media screen and (max-width: 575px) {
	.oitis-user-nav .oitis-avatar {
		height: 30px;
		line-height: 30px;
		width: 30px;
	}
}

.oitis-header-horizontal-main .oitis-avatar {
	height: 40px;
	line-height: 40px;
	width: 40px;
}

@media screen and (max-width: 991px) {
	.oitis-header-horizontal-main .oitis-avatar {
		height: 30px;
		line-height: 30px;
		width: 30px;
	}
}

.oitis-user-thumb {
	position: relative;
}

.ant-badge {
	margin-bottom: 6px;
}

.ant-badge:not(:last-child) {
	margin-right: 12px;
}

.ant-tag {
	margin-bottom: 8px;
}

.oitis-badge {
	border-radius: 4px;
	display: inline-block;
	font-size: 75%;
	line-height: 1;
	margin-bottom: 6px;
	padding: 5px 8px 4px;
	text-align: center;
	vertical-align: baseline;
	white-space: nowrap;
}

.oitis-badge:not(:last-child) {
	margin-right: 12px;
}

.oitis-chat-date .oitis-badge {
	height: 20px;
	line-height: 16px;
	margin-bottom: 0;
	padding: 2px;
	text-align: center;
	width: 20px;
}

.oitis-user-thumb .oitis-badge {
	display: block;
	height: 16px;
	left: -4px;
	line-height: 16px;
	margin: 0;
	padding: 0;
	position: absolute;
	top: -4px;
	width: 16px;
	z-index: 1;
}

.oitis-badge-outline {
	background-color: transparent;
	border: 1px solid #e8e8e8;
}

.oitis-badge-radius-sm .ant-badge-count {
	border-radius: 2px;
}

#components-back-top-demo-custom .ant-back-top {
	bottom: 100px;
}

#components-back-top-demo-custom .ant-back-top-inner {
	background-color: #1088e9;
	border-radius: 4px;
	color: #fff;
	font-size: 20px;
	height: 40px;
	line-height: 40px;
	text-align: center;
	width: 40px;
}

.ant-btn {
	display: inline-block;
	line-height: 34px;
	vertical-align: middle;
}

.ant-btn-group-sm .ant-btn {
	line-height: 24px;
}

.ant-btn-group-lg .ant-btn {
	line-height: 40px;
}

.ant-btn-sm {
	padding-bottom: 0;
	padding-top: 0;
}

.ant-btn-group>.ant-btn-sm,
.ant-btn-sm {
	line-height: 24px;
}

.ant-btn-sm.ant-btn-icon-only {
	line-height: 1;
}

.ant-btn-lg {
	padding-bottom: 0;
	padding-top: 0;
}

.ant-btn-group>.ant-btn-lg,
.ant-btn-lg {
	line-height: 40px;
}

.ant-btn-group-lg>.ant-btn,
.ant-btn-group-lg>span>.ant-btn,
.ant-btn-lg.ant-btn-icon-only {
	padding-bottom: 0;
	padding-top: 0;
}

.ant-btn:not(:last-child) {
	margin-right: 15px;
}

@media screen and (max-width: 575px) {
	.ant-btn:not(:last-child) {
		margin-right: 10px;
	}
}

.ant-btn-group .ant-btn:not(:last-child),
.ant-transfer-operation .ant-btn:not(:last-child) {
	margin-right: 0;
}

@media (max-width: 1599px) {
	.oitis-product-footer .ant-btn:not(:last-child) {
		margin-right: 6px;
	}
}

.ant-input-group-addon .ant-btn {
	margin-right: 0;
}

@media screen and (max-width: 575px) {
	.ant-btn {
		margin-bottom: 10px;
	}
}

.ant-input-search .ant-btn {
	margin-bottom: 0;
}

.oitis-customizer-option .ant-btn {
	background-color: #fa8c16;
	background: -o-linear-gradient(left, #fa8c16, #f5222d);
	border: 0;
	border-radius: 30px 0 0 30px;
	box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.05);
	color: #fff;
	font-size: 18px;
	height: 42px;
	line-height: 42px;
	margin: 0;
	padding: 0 18px;
}

.oitis-customizer-option .ant-btn:focus,
.oitis-customizer-option .ant-btn:hover {
	background-color: #f5222d;
	background: -o-linear-gradient(left, #f5222d, #fa8c16);
}

.oitis-customizer-option .ant-btn .icon:before {
	display: block;
}

.horizontal-layout.full-scroll .oitis-customizer-option .ant-btn {
	margin-right: 0;
}

.oitis-module-add-task .ant-btn {
	height: 36px;
}

.oitis-module-add-task .ant-btn .icon:before {
	position: relative;
	top: 2px;
}

.ant-modal-confirm-btns .ant-btn,
.ant-modal-footer .ant-btn {
	margin-bottom: 0;
}

.oitis-login-content .ant-btn {
	height: auto;
	line-height: 1;
	padding: 9px 20px !important;
}

.boxed-layout .ant-btn-group .ant-btn {
	padding: 0 12px;
}

@media (max-width: 1366px) {
	.ant-btn-group .ant-btn {
		padding: 0 12px;
	}

	.oitis-product-footer .ant-btn {
		font-size: 12px;
		height: 32px;
		line-height: 32px;
		padding: 0 10px;
	}
}

.ant-btn-background-ghost.ant-btn-primary {
	border-color: #038fde !important;
	color: #038fde !important;
}

.ant-btn-background-ghost.ant-btn-primary:focus,
.ant-btn-background-ghost.ant-btn-primary:hover {
	background-color: #038fde;
	border-color: #038fde;
	color: #fff;
}

.ant-btn-group:not(:last-child) {
	margin-right: 15px;
}

@media screen and (max-width: 575px) {
	.ant-btn-group:not(:last-child) {
		margin-right: 10px;
	}
}

.ant-radio-group .ant-radio-button-wrapper {
	margin-bottom: 6px;
}

button {
	outline: none;
}

.ant-confirm .ant-confirm-btns button+button {
	margin-bottom: 8px;
}

.ant-modal.ant-confirm .ant-btn,
.ant-modal.ant-confirm .ant-confirm-btns button+button {
	margin-bottom: 0;
}

.btn,
.oitis-btn {
	border: 1px solid #d9d9d9;
	border-radius: 4px;
	color: #545454;
	display: inline-block;
	font-size: 14px;
	font-weight: 400;
	height: 36px;
	line-height: 34px;
	margin-bottom: 15px;
	padding: 0 15px;
	text-align: center;
	vertical-align: middle;
	white-space: nowrap;
}

.btn:not(:last-child),
.oitis-btn:not(:last-child) {
	margin-right: 15px;
}

@media screen and (max-width: 575px) {

	.btn:not(:last-child),
	.oitis-btn:not(:last-child) {
		margin-right: 10px;
	}

	.btn,
	.oitis-btn {
		margin-bottom: 10px;
	}
}

.btn:focus,
.btn:hover,
.oitis-btn:focus,
.oitis-btn:hover {
	text-decoration: none;
}

.btn.focus,
.btn:focus,
.oitis-btn.focus,
.oitis-btn:focus {
	outline: 0;
}

.btn.disabled,
.btn:disabled,
.oitis-btn.disabled,
.oitis-btn:disabled {
	opacity: 0.65;
}

.btn:not([disabled]):not(.disabled),
.oitis-btn:not([disabled]):not(.disabled) {
	cursor: pointer;
}

.oitis-btn-list .btn,
.oitis-btn-list .oitis-btn {
	margin-bottom: 6px;
}

.oitis-sub-popover .btn,
.oitis-sub-popover .oitis-btn {
	border: 0;
	margin-bottom: 5px;
}

.oitis-sub-popover .btn:focus,
.oitis-sub-popover .btn:hover,
.oitis-sub-popover .oitis-btn:focus,
.oitis-sub-popover .oitis-btn:hover {
	background-color: #f5f5f5;
}

.oitis-sub-popover .btn:not(:last-child),
.oitis-sub-popover .oitis-btn:not(:last-child) {
	margin-right: 5px;
}

.oitis-login-content .btn,
.oitis-login-content .oitis-btn {
	height: auto;
	line-height: 1;
	padding: 9px 20px !important;
}

a.oitis-btn.disabled,
fieldset[disabled] a.oitis-btn {
	pointer-events: none;
}

.oitis-btn-rounded {
	border-radius: 50px !important;
}

.oitis-btn-link {
	font-size: 14px;
	font-weight: 400;
}

.oitis-btn-link,
.oitis-btn-link:hover {
	background-color: transparent;
	color: #038fde;
}

.oitis-btn-link.focus,
.oitis-btn-link:focus,
.oitis-btn-link:hover {
	border-color: transparent;
	text-decoration: underline;
}

.oitis-btn-link.disabled,
.oitis-btn-link:disabled {
	color: #bfbfbf;
}

.oitis-btn-group-lg>.oitis-btn,
.oitis-btn-lg {
	border-radius: 6px;
	font-size: 16px;
	height: 40px;
	line-height: 40px;
	padding: 0 15px;
}

.oitis-btn-group-sm>.oitis-btn,
.oitis-btn-sm {
	border-radius: 2px;
	font-size: 12px;
	height: 24px;
	line-height: 24px;
	padding: 0 7px;
}

.oitis-btn-xs {
	font-size: 10px;
	height: 26px;
	line-height: 25px;
	padding: 0 6px;
}

.oitis-btn-block {
	display: block;
	width: 100%;
}

.oitis-btn-block+.oitis-btn-block {
	margin-top: 0.5rem;
}

.oitis-icon-btn {
	-ms-align-items: center;
	align-items: center;
	border-radius: 2px;
	cursor: pointer;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	height: 40px;
	-ms-justify-content: center;
	justify-content: center;
	width: 40px;
}

.oitis-icon-btn:focus,
.oitis-icon-btn:hover {
	background-color: rgba(0, 0, 0, 0.1);
}

.oitis-layout-sider-dark .oitis-icon-btn:focus,
.oitis-layout-sider-dark .oitis-icon-btn:hover {
	background-color: #038fde;
}

.oitis-popover-header .oitis-icon-btn {
	height: 30px;
	width: 30px;
}

.oitis-icon-btn .icon-star-o:before,
.oitis-icon-btn .icon-star:before {
	position: relative;
	top: 3px;
}

@media screen and (max-width: 575px) {
	.oitis-module-box-content .oitis-icon-btn.icon {
		font-size: 16px;
	}
}

.oitis-radio-group-link.ant-radio-group .ant-radio-button-wrapper {
	border: 0;
	border-radius: 2px;
	height: 24px;
	line-height: 24px;
	margin-left: 0;
}

.oitis-radio-group-link.ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
	background-color: #038fde;
	color: #fff;
}

.oitis-radio-group-link.ant-radio-group .ant-radio-button-wrapper:not(:first-child):before {
	display: none;
}

@media screen and (max-width: 575px) {
	.oitis-radio-group-link-news {
		display: -ms-flex;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.oitis-radio-group-link .ant-radio-button-wrapper {
		padding: 0 8px;
	}
}

.oitis-radio-group-link-bg-light {
	margin-left: -5px;
	margin-right: -5px;
}

.oitis-radio-group-link-bg-light.ant-radio-group .ant-radio-button-wrapper {
	background-color: #e8e8e8;
	margin-left: 5px;
	margin-right: 5px;
	padding: 0 10px;
}

.oitis-radio-group-link-bg-light.ant-radio-group .ant-radio-button-wrapper.ant-radio-button-wrapper-checked {
	background-color: #038fde;
	color: #fff;
}

.oitis-radio-group-link-bg-light.ant-radio-group .ant-radio-button-wrapper:not(:first-child):before {
	display: none;
}

.ant-card {
	margin-bottom: 32px;
}

.ant-card-head {
	background: none;
}

.ant-card-head-title {
	font-weight: 400;
}

.ant-card-grid {
	text-align: center;
	width: 25%;
}

@media screen and (max-width: 767px) {
	.ant-card-grid {
		width: 33.33%;
	}
}

@media screen and (max-width: 575px) {
	.ant-card-grid {
		width: 50%;
	}

	.ant-card {
		margin-bottom: 20px;
	}
}

.ant-card-body .ant-card-head {
	padding-left: 0 !important;
	padding-right: 0 !important;
}

.ant-card-type-inner .ant-card-head {
	padding-left: 24px !important;
	padding-right: 24px !important;
}

.ant-card-wider-padding .ant-card-body> :last-child {
	margin-bottom: 0;
}

.oitis-card-center-vertical {
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	-ms-justify-content: center;
	justify-content: center;
}

.oitis-card-img {
	overflow: hidden;
}

.oitis-card-img,
.oitis-card-img .ant-card-body {
	position: relative;
}

.oitis-card-img .oitis-badge-up {
	border-radius: 50%;
	height: 40px;
	line-height: 36px;
	margin: 0;
	padding: 5px;
	position: absolute;
	right: 60px;
	text-align: center;
	top: -20px;
	width: 40px;
	z-index: 1;
}

.oitis-card-img .oitis-badge-up-right {
	box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
		0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
	line-height: 32px !important;
	right: 10px;
	top: -15px;
}

.oitis-card-img-center .ant-card-cover {
	text-align: center;
}

.oitis-card-img-center .ant-card-cover>* {
	display: inline-block;
	width: auto;
}

.oitis-card-body-border-top .ant-card-cover {
	padding-top: 24px;
}

.oitis-card-body-border-top .ant-card-body {
	border-top: 1px solid #e8e8e8;
	margin-top: 32px;
}

@media screen and (max-width: 575px) {
	.oitis-card-body-border-top .ant-card-body {
		margin-bottom: 20px;
	}
}

.oitis-ant-card-actions {
	list-style: none;
	margin: 0 -10px 10px;
	padding-left: 0;
}

.oitis-ant-card-actions li {
	display: inline-block;
	padding: 0 10px;
	vertical-align: top;
}

.oitis-ant-card-actions li:not(:last-child) {
	border-right: 1px solid #e8e8e8;
}

.oitis-card {
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
	margin-bottom: 32px;
	position: relative;
}

@media screen and (max-width: 575px) {
	.oitis-card {
		margin-bottom: 20px;
	}
}

.oitis-card-head {
	padding: 24px;
}

.oitis-card-head .oitis-card-title {
	margin-bottom: 0;
}

.oitis-card-head+.oitis-card-body {
	padding-top: 0;
}

.oitis-card .ant-card-head {
	border-bottom: 0;
	min-height: 10px;
	padding: 0 24px;
}

@media screen and (max-width: 991px) {
	.oitis-card .ant-card-head {
		padding: 0 24px;
	}
}

.oitis-card .ant-card-head-title {
	font-size: 14px;
	font-weight: 600;
	padding: 25px 0 0;
	text-transform: uppercase;
}

@media screen and (max-width: 991px) {
	.oitis-card .ant-card-head-title {
		padding-top: 20px;
	}
}

.oitis-card .ant-card-body,
.oitis-card-body {
	padding: 24px;
}

@media screen and (max-width: 991px) {

	.oitis-card .ant-card-body,
	.oitis-card-body {
		padding: 24px;
	}
}

.oitis-card.ant-card-bordered {
	border: 0;
}

.oitis-card.ant-card-hoverable:hover {
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.45);
}

.oitis-card .ant-card-cover {
	padding: 24px 24px 0;
}

@media screen and (max-width: 991px) {
	.oitis-card .ant-card-cover {
		padding: 24px 24px 0;
	}
}

.oitis-card-metrics {
	border-radius: 10px;
	box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
}

.oitis-card-metrics.ant-card-bordered {
	border: 0;
}

.oitis-card-metrics .ant-card-head {
	border-bottom: 0;
	min-height: 10px;
}

.oitis-card-metrics .ant-card-head-title {
	font-size: 14px;
	padding: 20px 0 0;
	text-transform: uppercase;
}

.oitis-card-widget {
	border-radius: 10px;
	box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
}

.oitis-card-widget.ant-card-bordered {
	border: 0;
}

.oitis-card-widget .ant-card-head {
	border-bottom: 0;
	min-height: 24px;
	padding: 24px 24px 0;
}

.oitis-card-widget .ant-card-head-wrapper {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.oitis-card-widget .ant-card-head-title {
	font-size: 14px;
	padding: 0;
	text-transform: uppercase;
}

.oitis-card-widget.oitis-ch-capitalize .ant-card-head-title {
	font-size: 14px;
	text-transform: capitalize;
}

.oitis-card-widget .ant-card-extra {
	padding: 0;
}

.oitis-card-widget .ant-card-extra .oitis-btn {
	margin-bottom: 0;
}

.oitis-card-widget .ant-card-body {
	padding: 24px;
}

.oitis-card-equal-height {
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	height: calc(100% - 32px);
}

@media screen and (max-width: 575px) {
	.oitis-card-equal-height {
		height: calc(100% - 20px);
	}
}

.oitis-card-equal-height .ant-card-body,
.oitis-card-equal-height .ant-card-body .oitis-rechart {
	display: -ms-flex;
	display: flex;
	flex: 1 1;
	flex-direction: column;
	flex-wrap: nowrap;
}

.oitis-card-equal-height .ant-card-body .oitis-rechart .recharts-responsive-container {
	display: flex;
	flex: 1 1;
}

.oitis-card-sm-p .ant-card-head {
	min-height: 10px;
}

.oitis-card-sm-p .ant-card-head-title {
	padding: 14px 14px 0;
}

.oitis-card-sm-p .ant-card-body {
	padding: 14px;
}

.oitis-card-sm-px .ant-card-head {
	min-height: 10px;
}

.oitis-card-sm-px .ant-card-body,
.oitis-card-sm-px .ant-card-head-title {
	padding-left: 10px;
	padding-right: 10px;
}

.oitis-entry-header {
	margin-bottom: 32px;
}

@media screen and (max-width: 575px) {
	.oitis-entry-header {
		margin-bottom: 20px;
	}
}

.oitis-entry-header .oitis-entry-heading {
	margin-bottom: 0;
}

.oitis-entry-header .oitis-entry-description {
	font-size: 13px;
	margin-bottom: 0;
}

.oitis-card-full {
	overflow: hidden;
}

.oitis-card-full .oitis-card-body {
	padding: 0;
}

.oitis-card-full .oitis-card-body .recharts-legend-wrapper {
	padding: 0 24px;
}

.oitis-card-full .ant-card-body {
	padding: 0;
}

.oitis-card-full .ant-card-body .recharts-legend-wrapper {
	padding: 0 24px;
}

.oitis-card-full .recharts-default-legend {
	margin: 0 -10px !important;
}

.oitis-card-full .recharts-default-legend li {
	display: inline-block;
	margin: 0 !important;
	padding: 0 10px;
	vertical-align: top;
}

.oitis-card-full .recharts-default-legend li .recharts-legend-item-text {
	margin-left: 10px;
}

.oitis-card-overview {
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
	margin-bottom: 32px;
	padding: 24px;
	position: relative;
}

@media screen and (max-width: 575px) {
	.oitis-card-overview {
		margin-bottom: 20px;
	}
}

.oitis-card-overview .oitis-card-title {
	margin-bottom: 20px;
}

.oitis-card-profile .ant-card-head {
	padding-bottom: 16px;
	padding-top: 15px;
}

.oitis-card-profile.oitis-card-tabs .ant-card-head {
	border-bottom: 1px solid #e8e8e8;
}

@media screen and (max-width: 575px) {
	.oitis-card-profile.oitis-card-tabs .ant-card-head {
		border-bottom: 0;
	}
}

.oitis-card-profile .ant-card-body,
.oitis-card-profile.oitis-card-tabs .ant-card-body {
	padding-top: 0;
}

.oitis-card-profile.oitis-card-tabs .ant-tabs {
	margin-top: -50px;
}

@media screen and (max-width: 575px) {
	.oitis-card-profile.oitis-card-tabs .ant-tabs {
		margin-top: 0;
	}
}

.oitis-card-profile .ant-tabs-tab {
	padding-bottom: 18px;
	padding-top: 15px;
}

.oitis-card-profile .ant-card-head-title,
.oitis-card-profile.oitis-card-tabs .ant-card-head-title {
	font-size: 20px;
	text-transform: capitalize;
}

.oitis-card-profile.oitis-card-tabs .ant-tabs-nav-container {
	color: #8c8c8c;
	font-size: 14px;
}

.oitis-card-profile-sm.oitis-card-widget .ant-card-body {
	padding-top: 10px;
}

@media (max-width: 1366px) {
	.oitis-card-1367-p .ant-card-head {
		min-height: 10px;
	}

	.oitis-card-1367-p .ant-card-body,
	.oitis-card-1367-p .ant-card-head-title {
		padding-left: 14px;
		padding-right: 14px;
	}
}

.ant-divider-horizontal.ant-divider-with-text,
.ant-divider-horizontal.ant-divider-with-text-left,
.ant-divider-horizontal.ant-divider-with-text-right {
	font-size: 14px;
}

.ant-cascader-picker {
	width: 100%;
}

.recharts-default-tooltip {
	background-color: hsla(0, 0%, 100%, 0.8) !important;
	border-radius: 4px;
	color: #fa8c15;
}

.recharts-tooltip-item-list li {
	color: #038fde !important;
}

.ant-form .ant-row {
	margin-left: 0;
	margin-right: 0;
}

.ant-form-inline {
	margin-left: -8px;
	margin-right: -8px;
}

.ant-form-inline .ant-row {
	padding-left: 8px;
	padding-right: 8px;
}

.ant-form-inline .ant-form-item {
	margin-bottom: 10px;
}

@media screen and (max-width: 575px) {
	.ant-form-inline .ant-form-item {
		display: block;
	}
}

.ant-form-vertical .ant-form-item {
	padding-bottom: 0;
}

.ant-form-vertical .ant-row {
	flex-direction: column;
}

.ant-form-extra {
	word-break: break-all;
}

@media (max-width: 575px) {
	.ant-form-item-label {
		padding-bottom: 0;
	}
}

.ant-form-item-with-help {
	margin-bottom: 20px;
}

.ant-advanced-search-form .oitis-btn-link:hover {
	text-decoration: none;
}

.ant-form-item [class^='ant-col-']>.ant-form-item:only-child,
.ant-form-item-control>.ant-form-item:last-child {
	margin-bottom: 0;
}

.oitis-form-row0 .ant-row {
	margin-left: 0 !important;
	margin-right: 0 !important;
}

.oitis-login-form {
	max-width: 350px;
}

.oitis-login-form .ant-form-item-control-wrapper {
	width: 100%;
}

.ant-form-inline .oitis-form-item-one-fourth {
	width: 25%;
}

@media (max-width: 575px) {
	.ant-form-inline .oitis-form-item-one-fourth {
		width: 100%;
	}
}

.ant-form-inline .oitis-form-item-two-fourth {
	width: 50%;
}

@media (max-width: 575px) {
	.ant-form-inline .oitis-form-item-two-fourth {
		width: 100%;
	}
}

.oitis-form-inline-currency {
	margin: 0 -8px;
}

.oitis-form-inline-currency .ant-form-item {
	padding: 0 8px;
}

.oitis-form-inline-currency .oitis-form-item-one-fourth {
	width: 30%;
}

@media (max-width: 575px) {
	.oitis-form-inline-currency .oitis-form-item-one-fourth {
		display: inline-block;
		width: 40%;
	}
}

.oitis-form-inline-currency .oitis-form-item-two-fourth {
	width: 40%;
}

@media (max-width: 575px) {
	.oitis-form-inline-currency .oitis-form-item-two-fourth {
		display: block;
		width: 100%;
	}
}

.oitis-radio-wrapper {
	-ms-align-items: flex-start;
	align-items: flex-start;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	margin-bottom: 10px;
	margin-right: 0;
	white-space: normal;
}

@media (max-width: 1599px) {
	.ant-form-item-label[class*='ant-col-'] {
		padding: 0 15px;
	}
}

@media (max-width: 1199px) {
	.ant-form-item-label[class*='ant-col-'] {
		padding: 0 15px;
	}

	.ant-advanced-search-form .ant-form-item,
	.ant-form-item,
	.ant-form-item-with-help {
		margin-bottom: 12px;
	}
}

@media (max-width: 991px) {
	.ant-form-item-label[class*='ant-col-'] {
		padding: 0 15px;
	}
}

@media (max-width: 767px) {
	.ant-form-item-label[class*='ant-col-'] {
		padding: 0 15px;
	}
}

@media (max-width: 575px) {
	.ant-form-item-label[class*='ant-col-'] {
		padding: 0 15px;
	}

	.ant-advanced-search-form .ant-btn,
	.ant-advanced-search-form .oitis-btn-link {
		display: block;
		margin: 0 0 10px !important;
	}
}

.ant-input-search {
	margin-bottom: 16px;
}

.ant-input-search>.ant-input-suffix>.ant-input-search-button {
	margin-right: 0;
}

.ant-input-group>[class*='col-'] {
	float: left !important;
	padding-left: 0;
}

.ant-transfer-list {
	margin-bottom: 10px;
}

.ant-input-number {
	margin-right: 10px;
}

.oitis-form-group {
	margin-bottom: 10px;
}

.oitis-search-bar {
	position: relative;
}

.oitis-search-bar .oitis-form-group {
	height: 100%;
	margin-bottom: 0;
	width: 100%;
}

.oitis-search-bar input[type='search'] {
	background: hsla(0, 0%, 98%, 0.1);
	padding-right: 35px;
}

.ant-layout-header .oitis-search-bar input[type='search'],
.ant-popover-content .oitis-search-bar input[type='search'] {
	border: 0;
}

.oitis-search-bar input[type='search']:focus {
	background-color: hsla(0, 0%, 98%, 0.3);
	box-shadow: none;
}

.oitis-search-bar .oitis-search-icon {
	background-color: transparent;
	border: 0;
	color: #d9d9d9;
	height: 32px;
	line-height: 36px;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	width: 30px;
}

.oitis-algolia-header .oitis-search-bar .oitis-search-icon {
	margin-top: 2px;
	text-align: left;
}

.oitis-search-bar .ant-input-lg+.oitis-search-icon {
	height: 40px;
	line-height: 45px;
}

.oitis-lt-icon-search-bar input[type='search'] {
	border: 0;
	padding-left: 35px;
	padding-right: 16px;
}

.oitis-lt-icon-search-bar .oitis-search-icon {
	left: 0;
	right: auto;
}

.oitis-algolia-header .oitis-lt-icon-search-bar {
	margin-right: 10px;
}

@media screen and (max-width: 1199px) {
	.oitis-algolia-header .oitis-lt-icon-search-bar {
		width: 140px;
	}
}

@media screen and (max-width: 400px) {
	.oitis-algolia-header .oitis-lt-icon-search-bar {
		margin-bottom: 10px;
		margin-right: 0;
		width: auto;
	}
}

.oitis-lt-icon-search-bar-lg {
	width: 350px;
}

.oitis-lt-icon-search-bar-lg input[type='search'] {
	border-radius: 4px;
	height: 46px;
	padding: 10px 18px 10px 40px;
}

@media screen and (max-width: 991px) {
	.oitis-lt-icon-search-bar-lg input[type='search'] {
		height: 38px;
		padding: 5px 18px 5px 40px;
	}
}

.oitis-header-search .oitis-lt-icon-search-bar-lg input[type='search'] {
	background-color: #f5f5f5;
	border: 1px solid #e8e8e8;
	border-radius: 4px 0 0 4px;
	height: 36px;
}

.oitis-header-search .oitis-lt-icon-search-bar-lg input[type='search']:focus {
	background-color: #f2f2f2;
}

.oitis-header-horizontal-dark .oitis-header-search .oitis-lt-icon-search-bar-lg input[type='search'] {
	background-color: hsla(0, 0%, 100%, 0.2);
	border-color: hsla(0, 0%, 100%, 0.2);
	color: #fff;
}

.oitis-header-horizontal-dark .oitis-header-search .oitis-lt-icon-search-bar-lg input[type='search']:focus {
	background-color: transparent;
}

.oitis-lt-icon-search-bar-lg .oitis-search-icon {
	font-size: 16px;
	height: 46px;
	left: 0;
	line-height: 50px;
	right: auto;
	width: 35px;
}

@media screen and (max-width: 991px) {
	.oitis-lt-icon-search-bar-lg .oitis-search-icon {
		height: 38px;
		line-height: 40px;
	}
}

.oitis-header-search .oitis-lt-icon-search-bar-lg .oitis-search-icon {
	height: 36px;
	line-height: 40px;
}

.oitis-inside-header-horizontal .oitis-lt-icon-search-bar-lg {
	margin-right: 10px;
	width: 200px;
}

@media screen and (max-width: 1199px) {
	.oitis-inside-header-horizontal .oitis-lt-icon-search-bar-lg {
		width: 120px;
	}

	.oitis-lt-icon-search-bar-lg {
		width: 150px;
	}
}

.oitis-popover-search-bar {
	margin: -10px -14px;
	width: 150px;
}

.oitis-chat-search-bar {
	flex: 1 1 auto;
	min-width: 100px;
	width: auto;
}

.oitis-chat-search-bar input[type='search'] {
	border: 0;
	border-radius: 0;
	box-shadow: none;
	height: 40px;
}

.oitis-chat-search-bar input[type='search']:focus {
	background-color: #fff;
}

.oitis-chat-search-bar .oitis-search-icon {
	height: 40px;
	line-height: 44px;
}

.ant-transfer-list {
	width: 150px;
}

@media screen and (max-width: 575px) {
	.ant-transfer-list {
		width: 100%;
	}
}

.oitis-select-sm {
	font-size: 12px;
}

.oitis-select-sm .ant-select-selection {
	border-radius: 4px;
}

.oitis-select-sm .ant-select-selection--single,
.oitis-select-sm.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	height: 24px;
	padding: 0 7px;
}

.oitis-select-sm.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
	height: 22px;
}

.oitis-select-sm.ant-select-single .ant-select-selector .ant-select-selection-item,
.oitis-select-sm.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
	line-height: 22px;
}

.oitis-select-sm .ant-select-selection__rendered {
	line-height: 22px;
	margin-left: 6px;
	margin-right: 6px;
}

.oitis-select-sm .ant-select-selection-selected-value {
	padding-right: 20px;
}

.oitis-select-sm .ant-select-arrow {
	right: 6px;
}

.oitis-select-sm .ant-select-dropdown {
	font-size: 12px;
}

.oitis-form-inline-label-up .ant-form-item,
.oitis-form-inline-label-up .ant-form-item>.ant-form-item-control-wrapper,
.oitis-form-inline-label-up .ant-form-item>.ant-form-item-label {
	display: block;
}

.oitis-form-inline-label-up .ant-form-item-label {
	color: #bfbfbf;
	line-height: 1;
	margin-bottom: 4px;
	text-align: left;
}

.oitis-form-inline-label-up .ant-input,
.oitis-form-inline-label-up .ant-select-selection--single {
	height: 30px;
}

.oitis-form-inline-label-up .ant-select-selection__rendered {
	line-height: 28px;
}

.oitis-form-inline-label-up .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
	height: 30px;
}

.oitis-form-inline-label-up .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
	height: 28px;
}

.oitis-form-inline-label-up .ant-select-single .ant-select-selector .ant-select-selection-item,
.oitis-form-inline-label-up .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
	line-height: 28px;
}

.oitis-form-inline-label-up.ant-form-inline .ant-form-item>.ant-form-item-control,
.oitis-form-inline-label-up.ant-form-inline .ant-form-item>.ant-form-item-label {
	display: block;
}

.oitis-form-inline-label-up .ant-form-item-label>label {
	height: auto;
}

.oitis-input-lineheight {
	line-height: 36px;
}

.ant-mention-wrapper {
	border: 1px solid #e8e8e8;
	border-radius: 6px;
	padding: 8.6px 11px;
}

@media screen and (max-width: 575px) {
	.oitis-ant-transfer-width .ant-transfer-list {
		width: 100% !important;
	}
}

@media screen and (max-width: 475px) {
	.ant-input-group.ant-input-group-compact>* {
		border-radius: 4px !important;
		border-right-width: 1px;
		width: 100% !important;
	}

	.ant-input-group.ant-input-group-compact> :not(:last-child) {
		margin-bottom: 10px;
	}

	.ant-input-group.ant-input-group-compact>.ant-calendar-picker .ant-input,
	.ant-input-group.ant-input-group-compact>.ant-cascader-picker .ant-input,
	.ant-input-group.ant-input-group-compact>.ant-mention-wrapper .ant-mention-editor,
	.ant-input-group.ant-input-group-compact>.ant-select-auto-complete .ant-input,
	.ant-input-group.ant-input-group-compact>.ant-select>.ant-select-selection,
	.ant-input-group.ant-input-group-compact>.ant-time-picker .ant-time-picker-input {
		border-radius: 4px;
		border-right-width: 1px;
	}

	.ant-input-group.ant-input-group-compact .oitis-border-lt-xs {
		border-left: 1px solid #d9d9d9 !important;
	}
}

.oitis-icon-views {
	-ms-align-items: center;
	align-items: center;
	border-radius: 10px;
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	height: calc(100% - 15px);
	-ms-justify-content: center;
	justify-content: center;
	margin-bottom: 15px;
	padding: 16px;
	position: relative;
	text-align: center;
	transition: all 0.3s ease-out;
}

.oitis-icon-views .icon {
	display: block;
	font-size: 20px;
	line-height: 1;
	margin-bottom: 12px;
	transition: all 0.3s ease-out;
}

.oitis-icon-views:hover {
	background-color: #038fde;
	color: #fff;
	cursor: pointer;
}

.oitis-icon-views:hover .icon {
	-webkit-transform: scale(1.4);
	transform: scale(1.4);
}

.oitis-icon-text {
	display: block;
	font-size: 12px;
}

.ant-btn-icon-only {
	padding: 0;
}

.ant-list-vertical .ant-list-item-main {
	min-width: 200px;
}

@media screen and (max-width: 480px) {
	.ant-list-vertical .ant-list-item-extra {
		margin-bottom: 10px;
	}
}

.oitis-list-item .ant-list-item-extra-wrap {
	display: -ms-flex;
	display: flex;
	flex: 1 1;
	flex-direction: row;
	flex-wrap: wrap;
}

.oitis-list-item .ant-list-item-extra-wrap .ant-list-item-extra {
	margin-left: 20px;
}

@media screen and (max-width: 575px) {
	.oitis-list-item .ant-list-item-extra-wrap .ant-list-item-extra {
		margin-left: 0;
		margin-top: 10px;
	}

	.oitis-list-item .ant-list-item-extra-wrap .ant-list-item-extra img {
		width: 100%;
	}

	.oitis-list-item .ant-list-item-extra-wrap {
		display: -ms-flex;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
	}
}

.oitis-user-list {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding-bottom: 10px;
	width: 100%;
}

.oitis-user-list:not(:first-child) {
	padding-top: 20px;
}

@media screen and (max-width: 575px) {
	.oitis-user-list:not(:first-child) {
		padding-top: 0;
	}
}

.oitis-user-list.oitis-card-list {
	background: #fff;
	border-radius: 4px;
	box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
	margin-bottom: 32px;
	padding: 30px;
}

.oitis-user-list.oitis-card-list .oitis-avatar-img {
	margin-right: 25px;
}

@media screen and (max-width: 767px) {
	.oitis-user-list.oitis-card-list {
		padding: 20px;
	}
}

@media screen and (max-width: 575px) {
	.oitis-user-list.oitis-card-list {
		margin-bottom: 20px;
	}
}

.oitis-user-list .oitis-avatar-img {
	margin-bottom: 10px;
	margin-right: 20px;
}

.oitis-user-list h3 {
	font-weight: 500;
	margin-bottom: 8px;
}

.oitis-user-list .oitis-link[class*='oitis-meta-'] {
	color: #545454;
	display: inline-block;
	font-size: 12px;
	font-weight: 500;
}

.oitis-user-list .oitis-link[class*='oitis-meta-'] i {
	margin-right: 6px;
	vertical-align: middle;
}

.oitis-user-list .oitis-description {
	width: calc(100% - 120px);
}

@media screen and (max-width: 575px) {
	.oitis-user-list .oitis-description {
		width: 100%;
	}
}

.oitis-user-list .oitis-btn-list li:not(:last-child) {
	margin-right: 10px;
}

.oitis-list-inline {
	list-style: none;
	margin-left: -5px;
	margin-right: -5px;
	padding-left: 0;
}

.oitis-list-inline li {
	display: inline-block;
	padding: 0 5px;
}

.oitis-list-inline-lg {
	list-style: none;
	margin-left: -10px;
	margin-right: -10px;
	padding-left: 0;
}

.oitis-list-inline-lg li {
	display: inline-block;
	padding: 0 10px;
}

.oitis-card-strip {
	margin-bottom: 10px;
}

.oitis-card-strip:not(:last-child) {
	border-bottom: 1px solid #e8e8e8;
}

.oitis-card-list .oitis-description {
	margin-top: 5px;
	width: calc(100% - 230px);
}

@media screen and (max-width: 767px) {
	.oitis-card-list .oitis-description {
		width: calc(100% - 80px);
	}
}

.oitis-card-list .oitis-card-list-footer {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	-ms-justify-content: center;
	justify-content: center;
	min-width: 150px;
	padding-left: 15px;
}

.oitis-card-list .oitis-card-list-footer .ant-btn {
	display: block;
	margin-right: 0;
	width: 120px;
}

.oitis-card-list .oitis-card-list-footer .ant-btn:last-child {
	margin-bottom: 0;
}

@media screen and (max-width: 767px) {
	.oitis-card-list .oitis-card-list-footer {
		display: -ms-flex;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		min-width: 10px;
		padding-left: 0;
	}

	.oitis-card-list .oitis-card-list-footer .ant-btn {
		margin-bottom: 0;
		margin-right: 12px;
		width: auto;
	}
}

.oitis-list-group {
	list-style: none;
	padding-left: 0;
}

.oitis-list-group li {
	align-items: center;
	display: flex;
	margin-bottom: 10px;
}

.oitis-list-group li .anticon,
.oitis-list-group li i {
	margin-right: 12px;
}

.oitis-dot-list {
	list-style: none;
	margin: 0 -3px 10px;
	padding-left: 0;
}

.oitis-dot-list li {
	display: inline-block;
	padding: 0 3px;
	vertical-align: top;
}

@media screen and (max-width: 575px) {
	.oitis-dot-list {
		margin-bottom: 5px;
	}
}

.ant-spin {
	margin-bottom: 10px;
	margin-right: 16px;
}

.oitis-loader-container {
	background-color: #f5f5f5;
	border-radius: 4px;
	display: block;
	margin-right: 0;
	padding: 30px 50px;
	text-align: center;
}

.ant-pagination.mini:not(:last-child),
.ant-pagination>li {
	margin-bottom: 10px;
}

.ant-pagination.mini li {
	margin-bottom: 5px !important;
}

.ant-pagination-jump-next,
.ant-pagination-jump-prev,
.ant-pagination-next,
.ant-pagination-prev {
	line-height: 30px;
}

.alpha-picker,
.hue-picker,
.photoshop-picker,
.swatches-picker {
	width: 100% !important;
}

.oitis-alpha-pickers {
	min-height: 250px;
	position: relative;
}

.ant-calendar-footer .ant-calendar-ok-btn {
	margin-bottom: 0;
}

.ant-progress-circle,
.ant-progress-line {
	margin-bottom: 8px;
	margin-right: 8px;
}

.ant-steps-dot {
	flex-wrap: wrap;
	overflow: hidden;
	padding: 10px 0;
}

.ant-steps-dot .ant-steps-item {
	margin-bottom: 10px;
}

@media screen and (max-width: 575px) {
	.ant-steps-dot .ant-steps-item-description {
		display: none;
	}
}

.ant-carousel h1,
.ant-carousel h2,
.ant-carousel h3,
.ant-carousel h4,
.ant-carousel h5,
.ant-carousel h6 {
	line-height: inherit;
	margin-bottom: 0;
}

.ant-carousel .slick-dots {
	margin-left: 0;
	margin-right: 0;
}

.oitis-vertical-slider {
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 -15px;
	position: relative;
}

.oitis-vertical-slider,
.oitis-vertical-slider-item {
	display: -ms-flex;
	display: flex;
}

.oitis-vertical-slider-item {
	-ms-align-items: center;
	align-items: center;
	flex-direction: column;
	flex-wrap: nowrap;
	height: 300px;
	padding: 0 15px;
	width: 33.33%;
}

@media screen and (max-width: 575px) {
	.oitis-vertical-slider-item {
		margin-bottom: 15px;
		width: 50%;
	}
}

.ant-carousel-vertical .oitis-vertical-slide .slick-slide {
	height: 160px;
}

.slick-slider {
	margin-bottom: 32px;
}

@media screen and (max-width: 575px) {
	.slick-slider {
		margin-bottom: 20px;
	}
}

.slick-slider .slick-prev {
	left: -10px;
}

.slick-slider .slick-prev:before {
	color: #000;
}

.slick-slider .slick-next {
	right: -10px;
}

.slick-slider .slick-next:before {
	color: #000;
}

.slick-slider img {
	object-fit: cover;
	width: 100%;
}

.slick-dots li button:before {
	display: none;
}

.oitis-slick-slider .slick-dots {
	bottom: 26px;
	padding: 0 24px;
	text-align: left;
}

.oitis-slick-slider .slick-dots li,
.oitis-slick-slider .slick-dots li button {
	height: 10px !important;
	width: 10px !important;
}

.oitis-slick-slider .slick-dots li button:before {
	border: 2px solid #000;
	border-radius: 50%;
	color: transparent;
	display: block;
	height: 10px !important;
	line-height: 10px !important;
	width: 10px !important;
}

.oitis-slick-slider .slick-dots li.slick-active button {
	height: 12px !important;
	width: 12px !important;
}

.oitis-slick-slider .slick-dots li.slick-active button:before {
	border-color: #038fde;
	color: transparent;
	height: 12px !important;
	line-height: 12px !important;
	width: 12px !important;
}

.oitis-slide-item {
	margin-left: 15px;
	margin-right: 15px;
}

@media screen and (max-width: 480px) {
	.oitis-slide-item {
		margin-left: 10px;
		margin-right: 10px;
	}
}

.oitis-slider,
.oitis-slider-img {
	position: relative;
}

.oitis-slider-img {
	padding-bottom: 24px;
}

.oitis-slider-img .oitis-img-up {
	border-radius: 10px;
	bottom: -5px;
	left: 24px;
	max-width: 64px;
	position: absolute;
	width: auto;
	z-index: 1;
}

.oitis-slider-content {
	padding: 24px;
}

.oitis-slick-slider {
	margin-bottom: 0;
	padding-bottom: 24px;
}

.oitis-slick-slider-dot-top {
	padding-bottom: 58px;
	padding-right: 24px;
	padding-top: 70px;
}

@media (max-width: 1366px) {
	.oitis-slick-slider-dot-top {
		padding-bottom: 10px;
		padding-top: 40px;
	}
}

.oitis-slick-slider-dot-top .slick-dots {
	bottom: auto;
	padding-left: 0;
	top: 30px;
}

@media (max-width: 1366px) {
	.oitis-slick-slider-dot-top .slick-dots {
		top: 10px;
	}
}

@media screen and (max-width: 575px) {
	.oitis-slick-slider-dot-top {
		padding-left: 24px;
	}
}

.oitis-slick-slider-lt-thumb {
	height: 100%;
	position: relative;
}

.oitis-slick-slider-lt-thumb img {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

@media screen and (max-width: 575px) {
	.oitis-slick-slider-lt-thumb img {
		left: auto;
		position: relative;
		top: auto;
	}
}

.ant-modal .slides {
	position: relative;
}

.ant-modal .slides .slick-next,
.ant-modal .slides .slick-prev {
	margin-top: -10px;
	position: absolute;
	top: 50%;
	z-index: 1;
}

.ant-modal .slides .slick-prev {
	left: 20px;
}

.ant-modal .slides .slick-next {
	right: 20px;
}

.ant-modal .slick-slider {
	margin-bottom: 0;
}

.ant-table-small .ant-table-thead>tr>th {
	background-color: transparent;
}

.ant-table-small>.ant-table-content>.ant-table-body>table,
.ant-table-small>.ant-table-content>.ant-table-fixed-left>.ant-table-body-outer>.ant-table-body-inner>table,
.ant-table-small>.ant-table-content>.ant-table-fixed-left>.ant-table-header>table,
.ant-table-small>.ant-table-content>.ant-table-fixed-right>.ant-table-body-outer>.ant-table-body-inner>table,
.ant-table-small>.ant-table-content>.ant-table-fixed-right>.ant-table-header>table,
.ant-table-small>.ant-table-content>.ant-table-header>table,
.ant-table-small>.ant-table-content>.ant-table-scroll>.ant-table-body>table,
.ant-table-small>.ant-table-content>.ant-table-scroll>.ant-table-header>table {
	padding: 0;
}

.ant-table-small>.ant-table-content>.ant-table-body {
	margin: 0;
}

.ant-table-tbody>tr>td,
.ant-table-thead>tr>th {
	word-break: normal;
}

.oitis-table-responsive .ant-table {
	min-height: 0.01%;
	overflow-x: auto;
}

@media screen and (max-width: 767px) {
	.oitis-table-responsive .ant-table {
		-ms-overflow-style: -ms-autohiding-scrollbar;
		border: 1px solid #e8e8e8;
		overflow-y: hidden;
		width: 100%;
	}

	.ant-card-bordered.oitis-card-full .oitis-table-responsive .ant-table {
		border: 0;
		border-bottom: 1px solid #e8e8e8;
	}

	.oitis-table-responsive .ant-table .ant-table-tbody>tr:last-child>td {
		border-bottom: 0;
	}

	.oitis-table-responsive .ant-table.ant-table-bordered .ant-table-body>table,
	.oitis-table-responsive .ant-table.ant-table-bordered .ant-table-fixed-left table,
	.oitis-table-responsive .ant-table.ant-table-bordered .ant-table-fixed-right table,
	.oitis-table-responsive .ant-table.ant-table-bordered .ant-table-header>table {
		border-left: 0;
		border-top: 0;
	}

	.oitis-table-responsive .ant-table.ant-table-bordered .ant-table-title {
		border: 0;
	}

	.oitis-table-responsive .ant-table.ant-table-bordered .ant-table-footer {
		border: 0;
		border-top: 1px solid #e8e8e8;
	}

	.oitis-table-responsive .ant-table.ant-table-bordered .ant-table-tbody>tr>td:last-child,
	.oitis-table-responsive .ant-table.ant-table-bordered .ant-table-thead>tr>th:last-child {
		border-right: 0;
	}

	.oitis-table-responsive .ant-table-body>table>tbody>tr>td,
	.oitis-table-responsive .ant-table-body>table>tbody>tr>th,
	.oitis-table-responsive .ant-table-body>table>tfoot>tr>td,
	.oitis-table-responsive .ant-table-body>table>tfoot>tr>th,
	.oitis-table-responsive .ant-table-body>table>thead>tr>td,
	.oitis-table-responsive .ant-table-body>table>thead>tr>th {
		white-space: nowrap;
	}
}

.oitis-table {
	background-color: transparent;
	margin-bottom: 1rem;
	max-width: 100%;
	width: 100%;
}

.oitis-table td,
.oitis-table th {
	border-top: 1px solid #e8e8e8;
	padding: 0.75rem;
	vertical-align: top;
}

.oitis-table thead th {
	border-bottom: 2px solid #e8e8e8;
	vertical-align: bottom;
}

.oitis-table tbody+tbody {
	border-top: 2px solid #e8e8e8;
}

.oitis-table-bordered,
.oitis-table-bordered td,
.oitis-table-bordered th {
	border: 1px solid #e8e8e8;
}

.oitis-table-bordered thead td,
.oitis-table-bordered thead th {
	border-bottom-width: 2px;
}

.oitis-table-no-bordered,
.oitis-table-no-bordered td,
.oitis-table-no-bordered th {
	border: 0;
}

.oitis-table-no-bordered thead td,
.oitis-table-no-bordered thead th {
	border-bottom-width: 2px;
}

.oitis-table-no-bordered .ant-table {
	border: 0;
	border-radius: 0;
}

.oitis-table-no-bordered .ant-table-thead>tr>th {
	font-size: 11px;
	font-weight: 300;
	text-transform: uppercase;
}

.oitis-table-no-bordered .ant-table-tbody>tr>td {
	border-bottom: 0;
}

.oitis-table-thead-uppercase thead th {
	text-transform: uppercase;
}

.oitis-table-padding-lg tbody td,
.oitis-table-padding-lg thead th {
	padding: 20px 30px !important;
}

@media screen and (max-width: 991px) {

	.oitis-table-padding-lg tbody td,
	.oitis-table-padding-lg thead th {
		padding: 15px 20px !important;
	}
}

@media screen and (max-width: 767px) {

	.oitis-table-padding-lg tbody td,
	.oitis-table-padding-lg thead th {
		padding: 10px !important;
	}
}

.oitis-table-padding-lg .ant-table-pagination {
	float: none;
	margin: 22px 0;
	text-align: center;
}

@media screen and (max-width: 991px) {
	.oitis-table-padding-lg .ant-table-pagination {
		margin: 16px 0;
	}
}

.ant-timeline-item {
	padding: 0;
}

.ant-timeline-item:not(:last-child) {
	padding-bottom: 20px;
}

@media screen and (max-width: 575px) {
	.ant-timeline-item:not(:last-child) {
		padding-bottom: 15px;
	}
}

.oitis-timeline-info {
	position: relative;
}

.oitis-timeline-info:not(:last-child) {
	padding-bottom: 20px;
}

.oitis-timeline-info-day {
	color: #595959;
	font-size: 12px;
	margin-bottom: 15px;
}

.oitis-timeline-info .ant-timeline {
	padding-left: 0;
	padding-top: 0;
}

.oitis-timeline-info .ant-timeline-item-head-custom {
	left: 0;
	margin-right: 15px;
	padding: 0;
	position: relative;
	top: 0;
	-webkit-transform: translate(0);
	transform: translate(0);
}

.oitis-timeline-info .ant-timeline-item {
	-ms-align-items: flex-start;
	align-items: flex-start;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.oitis-timeline-info .ant-timeline-item-content {
	color: #595959;
	flex: 1 1;
	margin-left: 0;
	padding-left: 0;
	top: 0;
}

.oitis-timeline-info .ant-timeline-item-content .oitis-link {
	color: #262626;
}

.oitis-timeline-info .ant-timeline-item-content .oitis-link:focus,
.oitis-timeline-info .ant-timeline-item-content .oitis-link:hover {
	color: #038fde;
}

.oitis-timeline-info .ant-timeline-item-tail {
	display: none;
}

.oitis-img-more {
	-ms-align-items: center;
	align-items: center;
	background-color: #fff;
	border: 1px solid #e8e8e8;
	border-radius: 6px;
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	height: 40px;
	-ms-justify-content: center;
	justify-content: center;
	width: 60px;
}

.oitis-timeline-left {
	float: left;
	margin-right: 20px;
}

.oitis-timeline-right {
	float: right;
	margin-left: 20px;
}

.oitis-timeline-circle {
	border-radius: 50%;
}

.oitis-timeline-section {
	margin-bottom: 25px;
	overflow: hidden;
}

.oitis-timeline-item {
	padding-bottom: 25px;
	padding-left: 80px;
	position: relative;
}

.oitis-timeline-item:before {
	border-left: 2px solid #e8e8e8;
	bottom: -20px;
	content: '';
	left: 25px;
	position: absolute;
	top: 25px;
	width: 2px;
	z-index: 1;
}

.oitis-timeline-item:first-child:before,
.oitis-timeline-item:last-child:before {
	border-left-style: dashed;
}

.oitis-timeline-badge {
	border-radius: 50%;
	color: #fff;
	font-size: 16px;
	height: 50px;
	left: 0;
	position: absolute;
	text-align: center;
	top: 16px;
	width: 50px;
	z-index: 2;
}

.oitis-timeline-badge.oitis-timeline-img {
	background-color: transparent;
}

.oitis-timeline-badge.oitis-timeline-img:after {
	display: none;
}

.oitis-timeline-badge:after {
	background-color: transparent;
	border: 4px solid #e8e8e8;
	border-radius: 50%;
	content: '';
	height: 42px;
	left: 4px;
	position: absolute;
	top: 4px;
	width: 42px;
	z-index: 1;
}

.oitis-timeline-badge [class*='oitis-']:before,
.oitis-timeline-badge [class^='oitis-']:before {
	line-height: 50px;
}

.oitis-timeline-header-img>img,
.oitis-timeline-img>img {
	display: inline-block;
	height: auto;
	max-width: 100%;
}

.oitis-timeline-panel {
	background-color: #fff;
	border: 1px solid #e8e8e8;
	border-radius: 10px;
	padding: 20px 30px;
	position: relative;
}

.oitis-timeline-panel:after,
.oitis-timeline-panel:before {
	border-style: solid;
	content: '';
	display: inline-block;
	position: absolute;
	top: 30px;
}

.oitis-timeline-panel:before {
	border-color: transparent #e8e8e8;
	border-width: 10px 17px 10px 0;
	left: -18px;
}

.oitis-timeline-panel:after {
	border-color: transparent #fff;
	border-width: 10px 16px 10px 0;
	left: -16px;
}

.oitis-timeline-no-padding {
	padding: 0;
}

.oitis-timeline-header-img,
.oitis-timeline-panel-header {
	margin-bottom: 10px;
}

.oitis-timeline-inverted .oitis-timeline-left {
	float: right;
	margin-left: 20px;
	margin-right: 0;
}

.oitis-timeline-heading {
	margin-bottom: 10px;
	overflow: hidden;
}

.oitis-timeline-body {
	clear: both;
}

.oitis-timeline-center .oitis-timeline-item {
	clear: both;
	float: right;
	padding-left: 55px;
	width: 50%;
}

.oitis-timeline-center .oitis-timeline-item:before {
	left: 0;
}

.oitis-timeline-center .oitis-timeline-badge {
	left: -25px;
	right: auto;
}

.oitis-timeline-center .oitis-timeline-time {
	left: -150px;
	position: absolute;
	right: auto;
	top: 32px;
	z-index: 2;
}

.oitis-timeline-center .oitis-timeline-inverted {
	float: left;
	padding-left: 0;
	padding-right: 55px;
	text-align: right;
}

.oitis-timeline-center .oitis-timeline-inverted:before {
	border-left: 0;
	border-right: 2px solid #e8e8e8;
	left: auto;
	right: -2px;
}

.oitis-timeline-center .oitis-timeline-inverted .oitis-timeline-badge {
	left: auto;
	right: -25px;
}

.oitis-timeline-center .oitis-timeline-inverted .oitis-timeline-panel:before {
	border-width: 10px 0 10px 17px;
	left: auto;
	right: -18px;
}

.oitis-timeline-center .oitis-timeline-inverted .oitis-timeline-panel:after {
	border-width: 10px 0 10px 16px;
	left: auto;
	right: -16px;
}

.oitis-timeline-center .oitis-timeline-inverted .oitis-timeline-time {
	left: auto;
	position: absolute;
	right: -150px;
	top: 32px;
	z-index: 2;
}

.oitis-timeline-zigzag .oitis-timeline-item {
	padding-left: 150px;
}

.oitis-timeline-zigzag .oitis-timeline-item+.oitis-timeline-item {
	margin-top: -80px;
}

.oitis-timeline-zigzag .oitis-timeline-img {
	height: 90px;
	left: 5px;
	width: 90px;
}

.oitis-timeline-zigzag .oitis-timeline-img>img {
	width: 100%;
}

.oitis-timeline-zigzag .oitis-timeline-inverted .oitis-timeline-img {
	right: 5px !important;
}

.oitis-timeline-zigzag .oitis-timeline-item:before {
	bottom: 20px;
	top: 20px;
	-webkit-transform: rotate(35deg);
	transform: rotate(35deg);
}

.oitis-timeline-zigzag .oitis-timeline-inverted {
	padding-left: 0;
	padding-right: 150px;
}

.oitis-timeline-zigzag .oitis-timeline-inverted:before {
	-webkit-transform: rotate(-35deg);
	transform: rotate(-35deg);
}

.oitis-timeline-zigzag .oitis-timeline-item:first-child:before {
	border-left-style: solid;
}

.oitis-timeline-zigzag .oitis-timeline-item:last-child:before {
	display: none;
}

.oitis-timeline-item:last-child:before {
	bottom: 25px;
}

@media screen and (max-width: 991px) {
	.oitis-timeline-zigzag .oitis-timeline-item+.oitis-timeline-item {
		margin-top: -60px;
	}

	.oitis-timeline-zigzag .oitis-timeline-item:before {
		bottom: 0;
	}

	.oitis-timeline-zigzag .oitis-timeline-item {
		padding-left: 120px;
	}

	.oitis-timeline-zigzag .oitis-timeline-inverted {
		padding-left: 0;
		padding-right: 120px;
	}
}

@media screen and (max-width: 767px) {
	.oitis-timeline-center .oitis-timeline-item {
		float: none;
		padding-left: 80px;
		width: auto;
	}

	.oitis-timeline-center .oitis-timeline-item:before {
		left: 25px;
	}

	.oitis-timeline-center .oitis-timeline-badge {
		left: 0;
	}

	.oitis-timeline-center .oitis-timeline-inverted {
		float: none;
		padding-right: 0;
		text-align: left;
	}

	.oitis-timeline-center .oitis-timeline-inverted:before {
		border-left: 2px solid #e8e8e8;
		border-right: 0;
		left: 24px;
		right: auto;
	}

	.oitis-timeline-center .oitis-timeline-inverted .oitis-timeline-badge {
		left: 0;
		right: auto;
	}

	.oitis-timeline-center .oitis-timeline-inverted .oitis-timeline-panel:before {
		border-width: 10px 17px 10px 0 !important;
		left: -18px;
		right: auto;
	}

	.oitis-timeline-center .oitis-timeline-inverted .oitis-timeline-panel:after {
		border-width: 10px 16px 10px 0 !important;
		left: -16px;
		right: auto;
	}

	.oitis-timeline-inverted .oitis-timeline-panel-header {
		float: none;
	}

	.oitis-timeline-inverted .oitis-timeline-left {
		float: left;
		margin-left: 0;
		margin-right: 20px;
	}

	.oitis-timeline-zigzag .oitis-timeline-panel {
		max-height: none;
		min-height: 10px;
		overflow-y: visible;
	}

	.oitis-timeline-zigzag .oitis-timeline-item {
		padding-left: 100px;
	}

	.oitis-timeline-zigzag .oitis-timeline-item+.oitis-timeline-item {
		margin-top: 0;
	}

	.oitis-timeline-zigzag .oitis-timeline-item:before {
		bottom: -25px;
		left: 45px;
		top: 25px;
		-webkit-transform: none;
		transform: none;
	}

	.oitis-timeline-zigzag .oitis-timeline-item:last-child:before {
		bottom: 0;
	}

	.oitis-timeline-zigzag .oitis-timeline-inverted:before {
		-webkit-transform: none;
		transform: none;
	}

	.oitis-timeline-center .oitis-timeline-inverted .oitis-timeline-time,
	.oitis-timeline-center .oitis-timeline-time {
		left: auto;
		margin-bottom: 6px;
		position: relative;
		right: auto;
		top: auto;
	}

	.oitis-timeline-center .oitis-timeline-time-item .oitis-timeline-panel:after,
	.oitis-timeline-center .oitis-timeline-time-item .oitis-timeline-panel:before {
		top: 10px;
	}
}

@media screen and (max-width: 399px) {
	.oitis-timeline-left {
		float: none;
		margin-right: 0;
	}

	.oitis-timeline-right {
		float: none;
		margin-left: 0;
	}

	.oitis-timeline-inverted .oitis-timeline-left {
		float: none;
		margin-right: 0;
	}
}

.oitis-tabs-half .ant-tabs-bar {
	margin-bottom: 0;
}

.oitis-tabs-half .ant-tabs-nav {
	display: block;
}

.oitis-tabs-half .ant-tabs-nav .ant-tabs-tab {
	display: block;
	margin: 0;
	text-align: center;
	width: 50%;
}

.oitis-tabs-half .ant-tabs-nav .ant-tabs-ink-bar {
	width: 50%;
}

.oitis-card-tabs .ant-card-head-title {
	font-size: 16px;
	text-transform: capitalize;
}

.oitis-card-tabs .ant-card-extra {
	position: relative;
	z-index: 2;
}

.oitis-card-tabs .ant-tabs {
	margin-top: -40px;
}

@media screen and (max-width: 575px) {
	.oitis-card-tabs .ant-tabs {
		margin-top: 0;
	}
}

.oitis-card-tabs .ant-tabs-tab {
	margin-right: 15px;
	padding-left: 5px;
	padding-right: 5px;
}

.oitis-card-tabs .ant-tabs-tab-active {
	font-weight: 400;
}

.oitis-card-tabs .ant-tabs-nav-wrap {
	font-size: 16px;
	-ms-justify-content: center;
	justify-content: center;
}

@media screen and (max-width: 575px) {
	.oitis-card-tabs .ant-tabs-nav-wrap {
		-ms-justify-content: flex-start;
		justify-content: flex-start;
	}
}

.oitis-card-tabs .ant-card-body {
	padding-top: 4px;
}

.oitis-tabs-right .ant-tabs-nav-wrap {
	-ms-justify-content: flex-end;
	justify-content: flex-end;
}

@media screen and (max-width: 575px) {
	.oitis-tabs-right .ant-tabs-nav-wrap {
		-ms-justify-content: flex-start;
		justify-content: flex-start;
	}
}

.oitis-app-module {
	display: -ms-flex;
	display: flex;
	flex: 1 1;
	flex-direction: row;
	flex-wrap: wrap;
	margin: -32px -32px 0;
	position: relative;
}

@media screen and (max-width: 575px) {
	.oitis-app-module {
		margin: -20px -15px 0;
	}
}

.oitis-container-wrap .oitis-app-module {
	margin: 0 0 32px;
}

.oitis-module-side {
	display: -ms-flex;
	display: flex;
	flex: 0 1 230px;
	flex-direction: column;
	flex-wrap: nowrap;
	min-width: 230px;
	position: relative;
	z-index: 2;
}

@media screen and (max-width: 991px) {
	.oitis-module-side {
		background-color: #fff;
		min-width: 260px;
	}
}

.oitis-module-side-header {
	-ms-align-items: center;
	align-items: center;
	background-color: #fbfbfb;
	border-bottom: 1px solid #e8e8e8;
	color: #545454;
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	-ms-justify-content: center;
	justify-content: center;
	min-height: 79px;
	padding: 10px;
}

@media screen and (max-width: 991px) {
	.oitis-module-side-header {
		min-height: 72px;
	}
}

.oitis-module-logo {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-size: 20px;
}

.oitis-module-logo i {
	font-size: 28px;
	line-height: 18px;
}

.oitis-module-side-content,
.oitis-module-user-info {
	display: -ms-flex;
	display: flex;
	flex: 1 1;
	flex-direction: column;
	flex-wrap: nowrap;
}

.oitis-module-user-detail {
	font-size: 12px;
}

.oitis-module-add-task {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 24px;
}

.oitis-module-add-task .ant-btn {
	margin-bottom: 0;
}

.oitis-module-nav {
	-ms-justify-content: center;
	justify-content: center;
	list-style: none;
	margin-bottom: 0;
	padding-left: 0;
}

.oitis-module-nav,
.oitis-module-nav li {
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
}

.oitis-module-nav li .oitis-link {
	-ms-align-items: center;
	align-items: center;
	color: #999;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 5px 29px;
	text-decoration: none;
}

.oitis-module-nav li .oitis-link:focus,
.oitis-module-nav li .oitis-link:hover {
	color: #000;
}

.oitis-module-nav li .oitis-link.active {
	color: #038fde;
}

.oitis-module-nav li i {
	font-size: 16px;
	margin-right: 16px;
}

.oitis-module-nav li i[class*=' icon-']:before,
.oitis-module-nav li i[class^='icon-']:before {
	display: block;
}

.oitis-module-nav li span {
	display: inline-block;
	vertical-align: middle;
}

.oitis-module-nav li.oitis-module-nav-label {
	padding: 12px 29px;
}

.oitis-module-box {
	display: -ms-flex;
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	flex-wrap: nowrap;
	max-width: calc(100% - 231px);
	position: relative;
	z-index: 2;
}

@media screen and (max-width: 991px) {
	.oitis-module-box {
		max-width: 100%;
	}
}

.oitis-module-box-header {
	background-color: #fff;
	border-bottom: 1px solid #e8e8e8;
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	-ms-justify-content: center;
	justify-content: center;
	min-height: 79px;
	padding: 10px 26px;
}

@media screen and (max-width: 991px) {
	.oitis-module-box-header {
		min-height: 58px;
		padding: 5px 26px 5px 110px;
		position: relative;
	}

	.oitis-module-box-header .oitis-drawer-btn {
		-ms-align-items: center;
		align-items: center;
		border-radius: 0;
		border-right: 1px solid #e8e8e8;
		display: -ms-flex;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		font-size: 22px;
		height: 58px;
		-ms-justify-content: center;
		justify-content: center;
		left: 0;
		position: absolute;
		text-align: center;
		top: 0;
		width: 98px;
		z-index: 1;
	}
}

.oitis-module-box-header-inner {
	-ms-justify-content: space-between;
	justify-content: space-between;
}

.oitis-module-box-header-inner,
.oitis-module-box-header-right {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.oitis-module-box-header-right {
	margin-left: auto;
}

.oitis-module-box-content {
	background-color: #fff;
	flex: 1 1 auto;
	flex-direction: column;
	flex-wrap: nowrap;
}

.oitis-module-box-content,
.oitis-module-box-topbar {
	display: -ms-flex;
	display: flex;
}

.oitis-module-box-topbar {
	-ms-align-items: center;
	align-items: center;
	border-bottom: 1px solid #e8e8e8;
	flex-direction: row;
	flex-wrap: wrap;
	min-height: 65px;
	padding: 8px 28px;
}

.oitis-module-box-topbar label.ant-checkbox-wrapper {
	margin-right: 10px;
}

.oitis-module-box-topbar .ant-dropdown-trigger {
	cursor: pointer;
	margin-right: 10px;
}

.oitis-module-box-topbar .ant-dropdown-trigger .icon:before {
	position: relative;
	top: 3px;
}

.oitis-module-box-topbar-todo {
	padding-left: 28px;
}

@media screen and (max-width: 991px) {
	.oitis-module-box-topbar {
		min-height: 58px;
		padding-left: 28px;
		padding-right: 28px;
	}
}

.oitis-module-list-icon {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding-right: 10px;
}

.oitis-module-list-icon .oitis-bar-icon .oitis-icon-menu:before {
	position: relative;
	top: 3px;
}

.oitis-toolbar-separator {
	border-left: 1px solid #e8e8e8;
	height: 100%;
	margin: 0 12px;
	width: 1px;
}

.oitis-module-list {
	flex-direction: column;
	flex-wrap: nowrap;
	-ms-justify-content: center;
	justify-content: center;
}

.oitis-module-list,
.oitis-module-list-item {
	display: -ms-flex;
	display: flex;
}

.oitis-module-list-item {
	-ms-align-items: center;
	align-items: center;
	cursor: pointer;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 15px 28px;
	position: relative;
	z-index: 5;
}

.oitis-module-list-item:not(:last-child) {
	border-bottom: 1px solid #e8e8e8;
}

.oitis-module-list-item.oitis-mail-cell {
	-ms-align-items: start;
	align-items: start;
}

@media screen and (max-width: 575px) {
	.oitis-module-list-item {
		padding-bottom: 10px;
		padding-top: 10px;
	}
}

.oitis-module-detail-header {
	margin-bottom: 10px;
}

@media screen and (max-width: 575px) {
	.oitis-module-detail-header {
		margin-bottom: 0;
	}
}

.oitis-module-list-info {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex: 1 1 auto;
	flex-direction: row;
	flex-wrap: nowrap;
	-ms-justify-content: space-between;
	justify-content: space-between;
	max-width: calc(100% - 140px);
}

@media screen and (max-width: 575px) {
	.oitis-module-list-info {
		max-width: calc(100% - 100px);
	}
}

.oitis-module-todo-content {
	flex: 1 1 auto;
	position: relative;
}

.oitis-module-todo-content .oitis-subject {
	margin-bottom: 5px;
}

.oitis-module-todo-right {
	margin-left: 8px;
}

.oitis-module-sidenav {
	background-color: #fff;
	border-right: 1px solid #e8e8e8;
	position: relative;
	z-index: 9;
}

.oitis-module-sidenav .oitis-chat-sidenav-main {
	max-width: 300px;
	min-width: 300px;
	width: 90%;
}

.oitis-module-date {
	max-width: 160px;
}

.oitis-module-detail-item {
	padding: 10px 28px;
}

.oitis-task-title {
	font-size: 18px;
}

@media screen and (max-width: 991px) {
	.oitis-task-title {
		font-size: 16px;
	}
}

.oitis-chat-todo-avatar {
	margin-right: 15px;
}

.oitis-module-side-scroll {
	height: calc(100vh - 201px) !important;
}

.framed-layout .oitis-module-side-scroll {
	height: calc(100vh - 241px) !important;
}

@media screen and (max-width: 991px) {

	.framed-layout .oitis-module-side-scroll,
	.oitis-module-side-scroll {
		height: calc(100vh - 72px) !important;
	}
}

.oitis-module-side-scroll>div:first-child {
	overflow-y: scroll !important;
}

.oitis-module-content-scroll {
	height: calc(100vh - 266px) !important;
}

.framed-layout .oitis-module-content-scroll {
	height: calc(100vh - 306px) !important;
}

@media screen and (max-width: 991px) {

	.framed-layout .oitis-module-content-scroll,
	.oitis-module-content-scroll {
		height: calc(100vh - 238px) !important;
	}
}

@media screen and (max-width: 575px) {
	.oitis-module-content-scroll {
		height: calc(100vh - 216px) !important;
	}
}

.oitis-module-content-scroll>div:first-child {
	overflow-y: scroll !important;
}

.oitis-todo-detail-content-scroll {
	height: calc(100vh - 319px) !important;
}

.framed-layout .oitis-todo-detail-content-scroll {
	height: calc(100vh - 359px) !important;
}

@media screen and (max-width: 991px) {

	.framed-layout .oitis-todo-detail-content-scroll,
	.oitis-todo-detail-content-scroll {
		height: calc(100vh - 291px) !important;
	}
}

@media screen and (max-width: 575px) {
	.oitis-todo-detail-content-scroll {
		height: calc(100vh - 270px) !important;
	}
}

.oitis-todo-detail-content-scroll>div:first-child {
	overflow-y: scroll !important;
}

.oitis-loader-view {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	flex-wrap: nowrap;
	-ms-justify-content: center;
	justify-content: center;
}

.oitis-loader-view .ant-spin {
	margin: 0;
}

.oitis-app-module .oitis-loader-view {
	height: calc(100vh - 266px) !important;
}

.framed-layout .oitis-app-module .oitis-loader-view {
	height: calc(100vh - 306px) !important;
}

@media screen and (max-width: 991px) {

	.framed-layout .oitis-app-module .oitis-loader-view,
	.oitis-app-module .oitis-loader-view {
		height: calc(100vh - 238px) !important;
	}
}

@media screen and (max-width: 575px) {
	.oitis-app-module .oitis-loader-view {
		height: calc(100vh - 244px) !important;
	}
}

.oitis-chat-module .oitis-loader-view {
	height: calc(100vh - 122px) !important;
}

.framed-layout .oitis-chat-module .oitis-loader-view {
	height: calc(100vh - 162px) !important;
}

@media screen and (max-width: 991px) {
	.framed-layout .oitis-chat-module .oitis-loader-view {
		height: calc(100vh - 122px) !important;
	}
}

@media screen and (max-width: 575px) {
	.oitis-chat-module .oitis-loader-view {
		height: calc(100vh - 100px) !important;
	}
}

.oitis-loader-position {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 10;
}

.oitis-module-default {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	height: 100%;
	-ms-justify-content: center;
	justify-content: center;
	padding: 15px;
}

.oitis-no-content-found {
	flex: 1 1;
	font-size: 16px;
}

.oitis-task-des,
.oitis-task-input {
	padding-left: 0;
}

.oitis-module-box-row {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
}

.oitis-module-box-column,
.oitis-module-list-content {
	display: -ms-flex;
	display: flex;
	flex: 1 1;
	flex-direction: column;
	flex-wrap: nowrap;
	width: 100%;
}

.oitis-mail-list-info {
	display: -ms-flex;
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	flex-wrap: nowrap;
	max-width: calc(100% - 150px);
	padding-left: 10px;
}

@media screen and (max-width: 575px) {
	.oitis-mail-list-info {
		margin-top: 10px;
		max-width: 100%;
		padding-left: 0;
	}
}

.oitis-mail-user-info {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-left: 10px;
}

.oitis-mail-user-info .oitis-avatar {
	margin-right: 10px;
}

.oitis-mail-user-info .oitis-badge {
	margin-bottom: 0;
}

.oitis-mail-user-des {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 3px;
}

.oitis-mail-user-des .oitis-icon-btn,
.oitis-mail-user-des .oitis-time {
	margin-left: auto;
}

.oitis-mail-detail-inner {
	padding: 24px;
}

@media screen and (max-width: 991px) {
	.oitis-mail-detail-inner {
		padding-left: 28px;
		padding-right: 28px;
	}
}

.oitis-mail-header {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

@media screen and (max-width: 498px) {
	.oitis-mail-header {
		-ms-align-items: flex-start;
		align-items: flex-start;
		display: block;
		flex-direction: row;
		flex-wrap: nowrap;
	}
}

.oitis-mail-header-content {
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
}

.oitis-mail-header-content .oitis-subject {
	margin-bottom: 5px;
}

.oitis-show-link {
	color: #038fde;
	cursor: pointer;
	margin-bottom: 8px;
}

.oitis-show-detail {
	margin-bottom: 12px;
}

.oitis-module-list-content .oitis-subject {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}

.oitis-module-list-content .oitis-message {
	color: #8d8d8d;
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	font-size: 13px;
}

.oitis-module-list-content .oitis-message p {
	margin-bottom: 8px;
}

.oitis-size {
	margin: 5px 0;
}

.oitis-sender-name {
	font-size: 15px;
	font-weight: 500;
	margin-right: 0;
}

.oitis-send-subject {
	width: calc(100% - 220px);
}

@media screen and (max-width: 575px) {
	.oitis-send-subject {
		margin-right: 6px;
		width: calc(100% - 180px);
	}
}

.oitis-mail-header-actions {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	-ms-justify-content: flex-end;
	justify-content: flex-end;
	min-width: 100px;
}

.oitis-mail-header-actions .icon-btn:not(:last-child) {
	margin-right: 3px;
}

@media screen and (max-width: 498px) {
	.oitis-mail-header-actions {
		-ms-justify-content: flex-start;
		justify-content: flex-start;
		min-width: 10px;
	}
}

.oitis-attachment-block {
	position: relative;
}

.oitis-attachment-row {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 -10px;
}

@media screen and (max-width: 575px) {
	.oitis-attachment-row {
		margin: 0 -5px;
	}
}

.oitis-attachment-col {
	padding: 0 10px;
}

.oitis-attachment-col img {
	width: 80px;
}

.oitis-attachment-col .size {
	padding-top: 5px;
}

@media screen and (max-width: 575px) {
	.oitis-attachment-col {
		padding: 0 5px;
	}

	.oitis-mail-list {
		border-right: 0;
	}

	.oitis-mail-detail,
	.oitis-mail-list {
		flex: 1 1 auto;
		max-width: 100%;
	}
}

.oitis-chat-module {
	position: relative;
}

.oitis-chat-module-box {
	background-color: #fafafa;
	flex: 1 1 auto;
	flex-direction: row;
	flex-wrap: nowrap;
	position: relative;
	width: 100%;
}

.oitis-chat-module-box,
.oitis-chat-sidenav {
	display: -ms-flex;
	display: flex;
}

.oitis-chat-sidenav {
	border-right: 1px solid #e8e8e8;
	flex: 0 1 315px;
	flex-direction: column;
	flex-wrap: nowrap;
	min-width: 315px;
}

.oitis-chat-sidenav-header {
	background-color: #fbfbfb;
	border-bottom: 1px solid #e8e8e8;
	flex-direction: column;
	padding: 25px 20px 12px;
}

.oitis-chat-sidenav-header,
.oitis-chat-user-hd {
	display: -ms-flex;
	display: flex;
	flex-wrap: nowrap;
}

.oitis-chat-user-hd {
	flex-direction: row;
	margin-bottom: 25px;
}

.oitis-chat-search-wrapper {
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	margin-bottom: 0;
}

.oitis-chat-avatar {
	min-width: 50px;
}

.oitis-chat-user-row .oitis-chat-avatar {
	min-width: 10px;
	padding: 0 5px;
}

.oitis-chat-user-hd .oitis-chat-avatar {
	cursor: pointer;
}

.oitis-chat-sidenav-scroll {
	height: calc(100vh - 295px) !important;
}

.framed-layout .oitis-chat-sidenav-scroll {
	height: calc(100vh - 335px) !important;
}

@media screen and (max-width: 991px) {

	.framed-layout .oitis-chat-sidenav-scroll,
	.oitis-chat-sidenav-scroll {
		height: calc(100vh - 175px) !important;
	}
}

.oitis-chat-sidenav-scroll>div:first-child {
	overflow-y: scroll !important;
}

.oitis-chat-sidenav-scroll-tab-1,
.oitis-chat-sidenav-scroll-tab-2 {
	height: calc(100vh - 320px) !important;
}

.framed-layout .oitis-chat-sidenav-scroll-tab-1,
.framed-layout .oitis-chat-sidenav-scroll-tab-2 {
	height: calc(100vh - 360px) !important;
}

@media screen and (max-width: 991px) {

	.framed-layout .oitis-chat-sidenav-scroll-tab-1,
	.framed-layout .oitis-chat-sidenav-scroll-tab-2,
	.oitis-chat-sidenav-scroll-tab-1,
	.oitis-chat-sidenav-scroll-tab-2 {
		height: calc(100vh - 198px) !important;
	}
}

.oitis-chat-sidenav-scroll-tab-1>div:first-child,
.oitis-chat-sidenav-scroll-tab-2>div:first-child {
	overflow-y: scroll !important;
}

.oitis-chat-list-scroll {
	height: calc(100vh - 268px) !important;
}

.framed-layout .oitis-chat-list-scroll {
	height: calc(100vh - 308px) !important;
}

@media screen and (max-width: 1199px) {
	.oitis-chat-list-scroll {
		height: calc(100vh - 242px) !important;
	}

	.framed-layout .oitis-chat-list-scroll {
		height: calc(100vh - 282px) !important;
	}
}

@media screen and (max-width: 991px) {
	.framed-layout .oitis-chat-list-scroll {
		height: calc(100vh - 242px) !important;
	}
}

@media screen and (max-width: 575px) {
	.oitis-chat-list-scroll {
		height: calc(100vh - 220px) !important;
	}
}

.oitis-chat-list-scroll>div:first-child {
	overflow-y: scroll !important;
}

.oitis-chat-sidenav-content {
	background-color: #fff;
}

.oitis-chat-sidenav-title {
	color: #1890ff;
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	font-size: 14px;
	-ms-justify-content: center;
	justify-content: center;
	padding: 10px 16px;
}

@media screen and (max-width: 1199px) {
	.oitis-chat-sidenav-title {
		font-size: 16px;
	}
}

@media screen and (max-width: 767px) {
	.oitis-chat-sidenav-title {
		font-size: 15px;
	}
}

.oitis-chat-tabs-header {
	background-color: #fbfbfb !important;
}

.oitis-chat-user {
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
}

.oitis-chat-user.oitis-chat-user-center {
	-ms-align-items: center;
	align-items: center;
	-ms-justify-content: center;
	justify-content: center;
}

.oitis-chat-user.oitis-chat-user-center .oitis-chat-avatar {
	margin-left: auto;
}

.oitis-chat-user-item {
	cursor: pointer;
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	-ms-justify-content: center;
	justify-content: center;
	max-height: 96px;
	padding: 16px;
}

.oitis-chat-user-item:not(:last-child) {
	border-bottom: 1px solid #e8e8e8;
}

.oitis-chat-user-item.active,
.oitis-chat-user-item:hover {
	background-color: #e6faff;
}

.oitis-chat-user-row {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 -5px;
}

.oitis-tabs-half .ant-tabs-nav-list,
.oitis-tabs-half .ant-tabs-nav:before {
	width: 100%;
}

.oitis-chat-info {
	flex: 1 1;
	max-width: calc(100% - 80px);
	padding: 0 5px;
}

.oitis-chat-info p {
	margin-bottom: 0;
}

.oitis-chat-info .h4 {
	display: block;
	margin-bottom: 3px;
}

.oitis-chat-info-des {
	color: #595959;
	font-size: 13px;
}

.oitis-chat-contact-col {
	flex: 1 1;
	max-width: calc(100% - 50px);
	padding: 0 5px;
}

.oitis-chat-contact-col p {
	margin-bottom: 0;
}

.oitis-chat-contact-col .h4 {
	display: block;
	margin-bottom: 3px;
}

.oitis-chat-date {
	padding: 0 5px;
	text-align: right;
}

.oitis-fs-80 {
	font-size: 80px !important;
	line-height: 80px !important;
}

.oitis-comment-box .oitis-fs-80 {
	margin-bottom: 12px;
	text-align: center;
}

@media screen and (max-width: 575px) {
	.oitis-fs-80 {
		font-size: 60px !important;
		line-height: 60px !important;
	}
}

.oitis-comment-box {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	flex-wrap: nowrap;
	height: calc(100vh - 122px) !important;
	-ms-justify-content: center;
	justify-content: center;
}

.framed-layout .oitis-comment-box {
	height: calc(100vh - 162px) !important;
}

@media screen and (max-width: 991px) {
	.framed-layout .oitis-comment-box {
		height: calc(100vh - 122px) !important;
	}
}

@media screen and (max-width: 575px) {
	.oitis-comment-box {
		height: calc(100vh - 100px) !important;
	}
}

.oitis-chat-box {
	flex: 1 1 auto;
	flex-direction: column;
	flex-wrap: nowrap;
	max-width: 100%;
	position: relative;
	z-index: 2;
}

.oitis-chat-box,
.oitis-chat-main {
	display: -ms-flex;
	display: flex;
}

.oitis-chat-main {
	flex-direction: column;
	flex-wrap: nowrap;
}

.oitis-chat-main-header {
	-ms-align-items: center;
	align-items: center;
	background-color: #fff;
	border-bottom: 1px solid #e8e8e8;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 16px;
	width: 100%;
}

@media screen and (max-width: 1199px) {
	.oitis-chat-main-header {
		padding: 8px 16px;
	}
}

@media screen and (max-width: 991px) {
	.oitis-chat-main-header {
		padding: 8px 30px;
	}
}

.oitis-chat-main-header-info {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-right: auto;
}

.oitis-chat-contact-name {
	font-size: 20px;
	font-weight: 500;
}

@media screen and (max-width: 1199px) {
	.oitis-chat-contact-name {
		font-size: 16px;
	}
}

@media screen and (max-width: 767px) {
	.oitis-chat-contact-name {
		font-size: 15px;
	}
}

.oitis-chat-main-footer {
	background-color: #fff;
	border-top: 1px solid #e8e8e8;
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	padding: 6px 16px;
	width: 100%;
}

.oitis-chat-main-footer .oitis-form-group {
	margin-bottom: 0;
}

.oitis-chat-item {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	padding: 16px;
}

@media screen and (max-width: 991px) {
	.oitis-chat-item {
		padding: 16px 30px;
	}
}

.oitis-chat-item .oitis-bubble-block {
	width: calc(100% - 40px);
}

.oitis-chat-item .oitis-bubble {
	background-color: #fff;
	border: 1px solid #d9d9d9;
	border-radius: 20px 20px 20px 0;
	display: inline-block;
	margin-left: 16px;
	max-width: 600px;
	padding: 12px 15px;
	position: relative;
}

.oitis-chat-item.oitis-flex-row-reverse .oitis-bubble-block {
	text-align: right;
}

.oitis-chat-item.oitis-flex-row-reverse .oitis-bubble {
	background-color: #e6faff;
	border-radius: 20px 20px 0 20px;
	margin-left: 0;
	margin-right: 16px;
	text-align: left;
}

.oitis-chat-item .ant-avatar {
	display: block;
}

.oitis-chat-btn {
	font-size: 38px !important;
	margin-right: 20px;
}

textarea.oitis-chat-textarea {
	border-radius: 0;
	box-shadow: none;
	height: 40px;
}

.oitis-chat-sent {
	cursor: pointer;
	font-size: 18px;
	line-height: 40px;
	min-width: 40px;
	text-align: center;
}

.oitis-last-message-time {
	color: #8c8c8c;
	font-size: 11px;
}

@media screen and (max-width: 1199px) {
	.oitis-chat-main-header .oitis-size-60 {
		height: 50px !important;
		line-height: 50px;
		width: 50px !important;
	}

	.oitis-chat-main-header button {
		margin-bottom: 0;
	}

	.oitis-chat-main-header-info .oitis-status-pos {
		max-width: 50px;
	}
}

@media screen and (max-width: 575px) {
	.oitis-module-default h1 {
		font-size: 17px;
	}
}

.oitis-contact-item {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	padding: 8px 28px;
}

.oitis-contact-item:not(:last-child) {
	border-bottom: 1px solid #e8e8e8;
}

.oitis-dragndrop-item {
	padding: 24px 16px;
}

.oitis-dragndrop-item .oitis-draggable-icon {
	margin-left: -16px;
}

@media screen and (max-width: 575px) {
	.oitis-dragndrop-item {
		padding-right: 5px;
	}
}

.oitis-contact-list-info {
	padding-left: 10px;
}

.oitis-contact-list-info .oitis-email {
	word-break: break-all;
}

.oitis-contact-list-info .oitis-toolbar-separator {
	display: inline-block;
}

@media screen and (max-width: 575px) {
	.oitis-contact-list-info .oitis-toolbar-separator {
		display: none;
	}

	.oitis-contact-list-info {
		max-width: calc(100% - 50px);
		padding-left: 0;
	}

	.oitis-contact-list-info .oitis-job-title {
		display: none;
	}
}

.oitis-module-contact-content {
	flex: 1 1;
}

.oitis-module-contact-right {
	margin-left: 8px;
}

.oitis-contact-name {
	font-size: 15px;
}

.oitis-draggable-icon {
	color: #8c8c8c;
	cursor: all-scroll;
}

.oitis-draggable-icon:active,
.oitis-draggable-icon:focus,
.oitis-draggable-icon:hover {
	color: #262626;
	cursor: all-scroll;
}

.oitis-module-side-nav {
	padding: 20px 0;
}

.oitis-modal-box-row {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.oitis-modal-box-row .oitis-modal-box-avatar {
	margin-left: 16px;
	-ms-order: 2;
	order: 2;
}

.oitis-modal-box-row .oitis-modal-box-avatar .ant-avatar {
	height: 120px;
	width: 120px;
}

@media screen and (max-width: 575px) {
	.oitis-modal-box-row .oitis-modal-box-avatar {
		margin-bottom: 16px;
		margin-left: 0;
		-ms-order: 1;
		order: 1;
		text-align: center;
	}
}

.oitis-modal-box-row .oitis-modal-box-form-item {
	flex: 1 1;
	-ms-order: 1;
	order: 1;
}

@media screen and (max-width: 575px) {
	.oitis-modal-box-row .oitis-modal-box-form-item {
		-ms-order: 2;
		order: 2;
	}

	.oitis-modal-box-row {
		display: -ms-flex;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
	}
}

.oitis-dragndrop-actions {
	flex-direction: row;
	flex-wrap: wrap;
}

.oitis-dragndrop-actions,
.oitis-rbc-calendar {
	display: -ms-flex;
	display: flex;
}

.oitis-rbc-calendar {
	flex-direction: column;
	flex-wrap: nowrap;
	overflow: hidden;
	padding-bottom: 32px;
}

@media screen and (max-width: 575px) {
	.oitis-rbc-calendar {
		margin-bottom: 20px;
	}
}

.oitis-rbc-calendar .rbc-calendar {
	display: -ms-flex;
	display: flex;
	flex: 1 1 auto;
	flex-direction: column;
	flex-wrap: nowrap;
	height: 100%;
}

.rbc-event.rbc-selected {
	background-color: #006eb8;
}

.rbc-slot-selection {
	background-color: #7ddcff;
}

.rbc-toolbar button.rbc-active,
.rbc-toolbar button:active {
	background-color: #52c6f7;
	background-image: none;
	border-color: #038fde;
	box-shadow: none;
	color: #fff;
}

.rbc-toolbar button.rbc-active:focus,
.rbc-toolbar button.rbc-active:hover,
.rbc-toolbar button:active:focus,
.rbc-toolbar button:active:hover,
.rbc-toolbar button:focus,
.rbc-toolbar button:hover {
	background-color: #52c6f7;
	border-color: #038fde;
	color: #fff;
}

.rbc-month-row {
	margin: 10px;
	min-height: 100px;
	overflow: visible;
}

.rbc-event {
	min-height: 50px;
}

.rbc-btn-group button {
	cursor: pointer;
}

@media screen and (max-width: 867px) {
	.rbc-toolbar {
		-ms-align-items: flex-start;
		align-items: flex-start;
		display: -ms-flex;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
	}

	.rbc-toolbar .rbc-toolbar-label {
		margin: 8px 0;
	}
}

@media screen and (max-width: 467px) {
	.rbc-toolbar {
		font-size: 12px;
	}

	.rbc-toolbar button {
		padding: 5px 10px;
	}
}

.oitis-com-calendar .ant-fullcalendar-header .ant-select {
	margin-bottom: 8px;
	margin-left: 8px;
}

.oitis-com-calendar .ant-picker-calendar-header {
	flex-wrap: wrap;
}

.oitis-com-calendar .ant-picker-calendar-header .ant-select {
	margin-bottom: 8px;
}

@media screen and (max-width: 349px) {
	.oitis-com-calendar {
		margin: 0 -20px;
	}
}

.oitis-com-calendar .ant-select .ant-select-arrow {
	top: 50%;
}

.oitis-com-calendar-card {
	border: 1px solid #e8e8e8;
	border-radius: 4px;
	max-width: 300px;
}

@media screen and (max-width: 349px) {
	.oitis-com-calendar-card .oitis-com-calendar {
		margin: 0;
	}
}

.oitis-profileon {
	border-radius: 10px;
	margin-bottom: 20px;
	overflow: hidden;
	position: relative;
}

.oitis-profileon-content {
	background-color: transparent;
	background: -o-linear-gradient(top, transparent, rgba(0, 0, 0, 0.9));
	bottom: 0;
	left: 0;
	padding: 15px 20px;
	position: absolute;
	right: 0;
	z-index: 2;
}

.oitis-profileon-content * {
	color: #fff;
}

.oitis-agents-list .oitis-profileon-content {
	padding: 10px;
}

.oitis-profileon-thumb {
	position: relative;
}

.oitis-profileon-thumb>img {
	width: 100%;
}

.oitis-profileon-thumb-htctrcrop {
	overflow: hidden;
	padding-bottom: 80%;
	position: relative;
}

.oitis-profileon-thumb-htctrcrop>img {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	width: 100%;
}

.oitis-profileon-title {
	margin-bottom: 5px;
}

.oitis-agents-list .oitis-profileon {
	margin-bottom: 0;
}

.oitis-follower {
	border-bottom: 1px solid #d9d9d9;
	margin-bottom: 15px;
	padding-bottom: 20px;
}

.oitis-follower-list {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	list-style: none;
	margin: 0 -5px;
	padding-left: 0;
}

.oitis-profile-banner .oitis-follower-list {
	margin: 0 -20px;
}

.oitis-follower-list li {
	color: #8c8c8c;
	font-size: 14px;
	padding: 0 5px;
	width: 33.33%;
}

.oitis-profile-banner .oitis-follower-list li {
	color: #fff;
	padding: 0 20px;
	width: auto;
}

.oitis-follower-list li:not(:first-child) {
	border-left: 1px solid #d9d9d9;
}

.oitis-profile-banner .oitis-follower-list li:not(:first-child) {
	border-left-color: #fff;
}

.oitis-follower-title {
	color: #262626;
	display: block;
	font-size: 14px;
}

.oitis-profile-banner .oitis-follower-title {
	color: #fff;
}

.oitis-wall-scroll {
	height: calc(100vh - 154px) !important;
}

.framed-layout .oitis-wall-scroll {
	height: calc(100vh - 192px) !important;
}

@media screen and (max-width: 991px) {

	.framed-layout .oitis-wall-scroll,
	.oitis-wall-scroll {
		height: calc(100vh - 124px) !important;
	}
}

@media screen and (max-width: 575px) {
	.oitis-wall-scroll {
		height: calc(100vh - 132px) !important;
	}
}

.oitis-wall-postlist {
	padding: 5px;
}

.oitis-entry-sec {
	margin-bottom: 20px;
	position: relative;
}

.ant-card .oitis-entry-sec,
.oitis-card .oitis-entry-sec {
	margin-bottom: 0;
}

.oitis-entry-title {
	border-bottom: 1px solid #d9d9d9;
	font-size: 16px;
	margin-bottom: 20px;
	padding-bottom: 15px;
	position: relative;
}

.oitis-entry-title:before {
	background-color: #038fde;
	bottom: -2px;
	content: '';
	height: 3px;
	left: 0;
	position: absolute;
	width: 42px;
	z-index: 1;
}

.ant-card .oitis-entry-title:before,
.oitis-card .oitis-entry-title:before {
	display: none;
}

.ant-card .oitis-entry-title,
.oitis-card .oitis-entry-title {
	border-bottom: 0;
	margin-bottom: 15px;
	padding-bottom: 0;
}

.oitis-fnd-list {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	list-style: none;
	margin: 0 -5px 20px;
	padding-left: 0;
}

.oitis-fnd-list li {
	padding: 0 5px;
	width: 33.33%;
}

@media (max-width: 399px) {
	.oitis-fnd-list li {
		width: 50%;
	}
}

.oitis-user-fnd {
	border-radius: 10px;
	overflow: hidden;
	position: relative;
}

.oitis-user-fnd>img {
	width: 100%;
}

.oitis-user-fnd-content {
	background-color: rgba(0, 0, 0, 0.1);
	background: -o-linear-gradient(top, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.95));
	color: #fff;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	height: 100%;
	left: 0;
	padding: 10px;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

.oitis-user-fnd-content h6 {
	align-self: flex-end;
	color: #fff;
	margin-bottom: 0;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}

.oitis-user-fnd-content .ant-badge {
	display: block;
	height: 10px;
	margin: 0;
	position: absolute;
	right: 10px;
	top: 10px;
	width: 10px;
	z-index: 1;
}

.oitis-user-fnd-content .ant-badge-status-dot {
	border: 1px solid #fff;
	display: block;
	height: 10px;
	top: 0;
	width: 10px;
}

.oitis-gallery-list {
	border-radius: 10px;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	list-style: none;
	margin: 0 -0.5px 15px;
	overflow: hidden;
	padding-left: 0;
}

.oitis-gallery-list li {
	margin-bottom: 1px;
	padding: 0 0.5px;
	width: 33.33%;
}

@media (max-width: 399px) {
	.oitis-gallery-list li {
		width: 50%;
	}
}

.oitis-gallery-list li>img {
	width: 100%;
}

.oitis-gallery-thumb {
	cursor: pointer;
	position: relative;
}

.oitis-gallery-thumb:before {
	background-color: rgba(0, 0, 0, 0.01);
	background: -o-linear-gradient(top,
			rgba(0, 0, 0, 0.01),
			rgba(0, 0, 0, 0.95));
	content: '';
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

.oitis-gallery-thumb-content {
	bottom: 0;
	color: #fff;
	left: 0;
	padding: 10px;
	position: absolute;
	right: 0;
	z-index: 2;
}

.oitis-gallery-thumb-content h6 {
	color: #fff;
	font-size: 10px;
	margin-bottom: 0;
}

.oitis-wall-user-info {
	margin-bottom: 6px;
}

.oitis-wall-user-title {
	margin-bottom: 4px;
}

.oitis-gallery-item {
	margin-bottom: 15px;
	position: relative;
}

.oitis-gallery-item img {
	border-radius: 10px;
	cursor: pointer;
	width: 100%;
}

.oitis-gallery-item-content {
	-ms-align-items: center;
	align-items: center;
	background-color: rgba(0, 0, 0, 0.7);
	bottom: 0;
	color: #fff;
	flex-direction: column;
	flex-wrap: nowrap;
	-ms-justify-content: center;
	justify-content: center;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
}

.oitis-gallery-grid,
.oitis-gallery-item-content {
	display: -ms-flex;
	display: flex;
}

.oitis-gallery-grid {
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 -5px;
}

.oitis-gallery-grid .oitis-gallery-item {
	padding: 0 5px;
}

.oitis-gallery-grid .oitis-gallery-item-content {
	left: 5px;
	right: 5px;
}

.oitis-gallery-2 .oitis-gallery-item,
.oitis-gallery-4 .oitis-gallery-item,
.oitis-gallery-4-more .oitis-gallery-item {
	width: 50%;
}

.oitis-gallery-3 .oitis-gallery-item {
	width: 33.33%;
}

.oitis-wall-comment-box {
	padding: 0 30px;
}

@media screen and (max-width: 1199px) {
	.oitis-wall-comment-box {
		padding: 0 20px;
	}
}

@media screen and (max-width: 1100px) {
	.oitis-wall-comment-box {
		padding: 0;
	}
}

@media screen and (max-width: 991px) {
	.oitis-wall-comment-box {
		padding: 0 30px;
	}
}

@media screen and (max-width: 767px) {
	.oitis-wall-comment-box {
		padding-left: 15px;
		padding-right: 0;
	}
}

.oitis-profile-banner {
	background-color: #038fde;
	color: #fff;
	margin: -32px -32px -58px;
	padding: 32px 32px 88px;
	position: relative;
}

@media screen and (max-width: 575px) {
	.oitis-profile-banner {
		margin: -20px -15px -58px;
		padding: 20px 15px 83px;
	}
}

.oitis-profile-banner:before {
	background-color: rgba(0, 0, 0, 0.3);
	content: '';
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

.oitis-profile-banner>div {
	position: relative;
	z-index: 2;
}

.oitis-profile-banner-top {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 40px;
}

@media (max-width: 575px) {
	.oitis-profile-banner-top {
		display: -ms-flex;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		margin-bottom: 25px;
	}
}

.oitis-profile-banner-top-left {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

@media (max-width: 575px) {
	.oitis-profile-banner-top-left {
		display: -ms-flex;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		margin-bottom: 25px;
	}
}

.oitis-profile-banner-avatar {
	margin-right: 1.5rem;
}

@media (max-width: 575px) {
	.oitis-profile-banner-avatar {
		margin-bottom: 8px;
		margin-right: 0;
	}
}

.oitis-profile-banner-top-right {
	margin-left: auto;
	text-align: center;
}

@media (max-width: 575px) {
	.oitis-profile-banner-top-right {
		margin-left: 0;
	}
}

.oitis-profile-banner-bottom {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

@media (max-width: 575px) {
	.oitis-profile-banner-bottom {
		display: -ms-flex;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
	}

	.oitis-profile-banner-bottom .oitis-tab-list {
		margin-bottom: 10px;
	}
}

.oitis-profile-banner .h1,
.oitis-profile-banner .h2,
.oitis-profile-banner .h3,
.oitis-profile-banner .h4,
.oitis-profile-banner .h5,
.oitis-profile-banner .h6,
.oitis-profile-banner .oitis-link,
.oitis-profile-banner h1,
.oitis-profile-banner h2,
.oitis-profile-banner h3,
.oitis-profile-banner h4,
.oitis-profile-banner h5,
.oitis-profile-banner h6 {
	color: #fff;
}

.oitis-profile-banner .oitis-link:focus,
.oitis-profile-banner .oitis-link:hover {
	color: #fa8c15;
}

.oitis-profile-content {
	position: relative;
	z-index: 2;
}

.oitis-profile-setting {
	margin-left: auto;
}

@media (max-width: 575px) {
	.oitis-profile-setting {
		margin-left: 0;
	}

	.oitis-profile-content-right {
		-ms-align-items: center !important;
		align-items: center !important;
		display: -ms-flex !important;
		display: flex !important;
		flex-direction: row !important;
		flex-wrap: wrap !important;
	}

	.oitis-profile-content-right .oitis-text-truncate {
		margin-left: auto;
		width: auto;
	}
}

.oitis-pro-contact-list:not(:last-child) {
	margin-bottom: 1rem;
}

.oitis-grid-thumb-equal,
.oitis-thumb-equal {
	height: 0;
	overflow: hidden;
	padding-bottom: 68%;
	position: relative;
	width: 100%;
}

.oitis-grid-thumb-cover,
.oitis-thumb-cover {
	display: block;
	height: 100%;
	position: absolute;
	width: 100%;
}

.oitis-grid-thumb-cover img,
.oitis-thumb-cover-img {
	height: auto;
	max-width: 100%;
	min-height: 100%;
	object-fit: contain;
	width: 100%;
}

.oitis-product-image .oitis-grid-thumb-equal {
	padding-bottom: 82%;
}

.oitis-product-row {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	-ms-justify-content: center;
	justify-content: center;
	overflow: hidden;
	position: relative;
	text-align: right;
}

.oitis-product-row.even {
	text-align: left;
}

.oitis-product-col {
	order: 1;
	width: 50%;
}

.odd .oitis-product-thumb {
	order: 2;
}

.oitis-product-content {
	padding: 20px 55px;
}

.oitis-product-content p {
	margin-bottom: 10px;
}

.oitis-product-row .oitis-grid-thumb-equal {
	padding-bottom: 69%;
}

.oitis-product-thumb img {
	width: 100%;
}

.oitis-product-content h4 {
	margin-bottom: 25px;
}

@media screen and (max-width: 1230px) {
	.oitis-product-row .oitis-grid-thumb-equal {
		padding-bottom: 71%;
	}
}

@media screen and (max-width: 1199px) {
	.oitis-product-row {
		display: -ms-flex;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
	}

	.oitis-product-row,
	.oitis-product-row.even {
		text-align: center;
	}

	.oitis-product-content h4 {
		margin-bottom: 10px;
	}

	.oitis-product-content {
		padding: 20px 35px;
	}

	.odd .oitis-product-thumb,
	.oitis-product-col {
		order: 1;
		width: 100%;
	}

	.oitis-product-row .oitis-grid-thumb-equal {
		padding-bottom: 40%;
	}
}

@media screen and (max-width: 991px) {
	.oitis-product-row {
		display: -ms-flex;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		text-align: right;
	}

	.oitis-product-row.even {
		text-align: left;
	}

	.oitis-product-image .oitis-grid-thumb-equal {
		padding-bottom: 120%;
	}

	.odd .oitis-product-thumb,
	.oitis-product-col {
		order: 1;
		width: 50%;
	}

	.odd .oitis-product-thumb {
		order: 2;
	}

	.oitis-product-row .oitis-grid-thumb-equal {
		padding-bottom: 70%;
	}
}

@media screen and (max-width: 767px) {
	.oitis-product-row {
		display: -ms-flex;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
	}

	.oitis-product-row,
	.oitis-product-row.even {
		text-align: center;
	}

	.oitis-product-row:last-child .oitis-product-col {
		padding-bottom: 0;
	}

	.oitis-product-row:last-child .ant-btn {
		margin-bottom: 0;
	}

	.oitis-product-content {
		padding: 20px 0;
	}

	.odd .oitis-product-thumb,
	.oitis-product-col {
		order: 1;
		width: 100%;
	}

	.oitis-product-image .oitis-grid-thumb-equal,
	.oitis-product-row .oitis-grid-thumb-equal {
		padding-bottom: 44%;
	}
}

.oitis-product-item {
	background: #fff;
	border: 1px solid #e8e8e8;
	border-radius: 12px;
	box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.03);
	margin-bottom: 32px;
	overflow: hidden;
	transition: box-shadow 0.1s ease-out;
}

.oitis-product-item:active,
.oitis-product-item:focus,
.oitis-product-item:focus-within,
.oitis-product-item:hover,
.oitis-product-item:target {
	border: 1px solid #ddd;
	box-shadow: 0 0 5px 3px rgba(0, 0, 0, 0.05);
}

@media screen and (max-width: 575px) {
	.oitis-product-item {
		margin-bottom: 20px;
	}
}

.oitis-product-body {
	padding: 24px;
}

.oitis-product-body+.oitis-product-footer {
	padding-top: 0;
}

.oitis-product-body :last-child {
	margin-bottom: 0;
}

.oitis-product-image>img {
	width: 100%;
}

.oitis-product-footer {
	padding: 24px 24px 15px;
}

.oitis-product-horizontal {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.oitis-product-horizontal .oitis-product-image {
	width: 25%;
}

@media screen and (max-width: 1199px) {
	.oitis-product-horizontal .oitis-product-image {
		width: 33.33333%;
	}
}

@media screen and (max-width: 991px) {
	.oitis-product-horizontal .oitis-product-image {
		width: 25%;
	}
}

@media screen and (max-width: 767px) {
	.oitis-product-horizontal .oitis-product-image {
		width: 100%;
	}
}

.oitis-product-horizontal .oitis-product-body {
	width: 53%;
}

@media screen and (min-width: 1400px) {
	.oitis-product-horizontal .oitis-product-body {
		width: 55%;
	}
}

@media screen and (max-width: 1199px) {
	.oitis-product-horizontal .oitis-product-body {
		width: 41.66667%;
	}
}

@media screen and (max-width: 991px) {
	.oitis-product-horizontal .oitis-product-body {
		width: 50%;
	}
}

@media screen and (max-width: 767px) {
	.oitis-product-horizontal .oitis-product-body {
		width: 100%;
	}
}

.oitis-product-horizontal .oitis-product-body+.oitis-product-footer {
	padding-top: 24px;
}

@media screen and (max-width: 767px) {
	.oitis-product-horizontal .oitis-product-body+.oitis-product-footer {
		padding-top: 0;
	}
}

.oitis-product-horizontal .oitis-product-footer {
	text-align: center;
	width: 22%;
}

@media screen and (min-width: 1400px) {
	.oitis-product-horizontal .oitis-product-footer {
		width: 20%;
	}
}

.oitis-product-horizontal .oitis-product-footer .ant-btn:not(:last-child) {
	margin-left: 8px;
	margin-right: 8px;
}

@media screen and (max-width: 767px) {
	.oitis-product-horizontal .oitis-product-footer .ant-btn:not(:last-child) {
		margin-left: 0;
	}
}

.oitis-product-horizontal .oitis-product-footer .ant-btn:last-child {
	margin-left: 8px;
	margin-right: 8px;
}

@media screen and (max-width: 767px) {
	.oitis-product-horizontal .oitis-product-footer {
		text-align: left;
		width: 100%;
	}
}

.oitis-product-horizontal .oitis-product-image .oitis-grid-thumb-equal {
	padding-bottom: 82%;
}

@media screen and (min-width: 1400px) {
	.oitis-product-horizontal .oitis-product-image .oitis-grid-thumb-equal {
		padding-bottom: 55%;
	}
}

@media screen and (max-width: 1199px) {
	.oitis-product-horizontal .oitis-product-image .oitis-grid-thumb-equal {
		padding-bottom: 80%;
	}
}

@media screen and (max-width: 1080px) {
	.oitis-product-horizontal .oitis-product-image .oitis-grid-thumb-equal {
		padding-bottom: 90%;
	}
}

@media screen and (max-width: 1000px) {
	.oitis-product-horizontal .oitis-product-image .oitis-grid-thumb-equal {
		padding-bottom: 110%;
	}

	.horizontal-layout .oitis-product-horizontal .oitis-product-image .oitis-grid-thumb-equal {
		padding-bottom: 80%;
	}
}

@media screen and (max-width: 991px) {
	.oitis-product-horizontal .oitis-product-image .oitis-grid-thumb-equal {
		padding-bottom: 88%;
	}

	.horizontal-layout .oitis-product-horizontal .oitis-product-image .oitis-grid-thumb-equal {
		padding-bottom: 128%;
	}
}

@media screen and (max-width: 849px) {
	.oitis-product-horizontal .oitis-product-image .oitis-grid-thumb-equal {
		padding-bottom: 110%;
	}
}

@media screen and (max-width: 767px) {

	.horizontal-layout .oitis-product-horizontal .oitis-product-image .oitis-grid-thumb-equal,
	.oitis-product-horizontal .oitis-product-image .oitis-grid-thumb-equal {
		padding-bottom: 44%;
	}
}

.oitis-product-vertical {
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	height: calc(100% - 32px);
}

@media screen and (max-width: 575px) {
	.oitis-product-vertical {
		height: calc(100% - 20px);
	}
}

.oitis-product-vertical .oitis-product-body {
	flex: 1 1;
}

.oitis-product-title {
	font-size: 18px;
}

.oitis-package,
.oitis-price-tables {
	position: relative;
}

.oitis-package {
	border-radius: 10px;
	box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	height: calc(100% - 15px);
	margin-bottom: 15px;
	overflow: hidden;
	-webkit-transform: scale(0.95);
	transform: scale(0.95);
	transition: all 0.2s ease-in-out;
}

.oitis-package:active,
.oitis-package:focus,
.oitis-package:hover {
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.55);
	-webkit-transform: scale(1);
	transform: scale(1);
}

.oitis-pt-dark .oitis-package:active,
.oitis-pt-dark .oitis-package:focus,
.oitis-pt-dark .oitis-package:hover {
	border-radius: 20px;
}

.oitis-pt-classic .oitis-package {
	border: 0;
	border-radius: 0;
}

.oitis-package.oitis-highlight {
	-webkit-transform: scale(1);
	transform: scale(1);
	z-index: 2;
}

.oitis-package-header {
	padding: 30px;
	text-align: center;
}

.oitis-package-header>.oitis-price {
	color: #fff;
	display: inline-block;
	font-size: 36px;
	font-weight: 500;
	margin-bottom: 12px;
	margin-left: 0;
}

.oitis-package-header>.oitis-price>i {
	display: inline-block;
	font-weight: 700;
	margin-right: 6px;
}

.oitis-package-header h5 {
	color: inherit;
}

.oitis-package-header.oitis-text-black>.oitis-price {
	color: #595959 !important;
}

@media only screen and (max-width: 991px) {
	.oitis-package-header {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.oitis-package-body {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex: 1 1;
	flex-direction: column;
	flex-wrap: nowrap;
	padding: 50px 30px;
}

@media only screen and (max-width: 991px) {
	.oitis-package-body {
		padding-left: 20px;
		padding-right: 20px;
	}
}

.oitis-package-items {
	flex: 1 1;
	font-size: 14px;
	list-style: none;
	margin: 0 0 20px;
	padding-left: 0;
}

.oitis-package-items li {
	line-height: inherit;
	margin-bottom: 16px;
}

.oitis-package-items li:before {
	content: '';
	display: table;
}

.oitis-package-items li:after {
	clear: both;
	content: '';
	display: table;
}

.oitis-package-items li i {
	float: left;
	line-height: inherit;
	margin-right: 16px;
	min-width: 20px;
}

.oitis-package-items li span {
	display: block;
	overflow: hidden;
}

.oitis-package-footer {
	padding-top: 30px;
}

.oitis-package-footer .ant-btn {
	border: 0;
	margin-bottom: 0;
	text-transform: capitalize;
}

@media only screen and (max-width: 767px) {

	.oitis-package.oitis-highlight,
	.oitis-package:active,
	.oitis-package:focus,
	.oitis-package:hover {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.oitis-text-black .oitis-text-white {
	color: #595959 !important;
}

.oitis-pt-circle .oitis-package-header {
	-ms-align-items: center;
	align-items: center;
	border-radius: 50%;
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	height: 224px;
	-ms-justify-content: center;
	justify-content: center;
	margin: 40px auto 0;
	width: 224px;
}

.oitis-pt-circle .oitis-package-header .oitis-price {
	margin-top: -5px;
}

@media only screen and (max-width: 991px) {
	.oitis-pt-circle .oitis-package-header .oitis-price {
		font-size: 26px;
		margin-bottom: 6px;
	}

	.oitis-pt-circle .oitis-package-header .oitis-letter-spacing-base {
		letter-spacing: 1px;
	}

	.oitis-pt-circle .oitis-package-header {
		height: 150px;
		width: 150px;
	}
}

.oitis-login-container {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex: 1 1;
	flex-direction: row;
	flex-wrap: wrap;
	height: 100%;
	-ms-justify-content: center;
	justify-content: center;
	padding-bottom: 30px;
	position: relative;
}

.oitis-login-content {
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
	font-size: 14px;
	margin: auto;
	max-width: 420px;
	padding: 35px 35px 20px;
	width: 94%;
}

.oitis-login-content .ant-input {
	background-color: #f5f5f5;
	height: auto;
	padding: 6px 12px;
}

.oitis-login-content .ant-input:focus {
	border-color: #038fde;
	box-shadow: none;
}

.oitis-login-content .ant-input-affix-wrapper {
	background-color: #f5f5f5;
	max-height: none;
}

.oitis-login-content .ant-form-item-control-wrapper {
	width: 100%;
}

.oitis-login-content .ant-form-item-children {
	display: block;
}

@media screen and (max-width: 575px) {
	.oitis-login-content {
		padding: 20px 20px 10px;
	}
}

.oitis-login-header {
	margin-bottom: 30px;
}

@media screen and (max-width: 575px) {
	.oitis-login-header {
		margin-bottom: 15px;
	}
}

.oitis-app-login-wrap {
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	height: 100%;
	-ms-justify-content: center;
	justify-content: center;
	overflow-x: hidden;
}

@media screen and (max-width: 575px) {
	.oitis-app-login-wrap {
		-ms-justify-content: flex-start;
		justify-content: flex-start;
		padding-top: 20px;
	}
}

.oitis-app-login-container {
	margin: 0 auto;
	max-width: 680px;
	position: relative;
	width: 94%;
}

@media screen and (max-width: 575px) {
	.oitis-app-login-container {
		padding-bottom: 20px;
	}
}

.oitis-app-login-container .oitis-loader-view {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	text-align: center;
	top: 0;
	z-index: 2;
}

.oitis-app-login-main-content {
	background-color: #fff;
	border-radius: 12px;
	box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-size: 14px;
	overflow: hidden;
}

.oitis-app-login-content {
	padding: 35px 35px 20px;
	width: 60%;
}

.oitis-app-login-content .ant-input {
	background-color: #f5f5f5;
}

.oitis-app-login-content .ant-input:focus {
	border-color: #038fde;
	box-shadow: none;
}

.oitis-app-login-content .oitis-btn {
	height: auto;
	padding: 6px 35px !important;
}

.oitis-app-login-content .ant-form-item-control-wrapper {
	width: 100%;
}

.oitis-app-login-content .ant-form-item-children {
	display: block;
}

@media screen and (max-width: 575px) {
	.oitis-app-login-content {
		padding: 20px 20px 10px;
		width: 100%;
	}
}

.oitis-app-login-header {
	margin-bottom: 30px;
}

@media screen and (max-width: 575px) {
	.oitis-app-login-header {
		margin-bottom: 15px;
	}
}

.oitis-app-logo-content {
	color: #fff;
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	overflow: hidden;
	padding: 35px 35px 20px;
	position: relative;
	width: 40%;
}

.oitis-app-logo-content>* {
	position: relative;
	z-index: 2;
}

.oitis-app-logo-content h1 {
	color: #fff;
}

@media screen and (max-width: 575px) {
	.oitis-app-logo-content {
		padding: 20px 20px 10px;
		width: 100%;
	}
}

.oitis-app-logo-content-bg {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

.oitis-app-logo-content-bg:before {
	background-color: rgba(3, 143, 222, 0.7);
	bottom: 0;
	content: '';
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
}

.oitis-app-logo-content-bg img {
	height: 100%;
	width: 100%;
}

.oitis-app-logo-wid {
	margin-bottom: auto;
}

.oitis-app-social-block {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	-ms-justify-content: space-between;
	justify-content: space-between;
}

.oitis-app-social-block .oitis-social-link,
.oitis-app-social-block .oitis-social-link li {
	margin: 0;
}

.oitis-app-social-block .oitis-social-link span {
	border: 1px solid #038fde;
	border-radius: 50%;
	color: #038fde;
}

.oitis-app-social-block .oitis-social-link span:focus,
.oitis-app-social-block .oitis-social-link span:hover {
	background-color: #038fde;
	color: #fff;
}

.oitis-app-social-block p {
	margin-bottom: 0;
}

.oitis-signin-form .ant-form-item-control-input {
	min-height: 40px;
}

.ant-list-item-meta {
	flex-wrap: wrap;
}

.oitis-task-list-item {
	position: relative;
}

.oitis-task-list-item:hover>.oitis-media-body {
	background: #f8f8f8;
	border-radius: 8px;
}

.oitis-task-list-item:not(:last-child) {
	margin-bottom: 15px;
}

.oitis-card-ticketlist .oitis-task-list-item:not(:last-child) {
	margin-bottom: 20px;
}

.oitis-card-tabs .oitis-task-list-item:last-child {
	margin-bottom: 15px;
}

.oitis-card-ticketlist .oitis-task-list-item .oitis-hover {
	display: none;
}

@media screen and (max-width: 575px) {
	.oitis-card-ticketlist .oitis-task-list-item .oitis-hover {
		display: block;
	}

	.oitis-card-ticketlist .oitis-task-list-item .oitis-nonhover {
		display: none;
	}
}

.oitis-card-ticketlist .oitis-task-list-item:hover .oitis-hover {
	display: inline-block;
}

.oitis-card-ticketlist .oitis-task-list-item:hover .oitis-nonhover {
	display: none;
}

.oitis-card-ticketlist .oitis-task-list-item:hover .oitis-task-item-title {
	color: #038fde;
}

.oitis-task-list-item:hover {
	cursor: pointer;
}

.oitis-task-list-item:hover .oitis-text-hover {
	color: #038fde;
}

.oitis-task-item-content {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	-ms-justify-content: space-between;
	justify-content: space-between;
	max-width: calc(100% - 40px);
}

.oitis-task-item-content-left {
	width: calc(100% - 250px);
}

@media screen and (max-width: 575px) {
	.oitis-task-item-content-left {
		margin-bottom: 10px;
		padding-right: 60px;
		width: 100%;
	}
}

.oitis-card-ticketlist .oitis-task-item-content-left {
	width: calc(100% - 100px);
}

@media screen and (max-width: 575px) {
	.oitis-card-ticketlist .oitis-task-item-content-left {
		padding-right: 0;
		width: 100%;
	}
}

.oitis-task-item-content-right {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	-ms-justify-content: flex-end;
	justify-content: flex-end;
	padding-left: 10px;
	width: 250px;
}

@media screen and (max-width: 575px) {
	.oitis-task-item-content-right {
		-ms-justify-content: flex-start;
		justify-content: flex-start;
		padding-left: 0;
		width: 100%;
	}
}

.oitis-card-ticketlist .oitis-task-item-content-right {
	padding-top: 8px;
	width: 100px;
}

@media screen and (max-width: 575px) {
	.oitis-card-ticketlist .oitis-task-item-content-right {
		padding-top: 0;
		width: 100%;
	}
}

.oitis-card-ticketlist .oitis-task-item-content {
	max-width: calc(100% - 52px);
}

.oitis-task-item-content .oitis-task-date {
	min-width: 55px;
	text-align: right;
}

.oitis-task-item-content .ant-tag {
	min-width: 60px;
	text-align: center;
}

@media screen and (max-width: 575px) {
	.oitis-task-item-content {
		flex-direction: column;
		position: relative;
	}

	.oitis-task-item-content .oitis-task-date {
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;
	}

	.oitis-card-ticketlist .oitis-task-item-content {
		padding-right: 0;
	}
}

.oitis-card-ticketlist .oitis-link {
	color: #038fde;
}

.oitis-card-testimonial {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	-ms-justify-content: space-between;
	justify-content: space-between;
	margin-bottom: 12px;
}

.oitis-card-testimonial-img {
	margin-bottom: 10px;
	margin-right: 10px;
}

.oitis-card-testimonial-content {
	background-color: #f5f5f5;
	border-radius: 4px;
	margin-left: 10px;
	padding: 15px;
	position: relative;
	width: calc(100% - 70px);
}

.oitis-card-testimonial-content:before {
	border-bottom: 7px solid transparent;
	border-right: 10px solid #f5f5f5;
	border-top: 7px solid transparent;
	content: '';
	height: 0;
	left: -10px;
	position: absolute;
	top: 10px;
	width: 0;
	z-index: 1;
}

.oitis-card-testimonial-content> :last-child {
	margin-bottom: 0;
}

.oitis-progress-task {
	position: relative;
}

.oitis-progress-task :last-child {
	margin-bottom: 0;
}

.oitis-progress-task-list {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-bottom: 15px;
}

.oitis-progress-task-list+.oitis-progress-task-list {
	border-top: 1px solid #e8e8e8;
	padding-top: 15px;
}

.oitis-progress-task-list .ant-progress {
	margin-bottom: 15px;
}

.oitis-progress-task-list-content {
	margin-bottom: 15px;
	margin-left: 20px;
}

@media screen and (max-width: 575px) {
	.oitis-progress-task-list {
		margin-bottom: 0;
	}
}

.oitis-act-task {
	position: relative;
}

.oitis-act-task-cell {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	-ms-justify-content: space-between;
	justify-content: space-between;
	margin-bottom: 20px;
}

@media screen and (max-width: 575px) {
	.oitis-act-task-cell {
		margin-bottom: 10px;
	}
}

.oitis-act-task-content {
	width: calc(100% - 72px);
}

.oitis-act-task-content label.ant-checkbox-wrapper span {
	display: block;
	overflow: hidden;
}

.oitis-act-task-content label.ant-checkbox-wrapper span.ant-checkbox {
	float: left;
	padding: 6px 5px 0 0;
}

.oitis-act-task-btn-view {
	margin-left: 8px;
	text-align: right;
	width: 65px;
}

@media screen and (max-width: 575px) {
	.oitis-act-task-btn-view {
		margin-left: 0;
		margin-top: 10px;
	}
}

.oitis-line-indicator {
	list-style: none;
	margin: 0;
	padding-left: 0;
}

.oitis-line-indicator li:not(:last-child) {
	margin-bottom: 10px;
}

@media screen and (max-width: 575px) {
	.oitis-line-indicator li:not(:last-child) {
		margin-bottom: 5px;
	}
}

.oitis-line-indicator li p {
	margin-bottom: 0;
}

.oitis-line-indicator-half {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 -12px;
}

.oitis-line-indicator-half li {
	padding: 0 12px;
	width: 50%;
}

.oitis-line-indicator-col {
	padding-right: 10px;
	width: 25%;
}

@media screen and (max-width: 767px) {
	.oitis-line-indicator-col {
		padding-right: 0;
		width: 100%;
	}
}

.oitis-line-indi {
	height: 3px;
}

.oitis-line-indi-info {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.oitis-wel-ema {
	position: relative;
}

.oitis-overview-row {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

@media screen and (max-width: 767px) {
	.oitis-overview-row {
		flex-direction: column;
	}
}

.oitis-overview-description {
	border-left: 1px solid #e8e8e8;
	color: #8c8c8c;
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	margin: -24px -24px -24px 0;
	order: 2;
	width: 32%;
}

@media screen and (max-width: 767px) {
	.oitis-overview-description {
		border-bottom: 1px solid #e8e8e8;
		border-left: 0 !important;
		border-top: 1px solid #e8e8e8;
		margin: 15px 0 0;
		order: 1;
		width: 100%;
	}
}

.oitis-map-col {
	flex: 1 1;
	order: 1;
	padding: 50px 10px 0;
}

@media screen and (max-width: 767px) {
	.oitis-map-col {
		order: 2;
		padding: 15px 0 0;
	}
}

.oitis-revenu {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex: 1 1;
	flex-direction: column;
	flex-wrap: nowrap;
	-ms-justify-content: center;
	justify-content: center;
	padding: 15px;
}

.oitis-revenu-total {
	border-bottom: 1px solid #e8e8e8;
}

.oitis-revenu-total h1 {
	font-size: 34px;
	font-weight: 600;
	margin-bottom: 5px;
}

@media screen and (max-width: 767px) {
	.oitis-revenu-total h1 {
		font-size: 22px;
	}
}

.oitis-revenu-row {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
}

.oitis-revenu-col {
	padding: 0 15px;
	text-align: center;
	width: 50%;
}

.oitis-revenu-col:not(:last-child) {
	border-right: 1px solid #e8e8e8;
}

.oitis-revenu h3 {
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 0;
}

.ant-divider-horizontal {
	margin: 12px 0;
}

.oitis-visit-col {
	order: 1;
}

@media screen and (max-width: 1199px) {
	.oitis-visit-col {
		margin-top: 20px;
		order: 2;
	}
}

.oitis-audi-col {
	order: 2;
}

@media screen and (max-width: 1199px) {
	.oitis-audi-col {
		order: 1;
	}
}

@media screen and (max-width: 575px) {
	.oitis-audi-col {
		margin-top: 20px;
	}
}

.oitis-rechart {
	position: relative;
}

.oitis-rechart-prod {
	left: 30px;
	position: absolute;
	top: 0;
	z-index: 1;
}

@media (max-width: 1366px) {
	.oitis-rechart-prod {
		left: 20px;
	}
}

.oitis-pink-purple-gradient {
	background-color: #564093;
	background: -o-linear-gradient(left, #564093, #d2449d);
}

.oitis-pink-purple-gradient-reverse {
	background-color: #872776;
	background: -o-linear-gradient(left, #872776, #352883);
}

.oitis-blue-cyan-gradient {
	background: linear-gradient(325deg, #45d1e6, #4363c6);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="@hstartColor", endColorstr="@hendColor", GradientType=0);
}

.oitis-app-thumb {
	display: inline-block;
}

.oitis-card-cover-black .ant-card-head {
	background-color: #000;
	color: #fff;
	padding-top: 20px;
}

.oitis-card-cover-black .ant-card-head h1 {
	color: #fff;
}

.oitis-card-cover-black .ant-card-head-title {
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
}

.oitis-card-cover-black .ant-card-body {
	padding: 1rem;
}

.oitis-dot-arrow-hover {
	overflow: hidden;
	position: relative;
}

.oitis-dot-arrow-hover:hover .oitis-hover-arrow {
	left: -15px;
}

.oitis-dot-arrow {
	bottom: 0;
	padding-right: 10px;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 2;
}

.oitis-dot-arrow,
.oitis-dot-arrow .oitis-dot {
	cursor: pointer;
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	-ms-justify-content: center;
	justify-content: center;
}

.oitis-dot-arrow .oitis-dot,
.oitis-hover-arrow {
	transition: all 0.3s ease-out;
}

.oitis-hover-arrow {
	-ms-align-items: center;
	align-items: center;
	border-radius: 50% 0 0 50%;
	cursor: pointer;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-size: 20px;
	height: 107px;
	-ms-justify-content: flex-start;
	justify-content: flex-start;
	left: 15px;
	padding: 10px;
	position: absolute;
	width: 107px;
}

.oitis-hover-arrow .icon {
	display: flex;
}

.oitis-actchart {
	margin-bottom: -4px;
}

.oitis-user-wid {
	position: relative;
	width: 80px;
}

.oitis-user-wid img {
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
}

.oitis-user-wid-row {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.oitis-user-wid-body {
	flex: 1 1;
}

.boxed-layout .oitis-build-box-lay,
.framed-layout .oitis-build-box-lay {
	padding: 0 10px !important;
}

.boxed-layout .oitis-build-box-lay img,
.framed-layout .oitis-build-box-lay img {
	width: 50px;
}

@media (max-width: 1366px) {
	.oitis-build-box-lay {
		padding: 0 10px !important;
	}

	.oitis-build-box-lay img {
		width: 50px;
	}
}

.oitis-build-box-lay-content {
	max-width: calc(100% - 70px);
}

.oitis-chart-title {
	font-size: 14px;
}

@media (max-width: 1366px) {
	.oitis-chart-title {
		font-size: 12px;
	}
}

.oitis-widget-bg {
	background-color: #4c429a;
	background: -o-linear-gradient(left, #4c429a, #9956ce);
	color: #fff;
	position: relative;
}

.oitis-widget-bg h1 {
	color: #fff;
}

.oitis-widget-badge {
	background-color: #ff6bb3;
	border-radius: 0 10px 0 10px;
	color: #fff;
	padding: 5px 12px;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 1;
}

.oitis-fnd-content {
	padding-right: 40px;
}

@media (max-width: 1366px) {
	.oitis-fnd-content {
		padding-right: 0;
	}
}

@media screen and (max-width: 575px) {
	.oitis-fnd-content {
		margin-top: 20px;
	}
}

.oitis-fnd-gallery-list {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	list-style: none;
	margin: 0 -6px;
	padding-left: 0;
}

.oitis-fnd-gallery-list li {
	padding: 0 6px;
}

.oitis-fnd-gallery-list li>img {
	width: 65px;
}

@media (max-width: 1366px) {
	.oitis-fnd-gallery-list li>img {
		width: 50px;
	}
}

.oitis-fnd-title {
	font-size: 24px;
	margin-bottom: 15px;
}

@media (max-width: 1366px) {
	.oitis-fnd-title {
		font-size: 20px;
		margin-bottom: 5px;
	}
}

.oitis-chart-up {
	color: #52c41a;
}

.oitis-bg-geekblue .oitis-chart-up {
	color: #fff;
}

.oitis-chart-down {
	color: #f5222d;
}

.oitis-chart-down .icon {
	display: inline-block;
	-webkit-transform: rotate(180deg);
	transform: rotate(180deg);
}

.oitis-barchart {
	margin-top: -15px;
}

@media screen and (max-width: 1199px) {
	.oitis-barchart {
		margin-top: 0;
	}
}

.oitis-customers {
	margin-bottom: -7px;
	margin-top: -7px;
}

@media (max-width: 1366px) {
	.oitis-customers {
		margin-bottom: -2px;
		margin-top: -2px;
	}

	.oitis-customers .oitis-size-60 {
		height: 50px !important;
		line-height: 50px !important;
		width: 50px !important;
	}
}

@media (max-width: 575px) {
	.oitis-customers {
		margin-bottom: 0;
		margin-top: 0;
	}
}

.oitis-fillchart {
	-ms-align-items: flex-end;
	align-items: flex-end;
	color: #fff;
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	min-height: 122px;
	position: relative;
	transition: all 0.7s ease-out;
}

.oitis-fillchart-content {
	height: 100%;
	left: 0;
	padding: 15px 20px;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

.oitis-overlay-fillchart .oitis-fillchart-content {
	background-color: hsla(0, 0%, 100%, 0.35);
}

.oitis-fillchart .ant-card-head-title,
.oitis-fillchart .oitis-chart-down,
.oitis-fillchart .oitis-chart-up,
.oitis-fillchart h2 {
	color: #fff;
}

.oitis-fillchart .oitis-chart-down,
.oitis-fillchart .oitis-chart-up {
	margin-right: 6px;
}

.oitis-fillchart-btn {
	bottom: 10px;
	cursor: pointer;
	-webkit-opacity: 0;
	-moz-opacity: 0;
	opacity: 0;
	position: absolute;
	right: 10px;
	transition: all 0.3s ease-out;
	visibility: hidden;
	z-index: 1;
}

.oitis-fillchart-btn .icon:before {
	line-height: 30px;
}

.oitis-fillchart-btn-close {
	background-color: #fff;
	border-radius: 50%;
	color: #038fde;
	cursor: pointer;
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	font-size: 16px;
	height: 30px;
	-webkit-opacity: 0;
	-moz-opacity: 0;
	opacity: 0;
	position: absolute;
	right: 10px;
	text-align: center;
	top: 10px;
	transition: all 0.3s ease-out;
	visibility: hidden;
	width: 30px;
}

.oitis-fillchart-btn-close .icon {
	font-size: 24px;
	height: 30px;
	line-height: 38px;
	width: 30px;
}

.oitis-fillchart:hover .oitis-fillchart-btn {
	-webkit-opacity: 1;
	-moz-opacity: 1;
	opacity: 1;
	visibility: visible;
}

.oitis-fillchart-nocontent .oitis-fillchart-btn,
.oitis-fillchart-nocontent .oitis-fillchart-content,
.oitis-fillchart-nocontent:hover .oitis-fillchart-btn {
	-webkit-opacity: 0;
	-moz-opacity: 0;
	opacity: 0;
	visibility: hidden;
}

.oitis-fillchart-nocontent .oitis-fillchart-btn-close {
	-webkit-opacity: 1;
	-moz-opacity: 1;
	opacity: 1;
	visibility: visible;
}

.oitis-fillchart-nocontent .ant-card-head-title {
	left: 20px;
	position: absolute;
	top: 15px;
	z-index: 1;
}

.oitis-agents-list {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	list-style: none;
	margin: 0 -15px;
	padding-left: 0;
}

.oitis-agents-list li {
	margin-bottom: 30px;
	padding: 0 15px;
	width: 20%;
}

@media screen and (max-width: 1199px) {
	.oitis-agents-list li {
		width: 25%;
	}
}

@media screen and (max-width: 991px) {
	.oitis-agents-list li {
		width: 20%;
	}
}

@media screen and (max-width: 767px) {
	.oitis-agents-list li {
		width: 33.33%;
	}
}

@media screen and (max-width: 575px) {
	.oitis-agents-list li {
		margin-bottom: 20px;
		padding: 0 10px;
		width: 50%;
	}

	.oitis-agents-list {
		margin: 0 -10px;
	}
}

.oitis-featured-item:not(:last-child) {
	border-bottom: 1px solid #e8e8e8;
	margin-bottom: 30px;
	padding-bottom: 10px;
}

.oitis-card-profile .oitis-featured-item:not(:last-child) {
	margin-bottom: 25px;
	padding-bottom: 8px;
}

@media (max-width: 575px) {
	.oitis-featured-item:not(:last-child) {
		margin-bottom: 27px;
	}
}

.oitis-featured-item:last-child .oitis-featured-thumb {
	margin-bottom: 0;
}

@media (max-width: 575px) {
	.oitis-featured-item:last-child .oitis-featured-thumb {
		margin-bottom: 15px;
	}

	.oitis-featured-item {
		-ms-align-items: normal;
		align-items: normal;
		display: -ms-flex;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
	}
}

.oitis-featured-thumb {
	margin-bottom: 10px;
	margin-right: 25px;
	position: relative;
}

@media (max-width: 575px) {
	.oitis-featured-thumb {
		margin-bottom: 15px;
		margin-right: 0;
	}

	.oitis-featured-thumb>img {
		height: auto !important;
		width: 100% !important;
	}
}

.oitis-featured-thumb .ant-tag {
	left: 15px;
	margin: 0;
	position: absolute;
	top: 15px;
	z-index: 1;
}

.oitis-card-profile .oitis-featured-thumb {
	max-width: 130px;
}

@media (max-width: 575px) {
	.oitis-card-profile .oitis-featured-thumb {
		max-width: none;
	}
}

.oitis-featured-content {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

@media (max-width: 1366px) {
	.oitis-featured-content {
		font-size: 13px;
	}
}

@media (max-width: 1199px) {
	.oitis-featured-content {
		font-size: 14px;
	}

	.oitis-card-profile .oitis-featured-content {
		display: -ms-flex;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
	}
}

@media (max-width: 767px) {
	.oitis-featured-content {
		display: -ms-flex;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
	}
}

.oitis-featured-content-right {
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	margin-left: auto;
	text-align: right;
}

.oitis-card-profile .oitis-featured-content-right {
	font-size: 14px;
}

@media (max-width: 1199px) {
	.oitis-card-profile .oitis-featured-content-right {
		margin-left: 0;
		text-align: left;
	}

	.oitis-card-profile .oitis-featured-content-right h2 {
		font-size: 16px;
	}

	.oitis-card-profile .oitis-featured-content-right h2 .oitis-fs-lg {
		font-size: 14px;
	}
}

@media (max-width: 767px) {
	.oitis-featured-content-right {
		margin-left: 0;
		margin-top: 15px;
		text-align: left;
	}
}

@media (max-width: 575px) {
	.oitis-card-profile .oitis-featured-content-right h2 {
		font-size: 14px;
	}
}

.oitis-news-item {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

.oitis-news-item:not(:last-child) {
	margin-bottom: 30px;
}

@media (max-width: 767px) {
	.oitis-news-item:not(:last-child) {
		margin-bottom: 26px;
	}
}

@media (max-width: 575px) {
	.oitis-news-item:not(:last-child) {
		margin-bottom: 22px;
	}

	.oitis-news-item {
		display: -ms-flex;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
	}
}

.oitis-news-thumb {
	margin-right: 25px;
	position: relative;
}

@media (max-width: 1366px) {
	.oitis-news-thumb {
		margin-right: 15px;
	}
}

@media (max-width: 575px) {
	.oitis-news-thumb {
		margin-bottom: 12px;
		margin-right: 0;
	}

	.oitis-news-thumb>img {
		height: auto !important;
		width: 100% !important;
	}
}

.oitis-news-content {
	color: #686868;
	width: calc(100% - 200px);
}

@media (max-width: 1366px) {
	.oitis-news-content {
		width: calc(100% - 190px);
	}
}

@media (max-width: 575px) {
	.oitis-news-content {
		width: 100%;
	}
}

.oitis-news-tags-row {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
}

@media (max-width: 1366px) {
	.oitis-news-tags-row {
		font-size: 12px;
	}
}

.oitis-news-tags-left {
	width: calc(100% - 180px);
}

@media (max-width: 1366px) {
	.oitis-news-tags-left {
		width: calc(100% - 130px);
	}
}

.oitis-news-tags-right {
	padding-left: 8px;
	text-align: right;
	width: 180px;
}

@media (max-width: 1366px) {
	.oitis-news-tags-right {
		width: 130px;
	}
}

.oitis-currentplan-row {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0 -5px;
}

.oitis-currentplan-col {
	padding: 0 5px;
	width: 50%;
}

@media (max-width: 1366px) {
	.oitis-currentplan-col {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.oitis-currentplan-col {
		width: 50%;
	}
}

@media (max-width: 575px) {
	.oitis-currentplan-col {
		width: 100%;
	}
}

.oitis-currentplan-right {
	border-left: 1px solid #e8e8e8;
}

@media (max-width: 1366px) {
	.oitis-currentplan-right {
		border-left: 0 !important;
		border-top: 1px solid #e8e8e8;
		padding-top: 10px;
	}
}

@media (max-width: 991px) {
	.oitis-currentplan-right {
		border-left: 1px solid #e8e8e8 !important;
		border-top: 0;
		padding-top: 0;
	}
}

@media (max-width: 575px) {
	.oitis-currentplan-right {
		border-left: 0 !important;
		border-top: 1px solid #e8e8e8;
		padding-top: 22px;
	}
}

.oitis-currentplan-right-content {
	-ms-align-items: flex-end;
	align-items: flex-end;
	display: -ms-flex;
	display: flex;
	flex-direction: column;
	flex-wrap: nowrap;
	height: 100%;
	-ms-justify-content: flex-end;
	justify-content: flex-end;
	text-align: right;
}

@media (max-width: 1366px) {
	.oitis-currentplan-right-content {
		-ms-align-items: flex-start;
		align-items: flex-start;
		-ms-justify-content: flex-start;
		justify-content: flex-start;
		text-align: left;
	}
}

@media (max-width: 991px) {
	.oitis-currentplan-right-content {
		-ms-align-items: flex-end;
		align-items: flex-end;
		-ms-justify-content: flex-end;
		justify-content: flex-end;
		text-align: right;
	}
}

@media (max-width: 575px) {
	.oitis-currentplan-right-content {
		-ms-align-items: flex-start;
		align-items: flex-start;
		-ms-justify-content: flex-start;
		justify-content: flex-start;
		text-align: left;
	}
}

.oitis-oth-plans-down {
	display: none;
}

@media (max-width: 1366px) {
	.oitis-oth-plans-down {
		display: block;
		margin-top: 15px;
	}
}

@media (max-width: 991px) {
	.oitis-oth-plans-down {
		display: none;
		margin-top: 0;
	}
}

@media (max-width: 575px) {
	.oitis-oth-plans-down {
		display: block;
		margin-top: 20px;
	}
}

@media (max-width: 1366px) {
	.oitis-oth-plans-up {
		display: none;
	}
}

@media (max-width: 991px) {
	.oitis-oth-plans-up {
		display: block;
	}
}

@media (max-width: 575px) {
	.oitis-oth-plans-up {
		display: none;
	}
}

.oitis-revenue-title {
	color: #000;
	font-size: 30px;
	font-weight: 600;
	line-height: 1;
}

@media (max-width: 1366px) {
	.oitis-revenue-title {
		font-size: 28px;
	}
}

@media (max-width: 575px) {
	.oitis-revenue-title {
		padding-top: 15px;
	}
}

.oitis-dealclose-header,
.oitis-dealclose-header-right {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.oitis-dealclose-header-right {
	margin-left: auto;
}

@media (max-width: 1366px) {
	.oitis-dealclose-header-right {
		margin-bottom: 20px;
		margin-left: 0;
		margin-top: 15px;
	}
}

@media (max-width: 991px) {
	.oitis-dealclose-header-right {
		margin-bottom: 0;
		margin-left: auto;
		margin-top: 0;
	}
}

@media (max-width: 575px) {
	.oitis-dealclose-header-right {
		margin-left: 0;
	}
}

@media (max-width: 1366px) {
	.oitis-dealclose-header {
		display: -ms-flex;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
	}
}

@media (max-width: 991px) {
	.oitis-dealclose-header {
		display: -ms-flex;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	}
}

@media (max-width: 575px) {
	.oitis-dealclose-header {
		display: -ms-flex;
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
	}
}

@media (max-width: 1366px) {
	.oitis-order-history .ant-table {
		font-size: 12px;
	}
}

.oitis-icon-img {
	height: 60px;
	width: 60px;
}

@media screen and (max-width: 1366px) {
	.oitis-icon-img {
		height: 40px;
		width: 40px;
	}
}

.oitis-smart-img {
	max-height: 90px;
	object-fit: cover;
	width: 100%;
}

@media screen and (max-width: 575px) {
	.oitis-smart-img {
		max-height: 180px;
	}
}

.oitis-ayurveda-thumb {
	max-height: 150px;
}

.oitis-ayurveda-thumb>img {
	object-fit: cover;
}

.oitis-dash-search {
	position: relative;
}

@media screen and (max-width: 575px) {
	.oitis-dash-search {
		padding-right: 30px;
	}

	.oitis-dash-search .oitis-search-icon {
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;
	}
}

.oitis-bg-dark-primary {
	background-color: #038fde;
	overflow: hidden;
	position: relative;
}

.oitis-bg-dark-primary>* {
	position: relative;
	z-index: 1;
}

.oitis-bg-dark-primary:before {
	background-color: rgba(0, 0, 0, 0.72);
	content: '';
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 1;
}

@media screen and (max-width: 1366px) {
	.recharts-text {
		font-size: 12px;
	}
}

@media screen and (max-width: 575px) {
	.oitis-list-item .ant-list-item-content {
		flex-direction: column;
	}
}

.oitis-algolia-sidebar {
	border-radius: 10px;
	box-shadow: 0 0 5px 5px rgba(0, 0, 0, 0.03);
	margin-bottom: 32px;
	width: 280px !important;
}

@media screen and (max-width: 767px) {
	.oitis-algolia-sidebar {
		width: 100% !important;
	}
}

@media screen and (max-width: 575px) {
	.oitis-algolia-sidebar {
		margin-bottom: 20px;
	}
}

.oitis-algolia-sidebar [class^='ais-'] {
	font-size: 14px;
}

.oitis-algolia-sidebar-content {
	padding: 24px;
}

.oitis-algolia-sidebar .ais-ClearRefinements,
.oitis-algolia-sidebar .ais-HierarchicalMenu {
	margin-bottom: 20px;
}

.oitis-algolia-sidebar .ais-HierarchicalMenu-list li {
	margin-bottom: 5px;
}

.oitis-algolia-sidebar .ais-HierarchicalMenu-list li a {
	color: #545454;
}

.oitis-algolia-sidebar .ais-HierarchicalMenu-list li a:focus,
.oitis-algolia-sidebar .ais-HierarchicalMenu-list li a:hover {
	color: #038fde;
}

.oitis-algolia-sidebar .ais-HierarchicalMenu-count {
	background-color: transparent;
	border-radius: 0;
	color: #8c8c8c;
	font-size: 12px;
	padding: 0 5px;
}

.oitis-algolia-sidebar .ais-Panel {
	margin-bottom: 20px;
}

.oitis-algolia-sidebar .ais-Panel-header {
	border-bottom: 0;
	font-size: 14px;
	font-weight: 600;
	margin-bottom: 8px;
	padding-bottom: 0;
	text-transform: uppercase;
}

.oitis-algolia-sidebar .ais-RefinementList-list li {
	margin-bottom: 5px;
}

.oitis-algolia-sidebar .ais-RefinementList-checkbox {
	margin-right: 12px;
}

.oitis-algolia-sidebar .ais-RefinementList-labelText {
	font-size: 14px;
}

.oitis-algolia-sidebar .ais-RefinementList-count {
	background-color: transparent;
	border-radius: 0;
	color: #8c8c8c;
	font-size: 14px;
	padding: 0 5px;
}

.oitis-algolia-sidebar .ais-HierarchicalMenu-list .ais-Breadcrumb-item--selected,
.oitis-algolia-sidebar .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item--selected,
.oitis-algolia-sidebar .ais-HierarchicalMenu-list .ais-Menu-item--selected {
	font-weight: 400;
}

.oitis-algolia-sidebar .ais-HierarchicalMenu-list .ais-Breadcrumb-item--selected>a,
.oitis-algolia-sidebar .ais-HierarchicalMenu-list .ais-HierarchicalMenu-item--selected>a,
.oitis-algolia-sidebar .ais-HierarchicalMenu-list .ais-Menu-item--selected>a {
	color: #038fde;
}

.oitis-algolia-sidebar .ais-RatingMenu-item--selected {
	font-weight: 600;
}

.oitis-algolia-layout-has-sider {
	flex-wrap: nowrap;
}

@media screen and (max-width: 767px) {
	.oitis-algolia-layout-has-sider {
		flex-wrap: wrap;
	}
}

.oitis-algolia-category-title {
	color: #8c8c8c;
	font-size: 14px;
	margin-bottom: 10px;
}

.oitis-algolia-main {
	display: -ms-flex;
	display: flex;
	flex: 1 1;
	flex-direction: column;
	flex-wrap: nowrap;
	margin-bottom: 32px;
	padding-left: 30px;
	width: 100%;
}

.oitis-algolia-main [class^='ais-'] {
	font-size: 14px;
}

@media screen and (max-width: 767px) {
	.oitis-algolia-main {
		padding-left: 0;
	}
}

@media screen and (max-width: 575px) {
	.oitis-algolia-main {
		margin-bottom: 20px;
	}
}

.oitis-algolia-header {
	margin-bottom: 20px;
}

.oitis-algolia-header,
.oitis-algolia-sort-by {
	display: -ms-flex;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
}

.oitis-algolia-sort-by {
	-ms-align-items: center;
	align-items: center;
	margin-left: auto;
}

.oitis-algolia-sort-by label {
	margin-right: 15px;
}

@media screen and (max-width: 400px) {
	.oitis-algolia-sort-by {
		margin-left: 0;
	}
}

.oitis-algolia-footer {
	padding: 10px;
}

.oitis-algolia-footer .ais-Pagination-list {
	flex-wrap: wrap;
}

.oitis-algolia-footer .ais-Pagination-list li {
	margin-bottom: 5px;
}

.oitis-algolia-content .ais-Stats {
	margin-bottom: 10px;
}

.oitis-product-name [class^='ais-'] {
	font-size: 16px;
}

.oitis-algolia-refinementList {
	width: 100%;
}

.oitis-algolia-refinementList li.ais-RefinementList-item {
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	width: 100%;
}

.ais-ClearRefinements-button,
.ais-ClearRefinements-button:focus,
.ais-ClearRefinements-button:hover,
.ais-CurrentRefinements-reset,
.ais-CurrentRefinements-reset:focus,
.ais-CurrentRefinements-reset:hover,
.ais-GeoSearch-redo,
.ais-GeoSearch-redo:focus,
.ais-GeoSearch-redo:hover,
.ais-GeoSearch-reset,
.ais-GeoSearch-reset:focus,
.ais-GeoSearch-reset:hover,
.ais-HierarchicalMenu-showMore,
.ais-HierarchicalMenu-showMore:focus,
.ais-HierarchicalMenu-showMore:hover,
.ais-InfiniteHits-loadMore,
.ais-InfiniteHits-loadMore:focus,
.ais-InfiniteHits-loadMore:hover,
.ais-InfiniteResults-loadMore,
.ais-InfiniteResults-loadMore:focus,
.ais-InfiniteResults-loadMore:hover,
.ais-Menu-showMore,
.ais-Menu-showMore:focus,
.ais-Menu-showMore:hover,
.ais-RangeInput-submit,
.ais-RangeInput-submit:focus,
.ais-RangeInput-submit:hover,
.ais-RefinementList-showMore,
.ais-RefinementList-showMore:focus,
.ais-RefinementList-showMore:hover {
	background-color: #038fde;
}

.ais-Breadcrumb-link,
.ais-Breadcrumb-link:focus,
.ais-Breadcrumb-link:hover,
.ais-HierarchicalMenu-link,
.ais-HierarchicalMenu-link:focus,
.ais-HierarchicalMenu-link:hover,
.ais-Menu-link,
.ais-Menu-link:focus,
.ais-Menu-link:hover,
.ais-Pagination-link,
.ais-Pagination-link:focus,
.ais-Pagination-link:hover,
.ais-RatingMenu-link,
.ais-RatingMenu-link:focus,
.ais-RatingMenu-link:hover {
	color: #038fde;
}

.ais-Pagination-item--selected .ais-Pagination-link {
	background-color: #038fde;
	border-color: #038fde;
}

.oitis-page-error-container {
	-ms-align-items: center;
	align-items: center;
	display: -ms-flex;
	display: flex;
	flex: 1 1;
	flex-direction: row;
	flex-wrap: wrap;
	height: 100%;
	-ms-justify-content: center;
	justify-content: center;
	position: relative;
}

.oitis-page-error-content {
	margin: 0 auto;
	max-width: 94%;
	width: 380px;
}

.oitis-page-error-content h2 {
	margin-bottom: 50px;
}

@media screen and (max-width: 767px) {
	.oitis-page-error-content h2 {
		margin-bottom: 20px;
	}
}

.oitis-error-code {
	color: #595959;
	font-size: 160px;
	font-weight: 500;
	line-height: 1;
	text-align: center;
	text-shadow: 10px 6px 8px hsla(0, 0%, 46%, 0.8);
}

@media screen and (max-width: 991px) {
	.oitis-error-code {
		font-size: 130px;
	}
}

@media screen and (max-width: 767px) {
	.oitis-error-code {
		font-size: 100px;
	}
}

@media screen and (max-width: 575px) {
	.oitis-error-code {
		font-size: 80px;
	}
}

.rdw-editor-toolbar .rdw-inline-wrapper {
	flex-wrap: wrap;
}

@media screen and (max-width: 399px) {
	.rdw-editor-toolbar .rdw-inline-wrapper .rdw-option-wrapper {
		margin-bottom: 8px;
	}
}

.oitis-testimonial {
	margin-bottom: 32px;
}

@media screen and (max-width: 575px) {
	.oitis-testimonial {
		margin-bottom: 20px;
	}
}

.oitis-classic-testimonial .ant-avatar {
	height: 120px;
	margin: 0 auto 30px;
	width: 120px;
}

.oitis-classic-testimonial .ant-avatar img {
	height: 100%;
	width: 100%;
}

@media screen and (max-width: 575px) {
	.oitis-classic-testimonial .ant-avatar {
		height: 80px;
		margin-bottom: 15px;
		width: 80px;
	}
}

.oitis-testimonial-bg {
	background-color: rgba(0, 0, 0, 0.8);
	border-radius: 6px;
	overflow: hidden;
	padding: 30px;
	position: relative;
}

@media screen and (max-width: 575px) {
	.oitis-testimonial-bg {
		padding: 16px;
	}
}

.oitis-testimonial-bg .ant-avatar {
	border: 4px solid #038fde;
	display: inline-block;
	height: 80px;
	overflow: hidden;
	width: 80px;
}

.oitis-testimonial-bg .ant-avatar img {
	height: 100%;
	width: 100%;
}

@media screen and (max-width: 450px) {
	.oitis-testimonial-bg .ant-avatar {
		height: 50px;
		width: 50px;
	}
}

.oitis-testimonial-bg .oitis-description {
	color: #d9d9d9;
	font-style: italic;
	font-weight: 400;
	line-height: 25px;
	margin-bottom: 15px;
}

.oitis-testimonial-bg .oitis-title {
	color: #038fde;
	margin-bottom: 2px;
}

.oitis-testimonial-des {
	padding-left: 30px;
	position: relative;
}

.oitis-testimonial-des:before {
	color: #fff;
	content: '\e066';
	font-family: gaxon;
	font-size: 20px;
	left: 0;
	position: absolute;
	top: 0;
}

@media screen and (max-width: 475px) {
	.oitis-standard .oitis-media {
		flex-direction: column;
	}
}

.flag,
.flag.flag-24 {
	background-repeat: no-repeat;
	display: inline-block;
}

.flag.flag-24 {
	background-image: url(flag/sprite-flags-24x24.png);
	height: 24px;
	width: 24px;
}

.flag.flag-24.flag-abkhazia {
	background-position: 0 0;
}

.flag.flag-24.flag-basque-country {
	background-position: -24px 0;
}

.flag.flag-24.flag-british-antarctic-territory {
	background-position: -48px 0;
}

.flag.flag-24.flag-commonwealth {
	background-position: -72px 0;
}

.flag.flag-24.flag-england {
	background-position: -96px 0;
}

.flag.flag-24.flag-gosquared {
	background-position: -120px 0;
}

.flag.flag-24.flag-kosovo {
	background-position: -144px 0;
}

.flag.flag-24.flag-mars {
	background-position: -168px 0;
}

.flag.flag-24.flag-nagorno-karabakh {
	background-position: -192px 0;
}

.flag.flag-24.flag-nato {
	background-position: -216px 0;
}

.flag.flag-24.flag-northern-cyprus {
	background-position: -240px 0;
}

.flag.flag-24.flag-olympics {
	background-position: -264px 0;
}

.flag.flag-24.flag-red-cross {
	background-position: -288px 0;
}

.flag.flag-24.flag-scotland {
	background-position: -312px 0;
}

.flag.flag-24.flag-somaliland {
	background-position: -336px 0;
}

.flag.flag-24.flag-south-ossetia {
	background-position: -360px 0;
}

.flag.flag-24.flag-united-nations {
	background-position: -384px 0;
}

.flag.flag-24.flag-unknown {
	background-position: 0 -24px;
}

.flag.flag-24.flag-wales {
	background-position: -24px -24px;
}

.flag.flag-24.flag-ad {
	background-position: -48px -24px;
}

.flag.flag-24.flag-ae {
	background-position: -72px -24px;
}

.flag.flag-24.flag-af {
	background-position: -96px -24px;
}

.flag.flag-24.flag-ag {
	background-position: -120px -24px;
}

.flag.flag-24.flag-ai {
	background-position: -144px -24px;
}

.flag.flag-24.flag-al {
	background-position: -168px -24px;
}

.flag.flag-24.flag-am {
	background-position: -192px -24px;
}

.flag.flag-24.flag-an {
	background-position: -216px -24px;
}

.flag.flag-24.flag-ao {
	background-position: -240px -24px;
}

.flag.flag-24.flag-aq {
	background-position: -264px -24px;
}

.flag.flag-24.flag-ar {
	background-position: -288px -24px;
}

.flag.flag-24.flag-as {
	background-position: -312px -24px;
}

.flag.flag-24.flag-at {
	background-position: -336px -24px;
}

.flag.flag-24.flag-au {
	background-position: -360px -24px;
}

.flag.flag-24.flag-aw {
	background-position: -384px -24px;
}

.flag.flag-24.flag-ax {
	background-position: 0 -48px;
}

.flag.flag-24.flag-az {
	background-position: -24px -48px;
}

.flag.flag-24.flag-ba {
	background-position: -48px -48px;
}

.flag.flag-24.flag-bb {
	background-position: -72px -48px;
}

.flag.flag-24.flag-bd {
	background-position: -96px -48px;
}

.flag.flag-24.flag-be {
	background-position: -120px -48px;
}

.flag.flag-24.flag-bf {
	background-position: -144px -48px;
}

.flag.flag-24.flag-bg {
	background-position: -168px -48px;
}

.flag.flag-24.flag-bh {
	background-position: -192px -48px;
}

.flag.flag-24.flag-bi {
	background-position: -216px -48px;
}

.flag.flag-24.flag-bj {
	background-position: -240px -48px;
}

.flag.flag-24.flag-bl {
	background-position: -264px -48px;
}

.flag.flag-24.flag-bm {
	background-position: -288px -48px;
}

.flag.flag-24.flag-bn {
	background-position: -312px -48px;
}

.flag.flag-24.flag-bo {
	background-position: -336px -48px;
}

.flag.flag-24.flag-br {
	background-position: -360px -48px;
}

.flag.flag-24.flag-bs {
	background-position: -384px -48px;
}

.flag.flag-24.flag-bt {
	background-position: 0 -72px;
}

.flag.flag-24.flag-bw {
	background-position: -24px -72px;
}

.flag.flag-24.flag-by {
	background-position: -48px -72px;
}

.flag.flag-24.flag-bz {
	background-position: -72px -72px;
}

.flag.flag-24.flag-ca {
	background-position: -96px -72px;
}

.flag.flag-24.flag-cc {
	background-position: -120px -72px;
}

.flag.flag-24.flag-cd {
	background-position: -144px -72px;
}

.flag.flag-24.flag-cf {
	background-position: -168px -72px;
}

.flag.flag-24.flag-cg {
	background-position: -192px -72px;
}

.flag.flag-24.flag-ch {
	background-position: -216px -72px;
}

.flag.flag-24.flag-ci {
	background-position: -240px -72px;
}

.flag.flag-24.flag-ck {
	background-position: -264px -72px;
}

.flag.flag-24.flag-cl {
	background-position: -288px -72px;
}

.flag.flag-24.flag-cm {
	background-position: -312px -72px;
}

.flag.flag-24.flag-cn {
	background-position: -336px -72px;
}

.flag.flag-24.flag-co {
	background-position: -360px -72px;
}

.flag.flag-24.flag-cr {
	background-position: -384px -72px;
}

.flag.flag-24.flag-cu {
	background-position: 0 -96px;
}

.flag.flag-24.flag-cv {
	background-position: -24px -96px;
}

.flag.flag-24.flag-cw {
	background-position: -48px -96px;
}

.flag.flag-24.flag-cx {
	background-position: -72px -96px;
}

.flag.flag-24.flag-cy {
	background-position: -96px -96px;
}

.flag.flag-24.flag-cz {
	background-position: -120px -96px;
}

.flag.flag-24.flag-de {
	background-position: -144px -96px;
}

.flag.flag-24.flag-dj {
	background-position: -168px -96px;
}

.flag.flag-24.flag-dk {
	background-position: -192px -96px;
}

.flag.flag-24.flag-dm {
	background-position: -216px -96px;
}

.flag.flag-24.flag-do {
	background-position: -240px -96px;
}

.flag.flag-24.flag-dz {
	background-position: -264px -96px;
}

.flag.flag-24.flag-ec {
	background-position: -288px -96px;
}

.flag.flag-24.flag-ee {
	background-position: -312px -96px;
}

.flag.flag-24.flag-eg {
	background-position: -336px -96px;
}

.flag.flag-24.flag-eh {
	background-position: -360px -96px;
}

.flag.flag-24.flag-er {
	background-position: -384px -96px;
}

.flag.flag-24.flag-es {
	background-position: 0 -120px;
}

.flag.flag-24.flag-et {
	background-position: -24px -120px;
}

.flag.flag-24.flag-eu {
	background-position: -48px -120px;
}

.flag.flag-24.flag-fi {
	background-position: -72px -120px;
}

.flag.flag-24.flag-fj {
	background-position: -96px -120px;
}

.flag.flag-24.flag-fk {
	background-position: -120px -120px;
}

.flag.flag-24.flag-fm {
	background-position: -144px -120px;
}

.flag.flag-24.flag-fo {
	background-position: -168px -120px;
}

.flag.flag-24.flag-fr {
	background-position: -192px -120px;
}

.flag.flag-24.flag-ga {
	background-position: -216px -120px;
}

.flag.flag-24.flag-gb {
	background-position: -240px -120px;
}

.flag.flag-24.flag-gd {
	background-position: -264px -120px;
}

.flag.flag-24.flag-ge {
	background-position: -288px -120px;
}

.flag.flag-24.flag-gg {
	background-position: -312px -120px;
}

.flag.flag-24.flag-gh {
	background-position: -336px -120px;
}

.flag.flag-24.flag-gi {
	background-position: -360px -120px;
}

.flag.flag-24.flag-gl {
	background-position: -384px -120px;
}

.flag.flag-24.flag-gm {
	background-position: 0 -144px;
}

.flag.flag-24.flag-gn {
	background-position: -24px -144px;
}

.flag.flag-24.flag-gq {
	background-position: -48px -144px;
}

.flag.flag-24.flag-gr {
	background-position: -72px -144px;
}

.flag.flag-24.flag-gs {
	background-position: -96px -144px;
}

.flag.flag-24.flag-gt {
	background-position: -120px -144px;
}

.flag.flag-24.flag-gu {
	background-position: -144px -144px;
}

.flag.flag-24.flag-gw {
	background-position: -168px -144px;
}

.flag.flag-24.flag-gy {
	background-position: -192px -144px;
}

.flag.flag-24.flag-hk {
	background-position: -216px -144px;
}

.flag.flag-24.flag-hn {
	background-position: -240px -144px;
}

.flag.flag-24.flag-hr {
	background-position: -264px -144px;
}

.flag.flag-24.flag-ht {
	background-position: -288px -144px;
}

.flag.flag-24.flag-hu {
	background-position: -312px -144px;
}

.flag.flag-24.flag-ic {
	background-position: -336px -144px;
}

.flag.flag-24.flag-id {
	background-position: -360px -144px;
}

.flag.flag-24.flag-ie {
	background-position: -384px -144px;
}

.flag.flag-24.flag-il {
	background-position: 0 -168px;
}

.flag.flag-24.flag-im {
	background-position: -24px -168px;
}

.flag.flag-24.flag-in {
	background-position: -48px -168px;
}

.flag.flag-24.flag-iq {
	background-position: -72px -168px;
}

.flag.flag-24.flag-ir {
	background-position: -96px -168px;
}

.flag.flag-24.flag-is {
	background-position: -120px -168px;
}

.flag.flag-24.flag-it {
	background-position: -144px -168px;
}

.flag.flag-24.flag-je {
	background-position: -168px -168px;
}

.flag.flag-24.flag-jm {
	background-position: -192px -168px;
}

.flag.flag-24.flag-jo {
	background-position: -216px -168px;
}

.flag.flag-24.flag-jp {
	background-position: -240px -168px;
}

.flag.flag-24.flag-ke {
	background-position: -264px -168px;
}

.flag.flag-24.flag-kg {
	background-position: -288px -168px;
}

.flag.flag-24.flag-kh {
	background-position: -312px -168px;
}

.flag.flag-24.flag-ki {
	background-position: -336px -168px;
}

.flag.flag-24.flag-km {
	background-position: -360px -168px;
}

.flag.flag-24.flag-kn {
	background-position: -384px -168px;
}

.flag.flag-24.flag-kp {
	background-position: 0 -192px;
}

.flag.flag-24.flag-kr {
	background-position: -24px -192px;
}

.flag.flag-24.flag-kw {
	background-position: -48px -192px;
}

.flag.flag-24.flag-ky {
	background-position: -72px -192px;
}

.flag.flag-24.flag-kz {
	background-position: -96px -192px;
}

.flag.flag-24.flag-la {
	background-position: -120px -192px;
}

.flag.flag-24.flag-lb {
	background-position: -144px -192px;
}

.flag.flag-24.flag-lc {
	background-position: -168px -192px;
}

.flag.flag-24.flag-li {
	background-position: -192px -192px;
}

.flag.flag-24.flag-lk {
	background-position: -216px -192px;
}

.flag.flag-24.flag-lr {
	background-position: -240px -192px;
}

.flag.flag-24.flag-ls {
	background-position: -264px -192px;
}

.flag.flag-24.flag-lt {
	background-position: -288px -192px;
}

.flag.flag-24.flag-lu {
	background-position: -312px -192px;
}

.flag.flag-24.flag-lv {
	background-position: -336px -192px;
}

.flag.flag-24.flag-ly {
	background-position: -360px -192px;
}

.flag.flag-24.flag-ma {
	background-position: -384px -192px;
}

.flag.flag-24.flag-mc {
	background-position: 0 -216px;
}

.flag.flag-24.flag-md {
	background-position: -24px -216px;
}

.flag.flag-24.flag-me {
	background-position: -48px -216px;
}

.flag.flag-24.flag-mf {
	background-position: -72px -216px;
}

.flag.flag-24.flag-mg {
	background-position: -96px -216px;
}

.flag.flag-24.flag-mh {
	background-position: -120px -216px;
}

.flag.flag-24.flag-mk {
	background-position: -144px -216px;
}

.flag.flag-24.flag-ml {
	background-position: -168px -216px;
}

.flag.flag-24.flag-mm {
	background-position: -192px -216px;
}

.flag.flag-24.flag-mn {
	background-position: -216px -216px;
}

.flag.flag-24.flag-mo {
	background-position: -240px -216px;
}

.flag.flag-24.flag-mp {
	background-position: -264px -216px;
}

.flag.flag-24.flag-mq {
	background-position: -288px -216px;
}

.flag.flag-24.flag-mr {
	background-position: -312px -216px;
}

.flag.flag-24.flag-ms {
	background-position: -336px -216px;
}

.flag.flag-24.flag-mt {
	background-position: -360px -216px;
}

.flag.flag-24.flag-mu {
	background-position: -384px -216px;
}

.flag.flag-24.flag-mv {
	background-position: 0 -240px;
}

.flag.flag-24.flag-mw {
	background-position: -24px -240px;
}

.flag.flag-24.flag-mx {
	background-position: -48px -240px;
}

.flag.flag-24.flag-my {
	background-position: -72px -240px;
}

.flag.flag-24.flag-mz {
	background-position: -96px -240px;
}

.flag.flag-24.flag-na {
	background-position: -120px -240px;
}

.flag.flag-24.flag-nc {
	background-position: -144px -240px;
}

.flag.flag-24.flag-ne {
	background-position: -168px -240px;
}

.flag.flag-24.flag-nf {
	background-position: -192px -240px;
}

.flag.flag-24.flag-ng {
	background-position: -216px -240px;
}

.flag.flag-24.flag-ni {
	background-position: -240px -240px;
}

.flag.flag-24.flag-nl {
	background-position: -264px -240px;
}

.flag.flag-24.flag-no {
	background-position: -288px -240px;
}

.flag.flag-24.flag-np {
	background-position: -312px -240px;
}

.flag.flag-24.flag-nr {
	background-position: -336px -240px;
}

.flag.flag-24.flag-nu {
	background-position: -360px -240px;
}

.flag.flag-24.flag-nz {
	background-position: -384px -240px;
}

.flag.flag-24.flag-om {
	background-position: 0 -264px;
}

.flag.flag-24.flag-pa {
	background-position: -24px -264px;
}

.flag.flag-24.flag-pe {
	background-position: -48px -264px;
}

.flag.flag-24.flag-pf {
	background-position: -72px -264px;
}

.flag.flag-24.flag-pg {
	background-position: -96px -264px;
}

.flag.flag-24.flag-ph {
	background-position: -120px -264px;
}

.flag.flag-24.flag-pirate-black {
	background-position: -144px -264px;
}

.flag.flag-24.flag-pirate-white {
	background-position: -168px -264px;
}

.flag.flag-24.flag-pk {
	background-position: -192px -264px;
}

.flag.flag-24.flag-pl {
	background-position: -216px -264px;
}

.flag.flag-24.flag-pn {
	background-position: -240px -264px;
}

.flag.flag-24.flag-pr {
	background-position: -264px -264px;
}

.flag.flag-24.flag-ps {
	background-position: -288px -264px;
}

.flag.flag-24.flag-pt {
	background-position: -312px -264px;
}

.flag.flag-24.flag-pw {
	background-position: -336px -264px;
}

.flag.flag-24.flag-py {
	background-position: -360px -264px;
}

.flag.flag-24.flag-qa {
	background-position: -384px -264px;
}

.flag.flag-24.flag-ro {
	background-position: 0 -288px;
}

.flag.flag-24.flag-rs {
	background-position: -24px -288px;
}

.flag.flag-24.flag-ru {
	background-position: -48px -288px;
}

.flag.flag-24.flag-rw {
	background-position: -72px -288px;
}

.flag.flag-24.flag-sa {
	background-position: -96px -288px;
}

.flag.flag-24.flag-sb {
	background-position: -120px -288px;
}

.flag.flag-24.flag-sc {
	background-position: -144px -288px;
}

.flag.flag-24.flag-sd {
	background-position: -168px -288px;
}

.flag.flag-24.flag-se {
	background-position: -192px -288px;
}

.flag.flag-24.flag-sg {
	background-position: -216px -288px;
}

.flag.flag-24.flag-sh {
	background-position: -240px -288px;
}

.flag.flag-24.flag-si {
	background-position: -264px -288px;
}

.flag.flag-24.flag-sk {
	background-position: -288px -288px;
}

.flag.flag-24.flag-sl {
	background-position: -312px -288px;
}

.flag.flag-24.flag-sm {
	background-position: -336px -288px;
}

.flag.flag-24.flag-sn {
	background-position: -360px -288px;
}

.flag.flag-24.flag-so {
	background-position: -384px -288px;
}

.flag.flag-24.flag-sr {
	background-position: 0 -312px;
}

.flag.flag-24.flag-ss {
	background-position: -24px -312px;
}

.flag.flag-24.flag-st {
	background-position: -48px -312px;
}

.flag.flag-24.flag-sv {
	background-position: -72px -312px;
}

.flag.flag-24.flag-sy {
	background-position: -96px -312px;
}

.flag.flag-24.flag-sz {
	background-position: -120px -312px;
}

.flag.flag-24.flag-tc {
	background-position: -144px -312px;
}

.flag.flag-24.flag-td {
	background-position: -168px -312px;
}

.flag.flag-24.flag-tf {
	background-position: -192px -312px;
}

.flag.flag-24.flag-tg {
	background-position: -216px -312px;
}

.flag.flag-24.flag-th {
	background-position: -240px -312px;
}

.flag.flag-24.flag-tj {
	background-position: -264px -312px;
}

.flag.flag-24.flag-tk {
	background-position: -288px -312px;
}

.flag.flag-24.flag-tl {
	background-position: -312px -312px;
}

.flag.flag-24.flag-tm {
	background-position: -336px -312px;
}

.flag.flag-24.flag-tn {
	background-position: -360px -312px;
}

.flag.flag-24.flag-to {
	background-position: -384px -312px;
}

.flag.flag-24.flag-tr {
	background-position: 0 -336px;
}

.flag.flag-24.flag-tt {
	background-position: -24px -336px;
}

.flag.flag-24.flag-tv {
	background-position: -48px -336px;
}

.flag.flag-24.flag-tw {
	background-position: -72px -336px;
}

.flag.flag-24.flag-tz {
	background-position: -96px -336px;
}

.flag.flag-24.flag-ua {
	background-position: -120px -336px;
}

.flag.flag-24.flag-ug {
	background-position: -144px -336px;
}

.flag.flag-24.flag-us {
	background-position: -168px -336px;
}

.flag.flag-24.flag-uy {
	background-position: -192px -336px;
}

.flag.flag-24.flag-uz {
	background-position: -216px -336px;
}

.flag.flag-24.flag-va {
	background-position: -240px -336px;
}

.flag.flag-24.flag-vc {
	background-position: -264px -336px;
}

.flag.flag-24.flag-ve {
	background-position: -288px -336px;
}

.flag.flag-24.flag-vg {
	background-position: -312px -336px;
}

.flag.flag-24.flag-vi {
	background-position: -336px -336px;
}

.flag.flag-24.flag-vn {
	background-position: -360px -336px;
}

.flag.flag-24.flag-vu {
	background-position: -384px -336px;
}

[data-icon]:before {
	content: attr(data-icon);
}

[class*=' icon-']:before,
[class^='icon-']:before,
[data-icon]:before {
	speak: none;

	-moz-osx-font-smoothing: grayscale;
	font-family: gaxon !important;
	font-style: normal !important;
	font-variant: normal !important;
	font-weight: 400 !important;
	line-height: 1;
	text-transform: none !important;
}

.icon-add:before {
	content: '\61';
}

.icon-add-circle:before {
	content: '\62';
}

.icon-affix:before {
	content: '\63';
}

.icon-alert:before {
	content: '\64';
}

.icon-alert-new:before {
	content: '\65';
}

.icon-all-contacts:before {
	content: '\66';
}

.icon-amchart:before {
	content: '\67';
}

.icon-anchor:before {
	content: '\68';
}

.icon-apps:before {
	content: '\69';
}

.icon-apps-new:before {
	content: '\6a';
}

.icon-arrow-left:before {
	content: '\6b';
}

.icon-arrow-right:before {
	content: '\6c';
}

.icon-attachment:before {
	content: '\6d';
}

.icon-auth-screen:before {
	content: '\6e';
}

.icon-autocomplete:before {
	content: '\6f';
}

.icon-avatar:before {
	content: '\70';
}

.icon-backtop:before {
	content: '\71';
}

.icon-badge:before {
	content: '\72';
}

.icon-basic-calendar:before {
	content: '\73';
}

.icon-birthday:before {
	content: '\74';
}

.icon-birthday-new:before {
	content: '\75';
}

.icon-bitcoin:before {
	content: '\76';
}

.icon-breadcrumb:before {
	content: '\77';
}

.icon-burger:before {
	content: '\78';
}

.icon-button:before {
	content: '\79';
}

.icon-calendar:before {
	content: '\7a';
}

.icon-calendar-new:before {
	content: '\41';
}

.icon-callout:before {
	content: '\42';
}

.icon-camera:before {
	content: '\43';
}

.icon-camera-2:before {
	content: '\44';
}

.icon-card:before {
	content: '\45';
}

.icon-cards-list-view:before {
	content: '\46';
}

.icon-carousel:before {
	content: '\47';
}

.icon-cascader:before {
	content: '\48';
}

.icon-chart:before {
	content: '\49';
}

.icon-chart-area:before {
	content: '\4a';
}

.icon-chart-area-new:before {
	content: '\4b';
}

.icon-chart-bar:before {
	content: '\4c';
}

.icon-chart-composed:before {
	content: '\4d';
}

.icon-chart-line:before {
	content: '\4e';
}

.icon-chart-pie:before {
	content: '\4f';
}

.icon-chart-radar:before {
	content: '\50';
}

.icon-chart-radial:before {
	content: '\51';
}

.icon-chart-scatter:before {
	content: '\52';
}

.icon-chart-tree:before {
	content: '\53';
}

.icon-charvlet-down:before {
	content: '\54';
}

.icon-charvlet-left:before {
	content: '\55';
}

.icon-charvlet-right:before {
	content: '\56';
}

.icon-charvlet-up:before {
	content: '\57';
}

.icon-chat:before {
	content: '\58';
}

.icon-chat-bubble:before {
	content: '\59';
}

.icon-chat-new:before {
	content: '\5a';
}

.icon-check:before {
	content: '\30';
}

.icon-check-circle-o:before {
	content: '\31';
}

.icon-check-cricle:before {
	content: '\32';
}

.icon-check-square:before {
	content: '\33';
}

.icon-check-square-o:before {
	content: '\34';
}

.icon-chevron-down:before {
	content: '\35';
}

.icon-chevron-left:before {
	content: '\36';
}

.icon-chevron-right:before {
	content: '\37';
}

.icon-chevron-up:before {
	content: '\38';
}

.icon-circle:before {
	content: '\39';
}

.icon-circle-o:before {
	content: '\21';
}

.icon-ckeditor:before {
	content: '\22';
}

.icon-close:before {
	content: '\23';
}

.icon-close-circle:before {
	content: '\24';
}

.icon-collapse:before {
	content: '\25';
}

.icon-company:before {
	content: '\26';
}

.icon-components:before {
	content: '\27';
}

.icon-compose:before {
	content: '\28';
}

.icon-contacts:before {
	content: '\29';
}

.icon-copy:before {
	content: '\2a';
}

.icon-crm:before {
	content: '\2b';
}

.icon-crypto:before {
	content: '\2c';
}

.icon-culture-calendar:before {
	content: '\2d';
}

.icon-custom-view:before {
	content: '\2e';
}

.icon-dasbhoard:before {
	content: '\2f';
}

.icon-data-display:before {
	content: '\3a';
}

.icon-data-entry:before {
	content: '\3b';
}

.icon-datepicker:before {
	content: '\3c';
}

.icon-default-timeline:before {
	content: '\3d';
}

.icon-diamond:before {
	content: '\3e';
}

.icon-divider:before {
	content: '\3f';
}

.icon-donut:before {
	content: '\40';
}

.icon-down:before {
	content: '\5b';
}

.icon-draft:before {
	content: '\5d';
}

.icon-drag-and-drop:before {
	content: '\5e';
}

.icon-dropdown:before {
	content: '\5f';
}

.icon-edit:before {
	content: '\60';
}

.icon-editor:before {
	content: '\7b';
}

.icon-ellipse-h:before {
	content: '\7c';
}

.icon-ellipse-v:before {
	content: '\7d';
}

.icon-email:before {
	content: '\7e';
}

.icon-error:before {
	content: '\5c';
}

.icon-error-404:before {
	content: '\e000';
}

.icon-error-500:before {
	content: '\e001';
}

.icon-etherium:before {
	content: '\e002';
}

.icon-exclamation:before {
	content: '\e003';
}

.icon-expand:before {
	content: '\e004';
}

.icon-extensions:before {
	content: '\e005';
}

.icon-extra-components:before {
	content: '\e006';
}

.icon-eye:before {
	content: '\e007';
}

.icon-facebook:before {
	content: '\e008';
}

.icon-family:before {
	content: '\e009';
}

.icon-feedback:before {
	content: '\e00a';
}

.icon-files:before {
	content: '\e00b';
}

.icon-filter:before {
	content: '\e00c';
}

.icon-filter-circle:before {
	content: '\e00d';
}

.icon-folder:before {
	content: '\e00e';
}

.icon-folder-o:before {
	content: '\e00f';
}

.icon-font:before {
	content: '\e010';
}

.icon-forgot-password:before {
	content: '\e011';
}

.icon-forward:before {
	content: '\e012';
}

.icon-forward-o:before {
	content: '\e013';
}

.icon-frequent:before {
	content: '\e014';
}

.icon-geo-location:before {
	content: '\e015';
}

.icon-graduation:before {
	content: '\e016';
}

.icon-growth:before {
	content: '\e017';
}

.icon-home:before {
	content: '\e018';
}

.icon-hotel-booking:before {
	content: '\e019';
}

.icon-icon:before {
	content: '\e01a';
}

.icon-image:before {
	content: '\e01b';
}

.icon-important:before {
	content: '\e01c';
}

.icon-important-o:before {
	content: '\e01d';
}

.icon-inbox:before {
	content: '\e01e';
}

.icon-inbuilt-apps:before {
	content: '\e01f';
}

.icon-input:before {
	content: '\e020';
}

.icon-inputnumber:before {
	content: '\e021';
}

.icon-invert-color:before {
	content: '\e022';
}

.icon-keyboard:before {
	content: '\e023';
}

.icon-like:before {
	content: '\e024';
}

.icon-like-o:before {
	content: '\e025';
}

.icon-link:before {
	content: '\e026';
}

.icon-lising-dbrd:before {
	content: '\e027';
}

.icon-list-select-o:before {
	content: '\e028';
}

.icon-listing-dbrd:before {
	content: '\e029';
}

.icon-litcoin:before {
	content: '\e02a';
}

.icon-localeprovider:before {
	content: '\e02b';
}

.icon-location:before {
	content: '\e02c';
}

.icon-lock-screen:before {
	content: '\e02d';
}

.icon-long-arrow:before {
	content: '\e02e';
}

.icon-long-arrow-down:before {
	content: '\e02f';
}

.icon-long-arrow-left:before {
	content: '\e030';
}

.icon-long-arrow-right:before {
	content: '\e031';
}

.icon-long-arrow-up:before {
	content: '\e032';
}

.icon-mail-open:before {
	content: '\e033';
}

.icon-map-clustering:before {
	content: '\e034';
}

.icon-map-directions:before {
	content: '\e035';
}

.icon-map-drawing:before {
	content: '\e036';
}

.icon-map-event-listener:before {
	content: '\e037';
}

.icon-map-google:before {
	content: '\e038';
}

.icon-map-km-layer:before {
	content: '\e039';
}

.icon-map-overlay:before {
	content: '\e03a';
}

.icon-map-popup-info:before {
	content: '\e03b';
}

.icon-map-selectable:before {
	content: '\e03c';
}

.icon-map-simple:before {
	content: '\e03d';
}

.icon-map-street-view:before {
	content: '\e03e';
}

.icon-map-styled:before {
	content: '\e03f';
}

.icon-map-traffic-layer:before {
	content: '\e040';
}

.icon-megaphone:before {
	content: '\e041';
}

.icon-mention:before {
	content: '\e042';
}

.icon-menu:before {
	content: '\e043';
}

.icon-menu-down:before {
	content: '\e044';
}

.icon-menu-fold:before {
	content: '\e045';
}

.icon-menu-left:before {
	content: '\e046';
}

.icon-menu-lines:before {
	content: '\e047';
}

.icon-menu-right:before {
	content: '\e048';
}

.icon-menu-select:before {
	content: '\e049';
}

.icon-menu-unfold:before {
	content: '\e04a';
}

.icon-menu-up:before {
	content: '\e04b';
}

.icon-message:before {
	content: '\e04c';
}

.icon-mic:before {
	content: '\e04d';
}

.icon-modal:before {
	content: '\e04e';
}

.icon-navigation:before {
	content: '\e04f';
}

.icon-noodles:before {
	content: '\e050';
}

.icon-notification:before {
	content: '\e051';
}

.icon-notification-new:before {
	content: '\e052';
}

.icon-orders:before {
	content: '\e053';
}

.icon-pagination:before {
	content: '\e054';
}

.icon-phone:before {
	content: '\e055';
}

.icon-picker:before {
	content: '\e056';
}

.icon-pizza:before {
	content: '\e057';
}

.icon-plain-list-divider:before {
	content: '\e058';
}

.icon-plain-list-view:before {
	content: '\e059';
}

.icon-popconfirm:before {
	content: '\e05a';
}

.icon-popover:before {
	content: '\e05b';
}

.icon-popup-calendar:before {
	content: '\e05c';
}

.icon-pricing-table:before {
	content: '\e05d';
}

.icon-product-grid:before {
	content: '\e05e';
}

.icon-product-list:before {
	content: '\e05f';
}

.icon-profile:before {
	content: '\e060';
}

.icon-profile2:before {
	content: '\e061';
}

.icon-progress:before {
	content: '\e062';
}

.icon-queries:before {
	content: '\e063';
}

.icon-question-circle:before {
	content: '\e064';
}

.icon-quote-backward:before {
	content: '\e065';
}

.icon-quote-forward:before {
	content: '\e066';
}

.icon-radiobutton:before {
	content: '\e067';
}

.icon-refer:before {
	content: '\e068';
}

.icon-rendaring-calendar:before {
	content: '\e069';
}

.icon-reply:before {
	content: '\e06a';
}

.icon-reply-o:before {
	content: '\e06b';
}

.icon-reset-password:before {
	content: '\e06c';
}

.icon-revenue-new:before {
	content: '\e06d';
}

.icon-ripple:before {
	content: '\e06e';
}

.icon-schedule:before {
	content: '\e06f';
}

.icon-search:before {
	content: '\e070';
}

.icon-search-new:before {
	content: '\e071';
}

.icon-select:before {
	content: '\e072';
}

.icon-selectable-map:before {
	content: '\e073';
}

.icon-sent:before {
	content: '\e074';
}

.icon-setting:before {
	content: '\e075';
}

.icon-shopping-cart:before {
	content: '\e076';
}

.icon-shuffle:before {
	content: '\e077';
}

.icon-signin:before {
	content: '\e078';
}

.icon-signup:before {
	content: '\e079';
}

.icon-slider:before {
	content: '\e07a';
}

.icon-social:before {
	content: '\e07b';
}

.icon-spam:before {
	content: '\e07c';
}

.icon-spin:before {
	content: '\e07d';
}

.icon-star:before {
	content: '\e07e';
}

.icon-star-half:before {
	content: '\e07f';
}

.icon-star-o:before {
	content: '\e080';
}

.icon-stats:before {
	content: '\e081';
}

.icon-steps:before {
	content: '\e082';
}

.icon-styled-map:before {
	content: '\e083';
}

.icon-sweet-alert:before {
	content: '\e084';
}

.icon-switch:before {
	content: '\e085';
}

.icon-tab:before {
	content: '\e086';
}

.icon-table:before {
	content: '\e087';
}

.icon-table-data:before {
	content: '\e088';
}

.icon-table-general:before {
	content: '\e089';
}

.icon-tag:before {
	content: '\e08a';
}

.icon-tag-new:before {
	content: '\e08b';
}

.icon-tag-o:before {
	content: '\e08c';
}

.icon-tasks:before {
	content: '\e08d';
}

.icon-team:before {
	content: '\e08e';
}

.icon-testimonial:before {
	content: '\e08f';
}

.icon-thumb-up:before {
	content: '\e090';
}

.icon-thumbs-down:before {
	content: '\e091';
}

.icon-ticket-new:before {
	content: '\e092';
}

.icon-tickets:before {
	content: '\e093';
}

.icon-timeline:before {
	content: '\e094';
}

.icon-timeline-left-align:before {
	content: '\e095';
}

.icon-timeline-new:before {
	content: '\e096';
}

.icon-timeline-with-icons:before {
	content: '\e097';
}

.icon-timepicker:before {
	content: '\e098';
}

.icon-tooltip:before {
	content: '\e099';
}

.icon-transfer:before {
	content: '\e09a';
}

.icon-translation:before {
	content: '\e09b';
}

.icon-trash:before {
	content: '\e09c';
}

.icon-tree:before {
	content: '\e09d';
}

.icon-treeselect:before {
	content: '\e09e';
}

.icon-uncheck-squire:before {
	content: '\e09f';
}

.icon-uncheck-squire-o:before {
	content: '\e0a0';
}

.icon-upload:before {
	content: '\e0a1';
}

.icon-user:before {
	content: '\e0a2';
}

.icon-user-o:before {
	content: '\e0a3';
}

.icon-view:before {
	content: '\e0a4';
}

.icon-view-o:before {
	content: '\e0a5';
}

.icon-visits:before {
	content: '\e0a6';
}

.icon-wall:before {
	content: '\e0a7';
}

.icon-widgets:before {
	content: '\e0a8';
}

.icon-wysiwyg:before {
	content: '\e0a9';
}

.ant-table-column-sorters {
	display: flex;
}

.ant-table-column-sorters>span {
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.ant-table-column-sorters>span:first-child {
	flex: 1 1;
}

.ant-pagination-options-size-changer .ant-select-item-option-content {
	padding: 5px 12px;
}

.ant-tabs-nav {
	margin: 0 !important;
}

.ant-form-item.ant-form-item-hidden {
	display: inherit !important;
	flex: 0 1 !important;
	margin: 0 !important;
	max-height: 0 !important;
	opacity: 0 !important;
	overflow: hidden !important;
	padding: 0 !important;
}

.ant-form-item {
	transition: all 0.15s ease-out;
}

.ant-row.ant-form-item .ant-form-item-label label div {
	overflow: hidden;
	overflow-wrap: normal;
	text-overflow: ellipsis;
}

.ant-popover-message {
	display: flex;
}