@font-face {
	font-family: 'NoirPro';
	src: url(noir-pro/NoirPro-Light.eot?#iefix) format('embedded-opentype'),
		url(noir-pro/NoirPro-Light.woff) format('woff'),
		url(noir-pro/NoirPro-Light.woff2) format('woff2');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'NoirPro';
	src: url(noir-pro/NoirPro-Regular.eot?#iefix) format('embedded-opentype'),
		url(noir-pro/NoirPro-Regular.woff) format('woff'),
		url(noir-pro/NoirPro-Regular.woff2) format('woff2');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'NoirPro';
	src: url(noir-pro/NoirPro-Medium.eot?#iefix) format('embedded-opentype'),
		url(noir-pro/NoirPro-Medium.woff) format('woff'),
		url(noir-pro/NoirPro-Medium.woff2) format('woff2');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'NoirPro';
	src: url(noir-pro/NoirPro-SemiBold.eot?#iefix) format('embedded-opentype'),
		url(noir-pro/NoirPro-SemiBold.woff) format('woff'),
		url(noir-pro/NoirPro-SemiBold.woff2) format('woff2');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'NoirPro';
	src: url(noir-pro/NoirPro-Bold.eot?#iefix) format('embedded-opentype'),
		url(noir-pro/NoirPro-Bold.woff) format('woff'),
		url(noir-pro/NoirPro-Bold.woff2) format('woff2');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'NoirPro';
	src: url(noir-pro/NoirPro-Heavy.eot?#iefix) format('embedded-opentype'),
		url(noir-pro/NoirPro-Heavy.woff) format('woff'),
		url(noir-pro/NoirPro-Heavy.woff2) format('woff2');
	font-weight: 900;
	font-style: normal;
}