html,
body {
	position: relative;
	display: flex;
	width: 100%;
	min-height: 100%;
	max-width: 100vw;
	padding: 0;
	margin: 0;

	font-size: 14px;
	font-weight: 400;
	line-height: 1.3;
	text-size-adjust: 100%;
	background: #f5f5f5;
	color: #545454;
	overflow: hidden !important;

	/* text-size-adjust: 100%; */
	/* -ms-overflow-style: scrollbar; */
	/* -webkit-tap-highlight-color: rgba(0, 0, 0, 0); */
}

#root {
	width: 100%;
	height: 100%;
	max-height: 100vh;
	position: relative;
	overflow: hidden scroll !important;
}

* {
	font-family: NoirPro, sans-serif;

	text-rendering: geometricPrecision;
	shape-rendering: geometricPrecision;
	image-rendering: optimizeQuality;

	/* HUOM: "optimizeSpeed" tuhoaa jotkut ikonit */
	/* text-rendering: optimizeSpeed; */
	/* image-rendering: optimizeSpeed; */
	/* shape-rendering: optimizeSpeed; */
}

*,
:after,
:before {
	user-select: none;
	box-sizing: border-box;
}

@-ms-viewport {
	width: device-width;
}

/* Webkit scrollbar */

::-webkit-scrollbar {
	width: 12px;
}

::-webkit-scrollbar-track {
	background: #eee;
}

::-webkit-scrollbar-thumb {
	background: #aaa;
}

::-webkit-scrollbar-thumb:hover {
	background: #bbb;
}

/* Element overrides */

button {
	outline: 0;
	border: 0;
	background: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
}

input,
textarea {
	user-select: auto;
	font-size: 14px;
}

a {
	color: #038fde;
	text-decoration: none;
	background-color: transparent;
	outline: none;
	cursor: pointer;
	transition: color 0.3s;
}

.anticon {
	display: inline-flex !important;
	align-items: center !important;
	justify-content: center !important;
}

/* Fixes */

.ant-popover-buttons {
	display: flex;
}

.ant-tabs-tabpane-hidden {
	display: none;
}

.ant-form .ant-row {
	width: 100%;
}

.rbc-header,
.rbc-date-cell {
	text-align: left !important;
}